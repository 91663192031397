interface Props {
  headerTitle: string;
  headerDescription?: string;
  bannerTitle: string;
  bannerDescription: string;
  iconImageSrc: string;
  iconImageAlt: string;
  actionButton?: React.ReactNode;
}

export default function AdminTablesHeader({
  headerTitle,
  headerDescription,
  bannerTitle,
  actionButton,
  iconImageSrc,
  iconImageAlt,
  bannerDescription,
}: Props) {
  return (
    <div className="column-gap-16px">
      <div className="column-gap-8px">
        <h2>{headerTitle}</h2>
        {headerDescription ? <p>{headerDescription}</p> : null}
      </div>
      <div className="admin-banner">
        <div className="column-gap-12px">
          <div className="column-gap-4px">
            <h3>{bannerTitle}</h3>
            <p>{bannerDescription}</p>
          </div>
          <div>{actionButton}</div>
        </div>
        <div>
          <img src={iconImageSrc} alt={iconImageAlt} />
        </div>
      </div>
    </div>
  );
}
