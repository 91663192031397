import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { GuideType, ModalType } from "./types";
import { ModuleType } from "app/components/Modules/types";

// ------------------ State Type/Structure ------------------
export interface ModuleComponentsState {
  guideType: GuideType;
  userIdOrTeamIdOrCompanyId: null | number;
  modalToShow: ModalType;
  modalTab: null | string;
  selectedModule: null | {
    moduleTemplateId: number | string;
    moduleType: ModuleType | "Resource";
    link?: string;
    moduleContent?: string;
    talentInsightsModuleId?: number;
    moduleTitle?: string; // only for resources
  };
}

// ------------------ InitialState ------------------
const initialState: ModuleComponentsState = {
  modalToShow: null,
  modalTab: null,
  selectedModule: null,
  guideType: "user",
  userIdOrTeamIdOrCompanyId: null,
};

// ------------------ Beginning of Slice Definition ------------------
export const modulesSlice = createSlice({
  name: "modules",
  initialState,
  reducers: {
    setModalTab(state, action: PayloadAction<string | null>) {
      state.modalTab = action.payload;
    },
    showAddModuleModal(
      state,
      {
        payload,
      }: PayloadAction<
        | {
            guideType: GuideType;
            userIdOrTeamIdOrCompanyId: number;
            section?: string;
          }
        | undefined
      >
    ) {
      state.modalToShow = "add-module";
      state.guideType = payload?.guideType ?? "user";
      state.modalTab = payload?.section ?? null;
      state.userIdOrTeamIdOrCompanyId =
        payload?.userIdOrTeamIdOrCompanyId ?? null;
    },
    showEditModuleModal(
      state,
      {
        payload,
      }: PayloadAction<{
        moduleTemplateId: number | string;
        moduleType: ModuleType | "Resource";
        guideType: GuideType;
        link?: string;
        moduleContent?: string;
        talentInsightsModuleId?: number;
        userIdOrTeamIdOrCompanyId: number;
        moduleTitle?: string; // only for resources
      }>
    ) {
      state.guideType = payload.guideType;
      state.userIdOrTeamIdOrCompanyId = payload.userIdOrTeamIdOrCompanyId;
      state.selectedModule = payload;
      state.modalToShow = "edit-module";
    },
    showWelcomeModal(state) {
      state.modalToShow = "welcome";
    },
    hideModal(state) {
      state.modalToShow = null;
      state.selectedModule = null;
    },
  },
});

// ------------------ Selectors ------------------
export const selectSelectedModule = (state: RootState) =>
  state.modules.selectedModule;
export const selectModalToShow = (state: RootState) =>
  state.modules.modalToShow;
export const selectModalTab = (state: RootState) => state.modules.modalTab;
export const selectGuideType = (state: RootState) => state.modules.guideType;
export const selectUserIdOrTeamIdOrCompanyId = (state: RootState) =>
  state.modules.userIdOrTeamIdOrCompanyId;

// Api Status
export const selectAddModuleToUserGuideStatus = (state: RootState) =>
  state.userGuide.addModuleToUserGuideStatus;
export const selectEditUserGuideModuleStatus = (state: RootState) =>
  state.userGuide.editUserGuideModuleStatus;
export const selectDeleteUserModuleByIdStatus = (state: RootState) =>
  state.userGuide.deleting;

export const selectAddModuleToTeamGuideStatus = (state: RootState) =>
  state.teamGuide.addModuleToTeamGuideStatus;
export const selectEditTeamGuideModuleStatus = (state: RootState) =>
  state.teamGuide.editTeamGuideModuleStatus;
export const selectDeleteTeamModuleByIdStatus = (state: RootState) =>
  state.teamGuide.deleting;

export const selectAddModuleToCompanyGuideStatus = (state: RootState) =>
  state.companyGuide.addModuleToCompanyGuideStatus;
export const selectEditCompanyGuideModuleStatus = (state: RootState) =>
  state.companyGuide.editCompanyGuideModuleStatus;
export const selectDeleteCompanyModuleByIdStatus = (state: RootState) =>
  state.companyGuide.deleting;

export const {
  showAddModuleModal,
  showEditModuleModal,
  showWelcomeModal,
  hideModal,
  setModalTab,
} = modulesSlice.actions;

export default modulesSlice.reducer;
