import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { request } from "utils/request";
import { OpenAIModules } from "app/components/OpenAIInternalTools/OpenAIModules/types";
import { OpenAIModuleInfo } from "app/components/OpenAIInternalTools/OpenAIModule/types";
import { REACT_APP_API_URL } from "utils/environmentVariables";

export interface OpenAIModulesState {
  modules: null | OpenAIModules;
  selectedModule: null | OpenAIModuleInfo;
  gettingModules: responseStatus;
}

const initialState: OpenAIModulesState = {
  modules: null,
  selectedModule: null,
  gettingModules: "idle",
};

export const getOpenAIModules = createAsyncThunk(
  "getOpenAIModule",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/openAI/modules`;
    return (await request(requestUrl, {
      method: "GET",
    })) as OpenAIModules;
  }
);

export const openAIModulesSlice = createSlice({
  name: "openAIModules",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOpenAIModules.pending, (state) => {
        state.gettingModules = "loading";
      })
      .addCase(
        getOpenAIModules.fulfilled,
        (state, action: PayloadAction<OpenAIModules>) => {
          return {
            ...state,
            gettingModules: "succeeded",
            modules: { ...action.payload },
          };
        }
      )
      .addCase(getOpenAIModules.rejected, (state) => {
        state.gettingModules = "failed";
      });
  },
});

export const selectOpenAIModules = (state: RootState) =>
  state.openAIModules.modules;

export const selectGettingModules = (state: RootState) =>
  state.openAIModules.gettingModules;

export default openAIModulesSlice.reducer;
