export const dashboardFilters = [
  {
    filterName: 'Most Recent',
  },
  {
    filterName: 'Team',
  },
  {
    filterName: 'Type',
  },
];

export const VALID_DASHBOARD_ROLES = [
  'Admin',
  'Department Leader',
  'Team Leader',
  'Team Member',
] as const;

export const TrackedQuickActionEventsMapByRole = {
  Admin: 'adminQuickActionClosed',
  'Department Leader': 'departmentLeaderQuickActionClosed',
  'Team Leader': 'teamLeaderQuickActionClosed',
} as const;
