import {
  AllTeam360AssessmentResults,
  TTeam360Factor,
  TTeam360Dimension,
  Team360AssessmentResult,
} from 'app/components/Team360Assessment/types';
import { Team } from 'app/containers/Global/types';
import { Department } from 'app/containers/AdminConsole/types';
import { getTeam360Score } from 'app/components/Team360Assessment/helpers';

export const newGetTableColumnsAndData = (
  activeFilters: {
    Department: number[];
    Team: number[];
  },
  activeFactorFilter: (TTeam360Factor | 'Overall TEAM Score')[],
  activeDimensionFilters: TTeam360Dimension[],
  orgScoresEnabled: boolean,
  teamInfoById: { [key: string]: Team },
  departmentsById: { [key: string]: Department },
  team360Scores: AllTeam360AssessmentResults
): {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
  }[];
} => {
  const labels: string[] = [];
  const newDataSets: {
    [filterValue: string]: {
      label: string;
      data: number[];
      backgroundColor: string;
    };
  } = {};
  const copyColorArr = [...COLOR_ARR];

  const iterateThroughActiveFilters = (
    filterArr: string[],
    type: 'factors' | 'dimensions',
    scores: Team360AssessmentResult
  ) => {
    filterArr.forEach((filter) => {
      const numericalScore = getNumericalScore(scores, type, filter);
      if (newDataSets[filter]) {
        newDataSets[filter].data.push(numericalScore);
      } else {
        newDataSets[filter] = {
          label: filter,
          data: [numericalScore],
          backgroundColor: copyColorArr.shift() ?? '',
        };
      }
    });
  };

  if (orgScoresEnabled) {
    const orgScores = team360Scores.companyScores;
    labels.push('This Organization');
    iterateThroughActiveFilters(activeFactorFilter, 'factors', orgScores);
    iterateThroughActiveFilters(
      activeDimensionFilters,
      'dimensions',
      orgScores
    );
  }

  // What we need to do is have the department and teams be different colors
  Object.entries(activeFilters).forEach(([type, ids]) => {
    ids.forEach((id) => {
      const scores = getTeamScoresById(
        team360Scores,
        id,
        type as 'Team' | 'Department'
      );
      const label = getBarLabel(
        teamInfoById,
        departmentsById,
        id,
        type as 'Team' | 'Department'
      );
      labels.push(label);
      iterateThroughActiveFilters(activeFactorFilter, 'factors', scores);
      iterateThroughActiveFilters(activeDimensionFilters, 'dimensions', scores);
    });
  });

  return {
    labels,
    datasets: Object.values(newDataSets),
  };
};

export const COLOR_ARR = [
  '#202D63',
  '#EE99BC',
  '#FFBEB0',
  '#ABEBED',
  '#7F91A6',
  '#8E262A',
  '#CB9B9C',
  '#F2D3B3',
  '#5C3142',
  '#D1A1A8',
  '#F5E0B5',
  '#788A77',
  '#D3C3BC',
  '#967A55',
  '#5C5B5E',
  '#C2D9E9',
  '#F0C8A8',
  '#A59281',
  '#AA7B62',
  '#E8C6D1',
];

export const AdvancedAnalyticsOptions = {
  events: [],
  borderRadius: 10,
  scales: {
    yAxis: {
      grid: {
        display: false,
      },
      ticks: {
        stepSize: 25,
        display: true,
      },
    },
    xAxis: {
      grid: {
        display: false,
      },
    },
    y: {
      display: false,
    },
    x: {
      display: false,
    },
  },
  plugins: {
    datalabels: {
      align: 'top',
      anchor: 'end',
      font: { size: 16 },
      textAlign: 'center',
    },
    legend: {
      position: 'bottom',
      useBorderRadius: true,
      labels: {
        usePointStyle: true,
        pointStyle: 'rectRounded',
        pointStyleWidth: 50,
      },
    },
  },
} as const;

// -------------------- Internal Helper functions --------------------
const getTeamScoresById = (
  team360Scores: AllTeam360AssessmentResults,
  id: number,
  type?: 'Team' | 'Department'
) => {
  switch (type) {
    case 'Team':
      return team360Scores.teamScores[id];
    case 'Department':
      return team360Scores.departmentScores[id];
    default:
      return team360Scores.companyScores;
  }
};

const getBarLabel = (
  teamInfoById: { [key: string]: Team },
  departmentsById: { [key: string]: Department },
  id: number,
  type?: 'Team' | 'Department'
) => {
  switch (type) {
    case 'Team':
      const teamName = teamInfoById[id]?.teamName || '';
      if (teamName.toLocaleLowerCase().includes(' team')) {
        return teamName;
      }
      return `${teamName} Team`;
    case 'Department':
      const departmentName = departmentsById[id]?.name || '';
      if (departmentName.toLocaleLowerCase().includes(' department')) {
        return departmentName;
      }
      return `${departmentName} Department`;
    default:
      return '';
  }
};

const getNumericalScore = (
  scoreObj: Team360AssessmentResult,
  type?: 'factors' | 'dimensions',
  id?: string
) => {
  if (id === 'Overall TEAM Score') {
    return getTeam360Score(scoreObj?.overall ?? 0);
  }

  if (type === 'factors') {
    return getTeam360Score(scoreObj.factors?.[id as TTeam360Factor] || 0);
  }

  return getTeam360Score(scoreObj.dimensions?.[id as TTeam360Dimension] || 0);
};
