import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import { Link, useNavigate } from "react-router-dom";
import Button from "app/storybookComponents/Button";
import {
  selectAllCompanyUsersById,
  selectCurrentUserAccountId,
  selectTeamsByTeamId,
} from "app/containers/Global/slice";
import { selectTeamsMostRecentAssessmentsInstancesEntities } from "app/containers/Assessment/slice";
import { useEffect, useMemo, useState } from "react";
import DashboardOnboardingCard from "../Cards/DashboardOnboardingCard";
import AvatarCircle from "app/components/AvatarCircle";
import { getHasAnyTeam360Results, getTeamTotalText } from "../helpers";
import { openCreateTeamModal } from "app/components/Modals/slice";
import {
  getPendingTests,
  getSuperpowersAndHiddenStrengths,
  getWorkPlaceInsightsReportByUserId,
  selectGetPendingTestsStatus,
  selectPendingTests,
  selectSuperpowersAndHiddenStrengthsById,
  selectWorkPlaceInsightsReportsById,
} from "app/components/WorkplaceInsightsReport/slice";
import { setShowModal } from "app/components/Onboarding/slice";
import {
  showScheduleAssessmentModal,
  showScheduleAssessmentModalForTeamId,
} from "app/components/LaunchAssessmentModal/slice";
import { Card } from "react-bootstrap";
import workplaceInsightsIllustration from "resources/images/Illustrations-Teams_EmptyState-TeamAverage.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RadarChart from "app/storybookComponents/Charts/RadarChart";
import {
  RADAR_LABEL_ARRAY,
  SINGLE_POPULATION_RADAR_CHART_DATASET,
} from "app/storybookComponents/Charts/constants";
import Team360TeamsCard from "../Cards/Team360TeamsCard";
import MyTeamsCard from "../Cards/MyTeamsCard";
import { WORKPLACE_INSIGHT_TRAIT_INFO } from "app/components/WorkplaceInsightsReport/constants";
import { REACT_APP_ODA_URL } from "utils/environmentVariables";
import { selectAllTeamInvitations } from "../slice";

interface Props {
  onShowTeamLeaderOnboardingModal: (departmentId?: null | number) => void;
  teamLeaderOnboardingModalBeenShown?: boolean;
  onShowInviteMemberModal: () => void;
  isLeader?: boolean;
}

export default function MemberDashboard({
  isLeader,
  onShowInviteMemberModal,
}: Readonly<Props>) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // ------------------------------- Selectors --------------------------------------------- //
  const usersById = useAppSelector(selectAllCompanyUsersById);
  const loggedInUserAccountId = useAppSelector(selectCurrentUserAccountId);
  const teamsById = useAppSelector(selectTeamsByTeamId);
  const teamsMostRecentAssessments = useAppSelector(
    selectTeamsMostRecentAssessmentsInstancesEntities
  );
  const workplaceInsightsReport = useAppSelector(
    selectWorkPlaceInsightsReportsById(Number(loggedInUserAccountId))
  );
  const superpowerAndHiddenStrengths = useAppSelector(
    selectSuperpowersAndHiddenStrengthsById(Number(loggedInUserAccountId))
  );
  const pendingTests = useAppSelector(selectPendingTests);
  const getPendingTestsStatus = useAppSelector(selectGetPendingTestsStatus);
  const pendingTeamNotifications = useAppSelector(selectAllTeamInvitations);

  // ------------------------------------- Component States ------------------------------------- //

  const [teamMembersOnSameTeam, setTeamMembersOnSameTeam] = useState<number[]>(
    []
  );

  // ------------------------------------- Effect ------------------------------------- //
  useEffect(() => {
    // If either request is not yet fulfilled then we don't get all the users with similar teams
    // Or if for some strange reason the user does not exist inside of the usersInfoById object then return.
    if (!loggedInUserAccountId || !usersById[loggedInUserAccountId]) {
      return;
    }
    const teamMemberIds: number[] = [];

    // Goes through the logged in user's teams
    usersById[loggedInUserAccountId]?.teamIds?.forEach((teamId) => {
      if (!teamsById[teamId]) return;

      // and then goes through each team's members and adds them to the userAccountIds object.
      teamsById[teamId].teamMemberIds?.forEach((memberId: number) => {
        teamMemberIds.push(memberId);
      });
    });

    // Save the teamMemberIds to a new set so that we only have one id per user.
    const teamMemberIdsSet = [...new Set(teamMemberIds)];
    setTeamMembersOnSameTeam(teamMemberIdsSet);
  }, [loggedInUserAccountId, usersById, teamsById]);

  useEffect(() => {
    if (!loggedInUserAccountId) return;
    dispatch(getWorkPlaceInsightsReportByUserId(loggedInUserAccountId));
    dispatch(getSuperpowersAndHiddenStrengths(loggedInUserAccountId));
  }, [loggedInUserAccountId, dispatch]);

  useEffect(() => {
    if (getPendingTestsStatus === "idle") {
      dispatch(getPendingTests());
    }
  }, [getPendingTestsStatus, dispatch]);

  // ------------------------------------- Memos ------------------------------------- //
  const userInfo = useMemo(() => {
    return loggedInUserAccountId ? usersById[loggedInUserAccountId] : null;
  }, [loggedInUserAccountId, usersById]);

  const userTeams = useMemo(() => {
    if (!userInfo?.teamIds) return [];
    // Filter out any teamIds that do not exist in the teamsById object
    return userInfo.teamIds.filter((teamId) => teamsById[teamId]);
  }, [teamsById, userInfo]);

  const radarChart = useMemo(() => {
    const data = workplaceInsightsReport?.traitScores
      ? RADAR_LABEL_ARRAY.map(
          (label) => workplaceInsightsReport.traitScores[label] + 25
        )
      : [];
    const firstName = userInfo?.firstName ?? "";
    const dataSet = {
      ...SINGLE_POPULATION_RADAR_CHART_DATASET,
      data,
      label: firstName,
    };

    return (
      <RadarChart
        canvasId={`${loggedInUserAccountId}-workplace-report-dashboard`}
        dataSets={[dataSet]}
        includeDashedBaseline
        includeGeneralPopulation
        hideCornerLabels
        hideLegend
      />
    );
  }, [workplaceInsightsReport?.traitScores, loggedInUserAccountId, userInfo]);

  // ------------------------------------- Constants ------------------------------------- //
  const userTeamsLength = userTeams.length;

  // ------------------------------------- Handlers ------------------------------------- //
  const onLaunchTeam360 = (incomingId?: number) => {
    if (incomingId) {
      return dispatch(showScheduleAssessmentModalForTeamId(incomingId));
    }
    dispatch(showScheduleAssessmentModal());
  };

  const onScrollNavigation = (path: string) => {
    window.scrollTo(0, 0);
    navigate(path);
  };

  // ------------------------------------- Getters ------------------------------------- //

  const hasPersonalityReport = !!superpowerAndHiddenStrengths?.superPower;
  const getWorkPlaceInsightsCard = () => {
    if (!hasPersonalityReport)
      return (
        <Card className="dashboard-blue-card">
          <h2>My Workplace Insights</h2>
          <div className="padding-20px column-gap-20px align-items-center">
            <div>
              <img src={workplaceInsightsIllustration} alt="Teams" />
            </div>
            <div className="text-center">
              <p>
                Complete this personality test to start learning about your
                Workplace Insights and how you can work better with your teams.
              </p>
              <p>
                If you’ve taken the EPP personality before, link your previous
                results.
              </p>
            </div>
            <div className="row-gap-12px">
              {pendingTests?.[0]?.eventId ? (
                <a
                  href={`${REACT_APP_ODA_URL}verify/index/${pendingTests?.[0]?.eventId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button>Begin Personality Test</Button>
                </a>
              ) : null}
              <a
                href={`${REACT_APP_ODA_URL}link-results`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="secondary-blue" className="white-button">
                  Link Previous Results
                </Button>
              </a>
            </div>
          </div>
        </Card>
      );

    const iconInfo =
      WORKPLACE_INSIGHT_TRAIT_INFO[
        superpowerAndHiddenStrengths?.superPower?.trait
      ];

    return (
      <DashboardOnboardingCard
        title={"My Workplace Superpower"}
        body={
          <Card className={`card-with-left-strip ${iconInfo?.className ?? ""}`}>
            <div
              style={{
                padding: "16px",
                paddingLeft: "24px",
              }}
              className="column-gap-8px"
            >
              <div
                className={`medium-icon-square ${iconInfo?.className ?? ""}`}
              >
                <img
                  src={iconInfo?.icon}
                  alt={"trait"}
                  style={{
                    width: "48px",
                    height: "48px",
                  }}
                />
              </div>
              <h2>{superpowerAndHiddenStrengths?.superPower?.trait}</h2>
              <p>{superpowerAndHiddenStrengths?.superPower?.summary}</p>
              <div>
                <Button
                  variant="secondary-blue"
                  className="border-0"
                  onClick={() => {
                    navigate("/UserGuide?tab=Workplace+Insights");
                  }}
                >
                  See more insights <FontAwesomeIcon icon="chevron-right" />
                </Button>
              </div>
            </div>
          </Card>
        }
        emptyState={null}
      />
    );
  };

  // ------------------------------------- My TEAMscans ------------------------------------- //
  const getTeam360MemberEmptyState = () => (
    <div className="empty-card">
      <span>Curious About Teamwork?</span>
      <p>
        When a team you're on launches the TEAMscan survey, you'll see that
        here. You don't see one now because either none have been launched for
        teams you're on, or you haven't joined any teams yet.
      </p>
      <div className="action-buttons">
        <Button
          variant="secondary-blue"
          onClick={() =>
            dispatch(
              setShowModal({
                eventType: "visitedTeam360TabWithResults",
              })
            )
          }
        >
          See details
        </Button>
      </div>
    </div>
  );

  const getTeam360LeadEmptyState = () => (
    <div className="empty-card">
      <span>How's Your Teamwork?</span>
      <p>
        Launch your first TEAMscan survey now. It will only take a few minutes.
        You can only do this after you've invited team members to this team.
      </p>
      <div className="action-buttons">
        <Button
          onClick={() => {
            onLaunchTeam360();
          }}
        >
          Launch TEAMscan
        </Button>
        <Button
          variant="secondary-blue"
          onClick={() =>
            dispatch(
              setShowModal({
                eventType: "team360LeadViewNotScheduledYet",
              })
            )
          }
        >
          See details
        </Button>
      </div>
    </div>
  );

  const getMyTEAM360sCard = () => {
    const team360Cards = getHasAnyTeam360Results(
      teamsMostRecentAssessments,
      userTeams
    ) ? (
      <Team360TeamsCard userTeamIds={userTeams} />
    ) : null;
    const singleTeamId = userTeamsLength === 1 ? userTeams[0] : undefined;

    return (
      <DashboardOnboardingCard
        title="My TEAMscans"
        emptyState={
          isLeader ? getTeam360LeadEmptyState() : getTeam360MemberEmptyState()
        }
        body={
          team360Cards ? (
            <>
              <p>Here are the teamwork surveys you've been invited to take:</p>
              {team360Cards}
            </>
          ) : null
        }
        primaryButton={{
          isSecondaryColor: true,
          text: "Launch TEAMscan",
          onClick: () => onLaunchTeam360(singleTeamId),
        }}
        secondaryButton={{
          text: "See details",
          onClick: () =>
            dispatch(
              setShowModal({
                eventType: "generalTeam360Information",
                hideActionButton: true,
              })
            ),
        }}
      />
    );
  };

  const getMyPersonalitySnapshotCard = () => {
    return (
      <DashboardOnboardingCard
        title="My Personality Snapshot"
        emptyState={null}
        body={
          <div>
            <hr className="m-0" />
            {radarChart}
            <hr className="m-0" />
          </div>
        }
        primaryButton={{
          isSecondaryColor: true,
          text: "See my workplace insights",
          onClick: () => {
            navigate("/UserGuide?tab=Workplace+Insights");
          },
        }}
      />
    );
  };

  const getMyTeamsCard = () => {
    const pendingTeamLength =
      pendingTeamNotifications?.map((notification) => notification.teamId)
        ?.length ?? 0;

    return (
      <DashboardOnboardingCard
        title="My Teams"
        emptyState={
          <div className="empty-card">
            <span>Waiting For Invite</span>
            <p>
              If you're a team leader, create your first team now. If you're a
              member on a team, but not a team leader, invitations to join any
              teams you're on will be shown here when the team leader adds you.
            </p>
            <div className="action-buttons">
              <Button onClick={() => dispatch(openCreateTeamModal())}>
                Create a team
              </Button>
              <Button
                variant="secondary-blue"
                onClick={() => onScrollNavigation("/Search/Teams")}
              >
                See all teams
              </Button>
            </div>
          </div>
        }
        body={
          userTeamsLength > 0 ? (
            <>
              <p>
                Click a team to view its team guide, team members, and more:
              </p>
              <MyTeamsCard
                teamIds={userTeams}
                userAccountId={loggedInUserAccountId}
              />
            </>
          ) : null
        }
        secondaryButton={{
          text: "Create a team",
          onClick: () => dispatch(openCreateTeamModal()),
        }}
        stepNumberHoverOver={{
          title: "Join your first team",
          body: "Accept or decline team invitations here.",
          timeToComplete: "Estimated time to complete: Less than a minute",
        }}
        footerText={getTeamTotalText(userTeams?.length, pendingTeamLength)}
        headerButton={{
          text: "See All",
          onClick: () => onScrollNavigation(`/Search/Teams`),
        }}
      />
    );
  };

  // ------------------------------------- My Teammates ------------------------------------- //
  const getTeammatesAvatars = (): any[] => {
    const isGreaterThan8 = teamMembersOnSameTeam.length > 8;

    // If more than 8 then we show the first 8 and then a +{number} more
    // If less than 8 then we show all of them
    const avatars = teamMembersOnSameTeam.slice(0, 9).map((userAccountId) => {
      const member = usersById[userAccountId];
      if (!member) return null;
      return (
        <Link
          to={`/UserGuide/${userAccountId}`}
          style={{
            textDecoration: "none",
          }}
        >
          <AvatarCircle
            userAccountId={userAccountId}
            key={userAccountId}
            className="custom-48px"
          />
        </Link>
      );
    });

    if (isGreaterThan8) {
      avatars.push(
        <Link
          to="/Search/People"
          style={{
            textDecoration: "none",
          }}
        >
          <div
            className="avatar-circle medium align-items-center custom-48px"
            key="more"
            style={{
              backgroundColor: "#ECEEF9",
              color: "#202D63",
              fontWeight: "bold",
            }}
          >
            +{teamMembersOnSameTeam.length - 8}
          </div>
        </Link>
      );
    }

    return avatars;
  };

  const getMyTeammatesCard = () => {
    const avatars = getTeammatesAvatars();
    if (avatars.length <= 1) return null;
    return (
      <DashboardOnboardingCard
        title="My Teammates"
        headerButton={{
          text: "See All",
          onClick: () => {
            onScrollNavigation("/Search/People");
          },
        }}
        body={<div className="row-gap-8px">{getTeammatesAvatars()}</div>}
        emptyState={<div>No Teammates</div>}
        footerText={`${teamMembersOnSameTeam.length} total teammate${
          teamMembersOnSameTeam.length === 1 ? "" : "s"
        }`}
        secondaryButton={{
          text: "Invite Member",
          onClick: () => {
            onShowInviteMemberModal();
          },
        }}
      />
    );
  };

  const getMainBody = () => {
    // if the user has work place insights then we show the my teammates card under the My Teams Card on the right.
    if (hasPersonalityReport) {
      return (
        <div className="dashboard-body-v2">
          <div className="dashboard-body-v2-column">
            {getWorkPlaceInsightsCard()}
            {getMyPersonalitySnapshotCard()}
          </div>
          <div className="dashboard-body-v2-column">
            {getMyTEAM360sCard()}
            {getMyTeamsCard()}
            {getMyTeammatesCard()}
          </div>
        </div>
      );
    }

    // if the user does not have work place insights then we show the my teammates card under the My Personality Snapshot Card on the right.
    return (
      <div className="dashboard-body-v2">
        <div className="dashboard-body-v2-column">
          {getWorkPlaceInsightsCard()}
          {getMyTeammatesCard()}
        </div>
        <div className="dashboard-body-v2-column">
          {getMyTEAM360sCard()}
          {getMyTeamsCard()}
        </div>
      </div>
    );
  };

  return getMainBody();
}
