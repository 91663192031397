import { useState } from "react";
import {
  TTeam360Factor,
  Team360AssessmentResult,
  TTeam360Dimension,
} from "./types";
import Button from "app/storybookComponents/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HalfDonutChart from "app/storybookComponents/Charts/HalfDonutChart";
import Collapse from "react-bootstrap/Collapse";
import Team360ResultsCardAccordion from "./Team360ResultsCardAccordion";
import {
  getHighAndLowFactors,
  getTeam360Score,
  getTopOrButtonFactors,
  getVerbalTag,
} from "./helpers";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "utils/redux/hooks";
import { selectTeamAnalyticsText } from "app/containers/TeamGuide/slice";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import HelpPopover from "./HelpPopover";
import { DimensionsAndFactorsTipsAndInterpretiveText } from "app/containers/TeamGuide/types";
import { HIGH_AND_LOW_FACTOR_TEXT } from "./constants";

interface Props {
  tipsAndInterpretiveText?: null | DimensionsAndFactorsTipsAndInterpretiveText;
  teamScores?: Team360AssessmentResult;
  companyScores?: Team360AssessmentResult;
  departmentScores?: Team360AssessmentResult;
  userScores?: Team360AssessmentResult;
  teamId?: number;
  onFactorOrDimensionClick: (
    factor: TTeam360Factor | TTeam360Dimension,
    highOrLow?: "high" | "low"
  ) => void;
  activeFactor?: TTeam360Factor;
  onSeeFullScoreReportClick?: () => void;
  snapShotText?: string;
  hideActionButtons?: boolean;
}

export default function Team360AssessmentResultsCard({
  teamScores,
  companyScores,
  userScores,
  departmentScores,
  teamId,
  onFactorOrDimensionClick,
  activeFactor,
  onSeeFullScoreReportClick,
  snapShotText,
  tipsAndInterpretiveText,
  hideActionButtons,
}: Props) {
  const navigate = useNavigate();
  const teamAnalyticsText = useAppSelector(selectTeamAnalyticsText(teamId));
  const [isExpanded, setIsExpanded] = useState(false);
  const currentScores =
    teamScores || departmentScores || companyScores || userScores;

  const getOverviewText = () => {
    const overviewText = !activeFactor && teamAnalyticsText?.overview?.text;
    // If no overview text or if we are showing the factor view don't show the overview text
    if (!overviewText) return null;
    return (
      <div>
        <p>{overviewText}</p>
      </div>
    );
  };

  const getChartOverview = () => {
    if (!chartScore) return null;
    const { low, high } = getHighAndLowFactors(currentScores);
    const tooltip = (
      <Tooltip
        id="button-tooltip"
        className="light-tooltip no-max-width-tooltip"
      >
        <div className="team-360-score-breakdown-tooltip">
          <div>
            <span>Score: 81 - 100</span> {getVerbalTag(90)}
          </div>
          <div>
            <span>Score: 61 - 80</span> {getVerbalTag(70)}
          </div>
          <div>
            <span>Score: 41 - 60</span> {getVerbalTag(50)}
          </div>
          <div>
            <span>Score: 21 - 40</span> {getVerbalTag(30)}
          </div>
          <div>
            <span>Score: 0 - 20</span> {getVerbalTag(10)}
          </div>
        </div>
      </Tooltip>
    );

    const getEntity = () => {
      if (teamScores) {
        return "team";
      }
      if (departmentScores) {
        return "department";
      }
      return "organization";
    };

    const entity = getEntity();

    return (
      <>
        {getOverviewText()}
        <div className={`team360-results-card-overview`}>
          <div
            style={{
              padding: "24px 34px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            className={`team360-results-card-overview-chart ${
              activeFactor ? "single" : ""
            }`}
          >
            <HalfDonutChart
              canvasId={`${teamId}overallscoregraph`}
              currentVal={Math.round(getTeam360Score(chartScore))}
              label={activeFactor ? `${activeFactor} score` : "Overall Score"}
            />
            <div
              className="d-flex justify-content-center mt-3"
              style={{
                whiteSpace: "nowrap",
              }}
            >
              <div className="team360-results-card-overview-text">
                <div className="row-gap-12px align-items-center">
                  {!activeFactor
                    ? `In this ${entity}, teamwork is a`
                    : `In this ${entity}, ${activeFactor} is a`}
                  <div>{getVerbalTag(getTeam360Score(chartScore))} </div>
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={tooltip}
                  >
                    <FontAwesomeIcon icon={["far", "circle-info"]} />
                  </OverlayTrigger>
                </div>
              </div>
            </div>
          </div>
          {!activeFactor ? (
            <div className="top-and-bottom-factors">
              <div className="padding-16px-12px column-gap-16px">
                <div className="row-gap-8px align-items-center">
                  <span className="top-and-bottom-factors-label">
                    Highest scoring area{" "}
                  </span>
                  <HelpPopover
                    title="Highest Scoring Area"
                    text={HIGH_AND_LOW_FACTOR_TEXT.high}
                  />
                </div>

                {getTopOrButtonFactors(onFactorOrDimensionClick, high, "high")}
              </div>
              <hr />
              <div className="padding-16px-12px column-gap-16px">
                <div className="row-gap-8px align-items-center">
                  <span className="top-and-bottom-factors-label">
                    Lowest scoring area{" "}
                  </span>
                  <HelpPopover
                    title="Lowest Scoring Area"
                    text={HIGH_AND_LOW_FACTOR_TEXT.low}
                  />
                </div>
                {getTopOrButtonFactors(onFactorOrDimensionClick, low, "low")}
              </div>
            </div>
          ) : null}
        </div>
      </>
    );
  };

  const getSnapShotBox = () => {
    // If we are showing the factor view, don't show the snapshot box
    if (activeFactor) {
      return null;
    }

    let text = "";
    if (snapShotText) {
      text = snapShotText;
    } else if (teamAnalyticsText?.vsOrganization?.breakdown) {
      text = teamAnalyticsText.vsOrganization.breakdown;
    }

    return text ? (
      <div className="snapshot-box">
        <FontAwesomeIcon icon={["far", "lightbulb-on"]} />
        <p>{text}</p>
      </div>
    ) : null;
  };

  const getActionButtons = () => {
    if (hideActionButtons) return null;
    if (activeFactor) {
      return (
        <div>
          <Button
            variant="secondary-blue"
            onClick={() => navigate("/SkillsGuide")}
          >
            See skill library
          </Button>
        </div>
      );
    }

    return (
      <div className="row-gap-12px">
        {onSeeFullScoreReportClick ? (
          <Button onClick={onSeeFullScoreReportClick}>
            See full score report
          </Button>
        ) : null}
        <Button
          variant="secondary-blue"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? "Hide score breakdown" : "See score breakdown"}
          <FontAwesomeIcon
            icon={`caret-${isExpanded ? "up" : "down"}`}
            className="ms-2"
          />
        </Button>
      </div>
    );
  };

  const chartScore = activeFactor
    ? currentScores?.factors?.[activeFactor]
    : currentScores?.overall;

  return (
    <div className="team360-results-card">
      {getChartOverview()}
      {getSnapShotBox()}
      <Collapse in={activeFactor ? true : isExpanded}>
        <div>
          {activeFactor ? (
            <span className="factor-name-label">{activeFactor} Dimensions</span>
          ) : null}
          <Team360ResultsCardAccordion
            onAccordionClick={(key) => {
              onFactorOrDimensionClick(key);
            }}
            teamScores={teamScores}
            companyScores={companyScores}
            departmentScores={departmentScores}
            userScores={userScores}
            type={activeFactor}
            tipsAndInterpretiveText={tipsAndInterpretiveText}
          />
        </div>
      </Collapse>
      {getActionButtons()}
    </div>
  );
}
