import { ModuleTemplates } from './types';
import { getEntries } from 'utils/helperFunctions';

// This modified type has the sections as keys and added module type to
export interface ModifiedModuleTemplates {
  [section: string]: ModuleTemplates;
}

export const modifyModuleTemplates = (
  moduleTemplates: ModuleTemplates | null
): ModifiedModuleTemplates => {
  const modifiedModuleTemplates = {} as ModifiedModuleTemplates;
  if (moduleTemplates === null) {
    return modifiedModuleTemplates;
  }
  getEntries(moduleTemplates).forEach(([moduleType, modulesObject]) => {
    Object.values(modulesObject).forEach((moduleTemplate) => {
      // If the section does not yet exist initialize it.
      if (modifiedModuleTemplates[moduleTemplate.section] === undefined) {
        modifiedModuleTemplates[moduleTemplate.section] = {
          'Free Text': {},
          List: {},
        };
      }
      modifiedModuleTemplates[moduleTemplate.section][moduleType][
        moduleTemplate.moduleTemplateId
      ] = moduleTemplate;
    });
  });
  return modifiedModuleTemplates;
};
