import React from "react";
import SurveysTable from "../AdminConsole/Tables/SurveysTable";

interface Props {
  departmentId: number;
}
export default function DepartmentSurveys({ departmentId }: Readonly<Props>) {
  return (
    <div>
      <SurveysTable departmentId={departmentId} />
    </div>
  );
}
