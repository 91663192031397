import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { request } from "utils/request";
import {
  OpenAIGeneratedText,
  OpenAIModuleInfo,
} from "app/components/OpenAIInternalTools/OpenAIGenerator/types";
import { REACT_APP_API_URL } from "utils/environmentVariables";

export interface OpenAIGeneratorState {
  openAIGeneratedText: null | OpenAIGeneratedText;
  gettingOpenAIGeneratedTextStatus: responseStatus;
  creatingModuleStatus: responseStatus;
  updatingModuleStatus: responseStatus;
}

const initialState: OpenAIGeneratorState = {
  openAIGeneratedText: null,
  gettingOpenAIGeneratedTextStatus: "idle",
  creatingModuleStatus: "idle",
  updatingModuleStatus: "idle",
};

export const createModule = createAsyncThunk(
  "createModule",
  async (payload: {
    title: string;
    backgroundContext: string;
    prompt: string;
    backupPrompt: string;
    freeTextInputs: string;
    tokensRequired: number;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/openAI/modules`;
    return (await request(requestUrl, {
      method: "POST",
      body: JSON.stringify({ payload }),
    })) as OpenAIModuleInfo;
  }
);

export const updateModule = createAsyncThunk(
  "updateModule",
  async ({
    moduleId,
    payload,
  }: {
    moduleId: number;
    payload: {
      title: string;
      backgroundContext: string;
      prompt: string;
      backupPrompt: string;
      freeTextInputs: string;
      tokensRequired: number;
    };
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/openAI/modules/${moduleId}`;
    return (await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify({ payload }),
    })) as OpenAIModuleInfo;
  }
);

export const getOpenAIGeneratedText = createAsyncThunk(
  "generateOpenAIGeneratedText",
  async (payload: {
    title: string;
    backgroundContext: string;
    prompt: string;
    backupPrompt: string;
    tokensRequired: number;
    freeTextInputs: string;
    sampleUserInput: string;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/modules/generate`;
    return (await request(requestUrl, {
      method: "POST",
      body: JSON.stringify({ payload }),
    })) as OpenAIGeneratedText;
  }
);

export const openAIGeneratorSlice = createSlice({
  name: "openAIGenerator",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createModule.pending, (state) => {
        state.creatingModuleStatus = "loading";
      })
      .addCase(updateModule.pending, (state) => {
        state.updatingModuleStatus = "loading";
      })
      .addCase(getOpenAIGeneratedText.pending, (state) => {
        state.gettingOpenAIGeneratedTextStatus = "loading";
      })
      .addCase(
        getOpenAIGeneratedText.fulfilled,
        (state, action: PayloadAction<OpenAIGeneratedText>) => {
          return {
            ...state,
            gettingOpenAIGeneratedTextStatus: "succeeded",
            openAIGeneratedText: { ...action.payload },
          };
        }
      )
      .addCase(createModule.fulfilled, (state) => {
        state.creatingModuleStatus = "succeeded";
      })
      .addCase(updateModule.fulfilled, (state) => {
        state.updatingModuleStatus = "succeeded";
      })
      .addCase(getOpenAIGeneratedText.rejected, (state) => {
        state.gettingOpenAIGeneratedTextStatus = "failed";
      })
      .addCase(createModule.rejected, (state) => {
        state.creatingModuleStatus = "failed";
      })
      .addCase(updateModule.rejected, (state) => {
        state.updatingModuleStatus = "failed";
      });
  },
});

export const selectOpenAIGeneratedText = (state: RootState) =>
  state.openAiGenerator.openAIGeneratedText;
export const selectGetOpenAIGeneratedTextStatus = (state: RootState) =>
  state.openAiGenerator.gettingOpenAIGeneratedTextStatus;

export const selectCreatingModuleStatus = (state: RootState) =>
  state.openAiGenerator.creatingModuleStatus;

export const selectUpdatingModuleStatus = (state: RootState) =>
  state.openAiGenerator.updatingModuleStatus;

export default openAIGeneratorSlice.reducer;
