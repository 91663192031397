import { Card, Collapse } from "react-bootstrap";
import Button from "app/storybookComponents/Button";
import { GenderInsightsResponse } from "app/containers/DepartmentInsightReport/types";
import HelpPopover from "../Team360Assessment/HelpPopover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode, useState } from "react";

interface Props {
  genderInsights: GenderInsightsResponse | null;
  title?: string;
  defaultExpanded?: boolean;
  secondDropdown?: ReactNode;
  isCardCollapseByDefault?: boolean;
}

export default function GenderInsightsCard({
  genderInsights,
  title = "Gender Insights",
  defaultExpanded,
  secondDropdown,
  isCardCollapseByDefault = false,
}: Props) {
  // -------------------------- States --------------------------
  const [isExpanded, setIsExpanded] = useState(!!defaultExpanded);
  const [isCardCollapsed, setIsCardCollapsed] = useState(
    isCardCollapseByDefault
  );

  // -------------------------- Guard Clause --------------------------
  if (!genderInsights) return null;

  // -------------------------- Helper functions --------------------------
  const getKeyInsights = (text: string) => {
    return (
      <div className="snapshot-box" style={{ marginTop: "15px" }}>
        <FontAwesomeIcon icon={["fal", "lightbulb-on"]} />
        <p>{text}</p>
      </div>
    );
  };

  const getSectionHeader = (title: string, text: string) => {
    return (
      <div style={{ fontWeight: "bold" }}>
        {title} <HelpPopover title={title} text={text} />
      </div>
    );
  };

  const getScoreDiv = (
    score: number | null,
    label: string,
    isBlue?: boolean
  ) => {
    if (!score) {
      return null;
    }

    return (
      <div className={`short-graph-bar ${isBlue ? "my" : "your"}-score`}>
        <span
          style={{
            width: `${score}%`,
          }}
          className="bar"
        />
        <span className="ms-2">{label}</span>
        <span className="fw-bold">{score}</span>
      </div>
    );
  };

  const getTeamPerformanceByGender = () => {
    const teamworkScoresByGender = genderInsights.teamworkScoresByGender;
    if (!teamworkScoresByGender) {
      return null;
    }
    const femaleScore = teamworkScoresByGender.female || 0;
    const maleScore = teamworkScoresByGender.male || 0;
    const nonBinaryScore = teamworkScoresByGender.nonBinary || 0;
    const maxScore = Math.max(femaleScore, maleScore, nonBinaryScore);
    return (
      <div className="short-graph">
        {getSectionHeader(
          "Average rating of team performance based on gender",
          teamworkScoresByGender.text
        )}
        {getScoreDiv(
          teamworkScoresByGender.female,
          teamworkScoresByGender.femaleLabel,
          maxScore === femaleScore
        )}
        {getScoreDiv(
          teamworkScoresByGender.male,
          teamworkScoresByGender.maleLabel,
          maxScore === maleScore
        )}
        {getScoreDiv(
          teamworkScoresByGender.nonBinary,
          teamworkScoresByGender.nonBinaryLabel,
          maxScore === nonBinaryScore
        )}
        {getKeyInsights(teamworkScoresByGender.keyInsights)}
      </div>
    );
  };

  const getTeamPerformanceByTeamLeaderGender = () => {
    const teamworkScoresByTeamLeadGender =
      genderInsights.teamworkScoresByTeamLeadGender;
    if (!teamworkScoresByTeamLeadGender) {
      return null;
    }
    const femaleScore = teamworkScoresByTeamLeadGender.female || 0;
    const maleScore = teamworkScoresByTeamLeadGender.male || 0;
    const nonBinaryScore = teamworkScoresByTeamLeadGender.nonBinary || 0;
    const maxScore = Math.max(femaleScore, maleScore, nonBinaryScore);
    return (
      <div className="short-graph">
        {getSectionHeader(
          "Average rating of team performance based on team leadership",
          teamworkScoresByTeamLeadGender.text
        )}
        {getScoreDiv(
          teamworkScoresByTeamLeadGender.female,
          teamworkScoresByTeamLeadGender.femaleLabel,
          maxScore === femaleScore
        )}
        {getScoreDiv(
          teamworkScoresByTeamLeadGender.male,
          teamworkScoresByTeamLeadGender.maleLabel,
          maxScore === maleScore
        )}
        {getScoreDiv(
          teamworkScoresByTeamLeadGender.nonBinary,
          teamworkScoresByTeamLeadGender.nonBinaryLabel,
          maxScore === nonBinaryScore
        )}
        {getKeyInsights(teamworkScoresByTeamLeadGender.keyInsights)}
      </div>
    );
  };

  const getTeamPerformanceByGenderComposition = () => {
    if (!genderInsights.teamworkScoresByGenderMix) {
      return null;
    }

    const maxScore = Math.max(
      genderInsights.teamworkScoresByGenderMix.mixed ?? 0,
      genderInsights.teamworkScoresByGenderMix.singleGender ?? 0
    );

    return (
      <div className="short-graph">
        {getSectionHeader(
          "Average rating of team performance based on gender composition",
          genderInsights.teamworkScoresByGenderMix.text
        )}
        {getScoreDiv(
          genderInsights.teamworkScoresByGenderMix.mixed,
          genderInsights.teamworkScoresByGenderMix.mixedLabel,
          maxScore === genderInsights.teamworkScoresByGenderMix.mixed
        )}
        {getScoreDiv(
          genderInsights.teamworkScoresByGenderMix.singleGender,
          genderInsights.teamworkScoresByGenderMix.singleGenderLabel,
          maxScore === genderInsights.teamworkScoresByGenderMix.singleGender
        )}
        {getKeyInsights(genderInsights.teamworkScoresByGenderMix.keyInsights)}
      </div>
    );
  };

  const cardArray = [
    getTeamPerformanceByGender(),
    getTeamPerformanceByTeamLeaderGender(),
    getTeamPerformanceByGenderComposition(),
  ].filter((x) => x);

  return (
    <Card
      className="p-4 analytics-summary-card"
      role={isCardCollapsed ? "button" : undefined}
      onClick={() => {
        if (!isCardCollapsed) return;
        setIsCardCollapsed(false);
      }}
    >
      <div className="d-flex justify-content-between align-items-start">
        <div>
          <h2>{title}</h2>
        </div>
        <div>
          <Button
            onClick={() => {
              setIsCardCollapsed(!isCardCollapsed);
            }}
            variant="secondary-blue"
            className="border-0"
          >
            {isCardCollapsed ? "See details" : "Collapse"}
            <FontAwesomeIcon
              icon={`caret-${isCardCollapsed ? "down" : "up"}`}
              className="ms-2"
            />
          </Button>
        </div>
      </div>
      <Collapse in={!isCardCollapsed}>
        <div className="column-gap-20px">
          <div>
            <p>{genderInsights.genderAnalysisText}</p>
          </div>
          {secondDropdown ? <>{secondDropdown}</> : null}
          <div className="column-gap-20px">
            {cardArray.slice(0, 1)}
            {cardArray.length > 1 ? (
              <Collapse in={isExpanded}>
                <div className="column-gap-20px">
                  {cardArray.slice(1).map((elm) => (
                    <>
                      <hr className="m-0" />
                      {elm}
                    </>
                  ))}
                </div>
              </Collapse>
            ) : null}
          </div>
          {cardArray.length > 1 ? (
            <div>
              <Button
                onClick={() => setIsExpanded(!isExpanded)}
                variant="secondary-blue"
              >
                {isExpanded ? "Collapse" : "Show more insights"}
                <FontAwesomeIcon
                  icon={`caret-${isExpanded ? "up" : "down"}`}
                  className="ms-2"
                />
              </Button>
            </div>
          ) : null}
        </div>
      </Collapse>
    </Card>
  );
}
