import { sendReminder } from "app/containers/Dashboard/slice";
import { useAppDispatch } from "utils/redux/hooks";
import Button from "app/storybookComponents/Button";
import Select from "react-select";
import SimpleModal from "./SimpleModal";
import { Form } from "react-bootstrap";
import { getSelectProps } from "utils/helperFunctions";
import { useCallback, useMemo, useState } from "react";
import { PendingTeamLeader } from "app/containers/Dashboard/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MultiCardCollapsibleContainer from "app/storybookComponents/CollapsibleCards/MultiCardCollapsibleContainer";

interface Props {
  onHide: () => void;
  show?: boolean;
  onShowInviteTeamLeaderModal?: () => void;
  pendingTeamLeaders?: PendingTeamLeader[];
}

export default function PendingTeamsModal({
  show,
  onHide,
  onShowInviteTeamLeaderModal,
  pendingTeamLeaders = [],
}: Readonly<Props>) {
  const dispatch = useAppDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [remindedMembers, setRemindedMembers] = useState<Set<number>>(
    new Set()
  );

  const getPendingTeamLeaderElement = useCallback(
    (teamLeader: PendingTeamLeader, key: number) => {
      const { firstName, lastName, emailAddress, userAccountId } = teamLeader;
      const isReminded = remindedMembers.has(userAccountId);
      const name = `${firstName} ${lastName}`.trim();
      return (
        <div className="section-body-row" key={key}>
          <div
            className="d-flex gap-2 align-items-center"
            style={{ color: "#53565a" }}
          >
            <div
              style={{
                width: "30px",
                textAlign: "center",
                fontSize: "24px",
              }}
            >
              <FontAwesomeIcon icon={["fal", "clock"]} />
            </div>
            <div className="section-body-name-and-description">
              <span className="section-body-row-name">
                Invite Sent to {name}
              </span>
              <span className="section-body-row-description">
                Team leader email: {emailAddress}
              </span>
            </div>
          </div>
          <div className="d-flex gap-2 align-items-center">
            {!isReminded ? (
              <Button
                className="nowrap"
                variant="secondary-blue"
                onClick={() => {
                  dispatch(
                    sendReminder({
                      userAccountId,
                      reminderType: "remindTeamLeader",
                    })
                  );
                  setRemindedMembers((prevIds) =>
                    new Set(prevIds).add(userAccountId)
                  );
                }}
              >
                Remind
              </Button>
            ) : (
              <span className="label-tag grey nowrap">Reminder sent</span>
            )}
          </div>
        </div>
      );
    },
    [dispatch, remindedMembers]
  );

  const teamMembers = useMemo(
    () =>
      pendingTeamLeaders
        .filter((teamLeader) => {
          return (
            teamLeader?.firstName
              ?.toLowerCase()
              .includes(searchInput.toLowerCase()) ??
            teamLeader.lastName
              ?.toLowerCase()
              .includes(searchInput.toLowerCase()) ??
            teamLeader.emailAddress
              ?.toLowerCase()
              .includes(searchInput.toLowerCase())
          );
        })
        .map((teamLeader, idx) => getPendingTeamLeaderElement(teamLeader, idx)),
    [pendingTeamLeaders, searchInput, getPendingTeamLeaderElement]
  );

  const getEmptyCard = () => {
    if (teamMembers.length === 0) {
      return (
        <div
          className="empty-card"
          style={{
            padding: "32px",
          }}
        >
          <div className="column-gap-12px">
            <span>
              <b>
                {searchInput
                  ? `No pending team found matching "${searchInput}"`
                  : `No team leaders have been invited yet`}
              </b>
            </span>
            <p>
              {searchInput
                ? "Try searching by another team or team leader name"
                : null}
            </p>
          </div>
        </div>
      );
    }
    return null;
  };

  const { selectStyles, components } = getSelectProps("search");
  return (
    <SimpleModal title="Pending Team Leaders" onHide={onHide} show={show}>
      <Form.Group className="w-100">
        <Select
          placeholder={"Search by name or email..."}
          isClearable={true}
          isSearchable={true}
          components={components}
          inputValue={searchInput}
          styles={selectStyles}
          menuIsOpen={false}
          onInputChange={(e, actionMeta) => {
            if (actionMeta.action === "input-change") {
              setSearchInput(e);
            }
          }}
        />
      </Form.Group>
      <MultiCardCollapsibleContainer
        items={teamMembers}
        collapsedText="See all pending leaders"
      />
      {getEmptyCard()}
      <div className="d-flex justify-content-between align-items-center">
        {onShowInviteTeamLeaderModal ? (
          <Button
            variant="secondary-blue"
            onClick={onShowInviteTeamLeaderModal}
          >
            Invite a team leader
          </Button>
        ) : null}
        <p className="ms-auto">
          {pendingTeamLeaders.length} pending team
          {pendingTeamLeaders.length === 0 ? "" : "s"}
        </p>
      </div>
    </SimpleModal>
  );
}
