import { ReactNode } from 'react';
import Button from 'app/storybookComponents/Button';
import { Card } from 'react-bootstrap';

interface Props {
  show: boolean;
  title?: string;
  children?: ReactNode;
  onHide: () => void;
  className?: string;
}

export default function SideDrawer({
  show,
  children,
  title,
  onHide,
  className,
}: Props) {
  return (
    <Card className={`side-drawer${show ? ' active' : ''} ${className ?? ''}`}>
      <div className="d-flex justify-content-between">
        <h2>{title}</h2>
        <Button
          onClick={() => onHide()}
          variant={'secondary-blue'}
          style={{ border: 'none', width: 'auto' }}
          xIcon
        />
      </div>
      {children}
    </Card>
  );
}
