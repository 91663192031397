import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { request } from "utils/request";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import { ChatbotMessages, ChatbotResponse, ChatbotPayload } from "./types";

// ------------------ State Type/Structure ------------------
export interface ChatbotState {
  chatbotMessages: ChatbotMessages | null;
  threadId: string | null;
  sendMessageStatus: responseStatus;
}

// ------------------ InitialState ------------------
const initialState: ChatbotState = {
  chatbotMessages: null,
  threadId: null,
  sendMessageStatus: "idle",
};

// ------------------ Asynchronous API calls ------------------
export const sendMessage = createAsyncThunk(
  "chatbot/sendMessage",
  async (payload: ChatbotPayload) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/chatbot`;
    return (await request(requestUrl, {
      method: "POST",
      body: JSON.stringify(payload),
    })) as ChatbotResponse;
  }
);

// ------------------ Beginning of Slice Definition ------------------
export const chatbotReducer = createSlice({
  name: "chatbot",
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendMessage.pending, (state) => {
        state.sendMessageStatus = "loading";
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.sendMessageStatus = "succeeded";
        state.chatbotMessages = action.payload.messages;
        state.threadId = action.payload.threadId;
      })
      .addCase(sendMessage.rejected, (state) => {
        state.sendMessageStatus = "failed";
      });
  },
});

// ------------------ Selectors ------------------
export const resetState = chatbotReducer.actions.resetState;
export const selectChatbotMessages = (state: RootState) =>
  state.chatbot.chatbotMessages;
export const selectThreadId = (state: RootState) => state.chatbot.threadId;
export const selectSendMessageStatus = (state: RootState) =>
  state.chatbot.sendMessageStatus;

export default chatbotReducer.reducer;
