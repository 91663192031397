import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  readonly prompt: string;
  readonly handleSendMessage: (message: string) => void;
}

export default function Prompt({ prompt, handleSendMessage }: Props) {
  return (
    <button
      className="chatbot-prompt"
      onClick={() => {
        handleSendMessage(prompt);
      }}
    >
      <div className="chatbot-prompt-text">{prompt}</div>
      <FontAwesomeIcon
        icon="chevron-right"
        style={{
          float: "right",
        }}
      />
    </button>
  );
}
