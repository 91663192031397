import { AssessmentInstance } from "app/containers/Assessment/types";
import { Month, TimeInterval } from "./types";
import { Dictionary } from "@reduxjs/toolkit";

export const getEndDateForAggregatePicker = (
  timeInterval: TimeInterval,
  today: Date = new Date()
) => {
  switch (timeInterval) {
    case "Over the past year":
      return new Date(
        today.getFullYear() - 1,
        today.getMonth(),
        today.getDate()
      );

    case "Over the past month":
      return new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        today.getDate()
      );

    case "Over the past 3 months":
      return new Date(
        today.getFullYear(),
        today.getMonth() - 3,
        today.getDate()
      );

    case "Over the past 6 months":
      return new Date(
        today.getFullYear(),
        today.getMonth() - 6,
        today.getDate()
      );
    default:
      return null;
  }
};

export const getInstancesInDateRange = (
  dateInstances: {
    startDate: string;
    endDate: string;
    assessmentInstanceId: number;
  }[] = [],
  monthSelected?: string | null,
  yearSelected?: null | number
): {
  startDate: string;
  endDate: string;
  assessmentInstanceId: number;
}[] => {
  if (dateInstances.length === 0) return dateInstances;
  // If the user has not selected a month or year then we need to return all instances
  // Stores in reverse order so that we can iterate over them in the correct order, so that as we iterate over them we can add them to the correct date
  const instancesMap = new Map<
    string,
    {
      startDate: string;
      endDate: string;
      assessmentInstanceId: number;
    }
  >();

  // First we need to filter the instances based on the month and year selected
  const filteredDateInstances = filterDateInstances(
    dateInstances,
    monthSelected,
    yearSelected
  );

  // Then we need to sort the instances by start date
  const sortedInstances = [...filteredDateInstances].sort(
    (a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
  );

  // Then we add them to our map
  sortedInstances.forEach((instance) => {
    const dateString = getStringDate(new Date(instance.startDate));
    instancesMap.set(dateString, {
      startDate: instance.startDate,
      endDate: instance.endDate,
      assessmentInstanceId: instance.assessmentInstanceId,
    });
  });

  return Array.from(instancesMap.values());
};

const filterDateInstances = (
  dateInstances: {
    startDate: string;
    endDate: string;
    assessmentInstanceId: number;
  }[] = [],
  monthSelected?: string | null,
  yearSelected?: null | number
): {
  startDate: string;
  endDate: string;
  assessmentInstanceId: number;
}[] => {
  // If neither a month or year is selected then we need to return all instances
  if (!monthSelected && !yearSelected) return dateInstances;
  return dateInstances.filter((instance) => {
    const instanceDate = new Date(instance.startDate);
    const instanceMonth = instanceDate.toLocaleString("en-US", {
      month: "long",
    });
    const instanceYear = instanceDate.getFullYear();

    // If the user selected both a month and a year then we need to return all instances that fall within that month and year
    if (monthSelected && yearSelected) {
      return instanceMonth === monthSelected && instanceYear === yearSelected;
    }

    // If the user has only selected a month then we need to return all instances that fall within that month regardless of year
    if (monthSelected) {
      return instanceMonth === monthSelected;
    }

    // If the user selected a year then we need to return all instances that fall within that year regardless of month
    if (yearSelected) {
      return instanceYear === yearSelected;
    }

    return true;
  });
};

export const getAllAvailableYears = (
  dateInstances: {
    startDate: string;
    endDate: string;
  }[] = []
) => {
  const years = dateInstances.map((instance) => {
    const instanceDate = new Date(instance.startDate);
    return instanceDate.getFullYear();
  });
  return [...new Set(years)];
};

export const getAllAvailableMonths = (
  dateInstances: {
    startDate: string;
    endDate: string;
  }[] = []
) => {
  const months = dateInstances.map((instance) => {
    const instanceDate = new Date(instance.startDate);
    return instanceDate.toLocaleString("en-US", { month: "long" });
  });
  return [...new Set(months)] as Month[];
};

export const getAppendedOpenAIUrl = ({
  startDate,
  endDate,
  instance,
  teamId,
  departmentId,
}: {
  startDate?: string;
  endDate?: string;
  instance?: string;
  teamId?: number;
  departmentId?: number;
}) => {
  let string = "";
  if (instance) string = addParameter(string, `instance=${instance}`);
  if (teamId) string = addParameter(string, `teamId=${teamId}`);
  if (departmentId)
    string = addParameter(string, `departmentId=${departmentId}`);
  if (!startDate || !endDate) return string;
  return addParameter(string, `startDate=${startDate}&endDate=${endDate}`);
};

export const getAppendedUrl = ({
  startDate,
  endDate,
  instance,
}: {
  startDate?: string;
  endDate?: string;
  instance?: string;
}) => {
  if (instance) return `?instance=${instance}`;
  if (!startDate || !endDate) return "";
  return `?startDate=${startDate}&endDate=${endDate}`;
};

export const addParameter = (url: string, param: string) => {
  if (url === "") {
    return `?${param}`;
  } else {
    return `${url}&${param}`;
  }
};

export const getStringDate = (date: Date) =>
  date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
