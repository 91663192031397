import Button from 'app/storybookComponents/Button';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

interface Props {
  title: string;
  bodyText: string;
  popoverFooter?: React.JSX.Element | null;
  showDismiss?: boolean;
  children?: React.JSX.Element;
}
export default function DashboardHelpPopover({
  title,
  bodyText,
  popoverFooter = null,
  showDismiss,
  children,
}: Props) {
  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(!show);
  };

  const overlay = (
    <Popover
      className="team-360-popover"
      style={{ minWidth: '400px', padding: '16px' }}
    >
      <div className="column-gap-16px">
        <div className="d-flex justify-content-between align-items-center">
          <p>
            <b>{title}</b>
          </p>
          <Button
            xIcon
            variant="secondary-gray"
            className="border-0"
            size="sm"
            onClick={() => setShow(false)}
          />
        </div>
        <p>{bodyText}</p>
        {showDismiss ? (
          <div>
            <Button variant="secondary-blue" onClick={() => setShow(false)}>
              Dismiss
            </Button>
          </div>
        ) : null}
        {popoverFooter}
      </div>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="auto"
      overlay={overlay}
      rootClose={true}
      show={show}
      onToggle={handleClick}
    >
      {children ? (
        <div role="button">{children}</div>
      ) : (
        <FontAwesomeIcon
          icon={['far', 'circle-info']}
          role="button"
          style={{ fontSize: '16px' }}
        />
      )}
    </OverlayTrigger>
  );
}
