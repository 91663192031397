// ------------------------------------- Modal Types -------------------------------------------
export type ModalType = null | "welcome" | "add-module" | "edit-module";

// ------------------------------------- Guide Type -------------------------------------------
export type GuideType = "user" | "team" | "company";
export type Guide = {
  content: {
    [sectionId: number]: Section;
  };
  coverPhoto: { base64?: string | null; picture?: string | null };
  profilePicture: { base64?: string | null; picture?: string | null };
};

// ------------------------------------- Section Type -------------------------------------------
export type Section = {
  moduleOrder: string;
  modules: {
    [talentInsightsModuleId: number]: {
      link?: string;
      moduleContent: string;
      moduleTemplateId: number;
      moduleType: ModuleType;
      talentInsightsModuleId: number;
      title: string;
    };
  };
  sectionId: number;
  title: string;
};

export type UsedModuleTemplateIds = {
  "Free Text": number[];
  List: number[];
};

// ------------------------------------- Module Templates -------------------------------------------
export interface ModuleTemplates {
  "Free Text": { [moduleTemplateId: string]: FreeTextModuleTemplate };
  List: { [moduleTemplateId: string]: ListModuleTemplate };
}

// ------------------------------------- Module Type -------------------------------------------
export type ModuleType = "Free Text" | "List";
export const isModuleType = (
  incomingModuleType: any
): incomingModuleType is ModuleType => {
  const incomingModule = incomingModuleType as ModuleType;
  return incomingModule === "Free Text" || incomingModule === "List";
};

// ------------------------------------- Base Module -------------------------------------------
// The Type for the actual module content, so aka what the user saved.
export interface BaseModule {
  section: string;
  title: string;
  moduleType: string;
  link: string;
}

export const isBaseModule = (module: any): module is BaseModule => {
  const incomingModule = module as BaseModule;
  return (
    incomingModule.section !== undefined &&
    incomingModule.title !== undefined &&
    incomingModule.link !== undefined &&
    incomingModule.moduleType !== undefined
  );
};

// ------------------------------------- Free Text Module -------------------------------------------
export interface FreeTextModule extends BaseModule {
  moduleContent: string;
  moduleType: "Free Text";
}

export const isFreeTextModule = (module: any): module is FreeTextModule => {
  const incomingModule = module as FreeTextModule;
  return (
    isBaseModule(incomingModule) &&
    incomingModule.moduleType === "Free Text" &&
    incomingModule.moduleContent !== undefined
  );
};

// ------------------------------------- List Module -------------------------------------------
export interface ListModule extends BaseModule {
  moduleContent: string[];
  moduleType: "List";
}
export const isListModule = (module: any): module is ListModuleTemplate => {
  const incomingModule = module as ListModule;
  return (
    isBaseModule(incomingModule) &&
    incomingModule.moduleType === "List" &&
    Array.isArray(incomingModule.moduleContent)
  );
};

// ------------------------------------- Base Module Template -------------------------------------------
// This contains all of the values that are shared amongst all of the modules
export interface BaseModuleTemplate {
  modulePriorityOrder?: number;
  shortDescription: string;
  description: string;
  linkAllowed: 0 | 1;
  moduleTemplateId: number;
  section: string;
  title: string;
  iconName?: string;
}
export const isBaseModuleTemplate = (module: any): module is BaseModule => {
  const incomingModule = module as BaseModuleTemplate;
  return (
    incomingModule.section !== undefined &&
    incomingModule.linkAllowed !== undefined &&
    incomingModule.moduleTemplateId !== undefined &&
    incomingModule.title !== undefined &&
    incomingModule.description !== undefined
  );
};

// ------------------------------------- Free Text Module Template -------------------------------------------
// Key/Values unique to Free Text Modules
export interface FreeTextModuleTemplate extends BaseModuleTemplate {
  characterLimit: string;
  placeholder: string;
}
export const isModuleFreeTextType = (
  module: any
): module is FreeTextModuleTemplate => {
  const incomingModule = module as FreeTextModuleTemplate;
  return (
    isBaseModuleTemplate(incomingModule) &&
    incomingModule.characterLimit !== undefined &&
    incomingModule.placeholder !== undefined
  );
};

// ------------------------------------- List Module Template -------------------------------------------
// Key/Values unique to List Modules
export interface ListModuleTemplate extends BaseModuleTemplate {
  perItemCharacterLimit: number;
  prefilled: string[];
  totalItemLimit: number;
}

export const isModuleListType = (
  module: FreeTextModuleTemplate | ListModuleTemplate
): module is ListModuleTemplate => {
  const incomingModule = module as ListModuleTemplate;
  return (
    isBaseModuleTemplate(incomingModule) &&
    incomingModule.perItemCharacterLimit !== undefined &&
    incomingModule.prefilled !== undefined &&
    incomingModule.totalItemLimit !== undefined
  );
};

// ------------------------------------- Module Endpoint Payload/Response -------------------------------------------
export type AddModulePayload = {
  link: string;
  moduleContent: string;
  moduleTemplateId?: number;
  moduleType: string;
  section: string;
  title: string;
};

export type EditModulePayload = {
  talentInsightsModuleId: number;
  title: string;
  moduleContent: string;
  link: string;
};

export type EditModuleResponse = {
  link: string;
  moduleContent: string;
  moduleTemplateId: number;
  moduleType: ModuleType;
  sectionId: number;
  talentInsightsModuleId: number;
  title: string;
};
