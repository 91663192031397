import { Card, Form } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import Button from "app/storybookComponents/Button";
import {
  getGeneratedText,
  selectGeneratedText,
  selectGettingTextGenerationResponseStatus,
} from "./slice";
import { useState } from "react";
import { freeTextInputs } from "./types";

interface Props {
  promptId: number;
  title: string;
  userInput: freeTextInputs;
}

export function OpenAIModuleResponseContent({ status, response }: any) {
  switch (status) {
    case "loading":
      return <p>Loading...</p>;
    case "failed":
      return <p>Failed to get generated text</p>;
    default:
      return <p>{response}</p>;
  }
}

export default function OpenAIModule({ promptId, title, userInput }: Props) {
  const dispatch = useAppDispatch();
  const generatedText = useAppSelector(selectGeneratedText);
  const gettingTextGenerationResponseStatus = useAppSelector(
    selectGettingTextGenerationResponseStatus
  );
  const [payload, setPayload] = useState({});

  const generateText = () => {
    dispatch(
      getGeneratedText({
        promptId: promptId,
        userInput: payload,
      })
    );
  };

  const getUserInputFields = () => {
    if (!userInput) {
      return null;
    }
    return Object.keys(userInput).map((key) => {
      const title = userInput[key].displayTitle;
      const placeholder = userInput[key].placeholder
        ? userInput[key].placeholder
        : "";
      return (
        <Form.Group className="ai-module-row" key={key}>
          <Form.Label>{title}</Form.Label>
          <Form.Control
            style={{ height: "auto", width: "500px" }}
            as="textarea"
            placeholder={placeholder}
            onChange={(e) => {
              setPayload({
                ...payload,
                [userInput[key].value]: e.target.value,
              });
            }}
          />
        </Form.Group>
      );
    });
  };

  const getContent = () => {
    switch (gettingTextGenerationResponseStatus) {
      case "loading":
        return <p>Loading...</p>;
      case "failed":
        return <p>Failed to get response from OpenAI</p>;
      default:
        return <p>{generatedText ? generatedText.response : null}</p>;
    }
  };

  return (
    <Card className="ai-module">
      <div className="ai-module-title-row">
        <h2>{title}</h2>
        <div className="beta-badge">Beta</div>
      </div>
      {getContent()}
      {getUserInputFields()}
      <div className="ai-module-row">
        <Button
          onClick={generateText}
          disabled={gettingTextGenerationResponseStatus === "loading"}
        >
          {generatedText ? "Generate another" : "Generate " + title}
        </Button>
      </div>
    </Card>
  );
}
