import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  high?: number;
  medium?: number;
  low?: number;
  starValue?: 'high' | 'medium' | 'low';
}

export default function StackBarChart({
  high = 0,
  medium = 0,
  low = 0,
  starValue,
}: Props) {
  const getGridColumnValue = (value: number) =>
    value ? ` ${value}fr` : ' 25px';

  const getBar = (className: string, value: number) => {
    return (
      <div className={`${className}`}>
        {value}
        {starValue === className ? (
          <FontAwesomeIcon icon="star" className="ms-1" />
        ) : null}
      </div>
    );
  };

  let gridTemplateColumns = '';
  gridTemplateColumns += getGridColumnValue(low);
  gridTemplateColumns += getGridColumnValue(medium);
  gridTemplateColumns += getGridColumnValue(high);

  return (
    <div
      style={{
        gridTemplateColumns,
      }}
      className="stack-bar-chart"
    >
      {getBar('low', low)}
      {getBar('medium', medium)}
      {getBar('high', high)}
    </div>
  );
}
