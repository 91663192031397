import { Modal } from "react-bootstrap";
import Button from "app/storybookComponents/Button";
import React from "react";

interface Props {
  modalTitle: string;
  hideModal: () => void;
  onConfirmClick: () => void;
  isOpen: boolean;
  warningTitle: string;
  warningMessage: string;
  customButtonText?: string;
  isInProgress?: boolean;
  isDanger?: boolean;
  extraBody?: React.ReactNode;
}

export default function WarningModal({
  hideModal,
  onConfirmClick,
  modalTitle,
  isOpen,
  warningTitle,
  warningMessage,
  customButtonText = "Delete", // Default value
  isInProgress,
  isDanger = true,
  extraBody = null,
}: Props) {
  return (
    <Modal show={isOpen} onHide={hideModal} size="lg">
      <div className="warning-modal">
        <div className="warning-modal-header">
          <h2>{modalTitle}</h2>
          <Button
            onClick={hideModal}
            variant={"secondary-blue"}
            style={{ border: "none", width: "auto" }}
            xIcon
          />
        </div>
        <div className="warning-modal-body">
          <h3>{warningTitle}</h3>
          <p>{warningMessage}</p>
          {extraBody}
        </div>
        <div className="warning-modal-buttons align-self-center">
          <Button variant="secondary-blue" onClick={hideModal} className="me-2">
            Cancel
          </Button>
          <Button
            variant={isDanger ? "danger" : "primary"}
            onClick={onConfirmClick}
            disabled={isInProgress}
          >
            {customButtonText}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
