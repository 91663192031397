import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { request } from "utils/request";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import { OpenAIUniversalVariables } from "app/components/OpenAIInternalTools/UniversalVariables/types";

export interface UniversalVariablesState {
  universalVariables: null | OpenAIUniversalVariables;
  gettingUniversalVariables: responseStatus;
}

const initialState: UniversalVariablesState = {
  universalVariables: null,
  gettingUniversalVariables: "idle",
};

export const getUniversalVariables = createAsyncThunk(
  "getUniversalVariables",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/openAI/universalVariables`;
    return (await request(requestUrl)) as OpenAIUniversalVariables;
  }
);

export const universalVariablesSlice = createSlice({
  name: "universalVariables",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUniversalVariables.pending, (state) => {
        state.gettingUniversalVariables = "loading";
      })
      .addCase(
        getUniversalVariables.fulfilled,
        (state, action: PayloadAction<OpenAIUniversalVariables>) => {
          state.gettingUniversalVariables = "succeeded";
          state.universalVariables = action.payload;
        }
      )
      .addCase(getUniversalVariables.rejected, (state) => {
        state.gettingUniversalVariables = "failed";
      });
  },
});

export const selectUniversalVariables = (state: RootState) =>
  state.universalVariables.universalVariables;

export const selectGettingUniversalVariablesStatus = (state: RootState) =>
  state.universalVariables.gettingUniversalVariables;

export default universalVariablesSlice.reducer;
