import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { request } from "utils/request";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { hideModal } from "app/components/Modules/slice";
import {
  ModuleTemplates,
  ModuleType,
  Guide,
  UsedModuleTemplateIds,
  Section,
  AddModulePayload,
  EditModulePayload,
  EditModuleResponse,
} from "app/components/Modules/types";
import { toast } from "react-toastify";
import { successNotificationOptions } from "utils/constants";

// ------------------ State Type/Structure ------------------
export interface CompanyGuideState {
  moduleTemplates: ModuleTemplates | null;
  companyGuides: { [companyAccountId: number]: Guide };
  companyConnectionSection: { [companyAccountId: number]: Section | undefined };
  usedCompanyTemplateIds: { [companyAccountId: number]: UsedModuleTemplateIds }; // used just to keep track of which ones the user has already enabled so we don't show duplicates
  addModuleToCompanyGuideStatus: responseStatus;
  deleting: responseStatus;
  editCompanyGuideModuleStatus: responseStatus;
  getModuleTemplatesStatus: responseStatus;
  getCompanyGuideStatus: responseStatus;
  removeCompanyMemberFromCompanyStatus: responseStatus;
  updatingGuidePhotoStatus: responseStatus;
  deletingGuidePhotoStatus: responseStatus;
  updateCompanyMemberByCompanyMemberId: responseStatus;
}

// ------------------ InitialState ------------------
const initialState: CompanyGuideState = {
  moduleTemplates: null,
  companyGuides: {},
  companyConnectionSection: {},
  usedCompanyTemplateIds: {},
  addModuleToCompanyGuideStatus: "idle",
  deleting: "idle",
  editCompanyGuideModuleStatus: "idle",
  getModuleTemplatesStatus: "idle",
  getCompanyGuideStatus: "idle",
  removeCompanyMemberFromCompanyStatus: "idle",
  updateCompanyMemberByCompanyMemberId: "idle",
  updatingGuidePhotoStatus: "idle",
  deletingGuidePhotoStatus: "idle",
};

// ------------------------------------ GET Requests ------------------------------------
export const getModuleTemplates = createAsyncThunk(
  "companyGuide/getModuleTemplates",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/moduleTemplates/companyGuide`;
    return (await request(requestUrl)) as ModuleTemplates;
  }
);

export const getCompanyGuide = createAsyncThunk(
  "companyGuide/getCompanyGuide",
  async (companyAccountId: number, thunkAPI) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/guides/companyGuides/${companyAccountId}`;
    const getCompanyGuideResponse = (await request(requestUrl)) as {
      [companyGuideId: number]: Guide;
    };

    thunkAPI.dispatch(
      setCompanyGuide({
        companyGuideResponse: getCompanyGuideResponse,
        companyAccountId,
      })
    );
    return getCompanyGuideResponse;
  },
  {
    condition: (companyAccountId, { getState }) => {
      const {
        companyGuide: { getCompanyGuideStatus, companyGuides },
      } = getState() as RootState;
      if (
        getCompanyGuideStatus === "loading" ||
        companyGuides[companyAccountId]
      ) {
        return false;
      }
    },
  }
);

// ------------------------------------ POSTS Requests ------------------------------------
export const addModuleToCompanyGuide = createAsyncThunk(
  "companyGuide/addModuleToCompanyGuide",
  async (
    {
      payload,
      companyAccountId,
    }: { payload: AddModulePayload; companyAccountId: number },
    thunkAPI
  ) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/companyGuides/${companyAccountId}/companyGuideOnBoarding`;
    const addModuleResponse = (await request(requestUrl, {
      method: "POST",
      body: JSON.stringify({ modules: [payload] }),
    })) as { [companyGuideId: number]: Guide };

    thunkAPI.dispatch(
      setCompanyGuide({
        companyGuideResponse: addModuleResponse,
        companyAccountId,
      })
    );
    thunkAPI.dispatch(hideModal());
    return addModuleResponse;
  }
);

// ------------------------------------ PUT Requests ------------------------------------
export const editCompanyGuideModule = createAsyncThunk(
  "companyGuide/editCompanyGuideModule",
  async (
    {
      payload,
      companyAccountId,
    }: { payload: EditModulePayload; companyAccountId: number },
    thunkAPI
  ) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/modules`;
    const editModuleResponse = (await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify({ modules: [payload] }),
    })) as EditModuleResponse[];
    thunkAPI.dispatch(hideModal());
    return { response: editModuleResponse, companyAccountId };
  }
);

export const updateGuidePhoto = createAsyncThunk(
  "companyGuide/updateGuidePhoto",
  async ({
    photoType,
    photo,
    imageName,
    companyAccountId,
  }: {
    companyAccountId: number;
    imgSrc: string; // used to update the UI
    imageName: string;
    photoType: "coverPhoto" | "profilePicture";
    photo: string;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/guides/companyGuides/${companyAccountId}/photo`;
    const updateGuidePhotoResponse = (await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify({ imageName, photo, photoType }),
    })) as {
      coverPhotoBase64: string;
      coverPhoto: string;
      profilePicture: string;
      profilePictureBase64: string;
      companyAccountId: number;
    };
    return updateGuidePhotoResponse;
  }
);

// ------------------------------------ DELETE Requests ------------------------------------
export const deleteSectionById = createAsyncThunk(
  "companyGuide/deleteSectionById",
  async (
    {
      sectionId,
      companyAccountId,
    }: { sectionId: number; companyAccountId: number },
    thunkAPI
  ) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/sections/${sectionId}`;
    const deleteSectionById = await request(requestUrl, {
      method: "DELETE",
    });

    thunkAPI.dispatch(
      removeSectionFromCompanyGuide({
        sectionId,
        companyAccountId,
      })
    );
    return deleteSectionById;
  }
);

export const deleteCompanyModuleById = createAsyncThunk(
  "companyGuide/deleteCompanyModuleById",
  async (
    {
      talentInsightsModuleId,
      companyAccountId,
    }: {
      talentInsightsModuleId: number;
      companyAccountId: number;
    },
    thunkAPI
  ) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/modules/${talentInsightsModuleId}`;
    const deleteModuleResponse = (await request(requestUrl, {
      method: "DELETE",
    })) as { [companyGuideId: number]: Guide };

    thunkAPI.dispatch(
      setCompanyGuide({
        companyGuideResponse: deleteModuleResponse,
        companyAccountId,
      })
    );
    thunkAPI.dispatch(hideModal());
    return deleteModuleResponse;
  }
);

export const deleteGuidePhoto = createAsyncThunk(
  "companyGuide/deleteGuidePhoto",
  async ({
    companyAccountId,
    photoType,
  }: {
    companyAccountId: number;
    photoType: "coverPhoto" | "profilePicture";
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/guides/companyGuides/${companyAccountId}/${photoType}`;

    await request(requestUrl, {
      method: "DELETE",
    });

    return {
      companyAccountId,
      photoType,
    };
  }
);

// ------------------ Beginning of Slice Definition ------------------
export const companyGuideSlice = createSlice({
  name: "companyGuide",
  initialState,
  reducers: {
    setCompanyGuide(
      state,
      {
        payload: { companyAccountId, companyGuideResponse },
      }: PayloadAction<{
        companyGuideResponse: { [companyGuideId: number]: Guide };
        companyAccountId: number;
      }>
    ) {
      // If the userGuideResponse is empty, return.
      if (Object.keys(companyGuideResponse).length === 0) {
        return;
      }

      const incomingCompanyGuide = Object.values(companyGuideResponse)[0];
      const companyGuideContent = incomingCompanyGuide.content;
      const companyGuideContentArray = Object.values(companyGuideContent);

      // Pulls out the section with the title 'Connect' and adds it to the companyConnectionSection state.
      const connectSection = companyGuideContentArray.find(
        (sectionObj) => sectionObj.title === "Connect"
      );

      const usedModules = {
        "Free Text": [],
        List: [],
      } as UsedModuleTemplateIds;

      // Goes through all of the user modules and adds the moduleTemplateId, to determine which templates the user has already used.
      companyGuideContentArray.forEach((sectionObj) => {
        if (!sectionObj.modules) {
          return;
        }
        Object.values(sectionObj.modules).forEach(
          ({ moduleType, moduleTemplateId }) => {
            if (moduleType in usedModules) {
              usedModules[moduleType].push(moduleTemplateId);
            }
          }
        );
      });

      if (connectSection !== undefined) {
        delete companyGuideContent[connectSection?.sectionId];
      }

      return {
        ...state,
        companyConnectionSection: {
          ...state.companyConnectionSection,
          [companyAccountId]: connectSection,
        },
        companyGuides: {
          ...state.companyGuides,
          [companyAccountId]: {
            ...state.companyGuides[companyAccountId],
            ...incomingCompanyGuide,
            content: companyGuideContent,
          },
        },
        usedCompanyTemplateIds: {
          ...state.usedCompanyTemplateIds,
          [companyAccountId]: usedModules,
        },
      };
    },
    addModuleTemplateIdToSet(
      state,
      {
        payload: { moduleTemplateId, moduleType, companyAccountId },
      }: PayloadAction<{
        moduleTemplateId: number;
        moduleType: ModuleType;
        companyAccountId: number;
      }>
    ) {
      // If the moduleId already exist lets not have duplicates
      if (
        state.usedCompanyTemplateIds[companyAccountId][moduleType].includes(
          moduleTemplateId
        )
      ) {
        return;
      }

      return {
        ...state,
        usedCompanyTemplateIds: {
          ...state.usedCompanyTemplateIds,
          [companyAccountId]: {
            ...state.usedCompanyTemplateIds[companyAccountId],
            [moduleType]: [
              ...state.usedCompanyTemplateIds[companyAccountId][moduleType],
              moduleTemplateId,
            ],
          },
        },
      };
    },
    removeModuleTemplateIdFromSet(
      state,
      {
        payload: { moduleTemplateId, moduleType, companyAccountId },
      }: PayloadAction<{
        moduleTemplateId: number;
        moduleType: ModuleType;
        companyAccountId: number;
      }>
    ) {
      return {
        ...state,
        usedCompanyTemplateIds: {
          ...state.usedCompanyTemplateIds,
          [companyAccountId]: {
            ...state.usedCompanyTemplateIds[companyAccountId],
            [moduleType]: state.usedCompanyTemplateIds[companyAccountId][
              moduleType
            ].filter((templateId) => templateId !== moduleTemplateId),
          },
        },
      };
    },
    removeSectionFromCompanyGuide(
      state,
      {
        payload: { sectionId, companyAccountId },
      }: PayloadAction<{ sectionId: number; companyAccountId: number }>
    ) {
      if (!state.companyGuides?.[companyAccountId]) {
        return;
      }

      const companyGuideContent = Object.assign(
        {},
        state.companyGuides[companyAccountId].content
      );
      const usedCompanyTemplateIds = Object.assign(
        {},
        state.usedCompanyTemplateIds[companyAccountId]
      );

      Object.values(companyGuideContent[sectionId].modules).forEach(
        ({ moduleTemplateId, moduleType }) => {
          if (moduleType in usedCompanyTemplateIds) {
            usedCompanyTemplateIds[moduleType] = usedCompanyTemplateIds[
              moduleType
            ].filter((templateId) => templateId !== moduleTemplateId);
          }
        }
      );
      delete companyGuideContent[sectionId];

      return {
        ...state,
        companyGuides: {
          ...state.companyGuides,
          [companyAccountId]: {
            ...state.companyGuides[companyAccountId],
            content: companyGuideContent,
          },
        },
        usedCompanyTemplateIds: {
          ...state.usedCompanyTemplateIds,
          [companyAccountId]: usedCompanyTemplateIds,
        },
      };
    },
    clearDeleteStatus(state) {
      state.deleting = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getModuleTemplates.pending, (state) => {
        state.getModuleTemplatesStatus = "loading";
      })
      .addCase(
        getModuleTemplates.fulfilled,
        (state, action: PayloadAction<ModuleTemplates>) => {
          state.moduleTemplates = action.payload;
          state.getModuleTemplatesStatus = "succeeded";
        }
      )
      .addCase(getModuleTemplates.rejected, (state) => {
        state.getModuleTemplatesStatus = "failed";
      })
      .addCase(getCompanyGuide.pending, (state) => {
        state.getCompanyGuideStatus = "loading";
      })
      .addCase(getCompanyGuide.fulfilled, (state) => {
        state.getCompanyGuideStatus = "succeeded";
      })
      .addCase(getCompanyGuide.rejected, (state) => {
        state.getCompanyGuideStatus = "failed";
      })
      .addCase(addModuleToCompanyGuide.pending, (state) => {
        state.addModuleToCompanyGuideStatus = "loading";
      })
      .addCase(addModuleToCompanyGuide.fulfilled, (state) => {
        state.addModuleToCompanyGuideStatus = "succeeded";
      })
      .addCase(addModuleToCompanyGuide.rejected, (state) => {
        state.addModuleToCompanyGuideStatus = "failed";
      })
      .addCase(editCompanyGuideModule.pending, (state) => {
        state.editCompanyGuideModuleStatus = "loading";
      })
      .addCase(
        editCompanyGuideModule.fulfilled,
        (
          state,
          {
            payload: { companyAccountId, response },
          }: PayloadAction<{
            response: EditModuleResponse[];
            companyAccountId: number;
          }>
        ) => {
          // Getting the sectionId from the first module in the response
          const { sectionId } = response[0];
          const modules = Object.assign(
            {},
            state.companyGuides[companyAccountId].content[sectionId].modules
          );
          response.forEach((module) => {
            modules[module.talentInsightsModuleId] = {
              ...modules[module.talentInsightsModuleId],
              ...module,
            };
          });

          return {
            ...state,
            editCompanyGuideModuleStatus: "succeeded",
            companyGuides: {
              ...state.companyGuides,
              [companyAccountId]: {
                ...state.companyGuides[companyAccountId],
                content: {
                  ...state.companyGuides[companyAccountId].content,
                  [sectionId]: {
                    ...state.companyGuides[companyAccountId].content[sectionId],
                    modules,
                  },
                },
              },
            },
          };
        }
      )
      .addCase(editCompanyGuideModule.rejected, (state) => {
        state.editCompanyGuideModuleStatus = "failed";
      })
      .addCase(deleteCompanyModuleById.pending, (state) => {
        state.deleting = "loading";
      })
      .addCase(deleteCompanyModuleById.fulfilled, (state) => {
        toast.success("Deleting Module Successful!", {
          ...successNotificationOptions,
        });
        state.deleting = "succeeded";
      })
      .addCase(deleteCompanyModuleById.rejected, (state) => {
        state.deleting = "failed";
      })
      .addCase(deleteSectionById.pending, (state) => {
        state.deleting = "loading";
      })
      .addCase(deleteSectionById.fulfilled, (state) => {
        toast.success("Deleting Section Successful!", {
          ...successNotificationOptions,
        });
        state.deleting = "succeeded";
      })
      .addCase(deleteSectionById.rejected, (state) => {
        state.deleting = "failed";
      })
      .addCase(updateGuidePhoto.pending, (state) => {
        state.updatingGuidePhotoStatus = "loading";
      })
      .addCase(
        updateGuidePhoto.fulfilled,
        (
          state,
          action: PayloadAction<{
            coverPhotoBase64: string;
            coverPhoto: string;
            profilePicture: string;
            profilePictureBase64: string;
            companyAccountId: number;
          }>
        ) => {
          toast.success("Updating Photo Successful!", {
            ...successNotificationOptions,
          });
          return {
            ...state,
            updatingGuidePhotoStatus: "succeeded",
            companyGuides: {
              ...state.companyGuides,
              [action.payload.companyAccountId]: {
                ...state.companyGuides[action.payload.companyAccountId],
                coverPhoto: {
                  picture: action.payload.coverPhoto,
                  base64: action.payload.coverPhotoBase64,
                },
                profilePicture: {
                  picture: action.payload.profilePicture,
                  base64: action.payload.profilePictureBase64,
                },
              },
            },
          };
        }
      )
      .addCase(updateGuidePhoto.rejected, (state) => {
        state.updatingGuidePhotoStatus = "failed";
      })
      .addCase(deleteGuidePhoto.pending, (state) => {
        state.deletingGuidePhotoStatus = "loading";
      })
      .addCase(
        deleteGuidePhoto.fulfilled,
        (
          state,
          action: PayloadAction<{
            companyAccountId: number;
            photoType: "coverPhoto" | "profilePicture";
          }>
        ) => {
          const photoType =
            action.payload.photoType === "coverPhoto"
              ? "Cover Photo"
              : "Profile Picture";
          toast.success(`Deleting ${photoType} Successful!`, {
            ...successNotificationOptions,
          });
          return {
            ...state,
            deletingGuidePhotoStatus: "succeeded",
            companyGuides: {
              ...state.companyGuides,
              [action.payload.companyAccountId]: {
                ...state.companyGuides[action.payload.companyAccountId],
                [action.payload.photoType]: {
                  picture: null,
                  base64: null,
                },
              },
            },
          };
        }
      )
      .addCase(deleteGuidePhoto.rejected, (state) => {
        state.deletingGuidePhotoStatus = "failed";
      });
  },
});

export const {
  addModuleTemplateIdToSet,
  removeModuleTemplateIdFromSet,
  removeSectionFromCompanyGuide,
  setCompanyGuide,
  clearDeleteStatus,
} = companyGuideSlice.actions;

// ------------------ Selectors ------------------
export const selectCompanyModuleTemplates = (state: RootState) =>
  state.companyGuide.moduleTemplates;
export const selectGetModuleTemplatesStatus = (state: RootState) =>
  state.companyGuide.getModuleTemplatesStatus;
export const selectGetCompanyGuideStatus = (state: RootState) =>
  state.companyGuide.getCompanyGuideStatus;
export const selectCompanyGuides = (state: RootState) =>
  state.companyGuide.companyGuides;
export const selectCompanyGuide = (state: RootState) => {
  const companyAccountId = state.global.companyInfo?.companyAccountId;
  return companyAccountId
    ? state.companyGuide.companyGuides[companyAccountId]
    : null;
};
export const selectCompanyConnectSection = (state: RootState) =>
  state.companyGuide.companyConnectionSection;
export const selectUsedCompanyTemplateIds = (state: RootState) =>
  state.companyGuide.usedCompanyTemplateIds;
export const selectUpdatingGuidePhotoStatus = (state: RootState) =>
  state.companyGuide.updatingGuidePhotoStatus;
export const selectDeletingGuidePhotoStatus = (state: RootState) =>
  state.companyGuide.deletingGuidePhotoStatus;
export const selectDeletingStatus = (state: RootState) =>
  state.companyGuide.deleting;

export default companyGuideSlice.reducer;
