import Collapse from 'react-bootstrap/Collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import TextArea from 'app/storybookComponents/Forms/TextArea';
import Button from 'app/storybookComponents/Button';

interface Props {
  title: string;
  numberOfStars?: number;
  feedbackMessage?: string;
  inputText?: string;
  onInputChange?: (inputText: string) => void;
  onSetStars?: (numberOfStars: number) => void;
  onSubmit?: () => void;
  onClose?: () => void;
  disabled?: boolean;
}

export default function FeedbackStarScreen({
  title,
  numberOfStars = 5,
  feedbackMessage,
  onSetStars,
  onInputChange,
  inputText,
  onClose,
  onSubmit,
  disabled,
}: Props) {
  const [showFeedbackMessage, setShowFeedbackMessage] = useState(true);
  const [realValueSelected, setRealValueSelected] = useState<number | null>(
    null
  );
  const [visualStarSelected, setVisualStarSelected] = useState<number | null>(
    null
  );

  const onStarClick = (starIndex: number) => {
    setRealValueSelected(starIndex);
    setShowFeedbackMessage(false);
    onSetStars?.(starIndex);
  };

  const getFontAwesomeIconPrefix = (i: number) => {
    if (typeof visualStarSelected === 'number') {
      return i <= visualStarSelected ? 'fas' : 'far';
    }
    if (typeof realValueSelected === 'number') {
      return i <= realValueSelected ? 'fas' : 'far';
    }
    return 'far';
  };

  const getStars = () => {
    const stars = [];
    for (let i = 0; i < numberOfStars; i++) {
      stars.push(
        <FontAwesomeIcon
          icon={[getFontAwesomeIconPrefix(i), 'star']}
          role="button"
          onClick={() => onStarClick(i)}
          onMouseEnter={() => setVisualStarSelected(i)}
          onMouseLeave={() => setVisualStarSelected(null)}
        />
      );
    }
    return stars;
  };

  return (
    <div className="feedback-stars-container">
      <Collapse in={showFeedbackMessage}>
        <div
          style={{
            marginBottom: '20px',
          }}
        >
          <FontAwesomeIcon
            icon="check-circle"
            color="green"
            size="2x"
            className="mb-2"
          />
          <h3 style={{ color: 'black' }}>{title}</h3>
          <p style={{ width: '540px' }}>{feedbackMessage}</p>
        </div>
      </Collapse>
      <div className="column-gap-8px">
        <span>Rate your experience:</span>
        <div style={{ fontSize: '34px' }}>{getStars()}</div>
      </div>
      {onInputChange ? (
        <Collapse
          in={
            !showFeedbackMessage &&
            realValueSelected !== null &&
            realValueSelected < 3
          }
        >
          <div className="mt-2">
            <span>Please give us feedback:</span>
            <TextArea
              maxLength={250}
              inputLabel="Is there any changes that would improve your experience? (Optional)"
              controlId={'feedbackResponse'}
              onTextChange={onInputChange}
              placeholder="Give us your feedback here..."
              inputText={inputText || ''}
              hideLength
            />
          </div>
        </Collapse>
      ) : null}
      <div className="feedback-stars-buttons mt-4">
        {!showFeedbackMessage && onSubmit ? (
          <Button className="me-2" disabled={disabled} onClick={onSubmit}>
            Submit feedback
          </Button>
        ) : null}
        {onClose ? (
          <Button
            variant="secondary-blue"
            onClick={onClose}
            disabled={disabled}
          >
            Close
          </Button>
        ) : null}
      </div>
    </div>
  );
}
