import Robot from "resources/images/robot.svg";

interface Props {
  readonly type: "user" | "assistant";
  readonly message: string;
}

export default function MessageBubble({ type, message }: Props) {
  const addNewLine = (message: string) => {
    const splitMessage = message.split("\n");
    return (
      <>
        {splitMessage.map((part, index) => {
          const hasNewLine = index < splitMessage.length - 1;
          return (
            <span key={index}>
              {part}
              {hasNewLine && <br />}
            </span>
          );
        })}
      </>
    );
  };

  const convertMarkdown = (message: string) => {
    // modify any word surrounded by **{word}** to just be {word}
    const boldRegex = /\*\*(.*?)\*\*/g;
    const splitMessage = message.split(boldRegex);
    return (
      <div>
        {splitMessage.map((part, index) => {
          const isBold = index % 2 === 1;
          return (
            <span
              key={index}
              style={{
                fontWeight: isBold ? "bold" : "normal",
              }}
            >
              {addNewLine(part)}
            </span>
          );
        })}
      </div>
    );
  };

  if (type === "assistant") {
    return (
      <div className="assistant-bubble-row">
        <img src={Robot} alt="Criteria" className="assistant-avatar" />
        <div className="assistant-bubble">{convertMarkdown(message)}</div>
      </div>
    );
  }

  return <div className="user-bubble">{convertMarkdown(message)}</div>;
}
