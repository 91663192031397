import { USER_TABS } from "./constants";
export type UserGuideTabs = (typeof USER_TABS)[number];
export const isUserGuideTabs = (value: any): value is UserGuideTabs => {
  return USER_TABS.includes(value);
};

export type EppPermissions = {
  canSeePersonalitySummary: boolean;
  canSeeWorkplaceInsights: boolean;
  canSeeOnTeamReport: boolean;
  canSeeManagerGuide: boolean;
};
