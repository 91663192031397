import {
  AssessmentInstance,
  AssessmentSeries,
  AssessmentTeam360AssessmentResult,
} from "./types";

export const getDaysRemaining = (endDate: string | Date | undefined | null) => {
  if (!endDate) return null;

  let copyDate = endDate;
  if (typeof copyDate === "string") {
    copyDate = new Date(copyDate);
  }
  const today = new Date();
  const diffTime = copyDate.getTime() - today.getTime();
  // // if the date is in the past, return 0
  // if (diffTime < 0) {
  //   return 0;
  // }

  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

export const isStartDateBeforeToday = (
  startDate: string | Date | undefined | null
) => {
  if (!startDate) return false;

  let copyDate = startDate;
  if (typeof copyDate === "string") {
    copyDate = new Date(copyDate);
  }
  const today = new Date();
  const diffTime = copyDate.getTime() - today.getTime();
  // if the date is in the past, return 0
  if (diffTime < 0) {
    return true;
  }

  return false;
};

export const getScoreClassName = (score: number = 0) => {
  if (score === 0) return "no-results";
  if (score > 80) {
    return "super";
  } else if (score > 60) {
    return "high";
  } else if (score > 40) {
    return "medium";
  } else if (score > 20) {
    return "low";
  } else {
    return "lowest";
  }
};

type AssessmentSeriesOrInstance = AssessmentSeries | AssessmentInstance;
export const splitSurveys = ({
  selectedSurvey,
  searchTerm,
  departments,
  teamInfoById,
}: {
  selectedSurvey: AssessmentSeriesOrInstance;
  searchTerm?: string;
  departments: { [key: number]: { name?: string } };
  teamInfoById: { [key: number]: { teamName?: string } };
}): {
  org?: AssessmentSeriesOrInstance;
  teams: AssessmentSeriesOrInstance[];
  departments: AssessmentSeriesOrInstance[];
} => {
  const returnObj: {
    org?: AssessmentSeriesOrInstance;
    teams: { [key: number]: AssessmentSeriesOrInstance };
    departments: { [key: number]: AssessmentSeriesOrInstance };
  } = {
    teams: {},
    departments: {},
  };
  const emptyIds = {
    inviteOrg: 0,
    teamIds: [],
    departmentIds: [],
    isNested: true,
  };
  const searchTermLower = searchTerm?.toLowerCase() ?? "";

  const processDepartment = ([id, departmentResults]: [
    string | number,
    AssessmentTeam360AssessmentResult | undefined
  ]) => {
    const departmentId = Number(id);
    if (!departments[departmentId] || returnObj.departments[departmentId])
      return;

    const departmentName = departments[departmentId].name?.toLowerCase() ?? "";
    if (
      searchTerm &&
      !`${departmentName} department`?.includes(searchTermLower)
    ) {
      return;
    }
    const totalCompleted = departmentResults?.totalCompleted ?? 0;
    const totalInvited = departmentResults?.totalInvited ?? 0;
    const completionRate = getCompletionRate(totalCompleted, totalInvited);

    returnObj.departments[departmentId] = {
      ...selectedSurvey,
      ...emptyIds,
      totalCompleted,
      totalInvited,
      completionRate,
      departmentIds: [departmentId],
      team360Scores: {
        departments: {
          [departmentId]: departmentResults,
        },
      },
    };
  };

  const processTeam = ([id, teamResults]: [
    string | number,
    AssessmentTeam360AssessmentResult | undefined
  ]) => {
    const teamId = Number(id);
    if (!teamInfoById[teamId] || returnObj.teams[teamId]) return;

    const teamName = teamInfoById[teamId].teamName?.toLowerCase() ?? "";
    if (searchTerm && !`${teamName} team`?.includes(searchTermLower)) {
      return;
    }
    const totalCompleted = teamResults?.totalCompleted ?? 0;
    const totalInvited = teamResults?.totalInvited ?? 0;
    const completionRate = getCompletionRate(totalCompleted, totalInvited);

    returnObj.teams[teamId] = {
      ...selectedSurvey,
      ...emptyIds,
      totalCompleted,
      totalInvited,
      completionRate,
      teamIds: [teamId],
      team360Scores: {
        teams: {
          [teamId]: teamResults,
        },
      },
    };
  };

  // We will add all the departments that exist under the team360Scores
  if (selectedSurvey?.team360Scores?.departments) {
    Object.entries(selectedSurvey.team360Scores.departments)?.forEach(
      processDepartment
    );
  }

  // Then we add all the departments that exist under departmentIds
  if (selectedSurvey?.departmentIds) {
    selectedSurvey.departmentIds.forEach((departmentId) => {
      processDepartment([
        departmentId,
        selectedSurvey.team360Scores?.departments?.[departmentId],
      ]);
    });
  }

  // We will add all the teams that exist under the team360Scores
  if (selectedSurvey?.team360Scores?.teams) {
    Object.entries(selectedSurvey.team360Scores.teams)?.forEach(processTeam);
  }

  // Then we add all the teams that exist under teamIds
  if (selectedSurvey?.teamIds) {
    selectedSurvey.teamIds.forEach((teamId) => {
      processTeam([teamId, selectedSurvey.team360Scores?.teams?.[teamId]]);
    });
  }

  if (
    selectedSurvey.inviteOrg &&
    (!searchTerm ||
      "full organization".includes(searchTerm.toLocaleLowerCase()))
  ) {
    returnObj.org = {
      ...selectedSurvey,
      ...emptyIds,
      inviteOrg: 1,
      team360Scores: {
        company: selectedSurvey?.team360Scores?.company,
      },
    };
  }

  return {
    org: returnObj.org,
    teams: Object.values(returnObj.teams),
    departments: Object.values(returnObj.departments),
  };
};

const getCompletionRate = (
  totalCompleted: number,
  totalInvited: number
): number => {
  if (!totalInvited) return 0;
  return Number(((totalCompleted / totalInvited) * 100).toFixed(0));
};
