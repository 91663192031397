import { Container } from 'react-bootstrap';
import JoinCard from './JoinCard';
import AccountSetupCard from './AccountSetupCard';

export default function AccountSetup() {
  const getContent = () => {
    const [, param] = window.location.pathname.split('/');
    if (param === 'join') {
      return <JoinCard />;
    } else if (param === 'accountSetup') {
      return <AccountSetupCard />;
    } else {
      return null;
    }
  };

  return <Container className="account-setup">{getContent()}</Container>;
}
