import { ReactElement, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Collapse, Dropdown } from "react-bootstrap";
import { useAppDispatch } from "utils/redux/hooks";
import { showEditModuleModal, showAddModuleModal } from "./slice";
import Module from "./Module";
import { GuideType, ModuleTemplates, ModuleType } from "./types";
import { getEntries } from "utils/helperFunctions";
import Button from "app/storybookComponents/Button";

interface Props {
  modules: {
    [talentInsightsModuleId: string]: {
      talentInsightsModuleId: number;
      title: string;
      moduleType: ModuleType;
      moduleContent: string;
      moduleTemplateId: number;
      link?: string;
    };
  };
  setDeleting: (payload: {
    type: "Module" | "Section";
    id: number | string;
    moduleType?: string;
    moduleTemplateId?: number;
  }) => void;
  guideType: GuideType;
  moduleOrder: number[];
  sectionId: string | number;
  title: string;
  userIdOrTeamIdOrCompanyId: number;
  hasEditAccess?: boolean;
  isCollapsible?: boolean;
  moduleTemplates?: null | ModuleTemplates;
}

export default function Section({
  guideType,
  hasEditAccess,
  moduleOrder,
  modules,
  sectionId,
  setDeleting,
  title,
  userIdOrTeamIdOrCompanyId,
  isCollapsible,
  moduleTemplates,
}: Props) {
  const dispatch = useAppDispatch();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const COLLAPSE_MIN = 4; // This is the number of modules needed in order to show the collapse button, even if the isCollapsible prop is true.
  const COLLAPSE_SHOWING_COUNT = 3; // This number is the total number of modules that will be shown when the section is collapsed.

  const onDropdownSelect = (e: string | null) => {
    switch (e) {
      case "add": {
        dispatch(
          showAddModuleModal({
            section: title,
            guideType,
            userIdOrTeamIdOrCompanyId,
          })
        );
        return;
      }
      case "delete": {
        setDeleting({ type: "Section", id: sectionId });
        return;
      }
    }
  };

  // -------------------------------- Render Getters --------------------------------
  const getSectionModules = () => {
    if (modules === undefined || Object.keys(modules).length === 0) {
      return [];
    }
    const moduleElements: ReactElement[] = [];
    if (Array.isArray(moduleOrder)) {
      moduleOrder.forEach((moduleId, idx) => {
        // Check the module exist before trying to access its properties
        if (!modules[moduleId]) {
          return;
        }

        const {
          title,
          moduleContent,
          moduleTemplateId,
          moduleType,
          talentInsightsModuleId,
          link,
        } = modules[moduleId];

        moduleElements.push(
          <Module
            title={title}
            content={moduleContent}
            key={idx}
            moduleType={moduleType}
            link={link}
            onEditClick={() => {
              dispatch(
                showEditModuleModal({
                  moduleContent,
                  moduleTemplateId,
                  moduleType,
                  talentInsightsModuleId,
                  link,
                  userIdOrTeamIdOrCompanyId,
                  guideType,
                  moduleTitle: title,
                })
              );
            }}
            onDeleteClick={() =>
              setDeleting({
                type: "Module",
                id: talentInsightsModuleId,
                moduleType,
                moduleTemplateId,
              })
            }
            hasEditAccess={hasEditAccess}
            moduleTemplate={
              moduleTemplates
                ? moduleTemplates?.[moduleType][moduleTemplateId]
                : undefined
            }
          />
        );
      });
    } else {
      // If for some reason moduleOrder is not an array, we will just render the modules in the order they are returned from the API.
      getEntries(modules).forEach(
        ([
          moduleId,
          {
            title,
            moduleContent,
            moduleTemplateId,
            moduleType,
            talentInsightsModuleId,
            link,
          },
        ]) => {
          moduleElements.push(
            <Module
              title={title}
              content={moduleContent}
              key={moduleId}
              moduleType={moduleType}
              link={link}
              onEditClick={() => {
                dispatch(
                  showEditModuleModal({
                    moduleContent,
                    moduleTemplateId,
                    moduleType,
                    talentInsightsModuleId,
                    userIdOrTeamIdOrCompanyId,
                    guideType,
                    moduleTitle: title,
                  })
                );
              }}
              onDeleteClick={() =>
                setDeleting({
                  type: "Module",
                  id: talentInsightsModuleId,
                  moduleType,
                  moduleTemplateId,
                })
              }
              moduleTemplate={
                moduleTemplates
                  ? moduleTemplates?.[moduleType][moduleTemplateId]
                  : undefined
              }
              hasEditAccess={hasEditAccess}
            />
          );
        }
      );
    }
    return moduleElements;
  };

  const getDropdown = () => (
    <Dropdown onSelect={onDropdownSelect}>
      <Dropdown.Toggle
        variant="outline-primary"
        id="dropdown-basic"
        className="no-caret"
      >
        <FontAwesomeIcon icon="ellipsis" size="lg" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="flip">
        <Dropdown.Item eventKey="add">
          <FontAwesomeIcon icon="plus" /> Add To Section
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item eventKey="delete" className="danger">
          Delete Section
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  const getSectionContent = () => {
    const modules = getSectionModules();
    const firstHalf = modules.slice(0, COLLAPSE_SHOWING_COUNT);
    const secondHalf = modules.slice(COLLAPSE_SHOWING_COUNT);
    return (
      <>
        <div className="section-header">
          <h2 className="section-title">{title || "Section"}</h2>
          {hasEditAccess ? getDropdown() : null}
        </div>
        <div className="section-modules">
          {firstHalf}
          {secondHalf?.length ? (
            <Collapse in={!isCollapsed}>
              <div className="section-modules">{secondHalf}</div>
            </Collapse>
          ) : null}
          {isCollapsible &&
          modules &&
          Object.keys(modules).length >= COLLAPSE_MIN ? (
            <div className="section-collapse">
              <Button
                onClick={() => setIsCollapsed(!isCollapsed)}
                variant="tertiary-blue"
                className="w-100"
              >
                {isCollapsed ? "See All" : "Collapse"}
              </Button>
            </div>
          ) : null}
        </div>
      </>
    );
  };

  // -------------------------------- Start if Return Section --------------------------------
  return <Card className="section">{getSectionContent()}</Card>;
}
