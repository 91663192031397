import { Card } from "react-bootstrap";
import { GuideType } from "app/components/Modules/types";
import { ReactComponent as HelpIcon } from "resources/icons/help.svg";

interface Props {
  guideType: GuideType;
  modalTab: string;
}

export default function InformationalModuleCard({
  guideType,
  modalTab,
}: Props) {
  const getCardText = () => {
    switch (guideType) {
      case "team":
        return getTeamText();
      case "user":
        return getUserText();
      case "company":
        return getCompanyText();
    }
  };

  const getTeamText = () => {
    const initialText =
      "Answering these prompts helps people at your organization understand ";
    switch (modalTab) {
      case "About Us":
        return initialText + "what your team does and why";
      case "Targets":
        return initialText + "what your team is working towards";
      case "Empower":
        return (
          initialText +
          "how your team builds trust and empowers its team members"
        );
      case "Align":
        return initialText + "your team’s working and communication style";
      case "Resources":
        return "Linking key resources on your team guide keeps your documents organized and easily accessible for your team members.";
      case "Monitor":
      case "Connect":
      default:
        return initialText + "your team’s atmosphere around learning and mood";
    }
  };

  const getUserText = () => {
    const initialText = "Answering these prompts helps your team members ";
    switch (modalTab) {
      case "At Work":
        return (
          initialText + "communicate and understand your work and working style"
        );
      case "Outside Work":
        return (
          initialText + "understand your interests and passions outside of work"
        );
      case "Connect":
        return initialText + "understand what to reach out to you for";
      case "About Me":
      default:
        return (
          initialText +
          "better understand your personality and goals inside and outside of work"
        );
    }
  };

  const getCompanyText = () => {
    const initialText = "Answering these prompts helps ";
    switch (modalTab) {
      case "About Us":
        return (
          initialText +
          "to remind your organization of your collective mission and why it’s important"
        );
      case "Our Mission":
        return (
          initialText +
          "your team members understand this organization’s drive and long-term goals"
        );
      case "Our Bigger Purpose":
        return "your company’s bigger purpose";
      case "Our Culture":
        return (
          initialText +
          "your team members understand your organization’s everyday functions and culture"
        );
      case "Our Values":
        return "your company’s values";
      case "Our Customers":
        return "your company’s customers";
      case "Resources":
        return "Linking key resources on your organization guide keeps your documents organized and easily accessible for your team and members.";
    }
  };

  return (
    <Card className="module-library-card informational">
      <HelpIcon className="help-icon" />
      <p
        style={{
          lineHeight: "20px",
          marginTop: "4px",
        }}
      >
        {getCardText()}
      </p>
    </Card>
  );
}
