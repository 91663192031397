import { Alert, Card, Form, InputGroup } from 'react-bootstrap';
import Button from 'app/storybookComponents/Button';
import LoginHeader from './LoginHeader';
import { SyntheticEvent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'utils/redux/hooks';
import { selectUpdatePasswordStatus, updateUsersPassword } from './slice';
import { getInputPlaceholder, getInputType, getEyeSlashed } from './helpers';

export default function ResetPasswordFlow() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const tk1 = searchParams.get('tk1');
  const tk2 = searchParams.get('tk2');

  const [password, setPassword] = useState('');
  const [verifiedPassword, setVerifiedPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showVerifiedPassword, setShowVerifiedPassword] = useState(false);
  const [passwordDoNotMatch, setPasswordDoNotMatch] = useState(false);

  const updatingPasswordStatus = useAppSelector(selectUpdatePasswordStatus);

  // Commented out for now as there are no such requirements on the backend to store the password
  // const checkPasswordStrength = (password: string) => {
  //   // Define the criteria for password strength
  //   const minLength = 8;
  //   const minUpperCase = 1;
  //   const minLowerCase = 1;
  //   const minNumbers = 1;
  //   const minSpecialChars = 1;

  //   // Regular expressions for character types
  //   const upperCaseRegex = /[A-Z]/g;
  //   const lowerCaseRegex = /[a-z]/g;
  //   const numberRegex = /[0-9]/g;
  //   const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/g;

  //   // Check password length
  //   if (password.length < minLength) {
  //     return 'Weak';
  //   }

  //   // Check uppercase characters
  //   if ((password.match(upperCaseRegex) || []).length < minUpperCase) {
  //     return 'Weak';
  //   }

  //   // Check lowercase characters
  //   if ((password.match(lowerCaseRegex) || []).length < minLowerCase) {
  //     return 'Weak';
  //   }

  //   // Check numbers
  //   if ((password.match(numberRegex) || []).length < minNumbers) {
  //     return 'Weak';
  //   }

  //   // Check special characters
  //   if ((password.match(specialCharRegex) || []).length < minSpecialChars) {
  //     return 'Weak';
  //   }

  //   // If all criteria are met, the password is strong
  //   return 'Strong';
  // };

  const submitHandler = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!tk1 || !tk2) {
      return;
    }

    if (verifiedPassword !== password) {
      setPasswordDoNotMatch(true);
      return;
    }

    dispatch(
      updateUsersPassword({
        tk1,
        tk2,
        password: verifiedPassword,
      })
    );
  };

  const getUserInputForm = () => (
    <Form
      onSubmit={submitHandler}
      noValidate
      className={'needs-validation column-gap-20px'}
    >
      <Form.Group className="has-error">
        <Form.Label>New password</Form.Label>
        <InputGroup>
          <Form.Control
            placeholder={getInputPlaceholder(showPassword)}
            type={getInputType(showPassword)}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setPasswordDoNotMatch(false);
            }}
            className={`${passwordDoNotMatch ? 'is-invalid ' : ''}${
              password ? '' : 'empty '
            }`}
          />
          <InputGroup.Text>
            <div role="button" onClick={() => setShowPassword(!showPassword)}>
              <FontAwesomeIcon icon={['far', getEyeSlashed(showPassword)]} />
            </div>
          </InputGroup.Text>
        </InputGroup>
        <Form.Text className="text-muted">
          Your new password must be at least 8 characters long
        </Form.Text>
      </Form.Group>

      <Form.Group className="has-error">
        <Form.Label>Confirm password</Form.Label>

        <InputGroup>
          <Form.Control
            placeholder={getInputPlaceholder(showVerifiedPassword)}
            type={getInputType(showVerifiedPassword)}
            value={verifiedPassword}
            onChange={(e) => {
              setVerifiedPassword(e.target.value);
              setPasswordDoNotMatch(false);
            }}
            className={`${passwordDoNotMatch ? 'is-invalid ' : ''}${
              verifiedPassword ? '' : 'empty '
            }`}
          />
          <InputGroup.Text>
            <div
              role="button"
              onClick={() => setShowVerifiedPassword(!showVerifiedPassword)}
            >
              <FontAwesomeIcon
                icon={['far', getEyeSlashed(showVerifiedPassword)]}
              />
            </div>
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>
      {passwordDoNotMatch ? (
        <Alert variant="danger">Sorry Password do not match try again</Alert>
      ) : null}
      <Button
        type="submit"
        className="w-100"
        disabled={password.length < 8 || updatingPasswordStatus === 'loading'}
      >
        Reset Password
      </Button>
    </Form>
  );

  return (
    <div className="login-page">
      <Card>
        <LoginHeader
          title={'Reset Password'}
          infoText={
            updatingPasswordStatus === 'succeeded'
              ? 'Your password has been reset'
              : 'Enter your new password and confirm.'
          }
        />
        {updatingPasswordStatus === 'succeeded' ? (
          <Button
            type="submit"
            className="w-100"
            onClick={() => {
              window.location.href = '/';
            }}
          >
            Back to Login
            <FontAwesomeIcon icon="arrow-left" className="ms-2" />
          </Button>
        ) : (
          getUserInputForm()
        )}
      </Card>
    </div>
  );
}
