import {
  ClassificationArray,
  TeamReflectionSummary,
} from "app/containers/AdminConsole/types";
import { TEAM_TABS } from "./constants";

export type TeamGuideView =
  | "guide"
  | "settings"
  | "team-members"
  | "full-team-personality-report";

export type TeamGuideTabs = (typeof TEAM_TABS)[number];
export const isTeamGuideTabs = (value: any): value is TeamGuideTabs => {
  return TEAM_TABS.includes(value);
};

export interface TeamAnalyticsTextScoreBreakdown {
  name: string;
  score: number;
  text: string;
}

export interface TeamAnalyticsText {
  overview?: {
    text: string;
    scoreLabel?: string;
    highestScoringFactor?: string;
    lowestScoringFactor?: string;
    scoreBreakDowns: {
      factors: TeamAnalyticsTextScoreBreakdown[];
      dimensions: (TeamAnalyticsTextScoreBreakdown & { dimensionId: number })[];
    };
  } | null;
  youVsYourTeammates?: {
    text: string;
    tip: string;
    overallScore: number;
    yourScore: number;
  } | null;
  strengthsAndWeaknesses?: {
    factors: FactorStrengthsAndWeaknesses;
    dimensions: DimensionStrengthsAndWeaknesses;
  } | null;
  vsOrganization?: {
    breakdown: string;
    description: string;
    classificationArray: ClassificationArray;
    teamClassification: "healthy" | "high-performing" | "needs-help" | null;
  } | null;
  completionInfo?: {
    totalInvited: number;
    totalCompleted: number;
    completionRate: number;
  };
  teamReflectionSummary?: TeamReflectionSummary;
  conversationPartners?: {
    strengths?: ConversationPartner[] | null;
    weaknesses?: ConversationPartner[] | null;
  };
  latestAssessmentEndDate?: string;
}

export interface ConversationPartner {
  dimension: string;
  userAccountId: number;
  instructions: string;
  analysis: string;
  tag: string;
}

export interface FactorStrengthsAndWeaknesses {
  highestScoringFactor: string;
  highestScoringFactorScore: number;
  highestScoringFactorText: string;
  lowestScoringFactor: string;
  lowestScoringFactorScore: number;
  lowestScoringFactorText: string;
}

export interface DimensionStrengthsAndWeaknesses {
  highestScoringDimension: string;
  highestScoringDimensionScore: number;
  highestScoringDimensionText: string;
  lowestScoringDimension: string;
  lowestScoringDimensionScore: number;
  lowestScoringDimensionText: string;
}

export interface Team360Status {
  scheduledAssessment: boolean;
  hasResults: boolean;
  enoughResults: boolean;
}

export interface DimensionsAndFactorsTipsAndInterpretiveText {
  dimensions: {
    [dimensionName: string]: TipsAndInterpretiveText;
  };
  factors: {
    [factorName: string]: TipsAndInterpretiveText;
  };
}

export interface TipsAndInterpretiveText {
  companyTips: string[];
  userTips: string[];
  interpretiveText: string;
}

export type ConversationPartnerType =
  | "teamLeaderInThisDepartment"
  | "teamMemberOnThisTeam";

export type ConversationsToSuggestPayload = {
  userAccountId: number;
  teamId: number;
  conversationPartnerType?: ConversationPartnerType;
  conversationPartner?: number;
};

export type SavedConversationsToSuggest = {
  activeUserAccountId: number;
  activeComparedTo: number | ConversationPartnerType;
  conversationToBeHad: {
    [conversationPartnerId: number]: ConversationsToSuggestResponse;
  };
};

export type ConversationsToSuggestResponse = {
  [partnerId: number]: ConversationPartner[] | undefined;
  teamLeaderInThisDepartment?: ConversationPartner[];
  teamMemberOnThisTeam?: ConversationPartner[];
};
