import { AssessmentMap } from 'app/containers/Assessment/constants';
import Button from 'app/storybookComponents/Button';
import { responseStatus } from 'utils/types';

interface Props {
  totalInvites: number;
  surveyId: number;
  onCancel: () => void;
  onLaunch: () => void;
  schedulingTeam360Status?: responseStatus;
  inviteType: string;
}

const LaunchAssessmentWarningPreviewBody = ({
  totalInvites,
  surveyId = 1,
  onCancel,
  onLaunch,
  schedulingTeam360Status,
  inviteType,
}: Props) => {
  const getWarning = () => {
    const assessmentName = AssessmentMap[surveyId]?.name;
    switch (inviteType) {
      case 'Select Team(s)':
        return `Are you sure you want to send this ${assessmentName} to
        everyone on the selected teams?`;
      case 'Specific Department(s)':
        return `Are you sure you want to send this ${assessmentName} to
        everyone in the selected departments?`;
      case 'Entire Organization':
      default:
        return `Are you sure you want to send this ${assessmentName} to
        everyone in your organization?`;
    }
  };

  return (
    <>
      <div className="column-gap-12px">
        <div className="column-gap-8px align-items-center">
          <h3>{getWarning()}</h3>
          <p>
            {totalInvites} people will receive an invitation to take this
            survey.
          </p>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <Button
          variant="secondary-blue"
          onClick={onCancel}
          style={{ margin: '5px' }}
        >
          Cancel
        </Button>
        <Button
          disabled={schedulingTeam360Status === 'loading'}
          onClick={onLaunch}
          style={{ margin: '5px' }}
        >
          Launch
        </Button>
      </div>
    </>
  );
};

export default LaunchAssessmentWarningPreviewBody;
