import AvatarCircle from "app/components/AvatarCircle";
import { UserInfo } from "app/containers/Global/types";
import { ReactNode } from "react";
import Select from "react-select";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import CreatableSingleSelect from "./CreatableSingleSelect";
import { getUserOptionValue } from "./helpers";

interface Props {
  users: { [userAccountId: string | number]: UserInfo };
  userAccountIds?: number[];
  selectedValue?: number | null;
  setSelectedValue: (payload: number | null) => void;
  currentUserAccountId?: number | null; // this prop is optional just incase you want to filter out the current user
  isCreatable?: boolean;
  setCreatedUserEmail?: (payload: string) => void;
  selectedCreatedUserEmail?: string;
  clearAll?: () => void;
}

export default function SingleUserSelect({
  users,
  userAccountIds,
  setSelectedValue,
  selectedValue,
  currentUserAccountId,
  isCreatable,
  setCreatedUserEmail,
  selectedCreatedUserEmail,
  clearAll,
}: Props) {
  const getLeaderValue = () => {
    if (selectedCreatedUserEmail) {
      return {
        label: selectedCreatedUserEmail,
        value: selectedCreatedUserEmail,
        emailAddress: selectedCreatedUserEmail,
        avatarCircle: null,
      };
    }

    if (!selectedValue) return null;
    return getUserOptionValue(users[selectedValue]);
  };

  const customFilterOption = (
    option: FilterOptionOption<{
      label: string;
      value: string;
      avatarCircle: ReactNode;
      emailAddress: string;
    }>,
    inputValue: string
  ) => {
    const label = option.label?.toLowerCase();
    const value = option.value?.toLowerCase();
    const emailAddress = option.data.emailAddress?.toLowerCase();
    const input = inputValue?.toLowerCase();
    return (
      label.includes(input) ||
      value.includes(input) ||
      emailAddress.includes(input)
    );
  };

  const getOptionLabel = (memberInfo: {
    label: string;
    value: string;
    avatarCircle: ReactNode;
    emailAddress: string;
  }) => (
    <div className="member-option">
      {memberInfo.avatarCircle}
      <div className="member-info">
        <span className="member-name">{memberInfo.label}</span>
        <span className="member-email">{memberInfo.emailAddress}</span>
      </div>
    </div>
  );

  // -- Start of getting user options --
  const userOptions: {
    label: string;
    value: string;
    avatarCircle: ReactNode;
    emailAddress: string;
  }[] = [];

  // if userAccountIds is not provided, use all users
  const userAccountIdsArr: number[] | string[] = userAccountIds
    ? [...new Set(userAccountIds)]
    : Object.keys(users);

  const filteredUserAccountIds = currentUserAccountId
    ? userAccountIdsArr
        .map((userAccountId) => Number(userAccountId))
        .filter(
          (userAccountId: number) => userAccountId !== currentUserAccountId
        )
    : userAccountIdsArr.map((userAccountId) => Number(userAccountId));

  filteredUserAccountIds.forEach((userAccountId) => {
    const user = users[userAccountId];
    const userObj = getUserOptionValue(user);
    if (userObj) {
      userOptions.push(userObj);
    }
  });

  const getSelect = () => {
    if (isCreatable) {
      return (
        <CreatableSingleSelect
          options={userOptions}
          onChange={(val) => {
            if (!val) {
              if (clearAll) return clearAll(); // if clearAll is provided, use it
              setSelectedValue(null);
              setCreatedUserEmail?.("");
              return;
            }
            if (val?.avatarCircle) {
              setSelectedValue(val?.value ? Number(val?.value) : null);
            }
          }}
          value={getLeaderValue()}
          onCaptureInputValue={(payload) => {
            if (!selectedValue) {
              setCreatedUserEmail?.(payload);
            }
          }}
        />
      );
    }
    return (
      <Select
        options={userOptions}
        formatOptionLabel={getOptionLabel}
        filterOption={customFilterOption}
        value={getLeaderValue()}
        onChange={(val) => {
          setSelectedValue(val?.value ? Number(val?.value) : null);
        }}
        className="mb-2"
        placeholder="Search by name or email"
      />
    );
  };

  // -- End of getting user options --
  return getSelect();
}
