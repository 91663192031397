import { ToastPosition } from "react-toastify";

export const PLATFORM_NAME = "Develop by Criteria";

export const LIKERT_SCALE = [
  {
    label: "Strongly Disagree",
    value: 1,
  },
  {
    label: "Disagree",
    value: 2,
  },
  {
    label: "Neutral",
    value: 3,
  },
  {
    label: "Agree",
    value: 4,
  },
  {
    label: "Strongly Agree",
    value: 5,
  },
];

export const LIKERT_SCALE_ALT_1 = [
  {
    label: "Not Effective",
    value: 1,
  },
  {
    label: "A Little Effective",
    value: 2,
  },
  {
    label: "Somewhat Effective",
    value: 3,
  },
  {
    label: "Effective",
    value: 4,
  },
  {
    label: "Very Effective",
    value: 5,
  },
];

export const LIKERT_SCALE_LIKELIHOOD = [
  {
    label: "Very Unlikely",
    value: 1,
  },
  {
    label: "Unlikely",
    value: 2,
  },
  {
    label: "Neutral",
    value: 3,
  },
  {
    label: "Likely",
    value: 4,
  },
  {
    label: "Very Likely",
    value: 5,
  },
];

export const LIKERT_SCALE_WORK_STYLE = [
  {
    label: "Fully In-person",
    value: 1,
  },
  {
    label: "Mostly In-person",
    value: 2,
  },
  {
    label: "Mixed",
    value: 3,
  },
  {
    label: "Mostly Remote",
    value: 4,
  },
  {
    label: "Fully Remote",
    value: 5,
  },
];

export const successNotificationOptions = {
  position: "bottom-left" as ToastPosition,
  autoClose: 3000,
  progress: undefined,
  progressStyle: { top: 0 },
};

export const PhoneNumbersCodes = {
  AF: { name: "Afghanistan", dial_code: "+93", emoji: "🇦🇫", code: "AF" },
  AX: {
    name: "Aland Islands",
    dial_code: "+358",
    emoji: "🇦🇽",
    code: "AX",
  },
  AL: { name: "Albania", dial_code: "+355", emoji: "🇦🇱", code: "AL" },
  DZ: { name: "Algeria", dial_code: "+213", emoji: "🇩🇿", code: "DZ" },
  AS: {
    name: "AmericanSamoa",
    dial_code: "+1684",
    emoji: "🇦🇸",
    code: "AS",
  },
  AD: { name: "Andorra", dial_code: "+376", emoji: "🇦🇩", code: "AD" },
  AO: { name: "Angola", dial_code: "+244", emoji: "🇦🇴", code: "AO" },
  AI: { name: "Anguilla", dial_code: "+1264", emoji: "🇦🇮", code: "AI" },
  AQ: { name: "Antarctica", dial_code: "+672", emoji: "🇦🇶", code: "AQ" },
  AG: {
    name: "Antigua and Barbuda",
    dial_code: "+1268",
    emoji: "🇦🇬",
    code: "AG",
  },
  AR: { name: "Argentina", dial_code: "+54", emoji: "🇦🇷", code: "AR" },
  AM: { name: "Armenia", dial_code: "+374", emoji: "🇦🇲", code: "AM" },
  AW: { name: "Aruba", dial_code: "+297", emoji: "🇦🇼", code: "AW" },
  AU: { name: "Australia", dial_code: "+61", emoji: "🇦🇺", code: "AU" },
  AT: { name: "Austria", dial_code: "+43", emoji: "🇦🇹", code: "AT" },
  AZ: { name: "Azerbaijan", dial_code: "+994", emoji: "🇦🇿", code: "AZ" },
  BS: { name: "Bahamas", dial_code: "+1242", emoji: "🇧🇸", code: "BS" },
  BH: { name: "Bahrain", dial_code: "+973", emoji: "🇧🇭", code: "BH" },
  BD: { name: "Bangladesh", dial_code: "+880", emoji: "🇧🇩", code: "BD" },
  BB: { name: "Barbados", dial_code: "+1246", emoji: "🇧🇧", code: "BB" },
  BY: { name: "Belarus", dial_code: "+375", emoji: "🇧🇾", code: "BY" },
  BE: { name: "Belgium", dial_code: "+32", emoji: "🇧🇪", code: "BE" },
  BZ: { name: "Belize", dial_code: "+501", emoji: "🇧🇿", code: "BZ" },
  BJ: { name: "Benin", dial_code: "+229", emoji: "🇧🇯", code: "BJ" },
  BM: { name: "Bermuda", dial_code: "+1441", emoji: "🇧🇲", code: "BM" },
  BT: { name: "Bhutan", dial_code: "+975", emoji: "🇧🇹", code: "BT" },
  BO: {
    name: "Bolivia, Plurinational State of",
    dial_code: "+591",
    emoji: "🇧🇴",
    code: "BO",
  },
  BA: {
    name: "Bosnia and Herzegovina",
    dial_code: "+387",
    emoji: "🇧🇦",
    code: "BA",
  },
  BW: { name: "Botswana", dial_code: "+267", emoji: "🇧🇼", code: "BW" },
  BR: { name: "Brazil", dial_code: "+55", emoji: "🇧🇷", code: "BR" },
  IO: {
    name: "British Indian Ocean Territory",
    dial_code: "+246",
    emoji: "🇮🇴",
    code: "IO",
  },
  BN: {
    name: "Brunei Darussalam",
    dial_code: "+673",
    emoji: "🇧🇳",
    code: "BN",
  },
  BG: { name: "Bulgaria", dial_code: "+359", emoji: "🇧🇬", code: "BG" },
  BF: {
    name: "Burkina Faso",
    dial_code: "+226",
    emoji: "🇧🇫",
    code: "BF",
  },
  BI: { name: "Burundi", dial_code: "+257", emoji: "🇧🇮", code: "BI" },
  KH: { name: "Cambodia", dial_code: "+855", emoji: "🇰🇭", code: "KH" },
  CM: { name: "Cameroon", dial_code: "+237", emoji: "🇨🇲", code: "CM" },
  CA: { name: "Canada", dial_code: "+1", emoji: "🇨🇦", code: "CA" },
  CV: { name: "Cape Verde", dial_code: "+238", emoji: "🇨🇻", code: "CV" },
  KY: {
    name: "Cayman Islands",
    dial_code: "+ 345",
    emoji: "🇰🇾",
    code: "KY",
  },
  CF: {
    name: "Central African Republic",
    dial_code: "+236",
    emoji: "🇨🇫",
    code: "CF",
  },
  TD: { name: "Chad", dial_code: "+235", emoji: "🇹🇩", code: "TD" },
  CL: { name: "Chile", dial_code: "+56", emoji: "🇨🇱", code: "CL" },
  CN: { name: "China", dial_code: "+86", emoji: "🇨🇳", code: "CN" },
  CX: {
    name: "Christmas Island",
    dial_code: "+61",
    emoji: "🇨🇽",
    code: "CX",
  },
  CC: {
    name: "Cocos (Keeling) Islands",
    dial_code: "+61",
    emoji: "🇨🇨",
    code: "CC",
  },
  CO: { name: "Colombia", dial_code: "+57", emoji: "🇨🇴", code: "CO" },
  KM: { name: "Comoros", dial_code: "+269", emoji: "🇰🇲", code: "KM" },
  CG: { name: "Congo", dial_code: "+242", emoji: "🇨🇬", code: "CG" },
  CD: {
    name: "Congo, The Democratic Republic of the Congo",
    dial_code: "+243",
    emoji: "🇨🇩",
    code: "CD",
  },
  CK: {
    name: "Cook Islands",
    dial_code: "+682",
    emoji: "🇨🇰",
    code: "CK",
  },
  CR: { name: "Costa Rica", dial_code: "+506", emoji: "🇨🇷", code: "CR" },
  CI: {
    name: "Cote d'Ivoire",
    dial_code: "+225",
    emoji: "🇨🇮",
    code: "CI",
  },
  HR: { name: "Croatia", dial_code: "+385", emoji: "🇭🇷", code: "HR" },
  CU: { name: "Cuba", dial_code: "+53", emoji: "🇨🇺", code: "CU" },
  CY: { name: "Cyprus", dial_code: "+357", emoji: "🇨🇾", code: "CY" },
  CZ: {
    name: "Czech Republic",
    dial_code: "+420",
    emoji: "🇨🇿",
    code: "CZ",
  },
  DK: { name: "Denmark", dial_code: "+45", emoji: "🇩🇰", code: "DK" },
  DJ: { name: "Djibouti", dial_code: "+253", emoji: "🇩🇯", code: "DJ" },
  DM: { name: "Dominica", dial_code: "+1767", emoji: "🇩🇲", code: "DM" },
  DO: {
    name: "Dominican Republic",
    dial_code: "+1849",
    emoji: "🇩🇴",
    code: "DO",
  },
  EC: { name: "Ecuador", dial_code: "+593", emoji: "🇪🇨", code: "EC" },
  EG: { name: "Egypt", dial_code: "+20", emoji: "🇪🇬", code: "EG" },
  SV: { name: "El Salvador", dial_code: "+503", emoji: "🇸🇻", code: "SV" },
  GQ: {
    name: "Equatorial Guinea",
    dial_code: "+240",
    emoji: "🇬🇶",
    code: "GQ",
  },
  ER: { name: "Eritrea", dial_code: "+291", emoji: "🇪🇷", code: "ER" },
  EE: { name: "Estonia", dial_code: "+372", emoji: "🇪🇪", code: "EE" },
  ET: { name: "Ethiopia", dial_code: "+251", emoji: "🇪🇹", code: "ET" },
  FK: {
    name: "Falkland Islands (Malvinas)",
    dial_code: "+500",
    emoji: "🇫🇰",
    code: "FK",
  },
  FO: {
    name: "Faroe Islands",
    dial_code: "+298",
    emoji: "🇫🇴",
    code: "FO",
  },
  FJ: { name: "Fiji", dial_code: "+679", emoji: "🇫🇯", code: "FJ" },
  FI: { name: "Finland", dial_code: "+358", emoji: "🇫🇮", code: "FI" },
  FR: { name: "France", dial_code: "+33", emoji: "🇫🇷", code: "FR" },
  GF: {
    name: "French Guiana",
    dial_code: "+594",
    emoji: "🇬🇫",
    code: "GF",
  },
  PF: {
    name: "French Polynesia",
    dial_code: "+689",
    emoji: "🇵🇫",
    code: "PF",
  },
  GA: { name: "Gabon", dial_code: "+241", emoji: "🇬🇦", code: "GA" },
  GM: { name: "Gambia", dial_code: "+220", emoji: "🇬🇲", code: "GM" },
  GE: { name: "Georgia", dial_code: "+995", emoji: "🇬🇪", code: "GE" },
  DE: { name: "Germany", dial_code: "+49", emoji: "🇩🇪", code: "DE" },
  GH: { name: "Ghana", dial_code: "+233", emoji: "🇬🇭", code: "GH" },
  GI: { name: "Gibraltar", dial_code: "+350", emoji: "🇬🇮", code: "GI" },
  GR: { name: "Greece", dial_code: "+30", emoji: "🇬🇷", code: "GR" },
  GL: { name: "Greenland", dial_code: "+299", emoji: "🇬🇱", code: "GL" },
  GD: { name: "Grenada", dial_code: "+1473", emoji: "🇬🇩", code: "GD" },
  GP: { name: "Guadeloupe", dial_code: "+590", emoji: "🇬🇵", code: "GP" },
  GU: { name: "Guam", dial_code: "+1671", emoji: "🇬🇺", code: "GU" },
  GT: { name: "Guatemala", dial_code: "+502", emoji: "🇬🇹", code: "GT" },
  GG: { name: "Guernsey", dial_code: "+44", emoji: "🇬🇬", code: "GG" },
  GN: { name: "Guinea", dial_code: "+224", emoji: "🇬🇳", code: "GN" },
  GW: {
    name: "Guinea-Bissau",
    dial_code: "+245",
    emoji: "🇬🇼",
    code: "GW",
  },
  GY: { name: "Guyana", dial_code: "+595", emoji: "🇬🇾", code: "GY" },
  HT: { name: "Haiti", dial_code: "+509", emoji: "🇭🇹", code: "HT" },
  VA: {
    name: "Holy See (Vatican City State)",
    dial_code: "+379",
    emoji: "🇻🇦",
    code: "VA",
  },
  HN: { name: "Honduras", dial_code: "+504", emoji: "🇭🇳", code: "HN" },
  HK: { name: "Hong Kong", dial_code: "+852", emoji: "🇭🇰", code: "HK" },
  HU: { name: "Hungary", dial_code: "+36", emoji: "🇭🇺", code: "HU" },
  IS: { name: "Iceland", dial_code: "+354", emoji: "🇮🇸", code: "IS" },
  IN: { name: "India", dial_code: "+91", emoji: "🇮🇳", code: "IN" },
  ID: { name: "Indonesia", dial_code: "+62", emoji: "🇮🇩", code: "ID" },
  IR: {
    name: "Iran, Islamic Republic of Persian Gulf",
    dial_code: "+98",
    emoji: "🇮🇷",
    code: "IR",
  },
  IQ: { name: "Iraq", dial_code: "+964", emoji: "🇮🇷", code: "IQ" },
  IE: { name: "Ireland", dial_code: "+353", emoji: "🇮🇪", code: "IE" },
  IM: { name: "Isle of Man", dial_code: "+44", emoji: "🇮🇲", code: "IM" },
  IL: { name: "Israel", dial_code: "+972", emoji: "🇮🇱", code: "IL" },
  IT: { name: "Italy", dial_code: "+39", emoji: "🇮🇹", code: "IT" },
  JM: { name: "Jamaica", dial_code: "+1876", emoji: "🇯🇲", code: "JM" },
  JP: { name: "Japan", dial_code: "+81", emoji: "🇯🇵", code: "JP" },
  JE: { name: "Jersey", dial_code: "+44", emoji: "🇯🇪", code: "JE" },
  JO: { name: "Jordan", dial_code: "+962", emoji: "🇯🇴", code: "JO" },
  KZ: { name: "Kazakhstan", dial_code: "+77", emoji: "🇰🇿", code: "KZ" },
  KE: { name: "Kenya", dial_code: "+254", emoji: "🇰🇪", code: "KE" },
  KI: { name: "Kiribati", dial_code: "+686", emoji: "🇰🇮", code: "KI" },
  KP: {
    name: "Korea, Democratic People's Republic of Korea",
    dial_code: "+850",
    emoji: "🇰🇵",
    code: "KP",
  },
  KR: {
    name: "Korea, Republic of South Korea",
    dial_code: "+82",
    emoji: "🇰🇷",
    code: "KR",
  },
  KW: { name: "Kuwait", dial_code: "+965", emoji: "🇰🇼", code: "KW" },
  KG: { name: "Kyrgyzstan", dial_code: "+996", emoji: "🇰🇬", code: "KG" },
  LA: { name: "Laos", dial_code: "+856", emoji: "🇱🇦", code: "LA" },
  LV: { name: "Latvia", dial_code: "+371", emoji: "🇱🇻", code: "LV" },
  LB: { name: "Lebanon", dial_code: "+961", emoji: "🇱🇧", code: "LB" },
  LS: { name: "Lesotho", dial_code: "+266", emoji: "🇱🇸", code: "LS" },
  LR: { name: "Liberia", dial_code: "+231", emoji: "🇱🇷", code: "LR" },
  LY: {
    name: "Libyan Arab Jamahiriya",
    dial_code: "+218",
    emoji: "🇱🇾",
    code: "LY",
  },
  LI: {
    name: "Liechtenstein",
    dial_code: "+423",
    emoji: "🇱🇮",
    code: "LI",
  },
  LT: { name: "Lithuania", dial_code: "+370", emoji: "🇱🇹", code: "LT" },
  LU: { name: "Luxembourg", dial_code: "+352", emoji: "🇱🇺", code: "LU" },
  MO: { name: "Macao", dial_code: "+853", emoji: "🇲🇴", code: "MO" },
  MK: { name: "Macedonia", dial_code: "+389", emoji: "🇲🇰", code: "MK" },
  MG: { name: "Madagascar", dial_code: "+261", emoji: "🇲🇬", code: "MG" },
  MW: { name: "Malawi", dial_code: "+265", emoji: "🇲🇼", code: "MW" },
  MY: { name: "Malaysia", dial_code: "+60", emoji: "🇲🇾", code: "MY" },
  MV: { name: "Maldives", dial_code: "+960", emoji: "🇲🇻", code: "MV" },
  ML: { name: "Mali", dial_code: "+223", emoji: "🇲🇱", code: "ML" },
  MT: { name: "Malta", dial_code: "+356", emoji: "🇲🇹", code: "MT" },
  MH: {
    name: "Marshall Islands",
    dial_code: "+692",
    emoji: "🇲🇭",
    code: "MH",
  },
  MQ: { name: "Martinique", dial_code: "+596", emoji: "🇲🇶", code: "MQ" },
  MR: { name: "Mauritania", dial_code: "+222", emoji: "🇲🇷", code: "MR" },
  MU: { name: "Mauritius", dial_code: "+230", emoji: "🇲🇺", code: "MU" },
  YT: { name: "Mayotte", dial_code: "+262", emoji: "🇾🇹", code: "YT" },
  MX: { name: "Mexico", dial_code: "+52", emoji: "🇲🇽", code: "MX" },
  FM: {
    name: "Micronesia, Federated States of Micronesia",
    dial_code: "+691",
    emoji: "🇫🇲",
    code: "FM",
  },
  MD: { name: "Moldova", dial_code: "+373", emoji: "🇲🇩", code: "MD" },
  MC: { name: "Monaco", dial_code: "+377", emoji: "🇲🇨", code: "MC" },
  MN: { name: "Mongolia", dial_code: "+976", emoji: "🇲🇳", code: "MN" },
  ME: { name: "Montenegro", dial_code: "+382", emoji: "🇲🇪", code: "ME" },
  MS: { name: "Montserrat", dial_code: "+1664", emoji: "🇲🇸", code: "MS" },
  MA: { name: "Morocco", dial_code: "+212", emoji: "🇲🇦", code: "MA" },
  MZ: { name: "Mozambique", dial_code: "+258", emoji: "🇲🇿", code: "MZ" },
  MM: { name: "Myanmar", dial_code: "+95", emoji: "🇲🇲", code: "MM" },
  NA: { name: "Namibia", emoji: "🇳🇦", dial_code: "+264", code: "NA" },
  NR: { name: "Nauru", dial_code: "+674", emoji: "🇳🇷", code: "NR" },
  NP: { name: "Nepal", dial_code: "+977", emoji: "🇳🇵", code: "NP" },
  NL: { name: "Netherlands", dial_code: "+31", emoji: "🇳🇱", code: "NL" },
  AN: {
    name: "Netherlands Antilles",
    dial_code: "+599",
    emoji: "🇧🇶",
    code: "AN",
  },
  NC: {
    name: "New Caledonia",
    dial_code: "+687",
    emoji: "🇳🇨",
    code: "NC",
  },
  NZ: { name: "New Zealand", dial_code: "+64", emoji: "🇳🇿", code: "NZ" },
  NI: { name: "Nicaragua", dial_code: "+505", emoji: "🇳🇮", code: "NI" },
  NE: { name: "Niger", dial_code: "+227", emoji: "🇳🇪", code: "NE" },
  NG: { name: "Nigeria", dial_code: "+234", emoji: "🇳🇬", code: "NG" },
  NU: { name: "Niue", dial_code: "+683", emoji: "🇳🇺", code: "NU" },
  NF: {
    name: "Norfolk Island",
    dial_code: "+672",
    emoji: "🇳🇫",
    code: "NF",
  },
  MP: {
    name: "Northern Mariana Islands",
    dial_code: "+1670",
    emoji: "🇲🇵",
    code: "MP",
  },
  NO: { name: "Norway", dial_code: "+47", emoji: "🇳🇴", code: "NO" },
  OM: { name: "Oman", dial_code: "+968", emoji: "🇴🇲", code: "OM" },
  PK: { name: "Pakistan", dial_code: "+92", emoji: "🇵🇰", code: "PK" },
  PW: { name: "Palau", dial_code: "+680", emoji: "🇵🇼", code: "PW" },
  PS: {
    name: "Palestinian Territory, Occupied",
    dial_code: "+970",
    emoji: "🇵🇸",
    code: "PS",
  },
  PA: { name: "Panama", dial_code: "+507", emoji: "🇵🇦", code: "PA" },
  PG: {
    name: "Papua New Guinea",
    dial_code: "+675",
    emoji: "🇵🇬",
    code: "PG",
  },
  PY: { name: "Paraguay", dial_code: "+595", emoji: "🇵🇾", code: "PY" },
  PE: { name: "Peru", dial_code: "+51", emoji: "🇵🇪", code: "PE" },
  PH: { name: "Philippines", dial_code: "+63", emoji: "🇵🇭", code: "PH" },
  PN: { name: "Pitcairn", dial_code: "+872", emoji: "🇵🇳", code: "PN" },
  PL: { name: "Poland", dial_code: "+48", emoji: "🇵🇱", code: "PL" },
  PT: { name: "Portugal", dial_code: "+351", emoji: "🇵🇹", code: "PT" },
  PR: {
    name: "Puerto Rico",
    dial_code: "+1939",
    emoji: "🇵🇷",
    code: "PR",
  },
  QA: { name: "Qatar", dial_code: "+974", emoji: "🇶🇦", code: "QA" },
  RO: { name: "Romania", dial_code: "+40", emoji: "🇷🇴", code: "RO" },
  RU: { name: "Russia", dial_code: "+7", emoji: "🇷🇺", code: "RU" },
  RW: { name: "Rwanda", dial_code: "+250", emoji: "🇷🇼", code: "RW" },
  RE: { name: "Reunion", dial_code: "+262", emoji: "🇷🇪", code: "RE" },
  BL: {
    name: "Saint Barthelemy",
    dial_code: "+590",
    emoji: "🇧🇱",
    code: "BL",
  },
  SH: {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    dial_code: "+290",
    emoji: "🇸🇭",
    code: "SH",
  },
  KN: {
    name: "Saint Kitts and Nevis",
    dial_code: "+1869",
    emoji: "🇰🇳",
    code: "KN",
  },
  LC: {
    name: "Saint Lucia",
    dial_code: "+1758",
    emoji: "🇱🇨",
    code: "LC",
  },
  MF: {
    name: "Saint Martin",
    dial_code: "+590",
    emoji: "🇲🇫",
    code: "MF",
  },
  PM: {
    name: "Saint Pierre and Miquelon",
    dial_code: "+508",
    emoji: "🇵🇲",
    code: "PM",
  },
  VC: {
    name: "Saint Vincent and the Grenadines",
    dial_code: "+1784",
    emoji: "🇻🇨",
    code: "VC",
  },
  WS: { name: "Samoa", dial_code: "+685", emoji: "🇼🇸", code: "WS" },
  SM: { name: "San Marino", dial_code: "+378", emoji: "🇸🇲", code: "SM" },
  ST: {
    name: "Sao Tome and Principe",
    dial_code: "+239",
    emoji: "🇸🇹",
    code: "ST",
  },
  SA: {
    name: "Saudi Arabia",
    dial_code: "+966",
    emoji: "🇸🇦",
    code: "SA",
  },
  SN: { name: "Senegal", dial_code: "+221", emoji: "🇸🇳", code: "SN" },
  RS: { name: "Serbia", dial_code: "+381", emoji: "🇷🇸", code: "RS" },
  SC: { name: "Seychelles", dial_code: "+248", emoji: "🇸🇨", code: "SC" },
  SL: {
    name: "Sierra Leone",
    dial_code: "+232",
    emoji: "🇸🇱",
    code: "SL",
  },
  SG: { name: "Singapore", dial_code: "+65", emoji: "🇸🇬", code: "SG" },
  SK: { name: "Slovakia", dial_code: "+421", emoji: "🇸🇰", code: "SK" },
  SI: { name: "Slovenia", dial_code: "+386", emoji: "🇸🇮", code: "SI" },
  SB: {
    name: "Solomon Islands",
    dial_code: "+677",
    emoji: "🇸🇧",
    code: "SB",
  },
  SO: { name: "Somalia", dial_code: "+252", emoji: "🇸🇴", code: "SO" },
  ZA: { name: "South Africa", dial_code: "+27", emoji: "🇿🇦", code: "ZA" },
  SS: { name: "South Sudan", dial_code: "+211", emoji: "🇸🇸", code: "SS" },
  GS: {
    name: "South Georgia and the South Sandwich Islands",
    dial_code: "+500",
    emoji: "🇬🇸",
    code: "GS",
  },
  ES: { name: "Spain", dial_code: "+34", emoji: "🇪🇸", code: "ES" },
  LK: { name: "Sri Lanka", dial_code: "+94", emoji: "🇱🇰", code: "LK" },
  SD: { name: "Sudan", dial_code: "+249", emoji: "🇸🇩", code: "SD" },
  SR: { name: "Suriname", dial_code: "+597", emoji: "🇸🇷", code: "SR" },
  SJ: {
    name: "Svalbard and Jan Mayen",
    dial_code: "+47",
    emoji: "🇸🇯",
    code: "SJ",
  },
  SZ: { name: "Swaziland", dial_code: "+268", emoji: "🇸🇿", code: "SZ" },
  SE: { name: "Sweden", dial_code: "+46", emoji: "🇸🇪", code: "SE" },
  CH: { name: "Switzerland", dial_code: "+41", emoji: "🇨🇭", code: "CH" },
  SY: {
    name: "Syrian Arab Republic",
    dial_code: "+963",
    emoji: "🇸🇾",
    code: "SY",
  },
  TW: { name: "Taiwan", dial_code: "+886", emoji: "🇹🇼", code: "TW" },
  TJ: { name: "Tajikistan", dial_code: "+992", emoji: "🇹🇯", code: "TJ" },
  TZ: {
    name: "Tanzania, United Republic of Tanzania",
    dial_code: "+255",
    emoji: "🇹🇿",
    code: "TZ",
  },
  TH: { name: "Thailand", dial_code: "+66", emoji: "🇹🇭", code: "TH" },
  TL: { name: "Timor-Leste", dial_code: "+670", emoji: "🇹🇱", code: "TL" },
  TG: { name: "Togo", dial_code: "+228", emoji: "🇹🇬", code: "TG" },
  TK: { name: "Tokelau", dial_code: "+690", emoji: "🇹🇰", code: "TK" },
  TO: { name: "Tonga", dial_code: "+676", emoji: "🇹🇴", code: "TO" },
  TT: {
    name: "Trinidad and Tobago",
    dial_code: "+1868",
    emoji: "🇹🇹",
    code: "TT",
  },
  TN: { name: "Tunisia", dial_code: "+216", emoji: "🇹🇳", code: "TN" },
  TR: { name: "Turkey", dial_code: "+90", emoji: "🇹🇷", code: "TR" },
  TM: {
    name: "Turkmenistan",
    dial_code: "+993",
    emoji: "🇹🇲",
    code: "TM",
  },
  TC: {
    name: "Turks and Caicos Islands",
    dial_code: "+1649",
    emoji: "🇹🇨",
    code: "TC",
  },
  TV: { name: "Tuvalu", dial_code: "+688", emoji: "🇹🇻", code: "TV" },
  UG: { name: "Uganda", dial_code: "+256", emoji: "🇺🇬", code: "UG" },
  UA: { name: "Ukraine", dial_code: "+380", emoji: "🇺🇦", code: "UA" },
  AE: {
    name: "United Arab Emirates",
    dial_code: "+971",
    emoji: "🇦🇪",
    code: "AE",
  },
  GB: {
    name: "United Kingdom",
    dial_code: "+44",
    emoji: "🇬🇧",
    code: "GB",
  },
  US: { name: "United States", dial_code: "+1", emoji: "🇺🇸", code: "US" },
  UY: { name: "Uruguay", dial_code: "+598", emoji: "🇺🇾", code: "UY" },
  UZ: { name: "Uzbekistan", dial_code: "+998", emoji: "🇺🇿", code: "UZ" },
  VU: { name: "Vanuatu", dial_code: "+678", emoji: "🇻🇺", code: "VU" },
  VE: {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    dial_code: "+58",
    emoji: "🇻🇪",
    code: "VE",
  },
  VN: { name: "Vietnam", dial_code: "+84", emoji: "🇻🇳", code: "VN" },
  VG: {
    name: "Virgin Islands, British",
    dial_code: "+1284",
    emoji: "🇻🇬",
    code: "VG",
  },
  VI: {
    name: "Virgin Islands, U.S.",
    dial_code: "+1340",
    emoji: "🇻🇮",
    code: "VI",
  },
  WF: {
    name: "Wallis and Futuna",
    dial_code: "+681",
    emoji: "🇼🇫",
    code: "WF",
  },
  YE: { name: "Yemen", dial_code: "+967", emoji: "🇾🇪", code: "YE" },
  ZM: { name: "Zambia", dial_code: "+260", emoji: "🇿🇲", code: "ZM" },
  ZW: { name: "Zimbabwe", dial_code: "+263", emoji: "🇿🇼", code: "ZW" },
};

export const NON_ADMIN_USER_IDs = [
  1037218,
  1074782,
  1112076,
  1074757,
  1092397, // Kate Phillips
  1046363, // Debbie Peacock
];
