import { useCallback, useEffect, useState, ReactNode } from "react";
import { useAppSelector } from "utils/redux/hooks";
import { Card, Collapse, Dropdown } from "react-bootstrap";
import Button from "app/storybookComponents/Button";
import {
  selectAllDimensionsById,
  selectSkillsById,
} from "app/containers/SkillsGuide/slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
// import Team360ResultPopover from '../TEAM360InsightsModules/Team360ResultPopover';
import { Team360AssessmentResult } from "./types";
import { CONSTRUCT_NAME_KEY } from "./constants";
import { DimensionStrengthsAndWeaknesses } from "app/containers/TeamGuide/types";
import { getEntries } from "utils/helperFunctions";

interface Props {
  title?: string;
  team360Scores?: Team360AssessmentResult;
  strengthsAndWeaknesses?: DimensionStrengthsAndWeaknesses;
  secondDropdown?: ReactNode;
  twoSkillsToWorkOnText?: string;
  stepsText?: string[];
  isExpanded?: boolean;
  setIsExpanded?: (isExpanded: boolean) => void;
}

export default function WhatSkillsCanYourTeamWorkOn({
  team360Scores,
  title = "What Can Your Team Do Next?",
  secondDropdown,
  twoSkillsToWorkOnText = "Two skills you might be interested in:",
  stepsText = [
    "Select to either focus on growth areas or build on your teamwork strengths.",
    "Then choose 1-2 skills to focus on doing or discussing with your team over the next 2 weeks.",
  ],
  isExpanded = false,
  setIsExpanded,
}: Props) {
  const navigate = useNavigate();
  const dimensionsByName = useAppSelector(selectAllDimensionsById);
  const skillsById = useAppSelector(selectSkillsById);
  const [tabShowing, setTabShowing] = useState<"weakness" | "strength">(
    "weakness"
  );
  const [dimensionSkillIds, setDimensionSkillIds] = useState<number[]>([]);
  const [storedDimensionsIds, setStoredDimensionsIds] = useState<{
    weakness: number[];
    strength: number[];
  }>({
    weakness: [],
    strength: [],
  });

  const getDimensionsSkillIds = useCallback(
    (dimensionArr: string[]) => {
      const skillIds: number[] = [];
      dimensionArr.forEach((dimension) => {
        const dimensionSkillIds = Object.values(
          dimensionsByName[dimension]?.skills ?? {}
        )?.map((skill) => skill.skillId);
        skillIds.push(...dimensionSkillIds);
      });
      return skillIds;
    },
    [dimensionsByName]
  );

  useEffect(() => {
    const dimensionArr = storedDimensionsIds[tabShowing];
    if (!dimensionArr?.length) {
      return;
    }
    const skillIds = storedDimensionsIds[tabShowing];
    setDimensionSkillIds(skillIds);
  }, [tabShowing, storedDimensionsIds]);

  useEffect(() => {
    if (!team360Scores?.dimensions) return;

    // We should iterate through all of the dimensions and sort by highest and lowest scoring
    const sortedDimensions = getEntries(CONSTRUCT_NAME_KEY)
      .map(([key, construct]) => {
        const dimension = team360Scores.dimensions?.[construct] ?? 0;
        return [key, dimension] as const;
      })
      .sort(
        ([, dimensionValueA], [, dimensionValueB]) =>
          dimensionValueA - dimensionValueB
      );

    // Then we splice that array getting the first 3 items and storing them into a variable called lowestScoringDimensions
    const lowestScoringDimensions = sortedDimensions
      .slice(0, 3)
      .map(([key]) => key);

    // Then we pop off the top 3 items and store them into a variable called highestScoringDimensions
    const highestScoringDimensions = sortedDimensions
      .slice(-3)
      .map(([key]) => key)
      .reverse();

    const weaknessDimensionsIds = getDimensionsSkillIds(
      lowestScoringDimensions
    );
    const strengthDimensionsIds = getDimensionsSkillIds(
      highestScoringDimensions
    );

    // Then we can use these variables to get the skillIds and then store them in state
    setStoredDimensionsIds({
      weakness: weaknessDimensionsIds,
      strength: strengthDimensionsIds,
    });
  }, [team360Scores, dimensionsByName, getDimensionsSkillIds]);

  const getSkillCards = () => {
    const cardArr = dimensionSkillIds.slice(0, 2).map((skillId) => {
      if (!skillsById[skillId]) {
        return null;
      }
      const { description, id, skill, construct } = skillsById[skillId];
      return (
        <Card key={id}>
          <h4>{skill}</h4>
          <p>{description}</p>
          <div className="mt-auto d-flex align-items-center justify-content-between w-100">
            <Button
              onClick={() => {
                navigate(`/SkillsGuide?skillId=${id}`);
              }}
              variant="secondary-blue"
            >
              See skill guide
            </Button>
            <span className="label-tag grey m-0 row-gap-4px align-items-center">
              {construct}{" "}
              {/* <Team360ResultPopover
                title={construct}
                bodyText={scoringDimensionText ?? ''}
                overallScore={Number(dimensionOverallScore)}
                popoverFooter={
                  <div>
                    <Button
                      variant="secondary-blue"
                      onClick={() => {
                        navigate(
                          `/SkillsGuide?factor=${factor}&dimension=${construct}`
                        );
                      }}
                    >
                      See dimension guide
                    </Button>
                  </div>
                }
              /> */}
            </span>
          </div>
        </Card>
      );
    });
    return cardArr;
  };

  const onGetNewSkillCards = () => {
    // If there are more than 3 cards remaining then splice off the first two
    if (dimensionSkillIds.length > 2) {
      const newSkillIds = dimensionSkillIds.slice(2);

      // So that we always have at least 2 cards if there is only one card left then we need to add the original array to the end of the new array
      if (newSkillIds.length === 1) {
        setDimensionSkillIds([
          ...newSkillIds,
          ...storedDimensionsIds[tabShowing],
        ]);
      } else {
        setDimensionSkillIds(newSkillIds);
      }
      return;
    }

    // If there are less than 2 cards then we need to reset the skillIds to the original array
    setDimensionSkillIds(storedDimensionsIds[tabShowing]);
  };

  const getDropdown = () => {
    const onDropdownSelect = (eventKey: string | null) => {
      if (eventKey === "strength" || eventKey === "weakness") {
        setTabShowing(eventKey);
      }
    };
    return (
      <Dropdown onSelect={onDropdownSelect}>
        <Dropdown.Toggle
          variant="light"
          id="dropdown-basic"
          className="dropdown-menu-240px"
        >
          {tabShowing === "strength"
            ? "Build on a strength"
            : "Focus on a growth area"}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-240px">
          <Dropdown.Item eventKey="weakness">
            Focus on a growth area
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item eventKey="strength">Build on a strength</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  // const {
  //   lowestScoringDimensionText,
  //   lowestScoringDimensionScore,
  //   highestScoringDimensionText,
  //   highestScoringDimensionScore,
  // } = strengthsAndWeaknesses ?? {};
  // const scoringDimensionText =
  //   tabShowing === 'weakness'
  //     ? lowestScoringDimensionText
  //     : highestScoringDimensionText;
  // const dimensionOverallScore =
  //   tabShowing === 'weakness'
  //     ? lowestScoringDimensionScore
  //     : highestScoringDimensionScore;

  return (
    <Card
      className="column-gap-20px"
      style={{ padding: "20px" }}
      role={isExpanded && setIsExpanded ? undefined : "button"}
      onClick={() => {
        if (isExpanded) return;
        setIsExpanded?.(true);
      }}
    >
      <div className="d-flex justify-content-between align-items-start">
        <div className="column-gap-8px">
          <h2>{title}</h2>

          <Collapse in={isExpanded}>
            <ol
              style={{
                paddingLeft: "20px",
                margin: 0,
              }}
            >
              {stepsText.map((step, index) => (
                <li key={index}>
                  <p>{step}</p>
                </li>
              ))}
            </ol>
          </Collapse>
        </div>
        {setIsExpanded ? (
          <div>
            <Button
              onClick={() => {
                setIsExpanded?.(!isExpanded);
              }}
              variant="secondary-blue"
              className="border-0"
            >
              {!isExpanded ? "See details" : "Collapse"}
              <FontAwesomeIcon
                icon={`caret-${isExpanded ? "up" : "down"}`}
                className="ms-2"
              />
            </Button>
          </div>
        ) : null}
      </div>
      <Collapse in={isExpanded}>
        <div className="column-gap-20px">
          <div className="row-gap-20px">
            {getDropdown()} {secondDropdown ?? null}
          </div>

          <div>
            <p className="grey-text">
              <b>{twoSkillsToWorkOnText}</b>
            </p>
            <div
              className="skills-cards-container"
              style={{
                gridTemplateColumns: "repeat(2, 1fr)",
              }}
            >
              {getSkillCards()}
            </div>
          </div>
          <div>
            <Button onClick={onGetNewSkillCards} variant="secondary-blue">
              Get another suggestion
              <FontAwesomeIcon icon="arrows-rotate" className="ms-2" />
            </Button>
          </div>
        </div>
      </Collapse>
    </Card>
  );
}
