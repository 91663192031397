import { memo } from 'react';
import Button from 'app/storybookComponents/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NavigateBackButton = () => {
  const location = useLocation();
  const navigate = useNavigate();
  if (location.key === 'default') {
    return null;
  }
  return (
    <Button
      onClick={() => navigate(-1)}
      variant="secondary-blue"
      style={{ border: 'none' }}
    >
      <FontAwesomeIcon icon="arrow-left" className="me-2" /> Back
    </Button>
  );
};

export default memo(NavigateBackButton);
