import { IconName } from "@fortawesome/fontawesome-svg-core";

// This is the format that we are saving to state
export type TSkillsUpdated = {
  [factor: string]: {
    [construct: string]: { [skillId: number]: TSkill };
  };
};

// This is the type of the data that comes back from the API
export type TSkill = {
  id: number;
  factor: string;
  construct: string;
  skill: string;
  title: string;
  description: string;
  quote: string;
  introduction: string;
  whyThisMatters: string;
  examples: string;
  someThingsToDo: string;
  someThingsToAvoid: string;
  reflectiveQuestions: string;
  teamQuestions: string;
  inSummary: string;
  feedbackSubmitted?: boolean;
};

export const isTSkillProp = (prop: string): prop is keyof TSkill => {
  return (
    prop === "id" ||
    prop === "factor" ||
    prop === "construct" ||
    prop === "skill" ||
    prop === "title" ||
    prop === "quote" ||
    prop === "introduction" ||
    prop === "whyThisMatters" ||
    prop === "examples" ||
    prop === "someThingsToDo" ||
    prop === "someThingsToAvoid" ||
    prop === "reflectiveQuestions" ||
    prop === "teamQuestions" ||
    prop === "inSummary"
  );
};

export type EditSkillPayload = Partial<
  Pick<
    TSkill,
    | "title"
    | "quote"
    | "introduction"
    | "whyThisMatters"
    | "examples"
    | "someThingsToDo"
    | "someThingsToAvoid"
    | "reflectiveQuestions"
    | "teamQuestions"
    | "inSummary"
  >
>;

// This is the type of the data that comes back from the API
export type TDimension = {
  id: number;
  dimensionId: number;
  title: string;
  introduction: string;
  highPerformingTeamsDo: string[];
  highPerformingTeamsAvoid: string[];
  buildOnStrengths: string[];
  addressWeaknesses: string[];
  actionSteps: string[];
  guideForTeamLeaders: string[];
  guideForTeamMembers: string[];
  factorId: number;
  name: string;
  internalConstructName: string;
  feedbackSubmitted?: boolean;
  skills?: {
    [skillId: number]: {
      skillId: number;
      dimensionId: number;
      name: string;
      description: string;
    };
  };
  whyThisMatters: string;
  whatSuccessLooksLike: string;
  tipsForBeingGreatAtThis: string[];
  commonMistakes: string[];
  shortInsights: {
    [key: number]: string;
  };
};

export const isTDimensionProp = (prop: string): prop is keyof TDimension => {
  return (
    prop === "id" ||
    prop === "dimensionId" ||
    prop === "title" ||
    prop === "introduction" ||
    prop === "highPerformingTeamsDo" ||
    prop === "highPerformingTeamsAvoid" ||
    prop === "buildOnStrengths" ||
    prop === "addressWeaknesses" ||
    prop === "actionSteps" ||
    prop === "guideForTeamLeaders" ||
    prop === "guideForTeamMembers" ||
    prop === "factorId" ||
    prop === "name" ||
    prop === "internalConstructName" ||
    prop === "whyThisMatters" ||
    prop === "whatSuccessLooksLike" ||
    prop === "tipsForBeingGreatAtThis" ||
    prop === "commonMistakes" ||
    prop === "shortInsights"
  );
};

export type EditDimensionPayload = Partial<
  Pick<
    TDimension,
    | "title"
    | "introduction"
    | "highPerformingTeamsDo"
    | "highPerformingTeamsAvoid"
    | "buildOnStrengths"
    | "addressWeaknesses"
    | "actionSteps"
    | "guideForTeamLeaders"
    | "guideForTeamMembers"
  >
>;

export type TSkillFocus = {
  skillFocusId: number;
  skillId: number;
  teamId: number;
  goal: string;
  successCriteria: string;
  targetDate: string;
};

export type SkillFocusPayload = Pick<
  TSkillFocus,
  "skillId" | "goal" | "successCriteria" | "targetDate"
>;

export type SkillGuideNavSchema = {
  [tabName: string]: {
    title: string;
    key: string;
    icon?: IconName;
    isList?: boolean;
  }[];
};

export type TFactor = {
  id: number;
  factorId: number;
  introduction: string;
  whyThisMatters: string;
  whatSuccessLooksLike: string;
  keyDimensionIds: string;
  keyEnablers: string;
  keyBlockers: string;
  reflectionQuestionsForTeamLeaders: string;
  reflectionQuestionsForTeamMembers: string;
  questionsToAskIfYourStruggling: string;
  questionsToAskIfAnotherTeamIsStruggling: string;
  keyDimensions: {
    dimensionId: number;
    name: string;
  }[];
};
