import { useState, useRef } from "react";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";

type Option = {
  label: string;
  value: number | string;
} & {
  [key: string]: any;
};

const Input = (props: any) => <components.Input {...props} isHidden={false} />;

interface Props {
  options: Option[];
  onChange: (option: Option | null) => void;
  value?: Option | null;
  placeHolder?: string;
  onCaptureInputValue?: (inputValue: string) => void; // This value will be used incase the user changes the input value but does not hit enter or select an option
  creatableText?: string;
}

export default function CreatableSingleSelect({
  options,
  placeHolder = "Enter email addresses...",
  onChange: onChangeProp,
  value = null,
  onCaptureInputValue,
  creatableText = "Invite",
}: Readonly<Props>) {
  const [inputValue, setInputValue] = useState("");
  const selectRef = useRef<any>(null);

  const onInputChange = (
    incomingInputValue: string,
    { action }: { action: string; prevInputValue: string }
  ) => {
    if (action === "input-change") {
      setInputValue(incomingInputValue);
      return;
    }

    // When we unfocus then we save whatever the user type in the input if the user passed in the onCaptureInputValue prop
    if (
      action === "menu-close" &&
      selectRef?.current?.inputRef?.value !== value?.label
    ) {
      onCaptureInputValue?.(inputValue);
    }
  };

  const onChange = (option: Option | null) => {
    setInputValue(option?.label ?? "");
    onChangeProp?.(option);
  };

  const onFocus = () => value && selectRef.current?.select?.inputRef?.select();

  return (
    <CreatableSelect<Option>
      ref={selectRef}
      options={options}
      value={value}
      inputValue={inputValue}
      onInputChange={onInputChange}
      onChange={onChange}
      onFocus={onFocus}
      controlShouldRenderValue={!!value}
      components={{
        Input,
      }}
      formatCreateLabel={(e) => `${creatableText} "${e}"`}
      noOptionsMessage={() => null}
      isClearable
      isSearchable
      placeholder={placeHolder}
      styles={{
        menu: (base: any) => ({
          ...base,
          marginTop: 0,
        }),
      }}
    />
  );
}
