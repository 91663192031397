import { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { useAppDispatch } from "utils/redux/hooks";
import Button from "app/storybookComponents/Button";
import { updateUserInfo } from "app/containers/Global/slice";
import Toggle from "app/components/Toggle";
// import { PhoneNumbersCodes } from 'utils/helpers/constants';

interface Props {
  user: null | { emailAddress: string; firstName: string; lastName: string };
  userInfo: null | {
    linkedInUrl?: string;
    jobTitle?: string;
    telephoneNumber?: string;
    secondaryEmail?: string;
    gender?: string;
  };
  modalShowing: null | "Contact" | "Personal";
  isLoading?: boolean;
  hideModal: () => void;
}

export default function EditInformationModal({
  hideModal,
  modalShowing,
  user,
  userInfo,
  isLoading,
}: Props) {
  const [firstName, setFirstName] = useState(user?.firstName || "");
  const [lastName, setLastName] = useState(user?.lastName || "");
  const [jobTitle, setJobTitle] = useState(userInfo?.jobTitle || "");
  const [gender, setGender] = useState(userInfo?.gender || "");
  const [linkedInUrl, setLinkedInUrl] = useState<string | false>(
    userInfo?.linkedInUrl || false
  );
  const [telephoneNumber, setTelephoneNumber] = useState<string | false>(
    userInfo?.telephoneNumber || false
  );
  const [secondaryEmail, setSecondaryEmail] = useState<string | false>(
    userInfo?.secondaryEmail || false
  );

  const dispatch = useAppDispatch();
  const getContactForm = () => (
    <>
      <Form.Group className="form-group">
        <Form.Label>Work Email</Form.Label>
        <Form.Control
          placeholder="Example@gmail.com"
          name="workEmail"
          disabled
          value={user?.emailAddress || ""}
        />
      </Form.Group>

      <Form.Group className="form-group">
        <div className="form-label-with-toggle">
          <Form.Label>LinkedIn</Form.Label>
          <Toggle
            isOn={linkedInUrl !== false}
            handleToggle={() => {
              setLinkedInUrl(
                linkedInUrl === false ? userInfo?.linkedInUrl || "" : false
              );
            }}
          />
        </div>
        {linkedInUrl !== false ? (
          <Form.Control
            placeholder="https://www.linkedin.com/in/example/"
            name="linkedIn"
            value={linkedInUrl}
            onChange={(e) => setLinkedInUrl(e.target.value)}
          />
        ) : null}
      </Form.Group>

      <Form.Group className="form-group">
        <div className="form-label-with-toggle">
          <Form.Label>Phone Number</Form.Label>
          <Toggle
            isOn={telephoneNumber !== false}
            handleToggle={() => {
              setTelephoneNumber(telephoneNumber === false ? "" : false);
            }}
          />
        </div>
        {telephoneNumber !== false ? (
          <Form.Control
            placeholder="XXX-XXX-XXXX"
            name="telephoneNumber"
            value={telephoneNumber}
            onChange={(e) => setTelephoneNumber(e.target.value)}
          />
        ) : null}
      </Form.Group>
      <Form.Group className="form-group">
        <div className="form-label-with-toggle">
          <Form.Label>Secondary Email</Form.Label>
          <Toggle
            isOn={secondaryEmail !== false}
            handleToggle={() => {
              setSecondaryEmail(
                secondaryEmail === false
                  ? userInfo?.secondaryEmail || ""
                  : false
              );
            }}
          />
        </div>
        {secondaryEmail !== false ? (
          <Form.Control
            placeholder="sample@domail.com"
            name="secondaryEmail"
            value={secondaryEmail}
            onChange={(e) => setSecondaryEmail(e.target.value)}
          />
        ) : null}
      </Form.Group>
    </>
  );

  const getPersonalInformationForm = () => (
    <>
      <Form.Group className="form-group">
        <Form.Label>First Name</Form.Label>
        <Form.Control
          placeholder="Stanley"
          required
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="form-group">
        <Form.Label>Last Name</Form.Label>
        <Form.Control
          placeholder="Hudson"
          required
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="form-group">
        <Form.Label>Position</Form.Label>
        <Form.Control
          placeholder="UI/UX Designer"
          value={jobTitle}
          onChange={(e) => setJobTitle(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="form-group">
        <Form.Label>Gender</Form.Label>
        <Form.Select onChange={(e) => setGender(e.target.value)} value={gender}>
          <option value="">Select Gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="non-binary">Non-binary</option>
          <option value="do-not-disclose">Choose not to disclose</option>
        </Form.Select>
      </Form.Group>
    </>
  );

  const getModalContent = () => {
    switch (modalShowing) {
      case "Contact":
        return getContactForm();
      case "Personal":
        return getPersonalInformationForm();
      default:
        return null;
    }
  };

  const getPayload = () => {
    switch (modalShowing) {
      case "Contact":
        return {
          linkedInUrl: linkedInUrl || "",
          telephoneNumber: telephoneNumber || "",
          secondaryEmail: secondaryEmail || "",
        };
      case "Personal":
        return {
          firstName: firstName,
          lastName: lastName,
          jobTitle: jobTitle,
          gender: gender,
        };
      default:
        return {};
    }
  };

  const isSaveDisabled = () => {
    if (isLoading) return true;
    if (modalShowing === "Personal") {
      return !jobTitle || !gender;
    }
    return false;
  };

  const onSave = () => {
    dispatch(updateUserInfo(getPayload()));
  };

  return (
    <Modal
      show={modalShowing !== null}
      onHide={hideModal}
      className="edit-information-modal"
      size="lg"
    >
      <div className="modal-title-row">
        <h2>Edit {modalShowing} Information</h2>
        <Button
          onClick={hideModal}
          variant={"secondary-blue"}
          style={{ border: "none" }}
          xIcon
        />
      </div>
      <Form
        className="edit-information-form"
        onSubmit={(e) => {
          e.preventDefault();
          onSave();
        }}
      >
        <div className="edit-information-modal-body">{getModalContent()}</div>
        <div className="edit-information-modal-footer">
          <Button type="submit" disabled={isSaveDisabled()}>
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
