import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'app/storybookComponents/Button';
import Collapse from 'react-bootstrap/Collapse';
import { useEffect, useState } from 'react';
import TeamAssessmentIcon from './TeamAssessmentIcon';
import {
  getTeam360FactorOrDimensionDescription,
  getTeam360Score,
  getVerbalTag,
  getMainAccordionValue,
} from './helpers';
import ShortCompareGraph from 'app/storybookComponents/Charts/ShortCompareGraph';
import { isTeam360Factor } from './types';
import HelpPopover from './HelpPopover';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface Value {
  name: string;
  yourScore?: number;
  yourTeamScore?: number;
  yourCompanyScore?: number;
  yourDepartmentScore?: number;
  description?: string;
  redirectPath?: string;
  buttonText?: string;
  iconName?: string;
}

interface Props {
  values: Value[];
}

export default function Team360ResultsCardAccordion2({ values }: Props) {
  const navigate = useNavigate();
  const [openedAccordion, setOpenedAccordion] = useState<number | null>(null);

  useEffect(() => {
    // If the values change then we need to update the opened to null
    setOpenedAccordion(null);
  }, [values]);

  const getBars = (
    rawTeamScore?: number,
    rawCompanyScore?: number,
    rawUserScore?: number,
    rawDepartmentScore?: number
  ) => {
    const teamScore = Math.round(getTeam360Score(rawTeamScore ?? 0));
    const companyScore = Math.round(getTeam360Score(rawCompanyScore ?? 0));
    const userScore = Math.round(getTeam360Score(rawUserScore ?? 0));
    const departmentScore = Math.round(
      getTeam360Score(rawDepartmentScore ?? 0)
    );
    const bars: {
      label: string;
      value: number;
      dull?: boolean;
    }[] = [];

    if (rawUserScore) {
      bars.push({
        label: 'Your score',
        value: userScore,
        dull: true,
      });
    }
    if (rawTeamScore) {
      bars.push({
        label: 'Your team’s average',
        value: teamScore,
      });
    }
    if (rawCompanyScore) {
      bars.push({
        label: 'Your organization’s average',
        value: companyScore,
        dull: true,
      });
    }
    if (rawDepartmentScore) {
      bars.push({
        label: 'Your department’s average',
        value: departmentScore,
      });
    }

    return bars;
  };

  const getDescriptionBox = (idx: number, value: Value): React.JSX.Element => {
    const title = value.name;
    const rawTeamScore = value.yourTeamScore;
    const rawCompanyScore = value.yourCompanyScore;
    const rawUserScore = value.yourScore;
    const rawDepartmentScore = value.yourDepartmentScore;
    const description = value.description;

    const isExpanded = openedAccordion === idx;
    const bars = getBars(
      rawTeamScore,
      rawCompanyScore,
      rawUserScore,
      rawDepartmentScore
    );
    const mainScore = getMainAccordionValue(
      rawTeamScore,
      rawUserScore,
      rawDepartmentScore,
      rawCompanyScore
    );
    const myScore = getTeam360Score(mainScore);

    return (
      <div className="team-360-accordion-container" key={title}>
        <div
          className="team-360-accordion-header"
          onClick={() => setOpenedAccordion(isExpanded ? null : idx)}
          role="button"
        >
          <div className="row-gap-12px align-items-center">
            {isTeam360Factor(value.iconName ?? title) ? (
              <div className="assessment-icon-holder">
                <TeamAssessmentIcon
                  name={value.iconName ?? title}
                  color="navy"
                />
              </div>
            ) : null}
            <h3>{title}</h3>
            <div>
              <HelpPopover
                title={title}
                text={getTeam360FactorOrDimensionDescription(title)}
              />
            </div>
          </div>
          <div className="row-gap-12px align-items-center">
            {getVerbalTag(myScore)}
            <p>
              <b>{myScore}</b>
            </p>
            <FontAwesomeIcon icon={`caret-${isExpanded ? 'up' : 'down'}`} />
          </div>
        </div>
        <Collapse in={isExpanded}>
          <div className="column-gap-16px">
            {description ? <p>{description}</p> : null}
            <ShortCompareGraph bars={bars} />
            {value.redirectPath && value.buttonText ? (
              <div>
                <Button
                  variant="secondary-blue"
                  onClick={() =>
                    value.redirectPath && navigate(value.redirectPath)
                  }
                >
                  {value.buttonText}
                  <FontAwesomeIcon icon="arrow-right" className="ms-2" />
                </Button>
              </div>
            ) : null}
          </div>
        </Collapse>
      </div>
    );
  };

  return (
    <Card className="team-360-accordion">
      {values.map((value, idx) => getDescriptionBox(idx, value))}
    </Card>
  );
}
