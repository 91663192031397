import { useState, ReactElement, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Collapse, Table } from 'react-bootstrap';
import {
  TableStructureError,
  UploadState,
  TableRowData,
} from 'app/containers/HiddenAdminConsole/types';
import Loading from 'app/storybookComponents/Loading';
import Button from 'app/storybookComponents/Button';
import IncorrectHeaders from 'app/storybookComponents/IncorrectHeaders';

interface Props {
  // Required Props
  tableName: string;
  tableDescription: string;
  tableData: TableRowData;
  tableTags: string;

  // Optional Props
  rowsAdded?: number | null;
  structureSuccess?: boolean;
  structureError?: TableStructureError;
  onUploadClick?: (tableName: string) => void;
  onDownloadClick?: (tableName: string) => void;
  uploadState?: UploadState;
}

export default function TableCard({
  // tagName,
  tableName,
  tableDescription,
  tableData,
  onUploadClick,
  onDownloadClick,
  structureError,
  uploadState,
  rowsAdded,
}: Props) {
  const [viewPreview, setViewPreview] = useState(false);
  const [tableColumns, setTableColumns] = useState<string[]>([]);

  useEffect(() => {
    if (tableData === undefined || tableData.length === 0) {
      return;
    }
    setTableColumns(Object.keys(tableData[0]));
  }, [tableData]);

  const getDefaultTablePreview = () => {
    if (tableData === undefined || tableData.length === 0) {
      return;
    }
    const tableHeaders = getTableHeaders(tableColumns);
    const tableRows: ReactElement[] = [];

    tableData.forEach((tableRow, idx) => {
      if (idx >= 2) return;

      tableRows.push(
        <tr key={idx}>
          {Object.values(tableRow).map((tableData, idy) => (
            <td key={idy}>{tableData}</td>
          ))}
        </tr>
      );
    });

    return (
      <>
        <p>Table Description - {tableDescription}</p>
        <p
          role="button"
          onClick={() => setViewPreview(!viewPreview)}
          className="preview-toggle"
        >
          Table Preview{' '}
          <FontAwesomeIcon
            icon={viewPreview ? 'caret-up' : 'caret-down'}
            className="ms-1"
          />
        </p>
        <Collapse in={viewPreview}>
          <div style={{ overflowX: 'scroll' }}>
            <Table bordered>
              <thead>
                <tr>{tableHeaders}</tr>
              </thead>
              <tbody>{tableRows}</tbody>
            </Table>
          </div>
        </Collapse>
      </>
    );
  };

  const getTableHeaders = (
    tableColumns: string[],
    mismatchPositions?: number[]
  ): ReactElement[] => {
    const tableHeaders: ReactElement[] = [];
    const copy = mismatchPositions && [...mismatchPositions]; // If this is causing problems just change to use an includes.
    tableColumns.forEach((columnName, idx) => {
      const warning = copy && copy[0] === idx ? 'warning' : '';

      tableHeaders.push(
        <th key={idx} className={`admin-console-table-header ${warning}`}>
          {columnName}
        </th>
      );

      // If we just colored the cell remove that element from the list
      if (warning && copy) {
        copy.shift();
      }
    });
    return tableHeaders;
  };

  const getUploadStateComponent = () => {
    switch (uploadState) {
      case 'Uploading':
      case 'Checking':
        return <Loading />;
      case 'CheckingSuccess':
        return (
          <p>
            All column names match
            <FontAwesomeIcon
              icon="check-circle"
              color="green"
              className="ms-2"
            />
          </p>
        );
      case 'UploadingError':
        return 'We have an oversize error';
      case 'CheckingError':
        return <IncorrectHeaders structureError={structureError} />;

      default:
        return getDefaultTablePreview();
    }
  };

  const getRowsAdded = () => {
    if (typeof rowsAdded !== 'number' || rowsAdded === 0) {
      return null;
    }
    return `(${rowsAdded > 0 ? '+' : ''}${rowsAdded} rows)`;
  };

  const getRowLength = () => {
    if (!tableData) {
      return null;
    }
    if (typeof rowsAdded === 'number') {
      return tableData.length + rowsAdded;
    }

    return tableData.length;
  };

  return (
    <Card className="admin-console-table-card p-3 mb-3">
      {/* <span>{tagName}</span> */}
      <h3>{tableName}</h3>
      {getUploadStateComponent()}
      <p>
        {uploadState === 'CheckingSuccess' ? 'New' : ''}
        Total:{' '}
        <b>
          {tableColumns.length} columns and {getRowLength()} rows{' '}
          {getRowsAdded()}
        </b>
      </p>
      {onUploadClick && onDownloadClick ? (
        <div>
          <Button className="me-2" onClick={() => onUploadClick(tableName)}>
            <FontAwesomeIcon icon="upload" className="me-2" />
            Upload Data
          </Button>
          <Button
            variant="secondary-blue"
            onClick={() => onDownloadClick(tableName)}
          >
            <FontAwesomeIcon icon="download" className="me-2" />
            Download Data
          </Button>
        </div>
      ) : null}
    </Card>
  );
}
