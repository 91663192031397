import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import { Card } from "react-bootstrap";
import Button from "app/storybookComponents/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  iconName?: IconName;
  iconPrefix?: IconPrefix;
  imageIcon?: React.ReactNode;
  listSpan?: string;
  title: string;
  description: string;
  actionButton: {
    onClick: () => void;
    text: string;
  };
  secondaryButton?: {
    onClick: () => void;
    text: string;
  };
  isWholeCardDisabled?: boolean;
  cornerTag?: JSX.Element | null;
}

export default function QuickActionCard({
  iconName,
  iconPrefix = "fas",
  title,
  actionButton,
  secondaryButton,
  description,
  listSpan,
  isWholeCardDisabled,
  imageIcon,
  cornerTag,
}: Readonly<Props>) {
  const getCardClassName = () => {
    if (isWholeCardDisabled) {
      return "quick-action-card disabled";
    }
    return "quick-action-card";
  };

  const getIconSquare = () => {
    if (imageIcon) {
      return <div className="small-icon-square">{imageIcon}</div>;
    }

    if (iconName) {
      return (
        <div className="small-icon-square">
          <FontAwesomeIcon icon={[iconPrefix, iconName]} className="icon" />
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      {cornerTag ? (
        <div className="quick-action-card-corner-tag">{cornerTag}</div>
      ) : null}
      <Card className={getCardClassName()}>
        {getIconSquare()}
        {listSpan ? <span className="list-span">{listSpan}</span> : null}
        <div className="column-gap-8px">
          <p>
            <b>{title}</b>
          </p>
          <p
            style={{
              color: "#53565a",
            }}
          >
            {description}
          </p>
        </div>
        <Button
          variant="secondary-blue"
          className="mt-auto"
          onClick={() => {
            if (isWholeCardDisabled) {
              return;
            }
            actionButton.onClick();
          }}
        >
          {actionButton.text}
        </Button>
        {secondaryButton ? (
          <Button
            variant="secondary-blue"
            className="border-0"
            onClick={() => {
              if (isWholeCardDisabled) {
                return;
              }
              secondaryButton.onClick();
            }}
          >
            {secondaryButton.text}
          </Button>
        ) : null}
      </Card>
    </div>
  );
}
