import MailSentIllustration from "resources/images/illustration-invite-sent.png";
import Button from "app/storybookComponents/Button";

interface Props {
  totalUsersInvited: number;
  onHide: () => void;
}

export default function InvitationSent({ totalUsersInvited, onHide }: Props) {
  return (
    <div className="invitation-sent">
      <div>
        <img src={MailSentIllustration} alt="People" />
      </div>
      <span>{totalUsersInvited} team members have been invited</span>
      <p>
        Invited users will receive an email with an invitation to join Develop
        by Criteria. Once they are logged in, they will be able to view this
        team.
      </p>
      <div>
        <Button onClick={onHide} variant="secondary-blue">
          Close
        </Button>
      </div>
    </div>
  );
}
