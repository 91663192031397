import { ClassificationArray } from "app/containers/AdminConsole/types";
import Button from "app/storybookComponents/Button";
import { HowYourDepartmentComparesDepartment } from "app/containers/DepartmentInsightReport/types";
import StackBarChart from "app/storybookComponents/Charts/StackBarChart";
import { Card, Collapse } from "react-bootstrap";
import TeamSummaryKey from "./TeamSummaryKey";
import SmallUserCard from "./SmallUserCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import { selectDepartments } from "app/containers/AdminConsole/slice";
import { useAppSelector } from "utils/redux/hooks";
import { getDepartmentLeadId } from "app/containers/AdminConsole/helpers";
import DimensionLinkedText from "app/components/TextWithLink/DimensionLinkedText";

interface Props {
  title?: string;
  cardDescriptions?: string[];
  snapShotText?: string;
  classificationArray?: ClassificationArray;
  highestScoringDepartment?: HowYourDepartmentComparesDepartment;
  lowestScoringDepartment?: HowYourDepartmentComparesDepartment;
  onSeeComparisonReport?: () => void;
  showThisDepartmentKey?: boolean;
  isExpanded?: boolean;
  setIsExpanded?: (isExpanded: boolean) => void;
}

export default function DepartmentSummaryCard({
  title = "How Does Your Department Compare?",
  cardDescriptions,
  classificationArray,
  highestScoringDepartment,
  lowestScoringDepartment,
  snapShotText,
  onSeeComparisonReport,
  showThisDepartmentKey,
  isExpanded = false,
  setIsExpanded,
}: Props) {
  // -------------------------- Redux selectors --------------------------
  const departments = useAppSelector(selectDepartments);

  // -------------------------- Helper functions --------------------------
  const totalText = useMemo(() => {
    if (!classificationArray) {
      return null;
    }
    let departmentCount = 0;
    Object.values(classificationArray).forEach((value) => {
      departmentCount += value;
    });
    const departmentOrDepartments =
      departmentCount === 1 ? "department" : "departments";
    return `${departmentCount} total ${departmentOrDepartments}`;
  }, [classificationArray]);

  const getLowAndHighCard = (
    label: string,
    scoringDimension?: HowYourDepartmentComparesDepartment
  ) => {
    const departmentId = scoringDimension?.departmentId;
    const departmentName = departmentId ? departments[departmentId]?.name : "";
    const leaderId = getDepartmentLeadId(departmentId, departments);
    return (
      <div className="align-items-start column-gap-20px">
        <span className="list-span">{label}</span>
        <div className="column-gap-12px">
          <h3>
            {departmentName}
            {departmentName?.toLocaleLowerCase().includes("department")
              ? ""
              : " Department"}
          </h3>
          <p>Overall Score: {Math.round(scoringDimension?.overall ?? 0)}</p>
        </div>
        {leaderId ? <SmallUserCard userAccountId={leaderId} /> : null}
      </div>
    );
  };

  return (
    <Card
      style={{
        padding: "20px",
      }}
      className="analytics-summary-card"
      role={isExpanded && setIsExpanded ? undefined : "button"}
      onClick={() => {
        if (isExpanded) return;
        setIsExpanded?.(true);
      }}
    >
      <div className="column-gap-8px">
        <div className="d-flex justify-content-between align-items-start">
          <div>
            <h2>{title}</h2>
          </div>
          {setIsExpanded ? (
            <div>
              <Button
                onClick={() => {
                  setIsExpanded?.(!isExpanded);
                }}
                variant="secondary-blue"
                className="border-0"
              >
                {!isExpanded ? "See details" : "Collapse"}
                <FontAwesomeIcon
                  icon={`caret-${isExpanded ? "up" : "down"}`}
                  className="ms-2"
                />
              </Button>
            </div>
          ) : null}
        </div>
        <Collapse in={isExpanded}>
          <div className="column-gap-8px">
            {cardDescriptions?.map((description) => (
              <p key={description}>{description}</p>
            ))}
          </div>
        </Collapse>
      </div>
      <Collapse in={isExpanded}>
        <div className="column-gap-20px">
          <div className="column-gap-12px">
            <div className="d-flex align-items-center justify-content-between">
              <p className="count">
                <b>{totalText}</b>
              </p>
              {showThisDepartmentKey ? (
                <p className="row-gap-8px">
                  <b>
                    <FontAwesomeIcon icon="star" />
                  </b>
                  This department
                </p>
              ) : null}
            </div>
            <StackBarChart
              high={classificationArray?.["high-performing"]}
              medium={classificationArray?.healthy}
              low={classificationArray?.["needs-help"]}
            />
            <TeamSummaryKey type="department" />
          </div>
          {highestScoringDepartment?.overall ===
          lowestScoringDepartment?.overall ? null : (
            <div className="low-and-high-summary-section">
              {getLowAndHighCard(
                "Top Scoring Department",
                highestScoringDepartment
              )}
              <div className="vertical-line" />
              {getLowAndHighCard(
                "Lowest Scoring Department",
                lowestScoringDepartment
              )}
            </div>
          )}
          {snapShotText ? (
            <div className="snapshot-box">
              <FontAwesomeIcon icon={["far", "lightbulb-on"]} />
              <DimensionLinkedText
                linkText={snapShotText}
                baseLink={"/SkillsGuide"}
              />
            </div>
          ) : null}
          {onSeeComparisonReport ? (
            <div>
              <Button onClick={onSeeComparisonReport} variant="secondary-blue">
                See comparison report
              </Button>
            </div>
          ) : null}
        </div>
      </Collapse>
    </Card>
  );
}
