import SimpleModal from "app/components/Modals/SimpleModal";
import Button from "app/storybookComponents/Button";
import { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import NoResultsIcon from "resources/images/no-results.jpg";
import { TimeInterval } from "./types";
import { getEndDateForAggregatePicker } from "./helpers";
import AggregatePicker from "./AggregatePicker";
import InstancePicker from "./InstancePicker";
import Loading from "app/storybookComponents/Loading";
import { useAppDispatch } from "utils/redux/hooks";
import { getAssessmentInstances } from "app/containers/Assessment/slice";

interface Props {
  onHide: () => void;
  show?: boolean;
  titleDescription?: string;
  surveyName?: string;
  onSubmit?: (payload: {
    startDate?: string;
    endDate?: string;
    instance?: string; // If we select an instance then we send the instance startDate as part of instance
    instanceType?: TimeInterval;
  }) => void;
  isLoading?: boolean;
  availableInstances?: {
    startDate: string;
    endDate: string;
    assessmentInstanceId: number;
  }[];
  defaultInstanceSelected?: {
    startDate?: string;
    endDate?: string;
  };
  entity?: "department" | "organization";
  failedToUpdate?: boolean;
  onResetFailedToUpdate?: () => void;
}

export default function DateRangePicker({
  show,
  onHide,
  titleDescription = "View either your team’s most recent TEAMscan results or the average of all TEAMscan surveys taken by your team over a given time period.",
  surveyName = "TEAMscan",
  onSubmit,
  isLoading,
  availableInstances,
  defaultInstanceSelected,
  entity,
  failedToUpdate,
  onResetFailedToUpdate,
}: Readonly<Props>) {
  const dispatch = useAppDispatch();

  const [tabShowing, setTabShowing] = useState<"Instance" | "Aggregate">(
    "Instance"
  );

  useEffect(() => {
    dispatch(getAssessmentInstances());
  }, [dispatch]);

  const sendAggregateResults = (
    optionSelected: TimeInterval | "customDateRange",
    startDate: string,
    endDate: string
  ) => {
    switch (optionSelected) {
      // If the optionSelected is customDateRange, then we need to send the startDate and endDate
      case "customDateRange": {
        if (!startDate || !endDate) {
          return;
        }
        return onSubmit?.({ startDate, endDate });
      }

      case "Over All Time": {
        // If no instances were passed in then we set the last date be the first day of 2023 and the last day to be today
        if (!availableInstances?.length) {
          const initialDate = new Date("2023-01-01");
          const lastDay = new Date();
          return onSubmit?.({
            startDate: initialDate.toISOString(),
            endDate: lastDay.toISOString(),
            instanceType: optionSelected,
          });
        }

        // If all time then we need the get the top and bottom date from the availableInstances sorted by date
        const sortedInstances = [...availableInstances].sort(
          (a, b) =>
            new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
        );
        const firstInstance = sortedInstances?.[0];
        const lastInstance = sortedInstances?.[sortedInstances.length - 1];

        return onSubmit?.({
          startDate: firstInstance?.startDate,
          endDate: lastInstance?.endDate,
          instanceType: optionSelected,
        });
      }
      case null:
        return;
    }

    // If the optionSelected is a time interval, then we need to send the startDate and endDate
    const today = new Date();
    const instanceEndDate = getEndDateForAggregatePicker(optionSelected, today);
    onSubmit?.({
      startDate: instanceEndDate?.toISOString(),
      endDate: today.toISOString(),
      instanceType: optionSelected,
    });
  };

  const hideModal = () => {
    onHide();
    onResetFailedToUpdate?.();
  };

  const getModalBody = () => {
    if (isLoading) {
      return (
        <div className="text-center column-gap-16px algin-items-center">
          <div>
            <Loading
              color="#53565A"
              size={70}
              speedMultiplier={0.4}
              borderWidth={10}
            />
          </div>

          <p>
            <b>Please wait, your insight report is being generated...</b>
          </p>
          <p>This will take less than a minute</p>
          <div>
            <Button variant="secondary-blue" onClick={hideModal}>
              Back
            </Button>
          </div>
        </div>
      );
    }

    if (failedToUpdate) {
      return (
        <>
          <div className="column-gap-16px align-items-center">
            <img
              src={NoResultsIcon}
              alt="no results"
              height="80px"
              width="80px"
            />
            <p>
              <b>No TEAMscan results found for this date range</b>
            </p>
            <p>
              Please try a different date range or select a specific instance.
            </p>
          </div>
          <div style={{ textAlign: "center", margin: "16px" }}>
            <Button variant="secondary-blue" onClick={onResetFailedToUpdate}>
              Back
            </Button>
          </div>
        </>
      );
    }

    return (
      <div className="column-gap-20px instance-picker">
        <p className="navy-text">{titleDescription}</p>

        <Nav
          className="simple-nav"
          activeKey={tabShowing || ""}
          onSelect={(e) => {
            if (!e) return;
            setTabShowing(e as "Instance" | "Aggregate");
          }}
        >
          <Nav.Item>
            <Nav.Link eventKey="Instance">Instance</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Aggregate">Aggregate</Nav.Link>
          </Nav.Item>
        </Nav>

        {tabShowing === "Aggregate" ? (
          <AggregatePicker
            surveyName={surveyName}
            onSubmit={(optionSelected, startDate, endDate) => {
              sendAggregateResults(optionSelected, startDate, endDate);
            }}
          />
        ) : (
          <InstancePicker
            surveyName={surveyName}
            availableInstances={availableInstances}
            onSubmit={(startDate, endDate) => {
              onSubmit?.({
                instance: startDate,
                startDate,
                endDate,
              });
            }}
            defaultInstanceSelected={defaultInstanceSelected}
            entity={entity}
          />
        )}
      </div>
    );
  };

  return (
    <SimpleModal show={show} onHide={hideModal} title="Configure score results">
      {getModalBody()}
    </SimpleModal>
  );
}
