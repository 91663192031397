import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { request } from "utils/request";
import { toast } from "react-toastify";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import { successNotificationOptions } from "utils/constants";
import {
  Team360FeedbackPayload,
  TTeam360Factor,
  TTeam360FactorResponse,
  Team360StoredResponses,
  getPreviousAnswersResponse,
} from "./types";
import { constructPayload } from "./helpers";

export interface Team360AssessmentState {
  showSurveyModal: null | "Overview" | "Product Feedback"; // Survey is the same as assessment
  surveysTeamId: number | null;
  $360AssessmentFactors: { [factorName: string]: TTeam360FactorResponse[] };
  getAllTeam360AssessmentResultsStatus: responseStatus;
  storedResponses: Team360StoredResponses;
  team360FeedbackPayload: Partial<Team360FeedbackPayload>;
  surveyProgress: number;
  totalQuestionCount: number;
  currentQuestionIndex: number;
  activeAssessmentEventId: number | null;
  reviewAssessmentEventId: number | null;
  previousAnswers: getPreviousAnswersResponse | null;
  team360actionByTeamId: {
    [teamId: number]: "extended" | "reminded" | null | undefined;
  };
  isSampleAssessment: boolean;
  getTeam360ScoresStatus: responseStatus;
  submitting360AssessmentResultsStatus: responseStatus;
  feedbackSentStatus: responseStatus;
  getPreviousAnswersStatus: responseStatus;
}

const initialState: Team360AssessmentState = {
  showSurveyModal: null,
  activeAssessmentEventId: null,
  reviewAssessmentEventId: null,
  surveysTeamId: null,
  storedResponses: {
    Target: {},
    Empower: {},
    Align: {},
    Monitor: {},
  },
  team360actionByTeamId: {},
  surveyProgress: 2,
  totalQuestionCount: 0,
  currentQuestionIndex: 0,
  $360AssessmentFactors: {},
  team360FeedbackPayload: {
    assessmentFeedback: "",
    doMoreResponse: "",
    doDifferentlyResponse: "",
  },
  isSampleAssessment: false,
  previousAnswers: null,
  getAllTeam360AssessmentResultsStatus: "idle",
  getTeam360ScoresStatus: "idle",
  feedbackSentStatus: "idle",
  submitting360AssessmentResultsStatus: "idle",
  getPreviousAnswersStatus: "idle",
};

// ------------------------------------ GETS ------------------------------------

// ------------------------------------ POSTS ------------------------------------
export const send360AssessmentResults = createAsyncThunk(
  "team360Assessment/send360AssessmentResults",
  async (
    payload: {
      completedAssessment: 0 | 1;
    },
    thunkAPI
  ) => {
    const {
      team360Assessment: {
        activeAssessmentEventId,
        storedResponses,
        isSampleAssessment,
      },
    } = thunkAPI.getState() as RootState;

    if (isSampleAssessment) {
      thunkAPI.dispatch(resetStoredResponses());
      throw new Error("No need to submit sample assessment");
    }

    if (!activeAssessmentEventId) {
      throw new Error("No active assessment event id or already submitting");
    }
    const answers = constructPayload(storedResponses);

    if (Object.keys(answers).length === 0) {
      thunkAPI.dispatch(resetStoredResponses());
      throw new Error("No answers to submit");
    }

    const requestUrl = `${REACT_APP_API_URL}/talentInsights/answers`;
    await request(requestUrl, {
      method: "POST",
      body: JSON.stringify({
        ...payload,
        answers,
        assessmentEventId: activeAssessmentEventId,
      }),
    });
    thunkAPI.dispatch(resetStoredResponses());

    return activeAssessmentEventId;
  }
);

export const send360TeamFeedback = createAsyncThunk(
  "team360Assessment/send360TeamFeedback",
  async (_, thunkAPI) => {
    const {
      team360Assessment: { reviewAssessmentEventId, team360FeedbackPayload },
    } = thunkAPI.getState() as RootState;

    if (!reviewAssessmentEventId)
      throw new Error("No active assessment event id");

    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teamFeedback`;
    const send360TeamFeedbackResponse = await request(requestUrl, {
      method: "POST",
      body: JSON.stringify({
        ...team360FeedbackPayload,
        assessmentEventId: reviewAssessmentEventId,
      }),
    });
    return send360TeamFeedbackResponse;
  }
);

export const getPreviousAnswers = createAsyncThunk(
  "team360Assessment/getPreviousAnswers",
  async (assessmentEventId: number) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/assessmentEvents/${assessmentEventId}/answers`;
    const response = (await request(requestUrl)) as getPreviousAnswersResponse;
    return response;
  }
);

export const scheduleTeam360 = createAsyncThunk(
  "team360Assessment/scheduleTeam360",
  async (teamId: number) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/scheduleTEAM360`;
    const scheduleTeam360Response = await request(requestUrl, {
      method: "POST",
      body: JSON.stringify({ teamId, testId: 1 }),
    });

    return scheduleTeam360Response;
  },
  {
    condition: (_, { getState }) => {
      const {
        team360Assessment: { activeAssessmentEventId },
      } = getState() as RootState;
      return !activeAssessmentEventId;
    },
  }
);

// This returns back the assessment questions for the TEAMscan assessment
export const getTEAMAssessmentItems = createAsyncThunk(
  "team360Assessment/getTEAMAssessmentItems",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/TEAMAssessmentItems`;
    const getTEAMAssessmentItemsResponse = (await request(requestUrl)) as {
      items: TTeam360FactorResponse[];
    };
    return getTEAMAssessmentItemsResponse.items;
  },
  {
    condition: (_, { getState }) => {
      const {
        team360Assessment: { $360AssessmentFactors },
      } = getState() as RootState;
      return Object.keys($360AssessmentFactors).length === 0;
    },
  }
);
// ------------------------------------ PUTS ------------------------------------
// ------------------------------------ DELETES ------------------------------------

export const team360AssessmentSlice = createSlice({
  name: "team360Assessment",
  initialState,
  reducers: {
    setActiveAssessmentEventId: (
      state,
      action: PayloadAction<number | null>
    ) => {
      state.activeAssessmentEventId = action.payload;
    },
    setShowSurveyModal: (
      state,
      action: PayloadAction<null | "Overview" | "Product Feedback">
    ) => {
      state.showSurveyModal = action.payload;
    },
    setSurveysTeamId: (state, action: PayloadAction<number | null>) => {
      state.surveysTeamId = action.payload;
    },
    resetReviewedAssessmentEventId: (state) => {
      state.reviewAssessmentEventId = null;
    },
    setSurveyProgress: (state, action: PayloadAction<number>) => {
      state.surveyProgress = action.payload;
    },
    setStoredResponses: (
      state,
      action: PayloadAction<{
        factorName: TTeam360Factor;
        itemId: number;
        answer: number;
        timeTaken: number;
      }>
    ) => {
      const { factorName, itemId, answer, timeTaken } = action.payload;

      const updatedStoredResponses = {
        ...state.storedResponses,
        [factorName]: {
          ...state.storedResponses[factorName],
          [itemId]: { answer, timeTaken },
        },
      };
      return {
        ...state,
        storedResponses: updatedStoredResponses,
      };
    },
    setCurrentQuestionIndexToLastAnswered: (state) => {
      const answersLength = state.previousAnswers
        ? state.previousAnswers.length
        : 0;
      if (answersLength === 0) return;
      const newIndex = answersLength - 1;
      const progress = (newIndex / state.totalQuestionCount) * 100;
      return {
        ...state,
        currentQuestionIndex: newIndex,
        surveyProgress: progress === 100 ? 98 : progress,
      };
    },
    increaseCurrentQuestionIndex: (state) => {
      const newIndex = state.currentQuestionIndex + 1;
      const progress = (newIndex / state.totalQuestionCount) * 100;
      return {
        ...state,
        currentQuestionIndex: newIndex,
        surveyProgress: progress === 100 ? 98 : progress,
      };
    },
    decreaseCurrentQuestionIndex: (state) => {
      const newIndex = state.currentQuestionIndex - 1;
      const progress = (newIndex / state.totalQuestionCount) * 100;
      return {
        ...state,
        currentQuestionIndex: newIndex,
        surveyProgress: progress === 0 ? 2 : progress,
      };
    },
    resetCurrentQuestionIndex: (state) => {
      state.currentQuestionIndex = 0;
    },
    resetStoredResponses: (state) => {
      return {
        ...state,
        storedResponses: {
          Target: {},
          Empower: {},
          Align: {},
          Monitor: {},
        },
        surveyProgress: 2,
        currentQuestionIndex: 0,
        activeAssessmentEventId: null,
      };
    },
    resetTeam360FeedbackPayload: (state) => {
      state.team360FeedbackPayload = initialState.team360FeedbackPayload;
    },
    setTeam360FeedbackPayload: (
      state,
      action: PayloadAction<Partial<Team360FeedbackPayload>>
    ) => {
      return {
        ...state,
        team360FeedbackPayload: {
          ...state.team360FeedbackPayload,
          ...action.payload,
        },
      };
    },
    setTeam360ActionByTeamId: (
      state,
      {
        payload,
      }: PayloadAction<{
        teamId: number;
        action: "extended" | "reminded" | null;
      }>
    ) => {
      state.team360actionByTeamId[payload.teamId] = payload.action;
    },
    setIsSampleAssessment: (state, action: PayloadAction<boolean>) => {
      // if it is then we set the totalQuestion count to 4,
      // otherwise iteratire through $360AssessmentFactors and count the number of items
      if (action.payload) {
        state.totalQuestionCount = 4;
      } else {
        let count = 0;
        Object.keys(state.$360AssessmentFactors).forEach((factor) => {
          count += state.$360AssessmentFactors[factor].length;
        });
        state.totalQuestionCount = count;
      }
      state.isSampleAssessment = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(send360AssessmentResults.pending, (state) => {
        state.submitting360AssessmentResultsStatus = "loading";
      })
      .addCase(
        send360AssessmentResults.fulfilled,
        (state, { payload }: PayloadAction<number>) => {
          return {
            ...state,
            submitting360AssessmentResultsStatus: "succeeded",
            activeAssessmentEventId: null,
            reviewAssessmentEventId: payload,
          };
        }
      )
      .addCase(send360AssessmentResults.rejected, (state, action) => {
        if (action.error.message === "No answers to submit") {
          return state; // Returning state so that we don't show the error toast
        }
        state.submitting360AssessmentResultsStatus = "failed";
      })
      .addCase(send360TeamFeedback.fulfilled, (state) => {
        toast.success(
          "Feedback submitted successfully",
          successNotificationOptions
        );
        state.feedbackSentStatus = "succeeded";
        state.team360FeedbackPayload = {};
        state.reviewAssessmentEventId = null;
      })
      .addCase(send360TeamFeedback.rejected, (state) => {
        state.feedbackSentStatus = "failed";
      })
      .addCase(send360TeamFeedback.pending, (state) => {
        state.feedbackSentStatus = "loading";
      })
      .addCase(getPreviousAnswers.pending, (state) => {
        state.getPreviousAnswersStatus = "loading";
      })
      .addCase(getPreviousAnswers.fulfilled, (state, { payload }) => {
        // set storedResponses to the previous answers
        state.getPreviousAnswersStatus = "succeeded";
        state.previousAnswers = payload;
        state.storedResponses = {
          Target: {},
          Empower: {},
          Align: {},
          Monitor: {},
        };
        payload.forEach((answer) => {
          state.storedResponses[answer.factorName][answer.itemId] = {
            answer: Number(answer.answer),
            timeTaken: answer.timeTaken,
          };
        });
      })
      .addCase(
        getTEAMAssessmentItems.fulfilled,
        (state, { payload }: PayloadAction<TTeam360FactorResponse[]>) => {
          const itemByFactor: {
            [factorName: string]: TTeam360FactorResponse[];
          } = {};
          payload.forEach((item) => {
            if (itemByFactor[item.factor]) {
              itemByFactor[item.factor].push(item);
            } else {
              itemByFactor[item.factor] = [item];
            }
          });
          state.$360AssessmentFactors = itemByFactor;
          state.totalQuestionCount = state.isSampleAssessment
            ? 4
            : payload.length;
        }
      );
  },
});

export const selectSubmitting360AssessmentResultsStatus = (state: RootState) =>
  state.team360Assessment.submitting360AssessmentResultsStatus;
export const selectFeedbackSentStatus = (state: RootState) =>
  state.team360Assessment.feedbackSentStatus;
export const selectActiveAssessmentEventId = (state: RootState) =>
  state.team360Assessment.activeAssessmentEventId;
export const selectShowSurveyModal = (state: RootState) =>
  state.team360Assessment.showSurveyModal;
export const selectSurveysTeamId = (state: RootState) =>
  state.team360Assessment.surveysTeamId;
export const selectReviewAssessmentEventId = (state: RootState) =>
  state.team360Assessment.reviewAssessmentEventId;
export const select360AssessmentFactors = (state: RootState) =>
  state.team360Assessment.$360AssessmentFactors;
export const selectStoredResponses = (state: RootState) =>
  state.team360Assessment.storedResponses;
export const selectSurveyProgress = (state: RootState) =>
  state.team360Assessment.surveyProgress;
export const selectTeam360FeedbackPayload = (state: RootState) =>
  state.team360Assessment.team360FeedbackPayload;
export const selectCurrentQuestionIndex = (state: RootState) =>
  state.team360Assessment.currentQuestionIndex;
export const selectPreviousAnswers = (state: RootState) =>
  state.team360Assessment.previousAnswers;
export const selectGetPreviousAnswersStatus = (state: RootState) =>
  state.team360Assessment.getPreviousAnswersStatus;
export const selectTeam360ActionByTeamId =
  (teamId?: number | null) => (state: RootState) =>
    teamId ? state.team360Assessment.team360actionByTeamId[teamId] : null;

export const {
  setActiveAssessmentEventId,
  setSurveysTeamId,
  setShowSurveyModal,
  resetReviewedAssessmentEventId,
  setStoredResponses,
  setSurveyProgress,
  setTeam360FeedbackPayload,
  resetTeam360FeedbackPayload,
  resetStoredResponses,
  increaseCurrentQuestionIndex,
  setCurrentQuestionIndexToLastAnswered,
  decreaseCurrentQuestionIndex,
  resetCurrentQuestionIndex,
  setTeam360ActionByTeamId,
  setIsSampleAssessment,
} = team360AssessmentSlice.actions;

export default team360AssessmentSlice.reducer;
