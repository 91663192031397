import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { request } from "utils/request";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import { AllTeam360AssessmentResults } from "app/components/Team360Assessment/types";
import { StartAndEndDateOrInstance } from "./types";
import { getInstanceKey } from "./helpers";
import { getAppendedUrl } from "app/components/InstancePicker/helpers";

// ------------------ State Type/Structure ------------------
export interface ExampleState {
  allTeam360AssessmentResults: AllTeam360AssessmentResults | null;
  allTeam360AssessmentResultsByDateRange: {
    [dateRangeString: string]: AllTeam360AssessmentResults | null;
  };
  gettingAllTeam360AssessmentResultsStatus: responseStatus;
}

// ------------------ InitialState ------------------
const initialState: ExampleState = {
  allTeam360AssessmentResults: null,
  allTeam360AssessmentResultsByDateRange: {},
  gettingAllTeam360AssessmentResultsStatus: "idle",
};

// ------------------ Asynchronous API calls ------------------
export const getTeam360Scores = createAsyncThunk(
  "surveyDataInstance/getTeam360Scores",
  async (payload: StartAndEndDateOrInstance | undefined) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teamScores${getAppendedUrl(
      payload ?? {}
    )}`;
    const response = (await request(requestUrl)) as AllTeam360AssessmentResults;
    return {
      response,
      ...payload,
    };
  },
  {
    condition: (payload, { getState }) => {
      const {
        surveyDataInstance: {
          allTeam360AssessmentResultsByDateRange,
          allTeam360AssessmentResults,
        },
      } = getState() as RootState;

      // Before we make the request, we need to make sure that the payload they are trying to reach does not exist
      const key = getInstanceKey(payload ?? {});
      if (
        (!key && allTeam360AssessmentResults) ||
        allTeam360AssessmentResultsByDateRange[key]
      ) {
        return false;
      }
    },
  }
);

// ------------------ Beginning of Slice Definition ------------------
export const surveyDataInstanceSlice = createSlice({
  name: "surveyDataInstance",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTeam360Scores.pending, (state) => {
        state.gettingAllTeam360AssessmentResultsStatus = "loading";
      })
      .addCase(
        getTeam360Scores.fulfilled,
        (
          state,
          {
            payload: { response, startDate, endDate, instance },
          }: PayloadAction<
            {
              response: AllTeam360AssessmentResults;
            } & StartAndEndDateOrInstance
          >
        ) => {
          if ((!startDate || !endDate) && !instance) {
            return {
              ...state,
              allTeam360AssessmentResults: response,
              gettingAllTeam360AssessmentResultsStatus: "succeeded",
            };
          }
          const key = getInstanceKey({ startDate, endDate, instance });
          return {
            ...state,
            gettingAllTeam360AssessmentResultsStatus: "succeeded",
            allTeam360AssessmentResultsByDateRange: {
              ...state.allTeam360AssessmentResultsByDateRange,
              [key]: response,
            },
          };
        }
      )
      .addCase(getTeam360Scores.rejected, (state) => {
        state.gettingAllTeam360AssessmentResultsStatus = "failed";
      });
  },
});

// ------------------ Selectors ------------------
export const selectAllTeam360Results =
  (payload?: {
    startDate?: string;
    endDate?: string;
    instance?: string | null;
  }) =>
  ({
    surveyDataInstance: {
      allTeam360AssessmentResults,
      allTeam360AssessmentResultsByDateRange,
    },
  }: RootState) => {
    // If they are not requesting a specific date range or instance, return the full object
    if (!payload?.endDate && !payload?.startDate && !payload?.instance) {
      return allTeam360AssessmentResults;
    }

    const key = getInstanceKey(payload);
    return allTeam360AssessmentResultsByDateRange[key] ?? null;
  };

export const selectGettingAllTeam360AssessmentResultsStatus = (
  state: RootState
) => state.surveyDataInstance.gettingAllTeam360AssessmentResultsStatus;

export default surveyDataInstanceSlice.reducer;
