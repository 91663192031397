import Button from "app/storybookComponents/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode, SyntheticEvent } from "react";
import { Card, OverlayTrigger, Popover } from "react-bootstrap";
import { IconName } from "@fortawesome/fontawesome-svg-core";

interface Props {
  title: string;
  body: string | number;
  onPendingTextClick?: () => void;
  pendingText?: string;
  toolTipContent?: ReactNode;
  action?: {
    text: string;
    onClick: () => void;
    iconName?: IconName;
  };
  pendingTextIcon?: IconName;
  onCardClick?: () => void;
}

export default function InfoCardV2({
  pendingText,
  title,
  body,
  action,
  pendingTextIcon = "clock",
  toolTipContent,
  onPendingTextClick,
  onCardClick,
}: Readonly<Props>) {
  const renderPopover = () => {
    if (!toolTipContent) {
      return;
    }
    const overlay = (
      <Popover className="team-360-popover">
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          {title ? <strong>{title}</strong> : null}
          {toolTipContent}
        </div>
      </Popover>
    );
    return (
      <OverlayTrigger rootClose placement="auto" overlay={overlay}>
        <FontAwesomeIcon icon={["far", "circle-info"]} />
      </OverlayTrigger>
    );
  };

  const onButtonClick = (e: SyntheticEvent, onClick: () => void) => {
    e.stopPropagation();
    onClick();
  };

  const getPendingText = () => {
    if (!pendingText) {
      return null;
    }

    if (onPendingTextClick) {
      return (
        <div
          className="row-gap-8px align-items-center"
          role="button"
          onClick={(e) => onButtonClick(e, onPendingTextClick)}
        >
          <FontAwesomeIcon icon={["far", pendingTextIcon]} />
          <p className="info-card-v2__pending-text">{pendingText}</p>
        </div>
      );
    }

    return (
      <div className="row-gap-8px align-items-center">
        <FontAwesomeIcon icon={["far", pendingTextIcon]} />
        <p className="info-card-v2__pending-text">{pendingText}</p>
      </div>
    );
  };

  return (
    <Card
      className="info-card-v2"
      onClick={onCardClick}
      style={{
        cursor: onCardClick ? "pointer" : "default",
      }}
    >
      <div className="column-gap-16px">
        <div className="column-gap-8px">
          <div className="row-gap-8px align-items-center">
            <p className="info-card-v2__title">{title}</p>
            <div>{renderPopover()}</div>
          </div>
          <p
            style={{
              fontSize: "32px",
              lineHeight: "40px",
            }}
          >
            {body}
          </p>
        </div>
        {getPendingText()}
      </div>
      {action ? (
        <Button
          onClick={(e) => onButtonClick(e, action.onClick)}
          variant="tertiary-blue"
          className="mt-auto"
          style={{
            padding: "12px 16px",
          }}
        >
          {action.text}
          <FontAwesomeIcon
            icon={action.iconName ?? "arrow-right"}
            className="ms-2"
          />
        </Button>
      ) : null}
    </Card>
  );
}
