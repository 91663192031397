import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";

interface LaunchAssessmentModalState {
  scheduleAssessmentModalOpen: boolean;
  scheduleAssessmentModalOpenForTeamId: number | null;
  scheduleAssessmentModalOpenForDepartmentId: number | null;
  isEntireOrganizationDefault: boolean;
  scheduleAssessmentModalSurveyEditId: number | null;
  isModalTemporarilyHidden: boolean;
}

const initialState: LaunchAssessmentModalState = {
  scheduleAssessmentModalOpen: false,
  scheduleAssessmentModalOpenForTeamId: null,
  scheduleAssessmentModalOpenForDepartmentId: null,
  scheduleAssessmentModalSurveyEditId: null,
  isEntireOrganizationDefault: false,
  isModalTemporarilyHidden: false,
};

export const launchAssessmentModalSlice = createSlice({
  name: "launchAssessmentModal",
  initialState,
  reducers: {
    showScheduleAssessmentModalForSurveyEditId: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.scheduleAssessmentModalSurveyEditId = payload;
      state.scheduleAssessmentModalOpen = true;
    },
    showScheduleAssessmentModalForTeamId: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.scheduleAssessmentModalOpenForTeamId = payload;
      state.scheduleAssessmentModalOpen = true;
    },
    showScheduleAssessmentModalForDepartmentId: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.scheduleAssessmentModalOpenForDepartmentId = payload;
      state.scheduleAssessmentModalOpen = true;
    },
    showScheduleAssessmentModal: (state) => {
      state.scheduleAssessmentModalOpen = true;
    },
    showScheduleAssessmentModalForOrganization: (state) => {
      state.scheduleAssessmentModalOpen = true;
      state.isEntireOrganizationDefault = true;
    },
    hideScheduleAssessmentModal: (state) => {
      state.scheduleAssessmentModalOpen = false;
      state.scheduleAssessmentModalOpenForTeamId = null;
      state.scheduleAssessmentModalOpenForDepartmentId = null;
      state.scheduleAssessmentModalSurveyEditId = null;
      state.isEntireOrganizationDefault = false;
    },
    temporarilyHideScheduleAssessmentModal: (state) => {
      // This action is used to hide the modal without resetting the state
      state.scheduleAssessmentModalOpen = false;
      state.isModalTemporarilyHidden = true;
    },
    reOpenScheduleAssessmentModal: (state) => {
      // IF not is temporarily hidden, then we we don't want to reopen it
      if (!state.isModalTemporarilyHidden) {
        return;
      }
      state.scheduleAssessmentModalOpen = true;
      state.isModalTemporarilyHidden = false;
    },
  },
});

export const selectScheduleAssessmentModalOpen = (state: RootState) =>
  state.launchAssessmentModal.scheduleAssessmentModalOpen;
export const selectScheduleAssessmentModalOpenForTeamId = (state: RootState) =>
  state.launchAssessmentModal.scheduleAssessmentModalOpenForTeamId;
export const selectScheduleAssessmentModalOpenForDepartmentId = (
  state: RootState
) => state.launchAssessmentModal.scheduleAssessmentModalOpenForDepartmentId;
export const selectEditAssessmentModalOpenForSurveyId = (state: RootState) =>
  state.launchAssessmentModal.scheduleAssessmentModalSurveyEditId;
export const selectIsEntireOrganizationDefault = (state: RootState) =>
  state.launchAssessmentModal.isEntireOrganizationDefault;

export const {
  showScheduleAssessmentModalForTeamId,
  showScheduleAssessmentModalForDepartmentId,
  showScheduleAssessmentModal,
  hideScheduleAssessmentModal,
  showScheduleAssessmentModalForSurveyEditId,
  showScheduleAssessmentModalForOrganization,
  temporarilyHideScheduleAssessmentModal,
  reOpenScheduleAssessmentModal,
} = launchAssessmentModalSlice.actions;

export default launchAssessmentModalSlice.reducer;
