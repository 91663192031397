import AvatarCircle from "app/components/AvatarCircle";
import Button from "app/storybookComponents/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import { useNavigate } from "react-router-dom";
import { memo } from "react";
import {
  acceptInvitation,
  declineInvitation,
  selectAllTeamInvitations,
} from "app/containers/Dashboard/slice";
// ------- Relative imports  -------- //
import { selectTeamsByTeamId } from "app/containers/Global/slice";
import MultiCardCollapsibleContainer from "app/storybookComponents/CollapsibleCards/MultiCardCollapsibleContainer";

interface Props {
  userAccountId: number | null;
  teamIds: number[];
}

const MyTeamsCard = ({ userAccountId, teamIds }: Readonly<Props>) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // ---------------------- App selectors ----------------------
  const teamsByTeamId = useAppSelector(selectTeamsByTeamId);
  const pendingTeamNotifications = useAppSelector(selectAllTeamInvitations);

  // ---------------------- Derived state ----------------------

  // ---------------------- Handlers ----------------------
  const onAccept = (teamId: number, notificationId: number) => {
    dispatch(acceptInvitation({ teamId, notificationId }));
  };
  const onReject = (teamId: number, notificationId: number) => {
    dispatch(declineInvitation({ teamId, notificationId }));
  };

  // ---------------------- Render ----------------------
  const acceptedTeams = teamIds
    .filter(
      (teamId) =>
        !pendingTeamNotifications.some(
          (notification) => notification.teamId === teamId
        )
    )
    .map((teamId) => {
      const team = teamsByTeamId[teamId];
      if (!team) return null;
      const totalMembers = team?.teamMemberIds?.length || 0;
      const isTeamLead = team?.teamLeadUserAccountIds?.includes(
        userAccountId ?? 0
      );

      return (
        <div
          className="section-body-row"
          key={teamId}
          role="button"
          onClick={() => navigate(`/TeamGuide/${teamId}`)}
        >
          <div className="d-flex gap-2 align-items-center">
            <AvatarCircle
              profilePicture={team?.profilePicture}
              avatarColor={team?.avatarColor}
              name={team?.teamName}
              size="small"
            />
            <div className="section-body-name-and-description">
              <p className="section-body-row-name">{team?.teamName}</p>
              <p className="section-body-row-description small-body-text">
                {totalMembers || 1} Member{totalMembers > 1 ? "s" : ""}
              </p>
            </div>
          </div>

          {isTeamLead ? (
            <div className="d-flex gap-2 align-items-center">
              <span className="team-role ti-badge">Team Leader</span>
              <FontAwesomeIcon
                icon={["far", "cog"]}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/TeamGuide/${teamId}/TeamSettings`);
                }}
              />
            </div>
          ) : null}
        </div>
      );
    });

  const pendingTeams = pendingTeamNotifications.map((notification) => {
    const { teamId, notificationId } = notification;
    const team = teamsByTeamId[teamId];

    return (
      <div className="section-body-row" key={teamId}>
        <div className="d-flex gap-2 align-items-center">
          <AvatarCircle
            profilePicture={team?.profilePicture}
            avatarColor={"53565A"}
            name={team?.teamName}
            size="small"
          />
          <div className="section-body-name-and-description">
            <span
              className="section-body-row-name"
              style={{ color: "$53565A" }}
            >
              {team?.teamName}
            </span>
            <span className="section-body-row-description">Pending Invite</span>
          </div>
        </div>

        <div className="d-flex gap-2 align-items-center">
          <Button onClick={() => onAccept(teamId, notificationId)}>
            Accept
          </Button>
          <Button
            variant="secondary-blue"
            onClick={() => onReject(teamId, notificationId)}
          >
            Decline
          </Button>
        </div>
      </div>
    );
  });

  return (
    <MultiCardCollapsibleContainer
      items={[...acceptedTeams, ...pendingTeams]}
      className="no-border-bottom"
      collapsedText="See all teams"
    />
  );
};

export default memo(MyTeamsCard);
