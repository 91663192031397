import SimpleModal from "app/components/Modals/SimpleModal";
import { useEffect, useState } from "react";
import Button from "app/storybookComponents/Button";
import { selectDepartments } from "../slice";
import { useAppSelector } from "utils/redux/hooks";
import { selectTeamsByTeamId } from "app/containers/Global/slice";
import SmallTeam360Card from "app/containers/Dashboard/Cards/SmallTeam360Card";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onPickInstance: (payload: {
    instanceId: number;
    teamId?: number;
    departmentId?: number;
  }) => void;
  teamIds?: number[];
  departmentIds?: number[];
  instanceId?: number;
}

export default function PickInstanceFromMultipleModal({
  isOpen,
  onClose,
  onPickInstance,
  teamIds,
  departmentIds,
}: Props) {
  // ------------------------- App selectors ----------------------------------//
  const departments = useAppSelector(selectDepartments);
  const teamInfoById = useAppSelector(selectTeamsByTeamId);

  // ------------------------- States ----------------------------------//
  const [type, setType] = useState<"team" | "department">("team");

  // ------------------------- Effect ----------------------------------//
  useEffect(() => {
    if (teamIds?.length) setType("team");
    else if (departmentIds?.length) setType("department");
  }, [type, teamIds, departmentIds]);

  const getTitle = () => {
    if (type === "team") return `Select team (${teamIds?.length ?? 0})`;
    return `Select department (${departmentIds?.length ?? 0})`;
  };

  const getDescription = () => {
    if (type === "team")
      return `This TEAMscan instance was sent to ${
        teamIds?.length ?? 0
      } teams.`;
    return "Select a department to invite the user to.";
  };

  const getModalBody = () => {
    if (teamIds?.length) {
      return teamIds.map((teamId) => <SmallTeam360Card teamId={teamId} />);
    }
    return getResultsCard("Departments", departmentIds?.length ?? 0, 1);
  };

  const getResultsCard = (title: string, memberCount: number, id: number) => (
    <div>
      <h5>{title}</h5>
      <p>
        {memberCount} member{memberCount === 1 ? "" : "s"}
      </p>
      <div>
        <Button variant="secondary-blue">View Results</Button>
      </div>
    </div>
  );

  return (
    <SimpleModal
      show={isOpen}
      onHide={onClose}
      title="Pick a team or department"
    >
      {/* <SmallTeam360Card
        teamId={1948}
        // pendingAssessmentEventId={notificationObj?.assessmentEventId}
        // key={teamId}
        // hasDepartmentLeadAccess={hasDepartmentLeadAccess}
      /> */}
      {getModalBody()}
    </SimpleModal>
  );
}
