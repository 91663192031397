import { ReactElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Dropdown } from "react-bootstrap";
import { BaseModuleTemplate, ModuleType } from "./types";

interface Props {
  title: string;
  content: string | string[];
  link?: string;
  onEditClick: () => void;
  hasEditAccess?: boolean;
  moduleType: ModuleType;
  onDeleteClick: () => void;
  moduleTemplate?: BaseModuleTemplate;
}

export default function Module({
  title,
  content,
  onEditClick,
  moduleType,
  hasEditAccess,
  link,
  onDeleteClick,
  moduleTemplate,
}: Props) {
  const getHeader = () => (
    <div className="module-title">
      <h3 className="d-inline w-auto">{moduleTemplate?.title || title}</h3>
      {hasEditAccess ? getDropdown() : null}
    </div>
  );

  const getContent = () => {
    switch (moduleType) {
      case "List":
        return getListTypeContent(content);
      case "Free Text":
        return <div className="free-text">{content}</div>;
      default:
        return content;
    }
  };

  const getListTypeContent = (content: string[] | string) => {
    try {
      const arrayContent: string[] = Array.isArray(content)
        ? content
        : JSON.parse(content);
      const elmArr: ReactElement[] = [];
      arrayContent.forEach((val, idx) => {
        if (!val) {
          return;
        }
        elmArr.push(
          <span key={idx} className="list-span lg">
            {val}
          </span>
        );
      });
      return <div className="list">{elmArr}</div>;
    } catch (error) {
      return content;
    }
  };

  const onDropdownSelect = (e: string | null) => {
    switch (e) {
      case "edit":
        return onEditClick();
      case "delete":
        return onDeleteClick();
      default:
        return null;
    }
  };

  const getDropdown = () => (
    <Dropdown onSelect={onDropdownSelect}>
      <Dropdown.Toggle
        variant="outline-primary"
        id="dropdown-basic"
        className="no-caret"
      >
        <FontAwesomeIcon icon="ellipsis" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item eventKey="edit">Edit Module</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item eventKey="delete" className="danger">
          Delete Module
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  return (
    <Card className="module">
      {getHeader()}
      {getContent()}
      {link ? (
        <div className="mt-2">
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              display: "block",
              width: "auto",
            }}
          >
            <FontAwesomeIcon icon="link" className="pe-2" />
            {link}
          </a>
        </div>
      ) : null}
    </Card>
  );
}
