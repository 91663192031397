import { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import Button from "app/storybookComponents/Button";
import { updateTeam } from "app/containers/Global/slice";
import { Team } from "app/containers/Global/types";
import { selectDepartments } from "app/containers/AdminConsole/slice";
import Select from "react-select";

interface Props {
  team: Team;
  modalShowing: boolean;
  hideModal: () => void;
}

export default function EditInformationModal({
  hideModal,
  modalShowing,
  team,
}: Props) {
  const [teamName, setTeamName] = useState(team?.teamName ?? "");
  const [departmentId, setDepartmentId] = useState(
    team?.departmentId ?? undefined
  );
  const departments = useAppSelector(selectDepartments);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setTeamName(team?.teamName ?? "");
    setDepartmentId(team?.departmentId ?? undefined);
  }, [team]);

  if (!departments) {
    return null;
  }

  const departmentOptions = Object.entries(departments).map(([key, val]) => ({
    label: val.name,
    value: Number(key),
  }));

  const onHideModal = () => {
    setTeamName(team?.teamName ?? "");
    setDepartmentId(team?.departmentId ?? undefined);
    hideModal();
  };

  return (
    <Modal
      show={modalShowing}
      className="invite-modal simple-modal"
      size="lg"
      onHide={hideModal}
    >
      <div className="modal-title-row">
        <h2>Edit Team Details</h2>
        <Button
          variant={"secondary-blue"}
          onClick={onHideModal}
          style={{ border: "none", width: "auto" }}
          xIcon
        />
      </div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          dispatch(
            updateTeam({
              teamId: team.teamId,
              payload: {
                teamName,
                departmentId: departmentId || team.departmentId,
              },
            })
          );
          onHideModal();
        }}
      >
        <Form.Group className="form-group">
          <Form.Label>Team Name</Form.Label>
          <Form.Control
            placeholder="e.g. Marketing"
            name="teamName"
            onChange={(e) => setTeamName(e.target.value)}
            value={teamName}
          />
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label>Department</Form.Label>
          <Select
            options={departmentOptions}
            onChange={(e) => {
              setDepartmentId(e?.value);
            }}
            value={departmentOptions.find(
              (option) => option.value === departmentId
            )}
          />
        </Form.Group>
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button type="submit">Save</Button>
        </div>
      </Form>
    </Modal>
  );
}
