import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { request } from "utils/request";
import { getUser } from "app/containers/Global/slice";
import { REACT_APP_API_URL } from "utils/environmentVariables";
// import { setCookieItem } from 'utils/helperFunctions';

// ------------------ State Type/Structure ------------------
export interface LoginState {
  gettingLoginStatus: responseStatus;
  resetPasswordLinkStatus: responseStatus;
  updatingUserPasswordStatus?: responseStatus;
}

// ------------------ InitialState ------------------
const initialState: LoginState = {
  gettingLoginStatus: "idle",
  resetPasswordLinkStatus: "idle",
  updatingUserPasswordStatus: "idle",
};

// ------------------ Asynchronous API calls ------------------
export const logUserIn = createAsyncThunk(
  "login/logUserIn",
  async (payload: { emailAddress: string; password: string }, { dispatch }) => {
    const requestUrl = `${REACT_APP_API_URL}/login`;
    const updateUserInfoResponse = (await request(requestUrl, {
      method: "POST",
      body: JSON.stringify(payload),
    })) as {
      data: {
        userAccountId: number;
      };
      jwt: string;
    };

    localStorage.setItem("tmg-tkn", updateUserInfoResponse.jwt);
    // setCookieItem('critToken', updateUserInfoResponse.jwt, 1);
    dispatch(getUser(updateUserInfoResponse?.data?.userAccountId));
    return updateUserInfoResponse;
  }
);

export const resetPasswordLink = createAsyncThunk(
  "login/resetPasswordLink",
  async (emailAddress: string) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/resetPasswordLink`;
    return (await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify({
        emailAddress,
        action: "reset",
      }),
    })) as string;
  }
);

export const updateUsersPassword = createAsyncThunk(
  "login/updateUsersPassword",
  async (payload: { tk1: string; tk2: string; password: string }) => {
    const requestUrl = `${REACT_APP_API_URL}/doResetPasswordLink`;
    return (await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify(payload),
    })) as string;
  }
);

// ------------------ Beginning of Slice Definition ------------------
export const loginReducer = createSlice({
  name: "login",
  initialState,
  reducers: {
    resetState: () => initialState,
    resetPasswordLinkStatus: (state) => {
      state.resetPasswordLinkStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logUserIn.pending, (state) => {
        state.gettingLoginStatus = "loading";
      })
      .addCase(logUserIn.fulfilled, (state) => {
        return {
          ...state,
          gettingLoginStatus: "succeeded",
        };
      })
      .addCase(logUserIn.rejected, (state) => {
        state.gettingLoginStatus = "failed";
      })
      .addCase(resetPasswordLink.pending, (state) => {
        state.resetPasswordLinkStatus = "loading";
      })
      .addCase(resetPasswordLink.fulfilled, (state) => {
        state.resetPasswordLinkStatus = "succeeded";
      })
      .addCase(resetPasswordLink.rejected, (state) => {
        state.resetPasswordLinkStatus = "failed";
      })
      .addCase(updateUsersPassword.pending, (state) => {
        state.updatingUserPasswordStatus = "loading";
      })
      .addCase(updateUsersPassword.fulfilled, (state) => {
        state.updatingUserPasswordStatus = "succeeded";
      })
      .addCase(updateUsersPassword.rejected, (state) => {
        state.updatingUserPasswordStatus = "failed";
      });
  },
});

// ------------------ Selectors ------------------
export const selectGetLoginStatus = (state: RootState) =>
  state.login.gettingLoginStatus;
export const selectResetPasswordLinkStatus = (state: RootState) =>
  state.login.resetPasswordLinkStatus;
export const selectUpdatePasswordStatus = (state: RootState) =>
  state.login.updatingUserPasswordStatus;

// ------------------ Actions ------------------
export const { resetState, resetPasswordLinkStatus } = loginReducer.actions;

export default loginReducer.reducer;
