import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "app/storybookComponents/Button";
import { Card } from "react-bootstrap";
import pendingTestImg from "resources/images/pending-test-img.png";
import { REACT_APP_ODA_URL } from "utils/environmentVariables";

interface Props {
  percentComplete?: number;
  eventId: string | number;
}

export default function PendingTest({ percentComplete, eventId }: Props) {
  return (
    <Card className="pending-test-card">
      <div>
        <img src={pendingTestImg} alt="pending test" height="159" />
      </div>
      <div>
        <h2>
          {percentComplete
            ? `You're ${percentComplete}% done with your personality test`
            : `You’re almost done! Complete your profile`}
        </h2>
        <p className="pending-tests-text">
          Once you complete your profile by taking the personality test, you’ll
          have access to:
        </p>
        <p className="pending-tests-text">
          <FontAwesomeIcon icon="arrow-right" className="me-2" />
          Your personalized Workplace Insights report
        </p>
        <p className="pending-tests-text">
          <FontAwesomeIcon icon="arrow-right" className="me-2" />
          Collaboration Guides with tips for working with specific teammates
        </p>
        <p className="pending-tests-text">
          <FontAwesomeIcon icon="arrow-right" className="me-2" />
          Team Personality Reports to see how you compare with your teammates
        </p>
      </div>
      <div>
        <a
          href={`${REACT_APP_ODA_URL}verify/index/${eventId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button>
            {percentComplete
              ? "Resume Personality Test"
              : "Begin Personality Test"}
            <FontAwesomeIcon icon="arrow-right" className="ms-2" />
          </Button>
        </a>
      </div>
    </Card>
  );
}
