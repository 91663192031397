import { OverlayTrigger, Popover } from "react-bootstrap";

interface Props {
  pointA?: {
    avatarCircle: React.JSX.Element;
    value: number;
  };
  pointB?: {
    avatarCircle: React.JSX.Element;
    value: number;
  };
  differenceThreshold?: number;
}

export default function AgreementChart({
  pointA,
  pointB,
  differenceThreshold = 5,
}: Props) {
  const getHighAndLow = () => {
    if (!pointA && !pointB) {
      return [null, null];
    } else if (!pointA && pointB) {
      return [null, pointB];
    } else if (pointA && !pointB) {
      return [null, pointA];
    } else if ((pointA?.value as number) > (pointB?.value as number)) {
      return [pointB, pointA];
    } else {
      return [pointA, pointB];
    }
  };

  // Since adding a opacity is a bit harder than removing it we will be keeping track of the part of the graph that should have some transparency
  const getTransparencyBars = () => {
    const [lowPoint, highPoint] = getHighAndLow();
    const highValue = highPoint?.value ?? 0;
    const lowValue = lowPoint?.value ?? 0;
    const avatars = getAvatars();

    const difference = Math.abs(highValue - lowValue);

    const overlay = (
      <Popover className="team-360-popover">
        <div style={{ display: "flex", flexDirection: "column" }}>
          {difference} point difference
        </div>
      </Popover>
    );

    return [
      <OverlayTrigger key="hover-overlay" rootClose overlay={overlay}>
        <div
          style={{
            color: "red",
            position: "absolute",
            left: `${lowValue}%`,
            width: `${difference}%`,
            height: "100%",
          }}
        />
      </OverlayTrigger>,
      <div
        key="bottom"
        className="negative-space"
        style={{
          left: 0,
          width: `${lowValue}%`,
        }}
      />,
      <div
        key="top"
        className="negative-space"
        style={{
          left: `${highValue}%`,
          width: `${100 - highValue}%`,
        }}
      />,
      ...avatars,
    ];
  };

  const getAvatars = () => {
    const [lowPoint, highPoint] = getHighAndLow();
    const highValue = highPoint?.value ?? 0;
    const lowValue = lowPoint?.value ?? 0;

    const difference = Math.abs(highValue - lowValue);
    if (difference >= differenceThreshold) {
      return [
        <div
          key="lowAvatar"
          className="avatar-circle-container"
          style={{
            zIndex: 3,
            left: `${lowValue}%`,
          }}
        >
          {lowPoint?.avatarCircle}
          <p className="small-body-text">{lowValue}</p>
        </div>,
        <div
          key="highAvatar"
          style={{
            zIndex: 4,
            left: `${highValue}%`,
          }}
          className="avatar-circle-container"
        >
          {highPoint?.avatarCircle}
          <p className="small-body-text">{highValue}</p>
        </div>,
      ];
    } else if (highValue === lowValue) {
      return [
        <div
          key="lowAvatar"
          className="avatar-circle-container"
          style={{
            zIndex: 3,
            left: `${lowValue - 3}%`,
          }}
        >
          <div className="d-flex">
            <div>{lowPoint?.avatarCircle}</div>
            <div
              style={{
                marginLeft: "-10px",
              }}
            >
              {highPoint?.avatarCircle}
            </div>
          </div>
          <p className="small-body-text">{lowValue}</p>
        </div>,
      ];
    } else {
      return [
        <div
          key="lowAvatar"
          className="avatar-circle-container"
          style={{
            zIndex: 3,
            left: `${lowValue}%`,
          }}
        >
          {lowPoint?.avatarCircle}
          <p className="small-body-text">{lowValue}</p>
        </div>,
        <div
          key="highAvatar"
          style={{
            zIndex: 4,
            left: `${lowValue + differenceThreshold}%`,
          }}
          className="avatar-circle-container"
        >
          {highPoint?.avatarCircle}
          <p className="small-body-text">{highValue}</p>
        </div>,
      ];
    }
  };

  return <div className="agreement-chart">{getTransparencyBars()}</div>;
}
