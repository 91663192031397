import { Modal } from 'react-bootstrap';
import Button from 'app/storybookComponents/Button';
import { ReactNode } from 'react';

interface Props {
  show?: boolean;
  onHide: () => void;
  titleSmallText?: string;
  title?: string;
  className?: string;
  children?: ReactNode;
}

const SimpleModal = ({
  show,
  onHide,
  children,
  title,
  className = '',
  titleSmallText,
}: Props) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      className={`simple-modal ${className}`}
    >
      <div className="modal-title-row">
        <div className="column-gap-12px">
          {titleSmallText ? (
            <p className="small-body-text">{titleSmallText}</p>
          ) : null}
          <h2>{title}</h2>
        </div>
        <div>
          <Button
            onClick={() => onHide()}
            variant={'secondary-blue'}
            style={{ border: 'none', width: 'auto' }}
            xIcon
          />
        </div>
      </div>
      {children}
    </Modal>
  );
};

export default SimpleModal;
