import { Modal } from "react-bootstrap";
import InviteUserForm from "app/storybookComponents/Forms/InviteUserForm";
import Button from "app/storybookComponents/Button";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  inviteUserByEmail,
  resetInvalidInvitedStrings,
  selectAllCompanyUsersById,
  selectInvalidInvitedStrings,
  selectInviteUserByEmailStatus,
} from "app/containers/Global/slice";
import { Team, UserInfo } from "app/containers/Global/types";
import { selectCompanySettings } from "app/containers/AdminConsole/slice";
import { getAllowedDomains } from "app/containers/AdminConsole/helpers";
import { useMemo } from "react";
import { INVITE_MEMBER_DESCRIPTION_TEXT_1 } from "./constants";

interface Props {
  hideModal: () => void;
  isLoading?: boolean;
  teamMembers?: UserInfo[];
  showing?: boolean;
  currentTeamInfo?: Team;
  teamId?: number;
  modalTitle: string;
  inviteLink?: string;
  onInviteSuccess?: () => void;
}

export default function InviteUsersModal({
  hideModal,
  showing,
  teamId,
  modalTitle = "",
  isLoading,
  inviteLink,
  currentTeamInfo,
  teamMembers,
  onInviteSuccess,
}: Props) {
  const dispatch = useAppDispatch();
  const companySettings = useAppSelector(selectCompanySettings);
  const usersById = useAppSelector(selectAllCompanyUsersById);
  const invitingUsersByStatus = useAppSelector(selectInviteUserByEmailStatus);
  const invalidInvitedStrings = useAppSelector(selectInvalidInvitedStrings);

  const allUserEmails = useMemo(() => {
    if (teamMembers && teamId) {
      return teamMembers.map((member) => member.emailAddress);
    }
    return Object.values(usersById).map((user) => user.emailAddress);
  }, [usersById, teamMembers, teamId]);

  const onHide = () => {
    hideModal();
    dispatch(resetInvalidInvitedStrings());
  };

  return (
    <Modal show={showing} onHide={onHide} size="lg" className="simple-modal">
      <div className="modal-title-row">
        <h2>{modalTitle}</h2>
        <Button
          onClick={() => onHide()}
          variant={"secondary-blue"}
          style={{ border: "none", width: "auto" }}
          xIcon
        />
      </div>
      <InviteUserForm
        onInviteViaEmail={async (emailAddresses: string[]) => {
          dispatch(resetInvalidInvitedStrings());
          const resp = (await dispatch(
            inviteUserByEmail({ teamId, emailAddresses })
          )) as any;
          if (resp.error) return;
          onInviteSuccess?.();
        }}
        modalDescription={
          <p>
            <b>Why Invite Users?</b> {INVITE_MEMBER_DESCRIPTION_TEXT_1}
          </p>
        }
        teamId={teamId ?? currentTeamInfo?.teamId}
        inviteMemberInfoMessage={
          teamId
            ? "Simply type in the email addresses of the people you want to invite. They'll receive an invitation to join the platform."
            : undefined
        }
        inviteLink={inviteLink}
        isLoading={isLoading || invitingUsersByStatus === "loading"}
        teamMembers={teamMembers}
        onHide={onHide}
        hiddenUserAccounts={currentTeamInfo?.teamMemberIds ?? []}
        allowedDomains={getAllowedDomains(companySettings)}
        allUsedEmails={allUserEmails}
        invalidInvitedStrings={invalidInvitedStrings}
        onCSVUploadSuccess={() => {
          onInviteSuccess?.();
        }}
      />
    </Modal>
  );
}
