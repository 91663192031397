import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, HTMLAttributes } from 'react';
import { Button } from 'react-bootstrap';

interface Props extends HTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  variant?:
    | 'primary'
    | 'secondary-blue'
    | 'secondary-gray'
    | 'tertiary'
    | 'tertiary-blue'
    | 'danger'
    | 'grey'
    | 'secondary-danger';
  xIcon?: boolean;
  disabled?: boolean;
  type?: 'button' | 'reset' | 'submit';
  dataTestId?: string;
  size?: 'sm' | 'lg'; // 'md' is default
}

export default function index(props: Props) {
  const { children, variant, xIcon, dataTestId } = props;
  const getBootstrapVariant = () => {
    switch (variant) {
      case 'secondary-danger':
        return 'outline-danger';
      case 'danger':
        return 'danger';
      case 'secondary-blue':
      case 'tertiary':
      case 'tertiary-blue':
        return 'outline-primary';
      case 'grey':
        return 'secondary';
      case 'secondary-gray':
        return 'outline-secondary';
      case 'primary':
      default:
        return 'primary';
    }
  };

  const className = `${props.className ?? ''}${
    variant === 'tertiary-blue' ? ' tertiary-blue' : ''
  } ${props.size ?? 'sm'}`;
  const buttonProps = {
    ...props,
    variant: getBootstrapVariant(),
  };
  delete buttonProps.size;
  delete buttonProps.xIcon;
  delete buttonProps.dataTestId;
  return (
    <Button {...buttonProps} data-testid={dataTestId} className={className}>
      {children || null}
      {xIcon ? <FontAwesomeIcon icon="xmark" size="lg" /> : null}
    </Button>
  );
}
