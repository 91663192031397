import { Modal } from "react-bootstrap";
import Button from "app/storybookComponents/Button";
import { Team, UserInfo } from "app/containers/Global/types";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  selectInviteLinkByTeamId,
  inviteUserByEmail,
  selectInviteUserByEmailStatus,
  selectInvalidInvitedStrings,
  resetInvalidInvitedStrings,
} from "app/containers/Global/slice";
import InviteUserForm from "app/storybookComponents/Forms/InviteUserForm";
import { selectCompanySettings } from "app/containers/AdminConsole/slice";
import { getAllowedDomains } from "app/containers/AdminConsole/helpers";
import { INVITE_MEMBER_DESCRIPTION_TEXT_1 } from "app/components/Modals/constants";

interface Props {
  show?: boolean;
  onHide: () => void;
  inviteCount?: number;
  teamId: number;
  currentTeamInfo?: Team;
  users: UserInfo[];
}

export default function InviteMemberModal({
  show,
  onHide,
  inviteCount,
  teamId,
  currentTeamInfo,
  users,
}: Props) {
  const dispatch = useAppDispatch();
  const teamLinksById = useAppSelector(selectInviteLinkByTeamId);
  const companySettings = useAppSelector(selectCompanySettings);
  const inviteUsersByEmailStatus = useAppSelector(
    selectInviteUserByEmailStatus
  );
  const invalidInvitedStrings = useAppSelector(selectInvalidInvitedStrings);

  const hideModal = () => {
    onHide();
    dispatch(resetInvalidInvitedStrings());
  };

  return (
    <Modal
      show={show}
      onHide={hideModal}
      className="invite-modal simple-modal"
      size="lg"
    >
      <div className="modal-title-row">
        <h2>
          {inviteCount
            ? "Invitations Sent"
            : `Invite People to ${currentTeamInfo?.teamName}`}
        </h2>
        <Button
          onClick={hideModal}
          variant={"secondary-blue"}
          style={{ border: "none", width: "auto" }}
          xIcon
        />
      </div>
      <InviteUserForm
        inviteLink={teamLinksById[teamId]}
        allowedDomains={getAllowedDomains(companySettings)}
        teamId={teamId}
        onInviteViaEmail={async (emailAddresses: string[]) => {
          dispatch(resetInvalidInvitedStrings());
          const resp = (await dispatch(
            inviteUserByEmail({ teamId: teamId, emailAddresses })
          )) as any;
          if (resp.error) return;
          hideModal();
        }}
        modalDescription={
          <p>
            <b>Why Invite Users?</b> {INVITE_MEMBER_DESCRIPTION_TEXT_1}
          </p>
        }
        teamMembers={users}
        isLoading={inviteUsersByEmailStatus === "loading"}
        hiddenUserAccounts={currentTeamInfo?.teamMemberIds ?? []}
        onHide={hideModal}
        totalUsersInvited={inviteCount}
        invalidInvitedStrings={invalidInvitedStrings}
        onCSVUploadSuccess={() => {
          hideModal();
        }}
      />
    </Modal>
  );
}
