export const getTotalTimeToComplete = (questionCount: number) => {
  const timeInSeconds = Math.round(questionCount * 5);
  const minutes = Math.floor(timeInSeconds / 60);
  const minuteOrMinutes = minutes === 1 ? 'minute' : 'minutes';
  const seconds = timeInSeconds % 60;

  if (minutes === 0) {
    return `~${seconds} seconds to complete`;
  }

  let timeToComplete = `~${minutes} ${minuteOrMinutes}`;
  if (seconds > 0) {
    timeToComplete += ` and ${seconds} seconds`;
  }
  timeToComplete += ' to complete';

  return timeToComplete;
};

export const getDateInMonthDDFormat = (date?: Date | string | null) => {
  try {
    if (!date) {
      throw new Error('Date is required');
    }
    const dateObj = new Date(date);
    const month = dateObj.toLocaleString('default', { month: 'short' });
    const day = dateObj.getDate();
    return `${month} ${day}`;
  } catch (e) {
    return 'Invalid Date';
  }
};
