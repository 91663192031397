import Form from "react-bootstrap/Form";
import { useState } from "react";
import Button from "app/storybookComponents/Button";
import { TIME_INTERVAL_OPTIONS } from "./constants";
import { TimeInterval } from "./types";

interface Props {
  surveyName: string;
  onSubmit: (
    optionSelected: TimeInterval | "customDateRange",
    startDate: string,
    endDate: string
  ) => void;
}

export default function AggregatePicker({ surveyName, onSubmit }: Props) {
  const [optionSelected, setOptionSelected] = useState<
    TimeInterval | "customDateRange" | null
  >(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const getTimeIntervalOptions = () =>
    TIME_INTERVAL_OPTIONS.map((option) => (
      <>
        <div className="instance-option">
          <Form.Check
            key={option}
            type={"radio"}
            id={option}
            checked={optionSelected === option}
            onChange={() => setOptionSelected(option)}
          />
          <p className="navy-text">{option}</p>
        </div>
        <hr className="m-0" />
      </>
    ));

  const onSendResults = () => {
    if (!optionSelected) return;
    onSubmit(optionSelected, startDate, endDate);
  };

  return (
    <>
      <div>
        <div className="column-gap-8px">
          <p>
            <b>Aggregate over a time interval</b>
          </p>
          <p className="navy-text">
            View a report with results averaged from all {surveyName} surveys
            launched:
          </p>
        </div>
        <div className="time-interval-option-container">
          {getTimeIntervalOptions()}
          <div className="instance-option mb-3">
            <Form.Check
              type={"radio"}
              id={`customDateRange`}
              checked={optionSelected === "customDateRange"}
              onChange={() => setOptionSelected("customDateRange")}
            />
            <p className="navy-text">Custom date range</p>
          </div>
        </div>
        <div>
          <div
            className={`column-gap-20px${
              optionSelected === "customDateRange" ? "" : " is-disabled"
            }`}
            style={{ marginLeft: "12px" }}
          >
            <div>
              <p className="mb-1">
                <b>From</b>
              </p>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                disabled={optionSelected !== "customDateRange"}
                max={new Date().toISOString().split("T")[0]}
              />
            </div>
            <div>
              <p className="mb-1">
                <b>To</b>
              </p>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                disabled={optionSelected !== "customDateRange"}
                max={new Date().toISOString().split("T")[0]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="snapshot-box">
        <p>
          The results in this report will include all responses completed for
          any TEAMscan surveys launched within the specified time interval or
          date range. Comparisons with other teams in your organization will use
          results from the same selected time period.
        </p>
      </div>
      <hr className="m-0" />
      <div className="ms-auto">
        <Button
          onClick={onSendResults}
          disabled={
            !optionSelected ||
            (optionSelected === "customDateRange" && (!startDate || !endDate))
          }
        >
          Show results
        </Button>
      </div>
    </>
  );
}
