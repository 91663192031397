import { UserInfo } from "app/containers/Global/types";
import Loading from "app/storybookComponents/Loading";
import { useState, ReactElement, useMemo, useEffect } from "react";
import { Form } from "react-bootstrap";
import Button from "../Button";
import InviteUserForm from "./InviteUserForm";
import { Department } from "app/containers/AdminConsole/types";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreatableSingleSelect from "../SearchableInput/CreatableSingleSelect";
import { getUserOptionValue } from "../SearchableInput/helpers";
import { INVITE_MEMBER_DESCRIPTION_TEXT_1 } from "app/components/Modals/constants";

interface Props {
  onCreateTeam: (payload: {
    teamName: string;
    departmentId: number;
    teamDescription: string;
    teamMemberEmails: string[];
    teamLeaderEmail?: string;
  }) => void;
  form: {
    teamName: string;
    departmentId: number;
    teamDescription: string;
    teamMemberEmails: string[];
  };
  setForm: (payload: {
    teamName: string;
    departmentId: number;
    teamDescription: string;
    teamMemberEmails: string[];
  }) => void;
  onClose?: () => void;
  isLoading?: boolean;
  createdTeamId?: number | null;
  usersInfoById: { [userId: number]: UserInfo };
  inviteUserByEmail: (emailAddresses: string[]) => void;
  userAccountId: number;
  inviteLink?: string;
  allowedDomains?: string[] | "ALL";
  departments?: { [departmentId: number]: Department };
  onCreateDepartment?: () => void;
  isAdmin?: boolean;
  invalidInvitedStrings?: string[];
  defaultTeamLeaderId?: number | null;
  loggedInUserId?: number | null;
}

export default function CreateTeamForm({
  onCreateTeam,
  form,
  setForm,
  onClose,
  isLoading,
  createdTeamId,
  usersInfoById,
  inviteUserByEmail,
  inviteLink,
  allowedDomains,
  departments = {},
  onCreateDepartment,
  isAdmin,
  invalidInvitedStrings,
  defaultTeamLeaderId,
  loggedInUserId,
}: Props) {
  const MAX_STEPS = 3;
  const TEAM_NAME_CHAR_LIMIT = 50;
  const TEAM_DESCRIPTION_CHAR_LIMIT = 250;
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedTeamLeaderId, setSelectedTeamLeaderId] = useState<
    null | number
  >(null);
  const [createdTeamLeaderEmail, setCreatedTeamLeaderEmail] = useState("");
  const [isLeaderEmailInvalid, setIsLeaderEmailInvalid] = useState<
    "invalid-domain" | "invalid-email" | null
  >(null);

  useEffect(() => {
    setSelectedTeamLeaderId(defaultTeamLeaderId ?? null);
  }, [defaultTeamLeaderId]);

  const onFormChange = (formField: string, value: string) => {
    setForm({ ...form, [formField]: value });
  };

  const onFormSubmit = () => {
    if (!createdTeamLeaderEmail && !selectedTeamLeaderId) {
      return onCreateTeam(form);
    }

    let teamLeaderEmail: string = "";

    // If the team leader Id is set then we use that email address.
    if (selectedTeamLeaderId && usersInfoById[selectedTeamLeaderId]) {
      teamLeaderEmail = usersInfoById[selectedTeamLeaderId].emailAddress;
    } else if (createdTeamLeaderEmail) {
      teamLeaderEmail = createdTeamLeaderEmail;
    }
    onCreateTeam({ ...form, teamLeaderEmail });
  };

  const departmentOptions = Object.entries(departments).map(([key, val]) => ({
    label: val.name,
    value: Number(key),
  }));
  const userOptions = useMemo(
    () =>
      Object.entries(usersInfoById).map(([key, val]) => ({
        label: val.firstName
          ? `${val.firstName ?? ""} ${val.lastName ?? ""}`
          : val.emailAddress,
        value: Number(key),
      })),
    [usersInfoById]
  );

  const getDepartmentDropdown = () => {
    if (isAdmin) {
      return (
        <CreatableSelect
          noOptionsMessage={() => null}
          options={departmentOptions}
          onChange={(e) => {
            if (e?.value) {
              setForm({ ...form, departmentId: e.value });
            } else {
              setForm({ ...form, departmentId: 0 });
            }
          }}
          formatCreateLabel={() => (
            <div>
              <FontAwesomeIcon icon="plus" /> Add a new department
            </div>
          )}
          onCreateOption={() => {
            onCreateDepartment?.();
          }}
          value={departmentOptions.find(
            (option) => option.value === form.departmentId
          )}
          isValidNewOption={() => true}
          isClearable
        />
      );
    }

    return (
      <Select
        options={departmentOptions}
        onChange={(e) => {
          if (e?.value) {
            setForm({ ...form, departmentId: e.value });
          }
        }}
        value={departmentOptions.find(
          (option) => option.value === form.departmentId
        )}
      />
    );
  };

  const domainChecker = (email: string): string | true => {
    // If no allowed domains are set, then we allow all domains.
    if (!allowedDomains || allowedDomains === "ALL") {
      return true;
    }

    // We split at the @ symbol to get the domain.
    const emailDomain = email.split("@")[1];
    // Then combine all of the allowed domains into one string and create a regex.
    const domainRegex = new RegExp(`^(${allowedDomains.join("|")})$`, "i");
    const domainTestResponse = domainRegex.test(emailDomain);

    // If the domain is valid return true, otherwise return the domain that was invalid.
    return domainTestResponse || emailDomain;
  };

  const onEmailCapture = (createdTeamLeaderEmail: string) => {
    if (!createdTeamLeaderEmail || selectedTeamLeaderId) {
      return setIsLeaderEmailInvalid(null);
    }

    const domainCheckerResponse = domainChecker(createdTeamLeaderEmail);
    if (domainCheckerResponse !== true) {
      // if not empty then we add that domain to the invalid domains list.
      if (domainCheckerResponse) {
        return setIsLeaderEmailInvalid("invalid-domain");
      }

      // else if the domain checker response is empty then we add the email to the general invalid emails list.
      return setIsLeaderEmailInvalid("invalid-email");
    }

    setIsLeaderEmailInvalid(null);
  };

  const getWarningBanner = () => {
    if (selectedTeamLeaderId) return;
    switch (isLeaderEmailInvalid) {
      case "invalid-domain":
        return getWarningBannerElm(
          `'${createdTeamLeaderEmail}' is not an accepted email domain`,
          `${createdTeamLeaderEmail}-invalid-domain`
        );
      case "invalid-email":
        return getWarningBannerElm(
          `'${createdTeamLeaderEmail}' is not a valid email`,
          `${createdTeamLeaderEmail}-invalid-email`
        );
      default:
        return null;
    }
  };

  const getWarningBannerElm = (stringValue: string, key: string) => (
    <div
      key={key}
      className="warning-banner light-red row-gap-12px align-items-center"
    >
      <FontAwesomeIcon icon="triangle-exclamation" />
      <p>{stringValue}</p>
    </div>
  );

  const getTeamLeaderValue = () => {
    if (createdTeamLeaderEmail) {
      return {
        label: createdTeamLeaderEmail,
        value: createdTeamLeaderEmail,
        emailAddress: createdTeamLeaderEmail,
        avatarCircle: null,
      };
    }

    if (!selectedTeamLeaderId) return null;
    return getUserOptionValue(usersInfoById[selectedTeamLeaderId]) ?? null;
  };

  const isNextForStep1Disabled = () => {
    // If no team name is set then we disable the next button.
    if (!form.teamName) {
      return true;
    }

    // If we have a selected team leader by Id then the next button is enabled.
    if (selectedTeamLeaderId) {
      return false;
    }

    // Given that the team leaderId is not set we check if the email is valid.
    if (isLeaderEmailInvalid || !createdTeamLeaderEmail) {
      return true;
    }

    // If none of the above conditions are met then we enable the next button.
    return false;
  };

  const getStep1TeamLeaderSnapshotMessage = () => {
    let message = "";

    if (selectedTeamLeaderId && loggedInUserId !== selectedTeamLeaderId) {
      message =
        "An invitation email will be sent to this user to be a leader of this team.";
    } else if (createdTeamLeaderEmail && !isLeaderEmailInvalid) {
      message =
        "An invitation email will be sent to this user to join Develop by Criteria as a member of this organization and to be a leader for this department.";
    }
    if (!message) return null;
    return (
      <div className="snapshot-box mt-2">
        <FontAwesomeIcon icon={["far", "lightbulb-on"]} />
        <p>{message}</p>
      </div>
    );
  };

  const teamLeaderSelected = getTeamLeaderValue();
  const steps: { [stepNumber: number]: ReactElement } = {
    1: (
      <div className="column-gap-18px">
        <h1>Create a Team</h1>
        <p>
          Teams should only be created by the manager or team leader. Within
          Develop by Criteria, teams are working groups that are fairly stable
          and likely to together for months or years.
        </p>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            setCurrentStep(2);
          }}
        >
          <Form.Group className="form-group" controlId="teamSetupFormTeamName">
            <Form.Label className="simple-form-label">Team Name</Form.Label>
            <Form.Control
              placeholder="Type your team name here..."
              name="teamName"
              onChange={(e) => {
                onFormChange(
                  e.target.name,
                  e.target.value.slice(0, TEAM_NAME_CHAR_LIMIT)
                );
              }}
              value={form.teamName}
              required
            />
            <Form.Text id="teamNameLimit" muted>
              {form.teamName.length}/{TEAM_NAME_CHAR_LIMIT} Characters
            </Form.Text>
          </Form.Group>
          {departmentOptions?.length ? (
            <Form.Group className="form-group" controlId="teamDepartment">
              <Form.Label className="simple-form-label">Department</Form.Label>
              {getDepartmentDropdown()}
            </Form.Group>
          ) : null}
          <Form.Group className="form-group" controlId="teamLeader">
            <Form.Label className="simple-form-label">
              Add/Invite Team Leader
            </Form.Label>

            <CreatableSingleSelect
              options={userOptions}
              onChange={(e) => {
                if (e?.value) {
                  setSelectedTeamLeaderId(Number(e.value));
                } else {
                  setSelectedTeamLeaderId(null);
                  setCreatedTeamLeaderEmail("");
                  setIsLeaderEmailInvalid(null);
                }
              }}
              value={teamLeaderSelected}
              onCaptureInputValue={(val) => {
                onEmailCapture(val);
                setCreatedTeamLeaderEmail(val);
              }}
              placeHolder="Search by name or email"
            />
            {getStep1TeamLeaderSnapshotMessage()}
          </Form.Group>
          {getWarningBanner()}
          <div className="ms-auto">
            <Button
              variant="primary"
              type="submit"
              disabled={isNextForStep1Disabled()}
            >
              Next
            </Button>
          </div>
        </Form>
      </div>
    ),
    2: (
      <div className="column-gap-18px">
        <h1>Short Team Description</h1>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            onFormSubmit();
            setCurrentStep(3);
          }}
        >
          <Form.Group className="form-group" controlId="accountSetupWhatIDo">
            <Form.Label>What does this team do?</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Type your team description here..."
              name="teamDescription"
              onChange={(e) => {
                onFormChange(
                  e.target.name,
                  e.target.value.slice(0, TEAM_DESCRIPTION_CHAR_LIMIT)
                );
              }}
              value={form.teamDescription}
              required
            />
            <Form.Text id="teamDescriptionLimit" muted>
              {form.teamDescription.length}/{TEAM_DESCRIPTION_CHAR_LIMIT}{" "}
              Characters
            </Form.Text>
          </Form.Group>

          <div className="ms-auto">
            <Button
              variant="primary"
              type="submit"
              disabled={!form.teamDescription}
            >
              Next
            </Button>
          </div>
        </Form>
      </div>
    ),
    3: (
      <div className="column-gap-18px">
        <h1>Invite People to {form.teamName}</h1>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <InviteUserForm
            onInviteViaEmail={inviteUserByEmail}
            teamId={createdTeamId ?? undefined}
            inviteLink={inviteLink}
            isLoading={isLoading}
            teamMembers={Object.values(usersInfoById)}
            allowedDomains={allowedDomains}
            invalidInvitedStrings={invalidInvitedStrings ?? []}
            modalDescription={
              <p>
                <b>Why Invite Users?</b> {INVITE_MEMBER_DESCRIPTION_TEXT_1}
              </p>
            }
            onCSVUploadSuccess={() => {
              onClose?.();
            }}
          />
        </Form>
      </div>
    ),
  };

  return (
    <>
      {onClose ? (
        <Button
          onClick={() => onClose()}
          variant={"secondary-blue"}
          style={{
            border: "none",
            width: "auto",
            position: "absolute",
            right: "16px",
            top: "16px",
          }}
          xIcon
        />
      ) : null}
      <div>
        <p className="mb-0">
          Step {currentStep} of {MAX_STEPS}
        </p>
        {isLoading ? <Loading /> : steps[currentStep]}
      </div>
    </>
  );
}
