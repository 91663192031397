import {
  selectAllPendingDepartmentLeaders,
  sendReminder,
} from "app/containers/Dashboard/slice";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import Button from "app/storybookComponents/Button";
import Select from "react-select";
import SimpleModal from "./SimpleModal";
import { Form } from "react-bootstrap";
import { getSelectProps } from "utils/helperFunctions";
import { useCallback, useMemo, useState } from "react";
import { PendingDepartmentLeader } from "app/containers/Dashboard/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  onHide: () => void;
  show?: boolean;
  onInviteDepartmentLeader?: () => void;
}

export default function PendingDepartmentsModal({
  show,
  onHide,
  onInviteDepartmentLeader,
}: Readonly<Props>) {
  const dispatch = useAppDispatch();
  const [searchInput, setSearchInput] = useState("");
  const pendingDepartmentLeaders = useAppSelector(
    selectAllPendingDepartmentLeaders
  );
  // since a user could be invited to multiple departments, we need to keep track of the userAccountId and departmentId
  const [remindedMembers, setRemindedMembers] = useState<Set<string>>(
    new Set()
  );

  const getPendingTeamLeaderElement = useCallback(
    (teamLeader: PendingDepartmentLeader, key: number) => {
      const { firstName, lastName, emailAddress, userAccountId, departmentId } =
        teamLeader;
      const remindedKeyString = `${userAccountId}-${departmentId}`;
      const isReminded = remindedMembers.has(remindedKeyString);
      const name = `${firstName} ${lastName}`.trim();
      return (
        <div className="section-body-row" key={key}>
          <div
            className="d-flex gap-2 align-items-center"
            style={{ color: "#53565a" }}
          >
            <div
              style={{
                width: "30px",
                textAlign: "center",
                fontSize: "24px",
              }}
            >
              <FontAwesomeIcon icon={["fal", "clock"]} />
            </div>
            <div className="section-body-name-and-description">
              <span className="section-body-row-name">
                Invite Sent to {name}
              </span>
              <span className="section-body-row-description">
                Team leader email: {emailAddress}
              </span>
            </div>
          </div>
          <div className="d-flex gap-2 align-items-center">
            {!isReminded ? (
              <Button
                className="nowrap"
                variant="secondary-blue"
                onClick={() => {
                  dispatch(
                    sendReminder({
                      userAccountId,
                      reminderType: "invitationReminder",
                    })
                  );
                  setRemindedMembers((prevIds) =>
                    new Set(prevIds).add(remindedKeyString)
                  );
                }}
              >
                Remind
              </Button>
            ) : (
              <span className="label-tag grey nowrap">Reminder sent</span>
            )}
          </div>
        </div>
      );
    },
    [dispatch, remindedMembers]
  );

  const teamMembers = useMemo(
    () =>
      pendingDepartmentLeaders
        .filter((teamLeader) => {
          return (
            teamLeader?.firstName
              ?.toLowerCase()
              .includes(searchInput.toLowerCase()) ??
            teamLeader.lastName
              ?.toLowerCase()
              .includes(searchInput.toLowerCase()) ??
            teamLeader.emailAddress
              ?.toLowerCase()
              .includes(searchInput.toLowerCase())
          );
        })
        .map((teamLeader, idx) => getPendingTeamLeaderElement(teamLeader, idx)),
    [searchInput, getPendingTeamLeaderElement, pendingDepartmentLeaders]
  );

  const { selectStyles, components } = getSelectProps("search");
  return (
    <SimpleModal title="Pending Department Leaders" onHide={onHide} show={show}>
      <Form.Group className="w-100">
        <Select
          placeholder={"Search by name or email..."}
          isClearable={true}
          isSearchable={true}
          components={components}
          inputValue={searchInput}
          styles={selectStyles}
          menuIsOpen={false}
          onInputChange={(e, actionMeta) => {
            if (actionMeta.action === "input-change") {
              setSearchInput(e);
            }
          }}
        />
      </Form.Group>
      <div className="section-container mb-0">{teamMembers}</div>
      <div className="d-flex justify-content-between align-items-center">
        {onInviteDepartmentLeader ? (
          <Button variant="secondary-blue" onClick={onInviteDepartmentLeader}>
            Invite a department leader
          </Button>
        ) : null}
        {/* <p className="ms-auto">
          {pendingTeamLeaders.length} pending team
          {pendingTeamLeaders.length === 0 ? "" : "s"}
        </p> */}
      </div>
    </SimpleModal>
  );
}
