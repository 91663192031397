import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "app/storybookComponents/Button";
import { SyntheticEvent, useEffect, useState } from "react";
import { Alert, Card, Form, InputGroup } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  logUserIn,
  resetPasswordLink,
  selectGetLoginStatus,
  selectResetPasswordLinkStatus,
  resetPasswordLinkStatus as resetPasswordLinkStatusAction,
} from "./slice";
import LoginHeader from "./LoginHeader";
import { getInputPlaceholder, getInputType, getEyeSlashed } from "./helpers";

export default function Login() {
  const dispatch = useAppDispatch();

  // ------------------ Selectors ------------------
  const getLoginStatus = useAppSelector(selectGetLoginStatus);
  const resetPasswordLinkStatus = useAppSelector(selectResetPasswordLinkStatus);

  // ------------------ State ------------------
  const [showPassword, setShowPassword] = useState(false);
  const [invalidEmailOrPassword, setInvalidEmailOrPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [resetPassword, setResetPassword] = useState(false);

  useEffect(() => {
    if (getLoginStatus === "failed") {
      setInvalidEmailOrPassword(true);
    }
  }, [getLoginStatus]);

  const submitHandler = (e: SyntheticEvent) => {
    e.preventDefault();
    // if resetPassword is true then we send the request to reset the password and exit the function
    if (resetPassword) {
      dispatch(resetPasswordLink(emailAddress));
      return;
    }

    dispatch(
      logUserIn({
        emailAddress,
        password,
      })
    );
  };

  const getUserInputForm = () => (
    <Form onSubmit={submitHandler} noValidate className={"needs-validation"}>
      <Form.Group className="has-error">
        <Form.Label>Email</Form.Label>
        <Form.Control
          placeholder="Example@gmail.com"
          type="emailAddress"
          value={emailAddress}
          onChange={(e) => {
            setEmailAddress(e.target.value);
            setInvalidEmailOrPassword(false);
          }}
          className={`${invalidEmailOrPassword ? "is-invalid " : ""}${
            emailAddress ? "" : "empty "
          }`}
          disabled={getLoginStatus === "loading"}
        />
      </Form.Group>
      {!resetPassword ? (
        <>
          <Form.Group className="has-error" style={{ marginTop: "18px" }}>
            <Form.Label>Password</Form.Label>
            <InputGroup>
              <Form.Control
                placeholder={getInputPlaceholder(showPassword)}
                type={getInputType(showPassword)}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setInvalidEmailOrPassword(false);
                }}
                className={`${invalidEmailOrPassword ? "is-invalid " : ""}${
                  password ? "" : "empty "
                }`}
                disabled={getLoginStatus === "loading"}
              />
              <InputGroup.Text>
                <div
                  role="button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <FontAwesomeIcon
                    icon={["far", getEyeSlashed(showPassword)]}
                  />
                </div>
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <div
            role="button"
            className="link-primary mt-2"
            onClick={() => {
              setResetPassword(true);
              setPassword("");
              setInvalidEmailOrPassword(false);
            }}
          >
            Forgot Password?
          </div>
        </>
      ) : null}
      {invalidEmailOrPassword && !resetPassword ? (
        <Alert variant="danger">
          Incorrect username or password. Please try again.
        </Alert>
      ) : null}
      <Button
        type="submit"
        className="w-100"
        style={{ marginTop: "30px" }}
        disabled={
          getLoginStatus === "loading" ||
          resetPasswordLinkStatus === "loading" ||
          emailAddress === ""
        }
      >
        {resetPassword ? "Reset Password" : "Login"}
        <FontAwesomeIcon icon="arrow-right" className="ms-2" />
      </Button>
      {resetPassword ? (
        <Button
          variant="secondary-blue"
          className="w-100 mt-2 border-0"
          onClick={() => setResetPassword(false)}
        >
          Back to Login
        </Button>
      ) : null}
    </Form>
  );

  const getSuccessfulResetPassword = () => {
    return (
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          setResetPassword(false);
          dispatch(resetPasswordLinkStatusAction());
        }}
      >
        <p>
          If your account exists in our database, we'll email you a reset link.
        </p>
        <Button type="submit" className="w-100" style={{ marginTop: "30px" }}>
          Back to Login
          <FontAwesomeIcon icon="arrow-left" className="ms-2" />
        </Button>
      </Form>
    );
  };

  return (
    <div className="login-page">
      <Card>
        <LoginHeader
          title={
            resetPassword
              ? "Forgot your password?"
              : "Unlock your team's potential"
          }
          subTitle={
            resetPassword ? null : (
              <span>
                <strong>with Develop</strong> by Criteria
              </span>
            )
          }
          infoText={
            resetPassword
              ? "Enter your email address below to receive an email containing a link to reset your password."
              : null
          }
        />

        {resetPasswordLinkStatus === "succeeded"
          ? getSuccessfulResetPassword()
          : getUserInputForm()}
      </Card>
    </div>
  );
}
