import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { request } from "utils/request";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import { OpenAIGeneratedText } from "app/components/OpenAIInternalTools/OpenAIModule/types";

export interface AffirmationState {
  affirmation: null | OpenAIGeneratedText;
  gettingAffirmationStatus: responseStatus;
}

const initialState: AffirmationState = {
  affirmation: null,
  gettingAffirmationStatus: "idle",
};

export const getAffirmation = createAsyncThunk(
  "generateAffirmation",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/generate`;
    return (await request(requestUrl, {
      method: "POST",
      body: JSON.stringify({ payload: { promptId: 4 } }),
    })) as OpenAIGeneratedText;
  }
);

export const affirmationSlice = createSlice({
  name: "affirmation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAffirmation.pending, (state) => {
        state.gettingAffirmationStatus = "loading";
      })
      .addCase(
        getAffirmation.fulfilled,
        (state, action: PayloadAction<OpenAIGeneratedText>) => {
          return {
            ...state,
            gettingAffirmationStatus: "succeeded",
            affirmation: { ...action.payload },
          };
        }
      )
      .addCase(getAffirmation.rejected, (state) => {
        state.gettingAffirmationStatus = "failed";
      });
  },
});

export const selectAffirmation = (state: RootState) =>
  state.affirmation.affirmation;
export const selectGetAffirmationStatus = (state: RootState) =>
  state.affirmation.gettingAffirmationStatus;

export default affirmationSlice.reducer;
