import AvatarCircle from "app/components/AvatarCircle";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  selectAllCompanyUsersById,
  selectSampleUsersInfoById,
} from "app/containers/Global/slice";
import { useAppSelector } from "utils/redux/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface MemberScore {
  score: number;
  userAccountId: number;
}

interface Props {
  memberScores: MemberScore[];
  lowLabel: string;
  highLabel: string;
}

export default function WorkplaceInsightBar({
  memberScores,
  lowLabel,
  highLabel,
}: Props) {
  const sampleUsersById = useAppSelector(selectSampleUsersInfoById);
  const companyUsersById = useAppSelector(selectAllCompanyUsersById);
  const usersInfoById = { ...sampleUsersById, ...companyUsersById };
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  let debounceTimer: NodeJS.Timeout | null = null; // Specify the type of debounceTimer

  const handleResize = () => {
    if (containerRef.current) {
      const width = containerRef.current.offsetWidth;
      setContainerWidth(width);
      // Perform your logic with the width here
    }
  };

  const debounce = (callback: () => void, delay: number) => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    debounceTimer = setTimeout(callback, delay);
  };

  useEffect(() => {
    // Initial width
    handleResize();

    const handleResizeDebounced = () => debounce(handleResize, 300); // Adjust the debounce delay as per your requirements

    // Listen for resize events
    window.addEventListener("resize", handleResizeDebounced);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResizeDebounced);
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // We will need to create a function that takes in the current memberScores and returns back a new array of nested arrays type
  const { allClashingScores, nonCrashingScores } = useMemo(() => {
    const threshold = 25; // This is the width size of the avatar.
    const allClashingScores: MemberScore[][] = [];
    const nonCrashingScores: MemberScore[] = [];
    let clashingScores: MemberScore[] = [];
    let isClashing = false;

    // will iterate through memberScores
    const addToArray = (
      memberScore: {
        userAccountId: number;
        score: number;
      },
      isClashingWithNext?: boolean
    ) => {
      // If the current score clashing with the next score of if the current score is clashing with the previous score, then push to clashingScores
      if (isClashing || isClashingWithNext) {
        clashingScores.push(memberScore);
      } else {
        // Else push to nonCrashingScores
        nonCrashingScores.push(memberScore);
      }

      // If the current score is not clashing with the next but is clashing with previous then we should push the current clashingScores to allClashingScores
      // and reset clashingScores to empty array
      if (isClashing && !isClashingWithNext) {
        allClashingScores.push(clashingScores);
        clashingScores = [];
      }
      isClashing = !!isClashingWithNext;
    };

    const sortedMembers = memberScores.sort((a, b) => a.score - b.score);
    sortedMembers.forEach((memberScore, idx) => {
      // If the last one no need to check the following one is clashing because there is no following one.
      if (idx === memberScores.length - 1) {
        addToArray(memberScore);
        return;
      }

      const currentScore = (memberScore.score / 100) * containerWidth;
      const nextScore = (memberScores[idx + 1].score / 100) * containerWidth;
      const isClashingWithNextMember = nextScore - currentScore < threshold;
      addToArray(memberScore, isClashingWithNextMember);
    });
    return { nonCrashingScores, allClashingScores };
  }, [memberScores, containerWidth]);

  const getToolTipContent = (
    member: MemberScore,
    clashingMembers?: MemberScore[]
  ) => {
    if (!clashingMembers) {
      const memberInfo = usersInfoById[member.userAccountId];
      return (
        <div>
          {memberInfo?.firstName || ""} {memberInfo?.lastName || ""}
        </div>
      );
    }
    return (
      <div className="d-flex flex-nowrap gap-1">
        {clashingMembers.map((member) => (
          <AvatarCircle userAccountId={member.userAccountId} size="small" />
        ))}
      </div>
    );
  };

  const getMemberElement = (member: MemberScore, members?: MemberScore[]) => {
    const score = member.score || 0;
    // left 95 is the width of the bar
    //so have to divide by 100 and multiply by 95
    const pos = (score / 100) * 95;
    return (
      <div
        style={{
          left: `${pos}%`,
        }}
        className="member"
        key={member.userAccountId}
      >
        {/* If member is not undefined then we should show an icon on top indicating that there is another user underneath */}
        {members ? (
          <div className="d-flex justify-content-center grey-text mb-1">
            <FontAwesomeIcon icon="location-dot" />
          </div>
        ) : null}
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip
              id="button-tooltip"
              className="light-tooltip no-max-width-tooltip"
            >
              {getToolTipContent(member, members)}
            </Tooltip>
          }
          trigger={["hover", "focus"]}
        >
          <div>
            <AvatarCircle userAccountId={member.userAccountId} size="small" />
          </div>
        </OverlayTrigger>
      </div>
    );
  };

  const members: React.JSX.Element[] = [];
  allClashingScores.forEach((clashingMembers) => {
    clashingMembers.forEach((member) => {
      members.push(getMemberElement(member, clashingMembers));
    });
  });
  nonCrashingScores.forEach((member) => {
    members.push(getMemberElement(member));
  });

  return (
    <>
      <div className="workplace-insights-bar-container" ref={containerRef}>
        <div className="bar">
          <div />
          <div />
          <div />
          <div />
        </div>
        <div className="members">{members}</div>
      </div>

      <div className="workplace-insights-bar-labels">
        <div>{lowLabel}</div>
        <div>{highLabel}</div>
      </div>
    </>
  );
}
