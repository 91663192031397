import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { request } from "utils/request";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import { OpenAIGeneratedText } from "app/components/OpenAIInternalTools/OpenAIModule/types";

export interface FeedbackState {
  feedback: null | OpenAIGeneratedText;
  gettingFeedbackStatus: responseStatus;
  gettingResetStatus: responseStatus;
}

const initialState: FeedbackState = {
  feedback: null,
  gettingFeedbackStatus: "idle",
  gettingResetStatus: "idle",
};

export const getFeedback = createAsyncThunk(
  "generateFeedback",
  async (payload: {
    recipient: string;
    behavior: string;
    feedbackType: string;
    tokensRequired: number;
    feedbackLength: string;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/generate`;
    return (await request(requestUrl, {
      method: "POST",
      body: JSON.stringify({ payload: { promptId: 5, userInput: payload } }),
    })) as OpenAIGeneratedText;
  }
);

export const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    resetFeedback: (state) => {
      state.feedback = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeedback.pending, (state) => {
        state.gettingFeedbackStatus = "loading";
      })
      .addCase(
        getFeedback.fulfilled,
        (state, action: PayloadAction<OpenAIGeneratedText>) => {
          return {
            ...state,
            gettingFeedbackStatus: "succeeded",
            feedback: { ...action.payload },
          };
        }
      )
      .addCase(getFeedback.rejected, (state) => {
        state.gettingFeedbackStatus = "failed";
      });
  },
});

export const selectFeedback = (state: RootState) => state.feedback.feedback;
export const selectGetFeedbackStatus = (state: RootState) =>
  state.feedback.gettingFeedbackStatus;

export const { resetFeedback } = feedbackSlice.actions;

export default feedbackSlice.reducer;
