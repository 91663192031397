import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "app/storybookComponents/Button";
import { useState, useEffect, useMemo } from "react";
import { Card, Collapse, OverlayTrigger, Popover } from "react-bootstrap";
import notableTraitIcon from "resources/icons/notable-trait.svg";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import EmptyTeamPersonalityIllustration from "resources/images/team-personality-empty.jpg";
import {
  selectPersonalityScoresByTeamId,
  selectGetTeamPersonalityScoresStatus,
  getTeamPersonalityScores,
} from "./slice";
import TraitCard from "./TraitCard";
import Loading from "app/storybookComponents/Loading";
import { selectTeamsByTeamId } from "app/containers/Global/slice";
import TeamBlindSpotReport from "./TeamBlindSpotReport";
import TeamPotentialConflictAreas from "./TeamPotentialConflictAreas";
import TeamSuperPower from "./TeamSuperPower";
import WhatTeamMembersBringToThisTeamReport from "./WhatTeamMembersBringToThisTeamReport";
import RadarChartCard from "./RadarChartCard";

interface Props {
  totalTeamMembers: number;
  teamId: number;
}

export default function TeamPersonality({ totalTeamMembers, teamId }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [showingAllTraits, setShowingAllTraits] = useState(false);
  const teamPersonalityScores = useAppSelector(
    selectPersonalityScoresByTeamId(teamId)
  );
  const getTeamPersonalityScoresStatus = useAppSelector(
    selectGetTeamPersonalityScoresStatus
  );

  const teamByTeamId = useAppSelector(selectTeamsByTeamId);
  const team = teamByTeamId[teamId] || null;

  useEffect(() => {
    // If we are already looking for the team personality scores or if we already have the scores, then we don't need to do anything
    if (
      getTeamPersonalityScoresStatus === "loading" ||
      teamPersonalityScores !== undefined
    ) {
      return;
    }
    // Otherwise, we need to fetch the team personality scores
    dispatch(getTeamPersonalityScores(teamId));
  }, [dispatch, teamId, teamPersonalityScores, getTeamPersonalityScoresStatus]);

  const getTraitCardsHeader = () => {
    if (teamPersonalityScores === null) {
      return <h2>Team Personality Traits</h2>;
    }

    const isViewInsightsButtonDisabled = !!(
      teamPersonalityScores && teamPersonalityScores.teamMembers.length < 4
    );

    return (
      <div>
        <div className="d-flex justify-content-between">
          <h2>Team Personality Traits</h2>
          <div
            style={{
              cursor: isViewInsightsButtonDisabled ? "not-allowed" : undefined,
            }}
          >
            <Button
              disabled={isViewInsightsButtonDisabled}
              onClick={() => {
                if (isViewInsightsButtonDisabled) return;
                navigate(`/TeamGuide/${teamId}/TeamPersonality`);
              }}
            >
              View Insights
            </Button>
          </div>
        </div>
        <p className="team-personality-paragraph">
          This section shows how your team members work according to 10
          different behavioral traits. Notable traits are marked with a
          <img src={notableTraitIcon} alt="notable trait" />
          and most strongly characterize your team.
        </p>
        <span className="count-text">
          Showing {teamPersonalityScores?.teamMembers.length || 0} of{" "}
          {totalTeamMembers} members.{" "}
          <OverlayTrigger
            trigger={["hover", "focus"]}
            overlay={
              <Popover
                className="explore-button-tooltip"
                id="explore-button-tooltip"
                style={{ padding: "10px" }}
              >
                Team members who have joined the team, completed their
                personality test, and chosen to be visible on Team Reports will
                appear on the continuums.
              </Popover>
            }
          >
            <FontAwesomeIcon icon={["far", "question-circle"]} />
          </OverlayTrigger>
        </span>
      </div>
    );
  };

  const { notableTraits, nonNotableTraits } = useMemo(() => {
    const notableTraits: React.JSX.Element[] = [];
    const nonNotableTraits: React.JSX.Element[] = [];
    if (!teamPersonalityScores?.teamScores) {
      return { notableTraits, nonNotableTraits };
    }

    Object.entries(teamPersonalityScores.teamScores).forEach(([key, value]) => {
      const traitCard = (
        <TraitCard
          traitAbbrev={key}
          teamMemberScores={teamPersonalityScores.teamMembers}
          isNotableTrait={!!value.isNotableTrait}
        />
      );
      if (!traitCard) return;
      if (value.isNotableTrait) {
        notableTraits.push(traitCard);
      } else {
        nonNotableTraits.push(traitCard);
      }
    });
    return { notableTraits, nonNotableTraits };
  }, [teamPersonalityScores]);

  const getTraitCardsContent = () => {
    if (getTeamPersonalityScoresStatus === "loading") {
      return <Loading />;
    }
    // if team personalityScore is null then we should be showing the empty state
    if (teamPersonalityScores === null) {
      return (
        <div className="column-gap-20px align-items-center">
          <img
            src={EmptyTeamPersonalityIllustration}
            alt="empty team personality illustration"
            style={{
              width: "200px",
              height: "200px",
            }}
          />
          <p>
            Once your team members have taken their personality assessment,
            their results will appear here.
          </p>
        </div>
      );
    }

    return (
      <>
        <div className="trait-cards">{notableTraits}</div>
        <Collapse in={showingAllTraits}>
          <div className="trait-cards">{nonNotableTraits}</div>
        </Collapse>
        <div className="trait-cards-expand-button-container">
          <Button
            variant="secondary-blue"
            onClick={() => {
              setShowingAllTraits(!showingAllTraits);
            }}
            className="border-0"
          >
            {showingAllTraits ? "Show notable traits" : "Show all traits"}
            <FontAwesomeIcon
              icon={showingAllTraits ? "chevron-up" : "chevron-down"}
              className="ms-2"
            />
          </Button>
        </div>
      </>
    );
  };

  const getTeamPersonalitySummaryText = () => {
    if (!teamPersonalityScores || !team.teamMemberIds) {
      return <div></div>;
    }

    return (
      <div style={{ fontSize: "14px" }}>
        See how personalities shape your team’s behaviors.{" "}
        {teamPersonalityScores.teamMembers.length} out of{" "}
        {team.teamMemberIds.length} members have taken the personality
        assessment. Encourage your team members to take the assessment. This
        report will be more accurate and valuable if it reflects all team
        members on this team.
      </div>
    );
  };

  if (getTeamPersonalityScoresStatus === "loading") {
    return (
      <div className="team-personality-content">
        <Card className="team-personality-content">
          <h2>Team Personality Report</h2>
          <Loading />
        </Card>
      </div>
    );
  }

  return (
    <div className="team-personality-content">
      <Card className="team-personality-content">
        <h2>Team Personality Report</h2>
        {getTeamPersonalitySummaryText()}
        {teamPersonalityScores && (
          <Card>
            {getTraitCardsHeader()}
            {getTraitCardsContent()}
          </Card>
        )}
        <TeamSuperPower
          superPower={teamPersonalityScores?.superPower || null}
        />
        <RadarChartCard
          teamPersonalityScores={teamPersonalityScores}
          teamId={teamId}
        />
        <TeamBlindSpotReport
          blindSpots={teamPersonalityScores?.blindSpots || []}
        />
        <WhatTeamMembersBringToThisTeamReport
          teamMembers={teamPersonalityScores?.teamMembers || []}
          whatTeamMembersBringToThisTeam={
            teamPersonalityScores?.whatTeamMembersBringToThisTeam || {}
          }
        />
        <TeamPotentialConflictAreas
          conflictAreas={teamPersonalityScores?.conflictAreas || []}
        />
      </Card>
    </div>
  );
}
