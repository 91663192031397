import { ReactNode } from "react";

interface Tab {
  label: string;
  count?: number;
  isDisabled?: boolean;
  tooltip?: ReactNode;
}

interface Props {
  tabs: Tab[];
  selectedTab?: string;
  onTabSelected: (tabLabel: string) => void;
}

const CircularTabs = ({ tabs, selectedTab, onTabSelected }: Props) => {
  return (
    <div className="circular-tabs">
      {tabs.map(({ label, count, isDisabled, tooltip }) => {
        const isActive = selectedTab === label;
        const tabClassName = `tab${isActive ? " active" : ""}${
          isDisabled ? " disabled" : ""
        }`;

        const handleTabClick = () => {
          if (!isDisabled) {
            onTabSelected(label);
          }
        };

        return (
          <div key={label} className={tabClassName} onClick={handleTabClick}>
            <p>{label}</p>
            {count ? <span className="tab-count">{count}</span> : null}
            {tooltip ?? null}
          </div>
        );
      })}
    </div>
  );
};

export default CircularTabs;
