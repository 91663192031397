import { TEAM_360_FACTOR_ID_TO_NAME_MAP } from 'app/containers/SkillsGuide/constants';
import { selectAllDimensionsById } from 'app/containers/SkillsGuide/slice';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'utils/redux/hooks';

interface Props {
  linkText?: string;
  separator?: string;
  baseLink?: string;
  showQuotationMarks?: boolean;
}

const DimensionLinkedText = ({
  linkText,
  separator = '"',
  baseLink = '/SkillsGuide',
  showQuotationMarks,
}: Props) => {
  const dimensionsByName = useAppSelector(selectAllDimensionsById);

  if (!linkText) return null;

  const regex = new RegExp(`${separator}([^${separator}]*)${separator}`);
  const parts = linkText.split(regex);

  return (
    <p>
      {parts.map((part, index) => {
        // This is a regular part of the string, just render it
        if (index % 2 === 0) {
          return part;
        }

        const dimension = dimensionsByName[part];
        if (!dimension) {
          return `"${part}"`;
        }
        const factor = TEAM_360_FACTOR_ID_TO_NAME_MAP[dimension.factorId];

        // This is a link text, render a Link component
        const linkPath = `${baseLink}?factor=${factor}&dimension=${encodeURIComponent(
          part
        )}`;
        return (
          <React.Fragment key={index}>
            {showQuotationMarks ? '"' : null}
            <Link key={index} to={linkPath}>
              {part}
            </Link>
            {showQuotationMarks ? '"' : null}
          </React.Fragment>
        );
      })}
    </p>
  );
};

export default memo(DimensionLinkedText);
