import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import Button from "app/storybookComponents/Button";
import { Dropdown, OverlayTrigger, Popover } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  selectCurrentUserAccountId,
  selectSampleTeamsByTeamId,
  selectSampleUsersInfoById,
  selectInviteLinkByTeamId,
  selectIsCurrentUserAdmin,
  selectCompanyInfo,
  selectGetAllUserStatus,
  selectTeamsByTeamId,
  selectAllCompanyUsersById,
} from "app/containers/Global/slice";
import {
  openCreateTeamModal,
  openInviteTeamLeaderModal,
} from "app/components/Modals/slice";
import {
  createCompanyInviteLink,
  getTeamAndDepartmentLeads,
  selectCompanyInviteLink,
  selectCompanySettings,
  selectCreateCompanyInviteLinkStatus,
  selectDepartments,
  selectGetTeamAndDepartmentLeadsStatus,
  selectTeamAndDepartmentLeadIdsForLoggedInUser,
} from "app/containers/AdminConsole/slice";
import { ValidDashboardRole, isDashboardRole } from "./types";
import { getNotifications } from "./slice";
import { Link, useSearchParams } from "react-router-dom";
import {
  getAssessmentInstances,
  getMostRecentAssessmentInstances,
  getPendingAssessments,
  selectGetPendingAssessmentsStatus,
} from "app/containers/Assessment/slice";
import DepartmentLeaderOnboarding from "./DashboardOnboarding/DepartmentLeaderOnboarding";
import Loading from "app/storybookComponents/Loading";
import AvatarCircle from "app/components/AvatarCircle";
import InviteUsersModal from "app/components/Modals/InviteUsersModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdminOnboarding from "./DashboardOnboarding/AdminOnboarding";
import QuickActionDrawer from "app/components/QuickActions/QuickActionDrawer";
import {
  postUserOnboardingTrackingForEntireSite,
  selectGetUserOnboardingTrackingStatus,
  selectGlobalTrackingEvents,
} from "app/components/Onboarding/slice";
import { TrackedQuickActionEventsMapByRole } from "./constants";
import {
  getTeam360Scores,
  selectGettingAllTeam360AssessmentResultsStatus,
} from "app/components/SurveyDataInstances/slice";
import TeamLeaderCreateTeamOnboardingModal from "./DashboardOnboarding/TeamLeaderCreateTeamOnboardingModal";
import { getAllowedDomains } from "../AdminConsole/helpers";
import MemberDashboard from "./DashboardOnboarding/MemberDashboard";

export default function DashboardIndex() {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  // ------------------------ Selectors ------------------------
  const userAccountId = useAppSelector(selectCurrentUserAccountId);
  const sampleUsersById = useAppSelector(selectSampleUsersInfoById);
  const companyUsersById = useAppSelector(selectAllCompanyUsersById);
  const sampleTeamsById = useAppSelector(selectSampleTeamsByTeamId);
  const allTeamsById = useAppSelector(selectTeamsByTeamId);
  const departments = useAppSelector(selectDepartments);
  const getAllTeam360ScoresStatus = useAppSelector(
    selectGettingAllTeam360AssessmentResultsStatus
  );
  const getPendingAssessmentsStatus = useAppSelector(
    selectGetPendingAssessmentsStatus
  );
  const teamAndDepartmentLeaderOf = useAppSelector(
    selectTeamAndDepartmentLeadIdsForLoggedInUser
  );
  const getTeamAndDepartmentLeadsStatus = useAppSelector(
    selectGetTeamAndDepartmentLeadsStatus
  );
  const createCompanyInviteLinkStatus = useAppSelector(
    selectCreateCompanyInviteLinkStatus
  );
  const companyInfo = useAppSelector(selectCompanyInfo);
  const companyInviteLink = useAppSelector(selectCompanyInviteLink);
  const teamLinksById = useAppSelector(selectInviteLinkByTeamId);
  const isAdmin = useAppSelector(selectIsCurrentUserAdmin);
  const globalTrackingEvents = useAppSelector(selectGlobalTrackingEvents);
  const getUserTrackingEventsStatus = useAppSelector(
    selectGetUserOnboardingTrackingStatus
  );
  const companySettings = useAppSelector(selectCompanySettings);
  const getAllUserStatus = useAppSelector(selectGetAllUserStatus);
  const teamsById = useMemo(
    () => ({ ...sampleTeamsById, ...allTeamsById }),
    [sampleTeamsById, allTeamsById]
  );
  const usersById = useMemo(
    () => ({ ...sampleUsersById, ...companyUsersById }),
    [sampleUsersById, companyUsersById]
  );

  // ------------------------ State ------------------------
  const [isTeamLead, setIsTeamLead] = useState(false);
  const [isDepartmentLead, setIsDepartmentLead] = useState(false);
  const [showCreateTeamBanner, setShowCreateTeamBanner] = useState(false);
  const [dashboardRoleShowing, setDashboardRoleShowing] =
    useState<ValidDashboardRole>("Team Member");
  const [selectedId, setSelectedId] = useState<null | number>(null);
  const [showInviteMemberModal, setShowInviteMemberModal] = useState(false);
  const [showQuickActions, setShowQuickActions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showTeamLeaderOnboardingModal, setShowTeamLeaderOnboardingModal] =
    useState(false);
  const [
    teamLeaderOnboardingModalBeenShown,
    setTeamLeaderOnboardingModalBeenShown,
  ] = useState(false);
  const [teamCreationDefaultDepartment, setTeamCreationDefaultDepartment] =
    useState<null | number>(null);

  // ------------------------ Effects ------------------------
  useEffect(() => {
    dispatch(getTeamAndDepartmentLeads());
    dispatch(getNotifications());
    dispatch(getMostRecentAssessmentInstances());
    dispatch(getAssessmentInstances());
  }, [dispatch]);

  useEffect(() => {
    if (!teamAndDepartmentLeaderOf) {
      return;
    }
    const isUserATeamLead = teamAndDepartmentLeaderOf?.teams?.length > 0;
    setIsTeamLead(isUserATeamLead);

    const isUserADepartmentLead =
      teamAndDepartmentLeaderOf?.departments?.length > 0;
    setIsDepartmentLead(isUserADepartmentLead);
  }, [teamAndDepartmentLeaderOf]);

  useEffect(() => {
    if (getAllTeam360ScoresStatus === "idle") {
      dispatch(getTeam360Scores());
    }
  }, [getAllTeam360ScoresStatus, dispatch]);

  useEffect(() => {
    if (getPendingAssessmentsStatus === "idle") {
      dispatch(getPendingAssessments());
    }
  }, [dispatch, getPendingAssessmentsStatus]);

  useEffect(() => {
    if (
      createCompanyInviteLinkStatus === "idle" &&
      companyInfo?.companyAccountId
    ) {
      dispatch(
        createCompanyInviteLink({
          payload: { expiration: 20 },
          companyAccountId: companyInfo?.companyAccountId,
        })
      );
    }
  }, [createCompanyInviteLinkStatus, companyInfo?.companyAccountId, dispatch]);

  useEffect(() => {
    if (
      !userAccountId ||
      !usersById[userAccountId] ||
      !teamAndDepartmentLeaderOf
    ) {
      return;
    }

    const user = usersById[userAccountId];
    const isUserATeamLead = teamAndDepartmentLeaderOf?.teams?.length > 0;
    // We will only show the modal if the id is 3 and the user is not team leader for any teams
    if (user.ti_onboardingRoleId === 3 && !isUserATeamLead) {
      setShowCreateTeamBanner(true);
    }
  }, [usersById, userAccountId, teamAndDepartmentLeaderOf]);

  // This function will take in array of departmentId, we will then iterate through each of them
  // and return the first one that has an actual department in the system. This is needed because sometimes when the user deletes a department it will still be in the array
  const getFirstDepartmentId = useCallback(
    (departmentIds: number[] = []) =>
      departmentIds.find((departmentId) => departments[departmentId]),
    [departments]
  );

  // This function will take in array of teamId, we will then iterate through each of them
  // and return the first one that has an actual team in the system. This is needed because sometimes when the user deletes a team it will still be in the array
  const getFirstTeamId = useCallback(
    (teamIds: number[] = []) => teamIds.find((teamId) => teamsById[teamId]),
    [teamsById]
  );

  useLayoutEffect(() => {
    setIsLoading(true);

    // if the users have not yet been loaded don't proceed
    if (getAllUserStatus !== "succeeded") {
      return;
    }

    // Save the query params into a variables
    const dashboardRole = searchParams.get("dashboardRole");
    const dashboardSelectedId = searchParams.get("dashboardSelectedId")
      ? Number(searchParams.get("dashboardSelectedId"))
      : null;

    // if dashboardSelectedId is a valid number then we set the selectedId to that number
    if (typeof dashboardSelectedId === "number") {
      setSelectedId(dashboardSelectedId);
    }

    // If a dashboard role is defined inside of the url and it is a valid role then we want to show that role
    // TODO: Test what happens when a member that is member tries to access the admin dashboard
    if (dashboardRole && isDashboardRole(dashboardRole)) {
      setDashboardRoleShowing(dashboardRole);
      return setIsLoading(false);
    }

    // If the user is an admin then we just show the admin dashboard first and return
    if (isAdmin) {
      setDashboardRoleShowing("Admin");
      setSelectedId(null);
      return setIsLoading(false);
    }

    // If not we check the departments, if the user is a department leader of any department then we set the initial role to department leader
    const firstDepartmentId = getFirstDepartmentId(
      teamAndDepartmentLeaderOf?.departments
    );
    if (firstDepartmentId) {
      setDashboardRoleShowing("Department Leader");
      setSelectedId(firstDepartmentId);
      return setIsLoading(false);
    }

    // If the user is not a department leader or admin then we check if they are a team leader of any team
    const firstTeamId = getFirstTeamId(teamAndDepartmentLeaderOf?.teams);
    if (firstTeamId) {
      setDashboardRoleShowing("Team Leader");
      setSelectedId(null); // Since we are consolidating team member and team leader dashboard we don't need to set the selectedId
      return setIsLoading(false);
    }

    // If the user is not a team leader or department leader then show them the team member role
    setDashboardRoleShowing("Team Member");
    setSelectedId(null);
    setIsLoading(false);
  }, [
    searchParams,
    userAccountId,
    teamAndDepartmentLeaderOf,
    isAdmin,
    getAllUserStatus,
    getFirstDepartmentId,
    getFirstTeamId,
  ]);

  const onRoleSelect = (role: string | null) => {
    if (!isDashboardRole(role)) {
      return;
    }

    setDashboardRoleShowing(role);
    const params: {
      dashboardRole: string;
      dashboardSelectedId?: string;
    } = {
      dashboardRole: role,
    };

    // If we select the role of Department Leader then we need to set the selectedId to the first department the user is a leader of
    if (role === "Department Leader" && userAccountId) {
      const firstDepartmentId = getFirstDepartmentId(
        teamAndDepartmentLeaderOf?.departments ?? []
      );
      if (firstDepartmentId) {
        params.dashboardSelectedId = String(firstDepartmentId);
        setSelectedId(firstDepartmentId);
      } else {
        setSelectedId(null);
      }
    }

    setSearchParams(params);
  };

  const getRoleDropdown = () => {
    const dropdownOptions: ValidDashboardRole[] = [];

    if (isTeamLead) {
      dropdownOptions.unshift("Team Leader");
    } else {
      dropdownOptions.unshift("Team Member");
    }

    if (isDepartmentLead) {
      dropdownOptions.unshift("Department Leader");
    }

    // Temporarily commenting out
    if (isAdmin) {
      dropdownOptions.unshift("Admin");
    }

    // If the user only has one option no need to show the dropdown
    if (dropdownOptions.length === 1 || getAllUserStatus !== "succeeded") {
      return null;
    }

    return (
      <>
        <span>Pick a view by role:</span>
        <Dropdown onSelect={onRoleSelect}>
          <Dropdown.Toggle
            variant="light"
            id="dropdown-basic"
            className="dropdown-menu-240px"
          >
            {dashboardRoleShowing}
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-240px">
            {dropdownOptions.map((option) => (
              <Dropdown.Item eventKey={option} key={option}>
                {option}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  const onIdSelect = (id: string | null) => {
    if (!id) return;
    setSearchParams((searchParams) => {
      searchParams.set("dashboardSelectedId", id);
      return searchParams;
    });
    setSelectedId(parseInt(id));
  };

  const getSpecificTeamOrDepartmentDropdown = () => {
    if (!userAccountId) return null;

    if (dashboardRoleShowing === "Department Leader") {
      return (
        <Dropdown onSelect={onIdSelect}>
          <Dropdown.Toggle
            variant="light"
            id="dropdown-basic"
            className="dropdown-menu-240px"
          >
            {selectedId ? departments[selectedId]?.name : "Select a department"}
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-240px">
            {teamAndDepartmentLeaderOf?.departments?.map((departmentId) =>
              departments[departmentId] ? (
                <Dropdown.Item eventKey={departmentId} key={departmentId}>
                  {departments[departmentId]?.name}
                </Dropdown.Item>
              ) : null
            )}
          </Dropdown.Menu>
        </Dropdown>
      );
    }

    // If the role selected is not team leader or department leader than we don't show any dropdown
    return null;
  };
  const onOpenInviteTeamLeaderModal = useCallback(() => {
    const payload =
      dashboardRoleShowing === "Department Leader" && selectedId
        ? {
            departmentId: selectedId,
          }
        : undefined;

    dispatch(openInviteTeamLeaderModal(payload));
  }, [dispatch, dashboardRoleShowing, selectedId]);

  const dashboardBody = useMemo(() => {
    switch (dashboardRoleShowing) {
      case "Admin":
        return (
          <AdminOnboarding
            onShowInviteMemberModal={() => setShowInviteMemberModal(true)}
            onShowInviteTeamLeaderModal={onOpenInviteTeamLeaderModal}
          />
        );
      case "Department Leader":
        return (
          <DepartmentLeaderOnboarding
            selectedDepartmentId={selectedId}
            userAccountId={userAccountId}
            onShowInviteMemberModal={() => setShowInviteMemberModal(true)}
            onShowInviteTeamLeaderModal={onOpenInviteTeamLeaderModal}
          />
        );
      case "Team Leader":
      case "Team Member":
        return (
          <MemberDashboard
            onShowTeamLeaderOnboardingModal={(departmentId) => {
              setShowTeamLeaderOnboardingModal(true);
              setTeamCreationDefaultDepartment(departmentId ?? null);
            }}
            teamLeaderOnboardingModalBeenShown={
              teamLeaderOnboardingModalBeenShown
            }
            onShowInviteMemberModal={() => setShowInviteMemberModal(true)}
            isLeader={dashboardRoleShowing === "Team Leader"}
          />
        );
    }
  }, [
    dashboardRoleShowing,
    userAccountId,
    selectedId,
    teamLeaderOnboardingModalBeenShown,
    onOpenInviteTeamLeaderModal,
  ]);

  const getInviteMemberModalTitle = () => {
    if (dashboardRoleShowing === "Team Leader" && selectedId) {
      return `Invite Member to ${teamsById[selectedId]?.teamName}`;
    }

    return "Invite Team Member";
  };
  const getQuickActionPopover = (
    title: string,
    bodyText: string,
    onDismiss: () => void
  ) => (
    <Popover
      className="team-360-popover"
      style={{ minWidth: "400px", padding: "16px" }}
    >
      <div className="column-gap-16px">
        <div className="d-flex justify-content-between align-items-center">
          <p>
            <b>{title}</b>
          </p>
          <Button
            xIcon
            variant="secondary-gray"
            className="border-0"
            size="sm"
            onClick={() => onDismiss()}
          />
        </div>
        <p>{bodyText}</p>
        <div>
          <Button variant="secondary-blue" onClick={() => onDismiss()}>
            Dismiss
          </Button>
        </div>
      </div>
    </Popover>
  );

  const getQuickActionButton = () => {
    // We hide the quick action button if the user is just a team member or an admin
    if (
      dashboardRoleShowing === "Team Member" ||
      dashboardRoleShowing === "Admin"
    ) {
      return null;
    }

    const trackingEventName =
      TrackedQuickActionEventsMapByRole[dashboardRoleShowing];

    const isQuickActionPopupShowing =
      !globalTrackingEvents?.[trackingEventName] &&
      getUserTrackingEventsStatus === "succeeded";

    // If the user is a department leader then we don't need to show the popover
    if (dashboardRoleShowing === "Department Leader") {
      return (
        <div>
          <Button
            onClick={() => setShowQuickActions(!showQuickActions)}
            variant="secondary-blue"
          >
            Quick Actions
          </Button>
        </div>
      );
    }

    return (
      <OverlayTrigger
        placement="auto"
        overlay={getQuickActionPopover(
          dashboardRoleShowing === "Team Leader"
            ? "Access quick actions"
            : "Getting Started?",
          getQuickActionPopoverText(),
          () => {
            dispatch(
              postUserOnboardingTrackingForEntireSite({
                eventType: trackingEventName,
              })
            );
          }
        )}
        rootClose={true}
        show={isQuickActionPopupShowing}
      >
        <div>
          <Button onClick={() => setShowQuickActions(!showQuickActions)}>
            Quick Actions
          </Button>
        </div>
      </OverlayTrigger>
    );
  };
  const getQuickActionPopoverText = () => {
    switch (dashboardRoleShowing) {
      case "Team Leader":
        return "Easily launch the TEAMscan, invite team members and create a new team from here.";
      case "Department Leader":
        return "Easily launch the TEAMscan, invite team leaders, create a team, and more from here.";
      case "Admin":
        return "Easily launch the TEAMscan, set up departments, invite team leaders, create a team, and more from here.";
      default:
        return "";
    }
  };

  const getDashboardContent = () => {
    if (getTeamAndDepartmentLeadsStatus === "loading" || isLoading) {
      return <Loading />;
    }

    return (
      <>
        <div
          className="row-gap-32px"
          style={{
            marginTop: "20px",
          }}
        >
          <Link to="/UserGuide" className="no-underline">
            <AvatarCircle
              userAccountId={userAccountId ?? undefined}
              size="extra-large"
            />
          </Link>
          <div className="w-100 column-gap-36px">
            <div
              className="row-gap-20px"
              style={{
                paddingTop: "4px",
                height: "40px",
              }}
            >
              <h1
                style={{
                  color: "white",
                }}
              >
                {name}
              </h1>
              {jobTitle ? (
                <div className={`label-tag blue`}>{jobTitle}</div>
              ) : null}
            </div>
            <div className="dashboard-header-v2">
              <div className="row-gap-12px align-items-center flex-wrap">
                {getRoleDropdown()}
                {getSpecificTeamOrDepartmentDropdown()}
              </div>
              <div className="dashboard-header-actions">
                {getQuickActionButton()}
              </div>
            </div>
          </div>
        </div>
        {showCreateTeamBanner && !userInfo?.teamIds.length ? (
          <div className="dashboard-banner">
            <p>
              <FontAwesomeIcon icon={["far", "lightbulb-on"]} /> Are you a team
              leader? Create your first team now
            </p>
            <div className="dashboard-banner-action-button">
              <Button
                onClick={() => setShowCreateTeamBanner(false)}
                variant="secondary-blue"
              >
                Dismiss
              </Button>
              <Button onClick={() => dispatch(openCreateTeamModal())}>
                Create a Team
              </Button>
            </div>
          </div>
        ) : null}
        <QuickActionDrawer
          onHide={() => setShowQuickActions(false)}
          showing={showQuickActions}
          role={dashboardRoleShowing}
          departmentId={
            dashboardRoleShowing === "Department Leader"
              ? selectedId
              : undefined
          }
          onShowInviteMemberModal={() => setShowInviteMemberModal(true)}
          onShowInviteTeamLeaderModal={onOpenInviteTeamLeaderModal}
        />
        {dashboardBody}
      </>
    );
  };

  const userInfo = userAccountId ? usersById[userAccountId] : null;
  const name = userInfo
    ? `${userInfo.firstName ?? ""} ${userInfo.lastName ?? ""}`
    : "";
  const jobTitle = userInfo?.jobTitle;

  return (
    <>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100px",
          backgroundColor: "#425CC7",
          zIndex: -1,
        }}
      />
      <div className="page dashboard">
        <div className="column-gap-20px">
          {/* Modals */}
          <InviteUsersModal
            modalTitle={getInviteMemberModalTitle()}
            showing={showInviteMemberModal}
            hideModal={() => setShowInviteMemberModal(false)}
            currentTeamInfo={
              dashboardRoleShowing === "Team Leader" && selectedId
                ? teamsById[selectedId]
                : undefined
            }
            teamId={
              dashboardRoleShowing === "Team Leader" && selectedId
                ? selectedId
                : undefined
            }
            teamMembers={Object.values(usersById)}
            inviteLink={
              dashboardRoleShowing === "Team Leader" && selectedId
                ? teamLinksById[selectedId]
                : companyInviteLink
            }
            onInviteSuccess={() => setShowInviteMemberModal(false)}
          />

          <TeamLeaderCreateTeamOnboardingModal
            show={showTeamLeaderOnboardingModal}
            onHide={() => {
              setShowTeamLeaderOnboardingModal(false);
              setTeamLeaderOnboardingModalBeenShown(true);
              setTeamCreationDefaultDepartment(null);
            }}
            userFirstName={userInfo?.firstName ?? ""}
            allowedDomains={getAllowedDomains(companySettings)}
            defaultDepartmentId={teamCreationDefaultDepartment}
          />
          {/* Modal End */}
          {getDashboardContent()}
        </div>
      </div>
    </>
  );
}
