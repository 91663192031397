import SimpleModal from "app/components/Modals/SimpleModal";
import { useAppDispatch } from "utils/redux/hooks";
import { useEffect, useMemo, useState } from "react";
import { getTeamComments } from "app/containers/TeamGuide/slice";
import { TeamComments } from "app/containers/AdminConsole/types";
import FeedbackCard from "../AnonymousFeedback/FeedbackCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDepartmentTeamComments } from "app/containers/DepartmentInsightReport/slice";
import Loading from "app/storybookComponents/Loading";

interface Props {
  onHide: () => void;
  show?: boolean;
  teamId?: number;
  departmentId?: number;
  teamComments?: TeamComments | null;
  snapShotText?: string | null;
  isSnapShotTextLoading?: boolean;
  modalTitle?: string;
}

export default function Team360CommentsByYourTeamModal({
  show,
  onHide,
  teamId,
  teamComments,
  departmentId,
  snapShotText,
  isSnapShotTextLoading,
  modalTitle = "Comments by your team",
}: Props) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!teamId || teamComments) return;
    dispatch(getTeamComments({ teamId }));
  }, [teamId, dispatch, teamComments]);

  useEffect(() => {
    if (!departmentId || teamComments) return;
    dispatch(getDepartmentTeamComments({ departmentId }));
  }, [departmentId, dispatch, teamComments]);

  const commentTotalText = useMemo(() => {
    if (!teamComments || !teamComments["doingWellNeedsImprovement"])
      return null;
    let count = 0;
    Object.values(teamComments["doingWellNeedsImprovement"]).forEach(
      (commentObj) => {
        count += Object.values(commentObj).flat().length;
      }
    );
    return `${count} total comment${count === 1 ? "" : "s"}`;
  }, [teamComments]);

  const getTextSynthesis = () => {
    if (isSnapShotTextLoading) {
      return (
        <div className="snapshot-box">
          <FontAwesomeIcon icon={["fal", "lightbulb-on"]} />
          <Loading />
        </div>
      );
    }

    if (snapShotText) {
      return (
        <div className="snapshot-box">
          <FontAwesomeIcon icon={["fal", "lightbulb-on"]} />
          <p>{snapShotText}</p>
        </div>
      );
    }
    return null;
  };

  const getCommentSection = () => {
    return (
      <>
        <FeedbackCard
          titleStem="Here are the top comments that emerged about what this team is"
          titleEmphasis="doing well"
          feedbackArr={
            teamComments?.doingWellNeedsImprovement?.doingWell?.map(
              (comment) => ({
                feedbackText: comment,
                from: "Team member",
              })
            ) ?? []
          }
        />
        <FeedbackCard
          titleStem="Here are the top comments that emerged about what this team"
          titleEmphasis="could be doing better"
          feedbackArr={
            teamComments?.doingWellNeedsImprovement?.needsImprovement?.map(
              (comment) => ({
                feedbackText: comment,
                from: "Team member",
              })
            ) ?? []
          }
        />
      </>
    );
  };

  return (
    <SimpleModal show={show} onHide={onHide} title={modalTitle}>
      {getTextSynthesis()}
      <div className="d-flex justify-content-between">
        <div className="mt-auto">
          <p
            className="row-gap-8px align-items-center"
            style={{ color: "#53565a" }}
          >
            <FontAwesomeIcon icon={["far", "comment"]} />
            {commentTotalText}
          </p>
        </div>
      </div>
      <div className="column-gap-20px collapsible-card-container">
        {getCommentSection()}
      </div>
    </SimpleModal>
  );
}
