import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";

// ------------------ State Type/Structure ------------------
export interface ModalsState {
  isCreateTeamModalOpen: boolean;
  createTeamModalPreSelectedDepartmentId: number | null;
  redirectUponTeamCreation: boolean; // It might be worth to create a whole separate slice just for modals, if we have more than one modal persisting across pages.
  isFromAdminConsole: boolean;
  createdTeamId: number | null;
  editDepartmentModalOpen: boolean;
  editingDepartmentId: number | null;
  openTeamCreationAfterDepartmentCreation?: boolean | number | null;
  isInviteTeamLeaderModalOpen: boolean;
  inviteTeamLeaderModalTeamId: number | null;
  inviteTeamLeaderModalDepartmentId: number | null;
}

// ------------------ InitialState ------------------
const initialState: ModalsState = {
  isCreateTeamModalOpen: false,
  createTeamModalPreSelectedDepartmentId: null,
  redirectUponTeamCreation: false,
  isFromAdminConsole: false,
  createdTeamId: null,
  editDepartmentModalOpen: false,
  editingDepartmentId: null,
  openTeamCreationAfterDepartmentCreation: false,
  isInviteTeamLeaderModalOpen: false,
  inviteTeamLeaderModalTeamId: null,
  inviteTeamLeaderModalDepartmentId: null,
};

export const onSuccessfulDepartmentCreation = createAsyncThunk(
  "modals/onSuccessfulDepartmentCreation",
  async (departmentId: number | undefined, { getState, dispatch }) => {
    const {
      modals: { openTeamCreationAfterDepartmentCreation },
    } = getState() as RootState;

    if (openTeamCreationAfterDepartmentCreation) {
      dispatch(closeEditDepartmentModal()); // Only closing the modal when we redirect to thr team creation modal, because we will be showing the success screen inside of the modal.
      dispatch(openCreateTeamModal({ preSelectedDepartmentId: departmentId }));
    }
  }
);

// ------------------ Beginning of Slice Definition ------------------
export const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    openCreateTeamModal(
      state,
      {
        payload,
      }: PayloadAction<
        | {
            redirectUponTeamCreation?: boolean;
            preSelectedDepartmentId?: number | null;
            isFromAdminConsole?: boolean;
          }
        | undefined
      >
    ) {
      return {
        ...state,
        redirectUponTeamCreation: !!payload?.redirectUponTeamCreation,
        isCreateTeamModalOpen: true,
        createTeamModalPreSelectedDepartmentId:
          payload?.preSelectedDepartmentId || null,
        isFromAdminConsole: !!payload?.isFromAdminConsole,
      };
    },
    closeCreateTeamModal(state) {
      state.redirectUponTeamCreation = false;
      state.isCreateTeamModalOpen = false;
      state.createdTeamId = null;
      state.createTeamModalPreSelectedDepartmentId = null;
    },
    setCreatedTeamId(state, { payload }: PayloadAction<number | null>) {
      state.createdTeamId = payload;
    },
    openEditDepartmentModal(
      state,
      {
        payload,
      }: PayloadAction<
        | {
            departmentId?: number;
            openTeamCreationAfterDepartmentCreation?: boolean | number;
          }
        | undefined
      >
    ) {
      state.editDepartmentModalOpen = true;
      state.editingDepartmentId = payload?.departmentId || null;
      state.openTeamCreationAfterDepartmentCreation =
        payload?.openTeamCreationAfterDepartmentCreation;
    },
    closeEditDepartmentModal(state) {
      state.editDepartmentModalOpen = false;
      state.editingDepartmentId = null;
      state.openTeamCreationAfterDepartmentCreation = false;
    },
    openInviteTeamLeaderModal(
      state,
      {
        payload,
      }: PayloadAction<
        | {
            teamId?: number | null;
            departmentId?: number | null;
          }
        | undefined
      >
    ) {
      state.isInviteTeamLeaderModalOpen = true;
      state.inviteTeamLeaderModalTeamId = payload?.teamId || null;
      state.inviteTeamLeaderModalDepartmentId = payload?.departmentId || null;
    },
    closeInviteTeamLeaderModal(state) {
      state.isInviteTeamLeaderModalOpen = false;
      state.inviteTeamLeaderModalTeamId = null;
      state.inviteTeamLeaderModalDepartmentId = null;
    },
  },
  extraReducers: (builder) => {},
});

// ------------------ Selectors ------------------
export const selectCreateTeamModalIsOpen = (state: RootState) =>
  state.modals.isCreateTeamModalOpen;
export const selectCreateTeamModalPreSelectedDepartmentId = (
  state: RootState
) => state.modals.createTeamModalPreSelectedDepartmentId;
export const selectRedirectAfterCreatingTeam = (state: RootState) =>
  state.modals.redirectUponTeamCreation;
export const selectCreatedTeamId = (state: RootState) =>
  state.modals.createdTeamId;
export const selectEditDepartmentModalIsOpen = (state: RootState) =>
  state.modals.editDepartmentModalOpen;
export const selectEditingDepartmentId = (state: RootState) =>
  state.modals.editingDepartmentId;
export const selectIsModalComingFromAdminConsole = (state: RootState) =>
  state.modals.isFromAdminConsole;
export const selectInviteTeamLeaderModalIsOpen = (state: RootState) =>
  state.modals.isInviteTeamLeaderModalOpen;
export const selectInviteTeamLeaderModalTeamId = (state: RootState) =>
  state.modals.inviteTeamLeaderModalTeamId;
export const selectInviteTeamLeaderModalDepartmentId = (state: RootState) =>
  state.modals.inviteTeamLeaderModalDepartmentId;

export const {
  openCreateTeamModal,
  closeCreateTeamModal,
  setCreatedTeamId,
  closeEditDepartmentModal,
  openEditDepartmentModal,
  openInviteTeamLeaderModal,
  closeInviteTeamLeaderModal,
} = modalsSlice.actions;

export default modalsSlice.reducer;
