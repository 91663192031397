import { Card } from 'react-bootstrap';
import NoResultsIcon from 'resources/images/no-results.jpg';

interface Props {
  name: string;
  type?: 'user' | 'team' | 'company';
}
export default function NoGuideYet({ type = 'user', name }: Props) {
  return (
    <Card className="not-available-card">
      <img src={NoResultsIcon} alt="no results" height="80px" width="80px" />
      <p>
        {name} has not completed the {type} guide yet
      </p>
    </Card>
  );
}
