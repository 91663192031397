import { Card, Dropdown } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  getOpenAIModules,
  selectOpenAIModules,
  selectGettingModules,
} from "./slice";
import { ReactElement, useEffect, useState } from "react";
import OpenAIModule from "../OpenAIModule";
import { resetGeneratedText } from "../OpenAIModule/slice";

export default function OpenAIModules() {
  const dispatch = useAppDispatch();
  const openAIModules = useAppSelector(selectOpenAIModules);
  const gettingModulesStatus = useAppSelector(selectGettingModules);
  const [selectedModuleId, setSelectedModuleId] = useState<number | null>(null);

  useEffect(() => {
    dispatch(getOpenAIModules());
  }, [dispatch]);

  const onDropdownSelect = (eventKey: any) => {
    setSelectedModuleId(eventKey);
    dispatch(resetGeneratedText());
  };

  const selectedModule = openAIModules?.modules.find(
    (module) => module.id === selectedModuleId
  );

  const getDropdown = () => {
    switch (gettingModulesStatus) {
      case "loading":
        return <p>Loading...</p>;
      case "failed":
        return <p>Failed to get Module Info</p>;
      default:
        if (openAIModules) {
          const modules: ReactElement[] = [];
          openAIModules.modules.forEach((module) => {
            modules.push(
              <Dropdown.Item eventKey={module.id}>{module.title}</Dropdown.Item>
            );
          });
          return (
            <Dropdown onSelect={onDropdownSelect}>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {selectedModule ? selectedModule.title : "Select Module"}
              </Dropdown.Toggle>
              <Dropdown.Menu id="user-settings-menu">{modules}</Dropdown.Menu>
            </Dropdown>
          );
        }
    }
  };
  const getAIModule = () => {
    if (selectedModule) {
      return (
        <OpenAIModule
          promptId={selectedModule.promptId}
          title={selectedModule.title}
          userInput={selectedModule.freeTextInputs}
        />
      );
    }
    return selectedModuleId;
  };

  return (
    <Card className="ai-module">
      {getDropdown()}
      {getAIModule()}
    </Card>
  );
}
