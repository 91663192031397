import AvatarCircle from "app/components/AvatarCircle";
import Button from "app/storybookComponents/Button";
import { Card } from "react-bootstrap";
import {
  selectAllCompanyUsersById,
  selectSampleUsersInfoById,
} from "app/containers/Global/slice";
import { useAppSelector } from "utils/redux/hooks";

interface Props {
  userAccountId: number;
  onUserGuideClick: () => void;
  isMemberPending?: boolean;
  pendingMessage?: string;
}

// If the user has been mar
export default function TeamMemberCardSmall({
  userAccountId,
  onUserGuideClick,
  isMemberPending,
  pendingMessage = "Pending",
}: Props) {
  const sampleUsersById = useAppSelector(selectSampleUsersInfoById);
  const companyUsersById = useAppSelector(selectAllCompanyUsersById);
  const usersInfoById = { ...sampleUsersById, ...companyUsersById };

  const userInformation = usersInfoById[userAccountId] || {};
  const {
    firstName = "",
    lastName = "",
    jobTitle,
    profilePicture,
    emailAddress,
    firstTeamsLogin: firstTimeLogin,
  } = userInformation;

  const fullName =
    firstName || lastName ? `${firstName} ${lastName}` : emailAddress;

  const hasLoggedInForTheFirstTime = !!(
    firstTimeLogin ||
    firstName ||
    lastName ||
    jobTitle
  );

  const getIsUserPending = () => {
    // if the isMemberPending prop is passed in, use that
    if (isMemberPending !== undefined) {
      return isMemberPending;
    }

    // otherwise check if the firstTimeLogin is true
    return !hasLoggedInForTheFirstTime;
  };

  const getPendingMessage = () => {
    if (!hasLoggedInForTheFirstTime) {
      return "Pending user";
    }

    return pendingMessage;
  };

  const isUserPending = getIsUserPending();
  return (
    <Card className="single-search-card">
      <div
        style={{
          opacity: isMemberPending ? 0.5 : 1,
        }}
      >
        <AvatarCircle
          name={fullName}
          profilePicture={profilePicture}
          userAccountId={userAccountId}
          avatarColor={!hasLoggedInForTheFirstTime ? "53565A" : undefined}
          size="large"
        />
      </div>
      <h3
        style={{
          color: isUserPending ? "#53565A" : undefined,
        }}
      >
        {fullName}
      </h3>
      {jobTitle ? (
        <div
          className="job-title"
          style={{
            marginBottom: "16px",
          }}
        >
          {jobTitle}
        </div>
      ) : null}
      <div className="column-gap-16px mt-auto">
        {isUserPending ? (
          <span className={`pending-tag`}>{getPendingMessage()}</span>
        ) : null}
        {/* Only when the user has not been logged into the org for the first time is when we disable the button */}
        <Button
          onClick={() => {
            if (!hasLoggedInForTheFirstTime) {
              return;
            }
            onUserGuideClick();
          }}
          variant="secondary-blue"
          disabled={!hasLoggedInForTheFirstTime}
        >
          View User Guide
        </Button>
      </div>
    </Card>
  );
}
