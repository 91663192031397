import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "utils/redux/store";
import {
  showScheduleAssessmentModalForTeamId,
  showScheduleAssessmentModal,
} from "app/components/LaunchAssessmentModal/slice";
import {
  OnboardingModalType,
  TrackingObject,
  ValidTrackingEvent,
  isValidTrackingEvent,
} from "./types";
import { request } from "utils/request";
import { responseStatus } from "utils/types";
import { REACT_APP_API_URL } from "utils/environmentVariables";

// ------------------ State Type/Structure ------------------
export interface OnboardingState {
  showModal: null | OnboardingModalType;
  hideActionButton: boolean;
  teamId: null | number;
  trackingEventsByTeamId: {
    // This prop will be used to track which events have already been shown to the user for a specific team
    [teamId: number]: {
      team360LeadViewNotScheduledYet?: boolean;
      visitedTeam360TabWithoutResults?: boolean;
      invitedTeamMember?: boolean;
    };
  };
  globalTrackingEvents: {
    visitedTeam360TabWithResults?: boolean;
    adminQuickActionClosed?: boolean;
    departmentLeaderQuickActionClosed?: boolean;
    teamLeaderQuickActionClosed?: boolean;
  };
  temporarilyHideModal: boolean;
  getUserOnboardingTrackingStatus: responseStatus;
  postUserOnboardingTrackingStatus: responseStatus;
}

// ------------------ InitialState ------------------
const initialState: OnboardingState = {
  showModal: null,
  teamId: null,
  hideActionButton: false,
  temporarilyHideModal: false, // this state is going to be used to allow the user to back into a previously opened modal
  trackingEventsByTeamId: {},
  globalTrackingEvents: {},
  getUserOnboardingTrackingStatus: "idle",
  postUserOnboardingTrackingStatus: "idle",
};

export const onSubmitModalClick = (): AppThunk => (dispatch, getState) => {
  const {
    onBoarding: { showModal, teamId },
  } = getState();
  // When viewing the modal "team360LeadViewNotScheduledYet", when the user clicks on the dismissButton
  if (showModal === "team360LeadViewNotScheduledYet") {
    if (teamId !== null) {
      dispatch(showScheduleAssessmentModalForTeamId(teamId));
    } else {
      dispatch(showScheduleAssessmentModal());
    }
  }

  // Regardless of which modal was showing upon clicking the submit we close the modal.
  dispatch(setHideModal());
};

export const getUserOnboardingTracking = createAsyncThunk(
  "onBoarding/getUserOnboardingTracking",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/userTracking`;
    const getUserOnboardingTrackingResponse = (await request(
      requestUrl
    )) as TrackingObject;
    return getUserOnboardingTrackingResponse;
  }
);

export const onTrackUserEvent = createAsyncThunk(
  "onBoarding/onTrackUserEvent",
  async (
    payload: { event: ValidTrackingEvent; teamId?: number },
    { getState }
  ) => {
    const {
      onBoarding: { trackingEventsByTeamId, globalTrackingEvents },
    } = getState() as RootState;

    // If the event is already in the trackingEventsByTeamId or globalTrackingEvents we don't need to make a request
    if (
      (payload.event in globalTrackingEvents &&
        globalTrackingEvents[
          payload.event as keyof typeof globalTrackingEvents
        ]) ||
      (payload.teamId &&
        payload.event in trackingEventsByTeamId[payload.teamId] &&
        trackingEventsByTeamId[payload.teamId][
          payload.event as keyof (typeof trackingEventsByTeamId)[number]
        ])
    ) {
      return payload;
    }

    const requestUrl = `${REACT_APP_API_URL}/talentInsights/userTracking`;
    await request(requestUrl, {
      method: "POST",
      body: JSON.stringify(payload),
    });
    return payload;
  }
);

// This function will be strictly used to show the modal one time per team, if the modal has already been shown for that team it will not be shown again
// NOTE: If you need to open the modal again for whatever reason use the setShowModal action
// OR if you want to set the tracking only without opening the modal use the onTrackUserEvent action
export const showOnboardingModalForTeamId = createAsyncThunk(
  "onBoarding/showOnboardingModalForTeamId",
  async (
    payload: {
      eventType:
        | "team360LeadViewNotScheduledYet"
        | "visitedTeam360TabWithoutResults";
      teamId: number;
    },
    { getState, rejectWithValue, dispatch }
  ) => {
    const {
      onBoarding: { trackingEventsByTeamId },
    } = getState() as RootState;
    // Dispatching this action before the request is made to ensure that the modal is closed before the request is made
    dispatch(setInitialShowModalPerTeamId(payload));

    if (trackingEventsByTeamId[payload.teamId]?.[payload.eventType]) {
      return rejectWithValue("event already tracked");
    }

    if (!isValidTrackingEvent(payload.eventType)) {
      return rejectWithValue("no endpoint call needed");
    }

    const requestUrl = `${REACT_APP_API_URL}/talentInsights/userTracking`;
    await request(requestUrl, {
      method: "POST",
      body: JSON.stringify({
        event: payload.eventType,
        teamId: payload.teamId,
      }),
    });
  }
);

export const postUserOnboardingTrackingForEntireSite = createAsyncThunk(
  "onBoarding/postUserOnboardingTrackingForEntireSite",
  async (
    payload: {
      eventType:
        | "visitedTeam360TabWithResults"
        | "adminQuickActionClosed"
        | "departmentLeaderQuickActionClosed"
        | "teamLeaderQuickActionClosed";
    },
    { getState, rejectWithValue, dispatch }
  ) => {
    const {
      onBoarding: { globalTrackingEvents },
    } = getState() as RootState;

    // Dispatching this action before the request is made to ensure that the modal is closed before the request is made
    dispatch(setInitialShowModalSiteWide(payload));

    if (globalTrackingEvents[payload.eventType]) {
      return rejectWithValue("event already tracked");
    }

    if (!isValidTrackingEvent(payload.eventType)) {
      return rejectWithValue("no endpoint call needed");
    }

    const requestUrl = `${REACT_APP_API_URL}/talentInsights/userTracking`;
    await request(requestUrl, {
      method: "POST",
      body: JSON.stringify({
        event: payload.eventType,
      }),
    });
  }
);

// ------------------ Beginning of Slice Definition ------------------
export const exampleSlice = createSlice({
  name: "onBoarding",
  initialState,
  reducers: {
    // ------------------ Reducers ------------------
    setInitialShowModalSiteWide: (
      state,
      {
        payload,
      }: PayloadAction<{
        eventType:
          | "visitedTeam360TabWithResults"
          | "adminQuickActionClosed"
          | "departmentLeaderQuickActionClosed"
          | "teamLeaderQuickActionClosed";
      }>
    ) => {
      // Before opening the modal we we check if the modal for that team has already been opened, if so no need to open it again... if you need to open again for whatever reason use the setShowModal action
      if (state.globalTrackingEvents?.[payload.eventType]) {
        return state;
      }
      const showModal =
        payload.eventType === "visitedTeam360TabWithResults"
          ? payload.eventType
          : state.showModal;
      return {
        ...state,
        showModal,
        globalTrackingEvents: {
          ...state.globalTrackingEvents,
          [payload.eventType]: true,
        },
      };
    },
    setInitialShowModalPerTeamId: (
      state,
      {
        payload: { eventType, teamId },
      }: PayloadAction<{
        eventType:
          | "team360LeadViewNotScheduledYet"
          | "visitedTeam360TabWithoutResults";
        teamId: number;
      }>
    ) => {
      // Before opening the modal we we check if the modal for that team has already been opened, if so no need to open it again... if you need to open again for whatever reason use the setShowModal action
      if (state.trackingEventsByTeamId?.[teamId]?.[eventType]) {
        return state;
      }

      return {
        ...state,
        teamId,
        showModal: eventType,
        trackingEventsByTeamId: {
          ...state.trackingEventsByTeamId,
          [teamId]: {
            ...state.trackingEventsByTeamId[teamId],
            [eventType]: true,
          },
        },
      };
    },
    setShowModal: (
      state,
      {
        payload: { eventType, hideActionButton },
      }: PayloadAction<{
        eventType: OnboardingModalType;
        hideActionButton?: boolean;
      }>
    ) => {
      state.showModal = eventType;
      state.hideActionButton = !!hideActionButton;
    },
    setHideModal: (state) => {
      state.showModal = initialState.showModal;
      state.hideActionButton = initialState.hideActionButton;
    },
    setHideActionButton: (state, action: PayloadAction<boolean>) => {
      state.hideActionButton = action.payload;
    },
    setTemporarilyHideOnboardingModal: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.temporarilyHideModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserOnboardingTracking.pending, (state) => {
        state.getUserOnboardingTrackingStatus = "loading";
      })
      .addCase(
        getUserOnboardingTracking.fulfilled,
        (
          state,
          {
            payload: { teamEvents, siteWideEvents },
          }: PayloadAction<TrackingObject>
        ) => {
          return {
            ...state,
            getUserOnboardingTrackingStatus: "succeeded",
            trackingEventsByTeamId: {
              ...state.trackingEventsByTeamId,
              ...teamEvents,
            },
            globalTrackingEvents: {
              ...state.globalTrackingEvents,
              ...siteWideEvents,
            },
          };
        }
      )
      .addCase(getUserOnboardingTracking.rejected, (state) => {
        state.getUserOnboardingTrackingStatus = "failed";
      })
      .addCase(showOnboardingModalForTeamId.pending, (state) => {
        state.postUserOnboardingTrackingStatus = "loading";
      })
      .addCase(showOnboardingModalForTeamId.fulfilled, (state) => {
        state.postUserOnboardingTrackingStatus = "succeeded";
      })
      .addCase(showOnboardingModalForTeamId.rejected, (state, action) => {
        state.postUserOnboardingTrackingStatus =
          action.payload === "event already tracked" ||
          action.payload === "no endpoint call needed"
            ? "idle"
            : "failed";
      })
      .addCase(postUserOnboardingTrackingForEntireSite.pending, (state) => {
        state.postUserOnboardingTrackingStatus = "loading";
      })
      .addCase(postUserOnboardingTrackingForEntireSite.fulfilled, (state) => {
        state.postUserOnboardingTrackingStatus = "succeeded";
      })
      .addCase(
        postUserOnboardingTrackingForEntireSite.rejected,
        (state, action) => {
          state.postUserOnboardingTrackingStatus =
            action.payload === "event already tracked" ||
            action.payload === "no endpoint call needed"
              ? "idle"
              : "failed";
        }
      )
      .addCase(onTrackUserEvent.pending, (state) => {
        state.postUserOnboardingTrackingStatus = "loading";
      })
      .addCase(
        onTrackUserEvent.fulfilled,
        (
          state,
          payload: PayloadAction<{ event: ValidTrackingEvent; teamId?: number }>
        ) => {
          state.postUserOnboardingTrackingStatus = "succeeded";
          if (payload.payload.teamId) {
            state.trackingEventsByTeamId[payload.payload.teamId] = {
              ...state.trackingEventsByTeamId[payload.payload.teamId],
              [payload.payload.event]: true,
            };
          } else {
            state.globalTrackingEvents = {
              ...state.globalTrackingEvents,
              [payload.payload.event]: true,
            };
          }
        }
      )
      .addCase(onTrackUserEvent.rejected, (state) => {
        state.postUserOnboardingTrackingStatus = "failed";
      });
  },
});

// ------------------ Selectors ------------------
export const selectShowModal = (state: RootState) => state.onBoarding.showModal;
export const selectTrackingEventsByTeamId = (state: RootState) =>
  state.onBoarding.trackingEventsByTeamId;
export const selectHideActionButton = (state: RootState) =>
  state.onBoarding.hideActionButton;
export const selectGlobalTrackingEvents = (state: RootState) =>
  state.onBoarding.globalTrackingEvents;
export const selectGetUserOnboardingTrackingStatus = (state: RootState) =>
  state.onBoarding.getUserOnboardingTrackingStatus;
export const selectIsOnboardingModalTemporarilyHidden = (state: RootState) =>
  state.onBoarding.temporarilyHideModal;

export const {
  setInitialShowModalPerTeamId,
  setInitialShowModalSiteWide,
  setShowModal,
  setHideModal,
  setHideActionButton,
  setTemporarilyHideOnboardingModal,
} = exampleSlice.actions;

export default exampleSlice.reducer;
