import { Modal as BootstrapModal } from 'react-bootstrap';
import { useAppSelector, useAppDispatch } from 'utils/redux/hooks';
import { hideModal, selectModalToShow } from '../slice';
import EditModuleModal from './EditModuleModal';
import AddModuleModal from './AddModuleModal';
import WelcomeModal from './WelcomeModal';

export default function ModuleModal() {
  const dispatch = useAppDispatch();
  const modalToShow = useAppSelector(selectModalToShow);

  const getContent = () => {
    switch (modalToShow) {
      case 'welcome':
        return <WelcomeModal />;
      case 'add-module':
        return <AddModuleModal />;
      case 'edit-module':
        return <EditModuleModal />;
      default:
        return null;
    }
  };

  return (
    <BootstrapModal
      size={'lg'}
      onHide={() => dispatch(hideModal())}
      show={modalToShow !== null}
      className={`${modalToShow}-modal`}
    >
      {getContent()}
    </BootstrapModal>
  );
}
