import {
  ReactElement,
  useEffect,
  useState,
  useMemo,
  useLayoutEffect,
} from "react";
import {
  showAddModuleModal,
  showEditModuleModal,
} from "app/components/Modules/slice";
import {
  selectCurrentUserAccountId,
  selectDeleteTeamStatus,
  deleteTeam,
  updateTeam,
  selectSampleUsersInfoById,
  selectAllCompanyUsersById,
  selectSampleTeamsByTeamId,
  getTeamByTeamId,
  selectInvitedCount,
  selectIsCurrentUserAdmin,
  clearInviteCount,
  selectTeamsByTeamId,
} from "app/containers/Global/slice";
import {
  selectCompanySettings,
  selectDepartments,
} from "app/containers/AdminConsole/slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { Section as SectionType } from "app/components/Modules/types";
import { Card, Dropdown } from "react-bootstrap";
import ModulesModal from "app/components/Modules/Modals/ModuleModal";
import { useAppSelector, useAppDispatch } from "utils/redux/hooks";
import Section from "app/components/Modules/Section";
import Button from "app/storybookComponents/Button";
import Header from "app/storybookComponents/GuideHeaderCard";
import BuildYourUserGuideCard from "app/components/BuildYourGuideCard";
import NoGuideYet from "app/components/GuideComponents/NoGuideYet";
import FullPageTeamPersonalityReport from "app/components/WorkplaceInsightsReport/FullPageTeamPersonalityReport";
import { selectCompanyGuide } from "app/containers/CompanyGuide/slice";
import AvatarCircle from "app/components/AvatarCircle";
import WarningModal from "app/storybookComponents/Modals/WarningModal";
import { Team, TeamMember } from "app/containers/Global/types";
import UploadPictureModal from "app/components/ImageEditor/UploadPictureModal";
import Loading from "app/storybookComponents/Loading";
import Team360ResultsModal from "app/components/Team360Assessment/Team360ResultsModal/Team360ResultsModal";
import {
  TTeam360Factor,
  isTeam360Factor,
} from "app/components/Team360Assessment/types";
import TeamPersonality from "app/components/WorkplaceInsightsReport/TeamPersonality";
import NavigateBackButton from "app/components/NavigateBackButton";
import { getDepartmentLeadId } from "app/containers/AdminConsole/helpers";
import TeamSettings from "./TeamSettings/TeamSettings";
import TeamFullPageCard from "./TeamFullPageCard";
import InviteMemberModal from "./Modals/InviteMemberModal";
import EditInformationModal from "./Modals/EditInformationModal";
import { TEAM_TABS } from "./constants";
import { TeamGuideTabs, TeamGuideView, isTeamGuideTabs } from "./types";
import {
  getTeamSkillFocus,
  selectGetSkillsGuideStatus,
  getSkillGuide,
} from "app/containers/SkillsGuide/slice";
import { getModuleOrder } from "./helpers";
import Team360FullPage from "./Team360FullPage";
import {
  getModuleTemplates,
  selectTeamModuleTemplates,
  getTeamGuide,
  selectTeamGuide,
  selectUsedTeamTemplateIds,
  deleteTeamModuleById,
  deleteSectionById,
  selectDeletingStatus,
  deleteGuidePhoto,
  updateGuidePhoto,
  selectUpdatingGuidePhotoStatus,
  selectDeletingGuidePhotoStatus,
  selectGetTeamGuideStatus,
  getTeamAnalyticsText,
  selectTeamAnalyticsText,
  selectGetTeamAnalyticsTextStatus,
  getTeamDisplayStatusByTeamId,
  selectTeam360StatusByTeamId,
  selectTipsAndInterpretiveTextByTeamId,
  getTipsAndInterpretiveText,
  getIndividualTeam360AssessmentResults,
  selectUserTeam360AssessmentResultsByTeamId,
  selectAllTeamAnalyticsText,
  selectGetTeam360StatusByTeamIdStatus,
} from "./slice";
import Team360ScoreBreakdownModal from "app/components/Team360Assessment/Team360ScoreBreakdownModal";
import { HIGH_AND_LOW_FACTOR_TEXT } from "app/components/Team360Assessment/constants";
import SmallTeam360PreviewCard from "app/components/Team360Assessment/SmallTeam360PreviewCard";
import { getTeam360Score } from "app/components/Team360Assessment/helpers";
import {
  getTeam360Scores,
  selectAllTeam360Results,
  selectGettingAllTeam360AssessmentResultsStatus,
} from "app/components/SurveyDataInstances/slice";
import Module from "app/components/Modules/Module";
import { openInviteTeamLeaderModal } from "app/components/Modals/slice";

export default function TeamGuide() {
  const urlParams = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const teamId = Number(urlParams.teamId);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // ------------ App Selectors ---------------
  const sampleUsersById = useAppSelector(selectSampleUsersInfoById);
  const companyUsersById = useAppSelector(selectAllCompanyUsersById);
  const filteredUsersInfoById = useAppSelector(selectAllCompanyUsersById);
  const currentUserAccountId = useAppSelector(selectCurrentUserAccountId);
  const moduleTemplates = useAppSelector(selectTeamModuleTemplates);
  const allTeamAnalyticText = useAppSelector(selectAllTeamAnalyticsText);
  const updatingGuidePhotoStatus = useAppSelector(
    selectUpdatingGuidePhotoStatus
  );
  const deletingGuidePhotoStatus = useAppSelector(
    selectDeletingGuidePhotoStatus
  );
  const departments = useAppSelector(selectDepartments);
  const teamGuideById = useAppSelector(selectTeamGuide);
  const getTeamGuideStatus = useAppSelector(selectGetTeamGuideStatus);
  const inviteCount = useAppSelector(selectInvitedCount);
  const sampleTeamsById = useAppSelector(selectSampleTeamsByTeamId);
  const allTeamsById = useAppSelector(selectTeamsByTeamId);
  const teamUsedModulesById = useAppSelector(selectUsedTeamTemplateIds);
  const deleteTeamStatus = useAppSelector(selectDeleteTeamStatus);
  const deletingStatus = useAppSelector(selectDeletingStatus);
  const getTeam360Status = useAppSelector(selectGetTeam360StatusByTeamIdStatus);
  const allTeam360Scores = useAppSelector(selectAllTeam360Results());
  const getAllTeam360ScoresStatus = useAppSelector(
    selectGettingAllTeam360AssessmentResultsStatus
  );
  const getSkillsGuideStatus = useAppSelector(selectGetSkillsGuideStatus);
  const team360AnalyticsText = useAppSelector(selectTeamAnalyticsText(teamId));
  const team360AnalyticsTextStatus = useAppSelector(
    selectGetTeamAnalyticsTextStatus
  );
  const companySettings = useAppSelector(selectCompanySettings);
  const isAdmin = useAppSelector(selectIsCurrentUserAdmin);
  const currentCompanyGuide = useAppSelector(selectCompanyGuide);
  const displayStatus = useAppSelector(selectTeam360StatusByTeamId(teamId));
  const tipsAndInterpretiveText = useAppSelector(
    selectTipsAndInterpretiveTextByTeamId(teamId)
  );
  const userTeam360Results = useAppSelector(
    selectUserTeam360AssessmentResultsByTeamId(teamId)
  );
  const usersInfoById = { ...sampleUsersById, ...companyUsersById };

  // ---------------- States ----------------
  const [activeHeaderTab, setActiveHeaderTab] =
    useState<TeamGuideTabs>("About");
  const [view, setView] = useState<TeamGuideView>("guide");
  const [lowOrHighFactorClicked, setLowOrHighFactorClicked] = useState<
    keyof typeof HIGH_AND_LOW_FACTOR_TEXT | null
  >("high");
  const [sections, setSections] = useState<{
    remainingSections: SectionType[];
    connectSection: SectionType | null;
  }>({ remainingSections: [], connectSection: null });

  // ---------------------------- Modal States ----------------------------------
  const [deleting, setDeleting] = useState<null | {
    type: "Module" | "Section";
    id: number | string;
    moduleType?: string;
    moduleTemplateId?: number;
  }>(null);
  const [showScoreBreakdownModal, setShowScoreBreakdownModal] = useState(false);
  const [showTeam360ResultsModal, setShowTeam360ResultsModal] =
    useState<null | TTeam360Factor>(null);
  const [informationModalShowing, setInformationModalShowing] =
    useState<boolean>(false);
  const [showInviteMemberModal, setShowInviteMemberModal] =
    useState<boolean>(false);
  const [photoModalShowing, setPhotoModalShowing] = useState<
    "profilePicture" | "coverPhoto" | null
  >(null);

  // ------------------------ Memo Hooks --------------------------------------------
  const teamInfoById = useMemo(
    () => ({ ...sampleTeamsById, ...allTeamsById }),
    [sampleTeamsById, allTeamsById]
  );

  const hasEditAccess = useMemo(() => {
    return !!(
      currentUserAccountId &&
      teamInfoById?.[teamId]?.teamLeadUserAccountIds?.includes(
        currentUserAccountId
      )
    );
  }, [teamInfoById, teamId, currentUserAccountId]);

  const userBelongsToTeam = useMemo(() => {
    return !!(
      currentUserAccountId &&
      teamInfoById?.[teamId]?.teamMemberIds?.includes(currentUserAccountId)
    );
  }, [teamInfoById, teamId, currentUserAccountId]);

  const isUserAdminOrDepartmentLead = useMemo(() => {
    if (isAdmin) return true;
    const currentTeamInfo = teamInfoById?.[teamId];
    return (
      getDepartmentLeadId(currentTeamInfo?.departmentId, departments) ===
      currentUserAccountId
    );
  }, [teamInfoById, teamId, currentUserAccountId, departments, isAdmin]);

  // ----------------------------------- API call hooks ------------------------------------------
  useEffect(() => {
    if (getAllTeam360ScoresStatus === "idle") {
      dispatch(getTeam360Scores());
    }
  }, [dispatch, getAllTeam360ScoresStatus]);

  // This hook calls the getTeamGuide when the teamId changes.
  useEffect(() => {
    // If there is no teamId or the teamId exists in the teamGuideById map no need to fetch for the data again.
    if (!teamId || teamGuideById[teamId]) {
      return;
    }

    dispatch(getTeamGuide(teamId));
  }, [teamId, dispatch, teamGuideById]);

  useEffect(() => {
    // If there is no teamId stop here.
    if (!teamId) {
      return;
    }

    dispatch(getTeamByTeamId(teamId));
  }, [teamId, dispatch]);

  // This hook fetches for the getTeamGuideStatus if it is idle. To prevent multiple calls.
  useEffect(() => {
    dispatch(getModuleTemplates());
  }, [dispatch]);

  // This hook changes the view of the component we are on based on the url path.
  useEffect(() => {
    const path = location.pathname;
    // If the user is an admin and the user when directly to TeamSettings then they should be redirected to the settings tab.
    const lastPath = path.split("/").pop();
    if (
      lastPath === "TeamSettings" &&
      (isUserAdminOrDepartmentLead || hasEditAccess)
    ) {
      setView("settings");
    } else if (lastPath === "TeamPersonality") {
      setView("full-team-personality-report");
    } else if (lastPath === "TeamMembers") {
      setView("team-members");
    } else {
      setView("guide");
    }
  }, [location.pathname, isUserAdminOrDepartmentLead, hasEditAccess]);

  // This hook sets the active tab based on the url search params.
  useLayoutEffect(() => {
    const tab = searchParams.get("tab");
    if (tab && isTeamGuideTabs(tab)) {
      setActiveHeaderTab(tab);
    } else {
      // if no tab is set or the tab is not a valid tab then we set the tab to the about tab.
      setActiveHeaderTab("About");
    }
  }, [searchParams, teamId, dispatch]);

  // This hook opens the modals if the url search params are set to true.
  useLayoutEffect(() => {
    // -------showScoreBreakdownModal-------
    const showScoreBreakdownModalBool = searchParams.get(
      "showScoreBreakdownModal"
    );
    setShowScoreBreakdownModal(showScoreBreakdownModalBool === "true");

    // -------showTeam360ResultsModal-------
    const showTeam360ResultsModalBool = searchParams.get(
      "showTeam360ResultsModal"
    );
    if (
      showTeam360ResultsModalBool &&
      isTeam360Factor(showTeam360ResultsModalBool)
    ) {
      setShowTeam360ResultsModal(showTeam360ResultsModalBool);
    } else {
      setShowTeam360ResultsModal(null);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!teamId || allTeamAnalyticText[teamId]) return;
    dispatch(getTeamAnalyticsText({ teamId }));
  }, [allTeamAnalyticText, teamId, dispatch]);

  useEffect(() => {
    if (!teamId || displayStatus) return;
    dispatch(getTeamDisplayStatusByTeamId({ teamId })); // Will need to add a check to see if the data already exists in the store.
  }, [teamId, dispatch, displayStatus]);

  useEffect(() => {
    // If no team Id, or if both breakdown and results modal are closed no need to fetch yet, or if we already have the data in the store.
    if (
      !teamId ||
      (!showScoreBreakdownModal && !showTeam360ResultsModal) ||
      tipsAndInterpretiveText
    ) {
      return;
    }
    dispatch(getTipsAndInterpretiveText({ teamId }));
  }, [
    teamId,
    dispatch,
    showScoreBreakdownModal,
    showTeam360ResultsModal,
    tipsAndInterpretiveText,
  ]);

  // Gets the user guide sections for the user
  useEffect(() => {
    if (!teamId || !teamGuideById[teamId]?.content) return;

    // Once we have user guide information we separate the connect section from the rest of the sections.
    let connectSection: SectionType | null = null;
    const remainingSections: SectionType[] = [];
    Object.values(teamGuideById[teamId].content).forEach((section) => {
      if (section.title?.toLowerCase() === "reach out for") {
        connectSection = section;
      } else {
        remainingSections.push(section);
      }
    });

    setSections({ remainingSections, connectSection });
  }, [teamGuideById, teamId]);

  // ----------------------------------- On success hooks ------------------------------------------
  useEffect(() => {
    if (updatingGuidePhotoStatus === "succeeded") {
      setPhotoModalShowing(null);
    }
  }, [updatingGuidePhotoStatus]);

  useEffect(() => {
    if (deletingGuidePhotoStatus === "succeeded") {
      setPhotoModalShowing(null);
    }
  }, [deletingGuidePhotoStatus]);

  useEffect(() => {
    if (deletingStatus === "succeeded") {
      setDeleting(null);
    }
  }, [deletingStatus]);

  useEffect(() => {
    if (userBelongsToTeam && !userTeam360Results) {
      dispatch(getIndividualTeam360AssessmentResults({ teamId }));
    }
  }, [teamId, dispatch, userBelongsToTeam, userTeam360Results]);

  // ----------------------------------- On Action Functions ------------------------------------------
  const onTeamGuideDeleteClick = () => {
    if (deleting?.id === undefined) {
      return;
    }

    if (
      deleting?.moduleType !== undefined &&
      deleting?.moduleTemplateId !== undefined &&
      deleting?.type === "Module"
    ) {
      dispatch(
        deleteTeamModuleById({
          talentInsightsModuleId: Number(deleting.id),
          teamId: currentTeamInfo.teamId,
        })
      );
    } else if (deleting?.type === "Section") {
      dispatch(
        deleteSectionById({
          sectionId: Number(deleting.id),
          teamId: currentTeamInfo.teamId,
        })
      );
    }
  };

  const onDeleteTeam = async () => {
    await dispatch(deleteTeam(teamId));
    navigate("/");
  };

  const onSetTab = (tab: string) => {
    setActiveHeaderTab(tab as TeamGuideTabs);
    setSearchParams({ tab });
    if (tab !== "TEAMscan") {
      return;
    }
    // Fetch the team focus when the user clicks on the TEAMscan tab.
    dispatch(getTeamSkillFocus(teamId));

    if (getSkillsGuideStatus === "idle") {
      dispatch(getSkillGuide());
    }
  };

  const onSeeTeam360ResultsClick = (
    factor: TTeam360Factor,
    lowOrHigh?: "low" | "high" | null
  ) => {
    let urlPath = location.pathname;
    if (activeHeaderTab) {
      urlPath = urlPath + `?tab=${activeHeaderTab}`;
    }
    urlPath = urlPath + `&showTeam360ResultsModal=${factor}`;
    if (lowOrHigh) {
      urlPath = urlPath + `&lowOrHighFactor=${lowOrHigh}`;
    }
    setLowOrHighFactorClicked(lowOrHigh ?? null);
    setShowTeam360ResultsModal(factor);
    navigate(urlPath, {
      replace: true,
    });
  };

  // ---------------------------------- Get Functions ----------------------------------
  // If the team is set to private and the currentUserAccountId is not a member of the team then this should be true.
  const isTeamPrivateAndUserIsNotMember = useMemo(() => {
    const currentTeamInfo = teamInfoById?.[teamId];
    return (
      // If the user is an admin then they should be able to see the team. Could change this in the future so that only team members can navigate between tabs and see the team content.
      !!(currentTeamInfo?.private === 1 && !userBelongsToTeam) && !isAdmin
    );
  }, [userBelongsToTeam, teamInfoById, teamId, isAdmin]);

  const getTeamMemberIds = (): {
    isPending: boolean;
    userAccountId: number;
    isLeader: boolean;
  }[] => {
    const returnArrTemp: {
      [userAccountId: number]: {
        isPending: boolean;
        isLeader: boolean;
      };
    } = [];

    currentTeamInfo?.teamMemberIds?.forEach((userAccountId) => {
      returnArrTemp[userAccountId] = {
        isPending: false,
        isLeader: false,
      };
    });

    currentTeamInfo?.pendingTeamMemberIds?.forEach((userAccountId) => {
      returnArrTemp[userAccountId] = {
        isPending: true,
        isLeader: false,
      };
    });

    currentTeamInfo?.teamLeadUserAccountIds?.forEach((userAccountId) => {
      returnArrTemp[userAccountId] = {
        isPending: false,
        isLeader: true,
      };
    });

    return Object.entries(returnArrTemp)
      .map(
        ([userAccountId, { isPending, isLeader }]) => ({
          isPending,
          userAccountId: Number(userAccountId),
          isLeader,
        }) // also sort by team lead first
      )
      .sort((a, b) => {
        if (a.isLeader !== b.isLeader) {
          return a.isLeader ? -1 : 1;
        }
        return a.userAccountId > b.userAccountId ? 1 : -1;
      });
  };

  const onOpenInviteTeamLeaderModal = () => {
    dispatch(
      openInviteTeamLeaderModal({
        teamId,
      })
    );
  };

  const getConnectModules = () => {
    if (!sections.connectSection || !moduleTemplates) {
      return null;
    }

    return (
      <div className="connect-modules">
        {Object.values(sections.connectSection.modules).map(
          ({
            moduleTemplateId,
            title,
            moduleType,
            moduleContent,
            link,
            talentInsightsModuleId,
          }) => (
            <Module
              key={`${talentInsightsModuleId}${moduleType}`}
              title={title}
              moduleType={moduleType}
              content={moduleContent}
              onEditClick={() => {
                dispatch(
                  showEditModuleModal({
                    moduleType,
                    moduleTemplateId,
                    moduleContent,
                    link,
                    talentInsightsModuleId,
                    userIdOrTeamIdOrCompanyId: teamId,
                    guideType: "team",
                  })
                );
              }}
              onDeleteClick={() =>
                setDeleting({
                  type: "Module",
                  id: talentInsightsModuleId,
                  moduleType,
                  moduleTemplateId,
                })
              }
              hasEditAccess={hasEditAccess}
              moduleTemplate={moduleTemplates[moduleType][moduleTemplateId]}
            />
          )
        )}
      </div>
    );
  };

  const getContactCard = () => {
    const teamLeads: ReactElement[] = [];
    currentTeamInfo?.teamMembers?.forEach(
      ({ userAccountId, isTeamLead }: TeamMember) => {
        if (!usersInfoById[userAccountId]) return null;
        const { firstName, lastName, profilePicture, emailAddress, jobTitle } =
          usersInfoById[userAccountId];
        if (isTeamLead === 1) {
          teamLeads.push(
            <div key={userAccountId} className="leader-info">
              <div
                role="button"
                onClick={() => navigate(`/UserGuide/${userAccountId}`)}
              >
                <AvatarCircle
                  name={`${firstName} ${lastName}`}
                  profilePicture={profilePicture}
                  userAccountId={userAccountId}
                />
                <div>
                  <p className="member-name">
                    {firstName} {lastName}
                  </p>
                  <span className="member-role">
                    {jobTitle || "No Job Title"}
                  </span>
                </div>
              </div>
              <div>
                <div className="contact-button" role="button">
                  <a href={`mailto:${emailAddress}`} style={{ color: "white" }}>
                    <FontAwesomeIcon icon="envelope" />
                  </a>
                </div>
              </div>
            </div>
          );
        }
      }
    );

    return (
      <Card className="section">
        <div className="section-header">
          <h2>Connect</h2>
          {hasEditAccess ? (
            <Dropdown
              onSelect={(e) => {
                if (e === "add") {
                  return dispatch(
                    showAddModuleModal({
                      section: "Reach Out For",
                      guideType: "team",
                      userIdOrTeamIdOrCompanyId: teamId,
                    })
                  );
                }
                if (e === "add-leader") {
                  return onOpenInviteTeamLeaderModal();
                }
              }}
            >
              <Dropdown.Toggle
                variant="outline-primary"
                id="dropdown-basic"
                className="no-caret"
              >
                <FontAwesomeIcon icon="ellipsis" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item eventKey="add">
                  <FontAwesomeIcon icon="plus" /> Add To Section
                </Dropdown.Item>
                <Dropdown.Item eventKey="add-leader">
                  Add a Team Leader
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : null}
        </div>
        <div className="column-gap-20px">
          {teamLeads.length > 0 ? (
            <div className="team-leads">
              <Card className="team-lead">
                <h3>Team Leader{teamLeads.length > 1 ? "s" : ""}</h3>
                <div className="leaders-container">{teamLeads}</div>
              </Card>
            </div>
          ) : null}
          {getConnectModules()}
        </div>
      </Card>
    );
  };

  const getMemberCard = (userAccountId: number, isPending?: boolean) => {
    const {
      firstName,
      lastName,
      profilePicture,
      jobTitle,
      firstTeamsLogin,
      emailAddress,
    } = usersInfoById[userAccountId];

    const hasLoggedIn = firstTeamsLogin || firstName || lastName || jobTitle;

    let jobTitleText = jobTitle;
    // If user is pending then regardless if the user already has a job title we update that to show pending team invitation.
    if (!hasLoggedIn) {
      jobTitleText = "Pending user";
    } else if (isPending) {
      jobTitleText = "Pending team invitation";
    }

    return (
      <Card
        key={userAccountId}
        role={hasLoggedIn ? "button" : undefined}
        className="team-member-card-no-button"
        onClick={() => {
          if (!hasLoggedIn) return;
          navigate(`/UserGuide/${userAccountId}`);
        }}
      >
        <div
          style={{
            opacity: isPending ? 0.5 : 1,
          }}
        >
          <AvatarCircle
            name={`${firstName} ${lastName}`}
            profilePicture={profilePicture}
            userAccountId={userAccountId}
            size="large"
            avatarColor={!hasLoggedIn ? "53565A" : undefined}
          />
        </div>
        <span
          className="name"
          style={{
            color: isPending ? "#53565A" : undefined,
          }}
        >
          {hasLoggedIn ? `${firstName || ""} ${lastName || ""}` : emailAddress}
        </span>
        <span className="role">{jobTitleText}</span>
      </Card>
    );
  };

  const getTeamMembersCard = () => {
    let memberCount = 0;
    const teamMembers: ReactElement[] = [];
    getTeamMemberIds().forEach(({ userAccountId, isPending }, idx) => {
      // If the user does not exist inside of usersInfoById then we can assume that the team member no longer exists.
      if (!usersInfoById[userAccountId]) return;
      memberCount += 1;

      // Only show 3 team members
      if (idx > 3) return;

      teamMembers.push(getMemberCard(userAccountId, isPending));
    });

    return (
      <Card className="section team-members">
        <div className="section-header">
          <h2>Team Members</h2>
          <Button
            variant="secondary-blue"
            style={{ border: "none" }}
            onClick={() => {
              navigate(`/TeamGuide/${teamId}/TeamMembers`);
            }}
          >
            See All
          </Button>
        </div>
        <div className="section-body">{teamMembers}</div>

        {memberCount && !hasEditAccess ? (
          <span
            className="section-row-count"
            role="button"
            onClick={() => {
              navigate(`/TeamGuide/${teamId}/TeamMembers`);
            }}
          >
            {memberCount} Total Member
            {memberCount > 1 ? "s" : ""}
          </span>
        ) : null}

        {hasEditAccess ? (
          <div
            style={{
              marginTop: "16px",
            }}
            className="row-gap-8px"
          >
            <Button onClick={() => setShowInviteMemberModal(true)}>
              Invite new member
            </Button>
            <Button
              variant="secondary-blue"
              onClick={() =>
                navigate(
                  `/TeamGuide/${teamId}/TeamSettings?teamSettingsActiveTab=members`
                )
              }
            >
              Manage
            </Button>
          </div>
        ) : null}
      </Card>
    );
  };

  const getTeamSections = () => {
    if (!currentTeamGuide || !teamId) {
      return null;
    }

    const sectionsArr = sections.remainingSections;
    if (sectionsArr.length === 0 && !hasEditAccess) {
      // If the user is able to view the TEAMscan but don't have access to view
      return <NoGuideYet name={currentTeamInfo?.teamName || ""} type="team" />;
    }

    return sectionsArr.map(({ sectionId, title, modules, moduleOrder }) => {
      return (
        <Section
          guideType="team"
          userIdOrTeamIdOrCompanyId={currentTeamInfo?.teamId}
          moduleOrder={getModuleOrder(moduleOrder)}
          sectionId={sectionId}
          title={title}
          modules={modules}
          key={sectionId}
          setDeleting={setDeleting}
          hasEditAccess={hasEditAccess}
          isCollapsible={title === "Resources"}
        />
      );
    });
  };

  const getTeamGuidePageContent = () => {
    switch (view) {
      case "guide":
        return (
          <>
            <div className="header-buttons">
              <NavigateBackButton />
              <div className="header-buttons-group">
                {hasEditAccess ? (
                  <>
                    <Button
                      variant="secondary-blue"
                      className="me-2"
                      onClick={() =>
                        navigate(
                          `/TeamGuide/${teamId}/TeamSettings?teamSettingsActiveTab=members`
                        )
                      }
                    >
                      <FontAwesomeIcon icon="gear" className="me-2" />
                      Settings
                    </Button>
                    <Button
                      onClick={() =>
                        dispatch(
                          showAddModuleModal({
                            guideType: "team",
                            userIdOrTeamIdOrCompanyId: teamId,
                          })
                        )
                      }
                    >
                      <FontAwesomeIcon icon="plus" className="me-2" />
                      Add Section
                    </Button>
                  </>
                ) : null}
              </div>
            </div>
            <Header
              name={currentTeamInfo?.teamName || ""}
              coverPhoto={currentTeamGuide?.coverPhoto?.picture ?? undefined}
              profilePicture={
                currentTeamGuide?.profilePicture?.picture ?? undefined
              }
              avatarColor={currentTeamInfo?.avatarColor}
              setPhotoModalShowing={setPhotoModalShowing}
              hasEditAccess={hasEditAccess}
              onEditClickHandler={() => setInformationModalShowing(true)}
              totalMembers={
                currentTeamInfo?.teamMemberIds?.length ??
                currentTeamInfo?.teamMembers?.length
              }
              pendingTeamMemberCount={
                currentTeamInfo?.pendingTeamMemberIds?.length
              }
              headerDescription={
                (currentTeamInfo?.departmentId &&
                  departments[currentTeamInfo?.departmentId]?.name) ||
                undefined
              }
              companyHeaderPhoto={companyHeaderPhoto}
              onInvite={() => setShowInviteMemberModal(true)}
              tabs={
                hasAccess
                  ? TEAM_TABS
                  : TEAM_TABS.filter((tab) => tab !== "TEAMscan")
              }
              activeKey={activeHeaderTab}
              setTab={onSetTab}
              isPrivateAndNoAccess={isTeamPrivateAndUserIsNotMember}
            />
            {getTabContent()}
          </>
        );
      case "settings":
        return (
          <TeamSettings
            teamInfo={currentTeamInfo}
            teamMembers={currentTeamInfo?.teamMembers || []}
            showInviteModal={() => setShowInviteMemberModal(true)}
            onDeleteTeam={() => {
              if (!teamId || !currentUserAccountId) {
                return;
              }
              onDeleteTeam();
            }}
            deleteTeamStatus={deleteTeamStatus}
            onUpdateTeam={(teamInfo: Partial<Team>) => {
              if (!teamId) {
                return;
              }
              dispatch(
                updateTeam({
                  teamId: teamId,
                  payload: teamInfo,
                })
              );
            }}
          />
        );
      case "team-members":
        return (
          <TeamFullPageCard
            hasEditAccess={hasEditAccess}
            goToTeamSettings={() => setView("settings")}
            teamMembers={currentTeamInfo?.teamMembers || []}
            teamName={currentTeamInfo?.teamName || ""}
            showInviteModal={() => setShowInviteMemberModal(true)}
            teamId={teamId}
          />
        );
      case "full-team-personality-report":
        return <FullPageTeamPersonalityReport teamId={teamId} />;
      default:
        return null;
    }
  };

  // Returns two module templates, of each type.
  const getBuildYourUserGuide = () => {
    if (
      !teamId ||
      !hasEditAccess ||
      moduleTemplates === null ||
      (currentTeamGuide && Object.keys(currentTeamGuide.content).length > 3)
    ) {
      return [];
    }

    const returnModules = [];
    const freeTextModule = Object.entries(moduleTemplates["Free Text"]).find(
      ([moduleTemplateId]) =>
        !currentTeamUsedModules?.["Free Text"].includes(
          Number(moduleTemplateId)
        )
    );

    if (freeTextModule) {
      returnModules.push({ ...freeTextModule[1], moduleType: "Free Text" });
    }
    const listModule = Object.entries(moduleTemplates["List"]).find(
      ([moduleTemplateId]) =>
        !currentTeamUsedModules?.["List"].includes(Number(moduleTemplateId))
    );
    if (listModule) {
      returnModules.push({ ...listModule[1], moduleType: "List" });
    }

    return returnModules;
  };

  const getTabContent = (): React.JSX.Element => {
    // If the user is not an admin, the team is private, and the user is not a member of the team then we show a message saying that the team is private.
    if (isTeamPrivateAndUserIsNotMember) {
      return (
        <Card className="private-guide-body">
          <FontAwesomeIcon
            icon={["fasr", "lock-keyhole"]}
            style={{ fontSize: "24px" }}
          />
          <div className="text-center">
            <h4 className="mb-0">This team is private</h4>
            <p className="mb-0">
              You can only view this team if you are a member of this team or an
              admin.
            </p>
          </div>
          {/* <Button>Request to join</Button> */}
        </Card>
      );
    }

    switch (activeHeaderTab) {
      case "About":
        return (
          <div className="guide-body">
            {getTeamGuideStatus === "loading" ? (
              <Loading />
            ) : (
              <div className="sections">
                {getTeamSections()}
                <BuildYourUserGuideCard
                  onClickHandler={() =>
                    dispatch(
                      showAddModuleModal({
                        guideType: "team",
                        userIdOrTeamIdOrCompanyId: teamId,
                      })
                    )
                  }
                  guideType="team"
                  modules={getBuildYourUserGuide()}
                  userIdOrTeamIdOrCompanyId={teamId}
                />
              </div>
            )}
            <div className="side-bar">
              {/* Only if the user belongs to the teams or ig the user is a manager then we show the preview */}
              {(isUserAdminOrDepartmentLead || userBelongsToTeam) &&
              hasEnoughResults &&
              team360AnalyticsText?.completionInfo?.totalCompleted &&
              team360AnalyticsText?.completionInfo?.totalCompleted > 1 ? (
                <SmallTeam360PreviewCard
                  className="section"
                  title="TEAMscan results available"
                  numberOfCompletedResponses={
                    team360AnalyticsText?.completionInfo?.totalCompleted
                  }
                  onViewResultsClick={() => {
                    navigate(`/TeamGuide/${teamId}?tab=TEAMscan`);
                  }}
                  overallScore={getTeam360Score(
                    allTeam360Scores?.teamScores[teamId]?.overall ??
                      userTeam360Results?.teamScores?.overall ??
                      0
                  )}
                />
              ) : null}
              {getContactCard()}
              <div>{getTeamMembersCard()}</div>
            </div>
          </div>
        );
      case "TEAMscan": {
        // If the user does not belong to the team or is not an admin then we should not be showing the TEAMscan results.
        // We wil iterate through all of the user's teams and get the 360 assessment results for each team, if they exist.
        // If the user:
        //   1. Is not an admin
        //   2. Is not a team leader
        //   3. Is not a department leader of the team
        return (
          <div className="guide-body">
            <div
              style={{
                gridColumn: "2 span",
                gap: "20px",
                display: "grid",
              }}
            >
              <Team360FullPage
                goBack={() => setView("guide")}
                teamId={teamId}
                onFactorClick={onSeeTeam360ResultsClick}
                isLoading={
                  team360AnalyticsTextStatus === "loading" ||
                  getAllTeam360ScoresStatus === "loading" ||
                  getTeam360Status === "loading"
                }
                hasEditAccess={!!(hasEditAccess || isUserAdminOrDepartmentLead)}
              />
            </div>
          </div>
        );
      }
      case "Team Personality":
        return (
          <TeamPersonality
            totalTeamMembers={currentTeamInfo?.teamMemberIds?.length ?? 0}
            teamId={teamId}
          />
        );
      default:
        return <></>;
    }
  };

  const hideInviteModal = () => {
    dispatch(clearInviteCount());
    setShowInviteMemberModal(false);
  };

  const getTeam360ResultModalDescription = () => {
    switch (lowOrHighFactorClicked) {
      case "low":
        return team360AnalyticsText?.strengthsAndWeaknesses?.factors
          ?.lowestScoringFactorText;
      case "high":
        return team360AnalyticsText?.strengthsAndWeaknesses?.factors
          ?.highestScoringFactorText;

      default:
        return null;
    }
  };

  const currentTeamInfo = teamInfoById?.[teamId];
  const currentTeamGuide = teamGuideById?.[teamId];
  const currentTeamUsedModules = teamUsedModulesById?.[teamId] ?? null;
  const companyHeaderPhoto =
    companySettings?.globalHeader ?? currentCompanyGuide?.coverPhoto?.picture;
  const anyOneCanViewTeam360Results =
    currentTeamInfo && currentTeamInfo.team360ResultsVisibility === 1;

  // Using || instead of ?? because anyOneCanViewTeam360Results is a boolean.
  const hasAccess =
    anyOneCanViewTeam360Results ||
    userBelongsToTeam ||
    hasEditAccess ||
    isUserAdminOrDepartmentLead;

  const totalCompleted = team360AnalyticsText?.completionInfo?.totalCompleted;
  const hasEnoughResults = totalCompleted && totalCompleted > 2;

  const teamResults =
    userTeam360Results?.teamScores ?? allTeam360Scores?.teamScores[teamId];
  return (
    <div className="page guide">
      <Team360ScoreBreakdownModal
        show={showScoreBreakdownModal}
        onHide={() => {
          setShowScoreBreakdownModal(false);
        }}
        modalSearchParam="showScoreBreakdownModal"
        teamId={teamId}
      />
      <EditInformationModal
        team={currentTeamInfo}
        modalShowing={informationModalShowing}
        hideModal={() => setInformationModalShowing(false)}
      />
      {showTeam360ResultsModal && teamResults ? (
        <Team360ResultsModal
          show={!!showTeam360ResultsModal}
          hideModal={() => {
            setShowTeam360ResultsModal(null);
            setLowOrHighFactorClicked(null);
            navigate(
              `${location.pathname}${
                activeHeaderTab ? `?tab=${activeHeaderTab}` : null
              }`,
              {
                replace: true,
              }
            );
          }}
          companyScores={allTeam360Scores?.companyScores}
          teamScores={teamResults}
          userScores={userTeam360Results?.userScores}
          teamId={teamId}
          activeFactor={showTeam360ResultsModal}
          tipsAndInterpretiveText={tipsAndInterpretiveText}
          modalDescription={getTeam360ResultModalDescription()}
        />
      ) : null}
      <InviteMemberModal
        show={showInviteMemberModal}
        onHide={hideInviteModal}
        inviteCount={inviteCount}
        teamId={teamId}
        currentTeamInfo={currentTeamInfo}
        users={Object.values(filteredUsersInfoById)}
      />
      <UploadPictureModal
        modalShowing={photoModalShowing}
        closeModal={() => setPhotoModalShowing(null)}
        picture={
          photoModalShowing === "profilePicture"
            ? currentTeamGuide?.profilePicture?.base64
            : currentTeamGuide?.coverPhoto?.base64
        }
        isLoading={
          deletingGuidePhotoStatus === "loading" ||
          updatingGuidePhotoStatus === "loading"
        }
        updateGuidePhoto={(photo, imgSrc, imageName) => {
          if (!currentTeamInfo.teamId || photoModalShowing === null) {
            return;
          }
          dispatch(
            updateGuidePhoto({
              photo,
              imgSrc,
              photoType: photoModalShowing,
              teamId: currentTeamInfo.teamId,
              imageName,
            })
          );
        }}
        deleteGuidePhoto={() => {
          if (!currentTeamInfo.teamId || photoModalShowing === null) {
            return;
          }
          dispatch(
            deleteGuidePhoto({
              teamId: currentTeamInfo.teamId,
              photoType: photoModalShowing,
            })
          );
        }}
      />
      <ModulesModal />
      {deleting !== null && (
        <WarningModal
          modalTitle={`Delete ${
            deleting.type === "Section" ? "Section" : "Module"
          }`}
          warningTitle={`Are you sure you want to delete this ${deleting?.type.toLocaleLowerCase()}`}
          warningMessage={
            deleting.type === "Section"
              ? "All modules inside this section will be deleted as well. This action cannot be undone"
              : "This action cannot be undone"
          }
          hideModal={() => setDeleting(null)}
          onConfirmClick={onTeamGuideDeleteClick}
          isOpen={deleting !== null}
          isInProgress={deletingStatus === "loading"}
        />
      )}
      {getTeamGuidePageContent()}
    </div>
  );
}
