import { useState, useEffect } from "react";

const useWidthBasedVisibility = (widthThreshold: number): boolean => {
  const [isVisible, setIsVisible] = useState<boolean>(
    window.innerWidth >= widthThreshold
  );

  useEffect(() => {
    const handleResize = () => {
      setIsVisible(window.innerWidth >= widthThreshold);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts or the threshold changes
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [widthThreshold]);

  return isVisible;
};

export default useWidthBasedVisibility;
