import { setCookieItem } from './helperFunctions';
import Cookies from 'js-cookie';
import { useState, useEffect, DependencyList } from 'react';

export const useCookie = (key: string, defaultValue: string) => {
  const getCookie = () => Cookies.get(key) || defaultValue;
  const [cookie, setCookie] = useState(getCookie());
  const updateCookie = (value: string, numberOfDays: number) => {
    setCookie(value);
    setCookieItem(key, value, numberOfDays);
  };
  return [cookie, updateCookie];
};

export function useDebounceEffect(
  fn: () => void,
  waitTime: number,
  deps: DependencyList
) {
  useEffect(() => {
    const t = setTimeout(() => {
      // @ts-ignore
      fn.apply(undefined, deps);
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
