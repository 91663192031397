import { useState, useEffect, useMemo, useLayoutEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "app/storybookComponents/Button";
import { Card, Form } from "react-bootstrap";
import { TeamMember } from "app/containers/Global/types";
import TeamMemberCardSmall from "app/storybookComponents/TeamMemberCards/TeamMemberCardSmall";
import { useAppSelector } from "utils/redux/hooks";
import {
  selectAllCompanyUsersById,
  selectSampleTeamsByTeamId,
  selectSampleUsersInfoById,
  selectTeamsByTeamId,
} from "app/containers/Global/slice";
import NavigateBackButton from "app/components/NavigateBackButton";

interface Props {
  teamMembers?: TeamMember[];
  teamName: string;
  showInviteModal: () => void;
  hasEditAccess?: boolean;
  goToTeamSettings: () => void;
  teamId: number;
}

// TODO: Need to hide the invite button if the logged in user does not have access to invite
export default function TeamFullPageCard({
  teamMembers,
  teamName,
  showInviteModal,
  hasEditAccess,
  goToTeamSettings,
  teamId,
}: Props) {
  const navigate = useNavigate();

  // ----------------- Redux Selectors ----------------- //
  const sampleTeamsById = useAppSelector(selectSampleTeamsByTeamId);
  const allTeamsById = useAppSelector(selectTeamsByTeamId);
  const sampleUsersById = useAppSelector(selectSampleUsersInfoById);
  const companyUsersById = useAppSelector(selectAllCompanyUsersById);

  // ----------------- Use States ----------------- //
  const [filteredTeamMembers, setFilteredTeamMembers] = useState<
    {
      userAccountId: number;
      isPending?: boolean;
    }[]
  >([]);
  const [value, setValue] = useState<string>("");
  const [membersInTeam, setMembersInTeam] = useState<
    {
      userAccountId: number;
      isPending?: boolean;
    }[]
  >([]);

  const teams = useMemo(
    () => ({ ...sampleTeamsById, ...allTeamsById }),
    [sampleTeamsById, allTeamsById]
  );
  const users = useMemo(
    () => ({ ...sampleUsersById, ...companyUsersById }),
    [sampleUsersById, companyUsersById]
  );

  // ----------------- Use Effects ----------------- //

  useEffect(() => {
    const timerId = setTimeout(() => {
      // handle the debounced value here
      const pattern = new RegExp(value.toLowerCase());
      setFilteredTeamMembers(
        membersInTeam.filter(({ userAccountId }) => {
          const userInformation = users[userAccountId];
          if (!userInformation) {
            // If the user is not found, then don't show them
            return false;
          }

          const {
            firstName = "",
            lastName = "",
            emailAddress = "",
          } = userInformation;
          const fullName = `${firstName} ${lastName}`;
          return (
            pattern.test(fullName.toLowerCase()) ||
            pattern.test(emailAddress.toLowerCase())
          );
        })
      );
    }, 500);

    // clear the timeout on each value change to reset the timer
    return () => clearTimeout(timerId);
  }, [value, membersInTeam, users]);

  useLayoutEffect(() => {
    const teamInfo = teams[teamId];
    if (!teamInfo) {
      return;
    }

    const newMembers: {
      userAccountId: number;
      isPending?: boolean;
    }[] = [];

    teamInfo.teamMemberIds?.forEach((userAccountId) => {
      newMembers.push({
        userAccountId,
      });
    });

    teamInfo.pendingTeamMemberIds?.forEach((userAccountId) => {
      newMembers.push({
        userAccountId,
        isPending: true,
      });
    });

    setMembersInTeam(newMembers);
  }, [teamId, teams]);

  // ----------------- Helper Functions ----------------- //

  const getTeamMemberCards2 = () => {
    const members = value ? filteredTeamMembers : membersInTeam;

    if (!members.length) {
      return <div>No team members found</div>;
    }

    return members.map((teamMember) => (
      <TeamMemberCardSmall
        key={teamMember.userAccountId}
        userAccountId={teamMember.userAccountId}
        isMemberPending={!!teamMember.isPending}
        onUserGuideClick={() =>
          navigate(`/UserGuide/${teamMember.userAccountId}`)
        }
        pendingMessage="Pending team invitation"
      />
    ));
  };

  // ----------------- Render ----------------- //
  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <NavigateBackButton />
      </div>
      <Card className="team-members-full-card">
        <span className="team-member-count">
          {teamMembers?.length || 0} Member
          {teamMembers && teamMembers?.length > 1 ? "s" : ""}
        </span>
        <div className="team-members-title-row">
          <Link to={`/TeamGuide/${teamId}`} className="no-underline">
            <h1>
              {teamName}
              {teamName.includes("Team") ? "" : " Team"}
            </h1>
          </Link>
          <div>
            {hasEditAccess ? (
              <Button onClick={goToTeamSettings} variant="secondary-blue">
                <FontAwesomeIcon icon="cog" className="me-2" />
                Manage
              </Button>
            ) : null}

            <Button onClick={showInviteModal}>
              <FontAwesomeIcon icon="user-plus" className="me-2" />
              Invite
            </Button>
          </div>
        </div>
        <Form.Group className="d-flex my-3">
          <Form.Control
            placeholder={"Search by name, job title or email..."}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </Form.Group>
        <div className="team-member-cards">{getTeamMemberCards2()}</div>
      </Card>
    </>
  );
}
