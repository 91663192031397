import { Card, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  getTextSynthesis,
  getWordCloud,
  getTextInsights,
  getFeedbackResponsesCount,
  selectTextSynthesis,
  selectTextInsights,
  selectGetTextInsightsStatus,
  selectTextInsightsErrorMessage,
  selectFeedbackResponsesCount,
  selectGetFeedbackResponsesCountStatus,
  resetTextInsights,
  resetTextSynthesis,
} from "./slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FreeTextInsightsModal from "./Modals/FreeTextInsightsModal";
import Loading from "app/storybookComponents/Loading";
import {
  selectCompanyInfo,
  selectGetCompanyInfoStatus,
} from "app/containers/Global/slice";
import { isInternalCompanyAccount } from "utils/helperFunctions";

interface Props {
  readonly departmentId?: number;
  readonly teamId?: number;
}

export default function FreeTextSummary({ departmentId, teamId }: Props) {
  const dispatch = useAppDispatch();
  const textSynthesis = useAppSelector(selectTextSynthesis);
  const textInsights = useAppSelector(selectTextInsights);
  const textInsightsErrorMessage = useAppSelector(
    selectTextInsightsErrorMessage
  );
  const textInsightsStatus = useAppSelector(selectGetTextInsightsStatus);
  const [showFreeTextInsightsModal, setShowFreeTextInsightsModal] =
    useState(false);
  const feedbackResponsesCount = useAppSelector(selectFeedbackResponsesCount);
  const feedbackResponsesCountStatus = useAppSelector(
    selectGetFeedbackResponsesCountStatus
  );
  const companyInfo = useAppSelector(selectCompanyInfo);
  const getCompanyInfoStatus = useAppSelector(selectGetCompanyInfoStatus);

  const getOpenAIParamsPayload = () => {
    if (departmentId) {
      return { departmentId };
    } else if (teamId) {
      return { teamId };
    }

    return {};
  };

  const payload = getOpenAIParamsPayload();

  useEffect(() => {
    dispatch(getWordCloud());
  }, [dispatch]);

  useEffect(() => {
    dispatch(resetTextSynthesis());
    dispatch(resetTextInsights());
    if (teamId) {
      dispatch(getFeedbackResponsesCount({ teamId }));
    } else if (departmentId) {
      dispatch(getFeedbackResponsesCount({ departmentId }));
    } else {
      dispatch(getFeedbackResponsesCount());
    }
  }, [teamId, departmentId, dispatch]);

  const handleOnClick = () => {
    dispatch(getTextInsights(payload));
    dispatch(getTextSynthesis(payload));
  };

  const getButtonRow = () => {
    if (textSynthesis && textInsights) {
      return (
        <Button onClick={() => setShowFreeTextInsightsModal(true)}>
          See insights
        </Button>
      );
    }

    if (textInsightsStatus === "failed") {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Button disabled={true}>Generate Insights</Button>
          <FontAwesomeIcon icon={["far", "triangle-exclamation"]} />
          <div>{textInsightsErrorMessage}</div>
        </div>
      );
    }

    return (
      <Button
        onClick={handleOnClick}
        disabled={textInsightsStatus === "loading"}
      >
        Generate Insights
      </Button>
    );
  };

  if (
    feedbackResponsesCountStatus === "loading" ||
    !feedbackResponsesCount ||
    feedbackResponsesCount < 20
  ) {
    return null;
  }

  if (
    !isInternalCompanyAccount(companyInfo?.companyAccountId) || // hiding this feature for all external companies
    getCompanyInfoStatus === "loading"
  ) {
    return null;
  }

  return (
    <>
      <FreeTextInsightsModal
        textInsights={textInsights}
        show={showFreeTextInsightsModal}
        entityType={departmentId ? "department" : "organization"}
        onHide={() => setShowFreeTextInsightsModal(false)}
      />
      <Card className="p-4 column-gap-20px">
        <div className="column-gap-8px">
          <h2>Free Text Summary</h2>
          <p>
            Analyze the most common words and phrases found in responses and
            generate insights from OpenAl
          </p>
        </div>
        {textSynthesis && textInsights ? (
          <div className="snapshot-box">
            <FontAwesomeIcon icon={["far", "lightbulb-on"]} />
            <p>{textSynthesis.skillsText}</p>
          </div>
        ) : null}
        <div>{getButtonRow()}</div>
        {textInsightsStatus === "loading" ? <Loading /> : null}
      </Card>
    </>
  );
}
