import { LIKERT_SCALE } from 'utils/constants';

interface Props {
  questionTitle: string;
  onSelect: (answer: number) => void;
  likertScale?: {
    label: string;
    value: number;
  }[];
  selectedValue?: number;
  customLabel?: string;
}

// Pure component
export default function LikertScale({
  questionTitle,
  likertScale = LIKERT_SCALE,
  onSelect,
  selectedValue,
  customLabel,
}: Props) {
  return (
    <div className="column-gap-4px">
      <h2 className="likert-scale-description">{questionTitle}</h2>
      <fieldset className="column-gap-16px">
        <legend id="rating-legend" className="likert-scale-instructions mb-0">
          {customLabel ||
            'Select the option which best represents your opinion'}
        </legend>
        <div
          role="radiogroup"
          aria-labelledby="rating-legend"
          className="likert-scale-options-container"
        >
          {likertScale.map(({ value, label }) => (
            <label
              className={`likert-scale-option ${
                selectedValue === value ? 'selected' : ''
              }`}
              key={value}
            >
              <input
                type="radio"
                name="rating"
                value={value}
                checked={selectedValue === value}
                aria-checked={selectedValue === value}
                onClick={() => {
                  onSelect(value);
                }}
                onChange={() => {}}
                tabIndex={0}
              />
              {label}
            </label>
          ))}
        </div>
      </fieldset>
    </div>
  );
}
