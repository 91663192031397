import { Card } from 'react-bootstrap';
import { TManagerGuide } from './types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NotAvailable from './NotAvailable';
import { useAppDispatch } from 'utils/redux/hooks';
import AvatarCircle from 'app/components/AvatarCircle';
import { ManagerGuideNavTitles } from './constants';
import ManagerGuideSamplePdf from 'resources/sample_pdfs/sample-manager-guide.pdf';
import { getManagerGuidePdf } from './slice';

interface Props {
  managerGuide: TManagerGuide;
  firstName?: string;
  lastName?: string;
  userAccountId: number;
}

export default function ManagerGuide({
  managerGuide,
  firstName = '',
  lastName = '',
  userAccountId,
}: Props) {
  const dispatch = useAppDispatch();

  const getCards = () => {
    return Object.entries(managerGuide).map(([key, questionAndTip]) => {
      const { title } = ManagerGuideNavTitles[key];
      return (
        <Card key={title} className="manager-guide-card shadow-sm">
          <Card.Header>{title}</Card.Header>
          <Card.Body>
            <h3>
              <FontAwesomeIcon
                icon={['far', 'lightbulb-on']}
                className="me-2"
              />
              <span className="text-dark">Tip</span>
            </h3>
            <Card.Text>{questionAndTip.tips}</Card.Text>
            {getConversationStarters(questionAndTip.questions)}
          </Card.Body>
        </Card>
      );
    });
  };

  const getNav = () => {
    return Object.keys(managerGuide).map((key) => {
      const { icon, title } = ManagerGuideNavTitles[key];
      return (
        <div key={key}>
          <img
            src={icon}
            alt={title}
            className="me-3"
            style={{ height: '40px', width: '40px' }}
          />
          <span>{title}</span>
        </div>
      );
    });
  };

  const getConversationStarters = (questions: string[]) => {
    return (
      <div>
        <span>Conversation Starters</span>
        <ul>
          {questions.map((question) => (
            <li key={question}>{question}</li>
          ))}
        </ul>
      </div>
    );
  };

  if (!managerGuide) {
    return (
      <NotAvailable
        message="Manager Guides provide an overview of how to communicate, support, and motivate your direct reports. Within the guide there are also tips and conversation starters to help you better manage your direct reports."
        linkLabel="View Sample Manager Guide"
        link={ManagerGuideSamplePdf}
      />
    );
  }

  return (
    <div>
      <div className="guide-pdf-download">
        <span
          role="button"
          onClick={() => {
            dispatch(getManagerGuidePdf(userAccountId));
          }}
        >
          <FontAwesomeIcon icon={['far', 'download']} className="me-2" />
          Download
        </span>
      </div>
      <Card className="p-4">
        <div className="collaboration-guide-header">
          <div>
            <h2>Manager Guide</h2>
            <p className="collaboration-guide-header-text mb-0">
              This guide provides tips for managing
              <strong>{` ${firstName} ${lastName} `}</strong>
              based on their personality results.
            </p>
            <p>
              {`For more in-depth information about ${firstName}'s personality, view
                their `}
              <span style={{ textDecoration: 'underline' }}>
                Work Insights report
              </span>
            </p>
          </div>
          <div className="collaboration-guide-avatars">
            <AvatarCircle userAccountId={userAccountId} />
          </div>
        </div>
        <hr />
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 2fr',
            gap: '12px',
          }}
        >
          <div className="navigation-box sticky-top">{getNav()}</div>
          <div className="d-flex flex-column gap-4">{getCards()}</div>
        </div>
      </Card>
    </div>
  );
}
