import {
  getAssessmentInformation,
  selectAssessmentInformation,
  selectDepartments,
} from "app/containers/AdminConsole/slice";
import { showScheduleAssessmentModalForDepartmentId } from "app/components/LaunchAssessmentModal/slice";
import {
  selectTeamsByTeamId,
  selectAllCompanyUsersById,
} from "app/containers/Global/slice";
import { memo, useEffect, useMemo, useState } from "react";
import { Card, Collapse } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "app/storybookComponents/Button";
import {
  getDepartmentInsightReport,
  selectLatestDepartmentInsightReportById,
} from "app/containers/DepartmentInsightReport/slice";
import { setShowModal } from "app/components/Onboarding/slice";
import Loading from "app/storybookComponents/Loading";
import {
  selectDepartmentTeamsTeam360AnalyticsTextStatus,
  getDepartmentTrackingEvents,
  getDepartmentCardInfo,
  selectDepartmentCardInfoById,
  selectDepartmentTrackingEvents,
  selectGetDepartmentTrackingEventsStatus,
  getDepartmentPendingTeamLeaders,
  selectDepartmentTeamLeadersByDepartmentId,
  getDepartmentAssessmentCompletionInfo,
  selectGetDepartmentAssessmentInfoStatus,
  getDepartmentResultsInfo,
  selectDepartmentResultsInfoByDepartmentId,
  checkIfAllPendingLeadersHaveBeenReminded,
  selectAllPendingLeadersHaveBeenReminded,
  selectCheckIfAllPendingLeadersHaveBeenRemindedStatus,
  selectRemindTeamLeadersStatus,
} from "../slice";
import { selectTeamsMostRecentAssessmentsInstancesEntities } from "app/containers/Assessment/slice";
import InfoCardV2 from "app/components/InfoCard/InfoCardV2";
import HalfDonutChart from "app/storybookComponents/Charts/HalfDonutChart";
import { getTeam360Score } from "app/components/Team360Assessment/helpers";
import QuickActionCard from "app/components/QuickActions/QuickActionCard";
import SurveyResultsModal from "app/components/Modals/SurveyResultsModal";
import PendingTeamsModal from "app/components/Modals/PendingTeamsModal";
import { AssessmentMap } from "app/containers/Assessment/constants";

interface Props {
  selectedDepartmentId: number | null;
  userAccountId: number | null;
  onShowInviteMemberModal: () => void;
  onShowInviteTeamLeaderModal: () => void;
}

type DepartmentLeaderPopup =
  | "dashboardTeamsInDepartmentPopup"
  | "dashboardTeam360ForDepartmentPopup"
  | "dashboardTeam360ResultsPopup";

const DepartmentLeaderOnboarding = ({
  selectedDepartmentId,
  onShowInviteTeamLeaderModal,
}: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // ------------------------ Redux Selectors ------------------------
  const departments = useAppSelector(selectDepartments);
  const usersById = useAppSelector(selectAllCompanyUsersById);
  const teamsById = useAppSelector(selectTeamsByTeamId);
  const departmentInsightReport = useAppSelector(
    selectLatestDepartmentInsightReportById(selectedDepartmentId)
  );
  const departmentTeamsTeam360AnalyticsTextStatus = useAppSelector(
    selectDepartmentTeamsTeam360AnalyticsTextStatus
  );
  const departmentCardInfo = useAppSelector(
    selectDepartmentCardInfoById(selectedDepartmentId)
  );
  const departmentTrackingEvents = useAppSelector(
    selectDepartmentTrackingEvents
  );

  const departmentTrackingEventsStatus = useAppSelector(
    selectGetDepartmentTrackingEventsStatus
  );
  const departmentTeamLeaders = useAppSelector(
    selectDepartmentTeamLeadersByDepartmentId(selectedDepartmentId)
  );
  const departmentAssessmentInfoStatus = useAppSelector(
    selectGetDepartmentAssessmentInfoStatus
  );
  const departmentResultsInfo = useAppSelector(
    selectDepartmentResultsInfoByDepartmentId(selectedDepartmentId)
  );
  const allPendingLeadersHaveBeenReminded = useAppSelector(
    selectAllPendingLeadersHaveBeenReminded
  );
  const remindTeamLeadersStatus = useAppSelector(selectRemindTeamLeadersStatus);
  const checkIfAllPendingLeadersHaveBeenRemindedStatus = useAppSelector(
    selectCheckIfAllPendingLeadersHaveBeenRemindedStatus
  );
  const teamsMostRecentAssessments = useAppSelector(
    selectTeamsMostRecentAssessmentsInstancesEntities
  );
  const assessmentInformation = useAppSelector(selectAssessmentInformation);

  // ------------------------ States ------------------------
  const [popoverQueue, setPopoverQueue] = useState<DepartmentLeaderPopup[]>([]);
  const [showResultsModal, setShowResultsModal] = useState(false);
  const [showPendingTeamsModal, setShowPendingTeamsModal] = useState(false);
  const [isUnderStandTeamworkCollapsed, setIsUnderStandTeamworkCollapsed] =
    useState(true);
  const currentPopoverShowing =
    popoverQueue.length > 0 ? popoverQueue[0] : null;

  // ------------------------ Effects ------------------------
  useEffect(() => {
    if (!selectedDepartmentId) return;
    dispatch(getDepartmentPendingTeamLeaders(selectedDepartmentId));
    dispatch(getDepartmentResultsInfo(selectedDepartmentId));
    dispatch(getDepartmentTrackingEvents(selectedDepartmentId));
  }, [dispatch, selectedDepartmentId]);

  useEffect(() => {
    if (!selectedDepartmentId || departmentInsightReport) return; // If we don't have a department selected then we don't want to call this or if we already have the report
    dispatch(
      getDepartmentInsightReport({ departmentId: Number(selectedDepartmentId) })
    );
  }, [dispatch, selectedDepartmentId, departmentInsightReport]);

  useEffect(() => {
    if (!selectedDepartmentId || departmentCardInfo) return;
    dispatch(getDepartmentCardInfo(selectedDepartmentId));
  }, [dispatch, selectedDepartmentId, departmentCardInfo]);

  useEffect(() => {
    if (departmentAssessmentInfoStatus === "idle") {
      dispatch(getDepartmentAssessmentCompletionInfo());
    }
  }, [departmentAssessmentInfoStatus, dispatch]);

  useEffect(() => {
    if (!selectedDepartmentId) return;
    if (checkIfAllPendingLeadersHaveBeenRemindedStatus === "idle") {
      dispatch(
        checkIfAllPendingLeadersHaveBeenReminded({
          departmentId: selectedDepartmentId,
        })
      );
    }
  }, [
    checkIfAllPendingLeadersHaveBeenRemindedStatus,
    dispatch,
    selectedDepartmentId,
  ]);

  useEffect(() => {
    if (
      !selectedDepartmentId ||
      !departmentTrackingEvents[selectedDepartmentId]
    ) {
      setPopoverQueue([]);
      return;
    }
    const showingPopovers = {
      dashboardTeamsInDepartmentPopup: false,
      dashboardTeam360ForDepartmentPopup: false,
      dashboardTeam360ResultsPopup: false,
    };

    departmentTrackingEvents[selectedDepartmentId].forEach(({ event }) => {
      switch (event) {
        case "dashboardTeam360ForDepartmentPopup":
        case "dashboardTeamsInDepartmentPopup":
        case "dashboardTeam360ResultsPopup":
          showingPopovers[event] = true;
          return;
        default:
      }
    });

    const newQueue = Object.entries(showingPopovers)
      .filter(([, showing]) => !showing)
      .map(([key]) => key as DepartmentLeaderPopup);
    setPopoverQueue(newQueue);
  }, [departmentTrackingEvents, selectedDepartmentId]);

  // These function calls have conditionals to prevent infinite loops
  useEffect(() => {
    dispatch(getAssessmentInformation());
  }, [dispatch]);

  // ------------------------ Variables ------------------------
  const chosenDepartment = selectedDepartmentId
    ? departments[selectedDepartmentId]
    : null;
  // const departmentName = chosenDepartment?.name;

  // ------------------------ Helper Functions ------------------------

  // const onCloseClick = (popoverKey: DepartmentLeaderPopup) => {
  //   // If there array of the queue is not empty then we just pop off the first element and set the queue to the new array
  //   if (popoverQueue.length > 0 && selectedDepartmentId) {
  //     setPopoverQueue(popoverQueue.slice(1));
  //     dispatch(
  //       trackDepartmentEvent({
  //         departmentId: selectedDepartmentId,
  //         event: popoverKey,
  //       })
  //     );
  //   }
  // };

  // const getPopoverTextAndTitle = (
  //   popoverKey: DepartmentLeaderPopup
  // ): { title: string; text: string } => {
  //   switch (popoverKey) {
  //     case "dashboardTeamsInDepartmentPopup":
  //       return {
  //         title: "Teams in this department",
  //         text: "Teams are the building blocks of your organization. Each team has a team leader and team members. Team leaders can invite team members to join their team.",
  //       };
  //     case "dashboardTeam360ForDepartmentPopup":
  //       return {
  //         title: "Department Results",
  //         text: "Results will be ready once 2 or more teams in your department complete their TEAMscans.",
  //       };
  //     case "dashboardTeam360ResultsPopup":
  //       return {
  //         title: "TEAMscan in this department",
  //         text: "A TEAMscan is a survey that team members take to provide feedback on their team leader. The results of the survey are used to create a report that helps team leaders improve their leadership skills.",
  //       };
  //   }
  // };

  // const getPopover = (popoverKey: DepartmentLeaderPopup) => {
  //   const { title, text } = getPopoverTextAndTitle(popoverKey);
  //   return (
  //     <Popover
  //       className="team-360-popover"
  //       style={{ minWidth: "400px", padding: "16px" }}
  //     >
  //       <div className="column-gap-16px">
  //         <div className="d-flex justify-content-between align-items-center">
  //           <p>
  //             <b>{title}</b>
  //           </p>
  //           <Button
  //             xIcon
  //             variant="secondary-gray"
  //             className="border-0"
  //             size="sm"
  //             onClick={() => onCloseClick(popoverKey)}
  //           />
  //         </div>
  //         <p>{text}</p>
  //         <div>
  //           <Button
  //             variant="secondary-blue"
  //             onClick={() => onCloseClick(popoverKey)}
  //           >
  //             Dismiss
  //           </Button>
  //         </div>
  //       </div>
  //     </Popover>
  //   );
  // };

  // const getDepartmentTeamsCountText = (
  //   teamCount: number = 0,
  //   memberCount: number = 0,
  //   teamLeadersCount: number = 0
  // ) => {
  //   if (teamCount) {
  //     return (
  //       <p>
  //         You have {teamCount} team{teamCount > 1 ? "s" : ""} with {memberCount}{" "}
  //         member{memberCount > 1 ? "s" : ""}. Click to see details for each
  //         team.
  //       </p>
  //     );
  //   } else {
  //     const leaderOrLeaders = teamLeadersCount === 1 ? "leader" : "leaders";
  //     return (
  //       <p>
  //         You've sent invitations to {teamLeadersCount} team {leaderOrLeaders}.
  //         Once they accept, they can create teams and invite team members.
  //       </p>
  //     );
  //   }
  // };
  // const getDepartmentTeamsFooterText = (
  //   teamCount: number = 0,
  //   memberCount: number = 0,
  //   teamLeadersCount: number = 0
  // ) => {
  //   let footerText = "";
  //   if (teamCount) {
  //     footerText += `${teamCount} total team${teamCount === 1 ? "" : "s"}`;
  //   }
  //   if (memberCount) {
  //     footerText += footerText ? ", " : "";
  //     footerText += `${memberCount} member${memberCount === 1 ? "" : "s"}`;
  //   }
  //   if (teamLeadersCount) {
  //     footerText += footerText ? ", " : "";
  //     footerText += `${teamLeadersCount} pending team${
  //       teamLeadersCount === 1 ? "" : "s"
  //     }`;
  //   }
  //   return footerText;
  // };

  // ----------------- V2 helpers -----------------

  const teamsThatCompletedTeam360 = useMemo(() => {
    const chosenDepartment = selectedDepartmentId
      ? departments[selectedDepartmentId]
      : null;
    let teamCount = 0;
    chosenDepartment?.teams?.forEach((teamId) => {
      if (!teamsMostRecentAssessments[teamId]?.totalInvited) return;
      teamCount++;
    });
    return teamCount;
  }, [teamsMostRecentAssessments, selectedDepartmentId, departments]);

  const getInfoCardTooltipContent = (type: "teams" | "people") => {
    return null;
  };

  const getInfoCardsV2 = () => {
    // If no teams or departments then we need to show the empty card to allow the admin to create a team or department
    if (
      !chosenDepartment?.teams?.length &&
      !chosenDepartment?.teamMembers?.length &&
      !departmentTeamLeaders?.length
    ) {
      return (
        <Card className="setup-empty-card">
          <div className="card-content">
            <h4>Set Up Your First Team</h4>
            <p
              style={{
                maxWidth: "320px",
              }}
            >
              Invite team leaders of teams in this department to get your
              department up and running.
            </p>
          </div>
          <div className="column-gap-20px">
            <Button
              onClick={() => {
                onShowInviteTeamLeaderModal();
              }}
            >
              Invite team leaders
            </Button>
          </div>
        </Card>
      );
    }

    // This should not be the pending members that have not joined the org
    // it should be all of the users who have not accepted to join their team and that team belongs to this department
    const pendingMembersForDepartment =
      chosenDepartment?.teamMembers?.filter((userAccountId) => {
        const user = usersById[userAccountId];
        return !user?.firstTeamsLogin;
      })?.length ?? 0;
    const totalTeams = chosenDepartment?.teams?.length ?? 0;
    const totalMembersInDepartment = chosenDepartment?.teamMembers?.length ?? 0;
    const totalAcceptedMembersInDepartment =
      totalMembersInDepartment - pendingMembersForDepartment;

    return (
      <div className="two-card-container">
        <InfoCardV2
          title="Teams In This Department"
          body={totalTeams}
          action={{
            onClick: () => onShowInviteTeamLeaderModal(),
            text: "Invite team leader",
            iconName: "plus",
          }}
          toolTipContent={getInfoCardTooltipContent("teams")}
          onPendingTextClick={
            departmentTeamLeaders?.length
              ? () => setShowPendingTeamsModal(true)
              : () => onShowInviteTeamLeaderModal()
          }
          pendingText={
            departmentTeamLeaders?.length
              ? `${departmentTeamLeaders?.length} pending team leaders`
              : "No pending team leaders"
          }
          onCardClick={
            totalTeams
              ? () => {
                  navigate(
                    `/Search/Teams?departmentId=${selectedDepartmentId}`
                  );
                }
              : undefined
          }
        />
        <InfoCardV2
          title="People In This Department"
          body={totalAcceptedMembersInDepartment}
          action={{
            onClick: () => {
              navigate(`/Search/People?departmentId=${selectedDepartmentId}`);
            },
            text: "View people",
            iconName: "arrow-right",
          }}
          pendingText={
            pendingMembersForDepartment
              ? `${pendingMembersForDepartment} invited but haven't joined yet`
              : "No pending invitations"
          }
          toolTipContent={getInfoCardTooltipContent("people")}
          onCardClick={
            totalMembersInDepartment
              ? () => {
                  navigate(
                    `/Search/People?departmentId=${selectedDepartmentId}`
                  );
                }
              : undefined
          }
        />
      </div>
    );
  };

  const getOrgTeam360Results = () => {
    // This means that no TEAMscan has been scheduled yet so we should not show this card at all.
    if (!departmentInsightReport?.completionInfo?.totalInvited) {
      return null;
    }

    if (!departmentInsightReport?.departmentScores?.overall) {
      return (
        <Card className="dashboard-container-header">
          <div className="dashboard-container-header__info">
            <div className="small-icon-square dark-grey">
              <FontAwesomeIcon icon="users" className="icon" />
            </div>
            <div className="column-gap-8px">
              <div className="row-gap-8px">
                <h3>
                  {chosenDepartment?.name ?? ""} TEAMscan results available
                </h3>
                <div className="label-tag grey m-0">Full department report</div>
              </div>
              <p>No responses so far from {teamsThatCompletedTeam360} teams</p>
            </div>
          </div>
          <div>
            <p>No results available</p>
          </div>
        </Card>
      );
    }

    // If pending should show ard indicating that its pending and should show the count of teams/departments invited
    // If we already scheduled a TEAMscan but no results are available, and the expiration date is passed then we just show not enough responses collected
    const companyTeam360Results =
      departmentInsightReport?.departmentScores?.overall;
    const smallSpeedChart = (
      <div style={{ height: "70px", width: "150px" }}>
        <HalfDonutChart
          canvasId="small-team360-preview-card-chart"
          currentVal={getTeam360Score(companyTeam360Results)}
          size="extra-small"
          borderWidth={2}
        />
      </div>
    );

    return (
      <Card className="dashboard-container-header">
        <div className="dashboard-container-header__info">
          <div className="small-icon-square blue">
            <FontAwesomeIcon icon="users" className="icon" />
          </div>
          <div className="column-gap-8px">
            <div className="row-gap-8px">
              <h3>{chosenDepartment?.name ?? ""} TEAMscan results available</h3>
              <div className="label-tag green m-0">Full department report</div>
            </div>
            <p>
              {departmentInsightReport?.completionInfo?.totalCompleted}{" "}
              completed responses from {teamsThatCompletedTeam360} teams
            </p>
          </div>
        </div>
        <div
          className="row-gap-20px align-items-center"
          style={{ gap: "40px" }}
        >
          {smallSpeedChart}
          <Button
            onClick={() => {
              navigate(`/DepartmentInsightReport/${selectedDepartmentId}`);
            }}
            style={{
              height: "fit-content",
            }}
          >
            View Results
          </Button>
        </div>
      </Card>
    );
  };

  const getTeam360Card = () => {
    const isLaunchTeam360Disabled = !!(
      selectedDepartmentId &&
      assessmentInformation?.departments?.[selectedDepartmentId]
        ?.activeAssessment
    );

    return (
      <Card className="column-gap-20px">
        <div className="dashboard-container-header">
          <div className="dashboard-container-header__info">
            <div className="small-icon-square">
              <FontAwesomeIcon icon={["fas", "poll-people"]} className="icon" />
            </div>
            <div>
              <h3>
                Understand teamwork and empower higher-performing managers
              </h3>
              <p>
                Launch and manage the TEAMscan teamwork survey and view results
              </p>
            </div>
          </div>
          <div className="row-gap-16px">
            <Button
              variant="secondary-blue"
              onClick={() => {
                dispatch(
                  setShowModal({
                    eventType: "generalTeam360Information",
                    hideActionButton: true,
                  })
                );
              }}
            >
              See details
            </Button>
            <Button
              onClick={() => {
                setIsUnderStandTeamworkCollapsed(
                  !isUnderStandTeamworkCollapsed
                );
              }}
            >
              {isUnderStandTeamworkCollapsed ? "See" : "Hide"} actions
            </Button>
          </div>
        </div>
        <Collapse in={!isUnderStandTeamworkCollapsed}>
          <div className="three-card-container">
            <QuickActionCard
              title="Launch TEAMscan survey"
              description="Launch the survey for one or more teams or your entire department."
              actionButton={{
                onClick: () => {
                  selectedDepartmentId &&
                    dispatch(
                      showScheduleAssessmentModalForDepartmentId(
                        selectedDepartmentId
                      )
                    );
                },
                text: "Launch survey",
              }}
              iconName="rocket-launch"
              isWholeCardDisabled={isLaunchTeam360Disabled}
              cornerTag={
                isLaunchTeam360Disabled ? (
                  <div className="label-tag green">Already active</div>
                ) : null
              }
              imageIcon={
                <img
                  alt={AssessmentMap[1].name}
                  src={AssessmentMap[1].assessmentIcon}
                />
              }
            />
            <QuickActionCard
              title="View TEAMscan Results"
              description="View results from past surveys for teams in this department."
              actionButton={{
                onClick: () => {
                  setShowResultsModal(true);
                },
                text: "View results",
              }}
              iconName="square-poll-vertical"
            />
            <QuickActionCard
              title="Manage TEAMscan Surveys"
              description="View 1-time and recurring surveys. Adjust survey period, reminders, and more."
              actionButton={{
                onClick: () => {
                  navigate("/Department/" + selectedDepartmentId + "/Surveys");
                },
                text: "Manage surveys",
              }}
              iconName="wrench"
            />
          </div>
        </Collapse>
      </Card>
    );
  };

  if (!selectedDepartmentId) return null;

  if (departmentTrackingEventsStatus === "loading") return <Loading />;

  return (
    <>
      <SurveyResultsModal
        onHide={() => setShowResultsModal(false)}
        show={showResultsModal}
        teamIds={chosenDepartment?.teams ?? []}
        showManageSurveysButton
        getEmptyCard={() => (
          <div className="empty-card">
            <span>No TEAMscan Results In This Department</span>
            <p>
              No teams have an active TEAMscan currently. Launch a TEAMscan for
              your department or one or more teams now.
            </p>
            <div className="action-buttons">
              <Button
                onClick={() => {
                  if (selectedDepartmentId) {
                    dispatch(
                      showScheduleAssessmentModalForDepartmentId(
                        selectedDepartmentId
                      )
                    );
                    setShowResultsModal(false);
                  }
                }}
              >
                Launch TEAMscan
              </Button>
              <Button
                variant="secondary-blue"
                onClick={() => {
                  dispatch(
                    setShowModal({
                      eventType: "generalTeam360Information",
                      hideActionButton: true,
                    })
                  );
                  setShowResultsModal(false);
                }}
              >
                See details
              </Button>
            </div>
          </div>
        )}
      />
      <PendingTeamsModal
        onHide={() => setShowPendingTeamsModal(false)}
        show={showPendingTeamsModal}
        onShowInviteTeamLeaderModal={() => {
          setShowPendingTeamsModal(false);
          onShowInviteTeamLeaderModal();
        }}
        pendingTeamLeaders={departmentTeamLeaders ?? []}
      />
      <div className="dashboard-container">
        {getInfoCardsV2()}
        <h2>Surveys</h2>
        {getOrgTeam360Results()}
        {getTeam360Card()}
      </div>
    </>
  );
};

export default memo(DepartmentLeaderOnboarding);
