import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { memo } from 'react';

interface Props {
  bars: {
    value: number;
    color: string;
    hasStar?: boolean;
    borderColor?: string;
  }[];
}

const DynamicStackBarChart = ({ bars }: Props) => {
  const getGridColumnValue = (value: number) =>
    value ? ` ${value}fr` : ' 25px';

  const getBar = ({
    value,
    color,
    hasStar,
    borderColor,
  }: {
    value: number;
    color: string;
    hasStar?: boolean;
    borderColor?: string;
  }) => {
    return (
      <div key={color} style={{ backgroundColor: color, borderColor }}>
        <p>{value}</p>
        {hasStar ? <FontAwesomeIcon icon="star" className="ms-1" /> : null}
      </div>
    );
  };

  const barElms: React.JSX.Element[] = [];
  let gridTemplateColumns = '';
  bars.forEach((bar) => {
    gridTemplateColumns += getGridColumnValue(bar.value);
    barElms.push(getBar(bar));
  });
  return (
    <div
      style={{
        gridTemplateColumns,
      }}
      className="stack-bar-chart"
    >
      {barElms}
    </div>
  );
};

export default memo(DynamicStackBarChart);
