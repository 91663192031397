import AvatarCircle from "app/components/AvatarCircle";
import { useAppSelector } from "utils/redux/hooks";
import {
  selectCompanyInfo,
  // selectCurrentUserAccountId,
  selectTeamsByTeamId,
  // selectSampleUsersInfoById,
} from "app/containers/Global/slice";
import Button from "app/storybookComponents/Button";
import { selectCompanyGuide } from "app/containers/CompanyGuide/slice";
import { selectDepartments } from "app/containers/AdminConsole/slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  SurveyEventFrequency,
  SurveyEventTypes,
  SurveyKeepAssessmentOpenOption,
} from "app/components/Team360Assessment/types";
import CircleEditButton from "app/storybookComponents/Button/CircleEditButton";
import { getTeam360EndDate } from "app/components/Team360Assessment/helpers";
import { getDepartmentPreviewSentTo, getTeamPreviewSentTo } from "./helpers";

interface Props {
  startDate: Date;
  assessmentId: number;
  teamIds?: number[];
  departmentIds?: number[];
  repeating?: string;
  postedDate?: string;
  customMessage?: string;
  onEditSendingTo?: () => void;
  onEditAdvancedSettings?: () => void;
  addCustomMessage?: () => void;
  frequency?: SurveyEventFrequency;
  eventType?: SurveyEventTypes;
  keepOpenFor?: SurveyKeepAssessmentOpenOption;
}

export default function SurveyPreviewCard({
  teamIds,
  departmentIds,
  eventType,
  onEditSendingTo,
  onEditAdvancedSettings,
  frequency,
  keepOpenFor,
  startDate,
}: Readonly<Props>) {
  // const usersByIdInfo = useAppSelector(selectSampleUsersInfoById);
  const companyInfo = useAppSelector(selectCompanyInfo);
  const teamsById = useAppSelector(selectTeamsByTeamId);
  const companyGuide = useAppSelector(selectCompanyGuide);
  const departments = useAppSelector(selectDepartments);
  // const loggedInUserAccountId = useAppSelector(selectCurrentUserAccountId);

  const getHeader = () => {
    // Before only showing 1 we need to check if there are multiple teams if so then we need to show the avatars of the teams
    if (teamIds) {
      const { avatars, description, title } = getTeamPreviewSentTo(
        teamsById,
        teamIds
      );
      return (
        <div className="row-gap-12px">
          <div className="multi-avatar-container">{avatars}</div>
          <div className="column-gap-4px">
            <p>
              <b>{title}</b>
            </p>
            <p className="small-body-text">{description}</p>
          </div>
        </div>
      );
    }
    // If there are departments then we need to show the avatars of the departments
    if (departmentIds) {
      const { avatars, description, title } = getDepartmentPreviewSentTo(
        departments,
        departmentIds
      );
      return (
        <div className="row-gap-12px">
          <div className="multi-avatar-container">{avatars}</div>
          <div className="column-gap-4px">
            <p>
              <b>{title}</b>
            </p>
            <p className="small-body-text">{description}</p>
          </div>
        </div>
      );
    }

    if (companyInfo) {
      const companyProfilePic = companyGuide?.profilePicture?.picture || "";
      return (
        <div className="row-gap-12px">
          <div>
            <AvatarCircle
              name={companyInfo?.companyName}
              profilePicture={companyProfilePic}
              size="small"
            />
          </div>
          <div className="column-gap-4px">
            <p>
              <b>{companyInfo?.companyName}</b>
            </p>
            <p className="small-body-text">
              Sending to the entire organization
            </p>
          </div>
        </div>
      );
    }
    return null;
  };

  const startDateText = new Intl.DateTimeFormat("en-US", {
    month: "short",
    day: "numeric",
  }).format(startDate);

  const endDateText =
    keepOpenFor &&
    new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
    }).format(getTeam360EndDate(startDate, keepOpenFor));
  return (
    <div className="survey-card">
      <div className="column-gap-12px">
        <p className="small-body-text grey-text">
          You are sending this survey to:
        </p>
        <div className="section-header d-flex row-gap-20px">
          {getHeader()}
          <CircleEditButton onClick={() => onEditSendingTo?.()} />
        </div>
      </div>
      <hr className="m-0" />
      <div className="survey-card-footer">
        <div>
          <div>
            <p className="small-body-text grey-text">Frequency</p>
            <div className="row-gap-8px align-items-center">
              <FontAwesomeIcon icon={["fal", "clock"]} />
              <p>
                Occurs {eventType === "One-time event" ? "Once" : frequency},
                open from {startDateText} to {endDateText}
              </p>
              <CircleEditButton onClick={() => onEditAdvancedSettings?.()} />
            </div>
          </div>
        </div>
        <div>
          <p>20 questions (+2 optional)</p>
        </div>
      </div>
    </div>
  );
}
