import React, {
  memo,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import Button from "app/storybookComponents/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import TeamAssessmentIcon from "app/components/Team360Assessment/TeamAssessmentIcon";
import { Card } from "react-bootstrap";
import HelpPopover from "app/components/Team360Assessment/HelpPopover";
import { getTeam360FactorOrDimensionDescription } from "app/components/Team360Assessment/helpers";

import {
  SkillGuideNavSchema,
  TDimension,
  TSkill,
  isTDimensionProp,
  isTSkillProp,
} from "./types";
import {
  SKILL_GUIDE_TEMPLATE,
  CONSTRUCTS_GUIDE_TEMPLATE,
  CONSTRUCT_SKILLS_IN_THIS_AREA_KEY,
} from "./constants";
import { getText } from "./helpers";
import AddSkillModal from "./Modals/AddSkillModal";
import EditSkillContentModal from "./Modals/EditSkillContentModal";
import {
  submitSkillFeedback,
  submitDimensionFeedback,
  selectSubmitSkillFeedbackStatus,
  selectDimensionSectionFeedbackSubmitted,
  selectSubmitDimensionFeedbackStatus,
  selectSkillSectionFeedbackSubmitted,
  selectUpdateSkillGuideStatus,
  updateSkillGuide,
  updateDimensionGuide,
  selectUpdateDimensionGuideStatus,
  selectSkillsGuide,
  selectHasLibraryEditAccess,
} from "./slice";
import { useNavigate } from "react-router-dom";

interface Props {
  factor: string;
  skill: TSkill | TDimension;
  type?: "Skill" | "Dimension";
}

const SkillPage = ({ skill, type = "Skill", factor }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const submitFeedbackStatus = useAppSelector(
    type === "Skill"
      ? selectSubmitSkillFeedbackStatus
      : selectSubmitDimensionFeedbackStatus
  );
  const feedbackSubmitted = useAppSelector(
    type === "Skill"
      ? selectSkillSectionFeedbackSubmitted
      : selectDimensionSectionFeedbackSubmitted
  );
  const updateSkillGuideStatus = useAppSelector(selectUpdateSkillGuideStatus);
  const updateDimensionGuideStatus = useAppSelector(
    selectUpdateDimensionGuideStatus
  );
  const userHasLibraryEditAccess = useAppSelector(selectHasLibraryEditAccess);

  const skillsGuide = useAppSelector(selectSkillsGuide);
  const [showEditSkillModal, setShowEditSkillModal] = useState<null | {
    propertyKey: string;
    propertyTitle: string;
  }>(null);
  const [showAddSkillModal, setShowAddSkillModal] = useState(false);
  const [selectedSection, setSelectedSection] = useState<string>(
    Object.keys(SKILL_GUIDE_TEMPLATE)[0]
  );
  const [selectedSchema, setSelectedSchema] = useState<SkillGuideNavSchema>({});

  useLayoutEffect(() => {
    if (type === "Skill") {
      setSelectedSchema(SKILL_GUIDE_TEMPLATE);
    } else if (type === "Dimension") {
      setSelectedSchema(CONSTRUCTS_GUIDE_TEMPLATE);
    }
  }, [type]);

  useEffect(() => {
    setSelectedSection(Object.keys(selectedSchema)[0]);
  }, [selectedSchema]);

  useEffect(() => {
    if (updateSkillGuideStatus === "succeeded") {
      setShowEditSkillModal(null);
    }
  }, [updateSkillGuideStatus]);

  useEffect(() => {
    if (updateDimensionGuideStatus === "succeeded") {
      setShowEditSkillModal(null);
    }
  }, [updateDimensionGuideStatus]);

  const getNav = () =>
    Object.keys(selectedSchema).map((key) => (
      <div
        key={key}
        onClick={() => setSelectedSection(key)}
        role="button"
        className={key === selectedSection ? "active" : ""}
      >
        <span>{key}</span>
      </div>
    ));

  const getNavContent = () => {
    if (!selectedSection) return null;
    let content: null | React.JSX.Element[] | React.JSX.Element = null;

    // Here we need to add a guard clause such that if the selectedSection is Skills in this area and the skill is a dimension, we need to render all of the skills in the dimension
    if (
      type === "Dimension" &&
      selectedSection === CONSTRUCT_SKILLS_IN_THIS_AREA_KEY &&
      "name" in skill
    ) {
      content = getDimensionSkillCards(skill.name);
    } else {
      content = selectedSchema[selectedSection]?.map((section) =>
        getSection(section)
      );
    }

    return (
      <div>
        <h2>{selectedSection}</h2>
        <p>{content}</p>
      </div>
    );
  };

  const getDimensionSkillCards = (dimensionName: string) => {
    const returnArr: React.JSX.Element[] = [];
    if (skillsGuide[factor][dimensionName]) {
      Object.values(skillsGuide[factor][dimensionName]).forEach((skill) =>
        returnArr.push(getSkillsCard(skill))
      );
    }
    return <div className="skills-cards-container">{returnArr}</div>;
  };

  const getSkillsCard = (skillObj: TSkill) => (
    <Card key={skillObj.skill}>
      <h4>{skillObj.skill}</h4>
      <p>{skillObj.description}</p>
      <Button
        variant="secondary-blue"
        onClick={() => navigate(`/SkillsGuide?skillId=${skillObj.id}`)}
      >
        See skill guide <FontAwesomeIcon icon="arrow-right" />
      </Button>
    </Card>
  );

  const getSection = ({
    key,
    title,
    icon,
    isList,
    hasBoldedHeader,
  }: {
    title: string;
    key: string;
    icon?: IconName;
    isList?: boolean;
    hasBoldedHeader?: boolean;
  }) => {
    return (
      <div key={key}>
        <div className="skill-guide-section-header">
          <div>
            {icon ? (
              <div className="small-icon-square">
                <FontAwesomeIcon icon={["fal", icon]} />
              </div>
            ) : null}
            <h3>{title}</h3>
          </div>
          {userHasLibraryEditAccess ? (
            <div
              className="edit-circle"
              onClick={() =>
                setShowEditSkillModal({
                  propertyKey: key,
                  propertyTitle: title,
                })
              }
              role="button"
            >
              <FontAwesomeIcon icon="edit" />
            </div>
          ) : null}
        </div>
        {getText(skill, key, isList, hasBoldedHeader)}
      </div>
    );
  };

  const getQuote = () => {
    if ("quote" in skill) {
      return (
        <div className="row-gap-20px align-items-center">
          <p>{skill.quote}</p>
          {userHasLibraryEditAccess ? (
            <div
              className="edit-circle"
              onClick={() =>
                setShowEditSkillModal({
                  propertyKey: "quote",
                  propertyTitle: "Quote",
                })
              }
              role="button"
            >
              <FontAwesomeIcon icon="edit" />
            </div>
          ) : null}
        </div>
      );
    }
    return null;
  };

  const getTitle = () => {
    if (type === "Skill") {
      return "Skill Guide: " + skill.title;
    }
    return skill.title;
  };

  const onThumbClick = (isUp: boolean) => {
    if (type === "Skill") {
      dispatch(
        submitSkillFeedback({
          skillGuideId: skill.id,
          payload: {
            isHelpful: isUp ? 1 : 0,
            section: selectedSection,
          },
        })
      );
    } else if (type === "Dimension") {
      dispatch(
        submitDimensionFeedback({
          dimensionGuideId: skill.id,
          payload: {
            isHelpful: isUp ? 1 : 0,
            section: selectedSection,
          },
        })
      );
    }
  };

  const onEditSave = (updatedContent: string) => {
    if (!showEditSkillModal?.propertyKey) return;
    if (type === "Skill" && isTSkillProp(showEditSkillModal.propertyKey)) {
      dispatch(
        updateSkillGuide({
          payload: { [showEditSkillModal.propertyKey]: updatedContent },
          skillGuideId: skill.id,
        })
      );
    }

    if (
      type === "Dimension" &&
      isTDimensionProp(showEditSkillModal.propertyKey)
    ) {
      dispatch(
        updateDimensionGuide({
          payload: { [showEditSkillModal.propertyKey]: updatedContent },
          dimensionId: skill.id,
          dimensionName: (skill as TDimension).name,
        })
      );
    }
  };

  return (
    <div className="skills-page column-gap-12px">
      {type === "Skill" ? (
        <AddSkillModal
          skill={skill as TSkill}
          showModal={showAddSkillModal}
          hideModal={() => setShowAddSkillModal(false)}
        />
      ) : null}
      <EditSkillContentModal
        showModal={!!showEditSkillModal}
        propertyKey={showEditSkillModal?.propertyKey}
        propertyTitle={showEditSkillModal?.propertyTitle}
        hideModal={() => setShowEditSkillModal(null)}
        skill={skill}
        onEditSave={onEditSave}
        isLoading={
          updateSkillGuideStatus === "loading" ||
          updateDimensionGuideStatus === "loading"
        }
      />
      <div className="skill-page-header">
        <div className="small-icon-square">
          <TeamAssessmentIcon name={factor} color="navy" />
        </div>
        <span style={{ fontSize: "16px" }}>
          <b>{factor}</b>
        </span>
        <span className={`${type} list-span`}>{type} Guide</span>
        <HelpPopover
          title={factor}
          text={getTeam360FactorOrDimensionDescription(factor)}
        />
      </div>
      <h1>{getTitle()}</h1>
      {getQuote()}
      <div className="skills-page-content">
        <div className="navigation-box">{getNav()}</div>
        <div className="nav-content">
          {getNavContent()}
          <div className="buttons-container">
            {!feedbackSubmitted[skill.id]?.includes(selectedSection) ? (
              <div className="thumb-buttons">
                <Button
                  variant="secondary-blue"
                  onClick={() => onThumbClick(true)}
                  disabled={submitFeedbackStatus === "loading"}
                >
                  <FontAwesomeIcon icon="thumbs-up" /> I found this helpful
                </Button>
                <Button
                  variant="secondary-blue"
                  disabled={submitFeedbackStatus === "loading"}
                  onClick={() => onThumbClick(false)}
                >
                  <FontAwesomeIcon icon="thumbs-down" /> I didn't find this
                  helpful
                </Button>
              </div>
            ) : (
              <div>Thanks for your feedback</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(SkillPage);
