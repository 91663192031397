import { useState, useEffect, useRef } from "react";
import { Card, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  selectChatbotMessages,
  selectThreadId,
  selectSendMessageStatus,
  sendMessage,
  resetState,
} from "./slice";
import Input from "./Input";
import Prompt from "./Prompt";
import MessageBubble from "./MessageBubble";
import Robot from "resources/images/robot.svg";

interface Props {
  readonly isOpen: boolean;
  readonly closeChatbot: () => void;
}

export default function Chatbot({ isOpen, closeChatbot }: Props) {
  const dispatch = useAppDispatch();
  const messages = useAppSelector(selectChatbotMessages);
  const threadId = useAppSelector(selectThreadId);
  const sendMessageStatus = useAppSelector(selectSendMessageStatus);
  const [message, setMessage] = useState("");
  const [justSentMessage, setJustSentMessage] = useState("");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (sendMessageStatus !== "loading") {
      return;
    }

    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [sendMessageStatus]);

  if (!isOpen) return null;

  const reversedMessages = messages ? [...messages].reverse() : [];

  const showSuggestedPrompts = !message && !messages && !justSentMessage;

  const handleSendMessage = (messageToSend: string) => {
    if (!messageToSend) return;
    dispatch(sendMessage({ message: messageToSend, threadId }));
    setJustSentMessage(messageToSend);
    setMessage("");
  };

  const getSuggestedPrompts = () => {
    const suggestedPrompts = [
      "Can you help me with something I'm struggling with as a team leader right now?",
      "Can you help me be a better manager for a specific team member or direct report?",
      "Can you help me design a 1-week experiment for improving our team's performance?",
    ];
    return (
      <div className="chatbot-prompt-container">
        {suggestedPrompts.map((prompt, index) => (
          <Prompt
            key={index}
            prompt={prompt}
            handleSendMessage={handleSendMessage}
          />
        ))}
      </div>
    );
  };

  const onDropdownSelect = (e: string | null) => {
    switch (e) {
      case "reset": {
        dispatch(resetState());
        setJustSentMessage("");
        return;
      }
    }
  };

  const getChatBotMessageContainerHeight = () => {
    if (showSuggestedPrompts && isFullScreen) return "40vh";
    if (showSuggestedPrompts && !isFullScreen) return "30vh";
    if (isFullScreen) return "65vh";

    return "60vh";
  };

  return (
    <>
      {isFullScreen && (
        <div
          className="backdrop"
          onClick={() => setIsFullScreen(false)}
          role="button"
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              setIsFullScreen(false);
            }
          }}
        />
      )}
      <Card
        className={isFullScreen ? "chatbot-card-full-screen" : "chatbot-card"}
        style={{
          position: "fixed",
          height: isFullScreen ? "90vh" : "80vh",
        }}
      >
        <div className="chatbot-header">
          <h2>
            CoachBot{" "}
            <FontAwesomeIcon
              onClick={() => setIsFullScreen(!isFullScreen)}
              icon={
                isFullScreen
                  ? "down-left-and-up-right-to-center"
                  : "up-right-and-down-left-from-center"
              }
              className="chatbot-fullscreen-toggle"
            ></FontAwesomeIcon>
          </h2>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Dropdown
              onSelect={onDropdownSelect}
              style={{ paddingRight: "10px" }}
            >
              <Dropdown.Toggle
                variant="outline-primary"
                id="dropdown-basic"
                className="no-caret"
              >
                <FontAwesomeIcon icon="ellipsis" size="sm" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="flip">
                <Dropdown.Item eventKey="reset" disabled={!threadId}>
                  Reset Chat History
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <FontAwesomeIcon
              icon="xmark"
              className="chatbot-x-mark"
              onClick={() => {
                closeChatbot();
                setIsFullScreen(false);
              }}
            />
          </div>
        </div>

        {/* Render messages */}
        <div
          className="chatbot-message-container"
          style={{
            height: getChatBotMessageContainerHeight(),
            flexDirection: isFullScreen ? "column" : "column-reverse",
          }}
        >
          <div className="inner-chatbot-message-container">
            {sendMessageStatus === "loading" && (
              <div className="assistant-bubble-row">
                <img src={Robot} alt="Criteria" className="assistant-avatar" />
                <div className="assistant-bubble" ref={messagesEndRef}>
                  <div className="typing-dots">
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                  </div>
                </div>
              </div>
            )}
            {sendMessageStatus === "loading" && (
              <MessageBubble type="user" message={justSentMessage} />
            )}
            {reversedMessages.map((message, index) => (
              <MessageBubble
                key={index}
                type={message.role}
                message={message.text}
              />
            ))}
            <MessageBubble
              type="assistant"
              message="Hey there! I’m CoachBot. I’m here to help with anything you need in the workplace. What can I help you with?"
            />
          </div>
        </div>
        {showSuggestedPrompts && getSuggestedPrompts()}
        <div
          style={{
            width: "100%",
          }}
        >
          <Input
            message={message}
            isFullScreen={isFullScreen}
            setMessage={setMessage}
            handleSendMessage={handleSendMessage}
          />
          {isFullScreen && (
            <div className="chatbot-links">
              To explore more help articles, visit the{" "}
              <a href="https://www.criteriacorp.com/resources">Help Center</a>.
            </div>
          )}
          {isFullScreen && (
            <div className="chatbot-links">
              If you need additional help, you can email your Support Team at{" "}
              <a href="mailto:techsupport@criteriacorp.com">
                techsupport@criteriacorp.com
              </a>
            </div>
          )}
        </div>
      </Card>
    </>
  );
}
