import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';
import NoResultsIcon from 'resources/images/no-results.jpg';

interface Props {
  message: string;
  link?: string; // Might change this from link to onLinkClick so that we can download a file or just pass in the file
  linkLabel?: string;
}

export default function NotAvailable({ message, link, linkLabel }: Props) {
  return (
    <Card className="not-available-card">
      <img src={NoResultsIcon} alt="no results" height="80px" width="80px" />
      <p>{message}</p>
      {link && linkLabel ? (
        <a target="_blank" rel="noopener noreferrer" href={link}>
          <FontAwesomeIcon icon={['fal', 'file']} /> {linkLabel}
        </a>
      ) : null}
    </Card>
  );
}
