import { Section } from 'app/components/Modules/types';
import { userGuideSectionOrderPriority } from './constants';

export const sortSections = (sections: Section[]): Section[] => {
  return sections.sort((a, b) => {
    const aHasPriority = a.title.toLowerCase() in userGuideSectionOrderPriority;
    const bHasPriority = b.title.toLowerCase() in userGuideSectionOrderPriority;

    if (aHasPriority && bHasPriority) {
      return (
        userGuideSectionOrderPriority[a.title.toLowerCase()] -
        userGuideSectionOrderPriority[b.title.toLowerCase()]
      );
    } else if (aHasPriority && !bHasPriority) {
      return -1; // move section a higher in the sort order
    } else if (!aHasPriority && bHasPriority) {
      return 1; // move section b higher in the sort order
    } else {
      return a.sectionId - b.sectionId; // maintain the original order
    }
  });
};
