import Chart from 'chart.js/auto';
import { memo, useLayoutEffect, useRef } from 'react';
import { dialPlugin, getDataColors, getRadiusAndOffset } from './helpers';

interface Props {
  canvasId: string;
  data?: number[];
  currentVal?: number;
  label?: string;
  size?: 'small' | 'medium' | 'large' | 'extra-small';
  borderWidth?: number;
}

const HalfDonutChart = ({
  canvasId,
  label = 'Overall Score',
  data = [20, 20, 20, 20, 20], // This will determine how big each section of the speedometer is
  currentVal = 37,
  size = 'small',
  borderWidth = 5,
}: Props) => {
  const chartRefInstance = useRef<{ chart: Chart | null }>({ chart: null });
  const chartRef = useRef<HTMLCanvasElement>(null);

  useLayoutEffect(() => {
    if (!chartRef?.current) {
      return;
    }
    const { cutout } = getRadiusAndOffset(size);

    chartRefInstance.current.chart = new Chart(chartRef.current, {
      type: 'doughnut',
      data: {
        datasets: [
          {
            label: '# of Votes',
            data: data,
            backgroundColor: getDataColors(data, currentVal),
            borderColor: 'white',
            borderWidth,
            borderRadius: 200,
            // @ts-ignore
            needleValue: currentVal, // Ignoring this error because it's a custom property
            size,
          },
        ],
      },
      plugins: [dialPlugin],
      options: {
        rotation: -90,
        circumference: 180,
        cutout: `${cutout}%`,
        plugins: {
          legend: {
            display: false,
          },
        },
        events: [],
      },
    }) as Chart;

    const chart = chartRefInstance.current.chart;
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [data, currentVal, borderWidth, size]);

  const getChartLabel = () => {
    if (size === 'extra-small') {
      return (
        <>
          <span className="score-number">{currentVal}</span>
          <div>
            <div className="score-label-extra-small" />
          </div>
        </>
      );
    }
    return (
      <>
        <span>{label}</span>
        <span className="score-number">{currentVal}</span>
        <span>out of 100</span>
      </>
    );
  };

  return (
    <div className={`half-donut-chart-wrapper ${size}`}>
      <div className="half-donut-chart">
        <canvas id={canvasId} ref={chartRef} />
        <div className="half-donut-text">{getChartLabel()}</div>
      </div>
    </div>
  );
};

export default memo(HalfDonutChart);
