import { useAppSelector } from "utils/redux/hooks";
import Button from "app/storybookComponents/Button";
import { selectTeamsByTeamId } from "app/containers/Global/slice";
import { useEffect, useMemo, useState } from "react";
import SmallTeam360Card from "./SmallTeam360Card";
import { selectNotifications, selectPendingAssessments } from "../slice";
import { getNotificationTeamMap } from "../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircularTabs from "app/storybookComponents/Tabs/CircularTabs";
import { selectTeamsMostRecentAssessmentsInstancesEntities } from "app/containers/Assessment/slice";
import { getDaysRemaining } from "app/containers/Assessment/helpers";

interface Props {
  userTeamIds: number[];
  maxShowing?: number;
  hasDepartmentLeadAccess?: boolean;
  hideTodoTab?: boolean;
}
const MAX_CARDS_SHOWING_WHEN_NOT_EXPANDED = 5;
const EMPTY_TABS: { [tabLabel: string]: JSX.Element[] } = {
  "To Do": [],
  Pending: [],
  Results: [],
  "No Results": [],
};

const Team360TeamsCard = ({
  userTeamIds,
  maxShowing = MAX_CARDS_SHOWING_WHEN_NOT_EXPANDED,
  hasDepartmentLeadAccess,
  hideTodoTab,
}: Props) => {
  // ---------------------- Selectors ----------------------
  const pendingAssessments = useAppSelector(selectPendingAssessments);
  const notifications = useAppSelector(selectNotifications);
  const teamsByTeamId = useAppSelector(selectTeamsByTeamId);
  const teamsMostRecentAssessments = useAppSelector(
    selectTeamsMostRecentAssessmentsInstancesEntities
  );

  // ---------------------- States ----------------------
  const [tabShowing, setTabShowing] = useState("All");
  const [isExpanded, setIsExpanded] = useState(false);
  const [tabs, setTabs] = useState(EMPTY_TABS);

  const notificationMap = useMemo(
    () => getNotificationTeamMap(notifications),
    [notifications]
  );

  // ---------------------- Effects ----------------------
  useEffect(() => {
    const todo: JSX.Element[] = [];
    const pending: JSX.Element[] = [];
    const results: JSX.Element[] = [];
    const noResults: JSX.Element[] = [];

    userTeamIds?.forEach((teamId) => {
      const mostRecentAssessment = teamsMostRecentAssessments[teamId];

      // If the team does not have any invited members then no need to show it as part of our list
      if (
        !mostRecentAssessment ||
        !teamsByTeamId[teamId] ||
        mostRecentAssessment.totalInvited === 0
      ) {
        return;
      }

      const notificationObj = notificationMap[teamId];
      const pendingAssessmentEventId = notificationObj?.assessmentEventId;

      const teamCard = (
        <SmallTeam360Card
          teamId={teamId}
          pendingAssessmentEventId={pendingAssessmentEventId ?? undefined}
          key={teamId}
          hasDepartmentLeadAccess={hasDepartmentLeadAccess}
        />
      );

      if (pendingAssessmentEventId && !hideTodoTab) {
        return todo.push(teamCard);
      }

      if (mostRecentAssessment?.hasEnoughResults) {
        return results.push(teamCard);
      }

      const daysRemaining = getDaysRemaining(mostRecentAssessment.endDate);
      if (daysRemaining && daysRemaining > 0) {
        return pending.push(teamCard);
      }
      return noResults.push(teamCard);
    });

    setTabs({
      "To Do": todo,
      Pending: pending,
      Results: results,
      "No Results": noResults,
    });
  }, [
    userTeamIds,
    teamsMostRecentAssessments,
    teamsByTeamId,
    notificationMap,
    pendingAssessments,
    hasDepartmentLeadAccess,
    hideTodoTab,
  ]);

  const getCollapseButton = () => {
    if (arrayLength < maxShowing) {
      return null;
    }

    if (isExpanded) {
      return (
        <Button
          variant="tertiary-blue"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          Collapse
          <FontAwesomeIcon icon={`caret-up`} className="ms-2" />
        </Button>
      );
    }
    return (
      <Button
        variant="tertiary-blue"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        See all surveys
        <FontAwesomeIcon icon={`caret-down`} className="ms-2" />
      </Button>
    );
  };

  const getTabArray = () => {
    const tabArray: { label: string; count: number; isDisabled: boolean }[] = [
      {
        label: "All",
        count: allCount,
        isDisabled: allCount === 0,
      },
    ];

    if (!hideTodoTab) {
      tabArray.push({
        label: "To Do",
        count: todoCount,
        isDisabled: todoCount === 0,
      });
    }

    return [
      ...tabArray,
      {
        label: "Results",
        count: resultsCount,
        isDisabled: resultsCount === 0,
      },
      {
        label: "Pending",
        count: pendingCount,
        isDisabled: pendingCount === 0,
      },
      {
        label: "No Results",
        count: noResultsCount,
        isDisabled: noResultsCount === 0,
      },
    ];
  };

  const returnArr =
    tabShowing === "All"
      ? [
          ...tabs["To Do"],
          ...tabs.Pending,
          ...tabs.Results,
          ...tabs["No Results"],
        ]
      : tabs[tabShowing];
  const arrayLength = returnArr.length;
  const todoCount = tabs["To Do"].length;
  const pendingCount = tabs.Pending.length;
  const resultsCount = tabs.Results.length;
  const noResultsCount = tabs["No Results"].length;
  const allCount = todoCount + pendingCount + resultsCount + noResultsCount;

  if (arrayLength === 0) {
    return null;
  }

  return (
    <>
      <CircularTabs
        selectedTab={tabShowing}
        onTabSelected={(tab) => setTabShowing(tab)}
        tabs={getTabArray()}
      />
      <div className="column-gap-12px">
        {returnArr.slice(0, !isExpanded ? maxShowing : arrayLength)}
      </div>
      {getCollapseButton()}
    </>
  );
};

export default Team360TeamsCard;
