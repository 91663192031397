import { ChangeEvent, RefObject } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Modal as BootstrapModal,
  Alert,
  Container,
  Button,
  Row,
  Col,
} from 'react-bootstrap';
import { useAppSelector } from 'utils/redux/hooks';
import { selectUpdatingTablesStatus } from './slice';
import {
  ModalType,
  TableStructureError,
  UploadState,
  SelectedTable,
} from './types';
import TableCard from 'app/components/TableCard';
import Loading from 'app/storybookComponents/Loading';

interface Props {
  modalToShow: ModalType;
  onHide: () => void;
  selectedTable: SelectedTable;
  handleUpload: (handleUpload: ChangeEvent<HTMLInputElement>) => void;
  structureError: TableStructureError;
  uploadState: UploadState;
  onUpdateTable: () => void;
  inputRef: RefObject<HTMLInputElement>;
  rowsAdded: number | null;
}

export default function Modal({
  modalToShow,
  onHide,
  selectedTable,
  handleUpload,
  structureError,
  uploadState,
  onUpdateTable,
  inputRef,
  rowsAdded,
}: Props) {
  const updateTableStatus = useAppSelector(selectUpdatingTablesStatus);

  const getModalContent = () => {
    switch (modalToShow) {
      case 'UploadData':
        return (
          <>
            <h2>Upload Data</h2>
            {getUploadDataContent()}
          </>
        );
      default:
        return null;
    }
  };

  const getUploadDataContent = () => {
    if (updateTableStatus === 'failed') {
      return (
        <Row>
          <Col className="upload-status-card">
            <FontAwesomeIcon
              icon="circle-xmark"
              color="red"
              size="2x"
              className="mb-2"
            />
            <p>Update was not successful, please try again</p>
            <div>
              <Button>Try Again</Button>
              <Button variant="outline-primary" onClick={onHide}>
                Close
              </Button>
            </div>
          </Col>
        </Row>
      );
    }

    if (updateTableStatus === 'succeeded') {
      return (
        <Row>
          <Col className="upload-status-card">
            <FontAwesomeIcon
              icon="check-circle"
              color="green"
              size="2x"
              className="mb-2"
            />
            <p>Update has been successfully completed</p>
            <Button variant="outline-primary" onClick={onHide}>
              Close
            </Button>
          </Col>
        </Row>
      );
    }

    if (updateTableStatus === 'loading') {
      return (
        <Row>
          <Col className="upload-status-card">
            <Loading />
          </Col>
        </Row>
      );
    }

    return (
      <>
        <Alert variant={'warning'}>
          <p className="mb-0">
            To update existing data tables (adding new rows, editing existing
            content, etc.), select an existing table and upload the updated .csv
            file with the same header names and number of headers.
          </p>
          <p className="mb-0">
            Note: This will not interrupt any existing users in their sessions,
            but will take effect for any new sessions.
          </p>
        </Alert>

        <h3>Upload a Data File (.csv)</h3>
        <input
          type="file"
          id="fileUpload"
          className="csv-upload"
          accept=".csv"
          onChange={(e) => handleUpload(e)}
          ref={inputRef}
        />
        {inputRef?.current?.files?.length !== undefined &&
        inputRef.current.files.length !== 0 ? (
          <FontAwesomeIcon icon="check" color="green" />
        ) : null}
        <p style={{ color: '#86888b', marginTop: '10px' }}>
          File size limit: 5 MB
        </p>

        <h3 className="mb-3">
          {structureError ? 'Table Structure Check' : 'Table You Are Changing:'}
        </h3>
        {selectedTable && (
          <TableCard
            tableTags={selectedTable.tableTags}
            tableData={selectedTable.tableData}
            tableName={selectedTable.tableName}
            tableDescription={selectedTable.tableDescription}
            structureError={structureError}
            uploadState={uploadState}
            rowsAdded={rowsAdded}
          />
        )}
        <div className="mt-2">
          <Button
            className="me-2"
            disabled={uploadState !== 'CheckingSuccess'}
            onClick={() => {
              onUpdateTable();
            }}
          >
            Upload Data
          </Button>
          <Button
            variant="outline-primary"
            className="me-2"
            onClick={() => {
              onHide();
            }}
          >
            Cancel
          </Button>
        </div>
      </>
    );
  };

  return (
    <BootstrapModal
      size={'lg'}
      onHide={onHide}
      show={modalToShow !== null}
      className="admin-console-modal"
    >
      <Container className="p-4">{getModalContent()}</Container>
    </BootstrapModal>
  );
}
