import { MemberScore } from './types';
import { getTraitNameAndIcon } from './helper-functions';
import { memo } from 'react';
import notableTraitIcon from 'resources/icons/notable-trait.svg';
import WorkplaceInsightBar from './WorkplaceInsightBar';

interface Props {
  teamMemberScores: MemberScore[];
  traitAbbrev: string;
  isNotableTrait?: boolean;
}

const TraitCard = ({
  traitAbbrev,
  isNotableTrait,
  teamMemberScores,
}: Props) => {
  const traitObject = getTraitNameAndIcon(traitAbbrev);
  if (!traitObject) return null;
  const { traitName, traitIcon, lowLabel, highLabel } = traitObject;

  const memberArr = teamMemberScores.map((member) => ({
    score: Number(member[traitAbbrev as keyof MemberScore]) || 0,
    userAccountId: member.userAccountId,
  }));

  return (
    <div className="trait-card">
      {isNotableTrait ? (
        <img
          src={notableTraitIcon}
          alt="notable trait"
          className="notable-trait-icon"
        />
      ) : null}
      <div className="trait-card-header">
        <img src={traitIcon} alt={traitName} />
        <h3>{traitName}</h3>
      </div>
      <WorkplaceInsightBar
        memberScores={memberArr}
        lowLabel={lowLabel}
        highLabel={highLabel}
      />
    </div>
  );
};

export default memo(TraitCard);
