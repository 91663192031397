import { useParams } from "react-router-dom";
import DepartmentSurveys from "./DepartmentSurveys";
import NavigateBackButton from "app/components/NavigateBackButton";
import { Card } from "react-bootstrap";

export default function Departments() {
  const { departmentId } = useParams();
  return (
    <div className="guide page">
      <div className="mb-3">
        <NavigateBackButton />
      </div>
      <Card className="padding-20px">
        <DepartmentSurveys departmentId={Number(departmentId)} />
      </Card>
    </div>
  );
}
