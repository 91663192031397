import { createEntityAdapter } from "@reduxjs/toolkit";
import {
  AssessmentInstance,
  AssessmentSeries,
  TeamMostRecentInstanceInfo,
  DepartmentMostRecentInstanceInfo,
} from "./types";

export const assessmentInstanceAdapter =
  createEntityAdapter<AssessmentInstance>({
    selectId: (e) => e.assessmentInstanceId,
    sortComparer: (a, b) => a.assessmentInstanceId - b.assessmentInstanceId, // This is what decides the order of entites in the array
  });

export const assessmentSeriesAdapter = createEntityAdapter<AssessmentSeries>({
  selectId: (e) => e.assessmentId,
  sortComparer: (a, b) => a.assessmentId - b.assessmentId, // This is what decides the order of entites in the array
});

export const teamsMostRecentInstanceAdapter =
  createEntityAdapter<TeamMostRecentInstanceInfo>({
    selectId: (e) => e.teamId,
    sortComparer: (a, b) => a.teamId - b.teamId,
  });

export const departmentsMostRecentInstanceAdapter =
  createEntityAdapter<DepartmentMostRecentInstanceInfo>({
    selectId: (e) => e.departmentId,
    sortComparer: (a, b) => a.departmentId - b.departmentId,
  });
