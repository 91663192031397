import Button from "app/storybookComponents/Button";
import {
  SurveyEventFrequency,
  SurveyEventTypes,
  SurveyKeepAssessmentOpenOption,
} from "app/components/Team360Assessment/types";
import { responseStatus } from "utils/types";
import SurveyPreviewCard from "./SurveyPreviewCard";
import { LaunchAssessmentState, LaunchAssessmentInviteType } from "./types";

interface Props {
  setState: (state: LaunchAssessmentState) => void;
  setAdvancedOpened: (opened: boolean) => void;
  inviteType: LaunchAssessmentInviteType;
  selectedId: number[];
  customMessage: string | false;
  eventType: SurveyEventTypes;
  frequency: SurveyEventFrequency;
  addCustomMessage: () => void;
  assessmentId: number;
  dates: {
    startDate: Date;
    endDate: Date;
  };
  keepOpenFor: SurveyKeepAssessmentOpenOption;
  schedulingTeam360Status: responseStatus;
  onCancel: () => void;
  onLaunch: () => void;
}

export default function LaunchAssessmentPreviewBody({
  setState,
  setAdvancedOpened,
  inviteType,
  selectedId,
  eventType,
  customMessage,
  frequency,
  dates,
  addCustomMessage,
  assessmentId,
  keepOpenFor,
  schedulingTeam360Status,
  onCancel,
  onLaunch,
}: Readonly<Props>) {
  // const getEntityType = () => {
  //   if (inviteType === 'Entire Organization') return 'organization';
  //   if (inviteType === 'Select Team(s)') return 'team';
  //   if (inviteType === 'Specific Department(s)') return 'department';
  // };

  return (
    <>
      <div className="column-gap-20px">
        <p className="launch-surveys__preview-text grey-text">
          Launch now or select a future launch date. Edit which members will
          receive this survey and how long this survey period is open for.
        </p>
        <SurveyPreviewCard
          onEditSendingTo={() => {
            setState("Invite");
          }}
          onEditAdvancedSettings={() => {
            setState("Configure");
            setAdvancedOpened(true);
          }}
          teamIds={inviteType === "Select Team(s)" ? selectedId : undefined}
          departmentIds={
            inviteType === "Specific Department(s)" ? selectedId : undefined
          }
          eventType={eventType}
          customMessage={customMessage || ""}
          startDate={dates.startDate}
          addCustomMessage={() => {
            setState("Configure");
            addCustomMessage();
          }}
          assessmentId={assessmentId}
          frequency={frequency}
          keepOpenFor={keepOpenFor}
        />
      </div>
      <div className="d-flex justify-content-between">
        <Button variant="secondary-gray" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={schedulingTeam360Status === "loading"}
          onClick={onLaunch}
        >
          Launch
        </Button>
      </div>
    </>
  );
}
