import { ClipLoader } from "react-spinners";

interface Props {
  color?: string;
  size?: string | number;
  speedMultiplier?: number;
  borderWidth?: number | string;
}

export default function Loading({
  color = "#36d7b7",
  size,
  speedMultiplier = 0.5,
  borderWidth,
}: Props) {
  return (
    <ClipLoader
      color={color}
      speedMultiplier={speedMultiplier}
      size={size}
      cssOverride={{
        borderWidth,
      }}
    />
  );
}
