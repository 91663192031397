import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  readonly message: string;
  readonly isFullScreen: boolean;
  readonly setMessage: (message: string) => void;
  readonly handleSendMessage: (message: string) => void;
}

export default function Input({
  message,
  isFullScreen,
  setMessage,
  handleSendMessage,
}: Props) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <textarea
        placeholder="Type a response..."
        value={message}
        onChange={(event) => setMessage(event.target.value)}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            handleSendMessage(message);
            event.preventDefault();
          }
        }}
        className="chatbot-input"
        style={{
          width: isFullScreen ? "80%" : "320px",
        }}
      />
      <FontAwesomeIcon
        icon="arrow-right"
        onClick={() => handleSendMessage(message)}
        style={{
          marginLeft: isFullScreen ? "20px" : "10px",
          color: message ? "#202D63" : "#D9DEF4",
          cursor: message ? "pointer" : "default",
        }}
      />
    </div>
  );
}
