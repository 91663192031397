import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { request } from "utils/request";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import {
  GetTiTablesResponse,
  PutTIAssessmentItemsPayload,
  TableRowData,
} from "./types";

// ------------------ State Type/Structure ------------------
export interface AdminConsoleState {
  tables: null | GetTiTablesResponse;
  gettingTableStatus: responseStatus;
  updatingTablesStatus: responseStatus;
}

// ------------------ InitialState ------------------
const initialState: AdminConsoleState = {
  tables: null,
  gettingTableStatus: "idle",
  updatingTablesStatus: "idle",
};

// ------------------ Asynchronous API calls ------------------
export const getTables = createAsyncThunk(
  "hiddenAdminConsole/getTables",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/TIAssessmentItems/tables`;
    return await request(requestUrl);
  }
);

export const updateTables = createAsyncThunk(
  "hiddenAdminConsole/updateTables",
  async ({
    tableName,
    payload,
  }: {
    tableName: string;
    payload: PutTIAssessmentItemsPayload;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/TIAssessmentItems/${tableName}`;
    const updateTablesResponse = (await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify(payload),
    })) as TableRowData;

    return { response: updateTablesResponse, tableName };
  }
);

// ------------------ Beginning of Slice Definition ------------------
export const hiddenAdminConsoleSlice = createSlice({
  name: "hiddenAdminConsole",
  initialState,
  reducers: {
    clearUpdatingTableStatus: (state) => {
      state.updatingTablesStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTables.pending, (state) => {
        state.gettingTableStatus = "loading";
      })
      .addCase(getTables.fulfilled, (state, action) => {
        state.tables = action.payload as GetTiTablesResponse;
        state.gettingTableStatus = "succeeded";
      })
      .addCase(getTables.rejected, (state) => {
        state.gettingTableStatus = "failed";
      })
      .addCase(updateTables.pending, (state) => {
        state.updatingTablesStatus = "loading";
      })
      .addCase(
        updateTables.fulfilled,
        (
          state,
          action: PayloadAction<{ response: TableRowData; tableName: string }>
        ) => {
          if (!action.payload.tableName || state.tables?.tables === undefined) {
            return;
          }

          return {
            ...state,
            updatingTablesStatus: "succeeded",
            tables: {
              tables: state.tables.tables.map((item) => {
                if (item.name === action.payload.tableName) {
                  return { ...item, content: action.payload.response };
                }
                return item;
              }),
            },
          };
        }
      )
      .addCase(updateTables.rejected, (state) => {
        state.updatingTablesStatus = "failed";
      });
  },
});

// ------------------ Selectors ------------------
export const selectTables = (state: RootState) =>
  state.hiddenAdminConsole.tables;
export const selectGetTablesStatus = (state: RootState) =>
  state.hiddenAdminConsole.gettingTableStatus;
export const selectUpdatingTablesStatus = (state: RootState) =>
  state.hiddenAdminConsole.updatingTablesStatus;

export const { clearUpdatingTableStatus } = hiddenAdminConsoleSlice.actions;
export default hiddenAdminConsoleSlice.reducer;
