import { ReactElement } from 'react';
import { Table, Alert } from 'react-bootstrap';
import { TableStructureError } from 'app/containers/HiddenAdminConsole/types';

export interface Props {
  structureError?: TableStructureError;
}

export default function index({ structureError }: Props) {
  if (!structureError) {
    return null;
  }

  const getTableHeaders = (
    tableColumns: string[],
    mismatchPositions?: number[]
  ): ReactElement[] => {
    const tableHeaders: ReactElement[] = [];
    const copy = mismatchPositions && [...mismatchPositions]; // If this is causing problems just change to use an includes.
    tableColumns.forEach((columnName, idx) => {
      const warning = copy && copy[0] === idx ? 'warning' : '';

      tableHeaders.push(
        <th key={idx} className={`admin-console-table-header ${warning}`}>
          {columnName}
        </th>
      );

      // If we just colored the cell remove that element from the list
      if (warning && copy) {
        copy.shift();
      }
    });
    return tableHeaders;
  };

  const { existingColumns, uploadedColumns, mismatchPositions } =
    structureError;
  const existingHeaders = getTableHeaders(existingColumns, mismatchPositions);
  const uploadedHeaders = getTableHeaders(uploadedColumns, mismatchPositions);

  return (
    <>
      <Alert variant="danger">
        <p className="mb-0">
          <span className="fw-bold">
            {mismatchPositions.length} column names do not match:
          </span>{' '}
          Please fix these errors and re-upload your file.
        </p>
      </Alert>
      <div style={{ overflowX: 'scroll' }}>
        <p>Existing Table</p>
        <Table bordered>
          <thead>
            <tr>{existingHeaders}</tr>
          </thead>
        </Table>
        <p>Uploaded Table</p>
        <Table bordered>
          <thead>
            <tr>{uploadedHeaders}</tr>
          </thead>
        </Table>
      </div>
    </>
  );
}
