import { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import Button from "app/storybookComponents/Button";
import Select, { components } from "react-select";
import { Team } from "app/containers/Global/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppSelector } from "utils/redux/hooks";
import { selectDepartments } from "app/containers/AdminConsole/slice";

interface Props {
  teamInfo?: { teamName: string; departmentId?: number };
  modalShowing: null | "Name" | "Department" | "Who Can Invite";
  hideModal: () => void;
  onSave: (payload: Partial<Team>) => void;
  loading?: boolean;
}

export default function TeamSettingsModal({
  hideModal,
  modalShowing,
  teamInfo,
  onSave,
  loading,
}: Props) {
  const departments = useAppSelector(selectDepartments);
  const [teamName, setTeamName] = useState(teamInfo?.teamName || "");
  const [departmentId, setDepartmentId] = useState(teamInfo?.departmentId);

  const getModalTitle = () => {
    switch (modalShowing) {
      case "Who Can Invite":
        return "Who can invite";
      case "Name":
      case "Department":
      default:
        return `Edit ${modalShowing}`;
    }
  };
  const getModalContent = () => {
    switch (modalShowing) {
      case "Name":
        return getTeamNameForm();
      case "Department":
        return getDepartmentForm();
      case "Who Can Invite":
        return getWhoCanInviteUserForm();
      default:
        return null;
    }
  };

  const getTeamNameForm = () => (
    <>
      <Form.Group>
        <Form.Label>Team Name</Form.Label>
        <Form.Control
          placeholder="Team's name"
          name="teamName"
          required
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
        />
      </Form.Group>
    </>
  );

  const getDepartmentForm = () => {
    let value = null;
    const options = Object.values(departments).map((department) => {
      if (departmentId === department.departmentId) {
        value = {
          value: departmentId,
          label: department.name,
        };
      }

      return {
        value: department.departmentId,
        label: department.name,
      };
    });

    return (
      <>
        <Form.Group>
          <Form.Label>Department</Form.Label>
          <Select
            components={{
              DropdownIndicator: (props: any) => (
                <components.DropdownIndicator {...props}>
                  <FontAwesomeIcon icon="caret-down" />
                </components.DropdownIndicator>
              ),
              IndicatorSeparator: () => null,
            }}
            value={value}
            options={options}
            isSearchable={false}
            onChange={(newValue) => {
              setDepartmentId(newValue?.value);
            }}
          />
        </Form.Group>
      </>
    );
  };

  const getWhoCanInviteUserForm = () => {
    return (
      <Select
        noOptionsMessage={() => null}
        components={{
          DropdownIndicator: null,
        }}
        // inputValue={inputText}
        isClearable
        isMulti
        isSearchable
        // options={options}
        // onChange={(newValue) => setSelectValues(newValue)}
        // onInputChange={(newValue) => setInputText(newValue)}
        // onKeyDown={selectKeyDown}
        placeholder="Search by name or email"
        // value={selectValues}
        styles={{
          menu: (base: any) => ({
            ...base,
            marginTop: 0,
          }),
        }}
        // formatOptionLabel={(member) => {
        //   const memberInfo = memberMap.get(member.value);
        //   if (memberInfo) {
        //     return (
        //       <div className="member-option">
        //         {memberInfo.avatarCircle}
        //         <div className="member-info">
        //           <span className="member-name">{memberInfo.fullName}</span>
        //           <span className="member-email">
        //             {memberInfo.emailAddress}
        //           </span>
        //         </div>
        //       </div>
        //     );
        //   }
        //   return (
        //     <div>
        //       <span>{member.label}</span>
        //     </div>
        //   );
        // }}
      />
    );
  };

  const handleSave = () => {
    switch (modalShowing) {
      case "Name":
        return onSave({ teamName });
      case "Department":
        return onSave({ departmentId });
      default:
        return null;
    }
  };

  return (
    <Modal
      show={modalShowing !== null}
      onHide={hideModal}
      className="simple-modal"
    >
      <div className="modal-title-row">
        <h2>{getModalTitle()}</h2>
        <Button
          onClick={hideModal}
          variant={"secondary-blue"}
          style={{ border: "none" }}
          xIcon
        />
      </div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSave();
        }}
      >
        {getModalContent()}
        <div
          style={{ marginTop: "20px", display: "flex", justifyContent: "end" }}
        >
          <Button type="submit" disabled={loading}>
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
