import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  hasPendingNotifications?: boolean;
  numberCount?: number | null;
  color?: string;
}

export default function NotificationBellWithNumber({
  hasPendingNotifications,
  numberCount = null,
  color,
}: Props) {
  return (
    <div className="notification-bell-with-number">
      {hasPendingNotifications ? (
        <span className="notification-count">
          {numberCount && numberCount > 10 ? `9+` : numberCount}
        </span>
      ) : null}
      <FontAwesomeIcon icon="bell" color={color} />
    </div>
  );
}
