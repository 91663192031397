import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { request } from "utils/request";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import { OpenAIGeneratedText } from "app/components/OpenAIInternalTools/OpenAIModule/types";

// ------------------ State Type/Structure ------------------
export interface TipState {
  tip: null | OpenAIGeneratedText;
  gettingTipStatus: responseStatus;
}

const initialState: TipState = {
  tip: null,
  gettingTipStatus: "idle",
};

export const getTip = createAsyncThunk("generateTip", async () => {
  const requestUrl = `${REACT_APP_API_URL}/talentInsights/generate`;
  return (await request(requestUrl, {
    method: "POST",
    body: JSON.stringify({ payload: { promptId: 3 } }),
  })) as OpenAIGeneratedText;
});

export const tipSlice = createSlice({
  name: "tip",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTip.pending, (state) => {
        state.gettingTipStatus = "loading";
      })
      .addCase(
        getTip.fulfilled,
        (state, action: PayloadAction<OpenAIGeneratedText>) => {
          return {
            ...state,
            gettingTipStatus: "succeeded",
            tip: { ...action.payload },
          };
        }
      )
      .addCase(getTip.rejected, (state) => {
        state.gettingTipStatus = "failed";
      });
  },
});

export const selectTip = (state: RootState) => state.tip.tip;
export const selectGetTipStatus = (state: RootState) =>
  state.tip.gettingTipStatus;

export default tipSlice.reducer;
