import { Modal } from 'react-bootstrap';
import Button from 'app/storybookComponents/Button';
import TextArea from 'app/storybookComponents/Forms/TextArea';
import TextInput from 'app/storybookComponents/Forms/TextInput';
import { useLayoutEffect, useState } from 'react';

interface Props {
  hideModal: () => void;
  showModal?: boolean;
  skill: {
    [key: string]: any;
  };
  propertyKey?: string;
  propertyTitle?: string;
  isLoading?: boolean;
  onEditSave: (updatedContent: string) => void;
}

export default function EditSkillContentModal({
  hideModal,
  showModal,
  skill,
  propertyKey,
  propertyTitle,
  onEditSave,
  isLoading,
}: Props) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [maxDescription, setMaxDescription] = useState(500);

  useLayoutEffect(() => {
    if (!skill || !propertyKey || typeof skill[propertyKey] !== 'string') {
      return;
    }

    setDescription(skill[propertyKey] as string);
    setMaxDescription(
      500 > (skill[propertyKey] as string).length
        ? 500
        : Math.round((skill[propertyKey] as string).length / 10) * 10
    );
  }, [skill, propertyKey]);

  useLayoutEffect(() => {
    if (!propertyTitle) {
      return;
    }

    setTitle(propertyTitle);
  }, [skill, propertyTitle]);

  return (
    <Modal
      className="simple-modal"
      onHide={hideModal}
      show={showModal}
      size="lg"
    >
      <div className="modal-title-row">
        <h2>Edit Content</h2>
        <Button
          onClick={() => hideModal()}
          variant={'secondary-blue'}
          style={{ border: 'none', width: 'auto' }}
          xIcon
        />
      </div>
      <div className="edit-skill-content-modal-body">
        <TextInput
          inputText={
            title +
            ' (Read Only, for now since the values are being stored in the frontend)'
          }
          inputLabel={'Title'}
          onTextChange={setTitle}
          controlId={'editTitle'}
          placeholder={'Enter Title'}
          disabled
        />
        <TextArea
          inputText={description}
          inputLabel={'Description'}
          onTextChange={setDescription}
          controlId={'editDescription'}
          placeholder={'Enter Description'}
          maxLength={maxDescription}
          // isResizable
        />
      </div>
      <div className="d-flex justify-content-between">
        <Button variant="secondary-gray" onClick={hideModal}>
          Cancel
        </Button>
        <Button onClick={() => onEditSave(description)} disabled={isLoading}>
          Save
        </Button>
      </div>
    </Modal>
  );
}
