import { Card } from 'react-bootstrap';
import TeamAssessmentIcon from './TeamAssessmentIcon';

const SECTION_DATA = [
  {
    name: 'Target',
    description: 'Defining clear, impactful, and achievable goals',
  },
  {
    name: 'Empower',
    description: 'Creating a safe and supported environment',
  },
  {
    name: 'Align',
    description: 'Maintaining clear communication',
  },
  {
    name: 'Monitor',
    description: 'Tracking potential threats and opportunities',
  },
];

interface Props {
  customGap?: number;
}

export default function Team360SectionInSurvey({ customGap }: Props) {
  return (
    <div
      className="team-360-section-in-survey"
      style={{
        gap: customGap ? `${customGap}px` : '16px',
      }}
    >
      {SECTION_DATA.map(({ name, description }) => {
        return (
          <Card
            key={name}
            className="row-gap-16px"
            style={{
              padding: '8px',
            }}
          >
            <div className="assessment-icon-holder">
              <TeamAssessmentIcon name={name} color="navy" />
            </div>
            <div>
              <h4>{name}</h4>
              <p
                className="small-body-text"
                style={{
                  color: '#53565a',
                }}
              >
                {description}
              </p>
            </div>
          </Card>
        );
      })}
    </div>
  );
}
