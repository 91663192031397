import { useEffect, useState, SyntheticEvent, ChangeEvent } from "react";
import { useParams } from "react-router-dom";
import { Card, Form, Button } from "react-bootstrap";
import { useAppSelector, useAppDispatch } from "utils/redux/hooks";
import {
  checkInviteLink,
  checkIfEmailExist,
  selectCheckInviteLinkResponse,
  selectCheckInviteLinkStatus,
  selectCheckIfEmailExistStatus,
  clearEmailExistStatus,
  clearCheckIfEmailExistStatus,
} from "./slice";
import DefaultJoinLogo from "resources/images/Join-Default-Logo.png";
import Loading from "app/storybookComponents/Loading";

export default function JoinCard() {
  const dispatch = useAppDispatch();
  const { inviteCode } = useParams();

  // ------------------------ useAppSelector ------------------------
  const checkInviteLinkStatus = useAppSelector(selectCheckInviteLinkStatus);
  const checkInviteLinkResponse = useAppSelector(selectCheckInviteLinkResponse);
  const checkIfEmailExistStatus = useAppSelector(selectCheckIfEmailExistStatus);

  // ------------------------ States ------------------------
  const [email, setEmail] = useState("");
  const [domainError, setDomainError] = useState<
    "No Domain" | "Invalid Domain" | null
  >(null);

  useEffect(() => {
    if (inviteCode === undefined) {
      return;
    }

    dispatch(checkInviteLink(inviteCode));
  }, [inviteCode, dispatch]);

  useEffect(() => {
    if (checkInviteLinkStatus === "failed") {
      // Kick them out OR should we show sorry invalid code
    }
  }, [checkInviteLinkStatus]);

  if (inviteCode === undefined) {
    return <p>No Code Given</p>;
  }
  // Placed right after hooks since hooks shouldn't be in conditionals
  if (checkInviteLinkStatus === "failed") {
    // if for whatever reason invite code is undefined make sure not render anything
    return <p>Sorry Invalid Invite Code</p>;
  }

  const submitHandler = (e: SyntheticEvent) => {
    e.preventDefault();

    const [, currentEmailDomain] = email.match(/.*@(\S+)/) || [];

    if (currentEmailDomain === undefined) {
      setDomainError("No Domain");
      return; // Error saying that no domain was found
    }

    // Noticed that the domains are case sensitive so will need to be case s
    const validDomain = isValidDomain(currentEmailDomain);

    if (!validDomain) {
      setDomainError("Invalid Domain");
      return;
    }

    dispatch(checkIfEmailExist({ code: inviteCode, emailAddress: email }));
  };

  const isValidDomain = (currentEmailDomain: string) => {
    if (checkInviteLinkResponse === null) {
      return false;
    }
    switch (checkInviteLinkResponse?.emailSetting) {
      case 1:
        // allow all domains
        return true;
      case 2:
        // only allow original domain
        return currentEmailDomain === checkInviteLinkResponse.originalDomain;
      case 3:
        // only allow original domain + domains in a custom list
        const customDomains = JSON.parse(
          checkInviteLinkResponse.customDomainList
        );

        return (
          currentEmailDomain === checkInviteLinkResponse.originalDomain ||
          customDomains.includes(currentEmailDomain)
        );
    }

    return false;
  };

  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (checkForErrors()) {
      clearErrors();
    }

    setEmail(e.target.value);
  };

  const checkForErrors = () =>
    domainError || checkIfEmailExistStatus === "failed";

  const clearErrors = () => {
    setDomainError(null);
    dispatch(clearEmailExistStatus());
  };

  const getJoinContent = () => {
    return (
      <div>
        <div className="align-self-center">
          <img
            src={DefaultJoinLogo}
            alt="Company Logo"
            style={{ width: "200px" }}
          />
        </div>
        <div>
          <h1>Join Develop by Criteria</h1>
        </div>
        <div className="mb-2">
          <Form
            onSubmit={submitHandler}
            noValidate
            className={"needs-validation"}
          >
            <Form.Group className="has-error">
              <Form.Label>Enter your work email to get started:</Form.Label>
              <Form.Control
                placeholder="Example@gmail.com"
                type="email"
                value={email}
                onChange={onEmailChange}
                className={`mb-2${checkForErrors() ? " is-invalid" : ""}`}
                disabled={checkIfEmailExistStatus === "loading"}
              />
              <div className="invalid-feedback">
                {domainError ||
                  "Looks like this account already exists. Please login to continue"}
              </div>
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              className="w-100"
              disabled={checkIfEmailExistStatus === "loading"}
            >
              Next
            </Button>
          </Form>
        </div>
        <div>
          <p>Already have an account? Go to Login Page</p>
        </div>
      </div>
    );
  };

  const getVerifyContent = () => {
    return (
      <div style={{ gap: "20px" }}>
        <div className="align-self-center">
          <img
            src={DefaultJoinLogo}
            alt="Company Logo"
            style={{ width: "200px" }}
          />
        </div>
        <div className="column-gap-20px">
          <div className="column-gap-12px">
            <h1>Check your email to verify</h1>
            <p>We’ve sent an email to {email} with a verification link</p>
          </div>
          <p>
            Didn’t receive an email from Criteria? Please check your spam folder
          </p>
          <Button
            variant="outline-primary"
            type="submit"
            className="w-100"
            onClick={() => dispatch(clearCheckIfEmailExistStatus())}
          >
            Resend Email
          </Button>
        </div>
      </div>
    );
  };

  const getContent = () => {
    if (checkInviteLinkStatus === "loading") {
      return <Loading />;
    }
    if (checkIfEmailExistStatus === "succeeded") {
      return getVerifyContent();
    }
    return getJoinContent();
  };

  return <Card className="join-card mt-5">{getContent()}</Card>;
}
