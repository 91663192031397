import { Card, Collapse } from "react-bootstrap";
import Button from "app/storybookComponents/Button";
import { VarianceReportAnalyticsText } from "app/containers/AdminConsole/types";
import { Team360AssessmentResult } from "app/components/Team360Assessment/types";
import Team360LowAgreementSkillCards from "app/components/Team360Assessment/Team360LowAgreementSkillCards";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  title?: string;
  varianceReport?: VarianceReportAnalyticsText | null;
  memberScores?: Team360AssessmentResult | null;
  teamLeaderScores?: Team360AssessmentResult | null;
  onSeeAgreementReport?: () => void;
  isExpanded?: boolean;
  setIsExpanded?: (isExpanded: boolean) => void;
}

export default function ManagersVsTeamMembers({
  varianceReport,
  title = "Manager vs. Team Member Views",
  memberScores,
  teamLeaderScores,
  onSeeAgreementReport,
  isExpanded = false,
  setIsExpanded,
}: Props) {
  if (!varianceReport) return null;
  return (
    <Card
      className="p-4 column-gap-20px"
      role={isExpanded && setIsExpanded ? undefined : "button"}
      onClick={() => {
        if (isExpanded) return;
        setIsExpanded?.(true);
      }}
    >
      <div className="d-flex justify-content-between align-items-start">
        <div>
          <h2>{title}</h2>
        </div>
        {setIsExpanded ? (
          <div>
            <Button
              onClick={() => {
                setIsExpanded?.(!isExpanded);
              }}
              variant="secondary-blue"
              className="border-0"
            >
              {!isExpanded ? "See details" : "Collapse"}
              <FontAwesomeIcon
                icon={`caret-${isExpanded ? "up" : "down"}`}
                className="ms-2"
              />
            </Button>
          </div>
        ) : null}
      </div>
      <Collapse in={isExpanded}>
        <div className="column-gap-20px">
          <p>{varianceReport.managersVsMembersText}</p>

          <div className="short-graph">
            <div className="short-graph-bar your-score">
              <span
                style={{
                  width: `${varianceReport.averageManagerScore}%`,
                }}
                className="bar"
              />
              <span className="ms-2">Average manager rating:</span>
              <span className="fw-bold">
                {varianceReport.averageManagerScore}
              </span>
            </div>
            <div className="short-graph-bar your-score">
              <span
                style={{
                  width: `${varianceReport.averageTeamMemberScore}%`,
                }}
                className="bar"
              />
              <span className="ms-2">Average team member rating:</span>
              <span className="fw-bold">
                {varianceReport.averageTeamMemberScore}
              </span>
            </div>
          </div>

          {memberScores || teamLeaderScores ? (
            <>
              {/* <hr className="m-0" /> */}
              <div>
                <Team360LowAgreementSkillCards
                  score1={memberScores}
                  score2={teamLeaderScores}
                  descriptionText="Here are 2 dimensions where team leaders and team members had the most differing views:"
                />
              </div>
            </>
          ) : null}

          {onSeeAgreementReport ? (
            <div>
              <Button onClick={onSeeAgreementReport} variant="secondary-blue">
                See agreement report
              </Button>
            </div>
          ) : null}
        </div>
      </Collapse>
    </Card>
  );
}
