import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { request } from "utils/request";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import { OpenAIPrompts } from "./types";

export interface OpenAIPromptEditorState {
  openAIPrompts: OpenAIPrompts | null;
  gettingOpenAIPromptsStatus: responseStatus;
  examplePromptResponse: string | null;
  gettingExamplePromptResponseStatus: responseStatus;
  updatingOpenAIPromptStatus: responseStatus;
}

const initialState: OpenAIPromptEditorState = {
  openAIPrompts: null,
  gettingOpenAIPromptsStatus: "idle",
  examplePromptResponse: null,
  gettingExamplePromptResponseStatus: "idle",
  updatingOpenAIPromptStatus: "idle",
};

export const getOpenAIPrompts = createAsyncThunk(
  "getOpenAIPrompts",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/openAI/prompts`;
    return (await request(requestUrl, {
      method: "GET",
    })) as OpenAIPrompts;
  }
);

export const updateOpenAIPrompt = createAsyncThunk(
  "updateOpenAIPrompt",
  async (payload: {
    promptId: number;
    backgroundContext: string;
    prompt: string;
    tokensRequired: number;
    modelType: string;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/openAI/prompts/${payload.promptId}`;
    return (await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify(payload),
    })) as OpenAIPrompts;
  }
);

export const generateExamplePromptResponse = createAsyncThunk(
  "generateExamplePromptResponse",
  async (payload: {
    backgroundContext: string;
    prompt: string;
    tokensRequired: number;
    modelType: string;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/prompts/generate`;
    return (await request(requestUrl, {
      method: "POST",
      body: JSON.stringify(payload),
    })) as string;
  }
);

export const OpenAIPromptEditorStateSlice = createSlice({
  name: "OpenAIPromptEditorState",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOpenAIPrompts.pending, (state) => {
        state.gettingOpenAIPromptsStatus = "loading";
      })
      .addCase(getOpenAIPrompts.fulfilled, (state, action) => {
        state.gettingOpenAIPromptsStatus = "succeeded";
        state.openAIPrompts = action.payload;
      })
      .addCase(getOpenAIPrompts.rejected, (state) => {
        state.gettingOpenAIPromptsStatus = "failed";
      })
      .addCase(generateExamplePromptResponse.pending, (state) => {
        state.gettingExamplePromptResponseStatus = "loading";
      })
      .addCase(generateExamplePromptResponse.fulfilled, (state, action) => {
        state.gettingExamplePromptResponseStatus = "succeeded";
        state.examplePromptResponse = action.payload;
      })
      .addCase(generateExamplePromptResponse.rejected, (state) => {
        state.gettingExamplePromptResponseStatus = "failed";
      })
      .addCase(updateOpenAIPrompt.pending, (state) => {
        state.updatingOpenAIPromptStatus = "loading";
      })
      .addCase(updateOpenAIPrompt.fulfilled, (state, action) => {
        state.updatingOpenAIPromptStatus = "succeeded";
        state.openAIPrompts = action.payload;
      })
      .addCase(updateOpenAIPrompt.rejected, (state) => {
        state.updatingOpenAIPromptStatus = "failed";
      });
  },
});

export const selectOpenAIPrompts = (state: RootState) =>
  state.promptEditor.openAIPrompts;
export const selectGettingOpenAIPromptsStatus = (state: RootState) =>
  state.promptEditor.gettingOpenAIPromptsStatus;

export const selectExamplePromptResponse = (state: RootState) =>
  state.promptEditor.examplePromptResponse;
export const selectGettingExamplePromptResponseStatus = (state: RootState) =>
  state.promptEditor.gettingExamplePromptResponseStatus;

export const selectUpdatingOpenAIPromptStatus = (state: RootState) =>
  state.promptEditor.updatingOpenAIPromptStatus;

export default OpenAIPromptEditorStateSlice.reducer;
