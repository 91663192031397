import Select from "react-select";
import { Dropdown } from "react-bootstrap";
import { useAppSelector } from "utils/redux/hooks";
import Button from "app/storybookComponents/Button";
import {
  selectCurrentUserAccountId,
  selectIsCurrentUserAdmin,
  selectTeamsByTeamId,
  selectAllCompanyUsersById,
} from "app/containers/Global/slice";
import {
  selectAssessmentInformation,
  selectDepartments,
} from "app/containers/AdminConsole/slice";
import { LaunchAssessmentInviteType } from "./types";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  selectTeamAssessmentsInstancesEntities,
  selectTeamsMostRecentAssessmentsInstancesEntities,
} from "app/containers/Assessment/slice";

interface Props {
  parentInviteType: LaunchAssessmentInviteType;
  parentSelectedIds: number[];
  onSave: (
    selectedIds: number[],
    inviteType: LaunchAssessmentInviteType
  ) => void;
  saveButtonText?: string;
  onCancel: () => void;
}

export default function LaunchAssessmentInviteBody({
  parentInviteType,
  parentSelectedIds,
  onCancel,
  onSave,
  saveButtonText = "Save",
}: Readonly<Props>) {
  // ------------------------ selectors ------------------------------
  const teamsById = useAppSelector(selectTeamsByTeamId);
  const departments = useAppSelector(selectDepartments);
  const usersById = useAppSelector(selectAllCompanyUsersById);
  const isAdmin = useAppSelector(selectIsCurrentUserAdmin);
  const currentAccountId = useAppSelector(selectCurrentUserAccountId);
  const assessmentInformation = useAppSelector(selectAssessmentInformation);
  const mostRecentAssessmentInstances = useAppSelector(
    selectTeamsMostRecentAssessmentsInstancesEntities
  );
  const allAvailableInstances = useAppSelector(
    selectTeamAssessmentsInstancesEntities
  );

  // ------------------------ state ------------------------------
  const [selectedIds, setSelectedIds] = useState<number[]>(parentSelectedIds);
  const [inviteType, setInviteType] =
    useState<LaunchAssessmentInviteType>(parentInviteType);
  const [warningMessages, setWarningMessages] = useState<ReactNode[]>([]);
  const [warningType, setWarningType] = useState<"warning" | "error" | null>(
    null
  );

  // ------------------------ useEffects ------------------------------
  const clearWarning = useCallback(() => {
    setWarningMessages([]);
    setWarningType(null);
  }, []);

  const checkTeamsWithActiveAssessments = useCallback(() => {
    const teamsWithActiveAssessments = new Set<number>();

    selectedIds.forEach((teamId) => {
      if (!assessmentInformation?.teams?.[teamId]?.activeAssessment) {
        return;
      }

      teamsWithActiveAssessments.add(teamId);
    });

    if (teamsWithActiveAssessments.size === 0) {
      return clearWarning();
    }

    const errorStrings: ReactNode[] = [];
    teamsWithActiveAssessments.forEach((teamId) => {
      const mostRecentAssessmentInstanceId =
        mostRecentAssessmentInstances?.[teamId]?.assessmentInstanceId;
      if (!mostRecentAssessmentInstanceId) return;
      const instance = allAvailableInstances?.[mostRecentAssessmentInstanceId];
      if (!instance) return;

      const { firstName = "", lastName = "", startDate } = instance;
      const date = new Date(startDate).toLocaleDateString();
      const teamName = teamsById[teamId]?.teamName;
      errorStrings.push(
        <p>
          {teamName} currently has an active TEAMscan survey. It was launched by{" "}
          {firstName} {lastName} on {date}. You must wait until the survey
          period ends in order to launch another survey for this team.
        </p>
      );
    });
    setWarningType("error");
    setWarningMessages(errorStrings);
  }, [
    assessmentInformation?.teams,
    selectedIds,
    clearWarning,
    allAvailableInstances,
    mostRecentAssessmentInstances,
    teamsById,
  ]);

  const checkDepartmentsWithActiveAssessments = useCallback(() => {
    let totalTeams = 0;
    let totalTeamsWithActiveAssessment = 0;

    selectedIds.forEach((departmentId) => {
      const departmentAssessmentInfo =
        assessmentInformation?.departments?.[departmentId];

      if (departmentAssessmentInfo?.teamsWithActiveAssessments) {
        totalTeamsWithActiveAssessment +=
          departmentAssessmentInfo.teamsWithActiveAssessments;
      }

      if (departments[departmentId]) {
        totalTeams += departments[departmentId].teams?.length ?? 0;
      }
    });

    if (!totalTeamsWithActiveAssessment) {
      return clearWarning();
    }

    if (totalTeamsWithActiveAssessment === totalTeams) {
      setWarningType("error");
      setWarningMessages([
        <p>
          This department currently has {totalTeamsWithActiveAssessment} out of{" "}
          {totalTeams} teams with active TEAMscan surveys. You must wait until
          the survey period ends in order to launch another survey for this
          department.
        </p>,
      ]);
      return;
    }

    setWarningType("warning");
    setWarningMessages([
      <p>
        <u>
          {totalTeamsWithActiveAssessment} out of {totalTeams} teams
        </u>{" "}
        in this department already have an active TEAMscan survey. Only teams
        without active TEAMscans will receive an invitation to take the survey
        launched by you. Department-level analytics will factor in results from
        any TEAMscans launched by teams in this department.
      </p>,
    ]);
  }, [assessmentInformation, departments, selectedIds, clearWarning]);

  const checkOrgWithActiveAssessments = useCallback(() => {
    const teamsWithActiveAssessments =
      assessmentInformation?.organization?.teamsWithActiveAssessments;
    const teamsInOrg = assessmentInformation?.organization?.teamsInOrg ?? 0;

    if (!teamsWithActiveAssessments) {
      return clearWarning();
    }

    if (teamsWithActiveAssessments === teamsInOrg) {
      setWarningType("error");
      setWarningMessages([
        <p>
          All teams in this organization currently have an active TEAMscan
          survey. You must wait until the survey period ends for at least one
          team in order to launch another survey for this organization.
        </p>,
      ]);
      return;
    }

    setWarningType("warning");
    setWarningMessages([
      <p>
        <u>
          {teamsWithActiveAssessments} out of {teamsInOrg} teams
        </u>{" "}
        in this organization already have an active TEAMscan survey. This will
        simply send a survey invitation to the other{" "}
        {teamsInOrg - teamsWithActiveAssessments} teams in this organization.
        Organization-level analytics will factor in results from any TEAMscans
        launched by teams or departments in this organization.
      </p>,
    ]);
  }, [assessmentInformation?.organization, clearWarning]);

  // Warning hook kinda messy but it works
  useEffect(() => {
    if (inviteType === "Entire Organization") {
      return checkOrgWithActiveAssessments();
    }

    if (inviteType === "Select Team(s)") {
      return checkTeamsWithActiveAssessments();
    }
    if (inviteType === "Specific Department(s)") {
      return checkDepartmentsWithActiveAssessments();
    }
  }, [
    inviteType,
    checkOrgWithActiveAssessments,
    checkTeamsWithActiveAssessments,
    checkDepartmentsWithActiveAssessments,
  ]);

  // ------------------------ functions ------------------------------
  const getSelectOptions = () => {
    const options: {
      label: string;
      value: string;
    }[] = [];
    switch (inviteType) {
      case "Select Team(s)": {
        // If the user is admin show all of the teams, if not then we should only be showing teams that the user belongs to
        if (isAdmin) {
          Object.values(teamsById).forEach((team) => {
            options.push({
              label: team.teamName,
              value: `${team.teamId}`,
            });
          });
        } else if (currentAccountId && usersById[currentAccountId]) {
          currentAccountId &&
            usersById[currentAccountId]?.teamIds?.forEach((teamId) => {
              options.push({
                label: teamsById[teamId]?.teamName,
                value: `${teamId}`,
              });
            });
        }
        break;
      }
      case "Specific Department(s)": {
        Object.values(departments).forEach((department) => {
          // if (!department.teams?.length) return;
          options.push({
            label: department.name ?? "",
            value: `${department.departmentId}`,
          });
        });
        break;
      }
      default:
        break;
    }

    // Doing breaks instead of returns incase we want to filter out some options
    return options;
  };

  const getSelectedValues = () => {
    switch (inviteType) {
      case "Select Team(s)":
        return selectedIds.map((id) => ({
          label: teamsById[id]?.teamName,
          value: String(teamsById[id]?.teamId),
        }));
      case "Specific Department(s)":
        return selectedIds.map((id) => ({
          label: departments[id]?.name,
          value: `${departments[id]?.departmentId}`,
        }));
      default:
        return [];
    }
  };

  const getMultiValueClass = (value: string) => {
    // If the state is teams and the team selected already has a assessment active then this should be the error class
    if (!assessmentInformation) return "select__multi-value";

    if (
      inviteType === "Select Team(s)" &&
      assessmentInformation.teams?.[value]?.activeAssessment
    ) {
      return "select__multi-value danger";
    }

    if (
      inviteType === "Specific Department(s)" &&
      (assessmentInformation.departments?.[value]?.activeAssessment ||
        assessmentInformation.departments?.[value]?.teamsWithActiveAssessments)
    ) {
      return `select__multi-value ${
        warningType === "error" ? " danger" : " warning"
      }`;
    }

    return "select__multi-value";
  };

  const getWarningMessageBanners = () =>
    warningMessages.map((message) => {
      if (warningType === "error") {
        return getDangerBanner(message);
      }
      return getWarningBanner(message);
    });

  const getDangerBanner = (message: ReactNode) => (
    <div
      className="warning-banner red row-gap-12px align-items-center"
      role="alert"
    >
      <FontAwesomeIcon icon="triangle-exclamation" />
      {message}
    </div>
  );
  const getWarningBanner = (message: ReactNode) => (
    <div className="warning-banner" role="alert">
      {message}
    </div>
  );

  const getInviteTypeDropdownMenu = () => {
    if (!isAdmin) {
      return (
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              // If we switch from one to the other we need to clear the selected ids
              if (inviteType !== "Select Team(s)") {
                setSelectedIds([]);
              }
              setInviteType("Select Team(s)");
            }}
          >
            Select Team(s)
          </Dropdown.Item>
        </Dropdown.Menu>
      );
    }

    return (
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => setInviteType("Entire Organization")}>
          Entire Organization
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            // If we switch from one to the other we need to clear the selected ids
            if (inviteType !== "Select Team(s)") {
              setSelectedIds([]);
            }
            setInviteType("Select Team(s)");
          }}
        >
          Select Team(s)
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            // If we switch from one to the other we need to clear the selected ids
            if (inviteType !== "Specific Department(s)") {
              setSelectedIds([]);
            }
            setInviteType("Specific Department(s)");
          }}
        >
          Specific Department(s)
        </Dropdown.Item>
      </Dropdown.Menu>
    );
  };

  const saveDisabled =
    (inviteType !== "Entire Organization" && selectedIds.length === 0) ||
    warningType === "error";

  const getSearchPlaceholder = () => {
    if (inviteType === "Specific Department(s)") {
      return "Search by department name";
    }

    if (inviteType === "Select Team(s)") {
      return "Search by team name";
    }

    return "";
  };

  return (
    <>
      <p className="launch-surveys__preview-text grey-text">
        {isAdmin
          ? "Invite your entire organization, select departments or select teams to take this survey."
          : "Invite a team that you lead to take this survey."}
      </p>
      <div>
        <Dropdown className="full-width-dropdown mb-2">
          <Dropdown.Toggle variant="light" id="dropdown-basic">
            {inviteType}
          </Dropdown.Toggle>

          {getInviteTypeDropdownMenu()}
        </Dropdown>
        {inviteType !== "Entire Organization" ? (
          <Select
            noOptionsMessage={() => null}
            placeholder={getSearchPlaceholder()}
            options={getSelectOptions()}
            isMulti
            value={getSelectedValues()}
            onChange={(values) => {
              if (values) {
                setSelectedIds(values.map(({ value }) => Number(value)));
              }
            }}
            classNames={{
              multiValue: ({ data: { value } }) => getMultiValueClass(value),
            }}
          />
        ) : null}
      </div>
      {getWarningMessageBanners()}

      <div className="d-flex justify-content-between">
        <Button variant="secondary-gray" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={saveDisabled}
          onClick={() => {
            if (!saveDisabled) onSave(selectedIds, inviteType);
          }}
        >
          {saveButtonText}
        </Button>
      </div>
    </>
  );
}
