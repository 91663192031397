import { responseStatus } from "utils/types";
import { request } from "utils/request";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import { UserSettings } from "./types";

export interface UserSettingsState {
  userSettings: UserSettings | null;
  getUserSettingsStatus: responseStatus;
  editUserSettingsStatus: responseStatus;
}

export const initialState: UserSettingsState = {
  userSettings: null,
  getUserSettingsStatus: "idle",
  editUserSettingsStatus: "idle",
};

export const getUserSettings = createAsyncThunk(
  "userSettings/getUserSettings",
  async (userAccountId: number) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/users/${userAccountId}/settings`;
    return (await request(requestUrl)) as UserSettings;
  }
);

export const updateUserSettings = createAsyncThunk(
  "userSettings/updateUserSettings",
  async ({
    payload,
    userAccountId,
  }: {
    payload: UserSettings;
    userAccountId: number;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/users/${userAccountId}/settings`;
    return (await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify(payload),
    })) as UserSettings;
  }
);

export const userSettingsSlice = createSlice({
  name: "userSettings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserSettings.pending, (state) => {
        state.getUserSettingsStatus = "loading";
      })
      .addCase(getUserSettings.fulfilled, (state, action) => {
        state.getUserSettingsStatus = "succeeded";
        state.userSettings = action.payload;
      })
      .addCase(getUserSettings.rejected, (state) => {
        state.getUserSettingsStatus = "failed";
      })
      .addCase(updateUserSettings.pending, (state) => {
        state.editUserSettingsStatus = "loading";
      })
      .addCase(updateUserSettings.fulfilled, (state, action) => {
        state.editUserSettingsStatus = "succeeded";
        state.userSettings = action.payload;
      })
      .addCase(updateUserSettings.rejected, (state) => {
        state.editUserSettingsStatus = "failed";
      });
  },
});

export const selectUserSettings = (state: RootState) =>
  state.userSettings.userSettings;

export const selectGetUserSettingsStatus = (state: RootState) =>
  state.userSettings.getUserSettingsStatus;

export default userSettingsSlice.reducer;
