import Button from "app/storybookComponents/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  readonly toggleIsCollapsed: () => void;
  readonly title: string;
  readonly isCollapsed: boolean;
}

export default function SectionHeader({
  toggleIsCollapsed,
  title,
  isCollapsed,
}: Props) {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <h2>{title}</h2>
      <Button
        variant="secondary-blue"
        className="border-0"
        onClick={toggleIsCollapsed}
        aria-expanded={isCollapsed}
      >
        {isCollapsed ? "Expand" : "Collapse"}
        <FontAwesomeIcon
          icon={`caret-${isCollapsed ? "down" : "up"}`}
          className="ms-2"
        />
      </Button>
    </div>
  );
}
