import TEAM360Icon from "resources/icons/TEAM-assessment-logo.svg";
export const AssessmentMap = {
  1: {
    name: "TEAMscan",
    type: "team360",
    assessmentIcon: TEAM360Icon,
  },
} as {
  [key: number]: {
    name: string;
    type: string;
    assessmentIcon: string;
  };
};
