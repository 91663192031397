import { Card } from "react-bootstrap";
import Button from "app/storybookComponents/Button";
import Team360AssessmentResultsCard from "app/components/Team360Assessment/Team360AssessmentResultsCard";
import {
  Team360AssessmentResult,
  TTeam360Factor,
} from "app/components/Team360Assessment/types";

interface Props {
  title?: string;
  overviewText: string;
  teamScores?: Team360AssessmentResult;
  companyScores?: Team360AssessmentResult;
  departmentScores?: Team360AssessmentResult;
  setShowTeam360ResultsModal: (
    factor: TTeam360Factor,
    highOrLow?: "high" | "low"
  ) => void;
  type: "organization" | "department";
  hideActionButtons?: boolean;
  onSeeScoreBreakdown?: () => void;
  onSeeComments?: () => void;
  footerButtons?: React.ReactNode;
}

export default function ResultsOverviewCard({
  title = "How's Your Team Doing Overall?",
  overviewText,
  companyScores,
  departmentScores,
  setShowTeam360ResultsModal,
  type,
  hideActionButtons,
  teamScores,
  onSeeScoreBreakdown,
  onSeeComments,
  footerButtons,
}: Readonly<Props>) {
  const team360Scores = teamScores ?? departmentScores ?? companyScores;
  if (!team360Scores) return null;
  const snapShotText = `Your overall ${type} teamwork score is the simple average score
  of all your teams across the 17 dimensions of high-performing teams
  measured by the TEAM model. This presents a holistic snapshot of the
  how well your teams think they are performing based on an
  equal-weighting of all the opinions of every team member.`;

  const onGetActionButtons = () => {
    if (!onSeeScoreBreakdown && !onSeeComments && !footerButtons) {
      return null;
    }
    return (
      <div
        style={{
          marginTop: "20px",
        }}
        className="row-gap-12px"
      >
        {onSeeScoreBreakdown ? (
          <Button onClick={onSeeScoreBreakdown}>See score breakdown</Button>
        ) : null}
        {onSeeComments ? (
          <Button onClick={onSeeComments} variant="secondary-blue">
            See comments
          </Button>
        ) : null}
        {footerButtons ?? null}
      </div>
    );
  };

  return (
    <Card className="p-4">
      <h2>{title}</h2>
      {overviewText ? (
        <p
          style={{
            margin: "20px 0",
          }}
        >
          {overviewText}
        </p>
      ) : null}
      <Team360AssessmentResultsCard
        teamScores={teamScores}
        departmentScores={departmentScores}
        companyScores={companyScores}
        snapShotText={snapShotText}
        onFactorOrDimensionClick={(factor, highOrLow) => {
          setShowTeam360ResultsModal(factor as TTeam360Factor, highOrLow);
        }}
        hideActionButtons={hideActionButtons}
      />
      {onGetActionButtons()}
    </Card>
  );
}
