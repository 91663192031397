import { useState, ReactNode, useEffect } from "react";
import { Card, Nav } from "react-bootstrap";
import UploadCSVForm from "app/storybookComponents/Forms/UploadTeamCSVForm";
import Button from "app/storybookComponents/Button";
import CreatableSelect from "react-select/creatable";
import { UserInfo } from "app/containers/Global/types";
import AvatarCircle from "app/components/AvatarCircle";
import InvitationSent from "./InvitationSent";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  onInviteViaEmail: (emails: string[]) => void;
  allowedDomains?: string[] | "ALL";
  hiddenUserAccounts?: number[];
  inviteLink?: string;
  isLoading?: boolean;
  onHide?: () => void;
  teamId?: number;
  teamMembers?: UserInfo[];
  totalUsersInvited?: number;
  inviteMemberInfoMessage?: string;
  openLinkInfoMessage?: string;
  modalDescription?: JSX.Element;
  allUsedEmails?: string[];
  invalidInvitedStrings: string[];
  onCSVUploadSuccess?: () => void;
}
interface OptionType {
  label: string;
  value: string;
  name: string;
}

export default function InviteUserForm({
  inviteLink = "",
  onHide = () => {},
  allowedDomains,
  teamId,
  teamMembers,
  isLoading,
  hiddenUserAccounts,
  totalUsersInvited,
  inviteMemberInfoMessage,
  openLinkInfoMessage,
  modalDescription,
  onInviteViaEmail,
  invalidInvitedStrings,
  onCSVUploadSuccess,
}: Readonly<Props>) {
  // This is only temporary until the backend updates the value.
  const [tabShowing, setTabShowing] = useState<"email" | "link" | "csv">(
    "email"
  );
  // --------------------- email tab states ---------------------
  const [inputText, setInputText] = useState<string>("");
  const [selectValues, setSelectValues] = useState<
    readonly {
      label: string;
      value: string;
      name: string;
      className?: string;
    }[]
  >([]);
  const [invalidDomains, setInvalidDomains] = useState<Set<string>>(
    new Set<string>()
  );
  const [generalInvalidEmails, setGeneralInvalidEmails] = useState<Set<string>>(
    new Set<string>()
  );

  // --------------------- link tab states ---------------------
  const [copied, setCopied] = useState<boolean>(false);

  // --------------------- email tab functions ---------------------
  // This makes sure that the success banner disappears after 3 seconds.
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (copied) {
      timer = setTimeout(() => {
        setCopied(false);
      }, 3000);
    }

    return () => clearTimeout(timer);
  }, [copied]);

  const onEmailInvite = () => {
    const newInvalidDomains: Set<string> = new Set<string>();
    const duplicateEmails: Set<string> = new Set<string>();
    const generalInvalidEmails: Set<string> = new Set<string>();
    const validEmails: string[] = [];

    const updatedValues = selectValues.map((selectedVal) => {
      const domainCheckerResponse = domainChecker(selectedVal.value);
      if (domainCheckerResponse !== true) {
        // if not empty then we add that domain to the invalid domains list.
        if (domainCheckerResponse) {
          newInvalidDomains.add(domainCheckerResponse);
          // else if the domain checker response is empty then we add the email to the general invalid emails list.
        } else {
          generalInvalidEmails.add(selectedVal.value);
        }

        return { ...selectedVal, className: "danger" };
      }

      validEmails.push(selectedVal.value);
      return selectedVal;
    });

    setInvalidDomains(newInvalidDomains);
    setGeneralInvalidEmails(generalInvalidEmails);

    // If no teamId is passed then we need to also count the emails that are already in the system as invalid.
    if (
      newInvalidDomains.size === 0 &&
      validEmails.length > 0 &&
      duplicateEmails.size === 0 &&
      generalInvalidEmails.size === 0
    ) {
      // send email invite
      onInviteViaEmail(validEmails); // comment out for now
      return;
    }

    setSelectValues(updatedValues);
  };

  //
  const domainChecker = (email: string): string | true => {
    // If no allowed domains are set, then we allow all domains.
    if (!allowedDomains || allowedDomains === "ALL") {
      return true;
    }

    // We split at the @ symbol to get the domain.
    const emailDomain = email.split("@")[1];
    // Then combine all of the allowed domains into one string and create a regex.
    const domainRegex = new RegExp(`^(${allowedDomains.join("|")})$`, "i");
    const domainTestResponse = domainRegex.test(emailDomain);

    // If the domain is valid return true, otherwise return the domain that was invalid.
    return domainTestResponse || emailDomain;
  };

  // --------------------- link tab functions ---------------------
  const onCopyLinkHandler = async () => {
    await navigator.clipboard.writeText(inviteLink || "");
    setCopied(true);
  };

  // --------------------- csv tab functions ---------------------
  const customFilterOption = (
    option: FilterOptionOption<OptionType>,
    inputValue: string
  ) => {
    return (
      option.data.label.toLowerCase().includes(inputValue.toLowerCase()) ||
      option.data.name.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const getWarningBanners = () => {
    const returnArray: ReactNode[] = [];

    invalidDomains?.forEach((email) => {
      returnArray.push(
        getWarningBanner(
          `'${email}' is not an accepted email domain`,
          `${email}-invalid-domain`
        )
      );
    });

    generalInvalidEmails?.forEach((email) => {
      returnArray.push(
        getWarningBanner(`'${email}' is not a valid email`, email)
      );
    });

    invalidInvitedStrings?.forEach((message) => {
      returnArray.push(getWarningBanner(message, message));
    });

    return returnArray;
  };
  const getWarningBanner = (stringValue: string, key: string) => (
    <div
      key={key}
      className="warning-banner light-red row-gap-12px align-items-center"
    >
      <FontAwesomeIcon icon="triangle-exclamation" />
      <p>{stringValue}</p>
    </div>
  );

  const onValueChange = () => {
    setInvalidDomains(new Set<string>());
    setGeneralInvalidEmails(new Set<string>());
  };

  const getTabContent = () => {
    switch (tabShowing) {
      case "email": {
        const memberMap = new Map<
          string,
          { avatarCircle: ReactNode; fullName: string; emailAddress: string }
        >();

        const options: OptionType[] = [];
        teamMembers?.forEach(
          ({ firstName, lastName, emailAddress, userAccountId }) => {
            if (hiddenUserAccounts?.includes(userAccountId)) {
              return;
            }
            memberMap.set(emailAddress, {
              fullName: `${firstName} ${lastName}`,
              avatarCircle: (
                <AvatarCircle
                  name={`${firstName} ${lastName}`}
                  userAccountId={userAccountId}
                  size="small"
                />
              ),
              emailAddress,
            });
            options.push({
              label: emailAddress,
              value: emailAddress,
              name: `${firstName} ${lastName}`,
            });
          }
        );

        return (
          <>
            <CreatableSelect
              formatCreateLabel={(e) => `Invite "${e}"`}
              noOptionsMessage={() => null}
              components={{
                DropdownIndicator: null,
              }}
              inputValue={inputText}
              isClearable
              isMulti
              isSearchable
              options={options}
              onChange={(newValue) => {
                setSelectValues(newValue);
                onValueChange();
              }}
              onInputChange={(newValue) => setInputText(newValue)}
              onCreateOption={(newValue) => {
                setSelectValues((prev) => [
                  ...prev,
                  {
                    label: newValue,
                    value: newValue,
                    className: "create-email",
                    name: "",
                  },
                ]);
                setInputText("");
              }}
              placeholder="Enter email addresses..."
              value={selectValues}
              styles={{
                menu: (base: any) => ({
                  ...base,
                  marginTop: 0,
                }),
              }}
              classNames={{
                multiValue: (base: any) =>
                  `${base?.data?.className || ""} multi-value select-item`,
                input: () => `simple-select-input`,
              }}
              formatOptionLabel={(member) =>
                getFormatOptionLabel(memberMap, member)
              }
              filterOption={customFilterOption}
            />
            {getWarningBanners()}
            <div className="action-buttons">
              <Button
                onClick={onEmailInvite}
                disabled={isLoading || selectValues.length === 0}
              >
                Send Invite
              </Button>
            </div>
          </>
        );
      }
      case "link":
        return (
          <>
            <Card
              onClick={onCopyLinkHandler}
              role="button"
              aria-disabled={!!inviteLink}
              className={inviteLink ? "invite-link" : "disabled-invite-link"}
            >
              <p className="invite-link">{inviteLink || "Loading..."}</p>
            </Card>
            <div className="action-buttons">
              <div>
                <Button
                  onClick={onCopyLinkHandler}
                  className={copied ? "copied-success" : ""}
                >
                  Copy Invite Link
                </Button>
                {copied ? (
                  <span className="ms-2" style={{ color: "#009952" }}>
                    <FontAwesomeIcon icon="check" className="me-2" />
                    Link Copied To Clipboard
                  </span>
                ) : null}
              </div>
            </div>
          </>
        );
      case "csv":
        return (
          <UploadCSVForm
            onHide={onHide}
            teamId={teamId}
            isLoading={isLoading}
            onCSVUploadSuccess={onCSVUploadSuccess}
          />
        );
    }
  };

  const getFormatOptionLabel = (
    memberMap: Map<
      string,
      { avatarCircle: ReactNode; fullName: string; emailAddress: string }
    >,
    member: {
      label: string;
      value: string;
    }
  ) => {
    const memberInfo = memberMap.get(member.value);
    if (memberInfo) {
      return (
        <div className="member-option">
          {memberInfo.avatarCircle}
          <div className="member-info">
            <span className="member-name">{memberInfo.fullName}</span>
            <span className="member-email">{memberInfo.emailAddress}</span>
          </div>
        </div>
      );
    }

    return (
      <div key={member.value}>
        <span className="simple-text">{member.label}</span>
      </div>
    );
  };

  const getSecondDescriptionText = () => {
    const teamOrPlatform = teamId ? "team" : "platform";
    switch (tabShowing) {
      case "email": {
        if (inviteMemberInfoMessage) return <p>{inviteMemberInfoMessage}</p>;
        return (
          <p>
            Simply type in the email addresses of the people you want to invite.
            They'll receive an invitation to join the {teamOrPlatform}.
          </p>
        );
      }
      case "link": {
        if (openLinkInfoMessage) return <p>{openLinkInfoMessage}</p>;
        return (
          <p>
            Generate a unique link that can be shared with potential users. They
            can use this link to join the {teamOrPlatform}.
          </p>
        );
      }
      case "csv":
        return null;
    }
  };

  return (
    <div className={"column-gap-20px"}>
      {/* If totalUsersInvited is defined and not 0 we display the success message */}
      {totalUsersInvited ? (
        <InvitationSent totalUsersInvited={totalUsersInvited} onHide={onHide} />
      ) : (
        <>
          {modalDescription ?? null}
          <Nav
            className="simple-nav"
            activeKey={tabShowing || ""}
            onSelect={(e) => {
              setTabShowing(e as "email" | "link" | "csv");
            }}
          >
            <Nav.Item>
              <Nav.Link eventKey="email">Via Email</Nav.Link>
            </Nav.Item>
            {inviteLink ? (
              <Nav.Item>
                <Nav.Link eventKey="link">Via Link</Nav.Link>
              </Nav.Item>
            ) : null}
            <Nav.Item>
              <Nav.Link eventKey="csv">Via CSV</Nav.Link>
            </Nav.Item>
          </Nav>
          {getSecondDescriptionText()}
          {getTabContent()}
        </>
      )}
    </div>
  );
}
