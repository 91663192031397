import { Dropdown } from "react-bootstrap";
import { DropdownSection } from "./types";

interface Props {
  sections?: DropdownSection[];
  onSelect: (key: string | null) => void;
  selected?: {
    key: string | null | number;
    title: string;
  };
}

// Might just make into a department, team, and individual dropdown
// Or just make this return the dropdown items .

export default function SectionedDropdownItems({
  sections = [],
  selected,
  onSelect,
}: Readonly<Props>) {
  if (sections.length === 0) {
    return null;
  }

  const renderSectionTitle = (title: string) => {
    return (
      <div
        key={title}
        style={{
          padding: "16px 20px 8px 20px",
          fontSize: "12px",
          color: "#53565A",
          borderTop: "1px solid #D9DEF4",
        }}
      >
        {title}
      </div>
    );
  };

  const renderDropdownItem = (item: {
    key?: string | number;
    title: string;
  }) => {
    return (
      <Dropdown.Item key={item.key} eventKey={item.key}>
        {item.title}
      </Dropdown.Item>
    );
  };

  const renderDivider = (key: string) => {
    return <Dropdown.Divider key={key} />;
  };

  const getDropdownItems = () => {
    const sectionItems: JSX.Element[] = [];

    sections.forEach((section, idx) => {
      if (section.title) {
        sectionItems.push(renderSectionTitle(section.title));
      }

      const nestedItems: JSX.Element[] = [];

      section.items.forEach((item, idy) => {
        nestedItems.push(renderDropdownItem(item));

        if (idy !== section.items.length - 1) {
          nestedItems.push(renderDivider(`${item.key}-divider`));
        }
      });

      if (idx !== sections.length - 1) {
        nestedItems.push(renderDivider(`${section.title}-divider`));
      }

      sectionItems.push(...nestedItems);
    });

    return sectionItems;
  };

  return (
    <Dropdown onSelect={onSelect}>
      <Dropdown.Toggle
        variant="light"
        id="dropdown-basic"
        className="dropdown-menu-240px"
      >
        {selected?.title ?? "Select"}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-240px">
        {getDropdownItems()}
      </Dropdown.Menu>
    </Dropdown>
  );
}
