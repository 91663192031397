import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { request } from "utils/request";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import { OpenAIGeneratedText } from "app/components/OpenAIInternalTools/OpenAIModule/types";

export interface QuoteState {
  quote: null | OpenAIGeneratedText;
  gettingQuoteStatus: responseStatus;
}

const initialState: QuoteState = {
  quote: null,
  gettingQuoteStatus: "idle",
};

export const getQuote = createAsyncThunk("generate", async () => {
  const requestUrl = `${REACT_APP_API_URL}/talentInsights/generate`;
  return (await request(requestUrl, {
    method: "POST",
    body: JSON.stringify({ payload: { promptId: 1 } }),
  })) as OpenAIGeneratedText;
});

export const quoteSlice = createSlice({
  name: "quote",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getQuote.pending, (state) => {
        state.gettingQuoteStatus = "loading";
      })
      .addCase(
        getQuote.fulfilled,
        (state, action: PayloadAction<OpenAIGeneratedText>) => {
          return {
            ...state,
            gettingQuoteStatus: "succeeded",
            quote: { ...action.payload },
          };
        }
      )
      .addCase(getQuote.rejected, (state) => {
        state.gettingQuoteStatus = "failed";
      });
  },
});

export const selectQuote = (state: RootState) => state.quote.quote;
export const selectGetQuoteStatus = (state: RootState) =>
  state.quote.gettingQuoteStatus;

export default quoteSlice.reducer;
