import { memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { WordCloudChart as WordCloud } from 'chartjs-chart-wordcloud';
import { getWordCloudLabelsAndData } from './helpers';

interface Props {
  canvasId: string;
  data?: {
    text: string;
    value: number;
  }[];
  currentVal?: number;
  label?: string;
  size?: 'small' | 'medium' | 'large';
  name?: string;
}

const WordCloudChart = ({ canvasId, data, size = 'small', name }: Props) => {
  const [normalizedData, setNormalizedData] = useState<
    {
      text: string;
      value: number;
    }[]
  >([]); // We need this state here to normalize the data because if the values are to big then the chart will not render.

  const chartRefInstance = useRef<{ chart: WordCloud | null }>({ chart: null });
  const chartRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!(chartRef && chartRef.current) || normalizedData.length === 0) {
      return;
    }

    const { labels, dataArr } = getWordCloudLabelsAndData(normalizedData);
    chartRefInstance.current.chart?.destroy();
    chartRefInstance.current.chart = new WordCloud(chartRef.current, {
      data: {
        labels,
        datasets: [
          {
            data: dataArr,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
      },
    });

    const chart = chartRefInstance.current.chart;
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [normalizedData, size, name]);

  useLayoutEffect(() => {
    if (!data) {
      return;
    }
    // We need to normalize the values so that the greatest value is 100 and the lowest is 10.
    // First we find the max and min values.
    const values = data.map((item) => item.value);
    const max = Math.max(...values);
    const min = Math.min(...values);

    // Then we normalize the values.
    const normalizedData = data.map((item) => {
      const normalizedValue = Math.round(
        ((item.value - min) / (max - min)) * 50
      ); // This will give us a value between 0 and 50. in terms of where it is in the range of min and max.

      return {
        text: item.text,
        value: normalizedValue || 10, // The lowest value should be 10.
      };
    });

    setNormalizedData(normalizedData);
  }, [data]);

  return (
    <div className={`${size}`}>
      <canvas id={canvasId} ref={chartRef} />
    </div>
  );
};

export default memo(WordCloudChart);
