import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import achievementIcon from 'resources/trait_icons/trait-icons-achievement.svg';
import assertivenessIcon from 'resources/trait_icons/trait-icons-assertiveness.svg';
import competitivenessIcon from 'resources/trait_icons/trait-icons-competitiveness.svg';
import conscientiousnessIcon from 'resources/trait_icons/trait-icons-conscientiousness.svg';
import cooperativenessIcon from 'resources/trait_icons/trait-icons-cooperativeness.svg';
import extroversionIcon from 'resources/trait_icons/trait-icons-extroversion.svg';
import motivationIcon from 'resources/trait_icons/trait-icons-motivation.svg';
import opennessIcon from 'resources/trait_icons/trait-icons-openness.svg';
import patienceIcon from 'resources/trait_icons/trait-icons-patience.svg';
import selfConfidenceIcon from 'resources/trait_icons/trait-icons-self-confidence.svg';

export const getWorkplaceChallengesAndMotivatorsCell = (
  cellPos: number,
  activeValue: number,
  key: string
) => {
  const active = activeValue === cellPos ? ' active' : '';
  const cellPosName = getCellPosName(cellPos);
  return (
    <td
      className={`${cellPosName}-cell data-cell ${active}`}
      key={`${key}_${cellPos}`}
    >
      {active ? (
        <FontAwesomeIcon
          icon={['fass', 'check-circle']}
          style={{ fontSize: '32px' }}
        />
      ) : (
        <FontAwesomeIcon
          icon="circle"
          style={{ fontSize: '8px', color: '#202d63' }}
        />
      )}
    </td>
  );
};

const getCellPosName = (cellPos: number) => {
  switch (cellPos) {
    case 0:
      return 'first';
    case 4:
      return 'last';
    default:
      return 'middle';
  }
};

export const getTraitNameAndIcon = (trait: string) => {
  const traitObject = {
    ACH: {
      traitName: 'Achievement',
      traitIcon: achievementIcon,
      traitColor: '#a0ade3',
      lowLabel: 'Impulsive',
      highLabel: 'Goal-Oriented',
      definition:
        "The Achievement scale reflects an individual's ability to follow through and complete tasks and to achieve specific goals.",
    },
    MOT: {
      traitName: 'Motivation',
      traitIcon: motivationIcon,
      traitColor: '#a0ade3',
      lowLabel: 'Easy-going',
      highLabel: 'Driven, Committed',
      definition:
        "The Motivation scale is intended to represent a person's inner drive, commitment to achieve, and the strength of inner emotions, needs, and values.",
    },
    CMP: {
      traitName: 'Competitiveness',
      traitIcon: competitivenessIcon,
      traitColor: '#ffad9c',
      lowLabel: 'Relaxed',
      highLabel: 'Competitive',
      definition:
        'The Competitiveness scale reflects the need to win, to perform better than others, or to surpass standards of achievement or performance.',
    },
    AST: {
      traitName: 'Assertiveness',
      traitIcon: assertivenessIcon,
      traitColor: '#ffd17f',
      lowLabel: 'Deferential',
      highLabel: 'Forceful, Dominant',
      definition:
        "The Assertiveness scale provides a gauge of an individual's directness in expressing himself or herself and in dealing with others.",
    },
    EXT: {
      traitName: 'Extroversion',
      traitIcon: extroversionIcon,
      traitColor: '#ffd17f',
      lowLabel: 'Introverted',
      highLabel: 'Extroverted',
      definition:
        'The Extroversion scale indicates the degree to which a person sees himself or herself as socially outgoing.',
    },
    COP: {
      traitName: 'Cooperativeness',
      traitIcon: cooperativenessIcon,
      traitColor: '#ffd17f',
      lowLabel: 'Aggressive, Independent',
      highLabel: 'Accommodating',
      definition:
        "The Cooperativeness scale indicates a person's level of comfort in working closely with others and in taking the lead from others.",
    },
    PAT: {
      traitName: 'Patience',
      traitIcon: patienceIcon,
      traitColor: '#ffad9c',
      lowLabel: 'Impatient',
      highLabel: 'Patient',
      definition:
        "The Patience scale indicates a person's ability to effectively cope with frustration encountered in completing tasks or in conflict-laden situations.",
    },
    SCN: {
      traitName: 'Self-Confidence',
      traitIcon: selfConfidenceIcon,
      traitColor: '#96e5e9',
      lowLabel: 'Modest',
      highLabel: 'Self-Confident',
      definition:
        'The Self-Confidence scale is an indicator of the level of confidence and self-assurance an individual brings to his or her work.',
    },
    CON: {
      traitName: 'Conscientiousness',
      traitIcon: conscientiousnessIcon,
      traitColor: '#a0ade3',
      lowLabel: 'Spontaneous, Laid-Back',
      highLabel: 'Dependable, Self-Disciplined',
      definition:
        "The Conscientiousness scale is an indicator of a person's tendencies with respect to being deliberate, self-disciplined, organized and dependable.",
    },
    OPEN: {
      traitName: 'Openness',
      traitIcon: opennessIcon,
      traitColor: '#96e5e9',
      lowLabel: 'Conventional, Traditional',
      highLabel: 'Experimental, Creative',
      definition:
        'The Openness scale measures the extent to which an individual is imaginative and creative, as opposed to down to earth and conventional.',
    },
  } as const;
  if (!traitObject[trait as keyof typeof traitObject]) return null;
  return traitObject[trait as keyof typeof traitObject];
};
