import { Modal } from "react-bootstrap";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  selectScheduleTEAM360Status,
  clearScheduleTEAM360Status,
} from "app/containers/AdminConsole/slice";
import {
  hideScheduleAssessmentModal,
  selectScheduleAssessmentModalOpen,
  selectScheduleAssessmentModalOpenForTeamId,
  selectScheduleAssessmentModalOpenForDepartmentId,
  selectEditAssessmentModalOpenForSurveyId,
  selectIsEntireOrganizationDefault,
  temporarilyHideScheduleAssessmentModal,
} from "./slice";
import LaunchAssessmentComponent from "./LaunchAssessmentComponent";

export default function LaunchAssessmentModal() {
  const dispatch = useAppDispatch();
  const schedulingTeam360Status = useAppSelector(selectScheduleTEAM360Status);
  const show = useAppSelector(selectScheduleAssessmentModalOpen);
  const teamId = useAppSelector(selectScheduleAssessmentModalOpenForTeamId);
  const departmentId = useAppSelector(
    selectScheduleAssessmentModalOpenForDepartmentId
  );
  const editingAssessmentId = useAppSelector(
    selectEditAssessmentModalOpenForSurveyId
  );
  const isEntireOrganizationDefault = useAppSelector(
    selectIsEntireOrganizationDefault
  );

  // -------------------------------- USE EFFECTS -------------------------------- //

  useEffect(() => {
    if (schedulingTeam360Status === "succeeded") {
      dispatch(hideScheduleAssessmentModal());
      dispatch(clearScheduleTEAM360Status());
    }
  }, [schedulingTeam360Status, dispatch]);

  // -------------------------------- EVENT HANDLERS -------------------------------- //

  const onHide = (isTemporary?: boolean) => {
    if (isTemporary) {
      return dispatch(temporarilyHideScheduleAssessmentModal());
    }

    dispatch(hideScheduleAssessmentModal());
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      className="simple-modal launch-survey-modal"
    >
      <LaunchAssessmentComponent
        onHide={onHide}
        isInModal
        teamId={teamId}
        departmentId={departmentId}
        editingAssessmentId={editingAssessmentId}
        isEntireOrganizationDefault={isEntireOrganizationDefault}
      />
    </Modal>
  );
}
