import Chart from "chart.js/auto";
import { memo, useLayoutEffect, useRef } from "react";
import {
  RADAR_COLOR_ARRAY,
  RADAR_LABEL_ARRAY,
  BASE_RADAR_CHART_DATASET,
  GENERAL_POPULATION_RADAR_CHART_DATASET,
} from "./constants";

interface Props {
  canvasId: string;
  size?: "small" | "medium" | "large";
  dataSets?: {
    data: number[];
    fill?: boolean;
    backgroundColor?: string;
    borderColor?: string;
    pointBackgroundColor?: string;
    pointBorderColor?: string;
    pointHoverBackgroundColor?: string;
    pointHoverBorderColor?: string;
    label?: string;
  }[];
  includeGeneralPopulation?: boolean;
  includeDashedBaseline?: boolean;
  hideCornerLabels?: boolean;
  hideLegend?: boolean;
}

const RadarChart = ({
  canvasId,
  size = "small",
  dataSets,
  includeGeneralPopulation,
  includeDashedBaseline,
  hideCornerLabels,
  hideLegend,
}: Props) => {
  const chartRefInstance = useRef<{ chart: Chart | null }>({ chart: null });
  const chartRef = useRef<HTMLCanvasElement>(null);

  useLayoutEffect(() => {
    if (!(chartRef && chartRef.current)) {
      return;
    }
    const datasets = dataSets ? [...dataSets] : [];

    if (includeGeneralPopulation) {
      datasets.push(GENERAL_POPULATION_RADAR_CHART_DATASET);
    }

    if (includeDashedBaseline) {
      datasets.push(BASE_RADAR_CHART_DATASET);
    }

    chartRefInstance.current.chart = new Chart(chartRef.current, {
      type: "radar",
      data: {
        labels: [...RADAR_LABEL_ARRAY],
        datasets,
      },

      options: {
        plugins: {
          legend: {
            labels: {
              filter: (legendItem) =>
                hideLegend ? false : legendItem.text !== "",
            },
            position: "bottom",
          },
        },
        scales: {
          r: {
            min: 0,
            max: 125,
            beginAtZero: true,
            pointLabels: {
              color: ({ index }) => RADAR_COLOR_ARRAY[index],
              font: {
                size: 14,
                weight: "bold",
              },
            },
            ticks: {
              stepSize: 25,
              display: false,
            },
          },
        },
      },
    });

    const chart = chartRefInstance.current.chart;
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [
    size,
    dataSets,
    includeDashedBaseline,
    includeGeneralPopulation,
    hideLegend,
  ]);

  return (
    <div className={`radar-chart-wrapper ${size}`}>
      {hideCornerLabels ? null : (
        <div className="radar-chart-corner-labels">
          <span className="cyan">Attitudes & Outlook</span>
          <span className="sapphire">Work Habits</span>
        </div>
      )}
      <div
        style={{
          width: "100%",
          maxWidth: "600px",
          alignSelf: "center",
        }}
      >
        <canvas id={canvasId} ref={chartRef} />
      </div>
      {hideCornerLabels ? null : (
        <div className="radar-chart-corner-labels">
          <span className="poppy">Temperament</span>
          <span className="gold">Interaction Style</span>
        </div>
      )}
    </div>
  );
};

export default memo(RadarChart);
