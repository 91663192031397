import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TEAM_SUMMARY_KEY_BREAKDOWN } from 'app/containers/AdminConsole/constants';

interface Props {
  type: 'team' | 'department';
}

export default function TeamSummaryKey({ type }: Props) {
  return (
    <div className="summary-chart-key">
      {TEAM_SUMMARY_KEY_BREAKDOWN.map(({ className, title, description }) => (
        <div className={`${className}`} key={title}>
          <FontAwesomeIcon
            icon="circle"
            style={{ fontSize: '8px' }}
            className="mt-1"
          />
          <div>
            <h4 className="breakdown-key">
              {title.replace('REPLACE_ME', type)}
            </h4>
            <p>{description}</p>
          </div>
        </div>
      ))}
    </div>
  );
}
