import { OverlayTrigger, Popover } from "react-bootstrap";
import Button from "app/storybookComponents/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

interface Props {
  textElms?: JSX.Element[];
  primaryButton?: {
    text: string;
    onClick: () => void;
  };
  secondaryButton?: {
    text: string;
    onClick: () => void;
  };
}

export default function HelpActionPopover({
  textElms,
  primaryButton,
  secondaryButton,
}: Readonly<Props>) {
  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(!show);
  };

  const onActionClick = (fn: () => void) => () => {
    setShow(false);
    fn();
  };

  const getActionButtons = () => {
    const buttons: JSX.Element[] = [];

    if (primaryButton) {
      buttons.push(
        <Button onClick={onActionClick(primaryButton.onClick)}>
          {primaryButton.text}
        </Button>
      );
    }

    if (secondaryButton) {
      buttons.push(
        <Button
          onClick={onActionClick(secondaryButton.onClick)}
          variant="secondary-blue"
        >
          {secondaryButton.text}
        </Button>
      );
    }

    if (buttons.length === 0) return null;
    return <div className="row-gap-8px">{buttons}</div>;
  };

  const getPopoverContent = () => {
    return (
      <div
        className="column-gap-16px"
        style={{ maxWidth: "350px", padding: "6px" }}
      >
        {textElms}
        {getActionButtons()}
      </div>
    );
  };

  const getPopover = () => (
    <Popover className="team-360-popover no-max-width">
      {getPopoverContent()}
    </Popover>
  );

  return (
    <OverlayTrigger
      rootClose
      placement="auto"
      overlay={getPopover()}
      show={show}
      onToggle={handleClick}
      trigger="click"
    >
      <FontAwesomeIcon icon={["far", "circle-info"]} />
    </OverlayTrigger>
  );
}
