export const getInstanceKey = ({
  startDate,
  endDate,
  instance,
}: {
  startDate?: string;
  endDate?: string;
  instance?: string | null;
}) => {
  if (instance) return `instance_${instance}`;
  if (!startDate || !endDate) return "latest_instance";
  return `${startDate}_${endDate}`;
};
