interface Props {
  bars: {
    label: string;
    value: number;
    dull?: boolean;
  }[];
}

export default function ShortCompareGraph({ bars }: Props) {
  const barComponents = bars.map((bar, idx) => {
    return (
      <div
        key={idx}
        className={`short-graph-bar ${bar.dull ? 'your' : 'my'}-score`}
      >
        <span
          style={{
            width: `${bar.value}%`,
          }}
          className="bar"
        />
        <span className="ms-2">{bar.label}</span>
        <span className="fw-bold">{bar.value}</span>
      </div>
    );
  });

  return <div className="short-graph">{barComponents}</div>;
}
