import React, { ReactNode } from 'react';
import CriteriaLogo from 'resources/icons/criteria-logo.svg';

interface Props {
  title: string;
  subTitle?: ReactNode;
  infoText?: string | null;
}

export default function LoginHeader({ title, subTitle, infoText }: Props) {
  return (
    <>
      <img
        src={CriteriaLogo}
        alt="Criteria"
        style={{ height: '64px' }}
        className="company-logo"
      />
      <div>
        <h1>{title}</h1>
        {subTitle}
      </div>
      {infoText ? <p className="login-card-info-text">{infoText}</p> : null}
    </>
  );
}
