export const ICON_MAP = {
  Target: {
    greetingMessage: "Let’s get started!",
    "What it is":
      "Target is about the team setting clear, impactful goals that are achievable.",
    "Why it matters":
      "Target setting is important because it helps the team focus its energy and resources on what matters most. This is key to achieving hard things.",
    "What success looks like":
      "Success looks like everyone in the team knowing the goals, feeling motivated by them, and consistently achieving them.",
    sequence: 1,
    next: "Empower",
  },
  Empower: {
    greetingMessage: "Great job! You just completed Section 1",
    "What it is":
      "Empower is about creating a safe and supportive space where everyone feels valued and motivated to contribute.",
    "Why it matters":
      "It's key because it boosts productivity, encourages open communication, and makes the team resilient and creative.",
    "What success looks like":
      "Success looks like a team where everyone feels free to share ideas, take calculated risks, and help each other.",
    sequence: 2,
    next: "Align",
  },
  Align: {
    greetingMessage: "Nice! You just completed Section 2",
    "What it is":
      "Align is about the team having clear roles, being able to manage conflicts, making good collective decisions, and understanding each other well.",
    "Why it matters":
      "It's vital because it ensures smooth collaboration, efficient conflict resolution, good decision-making, and a strong sense of unity in the team.",
    "What success looks like":
      "Success looks like a team where everyone communicates well, resolves conflicts quickly and constructively, and makes decisions together.",
    sequence: 3,
    next: "Monitor",
  },
  Monitor: {
    greetingMessage: "Almost there! This is the last section",
    "What it is":
      "Monitor is about the team effectively tracking their goal progress, keeping an eye on team dynamics, and being aware of their surroundings.",
    "Why it matters":
      "It's important because it allows the team to adapt to changes, spot potential problems early, and stay focused on their goals.",
    "What success looks like":
      "Success looks like a team that adjusts well to changes, keeps learning, maintains good morale, and holds themselves accountable for tracking progress.",
    sequence: 4,
    next: null,
  },
};

export const ALL_TEAM_360_FACTORS = [
  "Target",
  "Empower",
  "Align",
  "Monitor",
] as const;

export const TEAM360_DIMENSIONS_MAP = {
  Target: [
    "Pick the Right What",
    "Find the Goldilocks Zone",
    "Know Your How",
    "Build Buy-In",
  ],
  Empower: [
    "Feel Safe",
    "Help Each Other",
    "Believe You Can Do Big, Hard Things Together",
    "Motivate Each Other",
  ],
  Align: [
    "Know Your Teammates",
    "Communicate Well",
    "Manage (Don't Avoid) Conflict",
    "Make Good Decisions",
    "Stick Together",
  ],
  Monitor: [
    "Monitor Your Environment",
    "Learn Together",
    "Energize and Encourage",
    "Track Progress",
  ],
} as const;

export const CONSTRUCT_NAME_KEY = {
  "Pick the Right What": "Goal Specification",
  "Find the Goldilocks Zone": "Goal Difficulty",
  "Know Your How": "Strategy Formulation and Planning",
  "Build Buy-In": "Goal Commitment",
  "Feel Safe": "Psych Safety (individual)",
  "Help Each Other": "Group Organizational Citizenship Behaviors",
  "Believe You Can Do Big, Hard Things Together": "Team Potency",
  "Motivate Each Other": "Motivating",
  "Know Your Teammates": "Group Cognition",
  "Communicate Well": "Communication",
  "Manage (Don't Avoid) Conflict": "Conflict Management",
  "Make Good Decisions": "Decision-Making",
  "Stick Together": "Team Cohesion",
  "Monitor Your Environment": "Systems Monitoring",
  "Learn Together": "Team Learning",
  "Energize and Encourage": "Affect Management",
  "Track Progress": "Goal Progress",
} as {
  [key: string]: string;
};

export const TEAM_360_DIMENSION_DESCRIPTIONS = {
  "Pick the Right What":
    "Prioritize actions that align with team's mission and eliminate distractions",
  "Find the Goldilocks Zone":
    "Set challenging but achievable goals for optimal performance",
  "Know Your How":
    "Develop clear action plans to work efficiently and confidently.",
  "Build Buy-In":
    "Cultivate shared commitment to foster collaboration and accountability.",
  "Feel Safe":
    "Create a psychologically safe space for open communication and risk-taking.",
  "Help Each Other": "Promote collaboration through mutual assistance.",
  "Believe You Can Do Big, Hard Things Together":
    "Foster team resilience and adaptability for overcoming challenges.",
  "Motivate Each Other":
    "Encourage shared responsibility through mutual support and celebration.",
  "Know Your Teammates":
    "Understand each other's roles, strengths, and weaknesses for efficient collaboration.",
  "Communicate Well":
    "Ensure clarity and understanding through effective communication.",
  "Manage (Don't Avoid) Conflict":
    "Resolve conflicts quickly and prevent harmful group discord.",
  "Make Good Decisions":
    "Ensure quality and timely decisions through information gathering and collaboration.",
  "Stick Together":
    "Promote collaboration and productivity through unity and shared purpose.",
  "Monitor Your Environment":
    "Keep track of internal resources and external conditions for strategic decision making.",
  "Learn Together":
    "Foster innovation and improvement through continuous feedback and reflection.",
  "Energize and Encourage":
    "Enhance team well-being and productivity by promoting optimism and resilience.",
  "Track Progress":
    "Keep the team focused and accountable by tracking progress towards goals.",
} as { [key: string]: string };

export const TEAM_360_SURVEY_STATES = [
  "Overview",
  "Section Description",
  "Assessment",
  "Team Feedback 1",
  "Team Feedback 2",
] as const;

export const TEAM_360_EVENT_TYPES = [
  {
    label: "Recurring event",
    value: "Recurring event",
    recommended: false,
  },
  {
    label: "One-time event",
    value: "One-time event",
    recommended: false,
  },
] as const;

export const TEAM_360_EVENT_FREQUENCY = [
  {
    label: "Daily",
    value: "daily",
    recommended: false,
  },
  {
    label: "Weekly",
    value: "weekly",
    recommended: false,
  },
  {
    label: "Monthly",
    value: "monthly",
    recommended: false,
  },
  {
    label: "Quarterly",
    value: "quarterly",
    recommended: true,
  },
  {
    label: "Annually",
    value: "annually",
    recommended: false,
  },
] as const;

export const TEAM_360_KEEP_ASSESSMENT_OPEN_OPTIONS = [
  {
    label: "1 day",
    value: "1 day",
    recommended: false,
  },
  {
    label: "1 week",
    value: "1 week",
    recommended: false,
  },
  {
    label: "2 weeks",
    value: "2 weeks",
    recommended: false,
  },
  {
    label: "1 month",
    value: "1 month",
    recommended: true,
  },
] as const;

export const HIGH_AND_LOW_FACTOR_TEXT = {
  low: "This is the TEAM factor your team identified as having the most room to grow.",
  high: "This is the TEAM factor your team felt it was the strongest in.",
} as const;

export const TEAM_COMMENTS_KEY_MAP = {
  doingWellNeedsImprovement: { label: "Doing Well / Needs Improvement" },
  byWorkStyle: { label: "By Work Style" },
  byGender: {
    label: "By Gender",
  },
  // byLikelihoodToLeave: {
  //   label: 'By Likelihood to Leave',
  // },
  byTeam360Score: {
    label: "By Teamwork Score",
  },
} as const;
