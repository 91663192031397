import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InstanceShowing } from "./types";
import Button from "app/storybookComponents/Button";
import { getStringDate } from "./helpers";

interface Props {
  instanceShowing: InstanceShowing | null;
  onConfigureResults: () => void;
  hasPreviousInstances?: boolean;
  isLatestInstance?: boolean;
  hasEnoughResults?: boolean;
}

export default function InstanceWarningBanner({
  instanceShowing,
  hasEnoughResults,
  onConfigureResults,
  isLatestInstance,
  hasPreviousInstances,
}: Props) {
  const getBannerText = () => {
    if (
      instanceShowing?.startDate &&
      instanceShowing?.endDate &&
      !isLatestInstance
    ) {
      const startDateString = getStringDate(
        new Date(instanceShowing.startDate)
      );
      const endDateString = getStringDate(new Date(instanceShowing.endDate));

      return `This report is for the period ${startDateString} to ${endDateString}.`;
    }

    if (!hasEnoughResults) {
      return `The report for this instance is still pending.${
        hasPreviousInstances
          ? " You have past instances with TEAMscan results for this team."
          : ""
      }`;
    }

    if (isLatestInstance) {
      return "This report includes all responses from the most recent instance of the TEAMscan.";
    }

    return null;
  };

  return (
    <div
      className={`warning-banner${
        isLatestInstance && hasEnoughResults ? " blue " : " "
      }d-flex align-items-center justify-content-between`}
      style={{
        padding: "12px 16px",
      }}
    >
      <p>
        <b>{getBannerText()}</b>
      </p>
      <Button
        onClick={() => {
          onConfigureResults();
        }}
      >
        Configure view <FontAwesomeIcon icon="arrow-right" />
      </Button>
    </div>
  );
}
