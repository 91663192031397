import { Card, Collapse } from "react-bootstrap";
import Button from "app/storybookComponents/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StackBarChart from "app/storybookComponents/Charts/StackBarChart";
import { Team } from "app/containers/Global/types";
import { TeamSummaryAnalyticsText } from "app/containers/AdminConsole/types";
import { getTeam360Score } from "app/components/Team360Assessment/helpers";
import DimensionLinkedText from "app/components/TextWithLink/DimensionLinkedText";
import SmallUserCard from "./SmallUserCard";
import TeamSummaryKey from "./TeamSummaryKey";

interface Props {
  title?: string;
  teamSummary: TeamSummaryAnalyticsText | null;
  teams: {
    [teamId: number]: Team;
  };
  onSeeComparisonReport?: () => void;
  isExpanded?: boolean;
  setIsExpanded?: (isExpanded: boolean) => void;
}

export default function TeamSummaryCard({
  teamSummary,
  teams,
  title = "Team Summary",
  onSeeComparisonReport,
  isExpanded = false,
  setIsExpanded,
}: Props) {
  // -------------------------- Guard Clause --------------------------
  if (!teamSummary) return null;

  // -------------------------- Variables --------------------------
  const topScoringTeam = teamSummary
    ? teams[teamSummary.highestScoringTeam.teamId]
    : null;
  const lowestScoringTeam = teamSummary
    ? teams[teamSummary.lowestScoringTeam.teamId]
    : null;

  // -------------------------- Helper functions --------------------------
  const getFullScore = (score?: number | null) =>
    Math.round(getTeam360Score(score ?? 0));

  const teamCount = teamSummary?.teamCount;

  const getTotalText = () => {
    const teamOrTeams = teamCount === 1 ? "team" : "teams";
    return `${teamCount} total ${teamOrTeams}`;
  };

  return (
    <Card
      className="p-4 analytics-summary-card"
      role={isExpanded && setIsExpanded ? undefined : "button"}
      onClick={() => {
        if (isExpanded) return;
        setIsExpanded?.(true);
      }}
    >
      <div className="column-gap-8px">
        <div className="d-flex justify-content-between align-items-start">
          <div>
            <h2>{title}</h2>
          </div>
          {setIsExpanded ? (
            <div>
              <Button
                onClick={() => {
                  setIsExpanded?.(!isExpanded);
                }}
                variant="secondary-blue"
                className="border-0"
              >
                {!isExpanded ? "See details" : "Collapse"}
                <FontAwesomeIcon
                  icon={`caret-${isExpanded ? "up" : "down"}`}
                  className="ms-2"
                />
              </Button>
            </div>
          ) : null}
        </div>
        <Collapse in={isExpanded}>
          <p>{teamSummary?.teamSummaryText}</p>
        </Collapse>
      </div>
      <Collapse in={isExpanded}>
        <div className="column-gap-20px">
          <div className="column-gap-12px">
            <p className="count">
              <b>{getTotalText()}</b>
            </p>
            <StackBarChart
              high={teamSummary?.classificationArray["high-performing"]}
              medium={teamSummary?.classificationArray.healthy}
              low={teamSummary?.classificationArray["needs-help"]}
            />
          </div>
          <TeamSummaryKey type="team" />
          {teamCount > 1 && (
            <div className="low-and-high-summary-section">
              <div className="align-items-start column-gap-20px">
                <span className="list-span">Top Scoring Team</span>
                <div className="column-gap-12px">
                  <h3>
                    {topScoringTeam?.teamName}
                    {topScoringTeam?.teamName
                      .toLocaleLowerCase()
                      .includes("team")
                      ? ""
                      : " Team"}
                  </h3>
                  <p>
                    Overall Score:{" "}
                    {getFullScore(
                      teamSummary?.highestScoringTeam.scores.overall
                    )}
                  </p>
                </div>
                <SmallUserCard
                  userAccountId={topScoringTeam?.teamLeadUserAccountIds?.[0]}
                />
              </div>
              <div className="vertical-line" />
              <div className="align-items-start column-gap-20px">
                <span className="list-span">Lowest Scoring Team</span>
                <div className="column-gap-12px">
                  <h3>
                    {lowestScoringTeam?.teamName}
                    {lowestScoringTeam?.teamName
                      .toLocaleLowerCase()
                      .includes("team")
                      ? ""
                      : " Team"}
                  </h3>
                  <p>
                    Overall Score:{" "}
                    {getFullScore(
                      teamSummary?.lowestScoringTeam.scores.overall
                    )}
                  </p>
                </div>
                <SmallUserCard
                  userAccountId={lowestScoringTeam?.teamLeadUserAccountIds?.[0]}
                />
              </div>
            </div>
          )}

          <div className="snapshot-box" key="high-dimension">
            <FontAwesomeIcon icon={["far", "lightbulb-on"]} />
            <DimensionLinkedText
              linkText={teamSummary?.highestScoringDimensionText}
              baseLink="/SkillsGuide"
              showQuotationMarks
            />
          </div>
          <div className="snapshot-box" key="low-dimension">
            <FontAwesomeIcon icon={["far", "lightbulb-on"]} />
            <DimensionLinkedText
              linkText={teamSummary?.lowestScoringDimensionText}
              baseLink="/SkillsGuide"
              showQuotationMarks
            />
          </div>
          {onSeeComparisonReport ? (
            <div>
              <Button onClick={onSeeComparisonReport} variant="secondary-blue">
                See comparison report
              </Button>
            </div>
          ) : null}
        </div>
      </Collapse>
    </Card>
  );
}
