import AvatarCircle from "app/components/AvatarCircle";
import {
  selectAllCompanyUsersById,
  selectSampleUsersInfoById,
} from "app/containers/Global/slice";
import { useAppSelector } from "utils/redux/hooks";

interface Props {
  userAccountId: number | undefined;
}

export default function SmallUserCard({ userAccountId }: Props) {
  const sampleUsersById = useAppSelector(selectSampleUsersInfoById);
  const companyUsersById = useAppSelector(selectAllCompanyUsersById);
  const usersById = { ...sampleUsersById, ...companyUsersById };

  if (!userAccountId) return null;
  const user = usersById[userAccountId];
  if (!user) return null;
  return (
    <div className="small-user-card">
      <AvatarCircle userAccountId={user.userAccountId} />
      <div className="small-user-card-text">
        <h4>
          {user.firstName || ""} {user.lastName || ""}
        </h4>
        <span>{user.jobTitle ?? ""}</span>
      </div>
    </div>
  );
}
