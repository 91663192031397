import { ChangeEvent, Fragment, useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card } from "react-bootstrap";
import WarningBanner from "app/storybookComponents/WarningBanner";
import { downloadCSV } from "utils/helperFunctions";
import { TableStructureError } from "app/containers/HiddenAdminConsole/types";
import { ClipLoader } from "react-spinners";
import { parse } from "papaparse";
import {
  inviteUsersViaCSV,
  previewInviteUsersViaCSV,
  clearPreviewInviteUsersViaCSV,
  selectPreviewInviteUsersViaCSVStatus,
  selectPreviewInviteUsersViaCSVResponse,
  selectInviteUsersViaCSVStatus,
} from "app/containers/Global/slice";
import IncorrectHeaders from "app/storybookComponents/IncorrectHeaders";
import { useAppSelector, useAppDispatch } from "utils/redux/hooks";

interface Props {
  onHide?: () => void;
  teamId?: number;
  isLoading?: boolean;
  onCSVUploadSuccess?: () => void;
}

const TEMPLATE_COLUMNS_MAP = {
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
};

// TODO - remove the useAppselectors and useAppDispatches and pass them as props instead so that we can extract this component in the future.
export default function UploadCSVForm({
  onHide,
  teamId,
  isLoading,
  onCSVUploadSuccess,
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const previewInviteCSVStatus = useAppSelector(
    selectPreviewInviteUsersViaCSVStatus
  );
  const inviteCSVStatus = useAppSelector(selectInviteUsersViaCSVStatus);
  const previewInviteUsersViaCSVResponse = useAppSelector(
    selectPreviewInviteUsersViaCSVResponse
  );
  const [structureError, setStructureError] =
    useState<TableStructureError>(null);
  const [showGreenCheck, setShowGreenCheck] = useState(false);
  const [rowsAdded, setRowsAdded] = useState<number>(0);
  const [dataAdded, setDataAdded] = useState<
    {
      firstName: string;
      lastName: string;
      emailAddress: string;
    }[]
  >([]);
  const [error, setError] = useState<
    "file-size-error" | "error-headers" | null | "empty-file"
  >(null);

  useEffect(() => {
    if (
      previewInviteCSVStatus === "succeeded" &&
      previewInviteUsersViaCSVResponse?.validUsers?.length
    ) {
      setDataAdded(previewInviteUsersViaCSVResponse.validUsers);
      setRowsAdded(previewInviteUsersViaCSVResponse.validUsers.length);
    }
  }, [previewInviteUsersViaCSVResponse, previewInviteCSVStatus]);

  useEffect(() => {
    return () => {
      // Clean up
      dispatch(clearPreviewInviteUsersViaCSV());
    };
  }, [dispatch]);

  const downloadTemplateCSV = () => {
    const data: string[][] = [Object.values(TEMPLATE_COLUMNS_MAP)];
    data.push(Object.values(TEMPLATE_COLUMNS_MAP).map(() => "")); // Add an empty row
    downloadCSV("template", data);
  };

  const onInvite = async () => {
    await dispatch(inviteUsersViaCSV({ rows: dataAdded, teamId }));
    onCSVUploadSuccess?.();
  };

  const onCSVUploadError = (
    err: any,
    file: any,
    inputElem: any,
    reason: any
  ) => {
    console.error(
      "err, file, inputElem, reason:",
      err,
      file,
      inputElem,
      reason
    );
  };

  const checkTableStructure = ({
    data,
    meta: { fields },
  }: {
    data: {
      [columnName: string]: string | number;
    }[];
    meta: { fields: string[] };
  }) => {
    const mismatchPositions: number[] = [];

    Object.values(TEMPLATE_COLUMNS_MAP).forEach((columnHeader, idx) => {
      if (columnHeader !== fields[idx]) {
        mismatchPositions.push(idx);
      }
    });

    // // If there are no mismatching column names, then we set the state to success and save the data the is being uploaded to our state.
    if (mismatchPositions.length === 0) {
      const updatedUploadedData: {
        firstName: string;
        lastName: string;
        emailAddress: string;
      }[] = [];
      data.forEach((row) => {
        if (!row["First Name"] || !row["Last Name"] || !row["Email"]) {
          return;
        }
        updatedUploadedData.push({
          firstName: row["First Name"] as string,
          lastName: row["Last Name"] as string,
          emailAddress: row["Email"] as string,
        });
      });

      // if the uploaded file is empty, we set the error to empty-file
      if (updatedUploadedData.length === 0) {
        setError("empty-file");
        return;
      }

      setDataAdded(updatedUploadedData);
      setRowsAdded(data.length);
      setShowGreenCheck(true);
      dispatch(previewInviteUsersViaCSV(updatedUploadedData));
    } else {
      // Or else we set the structure error, so that the user is able to see which rows are not matching
      setError("error-headers");
      setStructureError({
        existingColumns: Object.values(TEMPLATE_COLUMNS_MAP),
        uploadedColumns: fields,
        mismatchPositions,
      });
    }
  };

  // Validating csv upload, checking header and setting new users in state
  const handleUpload = (event: ChangeEvent<HTMLInputElement>) => {
    setError(null);
    if (
      !event?.target?.files ||
      inputRef?.current?.files?.length === undefined ||
      inputRef.current.files.length === 0
    ) {
      dispatch(clearPreviewInviteUsersViaCSV());
      setShowGreenCheck(false);
      return;
    }

    const uploadFileIsCSV =
      event.target.files.length > 0 &&
      /.+(.csv)$/.test(event.target.files[0].name);
    if (uploadFileIsCSV && event.target.files[0].size > 5 * 1024 * 1024) {
      setError("file-size-error");
    } else if (uploadFileIsCSV) {
      // @ts-ignore
      parse(event.target.files[0], {
        delimiter: "",
        chunkSize: 3,
        header: true,
        complete: checkTableStructure,
        error: onCSVUploadError,
      });
    }
  };

  const getResultCard = () => {
    const invalidMap: { [errorType: string]: string[] } = {};
    previewInviteUsersViaCSVResponse?.invalidUsers?.forEach((user) => {
      if (user.errors) {
        user.errors.forEach((error) => {
          if (invalidMap[error]) {
            invalidMap[error].push(user.emailAddress);
          } else {
            invalidMap[error] = [user.emailAddress];
          }
        });
      }
    });

    const invalidEmails = Object.entries(invalidMap).map(([key, value]) => {
      if (value.length === 0) {
        return null;
      }

      return (
        <Fragment key={key}>
          <WarningBanner type="light-red">
            <FontAwesomeIcon icon="exclamation-triangle" className="me-2" />
            {key}
          </WarningBanner>
          <Card
            className="invalid-emails"
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "12px 8px",
              flexDirection: "row",
              padding: "8px",
            }}
          >
            {value.map((email, idx) => (
              <span
                className="invalid-email invalid-box"
                key={`${email}${idx}`}
              >
                {email}
              </span>
            ))}
          </Card>
        </Fragment>
      );
    });

    return (
      <Card style={{ padding: "20px" }} className="column-gap-12px">
        <p>
          <span className="sapphire-150-text">Total: </span>
          <b>
            {rowsAdded} email address
            {rowsAdded > 1 ? "es" : ""}
          </b>
        </p>
        {invalidEmails.length === 0 ? (
          <p>
            <span className="sapphire-150-text">Invalid Email Addresses:</span>{" "}
            <b>None</b>
          </p>
        ) : (
          invalidEmails
        )}
      </Card>
    );
  };

  const getCheckingComponent = () => {
    if (previewInviteCSVStatus === "idle") {
      return null;
    } else if (error === "empty-file") {
      return (
        <p>
          Sorry, the file you uploaded is empty. Please double check your file
          and try again.
        </p>
      );
    } else if (error === "file-size-error") {
      return "Sorry The file is to big...";
    } else if (error === "error-headers") {
      return <IncorrectHeaders structureError={structureError} />;
    }

    return (
      <>
        <div className="column-gap-12px">
          <h3>
            {previewInviteCSVStatus === "loading"
              ? "Checking File Structure and Email Domains..."
              : "Invite Summary"}
          </h3>
          {previewInviteCSVStatus === "loading" ? (
            <ClipLoader color="#36d7b7" speedMultiplier={0.5} />
          ) : (
            getResultCard()
          )}
        </div>
        <div>
          <Button
            className="me-2"
            onClick={onInvite}
            disabled={
              previewInviteCSVStatus === "loading" ||
              inviteCSVStatus === "loading" ||
              isLoading
            }
          >
            Send Invite
          </Button>
          <Button
            variant="outline-primary"
            className="me-2"
            onClick={() => {
              if (onHide) {
                onHide();
              }
            }}
          >
            Cancel
          </Button>
        </div>
      </>
    );
  };

  return (
    <div>
      <div className="column-gap-20px">
        <div>
          <p className="mb-0">
            To invite users via .csv file, please download this{" "}
            <span
              onClick={() => downloadTemplateCSV()}
              role="button"
              className="link-primary"
            >
              template (.csv)
            </span>
          </p>
          <p className="mb-0">
            Note: This template file is a blank .csv file with the following
            columns: First Name, Last Name, Email
          </p>
        </div>
        <p>Then, upload the completed file below:</p>
        <div>
          <input
            type="file"
            id="fileUpload"
            className="csv-upload"
            accept=".csv"
            onChange={(e) => handleUpload(e)}
            ref={inputRef}
          />
          {showGreenCheck ? (
            <FontAwesomeIcon icon="check" color="green" />
          ) : null}
          <p style={{ color: "#86888b", marginTop: "10px" }}>
            File size limit: 5 MB
          </p>
        </div>
        {getCheckingComponent()}
      </div>
    </div>
  );
}
