import { createEntityAdapter } from "@reduxjs/toolkit";
import {
  TeamInvitationNotification,
  PendingAssessmentNotification,
} from "./types";

export const teamInvitationAdaptor =
  createEntityAdapter<TeamInvitationNotification>({
    selectId: (e) => e.teamId,
    sortComparer: (a, b) => a.notificationId - b.notificationId, // This is what decides the order of entites in the array
  });

export const pendingAssessmentNotificationAdaptor =
  createEntityAdapter<PendingAssessmentNotification>({
    selectId: (e) => e.teamId,
    sortComparer: (a, b) => b.assessmentEventId - a.assessmentEventId, // This is what decides the order of entites in the array
  });
