import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "app/storybookComponents/Button";
import { memo, useState } from "react";
import { Collapse } from "react-bootstrap";

interface Props {
  title: string;
  feedbackArr: {
    feedbackText: string;
    feedbackDate: string;
    from: string;
    type?: "Department" | "Team";
  }[];
  defaultIsCollapsed?: boolean;
}

const CollapsibleCard = ({
  title,
  feedbackArr,
  defaultIsCollapsed = true,
}: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(defaultIsCollapsed);

  const getUserFeedback = () =>
    feedbackArr.map((item, index) => {
      return (
        <div key={index} className="user-feedback">
          <div className="anonymous-user">
            <FontAwesomeIcon icon={["fal", "user"]} />
          </div>
          <div>
            <p className="quote">"{item.feedbackText}"</p>
            <p className="author">
              Team member from the {item.from} Department (from{" "}
              {getDateText(item.feedbackDate)})
            </p>
          </div>
        </div>
      );
    });

  const getDateText = (dateText: string) => {
    const date = new Date(dateText);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear().toString().substring(-2);
    return `${month}/${day}/${year}`;
  };

  const getCardTitle = (text: string) => {
    const separatorIndex = text?.indexOf(":");
    if (separatorIndex === -1 || separatorIndex === undefined) {
      return <p>{text}</p>;
    }

    const firstPart = text.substring(0, separatorIndex);
    const secondPart = text.substring(separatorIndex + 1);
    return (
      <p>
        <span className="fw-bold">{firstPart}:</span>
        {secondPart}
      </p>
    );
  };

  return (
    <div className="collapsible-card">
      <div>{getCardTitle(title)}</div>
      <Collapse in={!isCollapsed}>
        <div>{getUserFeedback()}</div>
      </Collapse>
      <Button
        variant="secondary-blue"
        onClick={() => setIsCollapsed(!isCollapsed)}
        className="border-0"
      >
        {isCollapsed ? "See responses" : "Hide responses"}
        <FontAwesomeIcon
          icon={`caret-${isCollapsed ? "down" : "up"}`}
          className="ms-2"
        />
      </Button>
    </div>
  );
};

export default memo(CollapsibleCard);
