import { useAppSelector } from "utils/redux/hooks";
import { selectAllTeam360Results } from "app/components/SurveyDataInstances/slice";
import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import {
  ALL_TEAM_360_FACTORS,
  TEAM360_DIMENSIONS_MAP,
  CONSTRUCT_NAME_KEY,
} from "./constants";
import SimpleModal from "../Modals/SimpleModal";
import Team360ResultsCardAccordion2 from "./Team360ResultsCardAccordion2";
import {
  selectTipsAndInterpretiveTextByTeamId,
  selectUserTeam360AssessmentResultsByTeamId,
} from "app/containers/TeamGuide/slice";
import { getEntries } from "utils/helperFunctions";
import { useSearchParams } from "react-router-dom";
import { getMainAccordionValue } from "./helpers";

export interface Props {
  onHide: () => void;
  show?: boolean;
  teamId?: number;
  departmentId?: number;
  modalSearchParam?: string;
  startDate?: string;
  endDate?: string;
  instance?: string | null;
}

export default function Team360ScoreBreakdownModal({
  show,
  onHide,
  teamId,
  departmentId,
  modalSearchParam,
  startDate,
  endDate,
  instance,
}: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const team360Scores = useAppSelector(
    selectAllTeam360Results({ startDate, endDate, instance })
  );
  const userTeam360Results = useAppSelector(
    selectUserTeam360AssessmentResultsByTeamId(teamId)
  );
  const tipsAndInterpretiveText = useAppSelector(
    selectTipsAndInterpretiveTextByTeamId(teamId)
  );
  const [
    team360ScoreBreakdownModalDropdownSelection,
    setTeam360ScoreBreakdownModalDropdownSelection,
  ] = useState<"4 Factors" | "17 Dimensions">("4 Factors");

  useEffect(() => {
    const eventKey = searchParams.get(
      "team360ScoreBreakdownModalDropdownSelection"
    );
    if (eventKey !== "4 Factors" && eventKey !== "17 Dimensions") return;
    setTeam360ScoreBreakdownModalDropdownSelection(eventKey);
  }, [searchParams]);

  const getTeamScores = () => {
    if (userTeam360Results) {
      return userTeam360Results.teamScores;
    }
    if (teamId) {
      return team360Scores?.teamScores[teamId];
    }
    return null;
  };

  const teamScores = getTeamScores();
  const userScores = userTeam360Results ? userTeam360Results.userScores : null;
  const companyScores = team360Scores ? team360Scores.companyScores : null;
  const departmentScores =
    team360Scores && departmentId
      ? team360Scores.departmentScores[departmentId]
      : null;

  const getGraphValues = () => {
    // If no team Id return empty array
    const values: {
      name: string;
      yourTeamScore: number;
      yourScore?: number;
      yourCompanyScore?: number;
      description?: string;
      redirectPath?: string;
      buttonText?: string;
      iconName?: string;
      yourDepartmentScore?: number;
    }[] = [];

    // If the state is 4 factors we should iterate through the 4 factors and get the score for each factor
    if (team360ScoreBreakdownModalDropdownSelection === "4 Factors") {
      ALL_TEAM_360_FACTORS.forEach((factor) => {
        values.push({
          name: factor,
          yourScore: userScores?.factors?.[factor] ?? 0,
          yourTeamScore: teamScores?.factors?.[factor] ?? 0,
          yourCompanyScore: companyScores?.factors?.[factor] ?? 0,
          yourDepartmentScore: departmentScores?.factors?.[factor] ?? 0,
          buttonText: "See skill guide",
          description:
            tipsAndInterpretiveText?.factors?.[factor]?.interpretiveText,
          redirectPath: `/SkillsGuide?factor=${factor}`,
        });
      });
    } else if (
      team360ScoreBreakdownModalDropdownSelection === "17 Dimensions"
    ) {
      getEntries(TEAM360_DIMENSIONS_MAP).forEach(([factor, dimensions]) => {
        dimensions.forEach((dimension) => {
          const dimensionName = CONSTRUCT_NAME_KEY[dimension] ?? dimension;
          values.push({
            name: dimension,
            yourScore: userScores?.dimensions?.[dimensionName] ?? 0,
            yourTeamScore: teamScores?.dimensions?.[dimensionName] ?? 0,
            yourCompanyScore: companyScores?.dimensions?.[dimensionName] ?? 0,
            yourDepartmentScore:
              departmentScores?.dimensions?.[dimensionName] ?? 0,
            description:
              tipsAndInterpretiveText?.dimensions?.[dimensionName]
                ?.interpretiveText,
            buttonText: "See dimension guide",
            redirectPath: `/SkillsGuide?factor=${factor}&dimension=${dimension}`,
            iconName: factor,
          });
        });
      });
    }

    // Lastly we shall sort the values by the value, where the highest values will appear towards the bottom.
    values.sort((a, b) => {
      const aVal = getMainAccordionValue(
        a.yourTeamScore,
        a.yourScore,
        a.yourDepartmentScore,
        a.yourCompanyScore
      );

      const bVal =
        getMainAccordionValue(
          b.yourTeamScore,
          b.yourScore,
          b.yourDepartmentScore,
          b.yourCompanyScore
        ) ?? 0;

      return bVal - aVal;
    });
    return values;
  };

  const getDropdown = () => {
    const onStateSelect = (eventKey: string | null) => {
      if (eventKey !== "4 Factors" && eventKey !== "17 Dimensions") return;
      setSearchParams(
        (searchParams) => {
          searchParams.set(
            "team360ScoreBreakdownModalDropdownSelection",
            eventKey
          );
          return searchParams;
        },
        {
          replace: true,
        }
      );
      setTeam360ScoreBreakdownModalDropdownSelection(eventKey);
    };

    return (
      <Dropdown onSelect={onStateSelect}>
        <Dropdown.Toggle
          variant="light"
          id="dropdown-basic"
          className="dropdown-menu-240px"
        >
          {team360ScoreBreakdownModalDropdownSelection}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-240px">
          <Dropdown.Item eventKey="4 Factors">4 Factors</Dropdown.Item>
          <Dropdown.Item eventKey="17 Dimensions">17 Dimensions</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const getDescriptionText = () => {
    if (team360ScoreBreakdownModalDropdownSelection === "4 Factors") {
      return "View the TEAM model's four key areas: Target, Empower, Align, and Monitor. Each area represents a cluster of 4-5 dimensions, totaling 17 dimensions. Click in any area to see more details.";
    }
    return "Explore the 17 dimensions making up the TEAM model. Each dimension falls under one of the four main areas, represented by its respective icon. Click in any dimension to see more details.";
  };

  const onHideModal = () => {
    setSearchParams((sp) => {
      if (modalSearchParam) {
        sp.delete(modalSearchParam);
      }
      sp.delete("team360ScoreBreakdownModalDropdownSelection");
      return sp;
    });
    onHide();
  };

  return (
    <SimpleModal show={show} onHide={onHideModal} title="Score Breakdown">
      <>
        <p>{getDescriptionText()}</p>
        <div className="row-gap-20px">
          <div>{getDropdown()}</div>
        </div>
        <div>
          <Team360ResultsCardAccordion2 values={getGraphValues()} />
        </div>
      </>
    </SimpleModal>
  );
}
