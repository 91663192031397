export const TEAM_SUMMARY_KEY_BREAKDOWN = [
  {
    title: "REPLACE_ME in need of help",
    description: "<60 overall score & <50 on 4 or more core dimensions",
    className: "need-help",
  },
  {
    title: "Healthy REPLACE_ME",
    description: ">60 overall score & >50 on at least 14 to 17 core dimensions",
    className: "healthy",
  },
  {
    title: "High-performing REPLACE_ME",
    description: ">70 overall score & >70 on all core dimensions",
    className: "high",
  },
] as const;

export const ADMIN_CONSOLE_TABS = [
  "Departments",
  "Surveys",
  "SurveyBreakdown",
  "Teams",
  "People",
  "Settings",
  "Billing",
  "Analytics",
] as const;
