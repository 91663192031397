import NoResultsAvailable from "resources/images/results-not-found.png";
import GuideSuccessIllustration from "resources/images/guide-success-illustration.png";
import TEAM360Logo from "resources/icons/TEAM-assessment-logo.svg";
import { OnboardingModalType, OnboardingStateTemplate } from "./types";

const TEAM_MEMBER_LIST_ITEMS = [
  {
    title: "Provide Honest Feedback",
    description:
      "Your input can help identify strengths and areas for improvement within your team.",
  },
  {
    title: "Learn About Your Team",
    description:
      "Gain insights into how you and your teammates perceive your team's dynamics.",
  },
  {
    title: "Professional Growth",
    description:
      "Understanding the mechanics of effective teamwork can improve your own performance and potentially open up leadership opportunities.",
  },
];

const TEAM_MEMBER_BODY_HEADER = {
  title: "Check back once your team leader has launched the TEAMscan.",
  description:
    "Your perspective is crucial to your team's success. Participating in the TEAMscan Survey helps you contribute to your team's development. Here's how:",
};

const TEAM_MEMBER_FOOTER_MSG =
  "By participating in the TEAMscan Survey, you’re not only helping to improve your team but also paving the way for your own professional development. Once your team’s leader has launched a TEAMscan, you’ll be able to see the results here.";

// This constant will be used to show the modal for when the user clicks on the Team360 tab and the results are not available yet. (Team Member)
export const TEAM_MEMBER_NO_TEAM_360_RESULTS: OnboardingStateTemplate = {
  modalTitle: (
    <>
      <div>
        <img src={NoResultsAvailable} alt="No Results" />
      </div>
      <div>
        <h1>TEAMscan Results</h1>
        <h1>Not Available Yet</h1>
      </div>
    </>
  ),
  bodyHeader: TEAM_MEMBER_BODY_HEADER,
  bodyFooter: TEAM_MEMBER_FOOTER_MSG,
  dismissButtonText: "Back",
  listItems: TEAM_MEMBER_LIST_ITEMS,
};

// This constant will be user when the team does have results and they open the Team360 tab for the first time. (Team Member)
export const TEAM_MEMBER_FIRST_TIME_OPEN_TEAM_360: OnboardingStateTemplate = {
  modalTitle: (
    <>
      <div>
        <img src={TEAM360Logo} alt="TEAMscan" />
      </div>
      <div>
        <h1>Welcome to</h1>
        <h1>TEAMscan Results</h1>
      </div>
    </>
  ),
  bodyHeader: TEAM_MEMBER_BODY_HEADER,
  bodyFooter: TEAM_MEMBER_FOOTER_MSG,
  dismissButtonText: "Check it out",
  listItems: TEAM_MEMBER_LIST_ITEMS,
};

// If the team lead has not yet scheduled a Team360, this modal will be shown when the user clicks on the Team360 tab. (Team Lead)
export const TEAM_LEADER_TEAM_360_NOT_SCHEDULED_YET: OnboardingStateTemplate = {
  modalTitle: (
    <>
      <div>
        <img src={TEAM360Logo} alt="TEAMscan" />
      </div>
      <div>
        <h1>TEAMscan Results</h1>
        <h1>Not Available Yet</h1>
      </div>
    </>
  ),
  bodyHeader: {
    title: "Unleash Your Team’s Potential",
    description:
      "When everyone takes this quick, seemingly simple TEAMscan survey, you get powerful insights about team strengths, growth areas, and sources of agreement and disagreement across 17 proven teamwork dimensions. This enables you to:",
  },
  bodyFooter:
    "Improving and managing your team's score will not only make you a more effective leader but also create a more harmonious, efficient, and happy work environment.",
  dismissButtonText: "Launch the TEAMscan",
  listItems: [
    {
      title: "Elevate Productivity and Team Cohesion",
      description:
        "Harness insights to power real dialogue that boosts engagement, enhances collaboration, surfaces the unique strengths within your team, and creates an environment where everyone thrives.",
    },
    {
      title: "Benchmark and Monitor Team Growth",
      description:
        "Compare your team against organization and department-level standards, track growth over time, and ensure you know the direction and pace of your progress.",
    },
    {
      title: "Foster Development and Reduce Turnover",
      description:
        "Pinpoint areas of improvement, enable tailored development strategies, and create a supportive atmosphere that increases job satisfaction and retention.",
    },
  ],
};

export const USER_GUIDE_ONBOARDING: OnboardingStateTemplate = {
  modalTitle: (
    <>
      <div>
        <img src={GuideSuccessIllustration} alt="User Guide" />
      </div>
      <div>
        <h1>Create Your User Guide</h1>
      </div>
    </>
  ),
  bodyHeader: {
    description:
      "A User Guide is a powerful tool that helps you share essential and fun information with your team, fostering better collaboration and understanding. It also allows users from across the organization to discover and connect with you based on your skills and interests.",
  },
  bodyFooter:
    "Improving and managing your team's score will not only make you a more effective leader but also create a more harmonious, efficient, and happy work environment.",
  dismissButtonText: "Close",
  listItems: [
    {
      title: "Find and Be Found",
      description:
        "Build meaningful relationships with your team and others, making you discoverable for shared interests and expertise.",
    },
    {
      title: "Enhance Communication",
      description:
        "Get to know your teammates better, leading to improved communication and stronger bonds.",
    },
    {
      title: "Showcase Your Strengths",
      description:
        "Highlight your top strengths, empowering your team to collaborate effectively.",
    },
    {
      title: "Share Your Goals",
      description:
        "Encourage alignment and collaboration towards common objectives.",
    },
    {
      title: "Build Trust",
      description:
        "Foster trust among team members through openness and transparency.",
    },
  ],
};

export const TEAM_GUIDE_ONBOARDING: OnboardingStateTemplate = {
  modalTitle: (
    <>
      <div>
        <img src={GuideSuccessIllustration} alt="Team Guide" />
      </div>
      <div>
        <h1>Create Your Team Guide</h1>
      </div>
    </>
  ),
  bodyHeader: {
    description:
      "A Team Guide gives a snapshot of your team's identity and operations, empowering members to thrive and collaborate effectively. It also enables participation in the TEAMscan survey for continuous improvement.",
  },
  bodyFooter:
    "Crafting your Team Guide sets the foundation for high-performing teamwork, enabling continuous learning and improvement through the TEAMscan survey.",
  dismissButtonText: "Close",
  listItems: [
    {
      title: "TEAMscan Insights",
      description:
        "Participate in the TEAMscan survey for valuable insights and growth opportunities.",
    },
    {
      title: "Seamless Onboarding",
      description:
        "New members easily onboard and understand your team's purpose and values.",
    },
    {
      title: "Get Discovered",
      description:
        "Collaborate with teams and people across the organization with shared interests.",
    },
    {
      title: "Alignment and Standards",
      description:
        "Establish shared standards, ensuring productive and efficient teamwork.",
    },
    {
      title: "Build Trust and Support",
      description:
        "Foster trust and psychological safety by encouraging mutual support within the team.",
    },
  ],
};

export const ORGANIZATION_GUIDE_ONBOARDING: OnboardingStateTemplate = {
  modalTitle: (
    <>
      <div>
        <img src={GuideSuccessIllustration} alt="Organization Guide" />
      </div>
      <div>
        <h1>Create Your Organization Guide</h1>
      </div>
    </>
  ),
  bodyHeader: {
    description:
      "An Organization Guide is the go-to comprehensive resource for everything about your company.",
  },
  bodyFooter:
    "A centralized hub that benefits both new and existing employees, enhancing shared identity and organizational cohesion.",
  dismissButtonText: "Close",
  listItems: [
    {
      title: "Educate",
      description:
        "Quickly onboard new hires with essential information about your mission, values, and history.",
    },
    {
      title: "Connect",
      description:
        "Link to key internal and external resources, making it easier for employees to find what they need.",
    },
    {
      title: "Navigate",
      description:
        "Easily find and contact team and department leaders and HR personnel.",
    },
  ],
};

export const TEAM_360_GENERAL_INFORMATION: OnboardingStateTemplate = {
  modalTitle: (
    <>
      <div>
        <img src={TEAM360Logo} alt="TEAMscan" />
      </div>
      <div>
        <h1>TEAMscan Survey</h1>
        <h1 style={{ fontWeight: "normal" }}>and Results</h1>
      </div>
    </>
  ),
  bodyHeader: {
    description:
      "The TEAMscan was built by Criteria’s team of organizational psychologists and is a multi-rater survey that provides a holistic view of teamwork within your organization. ",
  },
  bodyFooter:
    "Drive continuous improvement by identifying best practices, practicing targeted skills and promoting valuable conversations across your organization.",
  dismissButtonText: "Close",
  listItems: [
    {
      title: "Measure",
      description:
        "Evaluate teams on a regular, recurring schedule based on 17 scientifically-proven dimensions of effective teamwork.",
    },
    {
      title: "Analyze",
      description:
        "Track team performance over time and compare across departments for actionable insights.",
    },
    {
      title: "Improve",
      description:
        "Team leaders and members get suggested skills from a library of 100+ skills and suggested conversations to develop your teams further.",
    },
  ],
};

export const ONBOARDING_MODAL_MAP = {
  generalTeam360Information: TEAM_360_GENERAL_INFORMATION,
  visitedTeam360TabWithoutResults: TEAM_MEMBER_NO_TEAM_360_RESULTS,
  visitedTeam360TabWithResults: TEAM_MEMBER_FIRST_TIME_OPEN_TEAM_360,
  team360LeadViewNotScheduledYet: TEAM_LEADER_TEAM_360_NOT_SCHEDULED_YET,
  userGuideOnboarding: USER_GUIDE_ONBOARDING,
  teamGuideOnboarding: TEAM_GUIDE_ONBOARDING,
  organizationGuideOnboarding: ORGANIZATION_GUIDE_ONBOARDING,
} as { [key in OnboardingModalType]: OnboardingStateTemplate };
