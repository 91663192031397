import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { request } from "utils/request";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import { ProfileCompleteResponse } from "./types";

export interface ProfileCompleteState {
  percentComplete: number;
  moreThanPercentage: number;
  gettingPercentCompleteStatus: responseStatus;
}

const initialState: ProfileCompleteState = {
  percentComplete: 0,
  moreThanPercentage: 0,
  gettingPercentCompleteStatus: "idle",
};

export const getPercentComplete = createAsyncThunk(
  "getPercentComplete",
  async (userAccountId: number) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/users/${userAccountId}/percentage`;
    return (await request(requestUrl, {
      method: "GET",
    })) as ProfileCompleteResponse;
  }
);

export const profileCompleteSlice = createSlice({
  name: "profileComplete",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPercentComplete.pending, (state) => {
      state.gettingPercentCompleteStatus = "loading";
    });
    builder.addCase(getPercentComplete.fulfilled, (state, action) => {
      state.gettingPercentCompleteStatus = "succeeded";
      state.percentComplete = action.payload.percentComplete;
      state.moreThanPercentage = action.payload.moreThanPercentage;
    });
    builder.addCase(getPercentComplete.rejected, (state) => {
      state.gettingPercentCompleteStatus = "failed";
    });
  },
});

export const selectPercentComplete = (state: RootState) =>
  state.profileComplete.percentComplete;
export const selectMoreThanPercentage = (state: RootState) =>
  state.profileComplete.moreThanPercentage;
export const selectGettingPercentCompleteStatus = (state: RootState) =>
  state.profileComplete.gettingPercentCompleteStatus;

export default profileCompleteSlice.reducer;
