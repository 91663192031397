import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "app/storybookComponents/Button";
import { Team, TeamMember } from "app/containers/Global/types";
import { useState, useEffect, useLayoutEffect, useRef } from "react";
import { selectDepartments } from "../../AdminConsole/slice";
import { Card, Nav, Form } from "react-bootstrap";
import TeamTable from "./TeamTable";
import TeamSettingsModal from "../Modals/TeamSettingsModal";
import { useAppSelector, useAppDispatch } from "utils/redux/hooks";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { responseStatus } from "utils/types";
import AvatarCircle from "app/components/AvatarCircle";
import WarningModal from "app/storybookComponents/Modals/WarningModal";
import {
  selectUpdateTeamStatus,
  editTeamMemberFromTeam,
  removeTeamMemberFromTeam,
  selectUpdateTeamMemberByTeamMemberIdStatus,
  selectRemoveTeamMemberFromTeamStatus,
} from "app/containers/Global/slice";
import Select from "react-select";
import SurveysTable from "./SurveysTable";
import { getTeamAssessmentInstances } from "../slice";

interface Props {
  teamInfo: Team;
  teamMembers?: TeamMember[];
  showInviteModal: () => void;
  onDeleteTeam: () => void;
  deleteTeamStatus: responseStatus;
  onUpdateTeam: (team: Partial<Team>) => void;
}

// TODO: Need to create modal to show who can invite people to the team and display those members who already have access.
export default function TeamSettings({
  teamInfo,
  teamMembers,
  showInviteModal,
  onDeleteTeam,
  deleteTeamStatus,
  onUpdateTeam,
}: Readonly<Props>) {
  // --------------------- Selectors ---------------------
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const updateTeamStatus = useAppSelector(selectUpdateTeamStatus);
  const updateTeamMemberStatus = useAppSelector(
    selectUpdateTeamMemberByTeamMemberIdStatus
  );
  const removeTeamMemberStatus = useAppSelector(
    selectRemoveTeamMemberFromTeamStatus
  );
  const departments = useAppSelector(selectDepartments);

  // --------------------- UseState ---------------------
  const [editTeamModal, setEditTeamModal] = useState<
    null | "Name" | "Department" | "Who Can Invite"
  >(null);
  const [tabShowing, setTabShowing] = useState<
    "members" | "settings" | "surveys"
  >("settings");

  const [showWarningModal, setShowWarningModal] = useState<
    | false
    | ({
        warningTitle: string;
        warningMessage: string;
        extraBody?: React.ReactNode;
      } & (
        | {
            modalTitle: "Change Permissions";
            isDanger: false;
            customButtonText: "Confirm Changes";
            teamMemberId: number;
            isTeamLead: 0 | 1;
            userAccountId: number;
          }
        | {
            modalTitle: "Remove Team Member";
            isDanger: true;
            customButtonText: "Remove";
            teamMemberId: number;
            userAccountId: number;
          }
        | {
            modalTitle: "Deactivate Team";
            isDanger: true;
            customButtonText: "Deactivate Team";
          }
      ))
  >(false);
  const [value, setValue] = useState<string>("");
  const [isPrivate, setIsPrivate] = useState<boolean>(!!teamInfo?.private);
  const [membersInTeam, setMembersInTeam] = useState<
    (TeamMember & { isPending?: boolean })[]
  >([]);

  // --------------------- UseEffect ---------------------

  useLayoutEffect(() => {
    if (!teamInfo) {
      return;
    }

    const newMembers: {
      [key: string]: TeamMember & { isPending?: boolean };
    } = {};

    teamInfo.teamMembers?.forEach((teamMember) => {
      newMembers[teamMember.userAccountId] = teamMember;
    });

    teamInfo.pendingTeamMemberIds?.forEach((userAccountId) => {
      if (!newMembers[userAccountId]) {
        return;
      }
      newMembers[userAccountId] = {
        ...newMembers[userAccountId],
        isPending: true,
      };
    });

    setMembersInTeam(Object.values(newMembers));
  }, [teamInfo]);

  useEffect(() => {
    if (updateTeamStatus === "succeeded") {
      setEditTeamModal(null);
    }
  }, [updateTeamStatus]);

  useEffect(() => {
    if (updateTeamMemberStatus === "succeeded") {
      setShowWarningModal(false);
    }
  }, [updateTeamMemberStatus]);

  useEffect(() => {
    if (removeTeamMemberStatus === "succeeded") {
      setShowWarningModal(false);
    }
  }, [removeTeamMemberStatus]);

  useLayoutEffect(() => {
    const eventKey = searchParams.get("teamSettingsActiveTab");
    if (
      eventKey === "members" ||
      eventKey === "settings" ||
      eventKey === "surveys"
    ) {
      setTabShowing(eventKey);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!teamInfo?.teamId) return;
    dispatch(getTeamAssessmentInstances(teamInfo?.teamId));
  }, [dispatch, teamInfo]);

  // --------------------- Functions ---------------------
  const getTabContent = () => {
    switch (tabShowing) {
      case "members":
        return getMemberTabContent();
      case "settings":
        return getGeneralSettingsContent();
      case "surveys":
        return getSurveyTabContent();
    }
  };

  const getDepartmentName = () =>
    teamInfo?.departmentId
      ? departments[teamInfo?.departmentId ?? 0]?.name ?? "No Department"
      : "No Department";

  const getTEAM360ResultsVisibilityValue = () => {
    switch (teamInfo?.team360ResultsVisibility) {
      case 1:
        return { value: "1", label: "Everyone" };
      case 2:
      default:
        return { value: "2", label: "Active Team Members" };
    }
  };

  const getMemberTabContent = () => {
    return (
      <>
        <div>
          <h2 className="d-inline w-auto me-3">Manage Team</h2>
          <Button onClick={showInviteModal}>
            <FontAwesomeIcon icon="user-plus" className="me-2" />
            Invite
          </Button>
        </div>
        <Form.Group className="d-flex my-3">
          <Form.Control
            placeholder={"Search by name or email..."}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </Form.Group>
        <TeamTable
          teamId={teamInfo?.teamId}
          teamMembers={membersInTeam ?? []}
          searchValue={value}
          updateTeamMember={(teamMemberId, payload) => {
            const memberInfo = teamMembers?.find(
              (teamMember) => teamMember.teamMemberId === teamMemberId
            );
            setShowWarningModal({
              warningTitle: "Change Permissions",
              warningMessage: `Are you sure you want to change ${
                payload.isTeamLead ? "team lead" : "team member"
              } permissions for ${memberInfo?.firstName}?`,
              modalTitle: "Change Permissions",
              isDanger: false,
              customButtonText: "Confirm Changes",
              teamMemberId,
              isTeamLead: payload.isTeamLead,
              userAccountId: memberInfo?.userAccountId ?? 0,
            });
          }}
          removeTeamMember={(teamMemberId) => {
            const memberInfo = teamMembers?.find(
              (teamMember) => teamMember.teamMemberId === teamMemberId
            );
            setShowWarningModal({
              warningTitle: "Remove Team Member",
              warningMessage: `Are you sure you want to remove ${memberInfo?.firstName} from the team?`,
              modalTitle: "Remove Team Member",
              isDanger: true,
              customButtonText: "Remove",
              teamMemberId,
              userAccountId: memberInfo?.userAccountId || 0,
            });
          }}
        />
      </>
    );
  };

  const getSurveyTabContent = () => {
    return (
      <>
        <div>
          <h2 className="d-inline w-auto me-3">Manage Surveys</h2>
          <Button onClick={() => {}}>Launch TEAMscan</Button>
        </div>

        <SurveysTable teamId={teamInfo.teamId} />
      </>
    );
  };

  const getGeneralSettingsContent = () => {
    const publicSetting = { value: "0", label: "Public" };
    const privateSetting = { value: "1", label: "Private" };
    return (
      <div className="column-gap-20px">
        <h2>Team Settings</h2>
        <div className="setting-row">
          <div className="setting-row-description">
            <h3>Team Name</h3>
            <p>{teamInfo?.teamName}</p>
          </div>
          <div className="setting-row-action">
            <Button
              variant="secondary-blue"
              onClick={() => setEditTeamModal("Name")}
            >
              Edit Team Name
            </Button>
          </div>
        </div>
        <div className="setting-row">
          <div className="setting-row-description">
            <h3>Department</h3>
            <p>{getDepartmentName()}</p>
          </div>
          <div className="setting-row-action">
            <Button
              variant="secondary-blue"
              onClick={() => setEditTeamModal("Department")}
            >
              Edit Department
            </Button>
          </div>
        </div>
        <hr className="m-0" />
        <div className="setting-row">
          <div className="setting-row-description">
            <h3>Team visibility</h3>
            <p>
              Specify which users can view the information on your team guide
            </p>
          </div>
          <div className="setting-row-action">
            <Select
              options={[publicSetting, privateSetting]}
              value={isPrivate ? privateSetting : publicSetting}
              onChange={(value) => {
                setIsPrivate(value?.value === "1");
                onUpdateTeam({
                  private: value?.value === "1" ? 1 : 0,
                });
              }}
            ></Select>
          </div>
        </div>
        <div className="setting-row">
          <div className="setting-row-description">
            <h3>Who can see TEAMscan results?</h3>
            <p>
              Specify which users can view the TEAMscan results for this team
            </p>
          </div>
          <div className="setting-row-action">
            <Select
              options={[
                { value: "1", label: "Everyone" },
                { value: "2", label: "Active Team Members" },
              ]}
              value={getTEAM360ResultsVisibilityValue()}
              onChange={(value) => {
                onUpdateTeam({
                  team360ResultsVisibility: parseInt(value?.value ?? "1"),
                });
              }}
            />
          </div>
        </div>
        <hr className="m-0" />
        <div className="column-gap-10px">
          <h3
            style={{
              color: "black",
            }}
          >
            Deactivate Team
          </h3>
          <p>Remove all team members and permanently delete team guide</p>
          <div>
            <Button
              variant="secondary-danger"
              onClick={() =>
                setShowWarningModal({
                  warningTitle: `Are you sure you want to deactivate "${teamInfo?.teamName}"?`,
                  warningMessage:
                    "This will permanently delete all information on this team guide and remove this team from the company directory. This action cannot be undone.",
                  modalTitle: "Deactivate Team",
                  isDanger: true,
                  customButtonText: "Deactivate Team",
                })
              }
              disabled={deleteTeamStatus === "loading"}
            >
              Deactivate Team
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const getWarningModalInProgress = () => {
    if (showWarningModal === false) {
      return false;
    }

    switch (showWarningModal.modalTitle) {
      case "Deactivate Team":
        return deleteTeamStatus === "loading";
      case "Change Permissions":
        return updateTeamMemberStatus === "loading";
      case "Remove Team Member":
        return removeTeamMemberStatus === "loading";
      default:
        return false;
    }
  };

  // ------------ "On Action" Functions ------------
  const onWarningConfirmClick = () => {
    if (showWarningModal === false) {
      return false;
    }

    switch (showWarningModal.modalTitle) {
      case "Deactivate Team":
        return onDeleteTeam();
      case "Change Permissions":
        return dispatch(
          editTeamMemberFromTeam({
            payload: {
              isTeamLead: showWarningModal.isTeamLead,
            },
            teamMemberId: showWarningModal.teamMemberId,
            teamId: teamInfo?.teamId,
            userAccountId: showWarningModal.userAccountId,
          })
        );
      case "Remove Team Member":
        return dispatch(
          removeTeamMemberFromTeam({
            teamMemberId: showWarningModal.teamMemberId,
            teamId: teamInfo?.teamId,
            userAccountId: showWarningModal.userAccountId,
          })
        );
      default:
        return false;
    }
  };

  return (
    <>
      <TeamSettingsModal
        teamInfo={teamInfo}
        modalShowing={editTeamModal}
        onSave={(payload) => onUpdateTeam(payload)}
        hideModal={() => setEditTeamModal(null)}
        loading={updateTeamStatus === "loading"}
      />
      {showWarningModal !== false ? (
        <WarningModal
          modalTitle={showWarningModal.modalTitle}
          warningTitle={showWarningModal.warningTitle}
          warningMessage={showWarningModal.warningMessage}
          hideModal={() => setShowWarningModal(false)}
          onConfirmClick={onWarningConfirmClick}
          isInProgress={getWarningModalInProgress()}
          isDanger={showWarningModal.isDanger}
          customButtonText={showWarningModal.customButtonText}
          extraBody={showWarningModal.extraBody}
          isOpen
        />
      ) : null}
      <div style={{ marginBottom: "20px" }}>
        <Button
          onClick={() => navigate(-1)}
          variant="secondary-blue"
          style={{ border: "none" }}
        >
          <FontAwesomeIcon icon="arrow-left" className="me-2" />
          Back
        </Button>
      </div>
      <Card className="team-settings column-gap-20px">
        <div className="d-flex justify-content-between align-items-center">
          <div className="team-settings-header row-gap-20px">
            <Link
              className="no-underline"
              to={`/TeamGuide/${teamInfo?.teamId}`}
            >
              <AvatarCircle
                name={teamInfo?.teamName || ""}
                avatarColor={teamInfo?.avatarColor}
                profilePicture={teamInfo?.profilePicture}
                size="large"
              />
            </Link>
            <div className="row-gap-12px align-items-end">
              <Link
                className="no-underline"
                to={`/TeamGuide/${teamInfo?.teamId}`}
              >
                <h1>{teamInfo?.teamName || ""}</h1>
              </Link>
              <span className="team-member-count">
                {teamMembers?.length || 0} Member
                {teamMembers && teamMembers?.length > 1 ? "s" : ""}
              </span>
            </div>
          </div>
          <div>
            <Button
              variant="secondary-blue"
              onClick={() => {
                navigate(`/TeamGuide/${teamInfo?.teamId}`);
              }}
            >
              Go to team guide
            </Button>
          </div>
        </div>
        <Nav
          className="simple-nav"
          activeKey={tabShowing || ""}
          onSelect={(e) => {
            if (!e) return;
            setTabShowing(e as "members" | "settings" | "surveys");
            setSearchParams((searchParams) => {
              searchParams.set("teamSettingsActiveTab", e);
              return searchParams;
            });
          }}
        >
          <Nav.Item>
            <Nav.Link eventKey="members">Members</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="settings">Settings</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="surveys">Surveys</Nav.Link>
          </Nav.Item>
        </Nav>
        {getTabContent()}
      </Card>
    </>
  );
}
