import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'app/storybookComponents/Button';
import { memo, useState } from 'react';

interface Props {
  feedbackArr: {
    feedbackText: string;
    from: string;
    feedbackDate?: string;
  }[];
  defaultIsCollapsed?: boolean;
  titleStem?: string;
  titleEmphasis?: string;
  emptyCardText?: string;
}

// This v2 of this component, when collapsed it will only show 2 user feedbacks and a button to expand
const FeedbackCard = ({
  feedbackArr,
  defaultIsCollapsed = true,
  titleStem,
  titleEmphasis,
  emptyCardText,
}: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(defaultIsCollapsed);

  const getUserFeedback = () => {
    if (!feedbackArr.length) {
      return (
        <div className="snapshot-box">
          <p>
            {emptyCardText ?? `There are no comments from ${titleEmphasis}`}
          </p>
        </div>
      );
    }
    const splicedArr = isCollapsed ? feedbackArr.slice(0, 2) : feedbackArr;

    return splicedArr.map((item, index) => (
      <div key={index} className="row-gap-12px">
        <div className="anonymous-user">
          <FontAwesomeIcon icon={['fas', 'user']} />
        </div>
        <div className="column-gap-8px">
          <p className="quote">"{item.feedbackText}"</p>
          <p className="author" style={{ color: '#53565a' }}>
            Team member
          </p>
        </div>
      </div>
    ));
  };

  // const getDateText = (dateText: string) => {
  //   const date = new Date(dateText);
  //   const month = date.getMonth() + 1;
  //   const day = date.getDate();
  //   const year = date.getFullYear().toString().substring(-2);
  //   return `${month}/${day}/${year}`;
  // };

  return (
    <div className="collapsible-card column-gap-20px">
      <div>
        <h4>
          {titleStem} <span style={{ color: '#425cc7' }}>{titleEmphasis}:</span>
        </h4>
      </div>
      {getUserFeedback()}
      {feedbackArr?.length > 2 ? (
        <Button
          variant="secondary-blue"
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="border-0"
        >
          {isCollapsed ? 'See responses' : 'Hide responses'}
          <FontAwesomeIcon
            icon={`caret-${isCollapsed ? 'down' : 'up'}`}
            className="ms-2"
          />
        </Button>
      ) : null}
    </div>
  );
};

export default memo(FeedbackCard);
