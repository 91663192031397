import { Form } from 'react-bootstrap';

interface Props {
  maxLength?: number;
  inputText: string;
  inputLabel: string;
  controlId: string;
  onTextChange: (text: string) => void;
  placeholder?: string;
  hideLength?: boolean;
  isResizable?: boolean;
}

// This component will be used to render a text area and underneath we will have the
export default function TextArea({
  maxLength,
  inputText,
  inputLabel,
  onTextChange,
  placeholder,
  controlId,
  hideLength,
  isResizable,
}: Props) {
  return (
    <Form.Group className="form-group" controlId={controlId}>
      {inputLabel ? (
        <Form.Label className="textarea-label">{inputLabel}</Form.Label>
      ) : null}
      <Form.Control
        placeholder={placeholder}
        onChange={(e) => {
          if (maxLength) {
            onTextChange(e.target.value.slice(0, maxLength));
          } else {
            onTextChange(e.target.value);
          }
        }}
        value={inputText}
        required
        as="textarea"
        className={isResizable ? 'resizable' : ''}
      />
      {!hideLength && maxLength ? (
        <Form.Text id={`${controlId}Limit`} muted>
          {inputText.length}/{maxLength} Characters
        </Form.Text>
      ) : null}
    </Form.Group>
  );
}
