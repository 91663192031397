import { ReactComponent as TargetIcon } from 'resources/icons/Target.svg';
import { ReactComponent as EmpowerIcon } from 'resources/icons/Empower.svg';
import { ReactComponent as AlignIcon } from 'resources/icons/Align.svg';
import { ReactComponent as MonitorIcon } from 'resources/icons/Monitor.svg';

interface Props {
  name: string;
  color?: 'white' | 'black' | 'navy';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
}
export default function TeamAssessmentIcon({
  name,
  color = 'white',
  size = 'md',
}: Props) {
  switch (name) {
    case 'Target': {
      return <TargetIcon className={`team-360-icon ${color} ${size}`} />;
    }
    case 'Empower': {
      return <EmpowerIcon className={`team-360-icon ${color} ${size}`} />;
    }
    case 'Align': {
      return <AlignIcon className={`team-360-icon ${color} ${size}`} />;
    }
    case 'Monitor': {
      return <MonitorIcon className={`team-360-icon ${color} ${size}`} />;
    }
    default: {
      return null;
    }
  }
}
