interface Props {
  fillWidth: number;
  color?: string;
}

export default function ProgressBar({ fillWidth, color }: Props) {
  return (
    <div className="assessment-progress-bar">
      <div
        className="fill"
        style={{ width: `${fillWidth}%`, backgroundColor: color }}
      ></div>
    </div>
  );
}
