import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { request } from "utils/request";
import { Rating } from "app/components/OpenAIModules/AIModuleRating/types";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import { responseStatus } from "utils/types";

export interface RatingState {
  tipRating: null | number;
  quoteRating: null | number;
  affirmationRating: null | number;
  feedbackRating: null | number;
  ratingStatus: responseStatus;
}

const initialState: RatingState = {
  tipRating: null,
  quoteRating: null,
  affirmationRating: null,
  feedbackRating: null,
  ratingStatus: "idle",
};

export const sendRating = createAsyncThunk(
  "rate",
  async (payload: { completionId: number; rating: number }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/completions/${payload.completionId}`;
    return (await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify({ thumbsUp: payload.rating }),
    })) as Rating;
  }
);

export const aiModuleRatingSlice = createSlice({
  name: "tip",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendRating.pending, (state) => {
        state.ratingStatus = "loading";
      })
      .addCase(sendRating.fulfilled, (state, action: PayloadAction<Rating>) => {
        switch (action.payload.type) {
          case "tip":
            state.tipRating = action.payload.rating;
            break;
          case "inspirationalQuote":
            state.quoteRating = action.payload.rating;
            break;
          case "affirmation":
            state.affirmationRating = action.payload.rating;
            break;
          case "feedback":
            state.feedbackRating = action.payload.rating;
            break;
        }
        state.ratingStatus = "succeeded";
      })
      .addCase(sendRating.rejected, (state) => {
        state.ratingStatus = "failed";
      });
  },
});

export const selectTipRating = (state: RootState) => state.rating.tipRating;
export const selectQuoteRating = (state: RootState) => state.rating.quoteRating;
export const selectAffirmationRating = (state: RootState) =>
  state.rating.affirmationRating;
export const selectFeedbackRating = (state: RootState) =>
  state.rating.feedbackRating;
export const selectRatingStatus = (state: RootState) =>
  state.rating.ratingStatus;

export default aiModuleRatingSlice.reducer;
