export const TIME_INTERVAL_OPTIONS = [
  "Over the past year",
  "Over the past month",
  "Over the past 3 months",
  "Over the past 6 months",
  "Over All Time",
] as const;

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
] as const;
