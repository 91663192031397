import { Card, Dropdown } from 'react-bootstrap';
import { TSkill, TSkillFocus } from './types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  skillFocus: TSkillFocus;
  skill: TSkill;
  hasEditAccess?: boolean;
  onDropdownSelect?: (e: string | null) => void;
}

export default function SkillsFocusCard({
  skill,
  skillFocus,
  hasEditAccess,
  onDropdownSelect,
}: Props) {
  const getDropdown = () => {
    if (!hasEditAccess || !onDropdownSelect) {
      return null;
    }
    return (
      <Dropdown onSelect={onDropdownSelect}>
        <Dropdown.Toggle
          variant="outline-primary"
          id="dropdown-basic"
          className="no-caret"
        >
          <FontAwesomeIcon icon="ellipsis" size="lg" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="flip">
          <Dropdown.Item eventKey="add">Edit Skill</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item eventKey="delete" className="danger">
            Delete Section
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <Card className="skill-focus-card">
      <div className="skill-focus-card__header">
        <h2>{skill?.title}</h2>
        {getDropdown()}
      </div>
      <span>Action for this week</span>
      <p>{skillFocus.goal}</p>
      <span>Success looks like</span>
      <p>{skillFocus.successCriteria}</p>
      {/* <FontAwesomeIcon icon={['fal', 'clock']} /> */}
      <span>{skillFocus.targetDate}</span>
      <div className="skill-focus-card__footer">
        <div className="skill-focus-card__footer-text">
          <div>
            Skill:
            <span> {skill?.title}</span>
          </div>
        </div>
        <FontAwesomeIcon icon="chevron-right" />
      </div>
    </Card>
  );
}
