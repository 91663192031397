import Button from "app/storybookComponents/Button";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  getModuleTemplates,
  selectGetModuleTemplatesStatus,
} from "app/containers/CompanyGuide/slice";
import {
  selectTeamsByTeamId,
  selectAllCompanyUsersById,
} from "app/containers/Global/slice";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { Card, Collapse } from "react-bootstrap";
import {
  getAnalyticsText,
  selectGetAnalyticsTextStatus,
  selectDepartments,
  selectAnalyticsText,
  getAssessmentInformation,
  selectAssessmentInformation,
} from "app/containers/AdminConsole/slice";
import {
  showScheduleAssessmentModalForOrganization,
  showScheduleAssessmentModalForDepartmentId,
} from "app/components/LaunchAssessmentModal/slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setShowModal } from "app/components/Onboarding/slice";
import { selectAllTeam360Results } from "app/components/SurveyDataInstances/slice";
import {
  getDepartmentAssessmentCompletionInfo,
  selectGetDepartmentAssessmentInfoStatus,
  getPendingAllPendingTeamLeaders,
  selectAllPendingTeamLeaders,
  getPendingDepartmentLeaders,
  selectAllPendingDepartmentLeaders,
} from "../slice";
import InfoCardV2 from "app/components/InfoCard/InfoCardV2";
import QuickActionCard from "app/components/QuickActions/QuickActionCard";
import { AssessmentMap } from "app/containers/Assessment/constants";
import HalfDonutChart from "app/storybookComponents/Charts/HalfDonutChart";
import { getTeam360Score } from "app/components/Team360Assessment/helpers";
import SurveyResultsModal from "app/components/Modals/SurveyResultsModal";
import PendingTeamsModal from "app/components/Modals/PendingTeamsModal";
import PendingUsersModal from "app/components/Modals/PendingUsersModal";
import {
  selectAllTeamsMostRecentAssessmentsInstances,
  selectAllDepartmentsMostRecentAssessmentsInstances,
} from "app/containers/Assessment/slice";
import {
  openCreateTeamModal,
  openEditDepartmentModal,
} from "app/components/Modals/slice";
import PendingDepartmentsModal from "app/components/Modals/PendingDepartmentsModal";

interface Props {
  onShowInviteMemberModal: () => void;
  onShowInviteTeamLeaderModal: () => void;
}

export default function AdminOnboarding({
  onShowInviteTeamLeaderModal,
}: Readonly<Props>) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // ------------------------ useStates ------------------------
  const [showResultsModal, setShowResultsModal] = useState(false);
  const [showPendingUserModal, setShowPendingUserModal] = useState(false);
  const [showPendingTeamsModal, setShowPendingTeamsModal] = useState(false);
  const [showPendingDepartmentsModal, setShowPendingDepartmentsModal] =
    useState(false);
  const [isUnderStandTeamworkCollapsed, setIsUnderStandTeamworkCollapsed] =
    useState(true);

  // ------------------------ useSelectors ------------------------
  const getModuleTemplatesStatus = useAppSelector(
    selectGetModuleTemplatesStatus
  );
  const departmentAssessmentInfoStatus = useAppSelector(
    selectGetDepartmentAssessmentInfoStatus
  );
  const getAnalyticsTextStatus = useAppSelector(selectGetAnalyticsTextStatus);
  const departments = useAppSelector(selectDepartments);
  const teamsById = useAppSelector(selectTeamsByTeamId);
  const usersById = useAppSelector(selectAllCompanyUsersById);
  const allTeam360Results = useAppSelector(selectAllTeam360Results());
  const analyticsText = useAppSelector(selectAnalyticsText);
  const pendingTeamLeaders = useAppSelector(selectAllPendingTeamLeaders);
  const allTeam360MostRecentAssessment = useAppSelector(
    selectAllTeamsMostRecentAssessmentsInstances
  );
  const allDepartmentsMostRecentAssessment = useAppSelector(
    selectAllDepartmentsMostRecentAssessmentsInstances
  );
  const pendingDepartmentLeaders = useAppSelector(
    selectAllPendingDepartmentLeaders
  );
  const assessmentInformation = useAppSelector(selectAssessmentInformation);
  // ------------------------ useCallbacks ------------------------

  // ------------------------ useEffects ------------------------
  useEffect(() => {
    if (getModuleTemplatesStatus === "idle") {
      dispatch(getModuleTemplates());
    }
  }, [getModuleTemplatesStatus, dispatch]);

  useEffect(() => {
    if (departmentAssessmentInfoStatus === "idle") {
      dispatch(getDepartmentAssessmentCompletionInfo());
    }
  }, [departmentAssessmentInfoStatus, dispatch]);

  useEffect(() => {
    if (getAnalyticsTextStatus === "idle") {
      dispatch(getAnalyticsText());
    }
  }, [dispatch, getAnalyticsTextStatus]);

  // These function calls have conditionals to prevent infinite loops
  useEffect(() => {
    dispatch(getPendingAllPendingTeamLeaders());
    dispatch(getPendingDepartmentLeaders());
    dispatch(getAssessmentInformation());
  }, [dispatch]);

  // ------------------------ memo functions ------------------------
  const pendingTeamMembers = useMemo(() => {
    // We shall go through all of the users and return the count of all the users who don't have firstTimeLogin
    let pendingCount = 0;
    Object.values(usersById).forEach((user) => {
      if (!user.firstTeamsLogin) {
        pendingCount++;
      }
    });
    return pendingCount;
  }, [usersById]);

  const teamsThatWereInvitedCount = allTeam360MostRecentAssessment.length;
  const departmentsThatWereInvitedCount =
    allDepartmentsMostRecentAssessment.length;

  // ------------------------ get functions ------------------------
  const seeOrgTeam360Results = () => {
    window.scrollTo(0, 0);
    navigate("/AdminConsole?tab=Analytics");
  };

  const getDepartmentTooltipContent = () => {
    return (
      <div>
        <p>
          See the total number of departments within your organization here.
        </p>
        <ul>
          <li>
            <strong>View & Manage:</strong> See and edit department details in
            the Admin Console or by clicking on the "Create / Manage
            departments" button.
          </li>
          <li>
            <strong>Delegate:</strong> Assign leaders to each department for
            better tracking and roll-out.
          </li>
          <li>
            <strong>Structure:</strong> Structure your company’s people and
            teams for better insights and analytics.
          </li>
        </ul>
      </div>
    );
  };

  const getTeamTooltipContent = () => {
    return (
      <div>
        <p>
          See the total count of active teams in your organization.Teams are the
          fundamental unit of group performance.
        </p>
        <ul>
          <li>
            <strong>View & Manage:</strong> See and edit teams via the Admin
            Console or or by clicking on the "Create / Manage Team" button.
          </li>
          <li>
            <strong>Categorize:</strong> Ensure each team is linked to a
            department for accurate reporting.
          </li>
        </ul>
      </div>
    );
  };

  const getPeopleTooltipContent = () => {
    return (
      <div>
        <p>
          See the number of users who have joined or been invited to join your
          organization. If the same user is invited with multiple emails, they
          will count as multiple users.
        </p>
        <ul>
          <li>
            <strong>Manage:</strong> Manage users and user roles through the
            Admin Console or or by clicking on the "Create / Manage people"
            button.
          </li>
          <li>
            <strong>Clarify:</strong> Users can be team members, team leaders,
            department leaders, or org admins — or any combination of all three.
          </li>
        </ul>
      </div>
    );
  };

  const getDepartmentsInfoCard = () => (
    <InfoCardV2
      title="Departments In This Organization"
      body={Object.keys(departments).length}
      action={{
        onClick: () => {
          navigate("/AdminConsole?tab=Departments");
        },
        text: "Create / Manage departments",
      }}
      onPendingTextClick={() => {
        if (pendingDepartmentLeaders?.length) {
          setShowPendingDepartmentsModal(true);
        } else {
          dispatch(openEditDepartmentModal());
        }
      }}
      toolTipContent={getDepartmentTooltipContent()}
      pendingText={
        pendingDepartmentLeaders?.length
          ? `${pendingDepartmentLeaders?.length} pending department${
              pendingDepartmentLeaders?.length === 1 ? "" : "s"
            }`
          : "No pending departments, create a department"
      }
    />
  );

  const getTeamsInfoCard = () => (
    <InfoCardV2
      title="Teams In This Organization"
      body={Object.keys(teamsById).length}
      action={{
        onClick: () => {
          navigate("/AdminConsole?tab=Teams");
        },
        text: "Create / Manage teams",
      }}
      toolTipContent={getTeamTooltipContent()}
      onPendingTextClick={
        pendingTeamLeaders?.length
          ? () => setShowPendingTeamsModal(true)
          : () => onShowInviteTeamLeaderModal()
      }
      pendingText={
        pendingTeamLeaders?.length
          ? `${pendingTeamLeaders?.length} pending team leaders`
          : "No pending team leaders, invite a team leader"
      }
    />
  );

  const getPeopleInfoCard = () => (
    <InfoCardV2
      title="People In This Organization"
      body={Object.keys(usersById).length}
      action={{
        onClick: () => {
          navigate("/AdminConsole?tab=People");
        },
        text: "Create / Manage people",
      }}
      pendingText={
        pendingTeamMembers
          ? `${pendingTeamMembers} pending members`
          : "No pending members"
      }
      onPendingTextClick={
        pendingTeamMembers
          ? () => {
              setShowPendingUserModal(true);
            }
          : undefined
      }
      toolTipContent={getPeopleTooltipContent()}
    />
  );

  // The following functions are going to be the new UI we use.
  const getInfoCardsV2 = () => {
    // If no teams or departments then we need to show the empty card to allow the admin to create a team or department
    if (!Object.keys(departments).length && !Object.keys(teamsById).length) {
      return (
        <Card className="setup-empty-card">
          <div className="card-content">
            <h3>Set Up Your First Department</h3>
            <p
              style={{
                maxWidth: "320px",
              }}
            >
              Invite department leaders in this organization to get departments
              in this organization up and running
            </p>
          </div>
          <div className="column-gap-20px">
            <Button
              onClick={() => {
                dispatch(openEditDepartmentModal());
              }}
            >
              Create a department
            </Button>
            <Button
              variant="tertiary-blue"
              onClick={() => dispatch(openCreateTeamModal())}
            >
              Pilot a team instead
            </Button>
          </div>
        </Card>
      );
    }

    return (
      <div className="three-card-container">
        {getDepartmentsInfoCard()}
        {getTeamsInfoCard()}
        {getPeopleInfoCard()}
      </div>
    );
  };

  const teamsCompletedTEAM360 = useMemo(() => {
    if (!analyticsText?.teamSummary?.classificationArray) {
      return 0;
    }
    return (
      analyticsText.teamSummary.classificationArray.healthy +
      analyticsText.teamSummary.classificationArray["high-performing"] +
      analyticsText.teamSummary.classificationArray["needs-help"]
    );
  }, [analyticsText?.teamSummary]);

  const departmentsCompletedTEAM360 = useMemo(() => {
    if (!analyticsText?.departmentSummary?.classificationArray) {
      return 0;
    }

    return (
      analyticsText.departmentSummary.classificationArray.healthy +
      analyticsText.departmentSummary.classificationArray["high-performing"] +
      analyticsText.departmentSummary.classificationArray["needs-help"]
    );
  }, [analyticsText?.departmentSummary]);

  const getCompletionText = () => {
    const teamOrTeams = teamsCompletedTEAM360 === 1 ? "team" : "teams";
    const departmentOrDepartments =
      departmentsCompletedTEAM360 === 1 ? "department" : "departments";

    return `${analyticsText?.completionInfo?.totalCompleted} completed responses from
    ${teamsCompletedTEAM360} ${teamOrTeams} and
    ${departmentsCompletedTEAM360} ${departmentOrDepartments}`;
  };

  const getOrgTeam360Results = () => {
    // This means that no TEAMscan has been scheduled yet so we should not show this card at all.
    if (!analyticsText?.completionInfo?.totalInvited) {
      return null;
    }

    if (!allTeam360Results?.companyScores?.overall) {
      return (
        <Card className="dashboard-container-header">
          <div className="dashboard-container-header__info">
            <div className="small-icon-square dark-grey">
              <FontAwesomeIcon icon="users" className="icon" />
            </div>
            <div className="column-gap-8px">
              <div className="row-gap-8px">
                <h3>Organization TEAMscan results available</h3>
                <div className="label-tag grey m-0">
                  Full organization report
                </div>
              </div>
              <p>
                No responses so far from {teamsThatWereInvitedCount} teams and{" "}
                {departmentsThatWereInvitedCount} departments invited
              </p>
            </div>
          </div>
          <div>
            <p>No results available</p>
          </div>
        </Card>
      );
    }

    // If pending should show ard indicating that its pending and should show the count of teams/departments invited
    // If we already scheduled a TEAMscan but no results are available, and the expiration date is passed then we just show not enough responses collected
    const companyTeam360Results = allTeam360Results?.companyScores?.overall;
    const smallSpeedChart = (
      <div style={{ height: "70px", width: "150px" }}>
        <HalfDonutChart
          canvasId="small-team360-preview-card-chart"
          currentVal={getTeam360Score(companyTeam360Results)}
          size="extra-small"
          borderWidth={2}
        />
      </div>
    );

    return (
      <Card className="dashboard-container-header">
        <div className="dashboard-container-header__info">
          <div className="small-icon-square blue">
            <FontAwesomeIcon icon="users" className="icon" />
          </div>
          <div className="column-gap-8px">
            <div className="row-gap-8px">
              <h3>Organization TEAMscan results available</h3>
              <div className="label-tag green m-0">
                Full organization report
              </div>
            </div>
            <p>{getCompletionText()}</p>
          </div>
        </div>
        <div
          className="row-gap-20px align-items-center"
          style={{ gap: "40px" }}
        >
          {smallSpeedChart}
          <Button
            onClick={() => {
              seeOrgTeam360Results();
            }}
            style={{
              height: "fit-content",
            }}
          >
            View Results
          </Button>
        </div>
      </Card>
    );
  };

  const getViewOrgGuideCard = () => {
    return (
      <Card className="dashboard-container-header">
        <div className="dashboard-container-header__info">
          <div className="small-icon-square">
            <FontAwesomeIcon icon="map" className="icon" />
          </div>
          <div>
            <h3>View organization guide</h3>
            <p>Add and configure content on your organization profile</p>
          </div>
        </div>
        <Button
          variant="secondary-blue"
          onClick={() => {
            navigate("/OrganizationGuide");
          }}
        >
          View organization guide
        </Button>
      </Card>
    );
  };

  const getTeam360Card = () => {
    const isLaunchTeam360Disabled =
      !!assessmentInformation?.organization.activeAssessment;
    return (
      <Card className="column-gap-20px">
        <div className="dashboard-container-header">
          <div className="dashboard-container-header__info">
            <div className="small-icon-square">
              <FontAwesomeIcon icon={["fas", "poll-people"]} className="icon" />
            </div>
            <div>
              <h3>
                Understand teamwork and empower higher-performing managers
              </h3>
              <p>
                Launch and manage the TEAMscan teamwork survey and view results
              </p>
            </div>
          </div>
          <div className="row-gap-16px">
            <Button
              variant="secondary-blue"
              onClick={() => {
                dispatch(
                  setShowModal({
                    eventType: "generalTeam360Information",
                    // hideActionButton: true,
                  })
                );
              }}
            >
              See details
            </Button>
            <Button
              onClick={() => {
                setIsUnderStandTeamworkCollapsed(
                  !isUnderStandTeamworkCollapsed
                );
              }}
            >
              {isUnderStandTeamworkCollapsed ? "See" : "Hide"} actions
            </Button>
          </div>
        </div>
        <Collapse in={!isUnderStandTeamworkCollapsed}>
          <div className="three-card-container">
            <QuickActionCard
              title="Launch TEAMscan Survey"
              description="Launch the survey for a single team, one or more departments or your entire organization."
              actionButton={{
                onClick: () => {
                  dispatch(showScheduleAssessmentModalForOrganization());
                },
                text: "Launch TEAMscan",
              }}
              iconName="rocket-launch"
              isWholeCardDisabled={isLaunchTeam360Disabled}
              cornerTag={
                isLaunchTeam360Disabled ? (
                  <div className="label-tag green">Already active</div>
                ) : null
              }
              imageIcon={
                <img
                  alt={AssessmentMap[1].name}
                  src={AssessmentMap[1].assessmentIcon}
                />
              }
            />
            <QuickActionCard
              title="View Results"
              description="View results from past surveys for teams and departments in this organization."
              actionButton={{
                onClick: () => {
                  setShowResultsModal(true);
                },
                text: "View TEAMscan Results",
              }}
              iconName="square-poll-vertical"
            />
            <QuickActionCard
              title="Manage Surveys"
              description="View 1-time and recurring surveys. Adjust survey period, reminders, and more."
              actionButton={{
                onClick: () => {
                  navigate("/AdminConsole?tab=Surveys");
                },
                text: "Manage TEAMscan",
              }}
              iconName="wrench"
            />
          </div>
        </Collapse>
      </Card>
    );
  };
  const getConfigureOrgSettingsCard = () => {
    return (
      <Card className="dashboard-container-header">
        <div className="dashboard-container-header__info">
          <div className="small-icon-square">
            <FontAwesomeIcon icon="gear" className="icon" />
          </div>
          <div>
            <h3>Configure organization settings</h3>
            <p>
              Manage who can join your organization, create teams, invite
              members, and more
            </p>
          </div>
        </div>
        <Button
          variant="secondary-blue"
          onClick={() => {
            navigate("/AdminConsole?tab=Settings");
          }}
        >
          Configure settings
        </Button>
      </Card>
    );
  };

  const teamIds = useMemo(() => {
    return Object.keys(teamsById).map((teamId) => parseInt(teamId));
  }, [teamsById]);

  const getSurveyResultModalEmptyCard = (selectedDepartmentId?: number) => {
    const onShowGeneralTeam360Information = () => {
      dispatch(
        setShowModal({
          eventType: "generalTeam360Information",
          // hideActionButton: true,
        })
      );
      setShowResultsModal(false);
    };
    if (selectedDepartmentId) {
      return (
        <div className="empty-card">
          <span>No TEAMscan Results In This Department</span>
          <p>
            No teams have an active TEAMscan currently. Launch a TEAMscan for
            your department or one or more teams now.
          </p>
          <div className="action-buttons">
            <Button
              onClick={() => {
                dispatch(
                  showScheduleAssessmentModalForDepartmentId(
                    selectedDepartmentId
                  )
                );
                setShowResultsModal(false);
              }}
            >
              Launch TEAMscan
            </Button>
            <Button
              variant="secondary-blue"
              onClick={onShowGeneralTeam360Information}
            >
              See details
            </Button>
          </div>
        </div>
      );
    }
    return (
      <div className="empty-card">
        <span>No TEAMscan Results In This Organization</span>
        <p>
          No teams have an active TEAMscan currently. Launch a TEAMscan for your
          organization, department, or one or more teams now.
        </p>
        <div className="action-buttons">
          <Button
            onClick={() => {
              dispatch(showScheduleAssessmentModalForOrganization());
              setShowResultsModal(false);
            }}
          >
            Launch TEAMscan
          </Button>
          <Button
            variant="secondary-blue"
            onClick={onShowGeneralTeam360Information}
          >
            See details
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <SurveyResultsModal
        onHide={() => setShowResultsModal(false)}
        show={showResultsModal}
        teamIds={teamIds}
        showManageSurveysButton
        isAdmin
        getEmptyCard={getSurveyResultModalEmptyCard}
      />
      <PendingTeamsModal
        onHide={() => setShowPendingTeamsModal(false)}
        show={showPendingTeamsModal}
        onShowInviteTeamLeaderModal={() => {
          setShowPendingTeamsModal(false);
          onShowInviteTeamLeaderModal();
        }}
        pendingTeamLeaders={pendingTeamLeaders}
      />
      <PendingUsersModal
        onHide={() => setShowPendingUserModal(false)}
        show={showPendingUserModal}
      />
      <PendingDepartmentsModal
        onHide={() => setShowPendingDepartmentsModal(false)}
        show={showPendingDepartmentsModal}
      />
      <div className="dashboard-container">
        {getInfoCardsV2()}
        <h2>Surveys</h2>
        {getOrgTeam360Results()}
        {getTeam360Card()}
        <h2>Other</h2>
        {getViewOrgGuideCard()}
        {getConfigureOrgSettingsCard()}
      </div>
    </>
  );
}
