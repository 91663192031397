import {
  RADAR_LABEL_ARRAY,
  SINGLE_POPULATION_RADAR_CHART_DATASET,
} from "app/storybookComponents/Charts/constants";
import { WorkplaceInsightsReport, StrengthTip } from "./types";
import { Card } from "react-bootstrap";
import {
  LIKERT_SCALE_VALUES,
  WORK_PLACE_INSIGHTS_REPORT_SECTION_TITLES,
} from "./constants";
import {
  getTraitNameAndIcon,
  getWorkplaceChallengesAndMotivatorsCell,
} from "./helper-functions";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import { selectCurrentUserAccountId } from "app/containers/Global/slice";
import NotAvailable from "./NotAvailable";
import PendingTest from "./PendingTest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getSuperpowersAndHiddenStrengths,
  // getWorkplaceInsightsPdf,
  selectSuperpowersAndHiddenStrengthsById,
} from "./slice";
import SimpleCollapsibleCard from "app/storybookComponents/CollapsibleCards/SimpleCollapsibleCard";
import { ReactElement, useEffect, useMemo, useState } from "react";
import RadarChart from "app/storybookComponents/Charts/RadarChart";

interface Props {
  workplaceInsightsReport?: WorkplaceInsightsReport;
  userAccountId: number;
  firstName: string;
  pendingTestId?: number | string | null;
}

export default function WorkPlaceInsightsReport({
  workplaceInsightsReport,
  userAccountId,
  firstName,
  pendingTestId,
}: Props) {
  const dispatch = useAppDispatch();
  // ----------------------- Selectors ----------------------- //
  const currentUserAccountId = useAppSelector(selectCurrentUserAccountId);
  const superpowerAndHiddenStrengths = useAppSelector(
    selectSuperpowersAndHiddenStrengthsById(userAccountId)
  );

  // ----------------------- State ----------------------- //
  const [openedCards, setOpenedCards] = useState<{
    [key: string]: boolean;
  }>({
    SuperpowerSection: true, // Default to open
    HiddenStrengthSection: true, // Default to open
    SecondHighestTraitSection: true,
  });
  const [moreInsightsShowing, setMoreInsightsShowing] =
    useState("notable-traits");

  // ----------------------- Effects ----------------------- //
  useEffect(() => {
    if (superpowerAndHiddenStrengths) return;
    dispatch(getSuperpowersAndHiddenStrengths(userAccountId));
  }, [userAccountId, dispatch, superpowerAndHiddenStrengths]);

  // ----------------------- Memos ----------------------- //
  const radarChart = useMemo(() => {
    const data = workplaceInsightsReport?.traitScores
      ? RADAR_LABEL_ARRAY.map(
          (label) => workplaceInsightsReport.traitScores[label] + 25
        )
      : [];
    const dataSet = {
      ...SINGLE_POPULATION_RADAR_CHART_DATASET,
      data,
      label: firstName,
    };

    return (
      <RadarChart
        canvasId={`${userAccountId}-workplace-report`}
        dataSets={[dataSet]}
        includeDashedBaseline
        includeGeneralPopulation
      />
    );
  }, [workplaceInsightsReport?.traitScores, userAccountId, firstName]);

  // ----------------------- Functions ----------------------- //
  const getNotableTraits = () =>
    workplaceInsightsReport?.notableTraits?.map(({ title, text, color }) => (
      <Card
        key={title}
        className="right-bar-card p-3"
        style={{ borderLeftColor: color }}
      >
        <h3>{title}</h3>
        <p>{text}</p>
      </Card>
    ));

  const getWorkStyle = () => {
    if (!workplaceInsightsReport?.workStyle) {
      return null;
    }
    const col1: ReactElement[] = [];
    const col2: ReactElement[] = [];

    Object.entries(workplaceInsightsReport.workStyle)?.forEach(
      ([abbrev, { title, text }], idx) => {
        const traitIcon = getTraitNameAndIcon(abbrev)?.traitIcon;
        const item = (
          <div key={abbrev}>
            <div className="title-with-icon">
              {traitIcon ? (
                <img
                  src={traitIcon}
                  alt="title icon"
                  height="32px"
                  width="32px"
                  className="me-2"
                />
              ) : null}
              <h3>{title}</h3>
            </div>
            <p>{text}</p>
          </div>
        );
        if (idx % 2 === 0) {
          col1.push(item);
        } else {
          col2.push(item);
        }
      }
    );

    return (
      <>
        <div className="column-gap-12px">{col1}</div>
        <div className="column-gap-12px">{col2}</div>
      </>
    );
  };

  const getTemperamentAttitudeOutlook = () => {
    if (!workplaceInsightsReport?.temperament) {
      return null;
    }
    return Object.entries(workplaceInsightsReport.temperament)?.map(
      ([abbrev, { title, text }]) => {
        const traitIcon = getTraitNameAndIcon(abbrev)?.traitIcon;
        return (
          <div key={abbrev}>
            <div className="title-with-icon">
              {traitIcon ? (
                <img
                  src={traitIcon}
                  alt="title icon"
                  height="32px"
                  width="32px"
                  className="me-2"
                />
              ) : null}
              <h3>{title}</h3>
            </div>
            <p>{text}</p>
          </div>
        );
      }
    );
  };

  const getStrengthAndPotentialChallenges = () => {
    const { strengths, weaknesses } = workplaceInsightsReport || {};
    const strengthElms = strengths?.map((strength) => (
      <li key={strength}>{strength}</li>
    ));
    const weaknessElms = weaknesses?.map((weakness) => (
      <li key={weakness}>{weakness}</li>
    ));

    return (
      <>
        <div className="column-gap-12px">
          <h3 className="strength-and-challenges-title">Strengths</h3>
          <ul>{strengthElms}</ul>
        </div>
        <div className="column-gap-12px">
          <h3 className="strength-and-challenges-title">
            Potential Challenges
          </h3>
          <ul>{weaknessElms}</ul>
        </div>
      </>
    );
  };

  const addHrsInBetweenListItems = (list: any[]) => {
    const newList: any[] = [];
    list.forEach((item, idx) => {
      newList.push(item);
      if (idx !== list.length - 1) {
        newList.push(<hr key={idx} className="m-0" />);
      }
    });
    return newList;
  };

  const getDevelopmentSuggestions = () => {
    const col1: ReactElement[] = [];
    const col2: ReactElement[] = [];

    workplaceInsightsReport?.suggestions?.forEach(
      ({ title, suggestions, color }, idx) => {
        const elm = (
          <div key={title} className="column-gap-8px">
            <h3
              className="development-suggestions-title-badge"
              style={{ backgroundColor: color }}
            >
              {title}
            </h3>
            <div style={{ paddingTop: "5px" }}>
              {suggestions.map((sug) => (
                <p key={sug}>{sug}</p>
              ))}
            </div>
          </div>
        );
        if (idx % 2 === 0) {
          col1.push(elm);
        } else {
          col2.push(elm);
        }
      }
    );
    return (
      <>
        <div className="column-gap-20px">{addHrsInBetweenListItems(col1)}</div>
        <div className="column-gap-20px">{addHrsInBetweenListItems(col2)}</div>
      </>
    );
  };

  const getWorkplaceChallengesAndMotivators = () => (
    <div>
      <p>{workplaceInsightsReport?.workplaceStressorsMotivatorsDescription}</p>
      <table className="workplace-challenges-and-motivators-table">
        <thead>
          <tr>
            <th />
            <th className="text-center">
              {" "}
              Less
              <br />
              comfortable
            </th>
            <th />
            <th className="text-center">
              Somewhat
              <br />
              comfortable
            </th>
            <th />
            <th className="text-center">
              Extremely
              <br />
              comfortable
            </th>
          </tr>
        </thead>
        <tbody>
          {workplaceInsightsReport?.workplaceStressorsMotivators?.map(
            ({ title, likertValue }, idx) => (
              <tr key={title} className={idx % 2 === 0 ? "dark-row" : ""}>
                <td>{title}</td>
                {LIKERT_SCALE_VALUES.map((value) =>
                  getWorkplaceChallengesAndMotivatorsCell(
                    value - 1,
                    likertValue,
                    title
                  )
                )}
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );

  const getOpenedCardsProperty = (title: string) => {
    switch (title) {
      case "Superpower":
        return "SuperpowerSection";
      case "Second Highest Trait":
        return "SecondHighestTraitSection";
      case "Hidden Strengths":
      default:
        return "HiddenStrengthSection";
    }
  };

  const getStrengthsOfThisTitle = (title: string) => {
    switch (title) {
      case "Second Highest Trait":
        return "Strengths Of This Trait";
      case "Superpower":
        return "Strengths Of This Superpower";
      case "Hidden Strengths":
      default:
        return "Hidden Strengths";
    }
  };

  const getRisksOfThisTitle = (title: string) => {
    switch (title) {
      case "Superpower":
        return "Risks Of This Superpower";
      case "Second Highest Trait":
        return "Risks Of This Trait";
      case "Hidden Strengths":
      default:
        return "Hidden Risks";
    }
  };

  const getStrengthSection = (strengthTip: StrengthTip, title: string) => {
    if (!strengthTip) {
      return null;
    }
    const overviewCardDescription =
      currentUserAccountId === userAccountId
        ? strengthTip.summary
        : strengthTip.summaryOfSomeoneElse;

    const openedCardsProperty = getOpenedCardsProperty(title);
    return (
      <>
        <SimpleCollapsibleCard
          title={
            currentUserAccountId === userAccountId
              ? `Your ${title}`
              : `${firstName}'s ${title}`
          }
          onOpen={(value: boolean) => {
            setOpenedCards({
              ...openedCards,
              [openedCardsProperty]: value,
            });
          }}
          isOpen={!!openedCards[openedCardsProperty]}
        >
          <div className="workplace-insights-strengths">
            {getStrengthOverviewCard(
              strengthTip.trait,
              overviewCardDescription
            )}
            <div className="workplace-insights-strengths__lists">
              {getStrengthsList(
                getStrengthsOfThisTitle(title),
                true,
                strengthTip.strengths
              )}
              {getStrengthsList(
                getRisksOfThisTitle(title),
                false,
                strengthTip.risks
              )}
            </div>
          </div>
        </SimpleCollapsibleCard>
      </>
    );
  };

  const getStrengthsList = (
    title?: string,
    strength?: boolean,
    list: string[] = []
  ) => (
    <Card className="column-gap-20px">
      <h3>{title}</h3>
      <ul>
        {list?.map((item) => (
          <li key={item}>
            <FontAwesomeIcon
              icon={strength ? "check" : "xmark"}
              className="mt-1"
              style={{
                color: strength ? "#009952" : "#D50057",
              }}
            />
            <p>{item}</p>
          </li>
        ))}
      </ul>
    </Card>
  );

  const getStrengthOverviewCard = (
    title: string = "",
    description: string = ""
  ) => (
    <Card className="card-with-left-strip dark-blue column-gap-4px">
      <h3>{title}</h3>
      <p>{description}</p>
    </Card>
  );

  const getMoreInsightsNavBox = () => {
    const navItems = Object.entries(
      WORK_PLACE_INSIGHTS_REPORT_SECTION_TITLES
    ).map(([id, title]) => (
      <div
        key={id}
        onClick={() => setMoreInsightsShowing(id)}
        className={moreInsightsShowing === id ? "selected" : ""}
      >
        <span>{title}</span>
      </div>
    ));
    return <div className="navigation-box sticky-top">{navItems}</div>;
  };

  const getMoreInsightsShowing = () => {
    switch (moreInsightsShowing) {
      case "notable-traits":
        return (
          <div
            id="notable-traits"
            className="column-gap-12px workplace-insights-more-insights-card"
          >
            <h2 className="mb-0">Notable Traits</h2>
            <p className="text-muted">
              <em>
                {currentUserAccountId === userAccountId ? "You" : firstName} can
                be described as:
              </em>
            </p>
            <div className="workplace-insights-more-insights-card__section">
              {getNotableTraits()}
            </div>
          </div>
        );
      case "work-communication-interaction-style":
        return (
          <div id="work-communication-interaction-style">
            <h2>Work, Communication & Interaction Style</h2>
            <div className="workplace-insights-more-insights-card__section">
              {getWorkStyle()}
            </div>
          </div>
        );
      case "temperament-attitudes-outlook":
        return (
          <div id="temperament-attitudes-outlook">
            <h2>Temperament, Attitudes & Outlook</h2>
            <div className="workplace-insights-more-insights-card__section">
              {getTemperamentAttitudeOutlook()}
            </div>
          </div>
        );
      case "strengths-potential-challenges":
        return (
          <div id="strengths-potential-challenges">
            <h2>Strengths & Potential Challenges</h2>
            <div className="workplace-insights-more-insights-card__section">
              {getStrengthAndPotentialChallenges()}
            </div>
          </div>
        );
      case "development-suggestions":
        return (
          <div id="development-suggestions">
            <h2>Development Suggestions</h2>
            <div className="workplace-insights-more-insights-card__section">
              {getDevelopmentSuggestions()}
            </div>
          </div>
        );
      case "workplace-challenges-motivators":
        return (
          <div id="workplace-challenges-motivators">
            <h2 className="mb-0">Workplace Challenges & Motivators</h2>
            <div>{getWorkplaceChallengesAndMotivators()}</div>
          </div>
        );
      default:
        return null;
    }
  };

  if (!workplaceInsightsReport) {
    if (currentUserAccountId !== userAccountId || !pendingTestId) {
      return (
        <NotAvailable
          message={`${firstName} has not yet completed their personality test.`}
        />
      );
    }
    return <PendingTest eventId={pendingTestId} />;
  }

  return (
    <div>
      {/* <div className="guide-pdf-download">
        <span
          role="button"
          onClick={() => {
            dispatch(getWorkplaceInsightsPdf(userAccountId));
          }}
        >
          <FontAwesomeIcon icon={["far", "download"]} className="me-2" />
          Download
        </span>
      </div> */}
      <Card className="workplace-insights-report">
        <div>
          <h2>{firstName}'s Workplace Insights</h2>
          <p>{workplaceInsightsReport?.description}</p>
        </div>
        {superpowerAndHiddenStrengths?.superPower &&
          getStrengthSection(
            superpowerAndHiddenStrengths?.superPower,
            "Superpower"
          )}
        {superpowerAndHiddenStrengths?.secondHighestTrait &&
          getStrengthSection(
            superpowerAndHiddenStrengths?.secondHighestTrait,
            "Second Highest Trait"
          )}
        {superpowerAndHiddenStrengths?.hiddenStrength &&
          getStrengthSection(
            superpowerAndHiddenStrengths?.hiddenStrength,
            "Hidden Strength"
          )}
        <SimpleCollapsibleCard
          isOpen={!!openedCards["PersonalitySummary"]}
          onOpen={(value: boolean) => {
            setOpenedCards({
              ...openedCards,
              PersonalitySummary: value,
            });
          }}
          title={
            currentUserAccountId === userAccountId
              ? "Your Personality Summary"
              : `${firstName}'s Personality Summary`
          }
        >
          <div style={{ marginTop: "-20px" }}>{radarChart}</div>
        </SimpleCollapsibleCard>

        <SimpleCollapsibleCard
          isOpen={!!openedCards["MoreInsights"]}
          onOpen={(value: boolean) => {
            setOpenedCards({
              ...openedCards,
              MoreInsights: value,
            });
          }}
          title="More Insights"
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 2fr",
              gap: "12px",
            }}
          >
            {getMoreInsightsNavBox()}
            <div>{getMoreInsightsShowing()}</div>
          </div>
        </SimpleCollapsibleCard>
      </Card>
    </div>
  );
}
