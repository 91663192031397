import Button from "app/storybookComponents/Button";
import HalfDonutChart from "app/storybookComponents/Charts/HalfDonutChart";
import { Card } from "react-bootstrap";

interface Props {
  title?: string;
  numberOfCompletedResponses?: number;
  onViewResultsClick: () => void;
  overallScore?: number;
  className?: string;
}

export default function SmallTeam360PreviewCard({
  title = "TEAMscan results available",
  numberOfCompletedResponses = 0,
  onViewResultsClick,
  overallScore,
  className,
}: Props) {
  return (
    <Card
      className={`small-team360-preview-card${
        className ? ` ${className}` : ""
      }`}
    >
      <div className="column-gap-16px">
        <div className="column-gap-4px">
          <h3>{title}</h3>
          <p
            className="small-body-text"
            style={{
              color: "#202D63",
            }}
          >
            {numberOfCompletedResponses} completed responses
          </p>
        </div>
        <div>
          <Button onClick={onViewResultsClick}>View Results</Button>
        </div>
      </div>
      <div>
        <HalfDonutChart
          canvasId="small-team360-preview-card-chart"
          currentVal={overallScore}
          size="extra-small"
          borderWidth={2}
        />
      </div>
    </Card>
  );
}
