/**
 *
 * AdminConsole
 *
 */

import { useState, ChangeEvent, useEffect, useRef } from 'react';
import { Container, Card } from 'react-bootstrap';
import { parse } from 'papaparse';
import { useAppSelector, useAppDispatch } from 'utils/redux/hooks';
import {
  getTables,
  updateTables,
  selectTables,
  selectGetTablesStatus,
  clearUpdatingTableStatus,
} from './slice';
import {
  ModalType,
  SelectedTable,
  TableStructureError,
  UploadState,
  PutTIAssessmentItemsPayload,
} from './types';
import TableCard from 'app/components/TableCard';
import Modal from './Modal';
import { downloadCSV } from 'utils/helperFunctions';
import OpenAIGenerator from 'app/components/OpenAIInternalTools/OpenAIGenerator';
import OpenAIModule from 'app/components/OpenAIInternalTools/OpenAIModules';
import PromptEditor from 'app/components/OpenAIInternalTools/PromptEditor';
import UniversalVariables from 'app/components/OpenAIInternalTools/UniversalVariables';

interface Props {}

export default function AdminConsole(props: Props) {
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const availableTables = useAppSelector(selectTables);
  const getTableStatus = useAppSelector(selectGetTablesStatus);

  useEffect(() => {
    if (getTableStatus === 'idle') {
      dispatch(getTables());
    }
  }, [dispatch, getTableStatus]);

  const [modalShowing, setModalShowing] = useState<ModalType>(null);
  const [selectedTable, setSelectedTable] = useState<SelectedTable>(null);
  const [uploadState, setUploadState] = useState<UploadState>(null);
  const [uploadedTable, setUploadedTable] =
    useState<PutTIAssessmentItemsPayload | null>(null);
  const [structureError, setStructureError] =
    useState<TableStructureError>(null);
  const [rowsAdded, setRowsAdded] = useState<number | null>(null);
  // Sets the selected table state and sets the modal showing to UploadData
  const onUploadDataClick = (tableName: string) => {
    const foundTable = availableTables?.tables?.find(
      ({ name }) => name === tableName
    );
    if (foundTable === undefined) {
      return null;
    }

    setModalShowing('UploadData');
    setSelectedTable({
      tableData: foundTable.content,
      tableName: foundTable.name,
      tableDescription: foundTable.description,
      tableTags: foundTable.tags,
    });
  };

  const downloadTableCSV = (tableName: string) => {
    const foundTable = availableTables?.tables?.find(
      ({ name }) => name === tableName
    );

    if (foundTable === undefined || foundTable.content.length === 0) {
      return null;
    }

    const data: string[][] = [];
    data.push(Object.keys(foundTable.content[0]));
    foundTable.content.forEach((tableRow) => {
      data.push(Object.values(tableRow));
    });
    downloadCSV(tableName, data);
  };

  // This functions dispatches the update tables call getting the actual data from the selectedTable State
  const onUpdateTable = () => {
    if (!selectedTable || !uploadedTable) return;
    dispatch(
      updateTables({
        tableName: selectedTable.tableName,
        payload: uploadedTable,
      })
    );
  };

  const hideModal = () => {
    setModalShowing(null);
    setSelectedTable(null);
    setUploadState(null);
    setUploadedTable(null);
    setStructureError(null);
    setRowsAdded(null);
    dispatch(clearUpdatingTableStatus());
  };

  // Validating csv upload, checking header and setting new users in state
  const handleUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (
      !event?.target?.files ||
      inputRef?.current?.files?.length === undefined ||
      inputRef.current.files.length === 0
    ) {
      setUploadState(null);
      return;
    }

    setUploadState('Uploading');
    const uploadFileIsCSV =
      event.target.files.length > 0 &&
      /.+(.csv)$/.test(event.target.files[0].name);
    if (uploadFileIsCSV && event.target.files[0].size > 5 * 1024 * 1024) {
      setUploadState('UploadingError');
    } else if (uploadFileIsCSV) {
      // @ts-ignore
      parse(event.target.files[0], {
        delimiter: '',
        chunkSize: 3,
        header: true,
        complete: checkTableStructure,
        error: onCSVUploadError,
      });
    }
  };

  const onCSVUploadError = (
    err: any,
    file: any,
    inputElem: any,
    reason: any
  ) => {
    console.error(
      'err, file, inputElem, reason:',
      err,
      file,
      inputElem,
      reason
    );
  };

  const checkTableStructure = ({
    data,
    meta: { fields },
  }: {
    data: {
      [columnName: string]: string | number;
    }[];
    meta: { fields: string[] };
  }) => {
    if (!selectedTable) {
      return;
    }

    setUploadState('Checking');
    const mismatchPositions: number[] = [];
    const tableColumns = Object.keys(selectedTable.tableData[0]);
    tableColumns.forEach((columnHeader, idx) => {
      if (columnHeader !== fields[idx]) {
        mismatchPositions.push(idx);
      }
    });

    // If there are no mismatching column names, then we set the state to success and save the data the is being uploaded to our state.
    if (mismatchPositions.length === 0) {
      setUploadState('CheckingSuccess');
      setUploadedTable({ values: data });
      setRowsAdded(data.length - selectedTable.tableData.length);
    } else {
      // Or else we set the structure error, so that the user is able to see which rows are not matching
      setStructureError({
        existingColumns: tableColumns,
        uploadedColumns: fields,
        mismatchPositions,
      });
      setUploadState('CheckingError');
    }
  };

  const getTableCards = () =>
    availableTables &&
    availableTables.tables.map(({ name, description, content, tags }, idx) => (
      <TableCard
        tableTags={tags}
        tableName={name}
        tableDescription={description}
        onUploadClick={onUploadDataClick}
        tableData={content}
        key={idx}
        onDownloadClick={downloadTableCSV}
      />
    ));

  return (
    <Container fluid className="admin-console page">
      <Modal
        modalToShow={modalShowing}
        onHide={hideModal}
        selectedTable={selectedTable}
        handleUpload={handleUpload}
        structureError={structureError}
        uploadState={uploadState}
        onUpdateTable={onUpdateTable}
        inputRef={inputRef}
        rowsAdded={rowsAdded}
      />
      <Card className="p-4">
        <h1>Admin Console</h1>
        <h2>New Data</h2>
        <p>
          To add a new data table or edit columns of an existing data table,
          please contact our Development team.
        </p>
        <h2>Existing Data Tables</h2>
        <p className="mb-0">
          To update existing data tables (adding new rows, editing existing
          content, etc.), select an existing table and upload the updated .csv
          file with the same header names and number of headers.
        </p>
        <p>
          Note: This will not interrupt any existing users in their sessions,
          but will take effect for any new sessions.
        </p>
        {getTableCards()}
        <UniversalVariables />
        <PromptEditor />
        <OpenAIGenerator />
        <h2>Open AI Modules Preview</h2>
        <OpenAIModule />
      </Card>
    </Container>
  );
}
