import { useAppSelector } from "utils/redux/hooks";
import Button from "app/storybookComponents/Button";
import { useEffect, useState } from "react";
import { selectPendingAssessments } from "../slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircularTabs from "app/storybookComponents/Tabs/CircularTabs";
import { selectDepartmentsMostRecentAssessmentsInstancesEntities } from "app/containers/Assessment/slice";
import { selectDepartments } from "app/containers/AdminConsole/slice";
import TempSmallTeam360Card from "./TempSmallTeam360Card";
import { getDaysRemaining } from "app/containers/Assessment/helpers";
import { selectAllTeam360Results } from "app/components/SurveyDataInstances/slice";

interface Props {
  departmentIds: number[];
  maxShowing?: number;
  hasDepartmentLeadAccess?: boolean;
  hideTodoTab?: boolean;
}
const MAX_CARDS_SHOWING_WHEN_NOT_EXPANDED = 5;
const EMPTY_TABS: { [tabLabel: string]: JSX.Element[] } = {
  "To Do": [],
  Pending: [],
  Results: [],
  "No Results": [],
};

const DepartmentTempCard = ({
  departmentIds,
  maxShowing = MAX_CARDS_SHOWING_WHEN_NOT_EXPANDED,
  hasDepartmentLeadAccess,
  hideTodoTab,
}: Props) => {
  // ---------------------- Selectors ----------------------
  const pendingAssessments = useAppSelector(selectPendingAssessments);
  const departmentsMostRecentAssessments = useAppSelector(
    selectDepartmentsMostRecentAssessmentsInstancesEntities
  );
  const departments = useAppSelector(selectDepartments);
  const allTeam360Scores = useAppSelector(selectAllTeam360Results());

  // ---------------------- States ----------------------
  const [tabShowing, setTabShowing] = useState("All");
  const [isExpanded, setIsExpanded] = useState(false);
  const [tabs, setTabs] = useState(EMPTY_TABS);

  // ---------------------- Effects ----------------------
  useEffect(() => {
    const todo: JSX.Element[] = [];
    const pending: JSX.Element[] = [];
    const results: JSX.Element[] = [];
    const noResults: JSX.Element[] = [];

    departmentIds?.forEach((departmentId) => {
      const mostRecentAssessment =
        departmentsMostRecentAssessments[departmentId];
      // If the team does not have any invited members then no need to show it as part of our list

      if (
        !departments[departmentId] ||
        !allTeam360Scores?.departmentScores?.[departmentId]?.overall ||
        mostRecentAssessment?.totalInvited === 0
      ) {
        return;
      }

      const teamCard = (
        <TempSmallTeam360Card
          departmentId={departmentId}
          pendingAssessmentEventId={
            mostRecentAssessment?.userAssessmentEventId ?? undefined
          }
          key={departmentId}
          hasDepartmentLeadAccess={hasDepartmentLeadAccess}
        />
      );

      if (mostRecentAssessment?.userAssessmentEventId && !hideTodoTab) {
        return todo.push(teamCard);
      }

      if (
        mostRecentAssessment?.hasEnoughResults
        // || allTeam360Scores?.departmentScores?.[departmentId]?.overall
      ) {
        return results.push(teamCard);
      }

      const daysRemaining = getDaysRemaining(mostRecentAssessment?.endDate);
      if (daysRemaining && daysRemaining > 0) {
        return pending.push(teamCard);
      }
      return noResults.push(teamCard);
    });

    setTabs({
      "To Do": todo,
      Pending: pending,
      Results: results,
      "No Results": noResults,
    });
  }, [
    departmentIds,
    departmentsMostRecentAssessments,
    departments,
    pendingAssessments,
    hasDepartmentLeadAccess,
    hideTodoTab,
    allTeam360Scores,
  ]);

  const getCollapseButton = () => {
    if (arrayLength <= maxShowing) {
      return null;
    }

    if (isExpanded) {
      return (
        <Button
          variant="tertiary-blue"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          Collapse
          <FontAwesomeIcon icon={`caret-up`} className="ms-2" />
        </Button>
      );
    }
    return (
      <Button
        variant="tertiary-blue"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        See all surveys
        <FontAwesomeIcon icon={`caret-down`} className="ms-2" />
      </Button>
    );
  };
  const getTabArray = () => {
    const tabArray: { label: string; count: number; isDisabled: boolean }[] = [
      {
        label: "All",
        count: allCount,
        isDisabled: allCount === 0,
      },
    ];

    if (!hideTodoTab) {
      tabArray.push({
        label: "To Do",
        count: todoCount,
        isDisabled: todoCount === 0,
      });
    }

    return [
      ...tabArray,
      {
        label: "Results",
        count: resultsCount,
        isDisabled: resultsCount === 0,
      },
      {
        label: "Pending",
        count: pendingCount,
        isDisabled: pendingCount === 0,
      },
      {
        label: "No Results",
        count: noResultsCount,
        isDisabled: noResultsCount === 0,
      },
    ];
  };

  const returnArr =
    tabShowing === "All"
      ? [
          ...tabs["To Do"],
          ...tabs.Pending,
          ...tabs.Results,
          ...tabs["No Results"],
        ]
      : tabs[tabShowing];
  const arrayLength = returnArr.length;
  const todoCount = tabs["To Do"].length;
  const pendingCount = tabs.Pending.length;
  const resultsCount = tabs.Results.length;
  const noResultsCount = tabs["No Results"].length;
  const allCount = todoCount + pendingCount + resultsCount + noResultsCount;

  if (arrayLength === 0) {
    return null;
  }

  return (
    <>
      <CircularTabs
        selectedTab={tabShowing}
        onTabSelected={(tab) => setTabShowing(tab)}
        tabs={getTabArray()}
      />
      <div className="column-gap-12px">
        {returnArr.slice(0, !isExpanded ? maxShowing : arrayLength)}
      </div>
      {getCollapseButton()}
    </>
  );
};

export default DepartmentTempCard;
