import { ReactElement, useEffect, useState } from "react";
import { Card, Form, Button } from "react-bootstrap";
import { useAppSelector, useAppDispatch } from "utils/redux/hooks";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "app/storybookComponents/Loading";
import {
  signUserUp,
  selectSetupAccountStatus,
  selectCheckSetupAccountStatus,
  checkSetupAccount,
} from "./slice";
import { CreateAccountPayload } from "./types";
import AssessmentLink from "../Assessment/AssessmentLink";

// TODO: NEED TO CREATE UNIT TESTS FOR ACCOUNT SETUP
export default function AccountSetupCard() {
  // Constants
  const MAX_STEPS = 3;
  // Utility Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const setupAccountStatus = useAppSelector(selectSetupAccountStatus);
  const checkSetupAccountStatus = useAppSelector(selectCheckSetupAccountStatus);

  // Use Effect Hooks
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (!queryParams.get("token1") || !queryParams.get("token2")) {
      return;
    }

    if (checkSetupAccountStatus === "idle") {
      setTokens({
        token1: queryParams.get("token1")!,
        token2: queryParams.get("token2")!,
      });
      dispatch(checkSetupAccount({ token1: queryParams.get("token1")! }));
    }
  }, [checkSetupAccountStatus, dispatch]);

  useEffect(() => {
    if (setupAccountStatus === "succeeded") {
      navigate("/", {
        state: {
          startingStep: 2,
        },
      });
    }
  }, [setupAccountStatus, navigate]);

  // Use State Hooks
  const [currentStep, setCurrentStep] = useState<1 | 2 | 3>(1);
  const [form, setForm] = useState<CreateAccountPayload>({
    // Step 1
    firstName: "",
    lastName: "",
    password: "",
    agreeToOdaTerms: 0,
    // Step 2
    position: "",
    whatIDoHere: "",
    gender: "select",
    // Step 3
    linkedInUrl: "",
    oneYearIn: "",
  });
  const [tokens, setTokens] = useState<null | {
    token1: string;
    token2: string;
  }>(null);
  const [showLinkedInLink, setShowLinkedInLink] = useState(false);
  const [showOptionalField1, setShowOptionalField1] = useState(false);

  // On change handlers
  const onFormChange = (formField: string, value: string) => {
    setForm({ ...form, [formField]: value });
  };

  const stepText = `Step ${currentStep} of ${MAX_STEPS}`;
  const steps: { [stepNumber: number]: ReactElement } = {
    1: (
      <div className="column-gap-20px">
        <div className="align-self-start">
          <p>{stepText}</p>
          <h1>Complete Account Setup</h1>
        </div>
        <p className="informational-text">
          Please enter your personal information
        </p>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            setCurrentStep(2);
          }}
        >
          <Form.Group
            className="form-group"
            controlId="accountSetupFormFirstName"
          >
            <Form.Label>First Name</Form.Label>
            <Form.Control
              placeholder="What should people call you?"
              name="firstName"
              onChange={(e) => {
                onFormChange(e.target.name, e.target.value);
              }}
              value={form.firstName}
              required
            />
          </Form.Group>

          <Form.Group
            className="form-group"
            controlId="accountSetupFormLastName"
          >
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              placeholder="Last Name"
              name="lastName"
              onChange={(e) => {
                onFormChange(e.target.name, e.target.value);
              }}
              value={form.lastName}
              required
            />
          </Form.Group>

          <Form.Group
            className="form-group"
            controlId="accountSetupFormPassword"
          >
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              name="password"
              onChange={(e) => {
                onFormChange(e.target.name, e.target.value);
              }}
              value={form.password}
              required
            />
          </Form.Group>
          <Form.Group
            className="form-group align-self-center mb-0"
            controlId="accountSetupFormCheckbox"
          >
            <Form.Check
              type="checkbox"
              label={
                <span>
                  I have read and agree to both the
                  <AssessmentLink text="Terms of Use" />
                  and the <AssessmentLink text="Privacy Policy" />
                </span>
              }
              name="agreeToOdaTerms"
              onChange={(e) => {
                setForm({
                  ...form,
                  agreeToOdaTerms: e.target.checked ? 1 : 0,
                });
              }}
              required
            />
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            disabled={
              !(
                form.firstName &&
                form.lastName &&
                form.password &&
                form.agreeToOdaTerms
              )
            }
          >
            Next
          </Button>
        </Form>
      </div>
    ),
    2: (
      <div className="column-gap-20px">
        <div className="align-self-start">
          <p>{stepText}</p>
          <h1>Complete Account Setup</h1>
        </div>
        <p className="informational-text">
          Please enter your contact information
        </p>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            setCurrentStep(3);
          }}
        >
          <Form.Group className="form-group" controlId="accountSetupGender">
            <Form.Label>Gender</Form.Label>
            <Form.Select
              onChange={(e) => onFormChange(e.target.name, e.target.value)}
              name="gender"
              value={form.gender}
              className="basic-form-input form-input"
            >
              <option value="select" disabled={true}>
                Select Gender
              </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="non-binary">Non-binary</option>
              <option value="do-not-disclose">Choose not to disclose</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="form-group" controlId="accountSetupPosition">
            <Form.Label>Position</Form.Label>
            <Form.Control
              placeholder="Position at your company"
              name="position"
              onChange={(e) => {
                onFormChange(e.target.name, e.target.value);
              }}
              value={form.position}
              required
            />
          </Form.Group>

          <Form.Group className="form-group" controlId="accountSetupWhatIDo">
            <Form.Label>What I do at this company</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="I am a Product Manager working on our new mobile consumer app."
              name="whatIDoHere"
              onChange={(e) => {
                onFormChange(e.target.name, e.target.value.slice(0, 250));
              }}
              value={form.whatIDoHere}
              required
            />
            <Form.Text id="whatIDoCharLimit" muted>
              {form.whatIDoHere.length}/250 Characters
            </Form.Text>
          </Form.Group>

          <Button
            variant="primary"
            type="submit"
            disabled={
              !(form.position && form.whatIDoHere && form.gender !== "select")
            }
          >
            Next
          </Button>
        </Form>
      </div>
    ),
    3: (
      <div className="column-gap-20px">
        <div className="align-self-start">
          <p>{stepText}</p>
          <h1>Optional Information</h1>
        </div>
        <p className="informational-text">
          Enter what you’d like your team members to know about you:
        </p>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            if (tokens === null) {
              return;
            }
            dispatch(
              signUserUp({
                ...form,
                ...tokens,
              })
            );
          }}
        >
          <Form.Group
            className="form-group"
            controlId="accountSetupOptionalField1"
          >
            <Form.Label>LinkedIn URL</Form.Label>
            {showLinkedInLink ? (
              <Form.Control
                placeholder="https://www.linkedin.com/in/"
                name="linkedInUrl"
                onChange={(e) => {
                  onFormChange(e.target.name, e.target.value);
                }}
                value={form.linkedInUrl}
              />
            ) : (
              <Button
                variant="outline-primary"
                className="d-block"
                onClick={() => {
                  setShowLinkedInLink(true);
                }}
              >
                <FontAwesomeIcon icon="plus" /> Add
              </Button>
            )}
          </Form.Group>

          <Form.Group
            className="form-group"
            controlId="accountSetupOptionalField3"
          >
            <Form.Label>My 1 Year Goal</Form.Label>
            {showOptionalField1 ? (
              <>
                <Form.Control
                  as="textarea"
                  placeholder="In 1 year, I will be leading a team and our mobile app will account for 20% of all company revenue"
                  name="oneYearIn"
                  onChange={(e) => {
                    onFormChange(e.target.name, e.target.value.slice(0, 250));
                  }}
                  value={form.oneYearIn}
                />
                <Form.Text id="oneYearInCharLimit" muted>
                  {form.oneYearIn.length}/250 Characters
                </Form.Text>
              </>
            ) : (
              <Button
                variant="outline-primary"
                className="d-block"
                onClick={() => {
                  setShowOptionalField1(true);
                }}
              >
                <FontAwesomeIcon icon="plus" /> Add
              </Button>
            )}
          </Form.Group>

          <Button
            variant="primary"
            type="submit"
            disabled={setupAccountStatus === "loading"}
          >
            Done
          </Button>
        </Form>
      </div>
    ),
  };

  const renderContent = () => {
    if (checkSetupAccountStatus === "failed") {
      return "Sorry This link no longer works.";
    }
    if (checkSetupAccountStatus === "loading") {
      return <Loading />;
    }
    return <>{steps[currentStep]}</>;
  };

  return <Card className="p-3 mt-5">{renderContent()}</Card>;
}
