import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { request } from "utils/request";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import { OpenAIGeneratedText } from "app/components/OpenAIInternalTools/OpenAIModule/types";

export interface SkillState {
  advice: null | OpenAIGeneratedText;
  gettingAdviceStatus: responseStatus;
}

const initialState: SkillState = {
  advice: null,
  gettingAdviceStatus: "idle",
};

export const getAdvice = createAsyncThunk(
  "generateAdvice",
  async (payload: {
    step1Response: string;
    step2Response: string;
    step3Response: string;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/generate`;
    return (await request(requestUrl, {
      method: "POST",
      body: JSON.stringify({ payload: { promptId: 39, userInput: payload } }),
    })) as OpenAIGeneratedText;
  }
);

export const skillSlice = createSlice({
  name: "skill",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAdvice.pending, (state) => {
        state.gettingAdviceStatus = "loading";
      })
      .addCase(
        getAdvice.fulfilled,
        (state, action: PayloadAction<OpenAIGeneratedText>) => {
          return {
            ...state,
            gettingAdviceStatus: "succeeded",
            advice: { ...action.payload },
          };
        }
      )
      .addCase(getAdvice.rejected, (state) => {
        state.gettingAdviceStatus = "failed";
      });
  },
});

export const selectAdvice = (state: RootState) => state.skillsCoach.advice;
export const selectGetAdviceStatus = (state: RootState) =>
  state.skillsCoach.gettingAdviceStatus;

export default skillSlice.reducer;
