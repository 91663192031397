import { Card } from "react-bootstrap";
import { SimilarityScore } from "./types";

interface Props {
  readonly leftStripColor: string;
  readonly title: string;
  readonly similarityScore: SimilarityScore;
  readonly tagColor: string;
}

export default function PersonalitySimilaritySection({
  leftStripColor,
  title,
  similarityScore,
  tagColor,
}: Props) {
  const tagClassName = `${tagColor} label-tag m-0`;

  return (
    <Card
      className={`card-with-left-strip column-gap-8px ${leftStripColor}`}
      style={{ padding: "20px 16px", width: "49%", minHeight: "148px" }}
    >
      <h3>{title}</h3>
      <p>{similarityScore.text}</p>
      <div className="d-flex row-gap-12px align-items-center mt-auto">
        <p className="grey-text">Similarity score:</p>
        <span className={tagClassName}>
          {Math.round(similarityScore.similarityScore)}%
        </span>
        <span className={tagClassName}>
          {similarityScore.similarityClassification}
        </span>
      </div>
    </Card>
  );
}
