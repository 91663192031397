import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'utils/redux/store';

// This function should return back if the user that is logged in has access to invite members to a company or team
export const addBreadCrumb =
  (pathname: string) => (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      global: {
        companyInfo,
        usersInfoById,
        teamsByTeamId,
        currentUserAccountId,
      },
      breadCrumbs: { historyArray, breadcrumbPressed },
    } = getState();

    if (breadcrumbPressed) {
      return dispatch(setBreadCrumbPressed(false));
    }
    const pathArray = pathname.split('/');

    const pathName =
      pathArray[1] === 'UserGuide' && pathArray[2] === undefined
        ? `${pathname}/${currentUserAccountId}`
        : pathname;

    // Before we add the breadcrumb to our history array we need to make sure that it is not the last item in the array, if it is then we do not need to add it again.
    if (
      historyArray.length > 0 &&
      historyArray[historyArray.length - 1].pathName === pathName
    ) {
      return;
    }

    // Also if the page I am trying to visit is the same as the page before the current, then instead of adding another breadcrumb lets pop the last breadcrumb off.
    if (
      historyArray.length > 1 &&
      historyArray[historyArray.length - 2].pathName === pathName
    ) {
      return dispatch(popHistoryArray());
    }

    // This function returns back the display name for the breadcrumb based on the path name
    const getDisplayName = () => {
      switch (pathArray[1]) {
        case 'UserGuide': {
          const userAccountId = Number(pathArray[2] || currentUserAccountId);

          if (!userAccountId || !usersInfoById[userAccountId]) {
            return pathArray[1];
          }
          return `${usersInfoById[userAccountId]?.firstName} ${usersInfoById[userAccountId]?.lastName}`;
        }
        case 'TeamGuide': {
          const teamId = Number(pathArray[2]);
          if (!teamId || !teamsByTeamId[teamId]) {
            return pathArray[1];
          }
          return teamsByTeamId[teamId].teamName;
        }
        case 'CompanyGuide': {
          if (!companyInfo) {
            return 'Company Guide';
          }
          return companyInfo.companyName;
        }
        case 'Search': {
          return pathArray[2];
        }
        default:
          return pathArray[1];
      }
    };

    dispatch(addSingleBreadCrumb({ pathName, displayName: getDisplayName() }));
  };

// This not be needed if we place the breadcrumbs inside of the App component
// ------------------ State Type/Structure ------------------
export interface BreadCrumbsState {
  historyArray: { pathName: string; displayName: string }[];
  breadcrumbPressed: boolean;
}

// ------------------ InitialState ------------------
const initialState: BreadCrumbsState = {
  historyArray: [],
  breadcrumbPressed: false,
};

// ------------------ Beginning of Slice Definition ------------------
export const breadCrumbSlice = createSlice({
  name: 'breadCrumbs',
  initialState,
  reducers: {
    clearHistoryArray: (state) => {
      state.historyArray = [];
    },
    addSingleBreadCrumb: (
      state,
      action: PayloadAction<{ pathName: string; displayName: string }>
    ) => {
      state.historyArray = [...state.historyArray, action.payload];
    },
    setHistoryArray: (
      state,
      action: PayloadAction<{ pathName: string; displayName: string }[]>
    ) => {
      state.historyArray = action.payload;
    },
    popHistoryArray: (state) => {
      state.historyArray = state.historyArray.slice(0, -1);
    },
    popMultipleHistoryArray: (state, { payload }: PayloadAction<number>) => {
      state.historyArray = state.historyArray.slice(0, -1 * payload);
      state.breadcrumbPressed = true;
    },
    setBreadCrumbPressed: (state, { payload }: PayloadAction<boolean>) => {
      state.breadcrumbPressed = payload;
    },
  },
});

// ------------------ Selectors ------------------
export const selectBreadcrumbs = (state: RootState) =>
  state.breadCrumbs.historyArray;

export default breadCrumbSlice.reducer;

export const {
  clearHistoryArray,
  addSingleBreadCrumb,
  setHistoryArray,
  popHistoryArray,
  popMultipleHistoryArray,
  setBreadCrumbPressed,
} = breadCrumbSlice.actions;
