import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useRef, useEffect } from 'react';

interface Props {
  filterName: string;
  options?: { label: string; value: string | number; selected?: boolean }[];
  onOptionSelect: (e: string | number) => void;
  toggledFilters?: { [filterName: string]: string[] | number[] };
  selectedValue?: string | number;
}

// Eventual we will add a prop that will hold the dropdown options, each option will have a checkmark next to it if it is selected.
export default function SearchFilter({
  selectedValue,
  filterName,
  options = [{ label: 'test', value: 'test' }],
  onOptionSelect,
  toggledFilters,
}: Props) {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isToggled, setIsToggled] = useState(false);
  const [toggledFilterCount, setToggledFilterCount] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (toggledFilters?.[filterName] === undefined) {
      return setToggledFilterCount(null);
    }
    setToggledFilterCount(`(${toggledFilters[filterName].length})`);
  }, [toggledFilters, filterName]);

  // Function to handle clicks outside the dropdown
  const handleClickOutside: EventListener = (event: Event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsToggled(false);
    }
  };

  useEffect(() => {
    // If the toggle is clicked then we need to add an event listener to close the dropdown if the user clicks outside of it.
    if (isToggled) {
      document.addEventListener('click', handleClickOutside);
    }

    // Once the toggle is closed we need to remove the event listener.
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isToggled]);

  const getOptions = () =>
    options.map((option, idx) => (
      <div key={option.value} className="filter-option" role="button">
        <input
          type="checkbox"
          id={`option${idx}`}
          name={`option${idx}`}
          checked={option.selected}
          onChange={() => {
            onOptionSelect(option.value);
          }}
        />
        <label htmlFor={`option${idx}`}>{option.label}</label>
      </div>
    ));

  return (
    <div className="filter" ref={dropdownRef}>
      <span
        className={`filter-button ${isToggled ? 'active' : ''}`}
        role={'button'}
        onClick={() => setIsToggled(!isToggled)}
      >
        {selectedValue || `${filterName || ''} ${toggledFilterCount || ''}`}
        <FontAwesomeIcon icon={`caret-${isToggled ? 'up' : 'down'}`} />
      </span>
      {isToggled ? (
        <div className="search-filter-dropdown-menu">
          {/* <div className="filter-option">
            <FontAwesomeIcon icon="search" />
            Search a ______
          </div> */}
          {getOptions()}
        </div>
      ) : null}
    </div>
  );
}
