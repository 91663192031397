import { memo, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import Button from "app/storybookComponents/Button";
import { selectTeamsByTeamId } from "app/containers/Global/slice";
import { useNavigate } from "react-router-dom";
import {
  acceptInvitation,
  declineInvitation,
  dismissNotification,
  selectDismissedNotificationIds,
  selectAllTeamInvitations,
  selectAllPendingAssessmentsNotifications,
} from "../slice";

interface Props {
  teamIds?: number[];
}

const NotificationCard = ({ teamIds }: Readonly<Props>) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const allPendingTeamNotifications = useAppSelector(selectAllTeamInvitations);
  const allPendingAssessmentNotifications = useAppSelector(
    selectAllPendingAssessmentsNotifications
  );
  const teamsById = useAppSelector(selectTeamsByTeamId);
  const dismissedNotificationIds = useAppSelector(
    selectDismissedNotificationIds
  );

  const getTeamInvitations = (teamIds: number[] = []) => {
    const teamInvitations: React.JSX.Element[] = [];
    teamIds.forEach((teamId) => {
      const team = teamsById[teamId];
      if (!team) {
        return;
      }

      teamInvitations.push(
        <div key={teamId}>
          <div>
            You've been invited to join "<span>{team.teamName}</span>"
          </div>
          <div>
            <div>
              <Button>Accept</Button>
              <Button variant="danger">Decline</Button>
            </div>
            <div>
              <Button variant="tertiary">Dismiss</Button>
            </div>
          </div>
        </div>
      );
    });
    return teamInvitations;
  };

  const pendingNotifications = useMemo(() => {
    const onAcceptInvitationClick = async (
      teamId: number,
      notificationId: number
    ) => {
      await dispatch(acceptInvitation({ teamId, notificationId }));
      navigate(`/TeamGuide/${teamId}`);
    };

    const pendingNotifications: React.JSX.Element[] = [];
    allPendingTeamNotifications.forEach((notification) => {
      const teamObj = teamsById[notification.teamId];
      if (!teamObj) {
        return;
      }
      const isDisabled = dismissedNotificationIds.includes(
        notification.notificationId
      );
      const { teamId, notificationId } = notification;
      pendingNotifications.push(
        <div key={notification.notificationId} className="notification">
          <p>You've been invited to join "{teamObj.teamName}" </p>
          <div className="notification-buttons">
            <div>
              <Button
                disabled={isDisabled}
                onClick={() => onAcceptInvitationClick(teamId, notificationId)}
              >
                Accept
              </Button>
              <Button
                variant="danger"
                disabled={isDisabled}
                onClick={() =>
                  dispatch(declineInvitation({ teamId, notificationId }))
                }
              >
                Decline
              </Button>
            </div>
            <div>
              <Button
                variant="tertiary"
                onClick={() => {
                  dispatch(dismissNotification(notification.notificationId));
                }}
                disabled={isDisabled}
              >
                Dismiss
              </Button>
            </div>
          </div>
        </div>
      );
    });

    allPendingAssessmentNotifications.forEach((notification) => {
      const teamInfo = notification?.teamId && teamsById[notification.teamId];
      const isDisabled = dismissedNotificationIds.includes(
        notification.notificationId
      );
      if (
        !teamInfo ||
        // This might be a temporary way to handle this, but if the team is private and the user is not a member of the team, then we don't want to show the notification
        (teamInfo.private === 1 && Object.keys(teamInfo).length === 1)
      ) {
        return;
      }
      pendingNotifications.push(
        <div key={notification.notificationId} className="notification">
          <p>
            You have been invited by {notification.invitedByFirstName} to take
            the {notification.testName}{" "}
            {teamInfo ? (
              <>
                for <b>{teamInfo.teamName}</b>
              </>
            ) : null}
            .
          </p>
          <div className="notification-buttons">
            <div>
              <Button
                disabled={isDisabled}
                onClick={() => {
                  navigate(
                    `/survey/team360?assessmentEventId=${notification.assessmentEventId}&teamId=${notification.teamId}`
                  );
                }}
                variant="secondary-blue"
              >
                {notification.droppedOffAt ? "Resume" : "Begin"} Survey
              </Button>
            </div>
          </div>
        </div>
      );
    });
    return pendingNotifications;
  }, [
    allPendingAssessmentNotifications,
    allPendingTeamNotifications,
    teamsById,
    dispatch,
    dismissedNotificationIds,
    navigate,
  ]);

  const teamInvitations = getTeamInvitations(teamIds);

  return (
    <div className="notification-box">
      <div
        style={{
          padding: "16px",
          paddingBottom: "0px",
        }}
      >
        <h3>Notifications</h3>
      </div>
      {!pendingNotifications?.length && !teamInvitations?.length ? (
        <div
          className="empty-card"
          style={{
            margin: "16px",
            padding: "20px",
          }}
        >
          <span>No notifications</span>
          <p>
            Invites to join teams or to take the TEAMscan survey will be shown
            here
          </p>
        </div>
      ) : null}
      {pendingNotifications?.length ? <div>{pendingNotifications}</div> : null}
      {teamInvitations?.length ? <div>{teamInvitations}</div> : null}
    </div>
  );
};

export default memo(NotificationCard);
