// This is here to achieve the animation effect
export const getSingleLikerClassName = (
  questionIndex: number,
  activeQuestion: number
): string => {
  const className = 'likert-single-scale';
  if (questionIndex === activeQuestion) {
    return className + ' active';
  }
  if (questionIndex === activeQuestion - 1) {
    return className + ' previous';
  }
  if (questionIndex === activeQuestion + 1) {
    return className + ' next';
  }
  return className;
};
