import { useState, useEffect, ReactElement } from "react";
import { Nav } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import ModuleCard from "app/components/Modules/Cards/ModuleCard";
import { getEntries } from "utils/helperFunctions";
import {
  hideModal,
  showEditModuleModal,
  setModalTab,
  selectModalTab,
  selectGuideType,
  selectUserIdOrTeamIdOrCompanyId,
} from "../slice";
import { modifyModuleTemplates, ModifiedModuleTemplates } from "../helpers";
import Button from "app/storybookComponents/Button";
import {
  selectUserModuleTemplates,
  selectUsedUserGuideModules,
} from "app/containers/UserGuide/slice";
import {
  selectTeamModuleTemplates,
  selectUsedTeamTemplateIds,
} from "app/containers/TeamGuide/slice";
import InformationalModuleCard from "../Cards/InformationalModuleCard";
import {
  selectCompanyModuleTemplates,
  selectUsedCompanyTemplateIds,
} from "app/containers/CompanyGuide/slice";

export default function AddModuleModal() {
  const dispatch = useAppDispatch();
  const userModuleTemplates = useAppSelector(selectUserModuleTemplates);
  const teamModuleTemplates = useAppSelector(selectTeamModuleTemplates);
  const companyModuleTemplates = useAppSelector(selectCompanyModuleTemplates);
  const guideType = useAppSelector(selectGuideType);
  const userUsedModules = useAppSelector(selectUsedUserGuideModules);
  const teamUsedModules = useAppSelector(selectUsedTeamTemplateIds);
  const companyUsedModules = useAppSelector(selectUsedCompanyTemplateIds);
  const userIdOrTeamIdOrCompanyId = useAppSelector(
    selectUserIdOrTeamIdOrCompanyId
  );

  const [modifiedModuleTemplates, setModifiedModuleTemplates] =
    useState<ModifiedModuleTemplates>({});
  const modalTab = useAppSelector(selectModalTab);

  useEffect(() => {
    switch (guideType) {
      case "user":
        if (userModuleTemplates !== null) {
          setModifiedModuleTemplates(
            modifyModuleTemplates(userModuleTemplates)
          );
        }
        break;
      case "team":
        if (teamModuleTemplates !== null) {
          setModifiedModuleTemplates(
            modifyModuleTemplates(teamModuleTemplates)
          );
        }
        break;
      case "company":
        if (companyModuleTemplates !== null) {
          setModifiedModuleTemplates(
            modifyModuleTemplates(companyModuleTemplates)
          );
        }
    }
  }, [
    userModuleTemplates,
    teamModuleTemplates,
    companyModuleTemplates,
    guideType,
  ]);

  useEffect(() => {
    if (modifiedModuleTemplates === null || modalTab) {
      return;
    }
    dispatch(setModalTab(Object.keys(modifiedModuleTemplates)[0]));
  }, [dispatch, modifiedModuleTemplates, modalTab]);

  const getTabs = () => {
    if (!modifiedModuleTemplates) {
      return null;
    }

    const tabs = Object.keys(modifiedModuleTemplates).map((val, idx) => (
      <Nav.Item key={idx}>
        <Nav.Link eventKey={val}>{val}</Nav.Link>
      </Nav.Item>
    ));

    // If either team or org guide then we should add a resource tab as the second tab
    if (guideType === "company" || guideType === "team") {
      tabs.splice(
        1,
        0,
        <Nav.Item key={"Resources"}>
          <Nav.Link eventKey={"Resources"}>Resources</Nav.Link>
        </Nav.Item>
      );
    }

    return (
      <Nav
        className="simple-nav"
        activeKey={modalTab ?? ""}
        onSelect={(e) => {
          dispatch(setModalTab(e));
        }}
      >
        {tabs}
      </Nav>
    );
  };

  const onAddModule = (
    moduleTemplateId: number | string,
    moduleType: string
  ) => {
    if (
      (moduleType !== "List" &&
        moduleType !== "Free Text" &&
        moduleType !== "Resource") ||
      userIdOrTeamIdOrCompanyId === null
    ) {
      return;
    }

    dispatch(
      showEditModuleModal({
        moduleTemplateId,
        moduleType,
        userIdOrTeamIdOrCompanyId,
        guideType,
      })
    );
  };

  const getModuleCards = () => {
    if (modalTab === "Resources") {
      return (
        <div className="module-card-holder">
          <ModuleCard
            moduleTemplateId={"resources"}
            title={"Key Resource"}
            onAddModule={onAddModule}
            shortDescription={
              "Share links to key resources (e.g. files, spreadsheets, documents, etc.)"
            }
            moduleType="Resource"
            iconName={"phone-laptop"}
          />
          <InformationalModuleCard guideType={guideType} modalTab={modalTab} />
        </div>
      );
    }

    if (
      !modalTab ||
      modifiedModuleTemplates === null ||
      !modifiedModuleTemplates[modalTab]
    ) {
      return null;
    }

    type ModuleTemplate = {
      moduleTemplateId: string;
      title: string;
      shortDescription: string;
      description: string;
      moduleType: string;
      moduleAdded: boolean;
      iconName?: string;
    };
    const addedModuleTemplates: ModuleTemplate[] = [];
    const nonAddedModuleTemplates: ModuleTemplate[] = [];

    getEntries(modifiedModuleTemplates[modalTab]).forEach(
      ([moduleType, moduleTemplatesObj]) => {
        Object.values(moduleTemplatesObj).forEach(
          ({
            moduleTemplateId,
            title,
            description,
            shortDescription,
            iconName,
          }) => {
            const moduleAdded = !!(
              userIdOrTeamIdOrCompanyId &&
              getUsedModules()?.[moduleType].includes(moduleTemplateId)
            );
            if (moduleAdded) {
              addedModuleTemplates.push({
                moduleTemplateId,
                title,
                description,
                moduleType,
                moduleAdded,
                shortDescription,
                iconName,
              });
            } else {
              nonAddedModuleTemplates.push({
                moduleTemplateId,
                title,
                description,
                moduleType,
                moduleAdded,
                shortDescription,
                iconName,
              });
            }
          }
        );
      }
    );

    const sortedModuleTemplates = [
      ...nonAddedModuleTemplates,
      ...addedModuleTemplates,
    ];

    const recommendedCards: ReactElement[] = [];
    const otherCards: ReactElement[] = [];
    sortedModuleTemplates.forEach(
      ({
        moduleTemplateId,
        title,
        moduleType,
        moduleAdded,
        shortDescription,
        iconName,
      }) => {
        if (recommendedCards.length < 2) {
          recommendedCards.push(
            <ModuleCard
              moduleTemplateId={moduleTemplateId}
              title={title}
              moduleType={moduleType}
              onAddModule={onAddModule}
              key={`${moduleType}${moduleTemplateId}`}
              shortDescription={shortDescription}
              moduleAdded={moduleAdded}
              iconName={iconName}
            />
          );
        } else {
          otherCards.push(
            <ModuleCard
              moduleTemplateId={moduleTemplateId}
              title={title}
              moduleType={moduleType}
              onAddModule={onAddModule}
              key={`${moduleType}${moduleTemplateId}`}
              shortDescription={shortDescription}
              moduleAdded={moduleAdded}
              iconName={iconName}
            />
          );
        }
      }
    );
    otherCards.push(
      <InformationalModuleCard guideType={guideType} modalTab={modalTab} />
    );

    return (
      <>
        <div>
          <h2 style={{ color: "#53565a", fontSize: "16px" }}>Recommended</h2>
          <div className="module-card-holder">{recommendedCards}</div>
        </div>
        {otherCards.length > 0 ? (
          <div>
            <h2 style={{ color: "#53565a", fontSize: "16px" }}>More Prompts</h2>
            <div className="module-card-holder">{otherCards}</div>
          </div>
        ) : null}
      </>
    );
  };

  const getUsedModules = () => {
    if (userIdOrTeamIdOrCompanyId === null) {
      return;
    }

    switch (guideType) {
      case "user":
        return userUsedModules?.[userIdOrTeamIdOrCompanyId];
      case "team":
        return teamUsedModules?.[userIdOrTeamIdOrCompanyId];
      case "company":
        return companyUsedModules?.[userIdOrTeamIdOrCompanyId];
      default:
        return null;
    }
  };

  const getAddToTitle = () => {
    if (guideType === "company") {
      return "Organization";
    }

    return guideType[0].toUpperCase() + guideType.slice(1);
  };

  return (
    <>
      <div className="modal-title-row">
        <h2>Add to {getAddToTitle()} Guide</h2>
        <Button
          onClick={() => dispatch(hideModal())}
          variant={"secondary-blue"}
          style={{ border: "none", width: "auto" }}
          xIcon
        />
      </div>
      <div>{getTabs()}</div>
      {getModuleCards()}
    </>
  );
}
