import "utils/fontawesomeIcons";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useMemo, useRef, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import GlobalNavbar from "app/components/GlobalNavbar";
import Dashboard from "app/containers/Dashboard";
import HiddenAdminConsole from "app/containers/HiddenAdminConsole";
import DepartmentInsightReport from "app/containers/DepartmentInsightReport";
import AdminConsole from "app/containers/AdminConsole";
import TeamGuide from "app/containers/TeamGuide";
import OrganizationGuide from "app/containers/CompanyGuide";
import AccountSetup from "app/containers/AccountSetup";
import UserGuide from "app/containers/UserGuide";
import Assessment from "app/containers/Assessment";
import Departments from "app/containers/Departments";
import Search from "app/containers/Search";
import Login from "app/containers/Login";
import { useAppSelector, useAppDispatch } from "utils/redux/hooks";
import {
  signUserIn,
  selectGetUserStatus,
  selectCurrentUserAccountId,
  selectGetAllTeamsStatus,
  selectGetAllUserStatus,
} from "app/containers/Global/slice";
import { getUserIdFromCookie } from "utils/helperFunctions";
import Loading from "app/storybookComponents/Loading";
import { ToastContainer } from "react-toastify";
import CreateTeamModal from "app/components/Modals/CreateTeamModal";
import {
  addBreadCrumb,
  clearHistoryArray,
} from "app/components/BreadCrumbs/slice";
import SkillsGuide from "app/containers/SkillsGuide";
import AdvancedAnalytics from "app/containers/AdvancedAnalytics";
import OnboardingModal from "app/components/Onboarding/OnboardingModal";
import UserSettings from "app/containers/UserSettings";
import ResetPasswordFlow from "app/containers/Login/ResetPasswordFlow";
import LaunchAssessmentModal from "app/components/LaunchAssessmentModal/LaunchAssessmentModal";
import EditDepartmentModal from "app/components/Modals/EditDepartmentModal";
import Team360AssessmentModal from "app/components/Team360Assessment/Team360AssessmentModal";
import FloatingChatbotButton from "app/containers/Chatbot/FloatingChatbotButton";
import InviteTeamLeaderModal from "app/components/Modals/InviteTeamLeaderModal";

// These will be the only pages that will show the breadcrumbs, we can add more later if we want
const allowedLocations = ["UserGuide", "TeamGuide", "CompanyGuide", "Search"];

function App() {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const tokenRef = useRef<string | null>(null);
  const user = useAppSelector(selectCurrentUserAccountId);
  const getUserStatus = useAppSelector(selectGetUserStatus);
  const getAllUsersStatus = useAppSelector(selectGetAllUserStatus);
  const getAllTeamStatus = useAppSelector(selectGetAllTeamsStatus);

  useEffect(() => {
    if (checkIfURLisPublic()) {
      return;
    }

    const userId = getUserIdFromCookie();
    // Once the user gets to the page then we need to fetch the user data, if the user is not validated then we need to kick them out.s
    if (getUserStatus === "idle" && userId !== null) {
      dispatch(signUserIn(userId));
    }
  }, [dispatch, getUserStatus]);

  // This hook is used to add and clear the breadcrumbs
  useEffect(() => {
    if (getAllTeamStatus !== "succeeded" || getAllUsersStatus !== "succeeded") {
      return;
    }

    const pathArray = pathname.split("/");
    if (!allowedLocations.includes(pathArray[1])) {
      dispatch(clearHistoryArray());
      return;
    }

    dispatch(addBreadCrumb(pathname));
  }, [pathname, dispatch, user, getAllTeamStatus, getAllUsersStatus]);

  // Once the user is logged in we should store the token that is in the local storage in a ref.
  // to be able to check if the token has changed.
  useEffect(() => {
    if (!user) return;
    const token = localStorage.getItem("tmg-tkn");
    tokenRef.current = token;
  }, [user]);

  // This effect is used to check if the token has changed in the local storage, if it has then we need to reload the page.
  useEffect(() => {
    const handleStorageChange = () => {
      const localStoredToken = localStorage.getItem("tmg-tkn");
      const currentToken = tokenRef.current;
      tokenRef.current = localStoredToken;

      // If the token is different then we need to reload the page.
      if (currentToken !== localStoredToken) {
        window.location.reload();
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  // As of now only two public routes should be /accountSetup and /join/:joinType/:inviteCode
  const checkIfURLisPublic = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const [, param1, param2] = window.location.pathname.split("/");
    const validJoinTypes = ["companies", "teams"];
    return (
      (param1 === "join" && validJoinTypes.includes(param2)) ||
      (param1 === "accountSetup" && queryParams.has("token1"))
    );
  };

  const [, firstPathName] = pathname.split("/");

  const navbar = useMemo(() => {
    if (!user) {
      return null;
    }
    switch (firstPathName) {
      case "accountSetup":
      case "join":
      case "survey":
      case "PasswordReset":
        return null;
    }
    return <GlobalNavbar />;
  }, [firstPathName, user]);

  const chatbot = useMemo(() => {
    if (!user) {
      return null;
    }

    return <FloatingChatbotButton />;
  }, [user]);

  // If gerUsersStatus is loading then we need to show the loading screen.
  if (getUserStatus === "loading") {
    return <Loading />;
  }

  return (
    <>
      <Team360AssessmentModal />
      <CreateTeamModal />
      <ToastContainer />
      <OnboardingModal />
      <LaunchAssessmentModal />
      <EditDepartmentModal />
      <InviteTeamLeaderModal />
      {/* When taking the assessment then we need to hide the navbar.  */}
      {navbar}
      {chatbot}
      <Routes>
        <Route path="/accountSetup" element={<AccountSetup />} />
        <Route path="/PasswordReset" element={<ResetPasswordFlow />} />
        <Route path="join/:joinType/:inviteCode" element={<AccountSetup />} />
        {user ? (
          <>
            <Route path={"/"} element={<Dashboard />} />
            <Route
              path="/HiddenAdminConsole"
              element={<HiddenAdminConsole />}
            />
            <Route path="/Search/People" element={<Search />} />
            <Route path="/Search/Teams" element={<Search />} />
            <Route path="/Search/Departments" element={<Search />} />
            <Route path="/AdminConsole" element={<AdminConsole />} />
            <Route
              path="/DepartmentInsightReport/:departmentId"
              element={<DepartmentInsightReport />}
            />
            <Route path="/UserSettings" element={<UserSettings />} />
            <Route path="/OrganizationGuide" element={<OrganizationGuide />} />
            <Route path="/AdvancedAnalytics" element={<AdvancedAnalytics />} />
            <Route path="/TeamGuide/:teamId" element={<TeamGuide />} />
            <Route
              path="/TeamGuide/:teamId/TeamSettings"
              element={<TeamGuide />}
            />
            <Route
              path="/TeamGuide/:teamId/TeamMembers"
              element={<TeamGuide />}
            />
            <Route
              path="/TeamGuide/:teamId/TeamPersonality"
              element={<TeamGuide />}
            />
            <Route path="/UserGuide" element={<UserGuide />} />
            <Route path="/UserGuide/:userAccountId" element={<UserGuide />} />
            <Route path="/SkillsGuide" element={<SkillsGuide />} />
            <Route path="/survey/team360" element={<Assessment />} />
            <Route
              path="/Department/:departmentId/Surveys"
              element={<Departments />}
            />

            {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
              routes for. */}
            <Route path="*" element={<div>404: Page Not Found</div>} />
          </>
        ) : (
          // If the user is not logged in then we need to show the login page.
          <Route path="*" element={<Login />} />
        )}
      </Routes>
    </>
  );
}

export default App;
