import { useState } from "react";
import Chatbot from "./Chatbot";
import Robot from "resources/images/robot.svg";
import { selectTeamAndDepartmentLeadIdsForLoggedInUser } from "app/containers/AdminConsole/slice";
import {
  selectCompanyInfo,
  selectGetCompanyInfoStatus,
  selectIsCurrentUserAdmin,
} from "app/containers/Global/slice";
import { useAppSelector } from "utils/redux/hooks";
import { isInternalCompanyAccount } from "utils/helperFunctions";

export default function FloatingChatbotButton() {
  const [showButton, setShowButton] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const teamAndDepartmentLeaderOf = useAppSelector(
    selectTeamAndDepartmentLeadIdsForLoggedInUser
  );
  const isAdmin = useAppSelector(selectIsCurrentUserAdmin);
  const companyInfo = useAppSelector(selectCompanyInfo);
  const getCompanyInfoStatus = useAppSelector(selectGetCompanyInfoStatus);

  const userIsATeamOrDepartmentLeader =
    teamAndDepartmentLeaderOf &&
    (teamAndDepartmentLeaderOf?.departments.length ||
      teamAndDepartmentLeaderOf?.teams.length);

  if (!(userIsATeamOrDepartmentLeader || isAdmin)) return null;

  if (
    !isInternalCompanyAccount(companyInfo?.companyAccountId) || // hiding this feature for all external companies
    getCompanyInfoStatus === "loading"
  ) {
    return null;
  }

  return (
    <>
      <button
        className="floating-chatbot-button"
        style={{
          display: showButton ? "block" : "none",
        }}
        onClick={() => {
          setShowButton(false);
          setIsOpen(true);
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src={Robot} alt="Criteria" className="assistant-avatar" /> Need
          coaching advice?
        </div>
      </button>
      <Chatbot
        isOpen={isOpen}
        closeChatbot={() => {
          setIsOpen(!isOpen);
          setShowButton(true);
        }}
      />
    </>
  );
}
