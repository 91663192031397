import { useState, useEffect, useLayoutEffect, useCallback } from "react";
import { Card, Form, Nav } from "react-bootstrap";
import { AdminConsoleTab, isAdminConsoleTab } from "./types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSearchParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "utils/redux/hooks";
import { PLATFORM_NAME } from "utils/constants";
import {
  selectAllCompanyUsersById,
  selectTeamsByTeamId,
  selectCompanyInfo,
} from "app/containers/Global/slice";
import {
  openCreateTeamModal,
  openEditDepartmentModal,
  openInviteTeamLeaderModal,
} from "app/components/Modals/slice";
import Button from "app/storybookComponents/Button";
import InviteUsersModal from "app/components/Modals/InviteUsersModal";
import PuzzleIllustration from "resources/images/trait-icons-team-dynamic.png";
import TeamAverageIllustration from "resources/images/illustration-team-average.png";
import MailSentIllustration from "resources/images/illustration-invite-sent.png";
import AdminConsoleSettings from "./AdminConsoleSettings";
import {
  selectDepartments,
  selectDepartmentTypes,
  getCompanySettings,
  createCompanyInviteLink,
  selectCompanyInviteLink,
  selectCreateCompanyInviteLinkStatus,
  selectGetCompanySettingsStatus,
  getTeamAndDepartmentLeads,
} from "./slice";
import { getSelectProps } from "utils/helperFunctions";
import Select from "react-select";
import Analytics from "./Analytics";
import {
  getDepartmentSearchInput,
  getPeopleSearchInput,
  getTeamSearchInput,
} from "./helpers";
import NavigateBackButton from "app/components/NavigateBackButton";
import DeactivateUserModal from "./Modals/DeactivateUserModal";
import {
  getDimensionGuide,
  getSkillGuide,
  selectGetDimensionGuideStatus,
  selectGetSkillsGuideStatus,
} from "app/containers/SkillsGuide/slice";
import AdminTablesHeader from "./AdminTablesHeader";
import TeamsTable from "./Tables/TeamsTable";
import DepartmentTable from "./Tables/DepartmentTable";
import PeopleTable from "./Tables/PeopleTable";
import SurveysTable from "./Tables/SurveysTable";
import SurveyBreakdownTable from "./Tables/SurveyBreakdownTable";

// TODO: Need to make the call to resend a user invitation
// TODO2: Need to make the call to deactivate a team, reactivate a team, remove a team, invite a user to a team and lastly we need to redirect the user to the team settings page(this will probably require us to set up a new route like /teamGuide/{teamId}/teamSettings)
export default function AdminConsole() {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  // ------------------------- App selectors -------------------------
  const companyInfo = useAppSelector(selectCompanyInfo);
  const teamInfoById = useAppSelector(selectTeamsByTeamId);
  const departments = useAppSelector(selectDepartments);
  const usersInfoById = useAppSelector(selectAllCompanyUsersById);
  const departmentTypes = useAppSelector(selectDepartmentTypes); // Department Types is different in that we use department types for analytics while departments are what the user sees.
  const companyInviteLink = useAppSelector(selectCompanyInviteLink);
  const createCompanyInviteLinkStatus = useAppSelector(
    selectCreateCompanyInviteLinkStatus
  );
  const getCompanySettingsStatus = useAppSelector(
    selectGetCompanySettingsStatus
  );
  const getDimensionGuideStatus = useAppSelector(selectGetDimensionGuideStatus);
  const getSkillsGuideStatus = useAppSelector(selectGetSkillsGuideStatus);

  // ------------------------- States -------------------------
  const [searchInput, setSearchInput] = useState("");
  const [displayedInput, setDisplayedInput] = useState("");
  const [filteredOptions, setFilteredOptions] = useState<{
    ids: number[];
    type: "Departments" | "Teams" | "People";
  } | null>(null);
  const [tabShowing, setTabShowing] = useState<AdminConsoleTab | null>(
    "Analytics"
  );
  const [modalShowing, setModalShowing] = useState<
    null | "Invite Users to Team" | "Invite Users to Company"
  >(null);
  const [editingId, setEditingId] = useState<undefined | number>(undefined);
  const [showDeactivateUserModalUserId, setShowDeactivateUserModalUserId] =
    useState<null | number>(null);

  // ------------------------- Hooks -------------------------
  useLayoutEffect(() => {
    // If the query params is set for the tab and the tab is a valid tab then we set the active tab to the query param.
    const tab = searchParams.get("tab");
    if (tab && isAdminConsoleTab(tab)) {
      setTabShowing(tab);
    } else {
      // if no tab is set or the tab is not a valid tab then we set the tab to the about tab.
      setTabShowing("Analytics");
    }
  }, [searchParams]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchInput(displayedInput);
    }, 500); // 300ms debounce delay

    return () => clearTimeout(delayDebounceFn);
  }, [displayedInput]);

  const handleSearchInputChange = useCallback(
    (changedInput: string) => {
      const pattern = new RegExp(changedInput.toLowerCase());
      switch (tabShowing) {
        case "Departments": {
          return setFilteredOptions({
            ids: getDepartmentSearchInput(
              pattern,
              departments,
              departmentTypes,
              usersInfoById
            ),
            type: tabShowing,
          });
        }
        case "Teams": {
          return setFilteredOptions({
            ids: getTeamSearchInput(pattern, teamInfoById, departments),
            type: tabShowing,
          });
        }
        case "People": {
          return setFilteredOptions({
            ids: getPeopleSearchInput(pattern, usersInfoById),
            type: tabShowing,
          });
        }
      }
    },
    [departments, departmentTypes, tabShowing, teamInfoById, usersInfoById]
  );

  useEffect(() => {
    if (searchInput) {
      handleSearchInputChange(searchInput);
    } else {
      setFilteredOptions(null);
    }
  }, [searchInput, handleSearchInputChange]);

  useEffect(() => {
    setDisplayedInput("");
    setSearchInput("");
    setFilteredOptions(null);
    if (tabShowing === "People") {
      dispatch(getTeamAndDepartmentLeads());
    }
  }, [tabShowing, dispatch]);

  useEffect(() => {
    if (
      createCompanyInviteLinkStatus === "idle" &&
      companyInfo?.companyAccountId
    ) {
      dispatch(
        createCompanyInviteLink({
          payload: { expiration: 20 },
          companyAccountId: companyInfo?.companyAccountId,
        })
      );
    }
  }, [createCompanyInviteLinkStatus, companyInfo?.companyAccountId, dispatch]);

  // Might need move this to the analytics page since not all tabs need this.
  useEffect(() => {
    if (getSkillsGuideStatus === "idle") {
      dispatch(getSkillGuide());
    }
  }, [dispatch, getSkillsGuideStatus]);

  // Might need move this to the analytics page since not all tabs need this.
  useEffect(() => {
    if (getDimensionGuideStatus === "idle") {
      dispatch(getDimensionGuide());
    }
  }, [dispatch, getDimensionGuideStatus]);

  useEffect(() => {
    if (getCompanySettingsStatus === "idle" && companyInfo?.companyAccountId) {
      dispatch(getCompanySettings(companyInfo.companyAccountId));
    }
  }, [getCompanySettingsStatus, companyInfo, dispatch]);

  // ------------------------- Helper functions -------------------------
  const getTable = () => {
    const isLoading = searchInput !== displayedInput;
    switch (tabShowing) {
      case "Teams": {
        return (
          <TeamsTable
            filteredTeamIds={
              filteredOptions !== null && filteredOptions.type === "Teams"
                ? filteredOptions.ids
                : undefined
            }
            onInviteToTeamClick={(teamId) => {
              setModalShowing("Invite Users to Team");
              setEditingId(teamId);
            }}
            searchTerm={searchInput}
            isLoading={isLoading}
          />
        );
      }
      case "People": {
        return (
          <PeopleTable
            filteredUserAccountIds={
              filteredOptions !== null && filteredOptions.type === "People"
                ? filteredOptions.ids
                : undefined
            }
            searchTerm={searchInput}
            isLoading={isLoading}
          />
        );
      }
      case "Departments":
        return (
          <DepartmentTable
            filteredDepartmentIds={
              filteredOptions !== null && filteredOptions.type === "Departments"
                ? filteredOptions.ids
                : undefined
            }
            searchTerm={searchInput}
            isLoading={isLoading}
          />
        );
      case "Surveys":
        return <SurveysTable />;
      case "SurveyBreakdown":
        return <SurveyBreakdownTable />;
      default:
        return null;
    }
  };

  const getSearchPlaceholderText = () => {
    switch (tabShowing) {
      case "Teams":
        return "Search by name, team lead or department...";
      case "People":
        return "Search by name, job title or email...";
      case "Departments":
        return "Search by name, type or leader...";
      default:
        return "";
    }
  };

  const getTabContent = () => {
    if (tabShowing === "Analytics") return <Analytics />;
    if (tabShowing === "Settings")
      return <AdminConsoleSettings users={usersInfoById} />;

    return (
      <>
        {getTablesHeader()}
        {getTableAndSearch()}
      </>
    );
  };

  const getTablesHeader = () => {
    switch (tabShowing) {
      case "Teams":
        return (
          <AdminTablesHeader
            headerTitle={tabShowing}
            headerDescription="View and manage all active teams. Create a new team or delete an existing team. View a team’s name, team leader, number of members, who can access the profile of the team (either team members only or anyone in the organization) and the department the team belongs to."
            bannerTitle="Create a new team"
            bannerDescription="Teams are the foundation of your organization. Create a team for each department, location, or any other group that makes sense for your organization."
            iconImageSrc={TeamAverageIllustration}
            iconImageAlt="Teams"
            actionButton={
              <div className="row-gap-8px">
                <Button
                  onClick={() =>
                    dispatch(openCreateTeamModal({ isFromAdminConsole: true }))
                  }
                >
                  <FontAwesomeIcon icon="user-plus" className="me-2" /> Create
                  Team
                </Button>
                <Button
                  variant="secondary-blue"
                  className="white-button"
                  onClick={() => dispatch(openInviteTeamLeaderModal())}
                >
                  Invite a team leader
                </Button>
              </div>
            }
          />
        );
      case "People":
        return (
          <AdminTablesHeader
            headerTitle={tabShowing}
            headerDescription="View and manage all users here. View a user’s job title, the number of teams they are on, and their access role. Users can be assigned as admin with full privileges or a non-admin user (e.g. a team member, team leader, or a department leader)."
            bannerTitle={`Invite people to ${PLATFORM_NAME}`}
            bannerDescription={`Learn, collaborate, and communicate with your team members all in one space`}
            iconImageSrc={MailSentIllustration}
            iconImageAlt="People"
            actionButton={
              <Button
                onClick={() => setModalShowing("Invite Users to Company")}
              >
                <FontAwesomeIcon icon="user-plus" className="me-2" /> Invite
              </Button>
            }
          />
        );
      case "Departments": {
        if (hasNoRows()) {
          return null;
        }

        return (
          <AdminTablesHeader
            headerTitle={tabShowing}
            headerDescription="Add, edit, or manage departments here. View all your departments, the number of teams and members per department, the department leader and the leader’s email."
            bannerTitle="Create a new department"
            bannerDescription="Departments simply categorize teams into larger groups (product, technology, marketing, etc.)"
            iconImageSrc={PuzzleIllustration}
            iconImageAlt="Departments"
            actionButton={
              <Button onClick={() => dispatch(openEditDepartmentModal())}>
                <FontAwesomeIcon icon="plus" className="me-2" /> Create
                Department
              </Button>
            }
          />
        );
      }
      case "Surveys": {
        return (
          <h2>{tabShowing}</h2>
          // <AdminTablesHeader
          //   headerTitle={tabShowing}
          //   bannerDescription="Send the TEAMscan survey to your organization, specific departments, or teams"
          //   bannerTitle="Launch TEAMscan"
          //   iconImageSrc={PuzzleIllustration}
          //   iconImageAlt="Launch TEAMscan"
          //   actionButton={
          //     <div className="row-gap-12px">
          //       <Button
          //         onClick={() => {
          //           dispatch(showScheduleAssessmentModal());
          //         }}
          //       >
          //         Launch
          //       </Button>
          //       <Button
          //         onClick={() => {
          //           dispatch(
          //             setShowOnboardingModal({
          //               eventType: "generalTeam360Information",
          //             })
          //           );
          //         }}
          //         variant="secondary-blue"
          //         className="white-button"
          //       >
          //         See details
          //       </Button>
          //     </div>
          //   }
          // />
        );
      }
      default:
        return null;
    }
  };

  const hasNoRows = () => {
    switch (tabShowing) {
      case "Teams":
        return Object.keys(teamInfoById).length === 0;
      case "People":
        return Object.keys(usersInfoById).length === 0;
      case "Departments":
        return Object.keys(departments).length === 0;
      default:
        return false;
    }
  };

  const getTableAndSearch = () => {
    const placeholder = getSearchPlaceholderText();
    const { selectStyles, components } = getSelectProps();
    return (
      <>
        {/* Hiding the search input for survey table and instead holding the search inside of the survey table component */}
        {tabShowing === "Surveys" ||
        tabShowing === "SurveyBreakdown" ||
        hasNoRows() ? null : (
          <Form.Group className="my-3">
            <Select
              placeholder={placeholder}
              isClearable={true}
              isSearchable={true}
              components={components}
              inputValue={displayedInput}
              styles={selectStyles}
              menuIsOpen={false}
              onInputChange={(e, actionMeta) => {
                if (actionMeta.action === "input-change") {
                  setDisplayedInput(e);
                }
              }}
            />
          </Form.Group>
        )}
        <div>{getTable()}</div>
      </>
    );
  };

  const onTabSelect = (tab: string | null) => {
    setSearchParams({ tab: tab ?? "" });
    setTabShowing(tab as AdminConsoleTab);
  };

  const getNavBar = () => {
    if (tabShowing === "Analytics" || tabShowing === "SurveyBreakdown") {
      return null;
    }

    return (
      <Nav
        className="simple-nav"
        activeKey={tabShowing ?? ""}
        onSelect={(tab) => {
          onTabSelect(tab);
        }}
        style={{
          gap: "24px",
          marginTop: "20px",
        }}
      >
        <Nav.Item>
          <Nav.Link eventKey="Surveys">Surveys</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="Departments">Departments</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="Teams">Teams</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="People">People</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="Settings">Settings</Nav.Link>
        </Nav.Item>
      </Nav>
    );
  };

  return (
    <div className="page admin-console">
      <DeactivateUserModal
        show={showDeactivateUserModalUserId !== null}
        onHide={() => setShowDeactivateUserModalUserId(null)}
        userAccountId={showDeactivateUserModalUserId}
      />

      <InviteUsersModal
        showing={
          modalShowing === "Invite Users to Company" ||
          modalShowing === "Invite Users to Team"
        }
        modalTitle={modalShowing ?? ""}
        hideModal={() => {
          setModalShowing(null);
          setEditingId(undefined);
        }}
        inviteLink={companyInviteLink}
        teamId={editingId}
        teamMembers={editingId ? Object.values(usersInfoById) : []}
        onInviteSuccess={() => setModalShowing(null)}
      />

      <div className="mb-2">
        <NavigateBackButton />
      </div>
      <Card
        style={{
          gap: "24px",
        }}
      >
        {/* <div className="d-flex justify-content-between">
          <h1>Admin Console</h1>
          <Button
            variant="secondary-blue"
            onClick={() => navigate("/OrganizationGuide")}
          >
            View Organization Profile
          </Button>
        </div> */}
        {getNavBar()}
        {getTabContent()}
      </Card>
    </div>
  );
}
