import SortableTable from "app/components/SortableTable";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  selectTeamSurveyInstancesByTeamId,
  updateTeam360DateRange,
} from "../slice";
import { memo } from "react";
import { SURVEY_MAP } from "app/components/LaunchAssessmentModal/constants";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sendReminder } from "app/containers/Dashboard/slice";
import { setTeam360ActionByTeamId } from "app/components/Team360Assessment/slice";
import { useNavigate } from "react-router-dom";
import {
  getDaysRemaining,
  isStartDateBeforeToday,
} from "app/containers/Assessment/helpers";
import { TeamAssessmentInstance } from "app/containers/Assessment/types";

interface Props {
  teamId: number;
}

const SurveysTable = ({ teamId }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const availableSurveyInstances = useAppSelector(
    selectTeamSurveyInstancesByTeamId(teamId)
  );

  const onTableDropdownSelect = (
    e: string | null,
    surveyInstance: TeamAssessmentInstance
  ) => {
    switch (e) {
      case "viewResults": {
        const { startDate, endDate } = surveyInstance;
        dispatch(
          updateTeam360DateRange({
            teamId,
            instance: startDate,
            startDate,
            endDate,
          })
        );
        navigate(`/TeamGuide/${teamId}?tab=TEAM+360`);
        return;
      }
      case "remindMembers": {
        dispatch(
          sendReminder({
            reminderType: "remindUserToTakeAssessment",
            teamId,
          })
        );
        dispatch(setTeam360ActionByTeamId({ teamId, action: "reminded" }));
        break;
      }
      case "editSurveySettings": {
        // TODO: Create modal to edit existing survey settings
        break;
      }
      case "deactivateSurvey": {
        // TODO: Create warning modal to show that the survey will be deactivated
        break;
      }
      default:
    }
  };

  const rows: any[] = [];
  const options = {
    month: "short",
    day: "numeric",
    year: "numeric",
  } as const;
  availableSurveyInstances?.forEach((surveyInstance) => {
    const daysRemaining = getDaysRemaining(surveyInstance.endDate);
    const isActive =
      daysRemaining !== null &&
      daysRemaining > 0 &&
      isStartDateBeforeToday(surveyInstance.startDate);
    rows.push({
      surveyType: SURVEY_MAP[surveyInstance.testId].name,
      launchDate: {
        displayValue: new Date(surveyInstance.startDate).toLocaleDateString(
          "en-US",
          options
        ),
        sortValue: new Date(surveyInstance.startDate),
      },
      endDate: {
        displayValue: new Date(surveyInstance.endDate).toLocaleDateString(
          "en-US",
          options
        ),
        sortValue: new Date(surveyInstance.endDate),
      },
      numberOfCompleted: {
        displayValue: (
          <div>
            <div className={`label-tag ${isActive ? "green" : "grey"}`}>
              {isActive ? "Active" : "Inactive"}
            </div>
          </div>
        ),
        sortValue: isActive,
      },
      dropdown: {
        displayValue: (
          <Dropdown onSelect={(e) => onTableDropdownSelect(e, surveyInstance)}>
            <Dropdown.Toggle
              variant="outline-primary"
              id="dropdown-basic"
              className="no-caret"
            >
              <FontAwesomeIcon icon="ellipsis" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {isActive ? (
                <>
                  <Dropdown.Item eventKey="viewResults">
                    View Results
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item eventKey="remindMembers">
                    Remind members
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  {/* Commented Out until I get the logic working */}
                  {/* <Dropdown.Item eventKey="editSurveySettings">
                    Edit survey settings
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item eventKey="deactivateSurvey" className="danger">
                    Deactivate survey
                  </Dropdown.Item> */}
                </>
              ) : (
                <Dropdown.Item eventKey="viewResults">
                  View Results
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        ),
        sortValue: surveyInstance?.active ? 1 : 0,
      },
    });
  });

  return (
    <SortableTable
      columnHeaders={[
        {
          key: "surveyType",
          label: "Survey Type",
        },
        {
          key: "numberOfCompleted",
          label: "# Responses Completed",
        },
        {
          key: "launchDate",
          label: "Launch Date",
        },
        {
          key: "endDate",
          label: "End Date",
        },
        {
          key: "dropdown",
          label: "",
        },
      ]}
      rows={rows}
    />
  );
};
export default memo(SurveysTable);
