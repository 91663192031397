import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AvatarCircle from "app/components/AvatarCircle";
import { UserInfo } from "app/containers/Global/types";
import { components } from "react-select";

export const getDropdownIndicator = (
  type: "search" | "caret-down" = "search"
) => {
  const icon = type === "search" ? "search" : "caret-down";
  return (props: any) => (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={icon} />
    </components.DropdownIndicator>
  );
};

export const getUserOptionValue = (user?: UserInfo) => {
  if (!user) return null;
  const fullName =
    user?.lastName || user?.firstName
      ? `${user?.firstName} ${user?.lastName}`
      : user?.emailAddress;
  // if (fullName.trim() === "") return; // If the user has no name, don't add them to the list
  return {
    label: fullName,
    value: String(user.userAccountId),
    avatarCircle: (
      <AvatarCircle
        name={fullName}
        userAccountId={user.userAccountId}
        size="small"
      />
    ),
    emailAddress: user.emailAddress,
  };
};
