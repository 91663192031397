import SimpleModal from "./SimpleModal";
import { selectDepartments } from "app/containers/AdminConsole/slice";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import InviteTeamLeader from "app/containers/Dashboard/DashboardOnboarding/InviteTeamLeader";
import {
  closeInviteTeamLeaderModal,
  selectInviteTeamLeaderModalDepartmentId,
  selectInviteTeamLeaderModalIsOpen,
  selectInviteTeamLeaderModalTeamId,
} from "./slice";

export default function InviteTeamLeaderModal() {
  const dispatch = useAppDispatch();
  const departments = useAppSelector(selectDepartments);
  const show = useAppSelector(selectInviteTeamLeaderModalIsOpen);
  const teamId = useAppSelector(selectInviteTeamLeaderModalTeamId);
  const selectedDepartmentId = useAppSelector(
    selectInviteTeamLeaderModalDepartmentId
  );

  const getTitle = () => {
    const department =
      selectedDepartmentId && departments[selectedDepartmentId];
    // If no department or the department is not found then we need to show a dropdown to show all of the departments and allow the user to select one
    if (!department) {
      return "Invite Team Leaders";
    }

    const { name } = department;
    const parsedName = name?.toLocaleLowerCase().includes("department")
      ? name
      : `${name} Department`;

    return `Invite Team Leaders to the ${parsedName} Department`;
  };

  const onHide = () => {
    dispatch(closeInviteTeamLeaderModal());
  };

  return (
    <SimpleModal show={show} onHide={onHide} title={getTitle()}>
      <InviteTeamLeader
        departmentId={selectedDepartmentId}
        onSuccessfulInvite={() => {
          onHide();
        }}
        teamId={teamId}
      />
    </SimpleModal>
  );
}
