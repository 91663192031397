import {
  findIconDefinition,
  IconName,
} from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "react-bootstrap";
import moduleIconPlaceHolder from "resources/images/module-library-card-placeholder-icon.svg";

interface Props {
  title: string;
  moduleType?: string;
  moduleTemplateId: string | number;
  onAddModule?: (moduleTemplateId: string | number, moduleType: string) => void;
  sectionId?: number;
  className?: string;
  shortDescription: string;
  moduleAdded?: boolean;
  iconName?: string;
}

// This card will be only for module Library might want to move ModuleLibrary into its own folder/component.
export default function ModuleCard({
  title,
  moduleType,
  onAddModule,
  moduleTemplateId,
  className,
  shortDescription,
  moduleAdded,
  iconName,
}: Props) {
  const getAppendedClassName = () => {
    let cardClassName = "";
    if (moduleAdded) {
      cardClassName += " added";
    }
    if (className) {
      cardClassName += ` ${className}`;
    }
    return cardClassName;
  };

  const iconDefinition = iconName
    ? findIconDefinition({
        iconName: iconName as IconName,
        prefix: "fal",
      })
    : null;

  const icon = iconDefinition ? (
    <FontAwesomeIcon icon={iconDefinition} />
  ) : (
    <img src={moduleIconPlaceHolder} alt={moduleType} />
  );

  return (
    <Card
      className={`module-library-card${getAppendedClassName()}`}
      role={moduleAdded ? "" : "button"}
      onClick={() => {
        if (moduleAdded) {
          return;
        }

        if (moduleType) {
          onAddModule?.(moduleTemplateId, moduleType);
        }
      }}
    >
      <div className="module-library-card-icon">{icon}</div>
      <div className="module-library-card-text">
        <h4>{title}</h4>
        <p className="module-library-card-description">{shortDescription}</p>
        <div className="module-library-card-footer">
          <div>
            <span
              className={`module-type ${moduleType?.replace(/\s+/g, "")} p-1`}
            >
              {moduleAdded ? "ADDED" : moduleType}
            </span>
          </div>
        </div>
      </div>
    </Card>
  );
}
