import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "utils/redux/store";
import { responseStatus } from "utils/types";
import { request } from "utils/request";
import { getDepartmentLeadId } from "app/containers/AdminConsole/helpers";
import { toast } from "react-toastify";
import { successNotificationOptions } from "utils/constants";
import { REACT_APP_API_URL } from "utils/environmentVariables";
import { GenderInsightsResponse } from "app/containers/DepartmentInsightReport/types";
import { getDepartmentInsightReport } from "app/containers/DepartmentInsightReport/slice";
import { getDepartmentResultsInfo } from "app/containers/Dashboard/slice";
import {
  CompanySettings,
  Department,
  DepartmentType,
  ScheduleSurveyPayload,
  AnalyticsText,
  TextInsightResponse,
  TextSynthesisResponse,
  WordCloudResponse,
  TeamAndDepartmentLeadsResponse,
  CompanySettingPayload,
  TeamComments,
  AssessmentInformation,
} from "./types";
import {
  getTeamAnalyticsText,
  getTeamDisplayStatusByTeamId,
} from "app/containers/TeamGuide/slice";
import {
  StartAndEndDateOrInstance,
  OpenAIUrlParams,
} from "app/components/SurveyDataInstances/types";
import { onSuccessfulDepartmentCreation } from "app/components/Modals/slice";
import {
  getAppendedUrl,
  getAppendedOpenAIUrl,
} from "app/components/InstancePicker/helpers";
import { TIME_INTERVAL_OPTIONS } from "app/components/InstancePicker/constants";
import { getTeam360Scores } from "app/components/SurveyDataInstances/slice";
import { InstanceShowing } from "app/components/InstancePicker/types";
import {
  PutAssessmentPayload,
  TeamAssessmentInstance,
} from "app/containers/Assessment/types";

// ------------------ State Type/Structure ------------------
export interface AdminConsoleState {
  companySettings: null | CompanySettings;
  departments: { [departmentId: number]: Department };
  departmentTypes: {
    [departmentTypeId: number]: DepartmentType;
  };
  analyticsText: AnalyticsText | null;
  feedbackResponsesCount: number | null;
  textInsights: TextInsightResponse | null;
  textInsightsErrorMessage: string | null;
  textSynthesis: TextSynthesisResponse | null;
  genderInsights: GenderInsightsResponse | null;
  wordCloud: WordCloudResponse | null;
  teamDepartmentLeads: null | TeamAndDepartmentLeadsResponse;
  companyInviteLink: string;
  organizationComments: TeamComments | null;
  team360InstancePicked: InstanceShowing;
  getTeamAndDepartmentLeadsCalled: boolean;
  organizationTeamAssessmentInstances: TeamAssessmentInstance[];
  assessmentInformation: AssessmentInformation | null;
  team360CardsExpanded: {
    [cardTitle: string]: boolean;
  };
  getTeamAssessmentInstancesStatus: responseStatus;
  gettingAssessmentInformationStatus: responseStatus;
  updatingTeam360DateRangeStatus: responseStatus;
  getGenderInsightsStatus: responseStatus;
  getOrganizationCommentsStatus: responseStatus;
  getTeamAndDepartmentLeadsStatus: responseStatus;
  scheduleTEAM360Status: responseStatus;
  getTextSynthesisStatus: responseStatus;
  getTextInsightsStatus: responseStatus;
  getWordCloudStatus: responseStatus;
  getAnalyticsTextStatus: responseStatus;
  getCompanySettingsStatus: responseStatus;
  updateCompanySettingsStatus: responseStatus;
  getDepartmentsStatus: responseStatus;
  createDepartmentStatus: responseStatus;
  createdDepartmentId: number | null;
  createCompanyInviteLinkStatus: responseStatus;
  deleteDepartmentStatus: responseStatus;
  getDepartmentTypesStatus: responseStatus;
  updateDepartmentStatus: responseStatus;
  getFeedbackResponsesCountStatus: responseStatus;
}

// ------------------ InitialState ------------------
const initialState: AdminConsoleState = {
  companySettings: null,
  departments: {},
  departmentTypes: {},
  analyticsText: null,
  textInsights: null,
  feedbackResponsesCount: null,
  textInsightsErrorMessage: null,
  genderInsights: null,
  textSynthesis: null,
  wordCloud: null,
  teamDepartmentLeads: null,
  companyInviteLink: "",
  getTeamAndDepartmentLeadsCalled: false,
  organizationComments: null,
  assessmentInformation: null,
  team360InstancePicked: {},
  organizationTeamAssessmentInstances: [],
  team360CardsExpanded: {},
  createdDepartmentId: null,
  gettingAssessmentInformationStatus: "idle",
  getGenderInsightsStatus: "idle",
  getTeamAssessmentInstancesStatus: "idle",
  updatingTeam360DateRangeStatus: "idle",
  getOrganizationCommentsStatus: "idle",
  getTeamAndDepartmentLeadsStatus: "idle",
  scheduleTEAM360Status: "idle",
  getTextSynthesisStatus: "idle",
  getTextInsightsStatus: "idle",
  getWordCloudStatus: "idle",
  getAnalyticsTextStatus: "idle",
  getCompanySettingsStatus: "idle",
  updateCompanySettingsStatus: "idle",
  getDepartmentsStatus: "idle",
  createDepartmentStatus: "idle",
  createCompanyInviteLinkStatus: "idle",
  deleteDepartmentStatus: "idle",
  getDepartmentTypesStatus: "idle",
  updateDepartmentStatus: "idle",
  getFeedbackResponsesCountStatus: "idle",
};

// ------------------ Helper Thunks ------------------
export const updateTeam360DateRange = createAsyncThunk(
  "adminConsole/updateTeam360DateRange",
  async (
    {
      startDate,
      endDate,
      instance,
      instanceType,
      throwErrorIfNoOverview,
    }: {
      instanceType?: (typeof TIME_INTERVAL_OPTIONS)[number];
      throwErrorIfNoOverview?: boolean;
    } & StartAndEndDateOrInstance,
    thunkAPI
  ) => {
    const {
      global: { companyInfo },
    } = thunkAPI.getState() as RootState;

    if (!companyInfo?.companyAccountId) {
      return { startDate, endDate, instanceType, instance };
    }
    const { companyAccountId } = companyInfo;
    const dateRange = { startDate, endDate, instance };

    const resp = (await thunkAPI.dispatch(
      getAnalyticsText({ ...dateRange, throwErrorIfNoOverview })
    )) as { error?: any };

    if (resp?.error) {
      throw new Error("No overview found");
    }

    await Promise.all([
      thunkAPI.dispatch(getGenderInsights({ ...dateRange, companyAccountId })),
      thunkAPI.dispatch(getTextInsights({ ...dateRange })),
      thunkAPI.dispatch(getOrganizationComments({ ...dateRange })),
      thunkAPI.dispatch(getTeam360Scores({ ...dateRange })),
    ]);
    //
    return { startDate, endDate, instanceType, instance };
  }
);

// ------------------------------------ GET Requests ------------------------------------
export const getCompanySettings = createAsyncThunk(
  "adminConsole/getCompanySettings",
  async (companyAccountId: number) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/companies/${companyAccountId}/settings`;
    const { settings } = (await request(requestUrl)) as {
      settings: CompanySettings;
    };
    return settings;
  }
);

export const getDepartments = createAsyncThunk(
  "adminConsole/getDepartments",
  async (companyAccountId: number) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/companies/${companyAccountId}/departments`;
    const getDepartmentsResponse = (await request(requestUrl)) as {
      departments: { [departmentId: number]: Department };
    };
    return getDepartmentsResponse.departments;
  },
  {
    condition: (_, { getState }) => {
      const {
        adminConsole: { getDepartmentsStatus },
      } = getState() as RootState;
      if (getDepartmentsStatus !== "idle") {
        return false;
      }
    },
  }
);

export const getDepartmentTypes = createAsyncThunk(
  "adminConsole/getDepartmentTypes",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/departmentTypes`;
    const getDepartmentTypesResponse = (await request(requestUrl)) as {
      departmentTypes: DepartmentType[];
    };
    return getDepartmentTypesResponse.departmentTypes;
  }
);

export const getAssessments = createAsyncThunk(
  "adminConsole/getAssessments",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/assessments`;
    const getAssessmentsResponse = await request(requestUrl);
    return getAssessmentsResponse;
  }
);

export const getAnalyticsText = createAsyncThunk(
  "adminConsole/getAnalyticsText",
  async (
    payload:
      | undefined
      | (StartAndEndDateOrInstance & {
          throwErrorIfNoOverview?: boolean;
        })
  ) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/getOrganizationInsightReport${getAppendedUrl(
      {
        ...payload,
      }
    )}`;

    const getAnalyticsTextResponse = (await request(
      requestUrl
    )) as AnalyticsText;

    if (
      payload?.throwErrorIfNoOverview &&
      !getAnalyticsTextResponse?.overview &&
      !getAnalyticsTextResponse?.completionInfo?.totalInvited
    ) {
      throw new Error("No overview found");
    }

    return getAnalyticsTextResponse;
  },
  {
    // This condition might cause problems incase the user wants to see analyticsText a different time interval
    // because if we are fetching for a different time interval, we will not fetch the analyticsText
    condition: (_, { getState }) => {
      const {
        adminConsole: { getAnalyticsTextStatus },
      } = getState() as RootState;
      if (getAnalyticsTextStatus === "loading") {
        return false;
      }
    },
  }
);

export const getGenderInsights = createAsyncThunk(
  "adminConsole/getGenderInsights",
  async ({
    companyAccountId,
    startDate,
    endDate,
    instance,
  }: { companyAccountId: Number } & StartAndEndDateOrInstance) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/genderAnalysis/organization/${companyAccountId}${getAppendedUrl(
      {
        startDate,
        endDate,
        instance,
      }
    )}`;
    const getGenderInsightsResponse = (await request(
      requestUrl
    )) as GenderInsightsResponse;
    return getGenderInsightsResponse;
  }
);

export const getTextSynthesis = createAsyncThunk(
  "adminConsole/getTextSynthesis",
  async (payload: undefined | OpenAIUrlParams) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/generateSynthesis${getAppendedOpenAIUrl(
      {
        ...payload,
      }
    )}`;
    const getTextSynthesisResponse = (await request(
      requestUrl
    )) as TextSynthesisResponse;
    return getTextSynthesisResponse;
  }
);

export const getWordCloud = createAsyncThunk(
  "adminConsole/getWordCloud",
  async (payload: undefined | StartAndEndDateOrInstance) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/getWordCloud${getAppendedUrl(
      {
        ...payload,
      }
    )}`;
    const getWordCloudResponse = (await request(
      requestUrl
    )) as WordCloudResponse;
    return getWordCloudResponse;
  },
  {
    condition: (_, { getState }) => {
      const {
        adminConsole: { getWordCloudStatus },
      } = getState() as RootState;
      if (getWordCloudStatus === "loading") {
        return false;
      }
    },
  }
);

export const getTextInsights = createAsyncThunk(
  "adminConsole/getTextInsights",
  async (payload: undefined | OpenAIUrlParams) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/TEAMScoreSummary${getAppendedOpenAIUrl(
      {
        ...payload,
      }
    )}`;
    const getTextInsightsResponse = (await request(
      requestUrl,
      { method: "GET" },
      true
    )) as TextInsightResponse & { status?: number };

    const textInsightsError = getTextInsightsError(getTextInsightsResponse);
    if (textInsightsError) {
      throw new Error(textInsightsError);
    }
    return getTextInsightsResponse;
  }
);

export const getFeedbackResponsesCount = createAsyncThunk(
  "adminConsole/getFeedbackResponsesCount",
  async (payload: undefined | OpenAIUrlParams) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/feedbackResponsesCount${getAppendedOpenAIUrl(
      {
        ...payload,
      }
    )}`;
    const getFeedbackResponsesCountResponse = (await request(requestUrl)) as {
      numberOfFeedbackResponses: number;
    };
    return getFeedbackResponsesCountResponse.numberOfFeedbackResponses;
  }
);

export const getOrganizationComments = createAsyncThunk(
  "adminConsole/getOrganizationComments",
  async (payload: undefined | StartAndEndDateOrInstance) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/comments${getAppendedUrl(
      {
        ...payload,
      }
    )}`;
    const getOrganizationCommentsResponse = (await request(
      requestUrl
    )) as TeamComments;
    return getOrganizationCommentsResponse;
  }
);

export const getTeamAndDepartmentLeads = createAsyncThunk(
  "adminConsole/getTeamAndDepartmentLeads",
  async (_, { rejectWithValue, getState }) => {
    const {
      adminConsole: { getTeamAndDepartmentLeadsCalled },
    } = getState() as RootState;

    if (getTeamAndDepartmentLeadsCalled) {
      return rejectWithValue("Already fetched");
    }
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teamAndDepartmentLeads`;
    const getTeamAndDepartmentLeadsResponse = (await request(
      requestUrl
    )) as TeamAndDepartmentLeadsResponse;
    return getTeamAndDepartmentLeadsResponse;
  }
);

export const reInviteUser = createAsyncThunk(
  "adminConsole/reInviteUser",
  async (userAccountId: number) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/users/${userAccountId}/invite`;
    const reInviteUserResponse = await request(requestUrl);
    return reInviteUserResponse;
  }
);

export const getTeamAssessmentInstances = createAsyncThunk(
  "adminConsole/getTeamAssessmentInstances",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teamAssessmentsInstances`;
    const response = (await request(requestUrl)) as TeamAssessmentInstance[];
    return response;
  },
  {
    condition: (_, { getState }) => {
      const {
        adminConsole: { organizationTeamAssessmentInstances },
      } = getState() as RootState;
      if (organizationTeamAssessmentInstances.length) {
        return false;
      }
    },
  }
);

export const getAssessmentInformation = createAsyncThunk(
  "adminConsole/getAssessmentInformation",
  async () => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/assessmentInformation`;
    const response = (await request(requestUrl)) as AssessmentInformation;
    return response;
  },
  {
    condition: (_, { getState }) => {
      const {
        adminConsole: {
          assessmentInformation,
          gettingAssessmentInformationStatus,
        },
      } = getState() as RootState;
      if (
        assessmentInformation ||
        gettingAssessmentInformationStatus === "loading"
      ) {
        return false;
      }
    },
  }
);

// ------------------------------------ POST Requests ------------------------------------

export const scheduleTEAM360 = createAsyncThunk(
  "adminConsole/scheduleTEAM360",
  async (payload: ScheduleSurveyPayload, { dispatch }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/scheduleTEAM360`;
    const scheduleTEAM360Response = await request(requestUrl, {
      method: "POST",
      body: JSON.stringify(payload),
    });

    // If a departmentId is included then we should fire the getDepartmentInsightReport action again to get the updated data
    if (payload.departmentIds?.length) {
      const departmentId = payload.departmentIds[0];
      dispatch(getDepartmentInsightReport({ departmentId }));
      dispatch(getDepartmentResultsInfo(departmentId));
    }

    if (payload.teamIds?.length) {
      const teamId = payload.teamIds[0];
      dispatch(getTeamDisplayStatusByTeamId({ teamId }));
      dispatch(getTeamAnalyticsText({ teamId }));
    }

    return scheduleTEAM360Response;
  }
);

export const createDepartment = createAsyncThunk(
  "adminConsole/createDepartment",
  async (
    payload: Partial<
      Department & {
        departmentLeaderEmail?: string;
      }
    >,
    { dispatch }
  ) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/departments`;
    const { departmentId } = (await request(requestUrl, {
      method: "POST",
      body: JSON.stringify(payload),
    })) as { departmentId: number };
    dispatch(onSuccessfulDepartmentCreation(departmentId));
    return { ...payload, departmentId };
  }
);

export const createCompanyInviteLink = createAsyncThunk(
  "adminConsole/createCompanyInviteLink",
  async ({
    payload,
    companyAccountId,
  }: {
    payload: { expiration: number };
    companyAccountId: number;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/companies/${companyAccountId}/invitationLink`;
    const { link } = (await request(requestUrl, {
      method: "POST",
      body: JSON.stringify(payload),
    })) as { link: string };
    return link;
  }
);

// ------------------------------------ PUT Requests ------------------------------------
export const updateCompanySettings = createAsyncThunk(
  "adminConsole/updateCompanySettings",
  async ({
    payload,
    companyAccountId,
  }: {
    payload: CompanySettingPayload;
    companyAccountId: number;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/companies/${companyAccountId}/settings`;
    const response = (await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify(payload),
    })) as { settings: CompanySettings };
    const settings = response?.settings;
    return settings;
  }
);

export const updateDepartment = createAsyncThunk(
  "adminConsole/updateDepartment",
  async (
    payload: Partial<
      Department & {
        departmentLeaderEmail?: string;
      }
    >,
    { getState, dispatch }
  ) => {
    if (!payload?.departmentId) {
      throw new Error("Department ID is required to update a department");
    }

    const {
      adminConsole: { departments },
    } = getState() as RootState;
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/departments/${payload.departmentId}`;
    const updateDepartmentResponse = (await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify(payload),
    })) as Department;

    // If the old depatment information had a department leader then we need to dispatch the action to remove the departmentId from the user's departmentIds array
    const oldDepartmentLeadId = getDepartmentLeadId(
      payload.departmentId,
      departments
    );

    if (oldDepartmentLeadId) {
      dispatch(
        removeUserLeader({
          type: "departments",
          id: payload.departmentId,
          userAccountId: oldDepartmentLeadId,
        })
      );
    }

    // If the new department information has a department leader then we need to dispatch the action to add the departmentId to the user's departmentIds array
    if (typeof payload?.leader === "number") {
      dispatch(
        addUserLeader({
          type: "departments",
          id: payload.departmentId,
          userAccountId: payload.leader,
        })
      );
    }
    dispatch(onSuccessfulDepartmentCreation(payload.departmentId));

    return updateDepartmentResponse;
  }
);

export const reassignTeamLead = createAsyncThunk(
  "adminConsole/reassignTeamLead",
  async ({
    newTeamLead,
    currentTeamLead,
    teamId,
  }: {
    newTeamLead: number;
    currentTeamLead: number;
    teamId: number;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teams/${teamId}/teamLead`;
    const reassignTeamLeadResponse = await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify({
        newTeamLead,
        currentTeamLead,
      }),
    });
    return reassignTeamLeadResponse;
  }
);

export const updateTeam360 = createAsyncThunk(
  "adminConsole/updateTeam360",
  async (
    { teamId, endDate }: { teamId: number; endDate?: string },
    { dispatch }
  ) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/teams/${teamId}/updateTeam360`;
    const response = await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify({ endDate }),
    });
    dispatch(getTeamAnalyticsText({ teamId }));
    return response;
  }
);

export const updateAssessmentSeries = createAsyncThunk(
  "adminConsole/updateAssessmentSeries",
  async ({
    payload,
    assessmentId,
  }: {
    payload: PutAssessmentPayload & { stopRecurringDate?: string };
    assessmentId: number;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/assessments/${assessmentId}`;
    const response = await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify({ ...payload }),
    });
    return response;
  }
);

export const updateAssessmentInstance = createAsyncThunk(
  "adminConsole/updateAssessmentInstance",
  async ({
    payload,
    assessmentId,
  }: {
    payload: PutAssessmentPayload;
    assessmentId: number;
  }) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/assessmentInstances/${assessmentId}`;
    const response = await request(requestUrl, {
      method: "PUT",
      body: JSON.stringify({ ...payload }),
    });
    return response;
  }
);

// ------------------------------------ DELETE Requests ------------------------------------
export const deleteDepartmentById = createAsyncThunk(
  "adminConsole/deleteDepartments",
  async (departmentId: number) => {
    const requestUrl = `${REACT_APP_API_URL}/talentInsights/departments/${departmentId}`;
    await request(requestUrl, {
      method: "DELETE",
    });
    return departmentId;
  }
);

function getTextInsightsError(
  response: TextInsightResponse & { status?: number }
) {
  if (response?.status === 402) {
    return "Your account doesn't have any remaining OpenAI credits, please contact your account manager if you'd like to add more.";
  } else if (response?.status === 500) {
    return "Unable to generate a response, please try again when more responses have been received.";
  }
  return null;
}

// ------------------ Beginning of Slice Definition ------------------
export const adminConsoleSlice = createSlice({
  name: "adminConsole",
  initialState,
  reducers: {
    clearScheduleTEAM360Status: (state) => {
      state.scheduleTEAM360Status = "idle";
    },
    removeUserLeader: (
      state,
      {
        payload: { type, id, userAccountId },
      }: PayloadAction<{
        type: "teams" | "departments";
        id: number;
        userAccountId: number;
      }>
    ) => {
      // If the state has not yet been initialized, return
      if (!state.teamDepartmentLeads) return;

      // If the id is not included then we add it to our state. otherwise do not add it
      const idArray = state.teamDepartmentLeads.users[userAccountId]?.[type];
      if (idArray.includes(id)) {
        return {
          ...state,
          teamDepartmentLeads: {
            ...state.teamDepartmentLeads,
            users: {
              ...state.teamDepartmentLeads.users,
              [userAccountId]: {
                ...state.teamDepartmentLeads.users[userAccountId],
                [type]: idArray.filter((idx) => idx !== id),
              },
            },
          },
        };
      }
    },
    addUserLeader: (
      state,
      {
        payload: { type, id, userAccountId },
      }: PayloadAction<{
        type: "teams" | "departments";
        id: number;
        userAccountId: number;
      }>
    ) => {
      // If the state has not yet been initialized, return
      if (!state.teamDepartmentLeads) return;

      // If the user does not exist in the state, initialize it and add the id to the state.
      if (!state.teamDepartmentLeads.users[userAccountId]) {
        return {
          ...state,
          teamDepartmentLeads: {
            ...state.teamDepartmentLeads,
            users: {
              ...state.teamDepartmentLeads.users,
              [userAccountId]: {
                ...state.teamDepartmentLeads.users[userAccountId],
                [type]: [id],
                [type === "teams" ? "departments" : "teams"]: [],
              },
            },
          },
        };
      }

      const idArray = state.teamDepartmentLeads.users[userAccountId]?.[type];
      // If the id already is part of the users teams or departments, do not add it
      if (idArray.includes(id)) {
        return state;
      } else {
        return {
          ...state,
          teamDepartmentLeads: {
            ...state.teamDepartmentLeads,
            users: {
              ...state.teamDepartmentLeads.users,
              [userAccountId]: {
                ...state.teamDepartmentLeads.users[userAccountId],
                [type]: [...idArray, id],
              },
            },
          },
        };
      }
    },
    resetUpdateTeam360DateRangeStatus(state) {
      state.updatingTeam360DateRangeStatus = "idle";
    },
    setTeam360CardsExpanded: (
      state,
      {
        payload: { value, cardTitle },
      }: PayloadAction<{ value: boolean; cardTitle: string }>
    ) => {
      return {
        ...state,
        team360CardsExpanded: {
          ...state.team360CardsExpanded,
          [cardTitle]: value,
        },
      };
    },
    clearTeam360ExpandedCards: (state) => {
      return {
        ...state,
        team360CardsExpanded: {},
      };
    },
    resetTextSynthesis(state) {
      state.textSynthesis = null;
    },
    resetTextInsights(state) {
      state.textInsights = null;
    },
    clearCreatedDepartmentId(state) {
      state.createdDepartmentId = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanySettings.pending, (state) => {
        state.getCompanySettingsStatus = "loading";
      })
      .addCase(
        getCompanySettings.fulfilled,
        (state, action: PayloadAction<CompanySettings>) => {
          return {
            ...state,
            getCompanySettingsStatus: "succeeded",
            companySettings: action.payload,
          };
        }
      )
      .addCase(getCompanySettings.rejected, (state) => {
        state.getCompanySettingsStatus = "failed";
      })
      .addCase(updateCompanySettings.pending, (state) => {
        state.updateCompanySettingsStatus = "loading";
      })
      .addCase(
        updateCompanySettings.fulfilled,
        (state, { payload }: PayloadAction<CompanySettings>) => {
          toast.success("Updating Company Settings successfully", {
            ...successNotificationOptions,
          });
          return {
            ...state,
            updateCompanySettingsStatus: "succeeded",
            companySettings: payload,
          };
        }
      )
      .addCase(updateCompanySettings.rejected, (state, payload) => {
        state.updateCompanySettingsStatus = "failed";
      })
      .addCase(getDepartmentTypes.pending, (state) => {
        state.getDepartmentTypesStatus = "loading";
      })
      .addCase(
        getDepartmentTypes.fulfilled,
        (state, action: PayloadAction<DepartmentType[]>) => {
          const departmentTypes: {
            [departmentTypeId: number]: DepartmentType;
          } = {};

          action.payload.forEach((departmentType) => {
            departmentTypes[departmentType.departmentTypeId] = departmentType;
          });

          return {
            ...state,
            getDepartmentTypesStatus: "succeeded",
            departmentTypes,
          };
        }
      )
      .addCase(getDepartmentTypes.rejected, (state) => {
        state.getDepartmentTypesStatus = "failed";
      })
      .addCase(getDepartments.pending, (state) => {
        state.getDepartmentsStatus = "loading";
      })
      .addCase(
        getDepartments.fulfilled,
        (
          state,
          { payload }: PayloadAction<{ [departmentId: number]: Department }>
        ) => {
          return {
            ...state,
            getDepartmentsStatus: "succeeded",
            departments: { ...payload },
          };
        }
      )
      .addCase(getDepartments.rejected, (state) => {
        state.getDepartmentsStatus = "failed";
      })
      .addCase(createDepartment.pending, (state) => {
        state.createDepartmentStatus = "loading";
      })
      .addCase(
        createDepartment.fulfilled,
        (state, { payload }: PayloadAction<Department>) => {
          toast.success("Created Department successfully", {
            ...successNotificationOptions,
          });
          return {
            ...state,
            createDepartmentStatus: "succeeded",
            departments: {
              ...state.departments,
              [payload.departmentId]: payload,
            },
            createdDepartmentId: payload.departmentId,
          };
        }
      )
      .addCase(createDepartment.rejected, (state) => {
        state.createDepartmentStatus = "failed";
      })
      .addCase(createCompanyInviteLink.pending, (state) => {
        state.createCompanyInviteLinkStatus = "loading";
      })
      .addCase(createCompanyInviteLink.fulfilled, (state, action) => {
        return {
          ...state,
          createCompanyInviteLinkStatus: "succeeded",
          companyInviteLink: action.payload,
        };
      })
      .addCase(createCompanyInviteLink.rejected, (state) => {
        state.createCompanyInviteLinkStatus = "failed";
      })
      .addCase(updateDepartment.pending, (state) => {
        state.updateDepartmentStatus = "loading";
      })
      .addCase(
        updateDepartment.fulfilled,
        (state, { payload }: PayloadAction<Department>) => {
          toast.success("Department updated successfully", {
            ...successNotificationOptions,
          });
          return {
            ...state,
            updateDepartmentStatus: "succeeded",
            departments: {
              ...state.departments,
              [payload.departmentId]: {
                ...state.departments[payload.departmentId],
                ...payload,
              },
            },
          };
        }
      )
      .addCase(updateDepartment.rejected, (state) => {
        state.updateDepartmentStatus = "failed";
      })
      .addCase(deleteDepartmentById.pending, (state) => {
        state.deleteDepartmentStatus = "loading";
      })
      .addCase(
        deleteDepartmentById.fulfilled,
        (state, { payload }: PayloadAction<number>) => {
          toast.success("Department deleted successfully", {
            ...successNotificationOptions,
          });
          const newDepartments = { ...state.departments };
          delete newDepartments[payload];
          return {
            ...state,
            deleteDepartmentStatus: "succeeded",
            departments: newDepartments,
          };
        }
      )
      .addCase(deleteDepartmentById.rejected, (state) => {
        state.deleteDepartmentStatus = "failed";
      })
      .addCase(getAnalyticsText.pending, (state) => {
        state.getAnalyticsTextStatus = "loading";
      })
      .addCase(
        getAnalyticsText.fulfilled,
        (state, { payload }: PayloadAction<AnalyticsText>) => {
          return {
            ...state,
            getAnalyticsTextStatus: "succeeded",
            analyticsText: payload,
          };
        }
      )
      .addCase(getAnalyticsText.rejected, (state) => {
        state.getAnalyticsTextStatus = "failed";
      })
      .addCase(getTextSynthesis.pending, (state) => {
        state.getTextSynthesisStatus = "loading";
      })
      .addCase(
        getTextSynthesis.fulfilled,
        (state, { payload }: PayloadAction<TextSynthesisResponse>) => {
          return {
            ...state,
            getTextSynthesisStatus: "succeeded",
            textSynthesis: payload,
          };
        }
      )
      .addCase(getTextSynthesis.rejected, (state) => {
        state.getTextSynthesisStatus = "failed";
      })
      .addCase(getWordCloud.pending, (state) => {
        state.getWordCloudStatus = "loading";
      })
      .addCase(
        getWordCloud.fulfilled,
        (state, { payload }: PayloadAction<WordCloudResponse>) => {
          return {
            ...state,
            getWordCloudStatus: "succeeded",
            wordCloud: payload,
          };
        }
      )
      .addCase(getWordCloud.rejected, (state) => {
        state.getWordCloudStatus = "failed";
      })
      .addCase(getTextInsights.pending, (state) => {
        state.getTextInsightsStatus = "loading";
      })
      .addCase(
        getTextInsights.fulfilled,
        (state, { payload }: PayloadAction<TextInsightResponse>) => {
          return {
            ...state,
            getTextInsightsStatus: "succeeded",
            textInsights: payload,
          };
        }
      )
      .addCase(getTextInsights.rejected, (state, action) => {
        state.getTextInsightsStatus = "failed";
        state.textInsightsErrorMessage = action.error.message as string;
      })
      .addCase(getFeedbackResponsesCount.pending, (state) => {
        state.getFeedbackResponsesCountStatus = "loading";
      })
      .addCase(
        getFeedbackResponsesCount.fulfilled,
        (state, { payload }: PayloadAction<number>) => {
          return {
            ...state,
            getFeedbackResponsesCountStatus: "succeeded",
            feedbackResponsesCount: payload,
          };
        }
      )
      .addCase(getFeedbackResponsesCount.rejected, (state) => {
        state.getFeedbackResponsesCountStatus = "failed";
      })
      .addCase(getGenderInsights.pending, (state) => {
        state.getGenderInsightsStatus = "loading";
      })
      .addCase(
        getGenderInsights.fulfilled,
        (state, { payload }: PayloadAction<GenderInsightsResponse>) => {
          return {
            ...state,
            getGenderInsightsStatus: "succeeded",
            genderInsights: payload,
          };
        }
      )
      .addCase(getGenderInsights.rejected, (state) => {
        state.getGenderInsightsStatus = "failed";
      })
      .addCase(scheduleTEAM360.pending, (state) => {
        state.scheduleTEAM360Status = "loading";
      })
      .addCase(scheduleTEAM360.fulfilled, (state) => {
        toast.success("Scheduling TEAMscan is successful!", {
          ...successNotificationOptions,
        });
        state.scheduleTEAM360Status = "succeeded";
      })
      .addCase(scheduleTEAM360.rejected, (state) => {
        state.scheduleTEAM360Status = "failed";
      })
      .addCase(getTeamAndDepartmentLeads.pending, (state) => {
        state.getTeamAndDepartmentLeadsStatus = "loading";
      })
      .addCase(
        getTeamAndDepartmentLeads.fulfilled,
        (state, { payload }: PayloadAction<TeamAndDepartmentLeadsResponse>) => {
          return {
            ...state,
            getTeamAndDepartmentLeadsStatus: "succeeded",
            getTeamAndDepartmentLeadsCalled: true,
            teamDepartmentLeads: payload,
          };
        }
      )
      .addCase(getTeamAndDepartmentLeads.rejected, (state, action) => {
        if (action.payload === "Already fetched") {
          state.getTeamAndDepartmentLeadsStatus = "succeeded";
        } else {
          state.getTeamAndDepartmentLeadsStatus = "failed";
        }
      })
      .addCase(getOrganizationComments.pending, (state) => {
        state.getOrganizationCommentsStatus = "loading";
      })
      .addCase(
        getOrganizationComments.fulfilled,
        (state, { payload }: PayloadAction<TeamComments>) => {
          return {
            ...state,
            getOrganizationCommentsStatus: "succeeded",
            organizationComments: payload,
          };
        }
      )
      .addCase(getOrganizationComments.rejected, (state) => {
        state.getOrganizationCommentsStatus = "failed";
      })
      .addCase(reInviteUser.fulfilled, () => {
        toast.success("Invitation Sent Successfully!", {
          ...successNotificationOptions,
        });
      })
      .addCase(updateTeam360.fulfilled, (state) => {
        toast.success("TEAMscan Updated Successfully!", {
          ...successNotificationOptions,
        });
      })
      .addCase(updateTeam360DateRange.pending, (state) => {
        state.updatingTeam360DateRangeStatus = "loading";
      })
      .addCase(
        updateTeam360DateRange.fulfilled,
        (
          state,
          {
            payload: { startDate, endDate, instanceType, instance },
          }: PayloadAction<
            {
              instanceType?: (typeof TIME_INTERVAL_OPTIONS)[number];
            } & StartAndEndDateOrInstance
          >
        ) => {
          return {
            ...state,
            updatingTeam360DateRangeStatus: "succeeded",
            team360InstancePicked: {
              instanceType: instanceType ?? null,
              startDate,
              endDate,
              instance,
            },
          };
        }
      )
      .addCase(updateTeam360DateRange.rejected, (state) => {
        state.updatingTeam360DateRangeStatus = "failed";
      })
      .addCase(getTeamAssessmentInstances.pending, (state) => {
        state.getTeamAssessmentInstancesStatus = "loading";
      })
      .addCase(
        getTeamAssessmentInstances.fulfilled,
        (state, action: PayloadAction<TeamAssessmentInstance[]>) => {
          state.getTeamAssessmentInstancesStatus = "succeeded";
          state.organizationTeamAssessmentInstances = action.payload;
        }
      )
      .addCase(getTeamAssessmentInstances.rejected, (state) => {
        state.getTeamAssessmentInstancesStatus = "failed";
      })
      .addCase(getAssessmentInformation.pending, (state) => {
        state.gettingAssessmentInformationStatus = "loading";
      })
      .addCase(
        getAssessmentInformation.fulfilled,
        (state, { payload }: PayloadAction<AssessmentInformation>) => {
          return {
            ...state,
            gettingAssessmentInformationStatus: "succeeded",
            assessmentInformation: payload,
          };
        }
      );
  },
});

// ------------------ Selectors ------------------
export const selectCompanySettings = (state: RootState) =>
  state.adminConsole.companySettings;
export const selectGetCompanySettingsStatus = (state: RootState) =>
  state.adminConsole.getCompanySettingsStatus;
export const selectDepartments = (state: RootState) =>
  state.adminConsole.departments;
export const selectGetDepartmentsStatus = (state: RootState) =>
  state.adminConsole.getDepartmentsStatus;
export const selectDepartmentTypes = (state: RootState) =>
  state.adminConsole.departmentTypes;
export const selectCreateDepartmentStatus = (state: RootState) =>
  state.adminConsole.createDepartmentStatus;
export const selectCreatedDepartmentId = (state: RootState) =>
  state.adminConsole.createdDepartmentId;
export const selectCompanyInviteLink = (state: RootState) =>
  state.adminConsole.companyInviteLink;
export const selectCreateCompanyInviteLinkStatus = (state: RootState) =>
  state.adminConsole.createCompanyInviteLinkStatus;
export const selectDeleteDepartmentStatus = (state: RootState) =>
  state.adminConsole.deleteDepartmentStatus;
export const selectUpdateDepartmentStatus = (state: RootState) =>
  state.adminConsole.updateDepartmentStatus;
export const selectGetAnalyticsTextStatus = (state: RootState) =>
  state.adminConsole.getAnalyticsTextStatus;
export const selectAnalyticsText = (state: RootState) =>
  state.adminConsole.analyticsText;
export const selectGetTextSynthesisStatus = (state: RootState) =>
  state.adminConsole.getTextSynthesisStatus;
export const selectTextSynthesis = (state: RootState) =>
  state.adminConsole.textSynthesis;
export const selectGetWordCloudStatus = (state: RootState) =>
  state.adminConsole.getWordCloudStatus;
export const selectWordCloud = (state: RootState) =>
  state.adminConsole.wordCloud;
export const selectGetTextInsightsStatus = (state: RootState) =>
  state.adminConsole.getTextInsightsStatus;
export const selectTextInsights = (state: RootState) =>
  state.adminConsole.textInsights;
export const selectFeedbackResponsesCount = (state: RootState) =>
  state.adminConsole.feedbackResponsesCount;
export const selectGetFeedbackResponsesCountStatus = (state: RootState) =>
  state.adminConsole.getFeedbackResponsesCountStatus;
export const selectScheduleTEAM360Status = (state: RootState) =>
  state.adminConsole.scheduleTEAM360Status;
export const selectTeamAndDepartmentLeads = (state: RootState) =>
  state.adminConsole.teamDepartmentLeads;
export const selectTeamAndDepartmentLeadIdsForLoggedInUser = (
  state: RootState
) => {
  const { teamDepartmentLeads } = state.adminConsole;
  const { currentUserAccountId } = state.global;
  if (!teamDepartmentLeads || !currentUserAccountId) return null; // If no teamDepartmentLeads or no currentUserAccountId, return null
  const { users } = teamDepartmentLeads;
  const teamAndDepartments = users?.[currentUserAccountId];
  if (!teamAndDepartments)
    return {
      teams: [],
      departments: [],
    }; // If no teamAndDepartments, return {}
  return teamAndDepartments;
};
export const selectGetTeamAndDepartmentLeadsStatus = (state: RootState) =>
  state.adminConsole.getTeamAndDepartmentLeadsStatus;
export const selectGetGenderInsightsStatus = (state: RootState) =>
  state.adminConsole.getGenderInsightsStatus;
export const selectGenderInsights = (state: RootState) =>
  state.adminConsole.genderInsights;

export const selectGetOrganizationCommentsStatus = (state: RootState) =>
  state.adminConsole.getOrganizationCommentsStatus;
export const selectOrganizationComments = (state: RootState) =>
  state.adminConsole.organizationComments;
export const selectTeam360InstancePicked = (state: RootState) =>
  state.adminConsole.team360InstancePicked;
export const selectUpdateTeam360DateRangeStatus = (state: RootState) =>
  state.adminConsole.updatingTeam360DateRangeStatus;
export const selectOrganizationTeamAssessmentInstances = (state: RootState) =>
  state.adminConsole.organizationTeamAssessmentInstances;
export const selectGetTeamAssessmentInstancesStatus = (state: RootState) =>
  state.adminConsole.getTeamAssessmentInstancesStatus;
export const selectAssessmentInformation = (state: RootState) =>
  state.adminConsole.assessmentInformation;
export const selectGettingAssessmentInformationStatus = (state: RootState) =>
  state.adminConsole.gettingAssessmentInformationStatus;
export const selectTeam360CardsExpanded = (state: RootState) =>
  state.adminConsole.team360CardsExpanded;
export const selectTextInsightsErrorMessage = (state: RootState) =>
  state.adminConsole.textInsightsErrorMessage;

// ------------------ Actions ------------------
export const {
  clearScheduleTEAM360Status,
  removeUserLeader,
  addUserLeader,
  resetUpdateTeam360DateRangeStatus,
  setTeam360CardsExpanded,
  resetTextInsights,
  resetTextSynthesis,
  clearCreatedDepartmentId,
} = adminConsoleSlice.actions;

export default adminConsoleSlice.reducer;
