import { Card } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import Select from "react-select";
import {
  getUserSettings,
  updateUserSettings,
  selectUserSettings,
  selectGetUserSettingsStatus,
} from "./slice";
import { selectCurrentUserAccountId } from "app/containers/Global/slice";
import Loading from "app/storybookComponents/Loading";
import Button from "app/storybookComponents/Button";
import DeactivateUserModal from "../AdminConsole/Modals/DeactivateUserModal";
import NavigateBackButton from "app/components/NavigateBackButton";
import { oneTwoThree, oneTwoThreeUndefined } from "./types";

export default function UserSettings() {
  const userSettings = useAppSelector(selectUserSettings);
  const [whoCanSeeYourCollaborationGuide, setWhoCanSeeYourCollaborationGuide] =
    useState<oneTwoThreeUndefined>(undefined);
  const [whoCanSeeYourWorkplaceInsights, setWhoCanSeeYourWorkplaceInsights] =
    useState<oneTwoThreeUndefined>(undefined);

  const [whoCanSeeYouOnTeamReports, setWhoCanSeeYouOnTeamReports] =
    useState<oneTwoThreeUndefined>(undefined);

  const [showDeactivateUserModal, setShowDeactivateUserModal] = useState(false);

  const userSettingsStatus = useAppSelector(selectGetUserSettingsStatus);
  const loggedInUserAccountId = useAppSelector(selectCurrentUserAccountId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (loggedInUserAccountId && userSettingsStatus === "idle") {
      dispatch(getUserSettings(loggedInUserAccountId));
    }
  }, [dispatch, loggedInUserAccountId, userSettingsStatus]);

  useEffect(() => {
    const userSettingsAreUndefined =
      !whoCanSeeYourCollaborationGuide ||
      !whoCanSeeYourWorkplaceInsights ||
      !whoCanSeeYouOnTeamReports;
    if (userSettings && userSettingsAreUndefined) {
      setWhoCanSeeYourCollaborationGuide(userSettings.showPersonalitySummary);
      setWhoCanSeeYourWorkplaceInsights(userSettings.showWorkplaceInsights);
      setWhoCanSeeYouOnTeamReports(userSettings.showOnTeamReport);
    }
  }, [
    userSettings,
    whoCanSeeYourCollaborationGuide,
    whoCanSeeYourWorkplaceInsights,
    whoCanSeeYouOnTeamReports,
  ]);

  const getValue = (setting: oneTwoThree) => {
    switch (setting) {
      case 1:
        return { value: "1", label: "No One" };
      case 2:
        return { value: "2", label: "Team Lead" };
      case 3:
      default:
        return { value: "3", label: "Everyone on my teams" };
    }
  };

  const getTeamReportsValue = (setting: oneTwoThree) => {
    if (setting === 1) {
      return { value: "1", label: "Only Me" };
    }
    return getValue(setting);
  };

  const setValue = (
    value: string,
    setting: "collaborationGuide" | "workplaceInsights" | "teamReports"
  ) => {
    if (!loggedInUserAccountId) return null;

    const numValue = parseInt(value) as oneTwoThree;
    const userSettingsPayload = {
      showPersonalitySummary: whoCanSeeYourCollaborationGuide || 3,
      showWorkplaceInsights: whoCanSeeYourWorkplaceInsights || 3,
      showOnTeamReport: whoCanSeeYouOnTeamReports || 3,
    };
    switch (setting) {
      case "collaborationGuide":
        setWhoCanSeeYourCollaborationGuide(numValue);
        userSettingsPayload.showPersonalitySummary = numValue;
        break;
      case "workplaceInsights":
        setWhoCanSeeYourWorkplaceInsights(numValue);
        userSettingsPayload.showWorkplaceInsights = numValue;
        break;
      case "teamReports":
      default:
        setWhoCanSeeYouOnTeamReports(numValue);
        userSettingsPayload.showOnTeamReport = numValue;
        break;
    }
    dispatch(
      updateUserSettings({
        payload: userSettingsPayload,
        userAccountId: loggedInUserAccountId,
      })
    );
  };

  const standardOptions = [
    { value: "1", label: "No One" },
    { value: "2", label: "Team Lead" },
    { value: "3", label: "Everyone on my teams" },
  ];

  const teamReportsOptions = [
    { value: "1", label: "Only Me" },
    { value: "2", label: "Team Lead" },
    { value: "3", label: "Everyone on my teams" },
  ];

  const getPrivacyAndVisibilitySection = () => {
    if (!userSettings) return <Loading />;
    return (
      <>
        <div className="setting-row">
          <div className="setting-row-description">
            <h3>Who can see your Collaboration Guide?</h3>
            <p>Specify which users can see your collaboration guide</p>
          </div>
          <div className="setting-row-action">
            <Select
              closeMenuOnSelect={false}
              options={standardOptions}
              value={getValue(whoCanSeeYourCollaborationGuide || 3)}
              onChange={(value) => {
                if (value) {
                  setValue(value?.value, "collaborationGuide");
                }
              }}
            />
          </div>
        </div>
        <div className="setting-row">
          <div className="setting-row-description">
            <h3>Who can see your Workplace Insights?</h3>
            <p>
              Specify which users can see your personality results taken from
              the EPP
            </p>
          </div>
          <div className="setting-row-action">
            <Select
              closeMenuOnSelect={false}
              options={standardOptions}
              value={getValue(whoCanSeeYourWorkplaceInsights || 3)}
              onChange={(value) => {
                if (value) {
                  setValue(value?.value, "workplaceInsights");
                }
              }}
            />
          </div>
        </div>
        <div className="setting-row">
          <div className="setting-row-description">
            <h3>Who can see you on Team Reports?</h3>
            <p>
              Specify which users can see your personality results on teams
              you're on
            </p>
          </div>
          <div className="setting-row-action">
            <Select
              closeMenuOnSelect={false}
              options={teamReportsOptions}
              value={getTeamReportsValue(whoCanSeeYouOnTeamReports || 3)}
              onChange={(value) => {
                if (value) {
                  setValue(value?.value, "teamReports");
                }
              }}
            />
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <div className="page guide">
        <div className="mb-2">
          <NavigateBackButton />
        </div>
        <DeactivateUserModal
          show={showDeactivateUserModal}
          onHide={() => setShowDeactivateUserModal(false)}
          userAccountId={loggedInUserAccountId}
        />
        <Card style={{ padding: "20px" }} className="column-gap-20px">
          <h1>Account Settings</h1>
          {getPrivacyAndVisibilitySection()}
          <hr className="m-0" />
          <div className="column-gap-10px">
            <h3>Deactivate Account</h3>
            <p>
              Deactivating your account will remove your access to the platform
              and delete your team memberships.
            </p>
            <div>
              <Button
                variant="secondary-danger"
                onClick={() => {
                  setShowDeactivateUserModal(true);
                }}
              >
                Deactivate account
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
}
