import { ReactNode } from "react";
interface Props {
  type?: "red" | "blue" | "green" | "light-red" | "gold";
  children?: ReactNode;
}
export default function index({ type = "red", children }: Props) {
  return (
    <div
      className={`warning-banner ${type}`}
      style={{
        padding: "12px 16px",
      }}
    >
      {children || null}
    </div>
  );
}
