interface Props {
  text: string;
}

export default function AssessmentLink({ text }: Props) {
  const getUrl = () => {
    switch (text) {
      case 'Terms of Use':
        return 'https://www.ondemandassessment.com/home/terms';
      case 'Privacy Policy':
        return 'https://www.ondemandassessment.com/home/privacy';
      case 'Disability/Accommodations':
        return 'https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6079734-disability-accommodations';
      case 'Copyright Notices':
        return 'https://dev.ondemandassessment.com/home/copyright';
      default:
        return '';
    }
  };
  return (
    <>
      {' '}
      <a href={getUrl()} target="_blank" rel="noopener noreferrer">
        {text}
      </a>{' '}
    </>
  );
}
