import { Button, Row, Col } from 'react-bootstrap';
import { useAppDispatch } from 'utils/redux/hooks';
import { hideModal, showAddModuleModal } from '../slice';

export default function WelcomeModal() {
  const dispatch = useAppDispatch();

  return (
    <Col>
      <Row>
        <h1>Welcome to your User Guide!</h1>
        <p>
          Answer some short prompts for your team members to get to you better
        </p>
        <Button onClick={() => dispatch(showAddModuleModal())} className="mb-3">
          Build My User Guide{' '}
        </Button>
        <Button variant="outline-primary" onClick={() => dispatch(hideModal())}>
          Skip
        </Button>
      </Row>
    </Col>
  );
}
