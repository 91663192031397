import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import Button from "app/storybookComponents/Button";
import { ONBOARDING_MODAL_MAP } from "./constants";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  selectShowModal,
  setHideModal,
  onSubmitModalClick,
  selectHideActionButton,
  selectIsOnboardingModalTemporarilyHidden,
  setTemporarilyHideOnboardingModal,
} from "./slice";
import { useNavigate } from "react-router-dom";
import { showScheduleAssessmentModal } from "app/components/LaunchAssessmentModal/slice";

export default function OnboardingModal() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const modalType = useAppSelector(selectShowModal);
  const hideActionButton = useAppSelector(selectHideActionButton);
  const isModalTemporarilyHidden = useAppSelector(
    selectIsOnboardingModalTemporarilyHidden
  );

  const onHide = () => {
    dispatch(setHideModal());
  };

  // Setting initial values. If modalType is null, then we will use the default values.
  const {
    listItems = [],
    bodyHeader = {
      title: "",
      description: "",
    },
    bodyFooter = null,
    dismissButtonText = "Dismiss",
    modalTitle = null,
  } = modalType ? ONBOARDING_MODAL_MAP[modalType] : {};

  const getCustomActionButtons = () => {
    if (hideActionButton) return null;
    switch (modalType) {
      case "generalTeam360Information":
        return (
          <div className="ms-auto row-gap-12px">
            <Button
              variant="secondary-blue"
              onClick={() => {
                dispatch(setTemporarilyHideOnboardingModal(true));
                navigate("/survey/team360?assessmentEventId=sample");
              }}
            >
              Preview survey
            </Button>
            <Button
              onClick={() => {
                onHide();
                dispatch(showScheduleAssessmentModal());
              }}
            >
              Launch survey{" "}
              <FontAwesomeIcon icon="arrow-right" className="ms-2" />
            </Button>
          </div>
        );
      default:
        return (
          <div className="onboarding-modal-footer">
            <Button onClick={() => dispatch(onSubmitModalClick())}>
              {dismissButtonText}
            </Button>
          </div>
        );
    }
  };

  return (
    <Modal
      onHide={onHide}
      show={!!modalType && !isModalTemporarilyHidden}
      size="lg"
      className="simple-modal"
    >
      <div className="d-flex justify-content-end">
        <Button
          variant={"secondary-blue"}
          onClick={onHide}
          style={{ border: "none", width: "auto" }}
          xIcon
        />
      </div>
      <div className="onboarding-modal-title">{modalTitle}</div>
      <div className="onboarding-modal-body">
        <div className="onboarding-modal-body-header">
          {bodyHeader.title ? <h3>{bodyHeader.title}</h3> : null}
          <p>{bodyHeader.description}</p>
        </div>
        <div className="onboarding-modal-body-content">
          <ul>
            {listItems.map(({ title, description }) => (
              <li key={title}>
                <FontAwesomeIcon icon={"circle-check"} />
                <div>
                  <h4 style={{ color: "black" }}>{title}</h4>
                  <p>{description}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="onboarding-modal-body-footer">
          <p>{bodyFooter}</p>
        </div>
      </div>
      {getCustomActionButtons()}
    </Modal>
  );
}
