import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  onClick: () => void;
  size?: 'small' | 'medium' | 'large';
}

export default function CircleEditButton({ onClick, size }: Props) {
  return (
    <div
      className={`edit-circle${size ? ' ' + size : ''}`}
      onClick={onClick}
      role="button"
    >
      <FontAwesomeIcon icon="edit" />
    </div>
  );
}
