import React, { useEffect, useLayoutEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import { Card, Collapse } from "react-bootstrap";
import { getEntries, getSelectProps } from "utils/helperFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSearchParams } from "react-router-dom";
import Button from "app/storybookComponents/Button";
import TeamAverageIllustration from "resources/images/illustration-team-average.png";
import { ALL_TEAM_360_FACTORS } from "app/components/Team360Assessment/constants";
import TeamAssessmentIcon from "app/components/Team360Assessment/TeamAssessmentIcon";
import Select from "react-select";
import { TSkill } from "./types";
import SkillPage from "./SkillPage";
import {
  getSkillGuide,
  selectGetSkillsGuideStatus,
  selectSkillsGuide,
  getDimensionGuide,
  selectAllDimensionsById,
  selectSkillsById,
  selectGetDimensionGuideStatus,
  getFactorGuide,
  selectGetFactorGuideStatus,
  selectFactorsByFactorName,
  selectHasLibraryEditAccess,
  getHasLibraryEditAccess,
} from "./slice";
import Loading from "app/storybookComponents/Loading";
import NavigateBackButton from "app/components/NavigateBackButton";
import { TEAM_360_FACTOR_ID_TO_NAME_MAP } from "./constants";

export default function SkillsGuide() {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  // ------------------------ Selectors ------------------------
  const skillsGuide = useAppSelector(selectSkillsGuide);
  const skillsById = useAppSelector(selectSkillsById);
  const dimensionsByName = useAppSelector(selectAllDimensionsById);
  const getSkillsGuideStatus = useAppSelector(selectGetSkillsGuideStatus);
  const getDimensionGuideStatus = useAppSelector(selectGetDimensionGuideStatus);
  const getFactorGuideStatus = useAppSelector(selectGetFactorGuideStatus);
  const factorsByFactorName = useAppSelector(selectFactorsByFactorName);

  // ------------------------ States ------------------------
  const [factorCardClicked, setFactorCardClicked] = useState<string>("Target");
  const [skillAccordionClicked, setSkillAccordionClicked] = useState<
    number | null
  >(null);
  const [selectedSkillId, setSelectedSkillId] = useState<null | number>(null);
  const [selectedDimensionName, setSelectedDimensionName] = useState<
    string | null
  >(null);
  const [searchInput, setSearchInput] = useState("");

  // ------------------------ Effects ------------------------
  useLayoutEffect(() => {
    if (!skillsGuide) return;
    const factor = searchParams.get("factor");
    const dimension = searchParams.get("dimension");
    const skillId = searchParams.get("skillId");

    // If both the factor and dimensions are provided then show the accordions for the specific factor with the dimension expanded
    if (factor && dimension) {
      setFactorCardClicked(factor);
      setSelectedDimensionName(dimension);
      setSelectedSkillId(null);
      return;
    }

    // If the factor is provided then we show the accordions for the specific factor
    setFactorCardClicked(factor ?? "Target");
    // If just the dimension is provided then we show the SkillPage for the specific dimension
    setSelectedDimensionName(dimension ?? null);
    setSelectedSkillId(skillId ? parseInt(skillId) : null);
  }, [searchParams, skillsGuide]);

  useEffect(() => {
    if (getSkillsGuideStatus === "idle") {
      dispatch(getSkillGuide());
    }
  }, [dispatch, getSkillsGuideStatus]);

  useEffect(() => {
    if (getDimensionGuideStatus === "idle") {
      dispatch(getDimensionGuide());
    }
  }, [dispatch, getDimensionGuideStatus]);

  useEffect(() => {
    dispatch(getFactorGuide());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getHasLibraryEditAccess());
  }, [dispatch]);

  // ------------------------ Action Handlers ------------------------
  const onFactorClick = (factorName: string) => {
    setFactorCardClicked(factorName);
    setSkillAccordionClicked(null);
    setSearchParams((sp) => {
      sp.set("factor", factorName);
      return sp;
    });
  };

  const onDimensionButtonClick = (dimensionName: string) => {
    if (dimensionsByName[dimensionName]) {
      setSelectedDimensionName(dimensionName);
      setSearchParams((sp) => {
        sp.set("dimension", dimensionName);
        return sp;
      });
    }
  };

  const onSkillClick = (skillObj: TSkill) => {
    setSelectedSkillId(skillObj.id);
    setSearchParams((sp) => {
      sp.set("skillId", `${skillObj.id}`);
      return sp;
    });
  };

  // ------------------------ Getters ------------------------
  const getFactorCards = () => {
    if (getSkillsGuideStatus === "loading") return <Loading />;

    return getEntries(skillsGuide).map(([factorName, dimensionsObj]) => {
      const dimensionCount = Object.keys(dimensionsObj).length;
      // const skillsCount = Object.values(dimensionsObj).reduce(
      //   (acc, curr) => acc + Object.keys(curr).length,
      //   0
      // );

      const allTeam360FactorsAsStringArray: string[] = [
        ...ALL_TEAM_360_FACTORS,
      ];

      return (
        <Card
          key={factorName}
          className={`factor-card-small ${
            factorCardClicked === factorName ? "selected" : ""
          }`}
          role="button"
          aria-pressed={factorCardClicked === factorName}
          onClick={() => {
            onFactorClick(factorName);
          }}
        >
          {allTeam360FactorsAsStringArray.includes(factorName) ? (
            <div className="small-icon-square">
              <TeamAssessmentIcon name={factorName} color="navy" />
            </div>
          ) : null}
          <div>
            <h4>{factorName}</h4>
            <p>
              {dimensionCount} Dimensions
              {/* - {skillsCount} Skills */}
            </p>
          </div>
        </Card>
      );
    });
  };

  const getSkillsCard = (skillObj: TSkill) => (
    <Card key={skillObj.skill}>
      <h4>{skillObj.skill}</h4>
      <p>{skillObj.description}</p>
      <Button onClick={() => onSkillClick(skillObj)} variant="secondary-blue">
        See skill guide <FontAwesomeIcon icon="arrow-right" />
      </Button>
    </Card>
  );

  const getAccordions = () => {
    if (!factorCardClicked || !skillsGuide[factorCardClicked]) return null;
    const dimensions = skillsGuide[factorCardClicked];
    return getEntries(dimensions).map(([dimensionName, skills], idx) => {
      const skillCount = Object.keys(skills).length;
      return (
        <React.Fragment key={dimensionName}>
          <div>
            <div
              className="skills-guide-accordion-title"
              onClick={() => {
                if (skillAccordionClicked === idx) {
                  return setSkillAccordionClicked(null);
                }
                setSkillAccordionClicked(idx);
              }}
              role="button"
            >
              <h3>{dimensionName}</h3>
              <div>
                <Button
                  variant="secondary-blue"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDimensionButtonClick(dimensionName);
                  }}
                >
                  See dimension guide
                </Button>
                <span>
                  {skillCount} related skill{skillCount > 1 ? "s" : ""}
                  <FontAwesomeIcon
                    icon={
                      skillAccordionClicked === idx ? "caret-up" : "caret-down"
                    }
                    className="ms-2"
                  />
                </span>
              </div>
            </div>
            <Collapse in={skillAccordionClicked === idx}>
              <div className="skills-cards-container">
                {Object.values(skills).map((skill) => getSkillsCard(skill))}
              </div>
            </Collapse>
          </div>
          {idx !== Object.keys(dimensions).length - 1 && (
            <hr
              className={`m-0 ${
                skillAccordionClicked === idx ? "selected" : ""
              }`}
            />
          )}
        </React.Fragment>
      );
    });
  };

  const getPageView = () => {
    let selectedSkill = null;
    let skillTypeSelected: "Skill" | "Dimension" = "Skill";
    let factorToDisplay = factorCardClicked;

    if (selectedSkillId && skillsById[selectedSkillId]) {
      selectedSkill = skillsById[selectedSkillId];
      factorToDisplay = selectedSkill.factor;
    } else if (
      selectedDimensionName &&
      dimensionsByName[selectedDimensionName]
    ) {
      skillTypeSelected = "Dimension";
      selectedSkill = dimensionsByName[selectedDimensionName];
      factorToDisplay = TEAM_360_FACTOR_ID_TO_NAME_MAP[selectedSkill.factorId];
    }

    if (selectedSkill) {
      return (
        <SkillPage
          skill={selectedSkill}
          factor={factorToDisplay}
          type={skillTypeSelected}
        />
      );
    }

    const { selectStyles, components } = getSelectProps();
    return (
      <>
        <div className="column-gap-20px">
          <div className="row-gap-20px align-items-center">
            <img src={TeamAverageIllustration} alt="Teams" />
            <h1
              style={{
                fontWeight: "normal",
              }}
            >
              Welcome to the <b>Skills Library</b>
            </h1>
          </div>
          <div
            className="padding-20px column-gap-20px"
            style={{
              backgroundColor: "#F7F7F7",
              borderRadius: "4px",
            }}
          >
            <p>
              The Skills Library is a treasure trove of actionable tools to help
              you improve teamwork. Here, you will find information about the
              four key factors of teamwork:
              <b>Target, Empower, Align, and Monitor.</b> Each of these factors
              is further divided into several dimensions, providing an in-depth
              look into the key features of high-performing teams.
            </p>
            <p>
              Remember, the Skills Library is a dynamic tool, and we encourage
              you to revisit regularly as you and your team progress and
              develop. Happy exploring!
            </p>
          </div>

          <Select
            placeholder={"Search by factor, dimension, skill..."}
            isClearable={true}
            isSearchable={true}
            components={components}
            inputValue={searchInput}
            onInputChange={setSearchInput}
            styles={selectStyles}
            options={getSelectOptions()}
            formatOptionLabel={getFormatOptionLabel}
            onChange={(optionInfo) => {
              if (!optionInfo) return;
              const { value, type } = optionInfo;
              if (type === "Skill") {
                selectSkillId(parseInt(value));
              } else if (type === "Dimension") {
                setSelectedDimensionName(value);
              } else if (type === "Factor") {
                setFactorCardClicked(value);
              }
            }}
          />
          <div className="factor-cards-container">{getFactorCards()}</div>
          <div className="factor-intro column-gap-8px">
            <h1>{factorCardClicked}</h1>
            <p>{factorsByFactorName[factorCardClicked]?.introduction}</p>
          </div>
          <div className="column-gap-16px">{getAccordions()}</div>
        </div>
      </>
    );
  };

  const getFormatOptionLabel = (
    optionInfo: {
      label: string;
      value: string;
      type: "Factor" | "Dimension" | "Skill";
    } | null
  ) => {
    if (!optionInfo) return null;
    const { label, type } = optionInfo;

    return (
      <div className="skill-search-option">
        <span>{label}</span>
        <p>{type}</p>
      </div>
    );
  };

  const selectSkillId = (skillId: number) => {
    setSelectedSkillId(skillsById[skillId].id);
  };

  // For this function what we will need to do is to first show the available factors then the available dimensions, then the available skills
  const getSelectOptions = () => {
    if (!skillsGuide) return [];

    interface IOption {
      label: string;
      value: string;
      type: "Factor" | "Dimension" | "Skill";
    }
    const factorOptions: IOption[] = [];
    const dimensionOptions: IOption[] = [];
    const skillOptions: IOption[] = [];

    Object.keys(skillsGuide).forEach((factor) => {
      factorOptions.push({
        label: factor,
        value: factor,
        type: "Factor",
      });

      Object.entries(skillsGuide[factor]).forEach(
        ([dimensionName, dimensionObj]) => {
          dimensionOptions.push({
            label: dimensionName,
            value: dimensionName,
            type: "Dimension",
          });

          Object.values(dimensionObj).forEach((skillObj) => {
            skillOptions.push({
              label: skillObj.skill,
              value: `${skillObj.id}`,
              type: "Skill",
            });
          });
        }
      );
    });

    return [...factorOptions, ...dimensionOptions, ...skillOptions];
  };

  return (
    <div className="guide page">
      <div className="mb-3">
        <NavigateBackButton />
      </div>
      <Card style={{ padding: "20px" }}>{getPageView()}</Card>
    </div>
  );
}
