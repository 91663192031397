import { Card, Form, Dropdown } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'utils/redux/hooks';
import Button from 'app/storybookComponents/Button';
import {
  getOpenAIGeneratedText,
  selectCreatingModuleStatus,
  createModule,
  updateModule,
  selectGetOpenAIGeneratedTextStatus,
  selectOpenAIGeneratedText,
  selectUpdatingModuleStatus,
} from './slice';
import {
  getOpenAIModules,
  selectOpenAIModules,
  selectGettingModules,
} from '../OpenAIModules/slice';
import { useState, ReactElement } from 'react';
import { OpenAIModuleInfo } from '../OpenAIModule/types';
import { OpenAIModuleResponseContent } from 'app/components/OpenAIInternalTools/OpenAIModule/index';

export default function OpenAIGenerator() {
  const dispatch = useAppDispatch();
  const openAIGeneratedText = useAppSelector(selectOpenAIGeneratedText);
  const getOpenAIGeneratedTextStatus = useAppSelector(
    selectGetOpenAIGeneratedTextStatus
  );
  const creatingModuleStatus = useAppSelector(selectCreatingModuleStatus);
  const updatingModuleStatus = useAppSelector(selectUpdatingModuleStatus);
  const openAIModules = useAppSelector(selectOpenAIModules);
  const gettingModulesStatus = useAppSelector(selectGettingModules);
  const [sampleUserInput, setSampleUserInput] = useState('');
  const [showForm, setShowForm] = useState(true);
  const [selectedModuleId, setSelectedModuleId] = useState<number | null>(null);
  const [selectedModule, setSelectedModule] = useState<OpenAIModuleInfo | null>(
    null
  );

  const [payload, setPayload] = useState({
    title: '',
    backgroundContext: '',
    prompt: '',
    backupPrompt: '',
    tokensRequired: 0,
    freeTextInputs: '',
  });

  const postModule = () => {
    dispatch(createModule(payload)).then(() => {
      dispatch(getOpenAIModules());
    });
  };

  const editModule = () => {
    if (!selectedModuleId) {
      return;
    }
    dispatch(
      updateModule({
        moduleId: selectedModuleId,
        payload: payload,
      })
    ).then(() => {
      dispatch(getOpenAIModules());
    });
  };

  const generateResponse = () => {
    dispatch(
      getOpenAIGeneratedText({
        title: payload.title,
        backgroundContext: payload.backgroundContext,
        prompt: payload.prompt,
        backupPrompt: payload.backupPrompt,
        tokensRequired: payload.tokensRequired,
        freeTextInputs: payload.freeTextInputs,
        sampleUserInput: sampleUserInput,
      })
    );
  };

  const onDropdownSelect = (eventKey: any) => {
    setSelectedModuleId(eventKey);
    if (openAIModules) {
      const module = openAIModules.modules.find(
        (module) => module.id === Number(eventKey)
      );
      setSelectedModule(module ? module : null);
      if (!module) {
        setPayload({
          title: '',
          backgroundContext: '',
          prompt: '',
          backupPrompt: '',
          tokensRequired: 0,
          freeTextInputs: '',
        });
      } else {
        setPayload({
          title: module.title,
          backgroundContext: module.backgroundContext,
          prompt: module.prompt,
          backupPrompt: module.backupPrompt,
          tokensRequired: module.tokensRequired,
          freeTextInputs: module.freeTextInputs
            ? JSON.stringify(module.freeTextInputs)
            : '',
        });
      }
      setSampleUserInput('');
    }
  };

  const getDropdown = () => {
    switch (gettingModulesStatus) {
      case 'loading':
        return <p>Loading...</p>;
      case 'failed':
        return <p>Failed to get Module Info</p>;
      default:
        if (openAIModules) {
          const modules: ReactElement[] = [];
          modules.push(
            <Dropdown.Item eventKey={0}>Create a New Module</Dropdown.Item>
          );
          openAIModules.modules.forEach((module) => {
            modules.push(
              <Dropdown.Item eventKey={module.id}>{module.title}</Dropdown.Item>
            );
          });
          return (
            <div className="dropdown">
              <Dropdown onSelect={onDropdownSelect}>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {selectedModule
                    ? 'Edit ' + selectedModule.title
                    : 'Create a New Module'}
                </Dropdown.Toggle>
                <Dropdown.Menu id="user-settings-menu">{modules}</Dropdown.Menu>
              </Dropdown>
            </div>
          );
        }
    }
  };

  const getButtonsSection = () => {
    const sharedRequirements =
      payload.backgroundContext &&
      payload.prompt &&
      payload.backupPrompt &&
      payload.tokensRequired &&
      isValidJSON(payload.freeTextInputs);

    const canGetExampleResponse =
      sharedRequirements && (payload.freeTextInputs ? sampleUserInput : true);

    const canCreateModule = sharedRequirements && payload.title;

    return (
      <div>
        <Button
          variant="primary"
          disabled={!canGetExampleResponse}
          className="generate-feedback"
          onClick={() => generateResponse()}
        >
          Generate Example Response
        </Button>
        <Button
          variant="primary"
          disabled={!canCreateModule}
          className="generate-feedback"
          onClick={!selectedModuleId ? postModule : editModule}
        >
          {!selectedModuleId ? 'Create' : 'Edit'} Module
        </Button>
      </div>
    );
  };

  const isValidJSON = (json: string) => {
    if (json) {
      try {
        JSON.parse(json);
      } catch (e) {
        return false;
      }
    }
    return true;
  };

  const freeTextInputError = () => {
    if (!isValidJSON(payload.freeTextInputs)) {
      return (
        <Form.Text className="text-danger">
          Please enter a valid JSON object
        </Form.Text>
      );
    }
  };

  const getForm = () => {
    return (
      <Form
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        <h3>Module Display</h3>
        <Form.Label>Module Title</Form.Label>
        <Form.Control
          type="text"
          placeholder="e.g. Affirmation"
          onChange={(event) =>
            setPayload({ ...payload, title: event.target.value })
          }
          value={payload.title}
        />
        <Form.Text>
          For the free text inputs, specify a JSON object with the values for
          the free text inputs you want in the module. Each input should have a
          <strong> value</strong>, which is the name of the variable, a
          <strong> displayTitle</strong>, which is what the user will see when
          they are prompted to enter the value, and a
          <strong> placeholder</strong> (optional), which is what the user will
          see in the input box. For example, if you want to have a free text
          input for a praise module, where a user can enter the name of the
          person they want to praise, you would enter the following JSON object:
          <br />
          <br />
          <code>
            {`{"1": {"value": "recipientName", "displayTitle": "Recipient Name", "placeholder":"John Doe"}}`}
          </code>
        </Form.Text>
        <p></p>
        <Form.Label>Free Text Inputs</Form.Label>
        <Form.Control
          as="textarea"
          placeholder='{"1": {"value": "recipientName", "displayTitle": "Recipient Name", "placeholder":"John Doe"}}'
          onChange={(event) =>
            setPayload({
              ...payload,
              freeTextInputs: event.target.value,
            })
          }
          value={payload.freeTextInputs}
        />
        {freeTextInputError()}
        <h3>Prompt</h3>
        <Form.Label>Background Context</Form.Label>
        <Form.Control
          as="textarea"
          rows={10}
          placeholder="e.g. Great Feedback is 'NICE' that means it's narrow and specific, immediate, constructive and even-handed."
          onChange={(event) =>
            setPayload({
              ...payload,
              backgroundContext: event.target.value,
            })
          }
          value={payload.backgroundContext}
        />
        <Form.Label>Prompt</Form.Label>

        <Form.Control
          type="text"
          placeholder="e.g. Could you generate some NICE feedback for $recipientName for $behavior? This feedback should be tailored to the $industry industry."
          onChange={(event) =>
            setPayload({ ...payload, prompt: event.target.value })
          }
          value={payload.prompt}
        />
        <Form.Text>
          For the prompt, you can utilize certain variable names in the
          response. These variable names reference either the value of the user
          inputs of the module, or universally available variables.
        </Form.Text>
        <p></p>
        <Form.Label>Backup Prompt</Form.Label>
        <Form.Control
          type="text"
          placeholder="e.g. Could you generate some NICE feedback"
          onChange={(event) =>
            setPayload({ ...payload, backupPrompt: event.target.value })
          }
          value={payload.backupPrompt}
        />
        <Form.Text>
          In case the user does not have the variables referenced in the prompt
          available, we need an alternate prompt that does not utilize any
          universal variables
        </Form.Text>
        <p></p>
        <Form.Label>Response Length</Form.Label>
        <Form.Select
          onChange={(event) =>
            setPayload({
              ...payload,
              tokensRequired: Number(event.target.value),
            })
          }
        >
          <option>Select Response Length</option>
          <option value={100} selected={payload.tokensRequired === 100}>
            A few sentences
          </option>
          <option value={200} selected={payload.tokensRequired === 200}>
            A paragraph
          </option>
          <option value={300} selected={payload.tokensRequired === 300}>
            A couple paragraphs
          </option>
        </Form.Select>
        <h3>Sample User Input</h3>
        <Form.Text className="sample-input">
          If you include free text inputs in your module, in order to test the
          response you need to provide a sample user input. This should be a
          JSON object with the "value" key from the fre text inputs JSON, and
          the values should be the values you want to test the response with.
        </Form.Text>
        <p></p>
        <Form.Control
          type="text"
          placeholder='{"recipientName": "John Doe", "behavior": "being late"}'
          onChange={(event) => setSampleUserInput(event.target.value)}
          disabled={!payload.freeTextInputs}
        />
        {getButtonsSection()}
      </Form>
    );
  };

  const getCreateOrUpdateModuleStatusMessage = () => {
    const activeStatus =
      creatingModuleStatus === 'idle'
        ? updatingModuleStatus
        : creatingModuleStatus;

    switch (activeStatus) {
      case 'idle':
        return <p></p>;
      case 'loading':
        return <p>Loading...</p>;
      case 'failed':
        return <p>Failed to save module</p>;
      case 'succeeded':
        return <p>Module saved successfully</p>;
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h1 style={{ marginRight: '10px' }}>
          Open AI Module Generator And Editor
        </h1>
        <Button onClick={() => setShowForm(!showForm)}>
          {showForm ? 'Hide' : 'Show'}
        </Button>
      </div>
      <Card className="ai-module" style={{ display: showForm ? '' : 'none' }}>
        {getDropdown()}
        {getForm()}
        {getCreateOrUpdateModuleStatusMessage()}
        <h3>Example Response: </h3>
        {OpenAIModuleResponseContent({
          status: getOpenAIGeneratedTextStatus,
          response: openAIGeneratedText ? openAIGeneratedText.response : '',
        })}
      </Card>
    </div>
  );
}
