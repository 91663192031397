import { Modal } from "react-bootstrap";
import Button from "app/storybookComponents/Button";
import Overview from "./Overview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  send360TeamFeedback,
  setActiveAssessmentEventId,
  selectShowSurveyModal,
  selectSurveysTeamId,
  selectFeedbackSentStatus,
  selectActiveAssessmentEventId,
  setShowSurveyModal,
  setSurveysTeamId,
  selectTeam360FeedbackPayload,
  setTeam360FeedbackPayload,
  resetTeam360FeedbackPayload,
} from "../slice";
import FeedbackStarScreen from "../FeedbackStarScreen";
import { selectPendingAssessments } from "app/containers/Dashboard/slice";
import { useNavigate } from "react-router-dom";

export default function Team360AssessmentModal() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const feedbackSentStatus = useAppSelector(selectFeedbackSentStatus);
  const activeAssessmentEventId = useAppSelector(selectActiveAssessmentEventId);
  const showState = useAppSelector(selectShowSurveyModal);
  const teamId = useAppSelector(selectSurveysTeamId);
  const pendingAssessments = useAppSelector(selectPendingAssessments);
  const teamFeedback = useAppSelector(selectTeam360FeedbackPayload);
  const currentAssessmentEvent = activeAssessmentEventId
    ? pendingAssessments[activeAssessmentEventId]
    : null;

  const hideModal = async () => {
    // If we close the modal and the user has not finished the team feedback or assessment feedback and the user had submitted enough information, then we want to send the results to the backend.
    if (
      typeof teamFeedback.teamEffectivenessRating === "number" &&
      typeof teamFeedback.likelihoodToLeaveThisTeam === "number" &&
      typeof teamFeedback.primaryWorkStyle === "number"
    ) {
      await dispatch(send360TeamFeedback());
    }
    dispatch(resetTeam360FeedbackPayload());
    dispatch(setTeam360FeedbackPayload({}));
    dispatch(setActiveAssessmentEventId(null));
    dispatch(setShowSurveyModal(null));
    dispatch(setSurveysTeamId(null));
    // Doing this instead of navigate so that when the user arrives to the Team Guide they will not be shown the back button.
    window.location.href = `/TeamGuide/${teamId}`;
    // navigate(`/TeamGuide/${teamId}`);
  };

  const getModalContent = () => {
    if (!showState) return null;
    if (showState === "Overview") {
      let memberIds: number[] = [];
      if (currentAssessmentEvent?.userAccountIds) {
        memberIds = [...currentAssessmentEvent.userAccountIds];
      }
      if (currentAssessmentEvent?.userAccountId) {
        memberIds.push(currentAssessmentEvent.userAccountId);
      }

      return (
        <Overview
          memberIds={memberIds}
          sentToText={currentAssessmentEvent?.sentToText}
          frequencyText={currentAssessmentEvent?.frequencyText}
          userAccountIds={currentAssessmentEvent?.userAccountIds}
          userAccountId={currentAssessmentEvent?.userAccountId}
          onStartSurvey={() => {}}
        />
      );
    }

    if (showState === "Product Feedback") {
      return (
        <FeedbackStarScreen
          title="Thank you for completing the TEAMscan!"
          onInputChange={(newVal) => {
            dispatch(
              setTeam360FeedbackPayload({
                ...teamFeedback,
                assessmentFeedback: newVal,
              })
            );
          }}
          inputText={teamFeedback.assessmentFeedback}
          onSetStars={(newVal) => {
            dispatch(
              setTeam360FeedbackPayload({
                ...teamFeedback,
                assessmentExperienceRating: newVal,
              })
            );
          }}
          onClose={() => {
            hideModal();
          }}
          onSubmit={() => {
            hideModal();
          }}
          disabled={feedbackSentStatus === "loading"}
        />
      );
    }

    return null;
  };

  return (
    <Modal
      show={!!showState}
      className="simple-modal"
      size="lg"
      onHide={hideModal}
    >
      <div className="assessment-header">
        <h2>{showState === "Overview" ? "Overview" : "TEAMscan"}</h2>
        <Button
          onClick={() => hideModal()}
          variant={"secondary-blue"}
          style={{ border: "none", width: "auto" }}
          xIcon
        />
      </div>
      {getModalContent()}
      {showState === "Overview" ? (
        <div className="team-360-footer">
          <div>
            <div role="button" onClick={() => hideModal()}>
              Back
            </div>
          </div>
          <div>
            <Button
              onClick={() => {
                navigate(
                  `/survey/team360?assessmentEventId=${activeAssessmentEventId}`
                );
                hideModal();
              }}
            >
              Start Survey <FontAwesomeIcon icon="arrow-right" />
            </Button>
          </div>
        </div>
      ) : null}
    </Modal>
  );
}
