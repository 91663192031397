import { useCallback, useEffect, useMemo, useState } from "react";
import NavigateBackButton from "app/components/NavigateBackButton";
import { useAppSelector, useAppDispatch } from "utils/redux/hooks";
import Loading from "app/storybookComponents/Loading";
import { Card } from "react-bootstrap";
import notableTraitIcon from "resources/icons/notable-trait.svg";
import {
  selectTeamPersonalityReportsByTeamId,
  getTeamPersonalityReports,
  selectGetTeamPersonalityReportsStatus,
} from "./slice";
import { getTraitNameAndIcon } from "./helper-functions";
import { WorkplaceInsightsTrait } from "./types";
import WorkplaceInsightBar from "./WorkplaceInsightBar";

interface Props {
  teamId: number;
}
export default function FullPageTeamPersonalityReport({ teamId }: Props) {
  const dispatch = useAppDispatch();
  const [selectedNav, setSelectedNav] = useState<string | null>(null);
  const teamPersonalityReport = useAppSelector(
    selectTeamPersonalityReportsByTeamId(teamId)
  );
  const getTeamPersonalityReportsStatus = useAppSelector(
    selectGetTeamPersonalityReportsStatus
  );

  useEffect(() => {
    // If no teamPersonalityReport, then get it (might add guard clause for status)
    if (!teamPersonalityReport) {
      dispatch(getTeamPersonalityReports(teamId));
    }
  }, [teamPersonalityReport, dispatch, teamId]);

  const sortedPersonalityTraits = useMemo(() => {
    if (!teamPersonalityReport?.team) {
      return [];
    }

    return Object.entries(teamPersonalityReport.team).sort((a, b) => {
      const [traitAbbrevA, traitA] = a;
      const [traitAbbrevB, traitB] = b;
      if (traitA.isNotableTrait === traitB.isNotableTrait) {
        return traitAbbrevA.localeCompare(traitAbbrevB);
      }

      return traitB.isNotableTrait - traitA.isNotableTrait;
    });
  }, [teamPersonalityReport]);

  // useEffect when sortedPersonalityTraits changes and the size is greater than 1 then we set the first trait as the selectedNav
  useEffect(() => {
    if (sortedPersonalityTraits.length > 0) {
      setSelectedNav(sortedPersonalityTraits[0][0]);
    }
  }, [sortedPersonalityTraits]);

  const getMemberScores = useCallback(
    (trait: WorkplaceInsightsTrait) => {
      if (!teamPersonalityReport?.teamMembers) {
        return [];
      }
      const teamMemberScores = teamPersonalityReport.teamMembers;
      return teamMemberScores.map((member) => {
        return {
          score: Number(member[trait]?.score) || 0,
          userAccountId: member.userAccountId,
        };
      });
    },
    [teamPersonalityReport?.teamMembers]
  );

  const getSuggestions = useCallback(
    (incomingMap?: { [key: string]: string } | null) => {
      if (!incomingMap) {
        return null;
      }

      return Object.entries(incomingMap).map(([key, val]) => {
        return (
          <div key={key}>
            <h3>{key}</h3>
            <p className="team-personality-suggestion-paragraph">{val}</p>
          </div>
        );
      });
    },
    []
  );

  const personalityTraits = useMemo(() => {
    const foundTrait = sortedPersonalityTraits.find(([traitAbbrev]) => {
      return traitAbbrev === selectedNav;
    });

    const [traitAbbrev, traitObj] = foundTrait
      ? foundTrait
      : sortedPersonalityTraits[0];
    const traitNameAndDescription = getTraitNameAndIcon(traitAbbrev);
    if (!traitNameAndDescription) {
      return null;
    }
    const { traitName, definition, lowLabel, highLabel } =
      traitNameAndDescription;
    const traitCard = (
      <div
        key={traitAbbrev}
        className={`team-personality-report-card__trait`}
        id={traitAbbrev}
      >
        <div className="d-flex justify-content-between">
          <h2>{traitName}</h2>
          {traitObj.isNotableTrait ? (
            <div>
              <img
                src={notableTraitIcon}
                alt="notable trait"
                className="notable-trait-icon"
              />{" "}
              Notable Trait
            </div>
          ) : null}
        </div>
        <p className="team-personality-personality-traits-paragraph">
          {definition}
        </p>
        <p className="team-personality-personality-traits-paragraph">
          {traitObj.summary}
        </p>
        <div>
          <WorkplaceInsightBar
            memberScores={getMemberScores(
              traitAbbrev as WorkplaceInsightsTrait
            )}
            lowLabel={lowLabel}
            highLabel={highLabel}
          />
        </div>
        <div className="what-does-it-mean">
          <div className="what-this-means-paragraph">
            What this means for ...
          </div>
          {getSuggestions(traitObj.suggestions)}
        </div>
      </div>
    );

    return (
      <div className="team-personality-report-card__trait-container">
        {traitCard}
      </div>
    );
  }, [sortedPersonalityTraits, getMemberScores, getSuggestions, selectedNav]);

  const navigateToTrait = (traitAbbrev: string) => {
    // scroll down to the section of the page that has the trait
    const element = document.getElementById(traitAbbrev);
    setSelectedNav(traitAbbrev);
    if (element) {
      element.scrollIntoView();
    }
  };

  const getPersonalityTraitNavigationBox = () => {
    const navItems = sortedPersonalityTraits.map(([traitAbbrev, traitObj]) => {
      const traitNameAndIcon = getTraitNameAndIcon(traitAbbrev);
      if (!traitNameAndIcon) {
        return null;
      }

      const { traitName, traitIcon } = traitNameAndIcon;
      return (
        <div
          key={traitAbbrev}
          className={`d-flex justify-content-between ${
            traitAbbrev === selectedNav ? " selected" : ""
          }`}
          onClick={() => navigateToTrait(traitAbbrev)}
        >
          <div>
            <img
              src={traitIcon}
              alt={traitName}
              className="me-3"
              style={{ height: "40px", width: "40px" }}
            />
            <span>{traitName}</span>
          </div>
          {traitObj.isNotableTrait ? (
            <img
              src={notableTraitIcon}
              alt="notable trait"
              className="notable-trait-icon"
            />
          ) : null}
        </div>
      );
    });

    return <div className="navigation-box sticky-top">{navItems}</div>;
  };

  const navigateBackButton = <NavigateBackButton />;

  if (getTeamPersonalityReportsStatus === "loading") {
    return <Loading />;
  }

  return (
    <div>
      {navigateBackButton ? (
        <div className="mb-0">{navigateBackButton}</div>
      ) : null}
      <Card className="team-personality-report-card">
        <div className="team-personality-report-card__header">
          <h1>Team Personality</h1>
          <p className="team-personality-paragraph">
            This section shows how your team members work according to 10
            different behavioral traits. Notable traits are marked with a
            <img src={notableTraitIcon} alt="notable trait" />
            and most strongly characterize your team.
          </p>
        </div>
        <div className="team-personality-report-card__body">
          {getPersonalityTraitNavigationBox()}
          {personalityTraits}
        </div>
      </Card>
    </div>
  );
}
