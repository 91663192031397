import { Card } from 'react-bootstrap';
import { useAppDispatch } from 'utils/redux/hooks';
import ModuleCard from 'app/components/Modules/Cards/ModuleCard';
import { showEditModuleModal } from 'app/components/Modules/slice';
import { GuideType, ModuleType } from 'app/components/Modules/types';
import Button from 'app/storybookComponents/Button';

interface Props {
  onClickHandler: () => void;
  modules: {
    moduleType: string;
    moduleTemplateId: number;
    title: string;
    shortDescription: string;
    iconName?: string;
  }[];
  userIdOrTeamIdOrCompanyId: number;
  guideType: GuideType;
}

export default function BuildYourUserGuideCard({
  onClickHandler,
  modules,
  userIdOrTeamIdOrCompanyId,
  guideType,
}: Props) {
  const dispatch = useAppDispatch();

  const getCards = () =>
    modules.map(
      ({ moduleTemplateId, moduleType, title, shortDescription, iconName }) => (
        <ModuleCard
          key={`${moduleTemplateId}${moduleType}`}
          moduleTemplateId={moduleTemplateId}
          moduleType={moduleType}
          title={title}
          onAddModule={() => {
            dispatch(
              showEditModuleModal({
                moduleTemplateId: moduleTemplateId,
                moduleType: moduleType as ModuleType,
                userIdOrTeamIdOrCompanyId,
                guideType,
              })
            );
          }}
          shortDescription={shortDescription}
          iconName={iconName}
        />
      )
    );

  return modules.length !== 0 ? (
    <Card className="build-your-user-guide-card">
      <h2>
        Build Your <span className="text-capitalize">{guideType}</span> Guide
      </h2>
      <p>Click on one to add:</p>
      <div className="module-card-holder">{getCards()}</div>
      <Button onClick={onClickHandler} variant="secondary-blue">
        See More Prompts
      </Button>
    </Card>
  ) : null;
}
