import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'utils/redux/hooks';
import Button from 'app/storybookComponents/Button';

import {
  selectUniversalVariables,
  selectGettingUniversalVariablesStatus,
  getUniversalVariables,
} from './slice';

export default function UniversalVariables() {
  const dispatch = useAppDispatch();
  const universalVariables = useAppSelector(selectUniversalVariables);
  const gettingUniversalVariablesStatus = useAppSelector(
    selectGettingUniversalVariablesStatus
  );
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (gettingUniversalVariablesStatus === 'idle') {
      dispatch(getUniversalVariables());
    }
  }, [gettingUniversalVariablesStatus, dispatch]);

  const getUniversalVariablesList = () => {
    if (!universalVariables || !show) {
      return <div></div>;
    }
    const universalVariablesList = universalVariables.universalVariables.map(
      (variable, idx) => (
        <li key={idx}>
          <strong>{variable.name}</strong>: {variable.description}
        </li>
      )
    );
    return <div>{universalVariablesList}</div>;
  };

  return (
    <div style={{ marginBottom: '10px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2>Universal Variables</h2>
        <Button
          onClick={() => {
            setShow(!show);
          }}
        >
          {show ? 'Hide' : 'Show'} Universal Variables
        </Button>
      </div>

      {getUniversalVariablesList()}
    </div>
  );
}
