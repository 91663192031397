import { useEffect, useState } from "react";
import Select, { FormatOptionLabelMeta, SingleValue } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "app/storybookComponents/Button";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  selectAllCompanyUsersById,
  selectCompanyInfo,
} from "app/containers/Global/slice";
import { UserInfo } from "app/containers/Global/types";
import { getDropdownIndicator } from "app/storybookComponents/SearchableInput/helpers";
import UploadPictureModal from "app/components/ImageEditor/UploadPictureModal";
import AdminConsoleSettingsModal from "./Modals/AdminConsoleSettingModal";
import { selectCompanySettings, updateCompanySettings } from "./slice";
import { CompanySettingDropdown, CompanySettings } from "./types";

interface Props {
  users: { [userAccountId: string | number]: UserInfo };
}

export default function AdminConsoleSettings({ users }: Props) {
  const dispatch = useAppDispatch();
  const companySettings = useAppSelector(selectCompanySettings);
  const companyInfo = useAppSelector(selectCompanyInfo);
  const usersInfoById = useAppSelector(selectAllCompanyUsersById);

  const [modalShowing, setModalShowing] = useState<
    null | "Edit Domains" | "Who Can Invite" | "Who Can Create"
  >(null);
  const [whoCanJoinOrganization, setWhoCanJoinOrganization] = useState<
    1 | 2 | 3 | undefined
  >(undefined);
  const [whoCanCreateTeams, setWhoCanCreateTeams] = useState<
    1 | 2 | 3 | undefined
  >(undefined);
  const [whoCanInvite, setWhoCanInvite] = useState<1 | 2 | 3 | undefined>(
    undefined
  );
  const [showEditGlobalHeaderModal, setShowEditGlobalHeaderModal] =
    useState(false);

  // ------------------------ Use Effects ------------------------------------

  useEffect(() => {
    setWhoCanJoinOrganization(companySettings?.emailSetting);
  }, [companySettings?.emailSetting]);

  useEffect(() => {
    setWhoCanCreateTeams(companySettings?.teamCreationAccess);
  }, [companySettings?.teamCreationAccess]);

  useEffect(() => {
    setWhoCanInvite(companySettings?.invitationAccess);
  }, [companySettings?.invitationAccess]);

  // ------------------------ Get Functions ------------------------------------
  const getWhoCanJoinOrganizationValue = () => {
    if (!whoCanJoinOrganization) {
      return;
    }
    switch (whoCanJoinOrganization) {
      case 2:
        return {
          value: `${whoCanJoinOrganization}`,
          label: `Anyone with a ${
            companySettings?.originalDomain || "@dmpaper.com"
          } email`,
        };
      case 3:
        return { value: `${whoCanJoinOrganization}`, label: "Custom List" };
      case 1:
      default:
        return { value: `${whoCanJoinOrganization}`, label: "Anyone" };
    }
  };

  // ------------------------ On Change Functions ------------------------------------
  const onWhoCanJoinOrganizationChange = (
    val: SingleValue<{
      value: string;
      label: string;
    }>
  ) => {
    if (!companyInfo?.companyAccountId) {
      return;
    }

    // Todo: for all cases we need to make sure that the user doesn't already have the value they are trying to set, if they do we don't need to make the call to the endpoint.
    switch (val?.value) {
      case "1": {
        // If the value is already set to all, we don't need to make the call to the endpoint.
        if (whoCanJoinOrganization === 1) return;

        setWhoCanJoinOrganization(1);
        return dispatch(
          updateCompanySettings({
            payload: { emailSetting: 1 },
            companyAccountId: companyInfo?.companyAccountId,
          })
        );
      }
      case "2": {
        // If the value is already set to onlyCurrentDomain, we don't need to make the call to the endpoint.
        if (whoCanJoinOrganization === 2) return;

        setWhoCanJoinOrganization(2);
        return dispatch(
          updateCompanySettings({
            payload: { emailSetting: 2 },
            companyAccountId: companyInfo?.companyAccountId,
          })
        );
      }
      case "3": {
        // If the value is already set to customList, we don't need to make the call to the endpoint.
        if (whoCanJoinOrganization === 3) return;

        setWhoCanJoinOrganization(3);
        return dispatch(
          updateCompanySettings({
            payload: { emailSetting: 3 },
            companyAccountId: companyInfo?.companyAccountId,
          })
        );
      }
      case "4":
        return setModalShowing("Edit Domains");
    }
  };

  // ------------------------ On Save Functions ------------------------------------
  const onAdminConsoleSettingsModalSave = (
    payload: Partial<CompanySettings>
  ) => {
    if (!companyInfo?.companyAccountId) {
      return;
    }
    return dispatch(
      updateCompanySettings({
        payload,
        companyAccountId: companyInfo?.companyAccountId,
      })
    );
  };

  const formatOptionLabelFn = (
    {
      value,
      label,
    }: {
      value: string;
      label: string;
    },
    formatOptionLabelMeta: FormatOptionLabelMeta<{
      value: string;
      label: string;
    }>,
    type: CompanySettingDropdown
  ) => {
    // We are only updating the ui for the menu.
    if (formatOptionLabelMeta.context !== "menu") {
      return label;
    }

    // If the option is edit list, we need to show the edit icon.
    if (value === "4") {
      return (
        <div>
          <FontAwesomeIcon icon="edit" />
          <span>{label}</span>
        </div>
      );
    }

    // If custom list, we need to show the list of domains or current users selected
    if (value === "3") {
      const listLength = getList(type)?.length ?? 0;
      return (
        <div>
          <span>
            {getSelectCheckMark(type, 3)}
            {label} ({listLength})
          </span>
          <span className="d-block">
            {listLength
              ? `(${getList(type)?.map(
                  (val, idx) => `${idx !== 0 ? " " : ""}${val}`
                )})`
              : null}
          </span>
        </div>
      );
    }

    // Need to add a checkmark next to it if it is the current value.
    return (
      <div>
        <span>
          {getSelectCheckMark(type, Number(value) as 1 | 2)}
          {label}
        </span>
      </div>
    );
  };

  const getSelectCheckMark = (
    type: CompanySettingDropdown,
    value: 1 | 2 | 3
  ) => {
    switch (type) {
      case "Who Can Join":
        return whoCanJoinOrganization === value ? (
          <FontAwesomeIcon icon="check" className="mr-2" />
        ) : null;
      case "Who Can Create":
        return whoCanCreateTeams === value ? (
          <FontAwesomeIcon icon="check" className="mr-2" />
        ) : null;
      case "Who Can Invite":
        return whoCanInvite === value ? (
          <FontAwesomeIcon icon="check" className="mr-2" />
        ) : null;
    }
  };

  const getList = (type: CompanySettingDropdown) => {
    switch (type) {
      case "Who Can Join":
        return companySettings?.customDomainList;
      case "Who Can Create":
        return companySettings?.teamCreationCustomList?.map(
          (userAccountId) =>
            `${usersInfoById[userAccountId].firstName} ${usersInfoById[userAccountId].lastName}`
        );
      case "Who Can Invite":
        return companySettings?.invitationCustomList?.map(
          (userAccountId) =>
            `${usersInfoById[userAccountId].firstName} ${usersInfoById[userAccountId].lastName}`
        );
    }
  };

  const selectStyles = {
    menu: (provided: any) => ({
      ...provided,
      width: 280,
      position: "absolute",
      right: 0,
    }),
  };

  const selectComponents = {
    DropdownIndicator: getDropdownIndicator("caret-down"),
    IndicatorSeparator: () => null,
  };

  return (
    <div className="column-gap-20px">
      <UploadPictureModal
        modalShowing={showEditGlobalHeaderModal ? "coverPhoto" : null}
        closeModal={() => setShowEditGlobalHeaderModal(false)}
        picture={companySettings?.globalHeaderBase64}
        updateGuidePhoto={async (photo, imgSrc, imageName) => {
          if (!companyInfo?.companyAccountId) {
            return;
          }
          await dispatch(
            updateCompanySettings({
              payload: { globalHeaderImage: photo, globalHeader: imageName },
              companyAccountId: companyInfo?.companyAccountId,
            })
          );
          setShowEditGlobalHeaderModal(false);
        }}
        deleteGuidePhoto={async () => {
          if (!companyInfo?.companyAccountId) {
            return;
          }
          await dispatch(
            updateCompanySettings({
              payload: { deleteGlobalHeader: true },
              companyAccountId: companyInfo?.companyAccountId,
            })
          );
          setShowEditGlobalHeaderModal(false);
        }}
      />
      <AdminConsoleSettingsModal
        modalShowing={modalShowing}
        onSave={onAdminConsoleSettingsModalSave}
        hideModal={() => setModalShowing(null)}
        users={users}
        companySettings={companySettings || undefined}
      />
      <h2>General Settings</h2>
      <div className="setting-row">
        <div className="setting-row-description">
          <h3>Who Can Join Your Organization?</h3>
          <p>Specify allowable domains for joining your organization</p>
        </div>
        <div className="setting-row-action">
          <Select
            closeMenuOnSelect={false}
            onChange={(e) => onWhoCanJoinOrganizationChange(e)}
            value={getWhoCanJoinOrganizationValue()}
            options={[
              { value: "1", label: "Anyone" },
              {
                value: "2",
                label: `Anyone with a ${
                  companySettings?.originalDomain || "@dmpaper.com"
                } email`,
              },
              { value: "3", label: "Custom List" },
              { value: "4", label: "Edit Custom List" },
            ]}
            isSearchable={false}
            formatOptionLabel={(opt, opt2) =>
              formatOptionLabelFn(opt, opt2, "Who Can Join")
            }
            styles={selectStyles}
            components={selectComponents}
          />
        </div>
      </div>
      <hr className="m-0" />
      <h2>Style Settings</h2>
      <div className="setting-row">
        <div className="setting-row-description">
          <h3>Default Global Header Image</h3>
          <p>
            This image will be the default header image for all team and user
            guides in your organization
          </p>
        </div>
        <div className="setting-row-action">
          <Button
            variant="secondary-blue"
            onClick={() => setShowEditGlobalHeaderModal(true)}
          >
            <FontAwesomeIcon icon="upload" className="me-2" /> Upload
          </Button>
        </div>
      </div>
    </div>
  );
}
