export const teamFilters = ["Department" as const];
export const teamFilterOptions = {
  "Team Size": [
    "All team sizes",
    "0-10 members",
    "11-50 members",
    "51-100 members",
    "101-200 members",
    "200+ members",
  ],
};

export const peopleFilters = ["Team", "Department"] as const;
