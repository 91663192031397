import { TCollaborationGuide, TCollaborationGuideKey } from "./types";
import { CollaborationGuideTitles } from "./constants";
import { Card } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "utils/redux/hooks";
import {
  selectCurrentUserAccountId,
  selectUserInfoById,
} from "app/containers/Global/slice";
import React, { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "app/storybookComponents/Loading";
import NotAvailable from "./NotAvailable";
import CollaborationGuideSamplePdf from "resources/sample_pdfs/sample-collaboration-guide.pdf";
import {
  getCollaborationGuide,
  getCollaborationGuidePdf,
  getEppSimilarityScores,
  getWorkPlaceInsightsReportByUserId,
  selectCollaborationGuideById,
  selectEppSimilarityScoresById,
  selectWorkPlaceInsightsReportsById,
} from "./slice";
import AvatarCircle from "app/components/AvatarCircle";
import {
  RADAR_LABEL_ARRAY,
  COLLABORATION_COMPARE_BY_RADAR_CHART_DATASET,
  COLLABORATION_COMPARE_FOR_RADAR_CHART_DATASET,
} from "app/storybookComponents/Charts/constants";
import RadarChart from "app/storybookComponents/Charts/RadarChart";
import SimpleCollapsibleCard from "app/storybookComponents/CollapsibleCards/SimpleCollapsibleCard";

interface Props {
  userAccountId: number;
  loading?: boolean;
  userIsMissingPersonalityTest?: boolean;
}

export default function CollaborationGuide({
  userAccountId,
  loading,
  userIsMissingPersonalityTest,
}: Readonly<Props>) {
  const dispatch = useAppDispatch();
  // -------------------- App Selectors -------------------- //
  const loggedInUserAccountId = useAppSelector(selectCurrentUserAccountId);
  const userInfo = useAppSelector(selectUserInfoById(userAccountId));
  const eppSimilarityScore = useAppSelector(
    selectEppSimilarityScoresById(userAccountId)
  );
  const yourWorkplaceInsightsReport = useAppSelector(
    selectWorkPlaceInsightsReportsById(Number(loggedInUserAccountId))
  );
  const theirWorkplaceInsightsReport = useAppSelector(
    selectWorkPlaceInsightsReportsById(userAccountId)
  );
  const collaborationGuide = useAppSelector(
    selectCollaborationGuideById(userAccountId)
  );

  // -------------------- State -------------------- //
  const [openedCards, setOpenedCards] = useState<{
    [key: string]: boolean;
  }>({ CollaborationGuideCard: true });

  // -------------------- Effects -------------------- //
  useEffect(() => {
    if (eppSimilarityScore) return;
    dispatch(getEppSimilarityScores(userAccountId));
  }, [userAccountId, dispatch, eppSimilarityScore]);

  useEffect(() => {
    if (!loggedInUserAccountId) return;
    const userId = Number(loggedInUserAccountId);
    dispatch(getWorkPlaceInsightsReportByUserId(userId));
  }, [loggedInUserAccountId, dispatch]);

  useEffect(() => {
    if (!userAccountId) return;
    dispatch(getWorkPlaceInsightsReportByUserId(userAccountId));
  }, [userAccountId, dispatch]);

  useEffect(() => {
    if (!userAccountId || collaborationGuide) return;
    dispatch(getCollaborationGuide(userAccountId));
  }, [userAccountId, dispatch, collaborationGuide]);

  // ----------------------- Memos ----------------------- //
  const radarChart = useMemo(() => {
    if (!userInfo) return null;
    const yourData = yourWorkplaceInsightsReport?.traitScores
      ? RADAR_LABEL_ARRAY.map(
          (label) => yourWorkplaceInsightsReport.traitScores[label] + 25
        )
      : [];
    const theirData = theirWorkplaceInsightsReport?.traitScores
      ? RADAR_LABEL_ARRAY.map(
          (label) => theirWorkplaceInsightsReport.traitScores[label] + 25
        )
      : [];

    return (
      <RadarChart
        canvasId={`${userInfo.userAccountId}-workplace-report`}
        dataSets={[
          {
            ...COLLABORATION_COMPARE_BY_RADAR_CHART_DATASET,
            data: yourData,
            label: "You",
          },
          {
            ...COLLABORATION_COMPARE_FOR_RADAR_CHART_DATASET,
            data: theirData,
            label: `${userInfo.firstName ?? ""} ${userInfo.lastName ?? ""}`,
          },
        ]}
      />
    );
  }, [
    userInfo,
    yourWorkplaceInsightsReport?.traitScores,
    theirWorkplaceInsightsReport?.traitScores,
  ]);

  const getHeaderText = () => {
    if (userIsMissingPersonalityTest) {
      return `Once you complete your personality test, you will be able to see how you compare to ${userInfo?.firstName} and see tips for working more effectively with them.`;
    }
    return `This guide provides an overview of how you and ${userInfo?.firstName}
            compare in communication style, work style, and thinking
            style. Within the guide there are also tips to help you
            work more effectively together.`;
  };

  const getCollaborationSections = () =>
    CollaborationGuideTitles.map(({ key, title, icon }, idx) => {
      const { tips, descriptions } = getTipsAndDescription(key);
      return (
        <React.Fragment key={key}>
          <div
            key={key}
            className="workplace-insights-section collaboration-guide"
          >
            <div>
              <div className="title-with-icon">
                <img
                  src={icon}
                  alt="title icon"
                  height="32px"
                  width="32px"
                  className="me-2"
                />
                <h3>{title}</h3>
              </div>
              <div>{descriptions}</div>
            </div>
            <div className="collaboration-tips">
              <h3>
                <FontAwesomeIcon
                  icon={["far", "lightbulb-on"]}
                  className="me-2"
                />
                Tips
              </h3>
              <ul>{tips}</ul>
            </div>
          </div>
          {idx !== CollaborationGuideTitles.length - 1 && <hr />}
        </React.Fragment>
      );
    });

  const getTipsAndDescription = (
    key: TCollaborationGuideKey
  ): {
    tips: JSX.Element[];
    descriptions: JSX.Element[];
  } => {
    const tips: JSX.Element[] = [];
    const descriptions: JSX.Element[] = [];
    collaborationGuide?.[key]?.forEach((obj) => {
      tips.push(<li key={obj.tip}>{obj.tip}</li>);
      descriptions.push(
        <p
          key={obj.description}
          dangerouslySetInnerHTML={{ __html: obj.description }}
          style={{ marginBottom: "1rem" }}
        />
      );
    });
    return { tips, descriptions };
  };

  const getCollaborationGuideCards = () => {
    const overallSimilarityScore =
      eppSimilarityScore?.overallSimilarityScore ?? 0;
    const tagClassName = `${getTagColor(overallSimilarityScore)} label-tag m-0`;
    return (
      <SimpleCollapsibleCard
        title="Similarities & Differences"
        isOpen={openedCards["CollaborationGuideCard"]}
        onOpen={(value) => {
          setOpenedCards({
            ...openedCards,
            CollaborationGuideCard: value,
          });
        }}
      >
        <div className="column-gap-20px">
          <span className="grey-text" style={{ fontSize: "16px" }}>
            <b>Overall similarity score: </b>
            <span className={tagClassName}>
              {eppSimilarityScore?.overallSimilarityType}
            </span>
          </span>
          <div className="collaboration-guide-content__cards">
            {getCollaborationGuideCard(
              "Attitudes & Outlook",
              "cyan",
              eppSimilarityScore?.attitudesAndOutlook?.insight ?? "",
              eppSimilarityScore?.attitudesAndOutlook?.similarityScore ?? 0,
              eppSimilarityScore?.attitudesAndOutlook?.similarityType ?? ""
            )}
            {getCollaborationGuideCard(
              "Work Habits",
              "blue",
              eppSimilarityScore?.workHabits?.insight ?? "",
              eppSimilarityScore?.workHabits?.similarityScore ?? 0,
              eppSimilarityScore?.workHabits?.similarityType ?? ""
            )}
            {getCollaborationGuideCard(
              "Temperament",
              "poppy",
              eppSimilarityScore?.temperament?.insight ?? "",
              eppSimilarityScore?.temperament?.similarityScore ?? 0,
              eppSimilarityScore?.temperament?.similarityType ?? ""
            )}
            {getCollaborationGuideCard(
              "Interaction Style",
              "yellow",
              eppSimilarityScore?.interactionStyle?.insight ?? "",
              eppSimilarityScore?.interactionStyle?.similarityScore ?? 0,
              eppSimilarityScore?.interactionStyle?.similarityType ?? ""
            )}
          </div>
        </div>
      </SimpleCollapsibleCard>
    );
  };

  const getCollaborationGuideCard = (
    title: string,
    leftStripColor: string,
    description: string,
    score: number,
    tagName: string
  ) => {
    const tagClassNames = `${getTagColor(score)} label-tag m-0`;
    return (
      <Card
        className={`card-with-left-strip column-gap-8px ${leftStripColor}`}
        style={{ padding: "20px 16px" }}
      >
        <h3>{title}</h3>
        <p>{description}</p>
        <div className="d-flex row-gap-12px align-items-center mt-auto">
          <p className="grey-text">Similarity score:</p>
          {/* <span className={tagClassNames}>{Math.round(score)}%</span> */}
          <span className={tagClassNames}>{tagName}</span>
        </div>
      </Card>
    );
  };

  // Might change this to change color based off string value
  const getTagColor = (score: number) => {
    if (score > 90) {
      return "blue";
    } else if (score > 80) {
      return "green";
    } else if (score > 70) {
      return "yellow";
    } else if (score > 60) {
      return "orange";
    } else {
      return "red";
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (!collaborationGuide) {
    return (
      <NotAvailable
        message="Collaboration Guides provide an overview of how you and your teammates compare in communication style, work style, and thinking style. Within the guide there are also tips to help you work more effectively together."
        linkLabel="View Sample Collaboration Guide"
        link={CollaborationGuideSamplePdf}
      />
    );
  }

  return (
    <div>
      <div className="guide-pdf-download">
        <span
          role="button"
          onClick={() => {
            dispatch(getCollaborationGuidePdf(userAccountId));
          }}
        >
          <FontAwesomeIcon icon={["far", "download"]} className="me-2" />
          Download
        </span>
      </div>
      <div className="column-gap-20px">
        {getCollaborationGuideCards()}
        <SimpleCollapsibleCard
          isOpen={!!openedCards["PersonalityComparison"]}
          onOpen={(value: boolean) => {
            setOpenedCards({
              ...openedCards,
              PersonalityComparison: value,
            });
          }}
          title={"Personality Comparison"}
        >
          <div style={{ marginTop: "-20px" }}>{radarChart}</div>
        </SimpleCollapsibleCard>

        <SimpleCollapsibleCard
          isOpen={!!openedCards["CollaborationTips"]}
          onOpen={(value: boolean) => {
            setOpenedCards({
              ...openedCards,
              CollaborationTips: value,
            });
          }}
          title={"Collaboration Tips"}
        >
          <div>
            <div className="collaboration-guide-header">
              <div>
                <p>{getHeaderText()}</p>
              </div>
              <div className="collaboration-guide-avatars">
                {loggedInUserAccountId ? (
                  <AvatarCircle userAccountId={loggedInUserAccountId} />
                ) : null}
                <AvatarCircle userAccountId={userAccountId} />
              </div>
            </div>
            <hr />
            <div>{getCollaborationSections()}</div>
          </div>
        </SimpleCollapsibleCard>
      </div>
    </div>
  );
}
