import { MemberScores } from "./types";
import { Card } from "react-bootstrap";
import SectionHeader from "./SectionHeader";
import { useState } from "react";
import EmptyTeamAverageIllustration from "resources/images/team-average-empty.jpg";
import {
  RADAR_LABEL_ARRAY,
  SINGLE_POPULATION_RADAR_CHART_DATASET,
} from "app/storybookComponents/Charts/constants";
import { getTraitNameAndIcon } from "./helper-functions";
import RadarChart from "app/storybookComponents/Charts/RadarChart";

interface Props {
  readonly teamPersonalityScores: MemberScores | null;
  readonly teamId: number;
}

export default function RadarChartCard({
  teamPersonalityScores,
  teamId,
}: Props) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleIsCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  const getRadarChart = () => {
    if (teamPersonalityScores === null) {
      return (
        <div className="column-gap-20px align-items-center">
          <img
            src={EmptyTeamAverageIllustration}
            alt="empty team average illustration"
            style={{
              width: "200px",
              height: "200px",
            }}
          />
          <p
            style={{
              maxWidth: "600px",
              textAlign: "center",
              color: "#5E6165",
            }}
          >
            Once your team members have taken their personality assessment or
            connected their results, your team personality summary will be shown
            here.
          </p>
        </div>
      );
    }

    type RadarLabel = (typeof RADAR_LABEL_ARRAY)[number];
    type RadarData = {
      [key in RadarLabel]?: number;
    };
    const radarData: RadarData = {};

    // We will create a map where the key is one of the RADAR_LABEL_ARRAY values and the value is the team average for that trait
    if (teamPersonalityScores?.teamScores) {
      Object.entries(teamPersonalityScores.teamScores).forEach(
        ([key, value]) => {
          const trait = getTraitNameAndIcon(key);
          // If the trait does not exist or if the trait name is not in the RADAR_LABEL_ARRAY, then we don't want to add it to the map
          if (!trait || !RADAR_LABEL_ARRAY.includes(trait.traitName)) return;
          radarData[trait.traitName as RadarLabel] = value.teamAverage;
        }
      );
    }

    const data = teamPersonalityScores?.teamScores
      ? RADAR_LABEL_ARRAY.map((label) => {
          return (radarData[label] ?? 0) + 25;
        })
      : [];

    const dataSet = {
      ...SINGLE_POPULATION_RADAR_CHART_DATASET,
      data,
      label: "Team Average",
    };

    return (
      <RadarChart
        canvasId={`team-personality-radar-chart-${teamId}`}
        dataSets={[dataSet]}
        includeDashedBaseline
        includeGeneralPopulation
      />
    );
  };

  if (!teamPersonalityScores) {
    return (
      <Card>
        <h2>Team Personality</h2>
        <p style={{ marginTop: "10px", marginBottom: "10px" }}>
          The Workplace Insights report shows how your team members work
          according to 10 different behavioral traits. Send the EPP assessment
          to your team members to see results.
        </p>
        {getRadarChart()}
      </Card>
    );
  }

  if (isCollapsed) {
    return (
      <Card>
        <SectionHeader
          toggleIsCollapsed={toggleIsCollapsed}
          title="Team Personality"
          isCollapsed={isCollapsed}
        />
      </Card>
    );
  }

  return (
    <Card>
      <SectionHeader
        toggleIsCollapsed={toggleIsCollapsed}
        title="Team Personality"
        isCollapsed={isCollapsed}
      />
      <p className="team-personality-paragraph">
        See where your team falls on average for all traits measure in your Team
        Personality.
      </p>
      {getRadarChart()}
    </Card>
  );
}
